import React from 'react';
import { Box, Grid, Link } from '@mui/material';

import Section from 'src/components/common/background/Section';
import Header from 'src/components/common/sections/Header';
import { PartnerLink } from 'src/types/common';

interface ImageShowcaseProps {
  title: string;
  body: string;
  imageData: PartnerLink[];
  showBackground: boolean;
}

function ImageShowcase({
  title,
  body,
  imageData,
  showBackground,
}: ImageShowcaseProps) {
  return (
    <Section
      containerSx={{ padding: '0px !important' }}
      sx={{ marginBottom: '0px' }}
    >
      <Grid
        item
        xs={12}
        borderRadius="4px"
        sx={{
          backgroundColor: showBackground ? 'primary.main' : 'unset',
          color: showBackground ? 'primary.contrastText' : 'primary.dark',
          paddingBottom: 3,
        }}
      >
        <Box marginX={{ xs: 3, md: 3 }}>
          <Header title={title} body={body} />
        </Box>
        <Grid xs={12} marginX={{ xs: 3, md: 3 }} container>
          {imageData.map((image) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              paddingTop={{ xs: 2, md: 0 }}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {image.href ? (
                <Link
                  href={image.href}
                  key={`about-partner-link-${image.name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="none"
                >
                  <img src={image.image} alt={image.name} width="220px" />
                </Link>
              ) : (
                <img src={image.image} alt={image.name} width="220px" />
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Section>
  );
}

export default ImageShowcase;
