import { cloneDeep } from 'lodash';

import { Code, InformationCard, InsightCard, PageBase } from 'src/types/common';
import { EditSection, EditType } from 'src/types/edit';
import { FilterGroup, FilterOrder, FilterType } from 'src/types/filters';
import { MapItem } from 'src/types/map';
import { CustomTheme } from 'src/types/organization_metadata';
import { HomePage } from 'src/types/pages/home';
import { InsightsPage, InsightSummaryPage } from 'src/types/pages/insights';
import {
  ConversationLibraryPage,
  HighlightsPage,
} from 'src/types/pages/voices';
import { CustomRoute } from 'src/types/routes';
import { slugify } from 'src/utils/utils';
import { getFilterByType } from './code';
import { generateHomePageWithMap } from './map';

const CONVERSATION_LIBRARY_PAGE_ID = 'conversation-library';
const INSIGHTS_PAGE_ID = 'insights';
export const VOICES_PAGE_ID = 'voices';

/**
 * This function generates the route and corresponding page for any insight that does not already have a route
 * @param routes the stored routes
 * @param pages the stored pages
 * @param analytic_codes the codes used to generate pages and navigation
 * @param theme the stored theme, taken from organization metadata
 * @returns a list of routes and a list of pages
 */
export const modifyInsights = (
  routes: CustomRoute[],
  pages: PageBase[],
  analytic_codes: Code[],
  theme: CustomTheme
): { routes: CustomRoute[]; pages: PageBase[] } => {
  // Modify Routes
  const newRoutes = cloneDeep(routes);
  const newPages = cloneDeep(pages);
  const insightsPage = newPages.find(
    (page) => page.page_id === INSIGHTS_PAGE_ID
  );
  if (!insightsPage) {
    return { pages, routes };
  }
  const insightCardSection = insightsPage.edit_metadata.sections.find(
    (section) => section.section_label === 'Insight Cards'
  );
  if (!insightCardSection) {
    return { pages, routes };
  }
  const insightsRoute = newRoutes.find(
    (route) => route.page_data_id === INSIGHTS_PAGE_ID
  ) ?? {
    visible: true,
    path: '/insights',
    label: 'insights',
    in_nav: true,
    page_data_id: INSIGHTS_PAGE_ID,
  };
  const insightSubroutes = insightsRoute.subroutes ?? [];
  const parentCodePageIds = analytic_codes
    .filter(({ display_name }) => !display_name.includes('*')) // Remove structural code
    .map(({ id, label, subcodes }) => ({
      id,
      label,
      page_id: `insights-${id}`,
      subcodes,
    }));
  // Remove routes that do not match a parent code
  let filteredInsightSubroutes = insightSubroutes.filter((subroute) =>
    parentCodePageIds.some(({ page_id }) => subroute.page_data_id === page_id)
  );
  // Remove parent codes that already have a matching route
  const filteredParentCodePageIds = parentCodePageIds.filter(
    ({ page_id }) =>
      !filteredInsightSubroutes.some(
        ({ page_data_id }) => page_data_id === page_id
      )
  );
  // Create new routes for missing insights
  const newInsightSubroutes = filteredParentCodePageIds.map(
    ({ label, page_id }) => {
      return {
        visible: true,
        path: `/${slugify(label)}`,
        label,
        in_nav: true,
        page_data_id: page_id,
      };
    }
  );
  // Join new subroutes with existing subroutes
  filteredInsightSubroutes =
    filteredInsightSubroutes.concat(newInsightSubroutes);
  // Set insights subroutes
  insightsRoute.subroutes = filteredInsightSubroutes;

  // Create new insight cards =
  const insightCards = (insightsPage as InsightsPage).insight_cards.content
    .cards;
  const newInsightCards: InformationCard[] = parentCodePageIds.map((data) => {
    const currentCard = insightCards.find(
      (card) => card.id === data.id.toString()
    );
    return {
      id: data.id.toString(),
      title: data.label,
      link: {
        to: `/insights/${slugify(data.label)}`,
        label: 'Explore Full Insights',
      },
      subtitle: `<p>${data.subcodes
        ?.map((subcode) => subcode.label)
        .join(', ')}</p>`,
      body: '<p>Insert explainer text</p>',
      ...currentCard,
    };
  });
  (insightsPage as InsightsPage).insight_cards.content.cards = newInsightCards;

  // Create new edit metadata for insight cards
  const newEditMetadata = parentCodePageIds.map(({ label }, ind) => {
    return {
      section_label: label,
      edit_blocks: [
        {
          block_label: 'Title',
          match: `insight_cards.content.cards[${ind}].title`,
          type: EditType.string,
        },
        {
          block_label: 'Subtitle',
          match: `insight_cards.content.cards[${ind}].subtitle`,
          type: EditType.complex_string,
        },
        {
          block_label: 'Paragraph',
          match: `insight_cards.content.cards[${ind}].body`,
          type: EditType.complex_string,
        },
        {
          block_label: 'Image',
          match: `insight_cards.content.cards[${ind}].image`,
          type: EditType.image,
        },
        {
          block_label: 'Link',
          match: `insight_cards.content.cards[${ind}].link`,
          type: EditType.link,
        },
      ],
    };
  });
  insightCardSection.sub_sections = newEditMetadata;
  // Create new pages for missing insights
  const newInsightPages = filteredParentCodePageIds
    .map(({ id, page_id }) => {
      const matchingCode = analytic_codes.find((code) => code.id === id);
      if (!matchingCode) {
        return undefined;
      }
      return generateInsightPage(page_id, matchingCode, theme);
    })
    .filter((item) => item) as InsightSummaryPage[];
  return { routes: newRoutes, pages: newPages.concat(newInsightPages) };
};

/**
 * This function generates the home page with a map showcase section (if a map exists)
 * @param pages A complete list of the pages in the site
 * @param map The array of mapItems present
 * @param demographic_codes All of the demographic codes
 */
export const modifyMap = (
  pages: PageBase[],
  map: MapItem[],
  demographic_codes: Code[]
): { pages: PageBase[] } => {
  if (map.length < 1) {
    return { pages };
  }
  const newPages: PageBase[] = cloneDeep(pages);
  const homePageIndex: number = pages.findIndex(
    (page) => page.page_id === 'home'
  );
  newPages[homePageIndex] = generateHomePageWithMap(
    newPages[homePageIndex] as HomePage,
    map,
    demographic_codes,
    true
  );
  return { pages: newPages };
};

/**
 * Generate a insight summary page template
 * @param page_id - the new id for the page
 * @param code - the code to generate a page for
 * @param theme - the current theme, taken from organization metadata
 * @returns an InsightSummaryPage template
 */
const generateInsightPage = (
  page_id: string,
  code: Code,
  theme: CustomTheme
): InsightSummaryPage => {
  return {
    page_id,
    section_order: [],
    header: {
      visible: true,
      content: {
        title: code.label,
        body: '<p>Insert code general explanation</p>',
        header_type: 'full',
      },
    },
    summary: {
      visible: true,
      content: {
        highlight_link_style: {
          default_color: theme.palette.secondary.main,
          active_color: theme.palette.primary.main,
        },
        paragraphs: [
          {
            label: 'Overview',
            text: '<p>Insert overview text</p>',
          },
        ].concat(
          code.subcodes?.map(({ label }) => ({
            label,
            text: '<p>Insert subcode explainer text</p>',
          })) ?? []
        ),
      },
    },
    edit_metadata: {
      page_label: code.label,
      page_id: page_id,
      subroute: true,
      sections: [
        {
          section_label: 'Introduction',
          edit_blocks: [
            {
              block_label: 'Header',
              match: 'header.content',
              type: EditType.banner,
            },
          ],
        },
        {
          section_label: 'Highlight Colors',
          edit_blocks: [
            {
              block_label: 'Color',
              match: 'summary.content.highlight_link_style.default_color',
              type: EditType.color,
            },
            {
              block_label: 'Active Color',
              match: 'summary.content.highlight_link_style.active_color',
              type: EditType.color,
            },
          ],
        },
        {
          section_label: 'Paragraphs',
          update_section: {
            match: 'summary.content.paragraphs',
            label: 'Add Paragraph',
            empty: {
              label: 'Default Label',
              text: 'Default Text',
            },
            newSectionMetadata: {
              section_label: 'Insight',
              numbered: true,
              edit_blocks: [
                {
                  block_label: 'Title',
                  match: `summary.content.paragraphs[index].label`,
                  type: EditType.string,
                },
                {
                  block_label: 'Paragraph',
                  match: `summary.content.paragraphs[index].text`,
                  type: EditType.embed_string,
                },
              ],
            },
          },
          sub_sections: (
            [
              {
                section_label: 'Overview',
                edit_blocks: [
                  {
                    block_label: 'Title',
                    match: 'summary.content.paragraphs[0].label',
                    type: EditType.string,
                  },
                  {
                    block_label: 'Paragraph',
                    match: 'summary.content.paragraphs[0].text',
                    type: EditType.embed_string,
                  },
                ],
              },
            ] as EditSection[]
          ).concat(
            code.subcodes
              ?.map((subcode, ind) => ({
                section_label: 'Insight',
                numbered: true,
                edit_blocks: [
                  {
                    block_label: 'Title',
                    match: `summary.content.paragraphs[${ind + 1}].label`,
                    type: EditType.string,
                  },
                  {
                    block_label: 'Paragraph',
                    match: `summary.content.paragraphs[${ind + 1}].text`,
                    type: EditType.embed_string,
                  },
                ],
              }))
              .filter((section) => section) as EditSection[]
          ),
        },
      ],
    },
  };
};

/**
 * This function modifies the voices routes depending on whether conversation data exists
 * @param routes the stored routes
 * @param pages the stored pages
 * @param hasConversationData - does conversation data exist on the window object
 * @returns a list of route and a list of pages
 */
export const modifyVoicesRoutes = (
  routes: CustomRoute[],
  pages: PageBase[],
  hasConversationData?: boolean
): { routes: CustomRoute[]; pages: PageBase[] } => {
  const newRoutes = cloneDeep(routes);
  let newPages = cloneDeep(pages);
  const voicesRoute = routes.find(
    (route) => route.page_data_id === VOICES_PAGE_ID
  ) ?? {
    visible: true,
    path: `/${VOICES_PAGE_ID}`,
    label: 'Voices',
    in_nav: true,
    page_data_id: VOICES_PAGE_ID,
  };
  if (!hasConversationData) {
    // No conversations in BOOTSTRAP_DATA
    // Remove conversation library subroute
    voicesRoute.subroutes = voicesRoute.subroutes?.filter(
      ({ page_data_id }) => page_data_id !== CONVERSATION_LIBRARY_PAGE_ID
    );
    // Remove conversation library page
    newPages = newPages.filter(
      (page) => page.page_id !== CONVERSATION_LIBRARY_PAGE_ID
    );
    return { routes: newRoutes, pages: newPages };
  }
  // Conversations exists in BOOTSTRAP_DATA
  let conversationLibrarySubroute = voicesRoute.subroutes?.find(
    ({ page_data_id }) => page_data_id !== CONVERSATION_LIBRARY_PAGE_ID
  );
  if (!conversationLibrarySubroute) {
    // Initialize subroute
    conversationLibrarySubroute = {
      visible: true,
      path: `/${CONVERSATION_LIBRARY_PAGE_ID}`,
      label: 'Conversation Library',
      in_nav: true,
      page_data_id: CONVERSATION_LIBRARY_PAGE_ID,
    };
  }
  let conversationLibraryPage = newPages.find(
    (page) => page.page_id === CONVERSATION_LIBRARY_PAGE_ID
  ) as ConversationLibraryPage;
  if (!conversationLibraryPage) {
    // Initialize conversation library page
    conversationLibraryPage = {
      page_id: CONVERSATION_LIBRARY_PAGE_ID,
      section_order: [],
      header: {
        visible: true,
        content: {
          title: 'Voices by Conversation',
          header_type: 'thin',
          body: '',
        },
      },
      edit_metadata: {
        page_label: 'Conversation Library',
        subroute: true,
        sections: [
          {
            section_label: 'Introduction',
            edit_blocks: [
              {
                block_label: 'Header',
                match: 'header.content',
                type: EditType.banner,
              },
            ],
          },
        ],
      },
    };
  }
  return { routes: newRoutes, pages };
};

/**
 * This function generates the insights_showcase on the home page
 * @param pages - the list of pages
 * @param codes - the list of codes
 * @returns a page
 */
export const getInsightsShowcase = (
  pages: PageBase[],
  codes: Code[]
): { pages: PageBase[] } => {
  const newPages = cloneDeep(pages);
  const modifiedCodes = codes.filter(
    (code) => !code.display_name.includes('*')
  );
  const homePage = newPages.find((page) => page.page_id === 'home');
  if (!homePage) {
    return { pages: newPages };
  }
  let insights = (homePage as HomePage).insights_showcase.content.insights;
  if (!insights) {
    (homePage as HomePage).insights_showcase.visible = false;
    return { pages: newPages };
  }
  const insightShowcaseEditMetadata = (
    homePage as HomePage
  ).edit_metadata.sections.find(
    (section) => section.section_label === 'Insights Showcase'
  );
  if (!insightShowcaseEditMetadata) {
    (homePage as HomePage).insights_showcase.visible = false;
    return { pages: newPages };
  }

  // Remove insights that are not used
  insights = insights.filter((insight) =>
    modifiedCodes.find((code) => code.id.toString() === insight.id)
  );
  const remainingCodes = modifiedCodes.filter(
    (code) => !insights.some((insights) => insights.id === code.id.toString())
  );
  const newInsights: InsightCard[] = remainingCodes.map((code) => ({
    id: code.id.toString(),
    title: code.label,
    subtitle: `<p>${code.subcodes?.map((code) => code.label).join(', ')}</p>`,
    body: '<p></p>',
    link: {
      to: `insights/${slugify(code.label)}`,
      label: 'Explore Full Insights',
    },
  }));
  (homePage as HomePage).insights_showcase.content.insights =
    insights.concat(newInsights);
  // Generate insight edit  metadata
  const editMetadataBase: EditSection[] = [
    {
      section_label: 'Overview',
      edit_blocks: [
        {
          block_label: 'Title',
          match: 'insights_showcase.content.overview.title',
          type: EditType.complex_string,
        },
        {
          block_label: 'Subtitle',
          match: 'insights_showcase.content.overview.subtitle',
          type: EditType.complex_string,
        },
        {
          block_label: 'Paragraph',
          match: 'insights_showcase.content.overview.body',
          type: EditType.complex_string,
        },
        {
          block_label: 'Link',
          match: `insights_showcase.content.overview.link`,
          type: EditType.link,
        },
        {
          block_label: 'Graph Type',
          match: `insights_showcase.content.overview.graph_type`,
          type: EditType.graph,
        },
        {
          block_label: 'Disable Child Code Links',
          match: `insights_showcase.content.overview.disable_through_click`,
          type: EditType.checkbox,
        },
      ],
    },
  ];
  const newEditSections = (
    homePage as HomePage
  ).insights_showcase.content.insights.map((insight, ind) => ({
    section_label: insight.title,
    edit_blocks: [
      {
        block_label: 'Title',
        match: `insights_showcase.content.insights[${ind}].title`,
        type: EditType.complex_string,
      },
      {
        block_label: 'Subtitle',
        match: `insights_showcase.content.insights[${ind}].subtitle`,
        type: EditType.complex_string,
      },
      {
        block_label: 'Paragraph',
        match: `insights_showcase.content.insights[${ind}].body`,
        type: EditType.complex_string,
      },
      {
        block_label: 'Link',
        match: `insights_showcase.content.insights[${ind}].link`,
        type: EditType.link,
      },
    ],
  }));
  insightShowcaseEditMetadata.sub_sections =
    editMetadataBase.concat(newEditSections);
  return { pages: newPages };
};

/**
 * This function creates a filter order for projects that have just been initialized.
 * @param pages - the list of pages
 * @param filters - the list of filters
 * @returns all the pages with a potential modification to filter order
 */
export const getFilterOrder = (pages: PageBase[], filters: FilterGroup[]) => {
  const newPages = cloneDeep(pages);
  const highlightsPage = newPages.find(
    (page) => page.page_id === 'highlight-explorer'
  );
  if (!highlightsPage) {
    return { pages: newPages };
  }
  let filterOrder = (highlightsPage as HighlightsPage).filters.content
    .filter_order;
  if (!filterOrder.tabs) {
    filterOrder = {
      tabs: [],
      thematic: { name: FilterType.thematic, order: [] },
      demographic: { name: FilterType.demographic, order: [] },
    } as FilterOrder;
    // defaults to the order given by the backend
    filterOrder.tabs = [FilterType.thematic, FilterType.demographic];
    filterOrder.demographic.order = getFilterByType(
      filters,
      FilterType.demographic
    ).map((group) => group.label);
    filterOrder.thematic.order = getFilterByType(filters, FilterType.thematic)
      .map((group) => group.label)
      .concat(
        getFilterByType(filters, FilterType.structural).map(
          (group) => group.label
        )
      );
    (highlightsPage as HighlightsPage).filters.content.filter_order =
      filterOrder;
  }
  if (!filterOrder.thematic.order) {
    filterOrder.thematic = { order: [], name: FilterType.thematic };
    filterOrder.demographic = { order: [], name: FilterType.demographic };
  }
  return { pages: newPages };
};
