import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

import { ProfileMetadata } from 'src/types/common';

function Profile({ profile }: { profile: ProfileMetadata }) {
  const xsDimension = '100px';
  const smDimension = '175px';
  const pictureStyle = {
    maxWidth: '100%',
    margin: 'auto',
    maxHeight: '100%',
  };
  const defaultStyle = {
    width: { xs: xsDimension, sm: smDimension },
    height: { xs: xsDimension, sm: smDimension },
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <Grid
      item
      lg={3}
      md={4}
      xs={6}
      container
      direction={'column'}
      alignItems={'center'}
    >
      {profile.image ? (
        <Box
          component="img"
          sx={{
            ...pictureStyle,
            borderRadius: profile.circular ? '50%' : 'unset',
          }}
          src={profile.image}
          alt={profile.name}
        />
      ) : (
        <Box component="div" sx={defaultStyle}>
          {profile.name
            .split(' ')
            .map((part) => part[0])
            .join('')
            .toUpperCase()}
        </Box>
      )}

      <Box paddingTop={2}>
        <Typography align="center" variant="body1">
          <strong>{profile.name}</strong>
        </Typography>
      </Box>
      <Box>
        <Typography align="center" variant="body2">
          {profile.description}
        </Typography>
      </Box>
    </Grid>
  );
}

export default Profile;
