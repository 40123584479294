import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { HighlightPlayer } from 'lvn-embed-api';

import appSelectors from 'src/redux/app/app-selectors';
import { Highlight } from 'src/types/common';
import { formatDuration } from 'src/utils/utils';
import Footer from '../Footer';
import Speaker from '../Speaker';
import TopicChips from '../TopicChips';
import Transcript from '../Transcript';
import Avatar from './Avatar';
import PlayButton from './PlayButton';

interface HighlightProps {
  sectionId: string;
  highlight: Highlight;
  onSelect?: (highlightId: number) => void;
  selectedHighlightId?: number;
  extend?: boolean;
  scrolling?: boolean;
  light?: boolean;
  triggerNext?: () => void;
  setAudioIcon?: (toggle: string) => void;
}

function HighlightContainer({
  sectionId,
  highlight,
  onSelect,
  selectedHighlightId,
  extend,
  scrolling,
  light,
  triggerNext,
  setAudioIcon,
}: HighlightProps) {
  const organization_metadata = useSelector(
    appSelectors.getOrganizationMetadata
  );
  const embedUrl = useSelector(appSelectors.getEmbedUrl);
  const [minimalPlayer, setMinimalPlayer] = useState<HighlightPlayer>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [displayTime, setDisplayTime] = useState(highlight.duration);

  const handlePlayerEnded = () => {
    if (triggerNext) {
      triggerNext();
    }
    setIsPlaying(false);
  };
  const handlePlayerTimeUpdate = (e: any) => {
    setDisplayTime(e.seconds);
  };
  const handlePlayerReady = React.useCallback((player: HighlightPlayer) => {
    player.on('ended', handlePlayerEnded);
    //@ts-ignore - api issue
    player.on('timeupdate', handlePlayerTimeUpdate);
  }, []);

  useEffect(() => {
    setMinimalPlayer(
      new HighlightPlayer({
        containerId: `${sectionId}-embed-minimal-${highlight.id}`,
        highlightId: highlight.id,
        highlightOptions: {
          type: 'minimal',
          fontSize: '16px',
          scrolling: scrolling,
          //@ts-ignore - api issue
          fontFamily: organization_metadata?.default_font_family,
          fontWeight: '500',
          highlightColor: organization_metadata?.embed_highlight_color,
          secret: highlight.secret,
          src: embedUrl,
        },
        onReady: handlePlayerReady,
      })
    );
  }, [
    sectionId,
    highlight,
    scrolling,
    handlePlayerReady,
    organization_metadata?.default_font_family,
    organization_metadata?.embed_highlight_color,
    embedUrl,
  ]);

  // Make sure to update the display time whenever the highlight changes since the initial useState only initializes
  useEffect(() => {
    setDisplayTime(highlight.duration);
  }, [highlight]);

  useEffect(() => {
    if (selectedHighlightId !== highlight.id) {
      if (minimalPlayer) {
        minimalPlayer.pause();
        setIsPlaying(false);
      }
    }
  }, [selectedHighlightId]);

  const handlePlayToggle = () => {
    if (minimalPlayer) {
      if (minimalPlayer.getIsPlaying()) {
        minimalPlayer.pause();
        setAudioIcon && setAudioIcon('play');
      } else {
        minimalPlayer.play();
        setAudioIcon && setAudioIcon('pause');
      }
      setIsPlaying(minimalPlayer.isPlaying);

      if (onSelect) onSelect(highlight.id);
    }
  };

  const speakerImg = highlight.speaker_img;

  return (
    <div data-testid="highlight-container">
      <Card elevation={1} sx={{ width: '100%', borderRadius: '5px' }}>
        <CardContent>
          <Grid
            container
            sx={{ paddingX: '.5rem', paddingY: '.5rem', textAlign: 'center' }}
          >
            {speakerImg && (
              <Grid item xs={1.5} paddingRight={'.5rem'}>
                <Avatar src={speakerImg} />
              </Grid>
            )}
            <Grid
              item
              xs={speakerImg ? 8.5 : 10}
              paddingLeft={speakerImg ? '' : '.5rem'}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Speaker speaker={highlight.speaker_name} />
            </Grid>
            <Grid container item xs={2} paddingRight={'.5rem'}>
              <div id={`play-${highlight.id}`}>
                <PlayButton
                  info={`${formatDuration(displayTime)}`}
                  isPlaying={isPlaying}
                  onClick={handlePlayToggle}
                />
              </div>
            </Grid>
          </Grid>
          <Transcript
            sectionId={sectionId}
            hid={highlight.id}
            extend={extend}
          />
        </CardContent>
        <Footer highlight={highlight} />
      </Card>
      <TopicChips code_ids={highlight.codes} light={light} />
    </div>
  );
}

export default HighlightContainer;
