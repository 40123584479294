import React from 'react';
import { Checkbox, ListItem, Typography } from '@mui/material';
import { cloneDeep, set } from 'lodash';

import { CustomRoute } from 'src/types/routes';
import StringEditor from './StringEditor';

const RouteEditor = React.forwardRef(
  (
    { initialRoute, allRoutes }: { initialRoute: CustomRoute; allRoutes: any },
    ref
  ) => {
    const [route, setRoute] = React.useState<CustomRoute>(initialRoute);
    const handleChange = (value: string | boolean, match: string) => {
      const newRoute = cloneDeep(route);

      set(newRoute, match, value);
      if (ref) {
        (ref as React.MutableRefObject<CustomRoute>).current = newRoute;
      }

      setRoute(newRoute);
    };

    const disabledInNav = route.subroutes
      ? false
      : !allRoutes.find((parentRoute: CustomRoute) => {
          return parentRoute.subroutes?.find((subroute: CustomRoute) => {
            return subroute.page_data_id === route.page_data_id;
          });
        }).in_nav;

    return (
      <>
        <ListItem disableGutters>
          <Typography variant="h6">Label</Typography>
          <StringEditor
            initialValue={route.label}
            onChange={(value) => handleChange(value, 'label')}
          />
        </ListItem>
        <ListItem disableGutters>
          <Typography variant="h6">Visible</Typography>
          <Checkbox
            data-testid="route-visibility-checkbox"
            checked={route.visible}
            onChange={(value, checked) => {
              handleChange(checked, 'visible');
            }}
          />
        </ListItem>
        <ListItem disableGutters>
          <Typography
            variant="h6"
            style={{ color: disabledInNav ? '#767676' : 'inherit' }}
          >
            Show In Navigation
          </Typography>
          <Checkbox
            data-testid="hide-navigation-checkbox"
            checked={route.in_nav}
            onChange={(value, checked) => {
              handleChange(checked, 'in_nav');
            }}
            disabled={disabledInNav}
          />
        </ListItem>
      </>
    );
  }
);

export default RouteEditor;
