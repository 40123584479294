import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import SingleFadeMenu from 'src/components/common/menus/SingleFadeMenu';
import appSelectors from 'src/redux/app/app-selectors';
import { VOICES_PAGE_ID } from 'src/setup/pages';
import { CustomRoute } from 'src/types/routes';
import { useEditing } from 'src/utils/useEditing';
import { useGeneratePath } from 'src/utils/useGeneratePath';

const MainNav = () => {
  const generatePath = useGeneratePath();
  const routes = useSelector(appSelectors.getCustomRoutes);
  const organization_metadata = useSelector(
    appSelectors.getOrganizationMetadata
  );
  const { canEdit, isEditing, updateEditState } = useEditing();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const mobile: boolean = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{ width: '100%', backgroundColor: 'white' }}
      data-testid="site-navbar"
    >
      <Toolbar sx={{ padding: mobile ? '16px' : '' }}>
        <Box
          sx={{
            flexGrow: 1,
            flexDirection: mobile ? 'row-reverse' : 'row',
            display: 'flex',
            alignItems: 'center',
            justifyContent: mobile ? 'space-between' : 'flex-start',
          }}
        >
          {mobile ? (
            <>
              {routes
                ?.filter((route) => route.path === '/')
                .map((route) => {
                  return (
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        paddingRight: { xs: '0.75rem', sm: '3rem' },
                        paddingLeft: '.5rem',
                        height: 'fit-content',
                        color: 'primary.dark',
                      }}
                      key={route.label}
                    >
                      <Link
                        to={generatePath(route.path)}
                        style={{
                          textDecoration: 'none',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <img
                          alt={
                            organization_metadata?.logo
                              ? organization_metadata?.name
                              : ''
                          }
                          src={organization_metadata?.logo}
                          style={{
                            height: '50px',
                            maxWidth: '300px',
                            objectFit: 'scale-down',
                          }}
                        />
                      </Link>
                    </Typography>
                  );
                })}
              <IconButton
                size="large"
                aria-label="portal-navigation"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                disableScrollLock
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', sm: 'none' },
                }}
              >
                {routes?.map(({ label, path }) => (
                  <MenuItem
                    key={`mobile-menu-${label}`}
                    onClick={handleCloseNavMenu}
                  >
                    <Link
                      style={{ textDecoration: 'none', color: 'black' }}
                      to={generatePath(path)}
                    >
                      {path === '/' ? 'Home' : label}
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : (
            <>
              {routes?.map((route) => {
                if (!route.visible) {
                  return <></>;
                }
                if (!route.in_nav) {
                  return <></>;
                }
                if (route.path === '/') {
                  return (
                    <Typography
                      variant="h6"
                      noWrap
                      component="div"
                      sx={{
                        paddingRight: { xs: '0.75rem', sm: '3rem' },
                        paddingLeft: '.5rem',
                        height: '2.25rem',
                        color: 'primary.dark',
                      }}
                      key={route.label}
                    >
                      <Link
                        to={generatePath(route.path)}
                        style={{
                          textDecoration: 'none',
                          display: 'flex',
                          justifyContent: 'center',
                          height: '100%',
                        }}
                      >
                        <img
                          alt={
                            organization_metadata?.logo
                              ? organization_metadata?.name
                              : ''
                          }
                          src={organization_metadata?.logo}
                          height="100%"
                          style={{ paddingRight: '0.5rem' }}
                        />
                        {route.label}
                      </Link>
                    </Typography>
                  );
                }
                if (route.subroutes?.length) {
                  return (
                    <SingleFadeMenu
                      key={route.label}
                      label={route.label}
                      links={route.subroutes
                        .filter(
                          (subroute) => subroute.visible && subroute.in_nav
                        )
                        .sort((a: CustomRoute, b: CustomRoute) => {
                          // ensure Highlight Explorer shows up before conversation library
                          if (route.page_data_id === VOICES_PAGE_ID) {
                            return b.page_data_id.localeCompare(a.page_data_id);
                          }
                          return 0;
                        })
                        .map((subroute) => {
                          return {
                            label: subroute.label,
                            link: route.path + subroute.path,
                          };
                        })}
                      defaultLink={route.path}
                    />
                  );
                } else {
                  return (
                    <Link
                      to={generatePath(route.path)}
                      key={route.label}
                      style={{
                        textDecoration: 'none',
                        padding: '6px 24px 6px 8px',
                        fontWeight: '700',
                        fontSize: '0.875rem',
                        lineHeight: 1.75,
                      }}
                    >
                      {route.label}
                    </Link>
                  );
                }
              })}
              {canEdit && !isEditing && (
                <div style={{ flexGrow: 1 }}>
                  <IconButton
                    sx={{
                      float: 'right',
                      color: '#4F29B7',
                      backgroundColor: '#ffffff',
                    }}
                    onClick={() => updateEditState(true)}
                  >
                    <EditIcon />
                  </IconButton>
                </div>
              )}
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default MainNav;
