import React from 'react';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material';
import { set } from 'lodash';

import { FilterGroup } from 'src/types/filters';
import StringEditor from './StringEditor';

const FilterGroupEditor = React.forwardRef(
  (
    {
      initialGroup,
    }: {
      initialGroup: FilterGroup;
    },
    ref
  ) => {
    const [open, setOpen] = React.useState<boolean>();
    const [group, setGroup] = React.useState<FilterGroup>(initialGroup);
    const handleChange = (value: string, match: string) => {
      const newGroup = { ...group };
      set(newGroup, match, value);
      // TODO
      if (ref) {
        (ref as React.MutableRefObject<FilterGroup>).current = newGroup;
      }
      setGroup(newGroup);
    };
    return (
      <>
        <ListItem data-testid={`${group.id}-filter-list-item`}>
          <ListItemButton onClick={() => setOpen((self) => !self)}>
            {open ? <ExpandMore /> : <ChevronRight />}
            <Typography variant="h6">{group.id}</Typography>
          </ListItemButton>
        </ListItem>
        <Collapse in={open}>
          <List sx={{ padding: '0' }}>
            <ListItem sx={{ padding: '0 0 0 1rem' }}>
              <Typography sx={{ marginX: '1rem' }}>Label:</Typography>
              <StringEditor
                initialValue={group.label}
                onChange={(value) => {
                  handleChange(value, 'label');
                }}
              />
            </ListItem>
          </List>
        </Collapse>
      </>
    );
  }
);

export default FilterGroupEditor;
