import React from 'react';
import { Box, Grid, useMediaQuery } from '@mui/material';

import HighlightContainer from 'src/components/Audio/Highlight/Highlight';
import { Highlight } from 'src/types/common';

interface HighlightBlockProps {
  highlight?: Highlight;
  sectionId?: string;
}

function HighlightBlock({ highlight, sectionId }: HighlightBlockProps) {
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  return (
    <Grid
      item
      xs={12}
      sm={6}
      padding={6}
      sx={{
        display: { xs: 'none', sm: 'block' },
        height: '100%',
        overflow: 'visible',
      }}
    >
      {highlight && (
        <Box>
          <HighlightContainer
            sectionId={`summary-${sectionId ? sectionId + '-' : ''}${
              isSmallScreen ? 'tablet' : 'desktop'
            }`}
            highlight={highlight}
            scrolling
            extend
          />
        </Box>
      )}
    </Grid>
  );
}

export default HighlightBlock;
