import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import * as d3 from 'd3';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { separateLocationPaths } from 'src/components/HighlightExplorer/utils/utils';
import { Code } from 'src/types/common';
import { LocationMetadata, MapItem } from 'src/types/map';

export const NULL_PK = -1;
export const UNASSIGNED_COLOR = '#A9A9A9';
export const INACTIVE_COLOR = '#6A6A6A';

export interface MapUpdate {
  mapItems: MapItem[];
  demographicCodes: Code[];
  codesHaveChanged: boolean;
}

export interface InitialMapItems {
  mapItems: MapItem[];
  generated: boolean;
  demographicCodes: Code[];
  mappableCodesChanged: boolean;
}

export const convertSVGToLocationMetadata = (
  svg: SVGSVGElement,
  threshhold?: number
): LocationMetadata[] => {
  const parent = svg.firstChild as SVGGElement;
  cleanPaths(svg, threshhold);
  const allLocations: Array<LocationMetadata> = Array.from(parent.children).map(
    (elem: Element) => {
      return {
        demographic_id: NULL_PK,
        paths: [],
        path: d3.select(elem).attr('d'),
        color: d3.select(elem).attr('fill'),
        label: 'u' + uuidv4(), // ensure each svg section has a unique identifier. "u" is needed as an arbitrary letter, as an id cannot start with a number
        matching_count: NULL_PK,
      };
    }
  );
  return allLocations;
};

export const sanitizeMap = (mapItems: MapItem[]): MapItem[] => {
  // need to remove longest path, likely to be the background svg or contiguous border
  mapItems.forEach((mapItem: MapItem) => {
    if (mapItem.demographic_id === NULL_PK) {
      mapItem.paths.sort((a: string, b: string) => {
        return a.length - b.length;
      });
      // remove last, longest paths
      // arbitrarily last 1, but we should probably test edge cases
      mapItem.paths.splice(-1, 1);
    }
  });
  return mapItems;
};

/**
 * This function combs through the svg and removes path elements that are not essential to the image
 * @param svg the svg element created on upload of a map image
 */
const cleanPaths = (svg: SVGSVGElement, threshold = 0.01) => {
  const parent = svg.firstChild as SVGGElement;
  const paths = parent.getElementsByTagName('path');
  let pathList: any = [];
  [].forEach.call(paths, (path: SVGPathElement) => {
    const { width, height } = path.getBoundingClientRect();
    const area = width * height;
    path.setAttribute('opacity', '1');
    pathList.push({
      path,
      area,
    });
  });
  const { width, height } = parent.getBoundingClientRect();
  const svgArea = width * height;

  // Remove paths that do not meet a certain size threshold
  pathList = pathList.filter((path: any) => {
    const removePath = path.area / svgArea <= threshold;
    if (removePath) {
      parent.removeChild(path.path);
    }
    return !removePath;
  });
};

/**
 * Function that calculates how much an SVG should scale upwards or downwards to fit its' parent container
 * @param imageHeight height in pixels of the given image
 * @param parentContainer HTMLElement of the parent container
 * @returns decimal ratio by which to scale the SVG up or down
 */
export const calculateImageScaleFactor = (
  imageHeight: number,
  parentContainer: HTMLElement
): number => {
  const parentContainerHeight: number =
    parentContainer.getBoundingClientRect().height;

  const ratio: number = imageHeight / parentContainerHeight;
  return ratio >= 1 ? 1 / ratio : 2 - ratio;
};

export const resetMap = (
  mapItems: MutableRefObject<MapItem[]>,
  resetMapItems: MutableRefObject<MapItem[]>,
  svgRef: MutableRefObject<null>,
  updateRef: () => void,
  setD3MappingComplete: Dispatch<SetStateAction<any>>
) => {
  if (svgRef.current) {
    const svgContainer = d3.select(svgRef.current as HTMLElement);
    const locationPaths: LocationMetadata[] = separateLocationPaths(
      resetMapItems.current as LocationMetadata[]
    );
    const existingUpload = document.getElementById('svgContainer')?.firstChild;
    if (existingUpload) {
      existingUpload.remove();
    }
    mapItems.current = cloneDeep(resetMapItems.current);
    svgContainer
      .append('svg')
      .attr('id', 'initialMapSvg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .append('g')
      .selectAll('g')
      .data(locationPaths)
      .join('path')
      .attr('fill', (d: LocationMetadata) => d.color ?? '#CCC')
      .attr('d', (d: LocationMetadata) => d.path ?? '');

    updateRef();
    setD3MappingComplete({});
  }
};
