import { EditType } from 'src/types/edit';
import { ProcessPage } from 'src/types/pages/about';

export const PROCESS: ProcessPage = {
  page_id: 'process',
  section_order: [],
  header: {
    visible: true,
    content: {
      title: 'Our Process',
      header_type: 'full',
      image: '',
      center: true,
      body: '',
    },
  },
  introduction: {
    visible: true,
    content: {
      title: 'How does this all work?',
      body: 'Insert general information about the process.',
      texture: false,
    },
  },
  facilitated_conversations: {
    label: 'Facilitated Conversations',
    visible: true,
    text: 'Insert text about designing the conversations.',
    image: '',
  },
  sensemaking: {
    label: 'Sensemaking',
    visible: true,
    text: 'After the conversations are facilitated and recorded the work of deep listening and human-led analysis begins.',
    image: '',
  },
  conversation_portal: {
    label: 'Conversation Portal',
    visible: true,
    text: 'The conversation portal is an interactive, public-facing platform to explore and listen to the stories and experiences in a thematically organized way.<br/><br/>Interested in learning more about our approach? Contact us at hello@cortico.ai.',
    image: '',
  },
  step_order: [
    'facilitated_conversations',
    'sensemaking',
    'conversation_portal',
  ],
  edit_metadata: {
    page_label: 'Process',
    subroute: true,
    sections: [
      {
        section_label: 'Introduction',
        edit_blocks: [
          {
            block_label: 'Header',
            match: 'header.content',
            type: EditType.banner,
          },
        ],
      },
      {
        section_label: 'Overview',
        edit_blocks: [
          {
            block_label: 'Visible?',
            match: 'introduction.visible',
            type: EditType.checkbox,
          },
          {
            block_label: 'Title',
            match: 'introduction.content.title',
            type: EditType.string,
          },
          {
            block_label: 'Paragraph',
            match: 'introduction.content.body',
            type: EditType.complex_string,
          },
          {
            block_label: 'Featured Highlight',
            match: 'introduction.content.featured_highlight',
            type: EditType.featured_highlight,
          },
          // TODO: Uncomment when we figure out how we'd like to handle the generation and retrieval of Medleys
          // {
          //   block_label: 'Medley',
          //   match: 'introduction.content.medley',
          //   type: EditType.medley,
          // },
          {
            block_label: 'Textured Background',
            match: 'introduction.content.texture',
            type: EditType.checkbox,
          },
        ],
      },
      {
        section_label: 'Section One',
        edit_blocks: [
          {
            block_label: 'Title',
            match: 'facilitated_conversations.label',
            type: EditType.string,
          },
          {
            block_label: 'Paragraph',
            match: 'facilitated_conversations.text',
            type: EditType.complex_string,
          },
          {
            block_label: 'Image',
            match: 'facilitated_conversations.image',
            type: EditType.image,
          },
        ],
      },
      {
        section_label: 'Section Two',
        edit_blocks: [
          {
            block_label: 'Title',
            match: 'sensemaking.label',
            type: EditType.string,
          },
          {
            block_label: 'Paragraph',
            match: 'sensemaking.text',
            type: EditType.complex_string,
          },
          {
            block_label: 'Image',
            match: 'sensemaking.image',
            type: EditType.image,
          },
        ],
      },
      {
        section_label: 'Section Three',
        edit_blocks: [
          {
            block_label: 'Title',
            match: 'conversation_portal.label',
            type: EditType.string,
          },
          {
            block_label: 'Paragraph',
            match: 'conversation_portal.text',
            type: EditType.complex_string,
          },
          {
            block_label: 'Image',
            match: 'conversation_portal.image',
            type: EditType.image,
          },
        ],
      },
    ],
  },
};
