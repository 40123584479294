import React from 'react';
import { Container, Grid } from '@mui/material';

import { InformationCard } from 'src/types/common';
import InfoCard from './InfoCard';

interface InfoCardsProps {
  cards: InformationCard[];
  triple?: boolean;
}

function InfoCards({ cards, triple }: InfoCardsProps) {
  return (
    <Container maxWidth="lg">
      <Grid
        container
        item
        spacing={5}
        paddingX={{ xs: 3, md: 0 }}
        paddingY={10}
      >
        {cards.map((card) => (
          <Grid sm={6} md={triple ? 4 : undefined} item key={card.title}>
            <InfoCard {...card} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default InfoCards;
