import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

interface CalloutProps {
  children: React.ReactElement;
  sx?: SxProps<Theme>;
  boxSx?: SxProps<Theme>;
  backgroundColor?: string;
  offset?: string;
}

function Callout({
  children,
  sx,
  boxSx,
  backgroundColor,
  offset,
}: CalloutProps) {
  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          ...sx,
          backgroundColor: backgroundColor ?? 'unset',
          marginTop: offset ? offset : 'unset',
        }}
        elevation={0}
      >
        <Box
          sx={{ ...boxSx }}
          marginY={{ xs: 4, md: 6 }}
          marginX={{ xs: 3, md: 6 }}
          paddingY={{ xs: 2, md: 4 }}
        >
          {children}
        </Box>
      </Paper>
    </Grid>
  );
}

export default Callout;
