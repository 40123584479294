import React from 'react';
import ArrowDown from '@mui/icons-material/ArrowDropDown';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

interface CardTabProps {
  idx: number;
  label: string;
  body: string;
  selectedIdx: number;
  onClick: (idx: number) => void;
  primaryColor?: string;
  secondaryColor?: string;
  small?: boolean;
}

function CardTab({
  idx,
  label,
  body,
  selectedIdx,
  onClick,
  primaryColor,
  secondaryColor,
  small,
}: CardTabProps) {
  const selected = idx === selectedIdx;

  return (
    <>
      <Card
        onClick={() => onClick(idx)}
        elevation={0}
        sx={{
          backgroundColor: selected
            ? primaryColor ?? 'primary.dark'
            : secondaryColor ?? 'primary.main',
          marginX: '1.5rem',
          cursor: 'pointer',
          height: small ? '90px' : '150px',
          color: selected ? 'white' : secondaryColor ?? 'white',
          ':hover': {
            cursor: selected ? 'default' : 'pointer',
            backgroundColor: selected
              ? primaryColor ?? 'primary.dark'
              : secondaryColor ?? 'primary.light',
            borderBlockEnd: '6px white solid',
          },
          zIndex: '100',
          borderBlockEnd: selected
            ? `6px white solid`
            : `6px ${primaryColor ?? 'primary.light'} solid`,
        }}
      >
        <CardContent>
          {label && (
            <Typography
              variant="caption"
              component="div"
              color={selected ? 'white' : secondaryColor ?? 'white'}
              sx={{ paddingBottom: '.25rem' }}
            >
              {label}
            </Typography>
          )}
          <Typography
            variant="body1"
            sx={{
              fontWeight: selected ? '700' : '600',
            }}
          >
            {body}
          </Typography>
        </CardContent>
      </Card>
      {selected && (
        <div
          style={{
            marginTop: '-28px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ArrowDown
            sx={{
              color: 'white',
              fontSize: '65px',
            }}
          />
        </div>
      )}
    </>
  );
}

export default CardTab;
