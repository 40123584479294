import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import { useGeneratePath } from 'src/utils/useGeneratePath';

interface LinkButtonProps {
  link: string;
  children: string;
  white?: boolean;
  size?: 'small' | 'medium' | 'large';
}

export default function LinkButton({
  link,
  children,
  white,
  size,
}: LinkButtonProps) {
  const generatePath = useGeneratePath();

  const buttonElem = useMemo(() => {
    const buttonStyles = white
      ? { color: 'white', borderColor: 'white', position: 'static !important' }
      : { position: 'static !important' };
    return (
      <Button
        variant="contained"
        disableElevation
        color="secondary"
        size={size}
        sx={{
          ...buttonStyles,
          ':hover': {
            color: 'white',
          },
        }}
        data-testid="link-button"
      >
        {children}
      </Button>
    );
  }, [children, size, white]);

  const externalLink: boolean = link.substring(0, 1) !== '/';

  return externalLink ? (
    <a href={link} target="_blank" style={{ textDecoration: 'none' }}>
      {buttonElem}
    </a>
  ) : (
    <Link
      to={generatePath(link)}
      style={{
        textDecoration: 'none',
      }}
    >
      {buttonElem}
    </Link>
  );
}
