import React from 'react';
import { useSelector } from 'react-redux';
import { Tune } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';

import { getBanner } from 'src/components/common/banners/utils';
import InfoModalButton from 'src/components/common/buttons/InfoModalButton';
import FilterModal from 'src/components/common/filters/FilterModal';
import ExplorerView from 'src/components/HighlightExplorer/ExplorerView';
import appSelectors from 'src/redux/app/app-selectors';
import { StoreState } from 'src/redux/store';
import { PageProps } from 'src/types/common';
import { FilterOption } from 'src/types/filters';
import { HighlightsPage } from 'src/types/pages/voices';
import { useFilters } from './useFilters';
import { useHighlights } from './useHighlights';
import { useMap } from './useMap';

function HighlightExplorer({ page_data_id }: PageProps) {
  const pageInfo = useSelector((state: StoreState) =>
    appSelectors.getPage(state, page_data_id)
  ) as HighlightsPage;
  const isExtraSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('xs')
  );
  const { filters, activeFilters, setActiveFilters } = useFilters();
  const { highlights, filteredHighlights } = useHighlights(activeFilters);
  const { modifiedMapData, handleResetMap, handleAddMapFilter } = useMap(
    filters,
    activeFilters,
    highlights,
    setActiveFilters
  );

  const handleRemoveFilter = (option: FilterOption) => {
    setActiveFilters(activeFilters.filter((x) => x !== option));
  };

  if (!pageInfo) {
    return <></>;
  }

  return (
    <Grid>
      <Grid container>
        {pageInfo.header?.visible && (
          <div
            data-edit-id={`${pageInfo.page_id}.header`}
            style={{ width: '100%' }}
          >
            {getBanner({
              ...pageInfo.header.content,
              icon: (
                <InfoModalButton
                  iconLabel="Filters"
                  icon={<Tune sx={{ fontSize: '20px' }} />}
                  closeButtonLabel="Close"
                >
                  {filters ? (
                    <FilterModal
                      filters={filters}
                      activeFilters={activeFilters}
                      setActiveFilters={setActiveFilters}
                      small={isExtraSmallScreen}
                      filter_order={pageInfo.filters.content.filter_order}
                    />
                  ) : (
                    <></>
                  )}
                </InfoModalButton>
              ),
            })}
          </div>
        )}
        {highlights && (
          <ExplorerView
            filters={activeFilters}
            handleRemoveFilter={handleRemoveFilter}
            handleAddMapFilter={handleAddMapFilter}
            handleResetMap={handleResetMap}
            highlights={filteredHighlights}
            mapData={modifiedMapData}
            locationType={pageInfo?.map.content.location_type}
            zoomable={pageInfo?.map.content.zoomable}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default HighlightExplorer;
