import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Code } from 'src/types/common';
import PackedCirclesViz from './PackedCircles/PackedCirclesViz';

const SVG = PackedCirclesViz.styled();

interface InsightCirclesProps {
  codes: Code[];
  onSelection: (id: string | undefined) => void;
  onClose: () => void;
  selectedInsightId?: string;
  disableThroughClick?: boolean;
  // Base width for drawing the viz (NOT actual width of svg)
  viewBoxWidth?: number;
  // Base height for drawing the viz (NOT actual height of svg)
  viewBoxHeight?: number;
  // Padding between edge of svg and viz
  verticalPadding?: number;
}

function InsightCircles({
  selectedInsightId,
  codes,
  onSelection = () => {
    return;
  },
  onClose = () => {
    return;
  },
  disableThroughClick,
  viewBoxWidth = 450,
  viewBoxHeight = 450,
  verticalPadding = 10,
}: InsightCirclesProps) {
  const svgRef = useRef<SVGElement>(null);

  const navigateToHighlights = useCallback(
    (url: string) => {
      if (disableThroughClick) {
        onClose();
        return;
      }
      navigate(url);
    },
    [disableThroughClick, onClose]
  );

  const viz = useMemo(() => {
    return new PackedCirclesViz(
      svgRef,
      viewBoxWidth,
      viewBoxHeight - 2 * verticalPadding,
      { animDuration: 250 },
      navigateToHighlights
    );
  }, [viewBoxWidth, viewBoxHeight, verticalPadding, svgRef]);

  useEffect(() => {
    viz.setUp(0, verticalPadding);

    return () => {
      viz.tearDown();
    };
  }, [viz, verticalPadding]);

  useEffect(() => {
    viz.update(codes, selectedInsightId);
    viz.registerHandlers(selectedInsightId, onSelection);

    return () => {
      viz.deregisterHandlers();
    };
  }, [viz, onSelection, codes, selectedInsightId]);
  const navigate = useNavigate();

  return (
    <Grid
      paddingX={4}
      paddingTop={1}
      paddingBottom={3}
      data-testid="packed-circles"
    >
      <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
        <SVG
          // @ts-ignore - ignore
          ref={svgRef}
          preserveAspectRatio="xMidYMid meet"
          viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
          id="infographic"
        />
      </Grid>
      <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
        {selectedInsightId ? (
          <Button onClick={onClose}>
            <Typography variant="caption">
              <CloseIcon
                sx={{
                  fontSize: '14px',
                  paddingRight: '0.5rem',
                  verticalAlign: 'middle',
                }}
              />
              Back to Insights
            </Typography>
          </Button>
        ) : (
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              variant="caption"
              color="text.secondary"
              lineHeight={1.5}
            >
              The circle size reflects the number of highlights for a theme.
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              lineHeight={1.5}
              component="div"
            >
              Click on a theme to learn more.
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default InsightCircles;
