import React from 'react';
import JsxParser from 'react-jsx-parser';
import { Add, ChevronRight, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import { EditSection } from 'src/types/edit';
import { focusSection, unfocusSection } from './utils/utils';

const CollapsableSection = ({
  editId,
  pageLabel,
  section,
  disabled,
  onUpdateSection,
  onSectionClick,
}: {
  editId?: string;
  pageLabel: string;
  section: EditSection;
  disabled?: boolean;
  onSectionClick: (section: EditSection) => void;
  onUpdateSection?: (
    section: EditSection,
    removeSubsection?: EditSection
  ) => void;
}) => {
  const [open, setOpen] = React.useState(false);
  // This is needed to ensure a user can always add new subsections, even when they've all been deleted
  const isParentSection =
    !!section.sub_sections?.length ||
    section.update_section?.newSectionMetadata.numbered;
  const removeCount = section.sub_sections?.filter(
    (section) => section.numbered
  ).length;
  const canUpdateSection = !!section.update_section;
  return (
    <>
      <ListItem
        data-testid={`collapsable-section-${section.section_label}`}
        key={section.section_label}
        disablePadding
        sx={{ paddingLeft: '20px' }}
      >
        <ListItemButton
          disabled={disabled}
          data-testid="subsection-button"
          onClick={() => {
            if (isParentSection) {
              setOpen((self) => !self);
            } else {
              onSectionClick({
                edit_header: `${pageLabel} > ${section.section_label}`,
                ...section,
              });
            }
          }}
          onMouseEnter={() => editId && focusSection(editId)}
          onMouseLeave={() => editId && unfocusSection(editId)}
        >
          {isParentSection && (open ? <ExpandMore /> : <ChevronRight />)}
          {/* @ts-ignore - ignore */}
          <ListItemText primary={<JsxParser jsx={section.section_label} />} />
        </ListItemButton>
      </ListItem>

      {isParentSection && (
        <Collapse in={open}>
          <List data-testid={`sublist-${section.section_label}`}>
            {section.sub_sections?.map((subsection) => {
              return (
                <ListItem
                  key={subsection.section_label}
                  disablePadding
                  sx={{ paddingLeft: '40px' }}
                  data-testid={`subsection-item-${subsection.section_label}`}
                >
                  <ListItemButton
                    disabled={disabled}
                    data-testid="parent-subsection-button"
                    onClick={() =>
                      onSectionClick({
                        edit_header: `${pageLabel} > ${section.section_label} > ${subsection.section_label}`,
                        ...subsection,
                        onUpdateSection:
                          canUpdateSection &&
                          subsection.numbered &&
                          removeCount &&
                          !!onUpdateSection
                            ? () => onUpdateSection(section, subsection)
                            : undefined,
                      })
                    }
                  >
                    <ListItemText
                      // @ts-ignore - ignore
                      primary={<JsxParser jsx={subsection.section_label} />}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
            {canUpdateSection && (
              <ListItem
                data-testid="list-item-update"
                disablePadding
                sx={{ paddingLeft: '40px' }}
              >
                <ListItemButton
                  disabled={disabled}
                  onClick={() => !!onUpdateSection && onUpdateSection(section)}
                >
                  <Add data-testid={`add-new-item-${section.section_label}`} />
                  Add New Item
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default CollapsableSection;
