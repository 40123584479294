import React from 'react';
import { Checkbox, List, ListItem, Typography } from '@mui/material';
import { cloneDeep, set } from 'lodash';

import { BannerItem } from 'src/types/common';
import CustomEditor from './CustomEditor';
import ImageEditor from './ImageEditor';
import ListEditor from './ListEditor';

const BANNER_TYPES = ['full', 'split', 'thin'];

const BannerEditor = React.forwardRef(
  (
    {
      initialData,
    }: {
      initialData: BannerItem;
    },
    ref
  ) => {
    const [banner, setBanner] = React.useState<BannerItem>(initialData);
    const handleChange = (value: string | boolean, match: string) => {
      const newBanner = cloneDeep(banner);
      set(newBanner, match, value);
      if (ref) {
        (ref as React.MutableRefObject<BannerItem>).current = newBanner;
      }
      setBanner(newBanner);
    };
    const isThinBanner = banner.header_type === 'thin';
    return (
      <List data-testid="banner-editor">
        <ListItem
          data-testid="banner-editor-header"
          sx={{ display: 'inline-block' }}
        >
          <Typography variant="h6" sx={{ marginTop: '1rem' }}>
            Header Type
          </Typography>
          <ListEditor
            initialOptions={[banner.header_type]}
            allOptions={BANNER_TYPES}
            single
            onChange={(value) => {
              handleChange(value as string, 'header_type');
            }}
          />
        </ListItem>
        <ListItem
          data-testid="banner-editor-title"
          sx={{ display: 'inline-block' }}
        >
          <Typography variant="h6" sx={{ marginTop: '1rem' }}>
            Title
          </Typography>
          <CustomEditor
            initialText={banner.title}
            onChange={(value) => handleChange(value, 'title')}
          />
        </ListItem>
        {!isThinBanner && (
          <>
            <ListItem sx={{ display: 'inline-block' }}>
              <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                Paragraph
              </Typography>
              <CustomEditor
                initialText={banner.body}
                onChange={(value) => handleChange(value, 'body')}
              />
            </ListItem>
            <ListItem sx={{ display: 'inline-block' }}>
              <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                Image
              </Typography>
              <ImageEditor
                initialValue={banner.image ?? ''}
                onChange={(value) => handleChange(value, 'image')}
              />
            </ListItem>
            <ListItem sx={{ display: 'inline-block' }}>
              <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                Textured Background
              </Typography>
              <Checkbox
                checked={banner.texture}
                onChange={(value, checked) => {
                  handleChange(checked, 'texture');
                }}
              />
            </ListItem>
            <ListItem sx={{ display: 'inline-block' }}>
              <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                Large
              </Typography>
              <Checkbox
                checked={banner.large}
                onChange={(value, checked) => {
                  handleChange(checked, 'large');
                }}
              />
            </ListItem>
            <ListItem sx={{ display: 'inline-block' }}>
              <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                Secondary Colors
              </Typography>
              <Checkbox
                checked={banner.secondary}
                onChange={(value, checked) => {
                  handleChange(checked, 'secondary');
                }}
              />
            </ListItem>
            <ListItem sx={{ display: 'inline-block' }}>
              <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                Centered
              </Typography>
              <Checkbox
                checked={banner.center}
                onChange={(value, checked) => {
                  handleChange(checked, 'center');
                }}
              />
            </ListItem>
            {/* <ListItem sx={{ display: 'inline-block' }}>
              <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                Arrow
              </Typography>
              <Checkbox
                checked={banner.arrow}
                onChange={(value, checked) => {
                  handleChange(checked, 'arrow');
                }}
              />
            </ListItem> */}
          </>
        )}
      </List>
    );
  }
);

export default BannerEditor;
