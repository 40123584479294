import React from 'react';
import { useSelector } from 'react-redux';

import { getD3Locations } from 'src/components/HighlightExplorer/utils/utils';
import appSelectors from 'src/redux/app/app-selectors';
import { Highlight } from 'src/types/common';
import { FilterGroup, FilterOption, FilterType } from 'src/types/filters';
import { LocationMetadata } from 'src/types/map';

export const useMap = (
  filters: FilterGroup[] | undefined,
  activeFilters: FilterOption[],
  filteredHighlights: Highlight[],
  setActiveFilters: React.Dispatch<React.SetStateAction<FilterOption[]>>
) => {
  const mapData = useSelector(appSelectors.getMap);

  const handleResetMap = () => {
    const mapDemographicIds = mapData?.map(
      ({ demographic_id }) => demographic_id
    );
    if (mapDemographicIds) {
      setActiveFilters(
        activeFilters.filter(
          (filter) => !mapDemographicIds.includes(filter.match as number)
        )
      );
    }
  };

  const handleAddMapFilter = (id: string) => {
    const alreadyFiltering = activeFilters.find(
      (option) => (option.match as number) === parseInt(id)
    );
    if (alreadyFiltering) {
      return;
    }
    const option = filters
      ?.filter((filter) => filter.type === FilterType.demographic)
      .find((filter) =>
        filter.options.some((option) => option.match === parseInt(id))
      )
      ?.options.find((option) => option.match === parseInt(id));
    if (option) {
      setActiveFilters((filters) => [...filters, option]);
    }
  };

  const modifiedMapData = React.useMemo((): LocationMetadata[] => {
    if (!mapData) {
      return [];
    }
    const activeMapIds = mapData
      .filter((item) =>
        activeFilters.some(
          (filter) => filter.match === item.demographic_id // Only use id
        )
      )
      .map((item) => item.demographic_id);
    return getD3Locations(mapData, filteredHighlights, activeMapIds);
  }, [mapData, activeFilters, filteredHighlights]);

  return {
    modifiedMapData,
    handleResetMap,
    handleAddMapFilter,
  };
};
