import { clear, get, set } from 'idb-keyval';

import { BootstrapData } from 'src/types/boostrap';

const STORAGE_KEY = 'bootstrap_data';
/**
 * Grabs the bootstrap data from local storage
 * @returns bootstrap data or undefined if not data present.
 */
export const getBootstrapFromStorage = async (): Promise<
  BootstrapData | undefined
> => {
  let bootstrapData;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  if (window.Cypress) {
    return bootstrapData;
  }
  await get(STORAGE_KEY)
    .then((ret) => (bootstrapData = ret))
    .catch((err) => err);
  return bootstrapData;
};

/**
 * Sets the blob data in storage
 * @param data bootstrap data
 */
export const setBootstrapInStorage = (data: BootstrapData) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  if (window.Cypress) {
    return;
  }
  set(STORAGE_KEY, data);
};

/**
 * Removes the blob data from local storage
 */
export const clearBootstrapStorage = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  if (window.Cypress) {
    return;
  }
  clear();
};
