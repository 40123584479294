import React, { useEffect } from 'react';
import JsxParser from 'react-jsx-parser';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';

import { getBanner } from 'src/components/common/banners/utils';
import InfoCards from 'src/components/common/cards/InfoCards';
import appSelectors from 'src/redux/app/app-selectors';
import { StoreState } from 'src/redux/store';
import { PageProps } from 'src/types/common';
import { AboutPage } from 'src/types/pages/about';
import { stripHTMLTags } from 'src/utils/utils';

function About({ page_data_id }: PageProps) {
  const pageInfo = useSelector((state: StoreState) =>
    appSelectors.getPage(state, page_data_id)
  ) as AboutPage;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {pageInfo ? (
        <Grid container>
          {pageInfo.header?.visible && (
            <div
              data-edit-id={`${pageInfo.page_id}.header`}
              style={{ width: '100%' }}
            >
              {getBanner(pageInfo.header.content)}
            </div>
          )}
          {pageInfo.navigation_cards?.visible && (
            <div
              data-edit-id={`${pageInfo.page_id}.navigation_cards`}
              style={{ width: '100%' }}
            >
              <InfoCards cards={pageInfo.navigation_cards.content.cards} />
            </div>
          )}
          {stripHTMLTags(pageInfo.addendumText) !== '' /* false */ && (
            <div
              data-edit-id={`${pageInfo.page_id}.addendumText`}
              style={{ width: '100%' }}
            >
              <Typography align="center" variant="body2">
                {/* @ts-ignore - jsx parser */}
                <JsxParser jsx={pageInfo.addendumText} />
              </Typography>
            </div>
          )}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}

export default About;
