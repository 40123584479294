import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import app, { sagas as appSagas } from './app/app-slice';

// import app, { sagas as appSagas } from "./app/app-slice";

/**
 * Combine reducers to produce a single reducer for state.
 * Each reducer handles a subtree of the state tree based
 * on its name.
 */
export const rootReducer = combineReducers({
  app,
});
export type StoreState = ReturnType<typeof rootReducer>;

/** Combine sagas into a single root saga */
export function* rootSaga() {
  yield all([...appSagas]);
}

/**
 * Helper function to create store with provided reducer and initial state.
 *
 * @return {Object} An object that holds the complete state of the App.
 */
export default function makeStore() {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];

  // in the non-production builds, mix in debugging middlewares
  if (process.env.NODE_ENV === 'development') {
    const logger = createLogger({
      collapsed: true,
    });
    // debugging middleware that logs actions
    middleware.push(logger as any);
  }

  const store = configureStore({
    reducer: rootReducer,
    middleware,
    devTools: process.env.NODE_ENV !== 'production',
  });

  sagaMiddleware.run(rootSaga);

  // store globally for convenience in development
  if (process.env.NODE_ENV !== 'production') {
    (window as any).store = store;
  }

  return store;
}
