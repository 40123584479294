import _ from 'lodash';
import { cloneDeep } from 'lodash';

import { AppState } from 'src/redux/app/app-slice';
import { PageBase, PageEditMetadata } from 'src/types/common';
import { EditBlock, EditSection } from 'src/types/edit';
import { OrganizationMetadata } from 'src/types/organization_metadata';
import { CustomRoute } from 'src/types/routes';
import {
  migrateToVersion1,
  migrateToVersion2,
  migrateToVersion3,
  migrateToVersion4,
  migrateToVersion5,
  migrateToVersion6,
} from './migratePageVersions';
import { getMatchingRoute } from './routes';

export const LATEST_INTERNAL_VERSION = 5;

/**
 * This function modifies page subsections to have numbers if numbered. It also
 * adds the corresponging route label to the metadata.
 * @param pages - the full list of pages to be rendered on the screen
 * @param routes - the full list of routes in the application
 * @returns a list of PageEditMetadata
 */
export const generateEditMetadata = (
  pages: PageBase[],
  routes: CustomRoute[]
): PageEditMetadata[] =>
  pages
    .filter(
      (page) =>
        !!page.edit_metadata &&
        !!getMatchingRoute(routes, { page_id: page.page_id })
    )
    .map(({ edit_metadata, page_id }) => {
      // Get page label
      const matchingRoute = getMatchingRoute(routes, { page_id });
      const page_label =
        matchingRoute?.path === '/' ? 'Home' : matchingRoute?.label;
      // Number sections
      const clonedMetadata: PageEditMetadata = cloneDeep(edit_metadata);
      const modifiedSections =
        clonedMetadata?.sections.map((section) => {
          const isNumbered = section.sub_sections?.some(
            (section) => section.numbered
          );
          let modifiedSubsections = section.sub_sections;
          if (isNumbered && modifiedSubsections) {
            let num = 1;
            modifiedSubsections = section.sub_sections?.map((subsection) => {
              if (subsection.numbered) {
                subsection.section_label = subsection.section_label + ` ${num}`;
                num = num + 1;
              }
              return subsection;
            });
          }
          section.sub_sections = modifiedSubsections;
          return section;
        }) ?? ([] as EditSection[]);
      clonedMetadata.sections = modifiedSections;
      return { ...clonedMetadata, page_id, page_label } as PageEditMetadata;
    });

/**
 * Takes in the bootstrap_data's pages and org metadata and merges their edit_metadata with the new initial page edit_metadata. Used for updates to fields, EditTypes, new edit blocks, etc.
 * @param pages Pages retrieved from the bootstrap_data
 * @param organizationMetadata Organization metadata retreived from the bootstrap_data
 * @param newPages New pages, retrieved from the initial state
 * @param newOrganizationMetadata New organization metadata, retrieved from the initial state
 * @returns Object containing the newly-merged pages and organization metadata
 */
export const mergeEditMetadata = (
  pages: PageBase[],
  organizationMetadata: OrganizationMetadata,
  newPages: PageBase[],
  newOrganizationMetadata: OrganizationMetadata
): {
  mergedPages: PageBase[];
  mergedOrganizationMetadata: OrganizationMetadata;
} => {
  const mergedPages: PageBase[] = cloneDeep(pages);
  const mergedOrganizationMetadata: OrganizationMetadata =
    cloneDeep(organizationMetadata);
  // need to clone the newPages array, as lodash's mergeWith() function will mutate the target object in addition to returning the merged object
  const clonedNewPages = cloneDeep(newPages);

  // iterate through each page of the new data and update the edit_metadata of the corresponding boostrap_data page
  clonedNewPages.forEach((newPage: PageBase) => {
    const matchingPageIndex: number = mergedPages.findIndex(
      (bootstrapPage) => bootstrapPage.page_id === newPage.page_id
    );
    if (matchingPageIndex !== -1) {
      /**
       * overwrite anything the "new" (initial) page has with the existing bootstrapped data... with the exception of edit_metadata.
       * In this case, we want to overwrite whatever the bootstrapped data has so it's always up to date with our new schema (new edit sections, new edit types, renames, etc.).
       * However, this requires additional processing, as some of the edit_metadata IS user-editable, namely in the case of removable sections (i.e. Highlight Explorer tabs on the homepage)
       */
      mergedPages[matchingPageIndex] = _.mergeWith(
        newPage,
        mergedPages[matchingPageIndex],
        (targetValue: any, sourceValue: any, propertyName: string) => {
          // edit_metadata is the only object we want to grab "new" values for, since the actual content values all come from the user (existing data)
          if (propertyName === 'edit_metadata') {
            const newMetadata: PageEditMetadata = cloneDeep(targetValue);
            const existingMetadata: PageEditMetadata = cloneDeep(sourceValue);

            // make sure the "new" edit metadata has up-to-date sub_sections (addable/removable sections that are present in the existing data, e.g. Highlight Showcase tabs)
            newMetadata.sections.forEach((newSection: EditSection) => {
              if (newSection.update_section && newSection.sub_sections) {
                const matchingExistingSection: EditSection | undefined =
                  existingMetadata.sections.find(
                    (existingSection) =>
                      existingSection.section_label === newSection.section_label
                  );
                if (matchingExistingSection) {
                  // get the new template for the sub-sections so we can overwrite the data in the existing sub-sections
                  const newSectionMetadata: EditSection =
                    newSection.update_section.newSectionMetadata;

                  // remove the old numbered sections (only the numbered sections can be added and removed by the users)
                  newSection.sub_sections = newSection.sub_sections.filter(
                    (subSection) => !subSection.numbered
                  );

                  // add the new numbered sections from the existing data
                  newSection.sub_sections = newSection.sub_sections.concat(
                    matchingExistingSection.sub_sections!.filter(
                      (subSection) => subSection.numbered
                    )
                  );

                  // go in and overwrite the numbered sections' edit metadata
                  newSection.sub_sections
                    .filter((subSection) => subSection.numbered)
                    .forEach((subSection: EditSection, index: number) => {
                      subSection.section_label =
                        newSectionMetadata.section_label;
                      // in theory, this should never change, but adding it just in case
                      subSection.numbered = newSectionMetadata.numbered;
                      // fix the match field to account for the correct index
                      subSection.edit_blocks =
                        newSectionMetadata.edit_blocks?.map(
                          (editBlock: EditBlock) => {
                            return {
                              ...editBlock,
                              match: editBlock.match.replace(
                                'index',
                                index.toString()
                              ),
                            };
                          }
                        );
                    });
                }
              }
            });

            return newMetadata;
          }
          return sourceValue;
        }
      );
    }
  });

  mergedOrganizationMetadata.edit_metadata = cloneDeep(
    newOrganizationMetadata.edit_metadata
  );

  return { mergedPages, mergedOrganizationMetadata };
};

/**
 * Function that takes in the current AppState and, if necessary, runs structural updates to the live page data, incrementing the internalVersion.
 *
 * @param data Current AppState. Should have overridden the initialState with whatever is in bootstrap_data.
 */
export const incrementInternalVersion = (data: AppState): void => {
  while (data.internalVersion < LATEST_INTERNAL_VERSION) {
    const nextVersion: number = data.internalVersion + 1;
    const pages = cloneDeep(data.pages);
    const codes = cloneDeep(data.codes);
    const routes = cloneDeep(data.routes);

    switch (nextVersion) {
      case 1: {
        data.pages = migrateToVersion1(pages);
        break;
      }
      case 2: {
        data.pages = migrateToVersion2(pages);
        break;
      }
      case 3: {
        data.pages = migrateToVersion3(pages);
        break;
      }
      case 4: {
        const V4 = migrateToVersion4(pages, codes);
        data.pages = V4.pages;
        data.filters = V4.filters;
        break;
      }
      case 5: {
        data.routes = migrateToVersion5(routes);
        break;
      }
      case 6: {
        data.pages = migrateToVersion6(pages);
        break;
      }
      // case 7: {
      //   data.pages = migrateToVersion7(pages);
      //   break;
      //   ...etc
      // }
      default:
        break;
    }
    data.internalVersion++;
  }
};
