import { EditType } from 'src/types/edit';
import { VoicesPage } from 'src/types/pages/voices';

export const VOICES: VoicesPage = {
  page_id: 'voices',
  section_order: [],
  header: {
    visible: true,
    content: {
      title: 'Voices',
      body: '<p>Explainer for voices information</p>',
      header_type: 'full',
      texture: true,
    },
  },
  navigation_cards: {
    visible: true,
    content: {
      cards: [
        {
          title: 'Browse Highlights',
          body: 'The search tool can be used to explore highlights across conversations.',
          image: '/images/voices_highlights.png',
          link: { to: 'highlight-explorer', label: 'Explore Highlights' },
        },
        {
          title: 'Conversation Library',
          body: 'Library of Conversations is a data visualization of collected conversations. Explore the most common themes and listen to connected highlights.',
          image: '/images/voices_conversations.png',
          link: {
            to: 'conversation-library',
            label: 'Explore Conversations',
          },
        },
      ],
      card_order: [],
    },
  },
  edit_metadata: {
    page_label: 'Voices',
    page_id: 'voices',
    sections: [
      {
        section_label: 'Introduction',
        edit_blocks: [
          {
            block_label: 'Header',
            match: 'header.content',
            type: EditType.banner,
          },
        ],
      },
      {
        section_label: 'Navigation Cards',
        update_section: {
          match: 'navigation_cards.content.cards',
          label: 'Add Navigation Card',
          empty: {
            title: 'Default Title',
            body: '<p>Default Body</p>',
            image: '',
            link: {
              to: '/',
              label: 'Default Link',
            },
          },
          newSectionMetadata: {
            section_label: 'Navigation Card',
            numbered: true,
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'navigation_cards.content.cards[index].title',
                type: EditType.string,
              },
              {
                block_label: 'Paragraph',
                match: 'navigation_cards.content.cards[index].body',
                type: EditType.complex_string,
              },
              {
                block_label: 'Image',
                match: 'navigation_cards.content.cards[index].image',
                type: EditType.image,
              },
              {
                block_label: 'Link',
                match: 'navigation_cards.content.cards[index].link',
                type: EditType.link,
              },
            ],
          },
        },
        sub_sections: [
          {
            section_label: 'Navigation Card',
            numbered: true,
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'navigation_cards.content.cards[0].title',
                type: EditType.string,
              },
              {
                block_label: 'Paragraph',
                match: 'navigation_cards.content.cards[0].body',
                type: EditType.complex_string,
              },
              {
                block_label: 'Image',
                match: 'navigation_cards.content.cards[0].image',
                type: EditType.image,
              },
              {
                block_label: 'Link',
                match: 'navigation_cards.content.cards[0].link',
                type: EditType.link,
              },
            ],
          },
          {
            section_label: 'Navigation Card',
            numbered: true,
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'navigation_cards.content.cards[1].title',
                type: EditType.string,
              },
              {
                block_label: 'Paragraph',
                match: 'navigation_cards.content.cards[1].body',
                type: EditType.complex_string,
              },
              {
                block_label: 'Image',
                match: 'navigation_cards.content.cards[1].image',
                type: EditType.image,
              },
              {
                block_label: 'Link',
                match: 'navigation_cards.content.cards[1].link',
                type: EditType.link,
              },
            ],
          },
        ],
      },
    ],
  },
};
