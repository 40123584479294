import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';

import HighlightContainer from 'src/components/Audio/Highlight/Highlight';
import { Highlight } from 'src/types/common';
import Carousel from './Carousel';

interface HighlightCarouselProps {
  highlights: Highlight[];
  highlight_ids: number[];
  id: string;
}

export default function HighlightCarousel({
  highlights,
  highlight_ids,
  id,
}: HighlightCarouselProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  // this lifted state is needed so that only one highlight plays at a time
  const [selectedHighlight, setSelectedHighlight] = useState<number>();

  useEffect(() => {
    setActiveIndex(0);
  }, [highlights]);

  const getHighlightList = () => {
    const indexedHighlights = [];
    for (const id of highlight_ids) {
      indexedHighlights.push(
        highlights.find((highlight) => highlight.id === id)
      );
    }

    return indexedHighlights.map((highlight) => {
      if (!highlight) return <></>;

      return (
        <Grid
          item
          paddingX={{ md: 3 }}
          key={`highlight-carousel-${highlight.id}`}
        >
          <HighlightContainer
            sectionId={`carousel-${id}`}
            highlight={highlight}
            onSelect={(id: number) => setSelectedHighlight(id)}
            selectedHighlightId={selectedHighlight}
            scrolling
            light
          />
        </Grid>
      );
    });
  };

  return (
    <Grid container>
      <Carousel
        content={getHighlightList()}
        compact={id === 'compact'}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
    </Grid>
  );
}
