import React from 'react';
import { Autocomplete, SxProps, TextField } from '@mui/material';

const ListEditor = React.forwardRef(
  (
    {
      initialOptions,
      allOptions,
      single,
      label,
      allowCustomValues,
      onChange,
      styleOverrides,
      testID,
    }: {
      initialOptions: string[];
      allOptions?: string[];
      single?: boolean;
      label?: string;
      allowCustomValues?: boolean;
      onChange?: (values: string[] | string) => void;
      styleOverrides?: SxProps;
      testID?: string;
    },
    ref
  ) => {
    const handleChange = (values: string[] | string) => {
      if (ref) {
        (ref as React.MutableRefObject<string[] | string>).current =
          values ?? [];
      }
      onChange && onChange(values);
    };
    return (
      <Autocomplete<string, true, true, boolean>
        sx={{
          marginY: '1rem',
          backgroundColor: '#FFF',
          padding: '0.5rem',
          ...styleOverrides,
        }}
        {...(!single && { multiple: true })}
        filterSelectedOptions
        freeSolo={allowCustomValues ?? true}
        options={allOptions ?? []}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : (option as any).toString()
        }
        defaultValue={initialOptions}
        onChange={(event, value) => {
          handleChange(value);
        }}
        data-testid={testID ?? 'list-editor'}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder="add more..."
            data-testid="list-editor-input"
            label={label}
          />
        )}
      />
    );
  }
);

export default ListEditor;
