import { EditType } from 'src/types/edit';
import { InsightsPage } from 'src/types/pages/insights';

export const INSIGHTS: InsightsPage = {
  page_id: 'insights',
  section_order: ['header', 'insight_cards'],
  header: {
    visible: true,
    content: {
      title: 'Insights',
      body: '<p>Insert text explaining the insights.</p>',
      header_type: 'full',
      texture: true,
    },
  },
  insight_cards: {
    visible: true,
    content: {
      cards: [],
      card_order: [],
    },
  },
  edit_metadata: {
    page_label: 'Insights',
    sections: [
      {
        section_label: 'Introduction',
        edit_blocks: [
          {
            block_label: 'Header',
            match: 'header.content',
            type: EditType.banner,
          },
        ],
      },
      {
        section_label: 'Insight Cards',
        sub_sections: [],
      },
    ],
  },
};
