import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
} from '@mui/material';

import appSelectors from 'src/redux/app/app-selectors';
import {
  draftSite,
  publishSite,
  updateInsights,
} from 'src/redux/app/app-slice';
import { useEditing } from 'src/utils/useEditing';
import CodesEditingTab from './tabs/CodesEditingTab';
import MapEditingTab from './tabs/MapEditingTab';
import PageEditingTab from './tabs/PageEditingTab';
import SiteEditingTab from './tabs/SiteEditingTab';

const EditingDrawer = () => {
  const { isEditing, updateEditState } = useEditing();
  const drawerWidth = isEditing ? '25%' : 0;
  const [currentTab, setCurrentTab] = React.useState(0);
  const [updateData, setUpdateData] = React.useState(false);
  const [publishing, setPublishing] = React.useState(false);
  const changesMade = useSelector(appSelectors.getChangesMade);
  const dispatch = useDispatch();
  const insightsDataTimestamp = useSelector(
    appSelectors.getInsightsDataTimestamp
  );
  const tabStyles = {
    color: '#aba7a3',
    '&.Mui-selected': {
      color: '#4F29B7',
      fontWeight: 500,
    },
  };
  const buttonStyle = { color: '#4F29B7', border: '1px solid #4F29B7' };

  const setEditingTab = (index: number) => {
    switch (index) {
      case 0:
        return <PageEditingTab />;
      case 1:
        return <SiteEditingTab />;
      case 2:
        return <CodesEditingTab />;
      case 3:
        return <MapEditingTab />;
      default:
        return <></>;
    }
  };

  const saveDraft = () => {
    dispatch(draftSite());
  };
  const publish = () => {
    dispatch(publishSite());
    setPublishing(false);
  };
  const insightsUpdate = () => {
    dispatch(updateInsights());
    setUpdateData(false);
  };

  return (
    <Drawer
      variant="persistent"
      open={isEditing}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar>
        <ButtonGroup>
          <Button onClick={() => setUpdateData(true)} sx={buttonStyle}>
            Update Data
          </Button>
          <Button disabled={!changesMade} onClick={saveDraft} sx={buttonStyle}>
            Save Draft
          </Button>
          <Button onClick={() => setPublishing(true)} sx={buttonStyle}>
            Publish
          </Button>
        </ButtonGroup>
        <div style={{ flexGrow: 1 }}>
          <IconButton
            onClick={() => updateEditState(false)}
            sx={{ float: 'right' }}
          >
            {<Close />}
          </IconButton>
        </div>
      </Toolbar>
      <Divider />
      <Box>
        <Tabs
          value={currentTab}
          onChange={(e, value) => setCurrentTab(value)}
          aria-label="edit settings tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Pages" sx={tabStyles} />
          <Tab
            label="Site Information"
            data-testid="site-information-tab"
            sx={tabStyles}
          />
          <Tab label="Codes" data-testid="codes-edit-tab" sx={tabStyles} />
          <Tab label="Map" data-testid="map-edit-tab" sx={tabStyles} />
        </Tabs>
        {setEditingTab(currentTab)}
      </Box>
      <Dialog open={publishing} onClose={() => setPublishing(false)}>
        <DialogTitle>
          Are you sure you want to publish these changes?
        </DialogTitle>
        <DialogContent>
          Publishing this will result to changes to the live site within the
          next 15 minutes.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => publish()}>Publish</Button>
          <Button onClick={() => setPublishing(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={updateData} onClose={() => setUpdateData(false)}>
        <DialogTitle>Are you sure you want to update data?</DialogTitle>
        <DialogContent>
          Updated data will be generated from insights within the next 15
          minutes. After 15 minutes, refresh the page to view the updated data
          in the current draft of the portal. Last updated:{' '}
          {insightsDataTimestamp}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => insightsUpdate()}>Update Data</Button>
          <Button onClick={() => setUpdateData(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Drawer>
  );
};

export default EditingDrawer;
