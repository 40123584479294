import React from 'react';
import { TextField } from '@mui/material';

const StringEditor = React.forwardRef(
  (
    {
      initialValue,
      onChange,
    }: { initialValue: string; onChange?: (value: string) => void },
    ref
  ) => {
    const handleChange = (value: string) => {
      if (ref) {
        (ref as React.MutableRefObject<string>).current = value;
      }
      onChange && onChange(value);
    };
    return (
      <div style={{ margin: '1rem 0' }}>
        <TextField
          multiline
          defaultValue={initialValue}
          data-testid="string-editor-input"
          onChange={(event) => handleChange(event.target.value)}
          sx={{ backgroundColor: '#FFF', width: '100%' }}
        />
      </div>
    );
  }
);

export default StringEditor;
