import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import { getBanner } from 'src/components/common/banners/utils';
import Callout from 'src/components/common/callouts/Callout';
import InfoCards from 'src/components/common/cards/InfoCards';
import HighlightShowcase from 'src/components/HighlightShowcase/HighlightShowcase';
import InsightsShowcaseContainer from 'src/components/InsightsShowcase/InsightsShowcaseContainer';
import MapShowcase from 'src/components/MapShowcase/MapShowcase';
import appSelectors from 'src/redux/app/app-selectors';
import { StoreState } from 'src/redux/store';
import { PageProps } from 'src/types/common';
import { HomePage } from 'src/types/pages/home';

function Landing({ page_data_id }: PageProps) {
  const pageInfo = useSelector((state: StoreState) =>
    appSelectors.getPage(state, page_data_id)
  ) as HomePage;
  const mapData = useSelector(appSelectors.getMap);
  if (!pageInfo) {
    return <></>;
  }

  return (
    <Grid container sx={{ mt: 0, width: '100%' }}>
      {pageInfo.header?.visible && (
        <div
          data-edit-id={`${pageInfo.page_id}.header`}
          style={{ width: '100%' }}
        >
          {getBanner(pageInfo.header.content)}
        </div>
      )}

      {pageInfo.insights_showcase?.visible && (
        <Grid
          container
          item
          data-edit-id={`${pageInfo.page_id}.insights_showcase`}
          sx={{ margin: '24px 0' }}
        >
          <Callout>
            <InsightsShowcaseContainer
              {...pageInfo.insights_showcase.content}
            />
          </Callout>
        </Grid>
      )}

      {pageInfo.highlights_showcase?.visible && (
        <Grid
          container
          item
          xs={12}
          data-edit-id={`${pageInfo.page_id}.highlights_showcase`}
          sx={{ margin: '24px 0' }}
        >
          <HighlightShowcase
            title={pageInfo.highlights_showcase.content.title}
            body={pageInfo.highlights_showcase.content.subtitle}
            optional_link={pageInfo.highlights_showcase.content.optional_link}
            tab_type={pageInfo.highlights_showcase.content.tab_type}
            tabs={pageInfo.highlights_showcase.content.tabs}
            {...pageInfo.highlights_showcase.content.style}
          />
        </Grid>
      )}
      {pageInfo.map_showcase?.visible && mapData && (
        <Grid
          container
          item
          xs={12}
          data-edit-id={`${pageInfo.page_id}.map_showcase`}
          sx={{ margin: '24px 0' }}
        >
          <Callout
            boxSx={{
              paddingBottom: '0 !important',
              marginBottom: '0 !important',
            }}
          >
            <MapShowcase
              map_data={mapData}
              overview={pageInfo.map_showcase.content.overview}
              location_cards={pageInfo.map_showcase.content.location_cards}
              locationType={pageInfo.map_showcase.content.location_type}
              zoomable={pageInfo.map_showcase.content.zoomable}
            />
          </Callout>
        </Grid>
      )}
      {pageInfo.navigation_cards?.visible && (
        <div
          data-edit-id={`${pageInfo.page_id}.navigation_cards`}
          style={{ width: '100%' }}
        >
          <InfoCards cards={pageInfo.navigation_cards.content.cards} />
        </div>
      )}
    </Grid>
  );
}

export default Landing;
