import { CustomRoute } from 'src/types/routes';

export const INITIAL_ROUTES: CustomRoute[] = [
  {
    visible: true,
    path: '/',
    page_data_id: 'home',
    label: 'Voices of Organization Name',
    in_nav: true,
    subroutes: [],
  },
  {
    visible: true,
    path: '/insights',
    page_data_id: 'insights',
    label: 'Insights',
    in_nav: true,
    subroutes: [],
  },
  {
    visible: true,
    path: '/voices',
    label: 'Voices',
    page_data_id: 'voices',
    in_nav: true,
    subroutes: [
      {
        visible: true,
        path: '/conversation-library',
        label: 'Conversation Library',
        in_nav: true,
        page_data_id: 'conversation-library',
      },
      {
        visible: true,
        path: '/highlight-explorer',
        label: 'Highlight Explorer',
        in_nav: true,
        page_data_id: 'highlight-explorer',
      },
    ],
  },
  {
    visible: true,
    path: '/about',
    label: 'About',
    page_data_id: 'about',
    in_nav: true,
    subroutes: [
      {
        visible: true,
        path: '/team',
        label: 'Our Team',
        in_nav: true,
        page_data_id: 'team',
      },
      {
        visible: true,
        path: '/process',
        label: 'Our Process',
        in_nav: true,
        page_data_id: 'process',
      },
    ],
  },
];
