import React, { useEffect } from 'react';
import * as d3 from 'd3';

import { SnippetMetadata } from 'src/types/common';

/**
 *
 * @param ref the ref for the containing div
 * @param highlights the available highlights
 * @returns - the needed functionality for making the snippets interactive
 */
export const useSnippetEvents = (
  ref: React.RefObject<HTMLDivElement>,
  updateActiveHighlight: (id?: number) => void
) => {
  const [tooltip, setTooltip] =
    React.useState<d3.Selection<HTMLDivElement, unknown, null, undefined>>();
  useEffect(() => {
    setTooltip(
      d3
        .select(ref.current)
        .append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0)
    );
  }, [ref]);

  // Highlight snippet and show tooltip when mouse over snippet (only highlights)
  const mouseoverSnippet = React.useCallback(
    (event: any, d: Partial<SnippetMetadata>) => {
      if (d.highlight_words && tooltip) {
        d3.select('#id' + d.id).classed('hover', true);
        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip
          .html(
            `${d.highlight_words.split(' ').slice(0, 20).join(' ') + '...'}`
          )
          .style('left', event.pageX + 'px')
          .style('top', event.pageY + 'px')
          .style('max-width', '400px');
      }
    },
    [tooltip]
  );

  // Remove highlight and hide tooltip when mouse leaves snippet
  const mouseleaveSnippet = React.useCallback(
    (event: any, d: Partial<SnippetMetadata>) => {
      d3.select('#id' + d.id).classed('hover', false);
      if (d.highlight_words && tooltip) {
        tooltip.transition().duration(200).style('opacity', 0);
      }
    },
    [tooltip]
  );

  // Snippet click callback, prepare modal with the correct embed and
  // shows it to the user.
  const snippetClick = React.useCallback(
    (event: any, d: SnippetMetadata) => {
      if (d.highlight_id) {
        mouseleaveSnippet(event, d);
        updateActiveHighlight(d.highlight_id);
      }
    },
    [updateActiveHighlight, mouseleaveSnippet]
  );

  return React.useMemo(
    () => ({
      mouseoverSnippet,
      mouseleaveSnippet,
      snippetClick,
    }),
    [mouseleaveSnippet, mouseoverSnippet, snippetClick]
  );
};
