import React, { useEffect, useMemo, useState } from 'react';
import JsxParser from 'react-jsx-parser';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import StyledBackground from 'src/components/common/background/StyledBackground';
import CardTabs from 'src/components/common/tabs/CardTabs';
import appSelectors from 'src/redux/app/app-selectors';
import {
  Highlight,
  HighlightShowcaseTab,
  OptionalLink,
  SectionStyle,
  TabType,
} from 'src/types/common';
import LinkButton from '../common/buttons/LinkButton';
import CustomTabs from '../common/tabs/CustomTabs';
import HighlightInfo from './HighlightInfo';
import HighlightInfoCompact from './HighlightInfoCompact';

interface HighlightShowcaseProps extends SectionStyle {
  title: string;
  body: string;
  optional_link?: OptionalLink;
  tab_type: TabType;
  tabs: HighlightShowcaseTab[];
}

function HighlightShowcase({
  title,
  body,
  optional_link,
  tab_type,
  tabs,
  texture,
  secondary,
  image: image,
}: HighlightShowcaseProps) {
  const highlights = useSelector(appSelectors.getHighlights);
  const isExtraSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  const keys = tabs.map(({ label }) => ({ label }));
  const [selectedTab, setSelectedTab] = useState(0);

  // Need to pass through event
  const handleChange = (tabIndex: number) => {
    setSelectedTab(tabIndex);
  };

  const visible_tab: HighlightShowcaseTab | undefined = useMemo(() => {
    return tabs ? tabs[selectedTab] : undefined;
  }, [tabs, selectedTab]);

  const HighlightLinkButton: JSX.Element = useMemo(() => {
    return visible_tab?.link.visible ? (
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}
      >
        <LinkButton link={visible_tab?.link.to}>
          {visible_tab?.link.label}
        </LinkButton>
      </div>
    ) : (
      <></>
    );
  }, [visible_tab]);

  const [featured, setFeatured] = useState<Highlight[]>();
  useEffect(() => {
    if (visible_tab) {
      setFeatured(
        highlights.filter((h) => visible_tab.highlight_ids.includes(h.id))
      );
    }
  }, [visible_tab, selectedTab, highlights]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          item
          xs={8}
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingBottom: '2rem',
          }}
        >
          <Typography
            textAlign="center"
            variant="h5"
            sx={{ color: 'primary.dark' }}
          >
            {title}
          </Typography>
          <Typography
            textAlign="center"
            variant="body1"
            component="span"
            sx={{
              color: 'primary.dark',
              fontWeight: '500',
              fontSize: '20px',
            }}
          >
            {body && (
              <>
                {/* @ts-ignore - ignore */}
                <JsxParser jsx={body} />
                <br />
              </>
            )}
          </Typography>
          {optional_link?.visible && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '2rem',
              }}
            >
              <LinkButton link={optional_link.to}>
                {optional_link.label}
              </LinkButton>
            </div>
          )}
        </Grid>
      </Grid>

      {tab_type === 'tab' && keys.length > 0 && !isExtraSmallScreen && (
        <CustomTabs
          items={keys}
          selected_index={selectedTab}
          onChange={handleChange}
        />
      )}

      <StyledBackground texture={texture} secondary={secondary} image={image}>
        <Grid
          item
          xs={12}
          paddingTop={1}
          sx={{
            display: { xs: 'none', md: 'block' },
            marginTop: tab_type === 'floating' ? '-5rem' : '0',
            width: '100%',
          }}
        >
          {tab_type === 'floating' && keys.length > 0 && (
            <CardTabs
              items={keys}
              selectedIdx={selectedTab}
              onClick={handleChange}
            />
          )}
          {visible_tab && (
            <HighlightInfo
              highlightLinkButton={HighlightLinkButton}
              highlights={featured}
              highlight_ids={visible_tab.highlight_ids}
              body={visible_tab.body}
            />
          )}
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' } }}>
          {keys.length > 0 && visible_tab && (
            <HighlightInfoCompact
              tabs={keys}
              selectedTopic={visible_tab.label}
              handleChange={(label) =>
                handleChange(tabs.findIndex((tab) => tab.label === label))
              }
              highlights={featured}
              highlight_ids={visible_tab.highlight_ids}
              highlightLinkButton={HighlightLinkButton}
              body={visible_tab.body}
            />
          )}
        </Grid>
      </StyledBackground>
    </Grid>
  );
}

export default HighlightShowcase;
