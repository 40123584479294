import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/material';
import { cloneDeep, set } from 'lodash';

import appSelectors from 'src/redux/app/app-selectors';
import { updateCodes } from 'src/redux/app/app-slice';
import { StoreState } from 'src/redux/store';
import { EditBlock, EditSection, EditType } from 'src/types/edit';
import CollapsableSection from '../CollapsableSection';
import EditSectionModal from '../EditSectionModal';

const CodesEditingTab = () => {
  const codes = useSelector((state: StoreState) =>
    appSelectors.getCodes(state)
  );
  const dispatch = useDispatch();
  const codesEditSection: EditSection[] = React.useMemo(
    () => [
      {
        section_label: 'Analytic Codes',
        edit_header:
          'Edit the Label and Colors associated with Analytic Codes.',
        edit_blocks: codes.analytic.map((code, index) => ({
          value: code,
          match: `analytic[${index}]`,
          type: EditType.code,
          block_label: code.display_name,
        })),
      },
      {
        section_label: 'Demographics',
        edit_header: 'Edit the Label and Colors associated with Demographics.',
        edit_blocks: codes.demographic.map((code, index) => ({
          value: code,
          match: `demographic[${index}]`,
          type: EditType.demographic,
          block_label: code.display_name,
        })),
      },
    ],
    [codes]
  );
  const [codeSection, setCodeSection] = React.useState<EditSection>();

  const onCloseCodes = () => {
    setCodeSection(undefined);
  };
  const onSaveCodes = (blocks: EditBlock[]) => {
    if (codes) {
      const newCodes = cloneDeep(codes);
      blocks.forEach((block) => {
        set(newCodes, block.match, block.value);
      });
      dispatch(updateCodes(newCodes));
    }
    onCloseCodes();
  };

  return (
    <>
      <List data-testid="edit-codes-and-filters-list">
        {codesEditSection.map((section) => {
          return (
            <CollapsableSection
              key={section.section_label}
              pageLabel={section.section_label}
              section={section}
              onSectionClick={(section) => setCodeSection(section)}
            />
          );
        })}
      </List>
      <EditSectionModal
        label={codeSection?.edit_header}
        blocks={codeSection?.edit_blocks ?? []}
        onSave={onSaveCodes}
        onClose={onCloseCodes}
      />
    </>
  );
};
export default CodesEditingTab;
