import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import appSelectors from 'src/redux/app/app-selectors';
import { setEditing } from 'src/redux/app/app-slice';

export const useEditing = () => {
  const isEditing = useSelector(appSelectors.getEditing);
  const canEdit = useSelector(appSelectors.getCanEdit);
  const dispatch = useDispatch();

  const updateEditState = React.useCallback(
    (value: boolean) => {
      canEdit && dispatch(setEditing(value));
    },
    [canEdit, dispatch]
  );

  return React.useMemo(
    () => ({
      canEdit,
      isEditing,
      updateEditState,
    }),
    [canEdit, isEditing, updateEditState]
  );
};
