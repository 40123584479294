import { EditType } from 'src/types/edit';
import { FilterOrder } from 'src/types/filters';
import { HighlightsPage } from 'src/types/pages/voices';

export const HIGHLIGHT_EXPLORER: HighlightsPage = {
  page_id: 'highlight-explorer',
  section_order: [],
  header: {
    visible: true,
    content: {
      title: 'Voices of FMCS',
      header_type: 'thin',
      body: '',
    },
  },
  filters: {
    visible: true,
    content: {
      filter_order: {} as FilterOrder,
    },
  },
  map: {
    visible: true,
    content: {
      zoomable: false,
      location_type: 'Location',
    },
  },
  edit_metadata: {
    page_label: 'Highlight Explorer',
    subroute: true,
    sections: [
      {
        section_label: 'Introduction',
        edit_blocks: [
          {
            block_label: 'Enable zoom on this map?',
            match: 'map.content.zoomable',
            type: EditType.checkbox,
          },
          {
            block_label: 'Location Type',
            match: 'map.content.location_type',
            type: EditType.string,
          },
          {
            block_label: 'Header',
            match: 'header.content',
            type: EditType.banner,
          },
        ],
      },
      {
        section_label: 'Filters',
        edit_blocks: [
          {
            block_label: 'Filter Order',
            type: EditType.filter_order,
            match: 'filters.content.filter_order',
          },
        ],
      },
    ],
  },
};
