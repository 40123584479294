import React from 'react';
import { useMediaQuery } from '@mui/material';
import * as d3 from 'd3';
import { debounce } from 'lodash';

import {
  ConversationMetadata,
  ConversationVisualizationMetadata,
} from 'src/types/common';

const PADDING_Y = 20;
const PADDING_X = 20;
const FOOTER_HEIGHT = 80;

export const useConversationLibrarySizing = (
  ref: React.RefObject<HTMLDivElement>,
  conversations: ConversationMetadata[]
) => {
  const isMobileScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  const [libraryHeight, setLibraryHeight] = React.useState<number>(0);
  const [libraryWidth, setLibraryWidth] = React.useState<number>(
    Math.min(window.innerWidth - PADDING_X)
  );
  const [numberOfColumns, setNumberOfColumns] = React.useState<number>(1);
  const [processedConversations, setProcessedConversations] = React.useState<
    ConversationVisualizationMetadata[]
  >([]);

  React.useEffect(() => {
    if (ref?.current) {
      const tagContainerHeight = (
        d3.select('div.tag-container').node() as HTMLElement
      )?.getBoundingClientRect().height;
      const initialHeight =
        window.innerHeight -
        ref.current?.getBoundingClientRect().top -
        FOOTER_HEIGHT - //Footer size
        tagContainerHeight -
        PADDING_Y; //Top padding

      const { processed, numberOfColumns, height } = processConversations(
        conversations,
        libraryWidth,
        initialHeight,
        isMobileScreen
      );
      setLibraryHeight(height);
      setProcessedConversations(processed);
      setNumberOfColumns(numberOfColumns);
    }
  }, [conversations, isMobileScreen, libraryWidth, ref]);

  React.useEffect(() => {
    const resizeEvent = debounce(() => {
      setLibraryWidth(window.innerWidth - PADDING_X);
    }, 100);
    if (window) {
      window.addEventListener('resize', resizeEvent);
    }

    return () => window.removeEventListener('resize', resizeEvent);
  }, []);
  return React.useMemo(
    () => ({
      libraryHeight,
      libraryWidth,
      numberOfColumns,
      processedConversations,
      isMobileScreen,
    }),
    [
      libraryHeight,
      libraryWidth,
      numberOfColumns,
      processedConversations,
      isMobileScreen,
    ]
  );
};

const processConversations = (
  conversations: ConversationMetadata[],
  width: number,
  initialHeight: number,
  isMobileScreen: boolean
) => {
  let height = initialHeight;
  let optimized = false;
  let numberOfColumns = 1;
  let conversationWidth =
    (width - 5 * numberOfColumns) / numberOfColumns -
    (numberOfColumns === 1 ? 100 : 0);
  let snippetHeight = 9;
  const conversationPadding = 5;
  const verticalSnippetPadding = 1;
  const textSpaceWidth = 10; // 40 with text
  let processed: ConversationVisualizationMetadata[] = [];
  while (!optimized) {
    let startY = 5;
    let startX = 5 + (numberOfColumns === 1 ? 50 : 0);
    processed = [...conversations].map((conversation) => {
      const conversationHeight =
        snippetHeight * conversation.speaker_count +
        2 * conversationPadding +
        verticalSnippetPadding * (conversation.speaker_count - 1);

      let nexty = startY + conversationHeight + 10;
      if (nexty > height - 30 && !isMobileScreen) {
        // If the next y is outside the height of the library, move to the next column
        startY = 5;
        nexty = 10 + conversationHeight + 10;
        startX += conversationWidth + 10 + (numberOfColumns === 1 ? 50 : 0);
      }

      // Find the starting x and y values of the zoom overlay.
      // This will center the conversation in it's corresponding overlay
      const conversation_zoom_x = startX - (width - conversationWidth) / 2;
      const conversation_zoom_y = startY - (height - conversationHeight) / 2;

      // Create the new entry
      const newEntry = {
        ...conversation,
        conversation_starting_x: startX,
        conversation_starting_y: startY,
        conversation_width: conversationWidth,
        conversation_height: conversationHeight,
        conversation_padding: conversationPadding,
        snippet_height: snippetHeight,
        snippet_padding: verticalSnippetPadding,
        text_width: textSpaceWidth,
        conversation_zoom_x,
        conversation_zoom_y,
      };
      // Increment the Y value
      startY = nexty;
      return newEntry;
    });
    // check for breaking condition
    const lastItem = processed[processed.length - 1];
    const finalX = lastItem.conversation_starting_x;
    if (finalX < width) {
      // The conversation visualization fits within the svg
      optimized = true;
    }

    if (finalX > width) {
      // The conversation visualization is off bottom of svg.
      // 100 is an arbitrary value
      if (numberOfColumns < 100) {
        // try incrementing number of columns
        numberOfColumns = numberOfColumns + 1;
        conversationWidth =
          (width - 5 * numberOfColumns - 2 * conversationPadding) /
          numberOfColumns;
      } else if (snippetHeight < 5) {
        // then try decreasing snippet height
        snippetHeight = snippetHeight - 1;
      } else {
        // abort
        optimized = true;
      }
    }
    if (isMobileScreen) {
      height =
        lastItem.conversation_starting_y +
        lastItem.conversation_height +
        PADDING_Y;
    }
  }
  return { processed, numberOfColumns, height };
};
