import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Grid, IconButton, TextField, Tooltip } from '@mui/material';

import { LinkItem } from 'src/types/common';
import ListEditor from './ListEditor';

const LinkEditor = React.forwardRef(
  (
    {
      link,
      potentialPaths,
      optionalChangeHandler,
    }: {
      link: LinkItem;
      potentialPaths: string[];
      optionalChangeHandler?: (value: string, match: string) => void;
    },
    ref
  ) => {
    const centerStyle = {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginY: '1rem',
    };

    const handleChangeWithRef = (value: string, type: 'label' | 'to') => {
      if (type === 'to' && !value) {
        // value is null
        value = '/';
      }
      if (ref && value) {
        // this ternary is needed in the case that the user updates both the label AND "to" field
        // grab the ref's current value if it exists, or the input link prop otherwise
        const newLink = (ref as React.MutableRefObject<LinkItem>).current
          ? { ...(ref as React.MutableRefObject<LinkItem>).current }
          : { ...link };
        switch (type) {
          case 'label':
            newLink.label = value;
            break;
          default:
            newLink.to = value;
            break;
        }
        (ref as React.MutableRefObject<LinkItem>).current = newLink;
      }
    };
    return (
      <Grid container>
        <Grid item xs={12} sx={centerStyle}>
          <TextField
            data-testid="link-editor"
            defaultValue={link.label}
            multiline
            label={'label'}
            onChange={(event) =>
              optionalChangeHandler
                ? optionalChangeHandler(event.target.value, 'label')
                : handleChangeWithRef(event.target.value, 'label')
            }
            sx={{ backgroundColor: '#FFF', width: '100%' }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ListEditor
            data-testid="list-editor"
            initialOptions={[link.to]}
            allOptions={potentialPaths}
            allowCustomValues={true}
            single
            label="URL"
            onChange={(value) => {
              optionalChangeHandler
                ? optionalChangeHandler(value as string, 'to')
                : handleChangeWithRef(value as string, 'to');
            }}
            styleOverrides={{ width: '100%' }}
          />
          <Tooltip
            title="Internal links must begin with '/'. Before saving you must click the corresponding link in the dropdown or click enter."
            sx={{ height: 'auto' }}
          >
            <IconButton sx={{ marginLeft: '0.25rem' }}>
              <HelpOutlineIcon></HelpOutlineIcon>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }
);

export default LinkEditor;
