import { get } from 'lodash';

export const SIZES = {
  12: 0,
  14: 1,
  16: 2,
  18: 3,
  20: 4,
  24: 5,
  28: 6,
  32: 7,
  36: 8,
  40: 9,
};

export const remToNum = (value?: string) => {
  return value ? parseInt(value.replace('rem', '')) : 0;
};

export const pxToNum = (value?: string) => {
  return value ? parseInt(value.replace('px', '')) : 0;
};

export const getFontSize = (value: number) => {
  const newFontSize = Object.keys(SIZES).find(
    (size) => get(SIZES, size) === value
  );
  return parseInt(newFontSize ?? '');
};

export const calculateScaledFontSize = (value?: number) => {
  return value ? get(SIZES, value) : 0;
};
