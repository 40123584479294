import React, { useEffect } from 'react';
import * as d3 from 'd3';

import { getMatchingParentCode } from 'src/setup/code';
import { Code } from 'src/types/common';

export const useInteractiveLibraryEvents = (
  codes: Code[],
  svgWidth: number,
  svgHeight: number,
  isMobile: boolean
) => {
  const [activeButtons, updateActiveButtons] = React.useState<number[]>([]);
  const [hoverButtons, updateHoverButtons] = React.useState<number[]>([]);
  // Draw line connection from all active (pushed) tag buttons and
  // any hovered tag button to snippets with those tags.
  const drawConnections = React.useCallback(() => {
    const link = d3.linkHorizontal();
    d3.selectAll('.line').remove();
    const conversationsContainer = d3.select('g.conversations_container');
    if (activeButtons.length === 0 && hoverButtons.length === 0) return;
    let connectionClass = '';
    const codesToConnect = activeButtons
      .concat(hoverButtons)
      .map((value) => getMatchingParentCode(value, codes))
      .filter((code) => code) as Code[];
    codesToConnect.forEach(({ id }) => {
      connectionClass += '._' + id;
    });

    if (connectionClass) {
      d3.selectAll(connectionClass + '.snippet').each(function () {
        const x = Number(d3.select(this).attr('x'));
        const y = Number(d3.select(this).attr('y'));
        const dx = Number(d3.select(this).attr('width')) / 2;
        const dy = Number(d3.select(this).attr('height')) / 2;

        codesToConnect.forEach(({ conversation_library_colors, id }) => {
          const indexOfParentCode = codes.findIndex((code) => code.id === id);
          let codeColor = 'none';
          codeColor = conversation_library_colors.background_color;
          const tagWidth = svgWidth / codes.length;

          const data: d3.DefaultLinkObject = {
            source: [
              tagWidth * indexOfParentCode + tagWidth / 2,
              isMobile ? 0 : svgHeight,
            ],
            target: [x + dx, y + dy],
          };

          conversationsContainer
            .append('path')
            .attr('d', link(data))
            .attr('class', 'line')
            .style('stroke-width', 0.4)
            .attr('stroke', codeColor)
            .attr('fill', 'none');
        });
      });
    }
  }, [activeButtons, hoverButtons, isMobile, svgHeight, svgWidth, codes]);

  // Highlight all active snippets via full opacity vs 0.5 for the rest
  const highlightSnippets = React.useCallback(() => {
    d3.selectAll('.snippet').style('opacity', 0.5);
    d3.selectAll('.snippet.hasTag').style('fill', '#767676');
    let connectionClass = '.snippet';
    activeButtons.forEach((id) => {
      connectionClass += '._' + id;
    });
    if (activeButtons.length > 0) {
      d3.selectAll(connectionClass)
        .style('opacity', 1)
        .style('fill', '#000000') // Darker Color so it is easier to note
        .classed('highlight', true);
    }
    if (activeButtons.length === 0) {
      d3.selectAll('.snippet').style('opacity', 1);
    }
  }, [activeButtons]);

  // Enable and highlight tag buttons that have snippets with tags of
  // the active buttons + extra.
  // Basically disables tag buttons that doesn't share snippets with
  // active tags.
  const highlightComboButtons = React.useCallback(() => {
    if (!activeButtons.length) {
      d3.selectAll('.tag-button').property('disabled', false);
      d3.selectAll('.tag-button').classed('disabled', false);
      return;
    }
    let connectionClass = '';
    activeButtons.forEach((id) => {
      connectionClass += '._' + id;
    });
    d3.selectAll('.tag-button').classed('disabled', true);
    d3.selectAll('.tag-button').property('disabled', true);
    codes.forEach(({ id }) => {
      if (
        activeButtons.includes(id) ||
        !d3.select(connectionClass + '._' + id).empty()
      ) {
        d3.select('#_' + id).classed('disabled', false);
        d3.select('#_' + id).property('disabled', false);
      }
    });
  }, [activeButtons, codes]);

  useEffect(() => {
    drawConnections();
    highlightSnippets();
    highlightComboButtons();
  }, [drawConnections, highlightComboButtons, highlightSnippets]);

  const updateButtons = React.useCallback(
    ({ activeId, hoverId }: { activeId?: number; hoverId?: number }) => {
      if (activeId) {
        if (activeButtons.includes(activeId)) {
          updateActiveButtons((arr) => {
            const index = arr.indexOf(activeId);
            if (index > -1) {
              arr.splice(index, 1);
            }
            return [...arr];
          });
        } else {
          updateActiveButtons((arr) => [...arr, activeId]);
        }
      }
      if (hoverId) {
        if (hoverButtons.includes(hoverId)) {
          updateHoverButtons((arr) => {
            const index = arr.indexOf(hoverId);
            if (index > -1) {
              arr.splice(index, 1);
            }
            return [...arr];
          });
        } else {
          updateHoverButtons((arr) => [...arr, hoverId]);
        }
      }
    },
    [activeButtons, hoverButtons]
  );

  return React.useMemo(
    () => ({ updateButtons, activeButtons, hoverButtons }),
    [activeButtons, hoverButtons, updateButtons]
  );
};
