import React from 'react';
import { Grid, Slider, Typography } from '@mui/material';

import { CustomTypography } from 'src/types/organization_metadata';
import {
  calculateScaledFontSize,
  getFontSize,
  pxToNum,
  remToNum,
} from './utils/typographyUtils';

const TypographyEditor = React.forwardRef(
  ({ initialTypography }: { initialTypography: CustomTypography }, ref) => {
    const centerStyle = {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginY: '1rem',
    };
    const [typography, setTypography] =
      React.useState<CustomTypography>(initialTypography);
    const handleChange = (value: number, type: keyof CustomTypography) => {
      const newTypography = { ...typography };
      switch (type) {
        case 'fontSize':
          newTypography.fontSize = getFontSize(value);
          break;
        case 'fontWeight':
          newTypography.fontWeight = value;
          break;
        case 'letterSpacing':
          newTypography.letterSpacing = `${value}px`;
          break;
        case 'paddingBottom':
          newTypography.paddingBottom = `${value}rem`;
          break;
      }
      if (ref) {
        (ref as React.MutableRefObject<CustomTypography>).current =
          newTypography;
      }
      setTypography(newTypography);
    };
    return (
      <Grid container spacing={6}>
        <Grid item xs={6} sx={centerStyle}>
          <Typography variant="body1">Font Size</Typography>
          <Slider
            aria-label="Font Size"
            value={calculateScaledFontSize(typography.fontSize)}
            scale={getFontSize}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={9}
            onChange={(e, v) => handleChange(v as number, 'fontSize')}
          />
        </Grid>
        <Grid item xs={6} sx={centerStyle}>
          <Typography variant="body1">Font Weight</Typography>
          <Slider
            aria-label="Font Weight"
            value={typography.fontWeight}
            valueLabelDisplay="auto"
            step={100}
            marks
            min={50}
            max={800}
            onChange={(e, v) => handleChange(v as number, 'fontWeight')}
          />
        </Grid>
        <Grid item xs={6} sx={centerStyle}>
          <Typography variant="body1">Letter Spacing</Typography>
          <Slider
            aria-label="Letter Spacing"
            value={pxToNum(typography.letterSpacing)}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={1}
            onChange={(e, v) => handleChange(v as number, 'letterSpacing')}
          />
        </Grid>
        <Grid item xs={6} sx={centerStyle}>
          <Typography variant="body1">Padding Bottom</Typography>
          <Slider
            aria-label="Padding Bottom"
            value={remToNum(typography.paddingBottom)}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={2}
            onChange={(e, v) => handleChange(v as number, 'paddingBottom')}
          />
        </Grid>
      </Grid>
    );
  }
);

export default TypographyEditor;
