import { useMemo, useState } from 'react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { List } from '@mui/material';
import { cloneDeep, isEqual } from 'lodash';

import { updateDemographicCodes, updateMap } from 'src/redux/app/app-slice';
import { EditBlock, EditSection, EditType } from 'src/types/edit';
import CollapsableSection from '../CollapsableSection';
import EditSectionModal from '../EditSectionModal';
import { InitialMapItems, MapUpdate } from '../utils/mapUtils';
import { useInitialMapItems } from '../utils/useInitialMapItems';

const MapEditingTab = () => {
  const dispatch = useDispatch();
  const initialMapItems: InitialMapItems = useInitialMapItems();
  const [editSection, setEditSection] = useState<EditSection>();
  const modifiedBlocks = useMemo(() => {
    if (!editSection) {
      return [];
    }
    return (
      editSection?.edit_blocks?.map((block) => {
        return {
          ...block,
        };
      }) ?? []
    );
  }, [editSection]);

  const onClose = () => {
    setEditSection(undefined);
  };

  const onSave = (blocks: EditBlock[]) => {
    if (editSection) {
      const mapUpdateObj: MapUpdate = cloneDeep(
        blocks.find((block) => block.type === EditType.map)?.value as MapUpdate
      );
      // only update the map/demographic codes if they've changed
      if (
        !isEqual(
          mapUpdateObj.demographicCodes,
          initialMapItems.demographicCodes
        )
      ) {
        // when a demographic color is changed, it changes the mapItems that refer to it as well
        dispatch(updateMap(mapUpdateObj));
        dispatch(updateDemographicCodes(mapUpdateObj.demographicCodes));
      } else if (!isEqual(mapUpdateObj.mapItems, initialMapItems.mapItems)) {
        dispatch(updateMap(mapUpdateObj));
      }
    }
    onClose();
  };

  return (
    <div>
      <List>
        <CollapsableSection
          key={'Edit Map'}
          pageLabel={'Edit Map'}
          section={{
            section_label: 'Edit Map',
            edit_blocks: [
              {
                block_label: 'Map',
                match: 'map',
                type: EditType.map,
                value: initialMapItems,
              },
            ],
          }}
          onSectionClick={(section) => setEditSection(section)}
        />
      </List>
      <EditSectionModal
        label={editSection?.edit_header}
        blocks={modifiedBlocks}
        onSave={onSave}
        onClose={onClose}
      />
    </div>
  );
};
export default MapEditingTab;
