import React, { useEffect, useState } from 'react';
import { ColorResult } from 'react-color';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { cloneDeep } from 'lodash';
import { useDebounce } from 'usehooks-ts';

import { Code } from 'src/types/common';
import ColorEditor from '../ColorEditor';

const DemographicList = ({
  initialDemographicCodes,
  trimMode,
  onDemographicCodeChanged,
}: {
  initialDemographicCodes: Code[];
  trimMode: boolean;
  onDemographicCodeChanged: (code: Code) => void;
}) => {
  const [demographicCodes, setDemographicCodes] = useState(
    initialDemographicCodes
  );
  const [selectedCode, setSelectedCode] = useState(demographicCodes[0]);
  const [updatedCode, setUpdatedCode] = useState(selectedCode);

  // Debounce the updated code when the color is changed so we aren't re-painting the map with every color change
  const debouncedUpdatedCode = useDebounce(updatedCode, 100);
  useEffect(() => {
    onDemographicCodeChanged(debouncedUpdatedCode);
  }, [debouncedUpdatedCode, onDemographicCodeChanged]);

  /**
   * Called when the user clicks on a demographic code.
   * @param code Selected demographic code
   */
  const handleDemographicCodeClick = (code: Code) => {
    setSelectedCode(code);
    onDemographicCodeChanged(code);
  };

  /**
   * Sets the current color
   * @param currentCode Currently selected code
   * @param color Currently selected color from the ColorEditor
   */
  const handleColorChange = (currentCode: Code, color: string): void => {
    const updatedCode: Code = cloneDeep(currentCode);
    updatedCode.embed_colors.background_color = color;
    setDemographicCodes((codes) =>
      codes.map((code: Code) => {
        return code.id === currentCode.id ? updatedCode : code;
      })
    );
    setUpdatedCode(updatedCode);
  };

  return (
    <List
      component="nav"
      aria-label="main demographic list"
      data-testid="demographic-codes-list"
    >
      {demographicCodes.map((code: Code) => {
        return (
          <ListItemButton
            key={code.id}
            selected={selectedCode.id === code.id}
            onClick={() => handleDemographicCodeClick(code)}
            disabled={trimMode}
          >
            <ListItemText primary={code.display_name} />
            {selectedCode.id === code.id && (
              <ColorEditor
                label=""
                color={code.embed_colors.background_color}
                onColorChange={(color: ColorResult) =>
                  handleColorChange(code, color.hex)
                }
              ></ColorEditor>
            )}
          </ListItemButton>
        );
      })}
    </List>
  );
};

export default DemographicList;
