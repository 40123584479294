import React from 'react';
import { Dialog, DialogContent } from '@mui/material';

import { Highlight } from 'src/types/common';
import HighlightContainer from '../Audio/Highlight/Highlight';

interface ConversationModalProps {
  onClose: (event: any, reason: 'backdropClick' | 'escapeKeyDown') => void;
  highlight?: Highlight;
}

function ConversationModal({ onClose, highlight }: ConversationModalProps) {
  return (
    <Dialog onClose={onClose} open={highlight !== undefined}>
      <DialogContent>
        {highlight && (
          <HighlightContainer
            highlight={highlight}
            sectionId="conversation-visualization"
            scrolling
            extend
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default ConversationModal;
