import convert from 'color-convert';

import { FilterGroup, FilterOption } from 'src/types/filters';
/**
 * Valid query args for the map view.
 */
export const MAP_QUERY_ARGS = {
  location: 'location',
  hideMap: 'hideMap',
  topic: 'topic',
  prompt: 'prompt',
  tenure: 'tenure',
};

/**
 * List of all available fonts for the site
 */
export const FONTS: string[] = [
  'Lato',
  'Merriweather',
  'Montserrat',
  'Open Sans',
  'Oswald',
  'Roboto',
  'Signika',
];

/**
 * Convenience function for getting query args.
 **/
export const getQueryArg = (param: string) => {
  const slugParam = slugify(param);
  const queryString: string = window.location.search;
  // need to cut off the editing prefix (when in editing mode) for proper param parsing
  const queryArg = new URLSearchParams(queryString).get(slugParam);
  return queryArg ?? undefined;
};

/**
 * Convenience function for making query args from filters.
 **/
export const makeFilterQueryArgs = (
  filters: FilterGroup[],
  activeFilters: FilterOption[]
): { [key: string]: string } => {
  // Initialize new search url
  const newSearchParams: { [key: string]: string } = {};

  filters.map((parent) => {
    const searchValues: string[] = [];
    parent.options.map((option) => {
      const matchingChild = activeFilters.find(
        (active) => active.label === option.label
      );
      if (matchingChild) {
        searchValues.push(slugify(option.label));
      }
    });
    if (searchValues.length) {
      newSearchParams[slugify(parent.label)] = searchValues.join(',');
    }
  });
  return newSearchParams;
};

const padTimeLeft = (time: number, pad: string, length: number) => {
  return (new Array(length + 1).join(pad) + time).slice(-length);
};

/**
 * Returns a formatted time in MM:SS.
 */
export const formatDuration = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainder = Math.round(seconds - minutes * 60.0);
  return `${padTimeLeft(minutes, '0', 2)}:${padTimeLeft(remainder, '0', 2)}`;
};

/**
 * Returns the speaker for a highlight
 */
export const getSpeaker = (speakers: any, highlight: any) => {
  const { conversation_id, speaker } = highlight;
  return speakers.find(
    (s: any) => s.conversation_id === conversation_id && speaker === s.name
  );
};

/**
 * Returns the speaker image source or undefined.
 */
export const getSpeakerImgSrc = (speakers: any, highlight: any) => {
  const speaker = getSpeaker(speakers, highlight);
  let src;
  if (speaker) {
    src = `${process.env.PUBLIC_URL}/avatars/${getSpeakerPath(
      speaker.name
    )}.jpg`;
  }

  return src;
};

export const getSpeakerPath = (speakerName: string) => {
  const regex = new RegExp(' ', 'g');
  return speakerName.replace(regex, '-');
};

/**
 * Returns an array of the speaker locations. The first element
 * is the primary location.
 */
export const getSpeakerInfo = (
  demographic: string,
  speakers: any,
  highlight: any
) => {
  const speaker = getSpeaker(speakers, highlight);
  if (speaker) {
    const { demographics } = speaker;

    // TODO: New speaker pipeline returns an array.
    // Use .join(", ") in the future and return "Undisclosed" when not found
    const info = demographics[demographic];
    if (info) {
      return getCamelCase(info);
    }
  }
  return null;
};

export const getCamelCase = (str: string) => {
  // TODO: Remove .toString() when convert to new pipeline format
  const parts = str.toString().split(' ');
  return parts.map((part) => part[0].toUpperCase() + part.slice(1)).join(' ');
};

export const getParentTagFromTagString = (tag: string) => {
  const parts = tag.split(' :: ');
  return parts[0].trim();
};

export const isColorLight = (hexColor: string) => {
  // determine if the color is light or dark
  // from https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
  const red = parseInt(`${hexColor[1]}${hexColor[2]}`, 16);
  const green = parseInt(`${hexColor[3]}${hexColor[4]}`, 16);
  const blue = parseInt(`${hexColor[5]}${hexColor[6]}`, 16);
  return red * 0.299 + green * 0.587 + blue * 0.114 > 186;
};

/**
 * Takes in a css string formatted in rgb(a) format [rgba(0, 255, 255, 0.3)] and returns the hex format [#000001]
 * https://stackoverflow.com/a/3627747
 * @param rgba Input css string to convert
 */
export const rgbaToHex = (rgba: string): string => {
  //@ts-ignore -ignore
  return `#${rgba
    .match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/)
    .slice(1)
    .map((n, i) =>
      (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n))
        .toString(16)
        .padStart(2, '0')
        .replace('NaN', '')
    )
    .join('')
    .toLocaleUpperCase()}`;
};

/**
 * Opposite of rgbaToHex; takes in a string in hex format and returns the rgb-formatted value
 * @param hex hex value to convert
 */
export const hexToRgb = (hex: string): string => {
  return `rgb(${parseInt(hex.slice(1, 3), 16)}, ${parseInt(
    hex.slice(3, 5),
    16
  )}, ${parseInt(hex.slice(5), 16)})`;
};

/**
 * Takes in a hex color and converts it to its' equivalent grayscale hex value
 * @param hex Input hex value to grayscale
 */
export const hexToGrayscale = (hex: string): string => {
  const gray = convert.hex.gray(hex);
  return `#${convert.gray.hex(gray)}`;
};

/**
 * Function to slugify any given string. Often used to create ids. This will remove any special keys from the string,
 * and turn it into a "dashified" (aka. slugified) string. IE. "Me & You" -> "me-you"
 * @param input - string to slugify
 * @returns sluggified string
 */
export function slugify(input: string) {
  return (
    input
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      ?.map((x: string, index: number) =>
        /* 
        This weird isNaN thing is used to handle the case where the input string begins with a number (e.g. '0-5 years').
        Valid CSS selectors cannot begin with a numeric, so here we're arbitrarily prepending an "a" onto the first character
        */
        !isNaN(+x) && index === 0 ? 'a' + x : x.toLowerCase()
      )
      .join('-') ?? ''
  );
}

/**
 * Convenience function that strips away any HTML tags, used to check for empty strings in the case of EditType.complex_string
 * @param htmlString Input string to trim
 * @returns Text content of html string
 */
export function stripHTMLTags(htmlString: string): string {
  return htmlString.replace(/<\/?[^>]+(>|$)/g, '');
}

/**
 * Function to simplify non-null assertion. Takes in any argument that should not be nullable and returns its' value, otherwise throws an error.
 * @param arg - argument to type check
 * @returns - input arg, given it's not null or undefined
 */
export function valueOrThrow<T>(arg: T | undefined | null): T {
  if (!arg) {
    throw new Error(`Unexpected null or undefined encountered!`);
  }
  return arg;
}
