import { CustomRoute } from 'src/types/routes';

export const ROUTES: CustomRoute[] = [
  {
    visible: true,
    path: '/',
    page_data_id: 'home',
    label: 'Voices of FMCS',
    in_nav: true,
    subroutes: [],
  },
  {
    visible: true,
    path: '/insights',
    page_data_id: 'insights',
    label: 'Insights',
    in_nav: true,
    subroutes: [
      {
        visible: true,
        path: '/work-life-balance',
        label: 'Work-Life Balance',
        in_nav: true,
        page_data_id: 'insights-18',
      },
      {
        visible: true,
        path: '/remote-working-experience',
        label: 'Remote Working Experience',
        in_nav: true,
        page_data_id: 'insights-27',
      },
      {
        visible: true,
        path: '/client-engagement',
        label: 'Client Engagement',
        in_nav: true,
        page_data_id: 'insights-9',
      },
      {
        visible: true,
        path: '/culture',
        label: 'Culture',
        in_nav: true,
        page_data_id: 'insights-36',
      },
      {
        visible: true,
        path: '/relationships',
        label: 'Relationships',
        in_nav: true,
        page_data_id: 'insights-23',
      },
      {
        visible: true,
        path: '/training-development',
        label: 'Training + Development',
        in_nav: true,
        page_data_id: 'insights-14',
      },
      {
        visible: true,
        path: '/intra-organizational',
        label: 'Intra-Organizational',
        in_nav: true,
        page_data_id: 'insights-32',
      },
      {
        visible: true,
        path: '/process',
        label: 'Process',
        in_nav: true,
        page_data_id: 'insights-42',
      },
    ],
  },
  {
    visible: true,
    path: '/voices',
    label: 'Voices',
    page_data_id: 'voices',
    in_nav: true,
    subroutes: [
      {
        visible: true,
        path: '/conversation-library',
        label: 'Conversation Library',
        in_nav: true,
        page_data_id: 'conversation-library',
      },
      {
        visible: true,
        path: '/highlight-explorer',
        label: 'Highlights',
        in_nav: true,
        page_data_id: 'highlight-explorer',
      },
    ],
  },
  {
    visible: true,
    path: '/about',
    label: 'About',
    page_data_id: 'about',
    in_nav: true,
    subroutes: [
      {
        visible: true,
        path: '/team',
        label: 'Our Team',
        in_nav: true,
        page_data_id: 'team',
      },
      {
        visible: true,
        path: '/process',
        label: 'Our Process',
        in_nav: true,
        page_data_id: 'process',
      },
    ],
  },
];
