import React from 'react';
import Play from '@mui/icons-material/PlayArrow';
import { useTheme } from '@mui/material/styles';

import { CustomTheme } from 'src/types/organization_metadata';

interface ContainerProps {
  onClick: (verticalPosition: number) => void;
  active?: boolean;
  secondaryColor?: string;
  primaryColor?: string;
  children: React.ReactElement;
}

export interface SelectedEmbed {
  selectedEmbedId: number;
  paragraphIndex: number;
  embedYPos: number;
}

const EmbedContainer = ({
  onClick,
  active,
  secondaryColor = 'blue',
  primaryColor = 'green',
  children,
}: ContainerProps) => {
  const [hover, setHover] = React.useState(false);
  const theme: CustomTheme = useTheme();
  const color = active || hover ? theme.palette.primary.contrastText : '#000';
  const backgroundColor = hover || active ? secondaryColor : primaryColor;
  return (
    <span
      onClick={(event: React.MouseEvent<HTMLElement>) => onClick(event.clientY)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        color,
        backgroundColor,
        cursor: 'pointer',
        display: 'inline-block',
      }}
    >
      {children}
    </span>
  );
};

export interface ZagatEmbedProps {
  children: React.ReactElement;
  getEmbedInfo: () => {
    selectedEmbedId?: number;
    default_color?: string;
    active_color?: string;
    selected?: boolean;
    setSelectedEmbed: (selectedEmbed: SelectedEmbed) => void;
  };
}

function ZagatEmbed({ children, getEmbedInfo }: ZagatEmbedProps) {
  const splitIndex = children?.props.children.lastIndexOf('-');
  const label = children.props.children.slice(0, splitIndex);
  const embedId = parseInt(children.props.children.slice(splitIndex + 1));

  const {
    selectedEmbedId,
    default_color,
    active_color,
    selected,
    setSelectedEmbed,
  } = getEmbedInfo();

  const handleClick = (embedId: number, verticalPosition: number) => {
    // set paragraph index to -1 and change it when it bubbles up; the Embed component doesn't need to know it's paragraph index
    setSelectedEmbed({
      selectedEmbedId: embedId,
      embedYPos: verticalPosition,
      paragraphIndex: -1,
    });
  };

  return (
    <EmbedContainer
      onClick={(verticalPosition: number) =>
        handleClick(embedId, verticalPosition)
      }
      active={selected && selectedEmbedId === embedId}
      primaryColor={default_color}
      secondaryColor={active_color}
    >
      <>
        <Play fontSize="inherit" viewBox="0 -3 24 24" />
        <span style={{ marginRight: '0.25rem' }}>{label}</span>
      </>
    </EmbedContainer>
  );
}

export default ZagatEmbed;
