import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';

import { Highlight } from 'src/types/common';
import { FilterOption } from 'src/types/filters';
import { LocationMetadata } from 'src/types/map';
import HighlightScroll from './HighlightScroll';
import InteractiveMap from './InteractiveMap';

interface ListViewProps {
  filters: FilterOption[];
  handleRemoveFilter: (filter: FilterOption) => void;
  handleAddMapFilter: (label: string) => void;
  handleResetMap: () => void;
  highlights: Highlight[];
  mapData?: LocationMetadata[];
  locationType?: string;
  zoomable: boolean;
}

function ExplorerView({
  filters,
  handleRemoveFilter,
  handleAddMapFilter,
  handleResetMap,
  highlights,
  mapData,
  locationType,
  zoomable,
}: ListViewProps) {
  const [mapOpen, setMapOpen] = React.useState(!!mapData && true);
  const activeMapFilter = React.useMemo(() => {
    const mapDemographicIds = mapData?.map(
      ({ demographic_id }) => demographic_id
    );
    return filters.some(
      (filter) => mapDemographicIds?.includes(filter.match as number)
    );
  }, [filters, mapData]);

  const handleLocationClick = (id: string) => {
    const existingFilter = filters.find(
      (filter) => filter.match === parseInt(id)
    );
    if (existingFilter) {
      handleRemoveFilter(existingFilter);
    } else {
      handleAddMapFilter(id);
    }
  };

  const hasMap = !!mapData?.length;
  const showMap = mapOpen && hasMap;

  return (
    <Grid container direction="row-reverse">
      {showMap && (
        <Grid
          item
          data-testid="map-container"
          xs={12}
          md={7}
          sx={{
            paddingTop: '3rem',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#FFF',
          }}
        >
          <Grid
            item
            paddingRight={{ xs: 4, md: 5 }}
            sx={{
              justifyContent: 'right',
              paddingTop: '0.5rem',
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <Button onClick={() => setMapOpen((self) => !self)} size="small">
              Hide Map{' '}
              <ArrowForwardIosIcon
                sx={{
                  fontSize: '12px',
                  verticalAlign: 'middle',
                  paddingLeft: '.5rem',
                }}
              />
            </Button>
          </Grid>

          <Grid sx={{ height: '100%' }}>
            <InteractiveMap
              locations={mapData}
              onLocationClick={handleLocationClick}
              onReset={handleResetMap}
              locationType={locationType}
              activeMapFilter={activeMapFilter}
              zoomable={zoomable}
            />
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} md={showMap ? 5 : 12}>
        <HighlightScroll
          filters={filters}
          handleRemoveFilter={handleRemoveFilter}
          onOpenMap={hasMap ? () => setMapOpen((self) => !self) : undefined}
          highlights={highlights}
          sectionId="full"
          isMapShowing={showMap}
        />
      </Grid>
    </Grid>
  );
}

export default ExplorerView;
