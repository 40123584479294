import React from 'react';
import JsxParser from 'react-jsx-parser';
import { Grid, Typography } from '@mui/material';

import ZagatEmbed, {
  SelectedEmbed,
  ZagatEmbedProps,
} from 'src/components/Audio/ZagatEmbed';

interface ParagraphBlockProps {
  title: string;
  text: string;
  default_color?: string;
  active_color?: string;
  selectedEmbedId?: number;
  setSelectedEmbed: (selectedEmbed: SelectedEmbed) => void;
  selected?: boolean;
}

function ParagraphBlock({
  title,
  text,
  selectedEmbedId,
  setSelectedEmbed,
  selected,
  default_color,
  active_color,
}: ParagraphBlockProps) {
  const getEmbedInfo: ZagatEmbedProps['getEmbedInfo'] = () => {
    return {
      selected,
      selectedEmbedId,
      setSelectedEmbed,
      default_color,
      active_color,
    };
  };

  return (
    <>
      <Grid
        item
        xs={12}
        md={6}
        paddingBottom={{ xs: 2, md: 5 }}
        paddingLeft={{ xs: 5, sm: 10, md: 0 }}
        paddingRight={{ xs: 2, sm: 8, md: 4 }}
      >
        <div>
          <Typography variant="h6" paddingY={3}>
            {title}
          </Typography>
          <div style={{ fontSize: '16px', paddingRight: '2rem' }}>
            {/* @ts-ignore - ignore */}
            <JsxParser
              bindings={{ getEmbedInfo }}
              // @ts-ignore - ignore
              components={{ Embed: ZagatEmbed }}
              jsx={text}
            />
          </div>
        </div>
      </Grid>
    </>
  );
}

export default ParagraphBlock;
