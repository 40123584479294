import React from 'react';
import { Grid } from '@mui/material';

import StyledCallout from 'src/components/common/callouts/StyledCallout';
import Header from 'src/components/common/sections/Header';
import { ProfileMetadata, SectionStyle } from 'src/types/common';
import ProfileGrid from './ProfileGrid';

interface GroupCalloutProps extends SectionStyle {
  title: string;
  body: string;
  people: ProfileMetadata[];
  showBackground: boolean;
}

function GroupCallout({
  title,
  body,
  people,
  texture,
  showBackground,
  center,
}: GroupCalloutProps) {
  return (
    <>
      <Grid>
        <StyledCallout
          containerSx={{ padding: '0px !important' }}
          boxSx={{
            paddingLeft: 3,
            paddingRight: 3,
            marginTop: '0 !important',
            marginX: 0,
          }}
          texture={texture}
          transparent={!showBackground}
        >
          <Header title={title} body={body} center={center} />
          <ProfileGrid people={people} />
        </StyledCallout>
      </Grid>
    </>
  );
}

export default GroupCallout;
