import React from 'react';
import Sticky from 'react-sticky-el';
import { Container, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import DropdownMenu from 'src/components/common/menus/DropdownMenu';

const JumpLink = styled('a')((props: any) => ({
  color: props.fontColor,
  textDecoration: 'none',
  fontSize: '14px',
}));

interface StickyNavProps {
  steps: { link: string; label: string }[];
  onClick: (label: string) => void;
  onChange: (label: string) => void;
  currSection?: string;
  numbered?: boolean;
}

function StickyNav({
  steps,
  onClick,
  onChange,
  currSection,
  numbered,
}: StickyNavProps) {
  const chipData = steps.map((step) => ({
    key: step.link,
    label: step.label,
  }));

  return (
    <Sticky>
      <Grid
        container
        item
        xs={12}
        sx={{
          paddingY: '1rem',
          backgroundColor: 'primary.main',
          overflow: 'hidden',
        }}
      >
        <Container maxWidth="lg">
          <Stack
            direction="row"
            spacing={5}
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            {chipData.map((data, ind) => (
              <JumpLink
                data-testid="floating-link-button"
                key={data.key}
                href={`#${data.key}`}
                onClick={() => onClick(data.label)}
                sx={{
                  fontWeight: currSection === data.label ? '700' : '500',
                  color: currSection === data.label ? 'white' : 'whitesmoke',
                }}
              >
                {numbered && (
                  <div
                    style={{
                      border: '1px solid white',
                      borderRadius: '50%',
                      display: 'inline-block',
                      width: '20px',
                      height: '20px',
                      textAlign: 'center',
                      marginRight: '0.5rem',
                    }}
                  >
                    {ind + 1}
                  </div>
                )}
                {data.label}
              </JumpLink>
            ))}
          </Stack>
          <Grid
            paddingX={{ xs: 3, sm: 6 }}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            <DropdownMenu
              items={steps}
              selected={currSection}
              label="Current Section"
              handleChange={(event) => onChange(event.target.value)}
            />
          </Grid>
        </Container>
      </Grid>
    </Sticky>
  );
}

export default StickyNav;
