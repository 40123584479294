import React from 'react';
import JsxParser from 'react-jsx-parser';
import { ArrowDownward } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useTexture } from '../texture/texture';
import ThinBanner from './ThinBanner';

interface SplitBannerProps {
  title: string;
  body: string;
  picture?: string;
  texture?: boolean;
  secondary?: boolean;
  large?: boolean;
  arrow?: boolean;
  icon?: React.ReactElement;
}

function SplitBanner({
  title,
  body,
  picture,
  texture,
  secondary = false,
  large,
  arrow,
  icon,
}: SplitBannerProps) {
  const { getTexture } = useTexture(secondary);
  return (
    <>
      <Grid container>
        <Grid
          item
          lg={5}
          md={6}
          paddingX={{ xs: 5, sm: 8 }}
          paddingY={4}
          sx={{
            color: secondary ? 'primary.main' : 'white',
            backgroundColor: secondary ? 'primary.light' : 'primary.dark',
            background: texture
              ? `url(${getTexture(secondary)})`
              : 'primary.dark',
            backgroundSize: 'cover',
          }}
        >
          <Typography variant="h4" paddingBottom={3} component={'span'}>
            {/* @ts-ignore - ignore */}
            <JsxParser jsx={title} />
          </Typography>
          {body && (
            <Typography
              variant="h6"
              sx={{ fontSize: '1.125rem' }}
              component={'span'}
            >
              {/* @ts-ignore - ignore */}
              <JsxParser jsx={body} />
            </Typography>
          )}
          {arrow && (
            <Typography sx={{ paddingTop: '2rem', marginBottom: '6rem' }}>
              <ArrowDownward />
            </Typography>
          )}
        </Grid>
        <Grid
          item
          lg={7}
          md={6}
          sx={{
            display: { xs: 'none', md: 'block' },
            background: `url(${picture})`,
            backgroundSize: 'cover',
            minHeight: large ? '65vh' : '50vh',
          }}
        ></Grid>
      </Grid>
      {icon && <ThinBanner title={title} icon={icon} isSubBanner={true} />}
    </>
  );
}

export default SplitBanner;
