import React from 'react';
import Pause from '@mui/icons-material/Pause';
import Play from '@mui/icons-material/PlayArrow';
import Button from '@mui/material/Button';

interface PlayButtonProps {
  info: string;
  isPlaying: boolean;
  onClick: () => void;
}
function PlayButton({ info, isPlaying, onClick }: PlayButtonProps) {
  const fontStyles = { paddingRight: '.5rem' };
  return (
    <Button
      variant="contained"
      size={'medium'}
      onClick={onClick}
      sx={{
        width: 'unset',
        fontWeight: '500',
        marginTop: '.15rem',
        fontSize: '12px',
        backgroundColor: isPlaying ? 'primary.light' : 'primary.dark',
        ':hover': {
          backgroundColor: 'primary.main',
        },
      }}
      data-testid="play-button"
      disableElevation
    >
      {isPlaying ? (
        <Pause fontSize="small" sx={fontStyles} />
      ) : (
        <Play fontSize="small" sx={fontStyles} />
      )}
      <span style={{ paddingRight: '.25rem' }}>{info}</span>
    </Button>
  );
}

export default PlayButton;
