import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import { getBanner } from 'src/components/common/banners/utils';
import ConversationHelpModal from 'src/components/ConversationLibrary/ConversationHelpModal';
import ConversationViz from 'src/components/ConversationLibrary/ConversationViz';
import appSelectors from 'src/redux/app/app-selectors';
import { StoreState } from 'src/redux/store';
import { ConversationMetadata, PageProps } from 'src/types/common';
import { ConversationLibraryPage } from 'src/types/pages/voices';

function ConversationViewContainer({ page_data_id }: PageProps) {
  const pageInfo = useSelector((state: StoreState) =>
    appSelectors.getPage(state, page_data_id)
  ) as ConversationLibraryPage;
  const conversations = useSelector(
    appSelectors.getConversations
  ) as ConversationMetadata[];
  const codes = useSelector(appSelectors.getCodes);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const highlights = useSelector(appSelectors.getHighlights);
  if (!pageInfo) {
    return <></>;
  }
  const conversationCount = conversations.length;
  const modifiedCodes = codes.analytic.filter(
    (code) => !code.display_name.includes('*')
  );
  return (
    <Grid container>
      {pageInfo.header?.visible && (
        <div
          data-edit-id={`${pageInfo.page_id}.header`}
          style={{ width: '100%' }}
        >
          {getBanner({
            ...pageInfo.header.content,
            icon: (
              <ConversationHelpModal conversation_count={conversationCount} />
            ),
          })}
        </div>
      )}
      {modifiedCodes.length && conversations.length && (
        <Grid
          container
          item
          xs={12}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <ConversationViz
            conversations={conversations}
            codes={modifiedCodes}
            highlights={highlights ?? []}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default ConversationViewContainer;
