import { EditType } from 'src/types/edit';
import { HomePage } from 'src/types/pages/home';

export const HOME: HomePage = {
  page_id: 'home',
  section_order: [
    'header',
    'insights_showcase',
    'highlights_showcase',
    'navigation_cards',
  ],
  header: {
    visible: true,
    content: {
      title: '<p>Insert some default text here</p>',
      body: '<p>Insert some default text here</p>',
      image: '',
      header_type: 'full',
    },
  },
  insights_showcase: {
    visible: true,
    content: {
      overview: {
        id: 'overview',
        graph_type: 'packed_circles',
        disable_through_click: false,
        title: '<p>Insert some default text here</p>',
        subtitle: '<p></p>',
        body: '<p>Insert some default text here</p>',
        link: {
          to: '/insights',
          label: 'View Insights',
        },
      },
      insights: [],
    },
  },
  highlights_showcase: {
    visible: true,
    content: {
      title: 'Conversation Prompts',
      subtitle: '',
      optional_link: {
        visible: false,
        to: '/voices/highlight-explorer',
        label: 'Hear Highlights',
      },
      tab_type: 'floating',
      style: {
        texture: false,
        secondary: false,
      },
      tabs: [
        {
          label: 'Insert prompt name or question here',
          link: {
            visible: true,
            label: 'Hear Highlights',
            to: '/voices/highlight-explorer',
          },
          highlight_ids: [],
        },
      ],
      tab_order: [],
    },
  },
  map_showcase: {
    visible: false,
    content: {
      zoomable: false,
      location_type: 'Location',
      overview: {
        title: 'Insert map overview title',
        body: '<p>Insert Map explainer text</p>',
        link: {
          to: '/voices/highlight-explorer',
          label: 'Explore All Highlights',
        },
      },
      location_cards: [],
    },
  },
  navigation_cards: {
    visible: true,
    content: {
      cards: [
        {
          title: '<p>Conversation Library</p>',
          body: '<p>The Conversation Library is a data visualization of collected conversations. Explore the most common themes and listen to connected highlights.</p>',
          image: '',
          link: {
            to: 'voices/conversation-library',
            label: 'Visit Conversation Library',
          },
        },
        {
          title: '<p>How does this all work?</p>',
          body: '<p>Learn more about the conversations and how we drew insights from what people said.</p>',
          image: '',
          link: {
            to: 'about/process',
            label: 'View Our Process',
          },
        },
      ],
      card_order: [],
    },
  },
  edit_metadata: {
    page_label: 'Home',
    sections: [
      {
        section_label: 'Introduction',
        edit_blocks: [
          {
            block_label: 'Header',
            match: 'header.content',
            type: EditType.banner,
          },
        ],
      },
      {
        section_label: 'Insights Showcase',
        sub_sections: [
          {
            section_label: 'Overview',
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'insights_showcase.content.overview.title',
                type: EditType.complex_string,
              },
              {
                block_label: 'Subtitle',
                match: 'insights_showcase.content.overview.subtitle',
                type: EditType.complex_string,
              },
              {
                block_label: 'Paragraph',
                match: 'insights_showcase.content.overview.body',
                type: EditType.complex_string,
              },
            ],
          },
        ],
      },
      {
        section_label: 'Highlight Showcase',
        update_section: {
          match: 'highlights_showcase.content.tabs',
          label: 'Add Navigation Card',
          empty: {
            label: 'Default Label',
            link: {
              visible: true,
              label: 'Default Link',
              to: '/',
            },
            highlight_ids: [],
          },
          newSectionMetadata: {
            section_label: 'Tab',
            numbered: true,
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'highlights_showcase.content.tabs[index].label',
                type: EditType.string,
              },
              {
                block_label: 'Paragraph',
                match: 'highlights_showcase.content.tabs[index].body',
                type: EditType.complex_string,
              },
              {
                block_label: 'Optional Link',
                match: 'highlights_showcase.content.tabs[index].link',
                type: EditType.optional_link,
              },
              {
                block_label: 'Highlight List',
                match: 'highlights_showcase.content.tabs[index].highlight_ids',
                type: EditType.highlight_list,
              },
            ],
          },
        },
        sub_sections: [
          {
            section_label: 'Overview',
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'highlights_showcase.content.title',
                type: EditType.string,
              },
              {
                block_label: 'Paragraph',
                match: 'highlights_showcase.content.subtitle',
                type: EditType.complex_string,
              },
              {
                block_label: 'Tab Type',
                match: 'highlights_showcase.content.tab_type',
                type: EditType.tab_type,
              },
              {
                block_label: 'Textured Background',
                match: 'highlights_showcase.content.style.texture',
                type: EditType.checkbox,
              },
              {
                block_label: 'Optional Link',
                match: 'highlights_showcase.content.optional_link',
                type: EditType.optional_link,
              },
            ],
          },
          {
            section_label: 'Tab',
            numbered: true,
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'highlights_showcase.content.tabs[0].label',
                type: EditType.string,
              },
              {
                block_label: 'Paragraph',
                match: 'highlights_showcase.content.tabs[0].body',
                type: EditType.complex_string,
              },
              {
                block_label: 'Optional Link',
                match: 'highlights_showcase.content.tabs[0].link',
                type: EditType.optional_link,
              },
              {
                block_label: 'Highlight List',
                match: 'highlights_showcase.content.tabs[0].highlight_ids',
                type: EditType.highlight_list,
              },
            ],
          },
        ],
      },
      {
        section_label: 'Navigation Cards',
        update_section: {
          match: 'navigation_cards.content.cards',
          label: 'Add Navigation Card',
          empty: {
            title: 'Default Title',
            body: '<p>Default Body</p>',
            image: '',
            link: {
              to: '/',
              label: 'Default Link',
            },
          },
          newSectionMetadata: {
            section_label: 'Navigation Card',
            numbered: true,
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'navigation_cards.content.cards[index].title',
                type: EditType.string,
              },
              {
                block_label: 'Paragraph',
                match: 'navigation_cards.content.cards[index].body',
                type: EditType.complex_string,
              },
              {
                block_label: 'Image',
                match: 'navigation_cards.content.cards[index].image',
                type: EditType.image,
              },
              {
                block_label: 'Link',
                match: 'navigation_cards.content.cards[index].link',
                type: EditType.link,
              },
            ],
          },
        },
        sub_sections: [
          {
            section_label: 'Navigation Card',
            numbered: true,
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'navigation_cards.content.cards[0].title',
                type: EditType.string,
              },
              {
                block_label: 'Paragraph',
                match: 'navigation_cards.content.cards[0].body',
                type: EditType.complex_string,
              },
              {
                block_label: 'Image',
                match: 'navigation_cards.content.cards[0].image',
                type: EditType.image,
              },
              {
                block_label: 'Link',
                match: 'navigation_cards.content.cards[0].link',
                type: EditType.link,
              },
            ],
          },
          {
            section_label: 'Navigation Card',
            numbered: true,
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'navigation_cards.content.cards[1].title',
                type: EditType.string,
              },
              {
                block_label: 'Paragraph',
                match: 'navigation_cards.content.cards[1].body',
                type: EditType.complex_string,
              },
              {
                block_label: 'Image',
                match: 'navigation_cards.content.cards[1].image',
                type: EditType.image,
              },
              {
                block_label: 'Link',
                match: 'navigation_cards.content.cards[1].link',
                type: EditType.link,
              },
            ],
          },
        ],
      },
    ],
  },
};
