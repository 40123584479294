/* eslint-disable unused-imports/no-unused-imports */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import * as api from 'src/api/api';
import { updatePageLinks } from 'src/components/Editing/utils/filterGroupUtils';
import { MapUpdate } from 'src/components/Editing/utils/mapUtils';
import {
  INITIAL_BOOTSTRAP_DATA,
  ROBUST_BOOTSTRAP_DATA,
} from 'src/mocks/bootstrap';
import { generateEditMetadata } from 'src/setup/editmetadata';
import { generateHomePageWithMap } from 'src/setup/map';
import { setupApp } from 'src/setup/setup';
import {
  clearBootstrapStorage,
  setBootstrapInStorage,
} from 'src/setup/storage';
import { incrementVersion } from 'src/setup/version';
import { BootstrapData } from 'src/types/boostrap';
import {
  Code,
  Codes,
  PageBase,
  PageEditMetadata,
  ServerError,
} from 'src/types/common';
import { FilterGroup } from 'src/types/filters';
import { OrganizationMetadata } from 'src/types/organization_metadata';
import { HomePage } from 'src/types/pages/home';
import { CustomRoute } from 'src/types/routes';

export interface AppState extends Required<BootstrapData> {
  page_edit_metadata: PageEditMetadata[];
  editing: boolean;
  saveMessage?: string;
  changes_made: boolean;
  request_sent: boolean;
  error_message: ServerError | undefined;
}

if (process.env.REACT_APP_ENV === 'development' && !window.Cypress) {
  // Bootstrap with mockdata for development
  // window.BOOTSTRAP_DATA = INITIAL_BOOTSTRAP_DATA;
  window.BOOTSTRAP_DATA = ROBUST_BOOTSTRAP_DATA;
}

// initial state for reducer
const initialState: AppState = {
  editing: false,
  saveMessage: '',
  changes_made: false,
  request_sent: false,
  error_message: undefined,
  page_edit_metadata: [],
  ...(window.BOOTSTRAP_DATA as Required<BootstrapData>),
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setup(state, action: PayloadAction<undefined>) {},
    setupSuccess(state, action: PayloadAction<AppState>) {
      return cloneDeep(action.payload);
    },
    setupFailure(state, action: PayloadAction<ServerError>) {
      state.error_message = action.payload;
    },
    setEditing(state, action: PayloadAction<boolean>) {
      state.editing = action.payload;
    },
    updatePage(state, action: PayloadAction<PageBase>) {
      const newPages = [...state.pages];
      const matchingPageIndex = newPages.findIndex(
        (page) => action.payload.page_id === page.page_id
      );
      newPages[matchingPageIndex] = action.payload;
      let newEditMetadata = [] as PageEditMetadata[];
      if (state.editAccess) {
        newEditMetadata = generateEditMetadata(newPages, state.routes);
      }
      state.page_edit_metadata = newEditMetadata;
      state.pages = newPages;
      state.changes_made = true;
      setBootstrapInStorage(stateToBootstrap(cloneDeep(state)));
    },
    updateMap(state, action: PayloadAction<MapUpdate>) {
      if (state.editAccess) {
        // if this is the first map upload or the codes have changed, we need to toggle the map_showcase visible and generate location cards
        if (state.map.length === 0 || action.payload.codesHaveChanged) {
          const newPages = [...state.pages];
          newPages.forEach((page, index: number, arr: PageBase[]) => {
            if (page.page_id === 'home') {
              arr[index] = generateHomePageWithMap(
                page as HomePage,
                action.payload.mapItems,
                state.codes.demographic
              );
            }
          });
          state.page_edit_metadata = generateEditMetadata(
            newPages,
            state.routes
          );
          state.pages = newPages;
        }
        state.map = action.payload.mapItems;
        state.changes_made = true;
        setBootstrapInStorage(stateToBootstrap(cloneDeep(state)));
      }
    },
    updateDemographicCodes(state, action: PayloadAction<Code[]>) {
      if (state.editAccess) {
        const demographicCodes: Code[] = cloneDeep(state.codes.demographic);
        demographicCodes.forEach((code: Code) => {
          code.subcodes = code.subcodes?.map((subCode: Code) => {
            const foundCode: Code | undefined = action.payload.find(
              (newCode) => newCode.id === subCode.id
            );
            return foundCode ?? subCode;
          });
        });
        state.codes = { ...state.codes, demographic: demographicCodes };
        state.changes_made = true;
        setBootstrapInStorage(stateToBootstrap(cloneDeep(state)));
      }
    },
    updateOrganizationMetadata(
      state,
      action: PayloadAction<OrganizationMetadata>
    ) {
      state.organization_metadata = action.payload;
      document.title = `${action.payload.short_name}`;
      state.changes_made = true;
      setBootstrapInStorage(stateToBootstrap(cloneDeep(state)));
    },
    updateFilters(state, action: PayloadAction<FilterGroup[]>) {
      const existingFilters: FilterGroup[] = cloneDeep(state.filters);
      const newFilters: FilterGroup[] = cloneDeep(action.payload);
      const existingPages: PageBase[] = cloneDeep(state.pages);
      state.filters = newFilters;
      // need to update pages to have up-to-date links
      state.pages = updatePageLinks(existingPages, existingFilters, newFilters);
      state.changes_made = true;
      setBootstrapInStorage(stateToBootstrap(cloneDeep(state)));
    },
    updateCodes(state, action: PayloadAction<Codes>) {
      state.codes = action.payload;
      state.changes_made = true;
      setBootstrapInStorage(stateToBootstrap(cloneDeep(state)));
    },
    updateRoutes(state, action: PayloadAction<CustomRoute[]>) {
      state.routes = action.payload;
      state.changes_made = true;
      state.page_edit_metadata = generateEditMetadata(
        state.pages,
        state.routes
      );
      setBootstrapInStorage(stateToBootstrap(cloneDeep(state)));
    },
    draftSite(state, action: PayloadAction<undefined>) {
      state.request_sent = true;
      state.saveMessage = 'Saving Draft...';
    },
    draftSiteSuccess(state, action: PayloadAction<undefined>) {
      state.request_sent = false;
      state.saveMessage = 'Draft saved!';
      state.changes_made = false;
      clearBootstrapStorage();
    },
    draftSiteFailure(state, action: PayloadAction<ServerError>) {
      state.request_sent = false;
      state.saveMessage = 'Failed to save draft.';
      state.changes_made = false;
      state.error_message = action.payload;
    },
    updateInsights(state, action: PayloadAction<undefined>) {
      state.request_sent = true;
    },
    updateInsightsSuccess(state, action: PayloadAction<undefined>) {
      state.request_sent = false;
      state.changes_made = false;
      clearBootstrapStorage();
    },
    updateInsightsFailure(state, action: PayloadAction<ServerError>) {
      state.request_sent = false;
      state.changes_made = false;
      state.error_message = action.payload;
    },
    publishSite(state, action: PayloadAction<undefined>) {
      state.request_sent = true;
      state.saveMessage = 'Publishing site...';
    },
    publishSiteSuccess(state, action: PayloadAction<undefined>) {
      state.request_sent = false;
      state.saveMessage = 'Site published!';
      state.changes_made = false;
      clearBootstrapStorage();
    },
    publishSiteFailure(state, action: PayloadAction<ServerError>) {
      state.request_sent = false;
      state.saveMessage = 'Failed to publish site.';
      state.changes_made = false;
      state.error_message = action.payload;
    },
  },
});

export const {
  setup,
  setupSuccess,
  setupFailure,
  setEditing,
  updatePage,
  updateMap,
  updateDemographicCodes,
  updateOrganizationMetadata,
  updateFilters,
  updateCodes,
  updateRoutes,
  draftSite,
  draftSiteFailure,
  draftSiteSuccess: draftSiteSuccess,
  updateInsights,
  updateInsightsSuccess,
  updateInsightsFailure,
  publishSite,
  publishSiteSuccess: publishSiteSuccess,
  publishSiteFailure,
} = slice.actions;
export const actions = slice.actions;

export default slice.reducer;

const stateToBootstrap = (state: AppState): BootstrapData => ({
  editAccess: state.editAccess,
  organization_metadata: state.organization_metadata,
  routes: state.routes,
  pages: state.pages,
  filters: state.filters,
  codes: state.codes,
  map: state.map,
  highlights: state.highlights,
  analytic_codes: state.analytic_codes,
  demographic_codes: state.demographic_codes,
  conversations: state.conversations,
  unpublished_draft: state.unpublished_draft,
  version: state.version,
  embedURL: state.embedURL,
  internalVersion: state.internalVersion,
  insights_data_timestamp: state.insights_data_timestamp,
});

export function* sagaSetup(action: ReturnType<typeof setup>) {
  const state: AppState = yield select((state) => state);
  // Set the session storage to contain the bootstrap data.
  try {
    // @ts-ignore - ignore
    const initialState: AppState = yield call(setupApp);
    yield put(setupSuccess(initialState));
  } catch (err) {
    yield put(setupFailure(err as ServerError));
  }
}
export function* sagaDraftSite(action: ReturnType<typeof draftSite>) {
  const state: AppState = yield select((state) => state.app);
  // Set the session storage to contain the bootstrap data.
  const update_data = incrementVersion(stateToBootstrap(state), 'draft');
  try {
    // @ts-ignore - ignore
    yield call(api.draftSite, update_data);
    yield put(draftSiteSuccess());
  } catch (err) {
    yield put(draftSiteFailure(err as ServerError));
  }
}
export function* sagaUpdateInsights(action: ReturnType<typeof updateInsights>) {
  const state: AppState = yield select((state) => state.app);
  // Set the session storage to contain the bootstrap data.
  const update_data = incrementVersion(stateToBootstrap(state), 'draft');
  try {
    // @ts-ignore - ignore
    yield call(api.updateInsights, update_data);
    yield put(updateInsightsSuccess());
  } catch (err) {
    yield put(updateInsightsFailure(err as ServerError));
  }
}

export function* sagaPublishSite(action: ReturnType<typeof publishSite>) {
  const state: AppState = yield select((state) => state.app);
  // Set the session storage to contain the bootstrap data.
  const update_data = incrementVersion(stateToBootstrap(state), 'publish');
  try {
    // @ts-ignore - ignore
    yield call(api.publishSite, update_data);
    yield put(publishSiteSuccess());
  } catch (err) {
    yield put(publishSiteFailure(err as ServerError));
  }
}

export const sagas = [
  takeEvery(setup.type, sagaSetup),
  takeEvery(draftSite.type, sagaDraftSite),
  takeEvery(publishSite.type, sagaPublishSite),
  takeEvery(updateInsights.type, sagaUpdateInsights),
];
