import { cloneDeep } from 'lodash';

import { Code, CodeMetadata, Codes, Highlight } from 'src/types/common';
import { FilterGroup, FilterOption, FilterType } from 'src/types/filters';

/**
 * Adds and removes codes depending on the data retrieved from the backend
 * @param codes - the codes used for the UI stored in the bootstrap data.
 * @param code_metadata - the codes provided by the backend.
 * @param highlights - the highlights provided by the backend.
 * @returns the codes used for the ui
 */
export const generateCodes = (
  codes: Code[],
  highlights?: Highlight[],
  code_metadata?: CodeMetadata[],
  demographic?: boolean
): Code[] => {
  const newCodes = cloneDeep(codes);
  if (!code_metadata || !highlights) {
    return newCodes;
  }
  const countedCodes = code_metadata.map((code) =>
    countCodeOccurrance(code, highlights, demographic)
  );
  return mergeCodeCount(newCodes, countedCodes);
};

export const mergeCodeCount = (
  codes: Code[],
  countedCodes: Pick<Code, 'id' | 'count' | 'subcodes' | 'display_name'>[],
  isSubcode = false
): Code[] => {
  const newCodes = codes
    .map((code) => {
      const matchingCode = countedCodes.find(({ id }) => id === code.id);
      if (!matchingCode) {
        return undefined; // remove deleted codes
      }
      const subcodes =
        code.subcodes &&
        mergeCodeCount(code.subcodes, matchingCode.subcodes ?? []);
      return {
        ...code,
        label: matchingCode.display_name, // Ensure code label is the display name in insights
        subcodes,
        count: matchingCode.count,
      };
    })
    .filter((code) => code) as Code[];
  // Add codes the don't exist
  const missingCodes = countedCodes
    .filter((code) => !codes.some(({ id }) => id === code.id))
    .map((code) => {
      const subcodes =
        code.subcodes && mergeCodeCount([], code.subcodes ?? [], true);
      return {
        ...code,
        subcodes,
        label: code.display_name,
        showcase_colors: {
          background_color: isSubcode ? '#ebedef' : '#4F29B7',
          text_color: isSubcode ? '#000000' : '#FFFFFF',
        },
        conversation_library_colors: {
          background_color: isSubcode ? '#ebedef' : '#4F29B7',
          text_color: isSubcode ? '#000000' : '#FFFFFF',
        },
        embed_colors: {
          background_color: isSubcode ? '#ebedef' : '#4F29B7',
          text_color: isSubcode ? '#000000' : '#FFFFFF',
        },
      } as Code;
    });
  return newCodes.concat(missingCodes);
};

export const countCodeOccurrance = (
  code_metadata: CodeMetadata,
  highlights: Highlight[],
  demographic?: boolean
): Pick<Code, 'id' | 'count' | 'subcodes' | 'display_name'> => {
  let subcodes = code_metadata.subcodes;
  const matchIds = [code_metadata.id].concat(
    subcodes?.map(({ id }) => id) ?? []
  );
  let count = 0;
  highlights.forEach((highlight) => {
    if (
      !demographic &&
      highlight.codes.some((code) => matchIds?.includes(code))
    ) {
      count = count + 1;
    }
    if (
      demographic &&
      highlight.demographics.some((code) => matchIds?.includes(code))
    ) {
      count = count + 1;
    }
  });
  subcodes = subcodes?.map((subcode) =>
    countCodeOccurrance(subcode, highlights, demographic)
  );
  return {
    ...code_metadata,
    subcodes: subcodes as Code[],
    count,
  };
};

export const getMatchingParentCode = (id: number, codes: CodeMetadata[]) => {
  return codes.filter((code) => {
    return (
      !code.display_name.includes('*') &&
      (code.id === id || code.subcodes?.some((subcode) => subcode.id === id))
    );
  })[0];
};

export const codesToFilters = (codes: Codes): FilterGroup[] => {
  const newFilters: FilterGroup[] = [];
  // Create filters for analytic codes
  const thematic = codes.analytic.filter(
    (code) => !code.display_name.includes('*')
  );
  const structural = codes.analytic.filter((code) =>
    code.display_name.includes('*')
  );

  if (thematic.length) {
    thematic.forEach((code) => {
      const filterOptions: FilterOption[] =
        code.subcodes?.map((subcode) => {
          return {
            key: 'codes',
            type: 'single',
            match: subcode.id,
            label: subcode.label,
          };
        }) ?? [];
      if (filterOptions.length) {
        newFilters.push({
          label: code.label,
          id: code.id,
          options: filterOptions,
          type: FilterType.thematic,
        });
      }
    });
  }

  if (structural.length) {
    structural.forEach((code) => {
      const filterOptions: FilterOption[] =
        code.subcodes?.map((subcode) => {
          return {
            key: 'codes',
            type: 'single',
            match: subcode.id,
            label: subcode.label.replace('*', ''),
          };
        }) ?? [];
      if (filterOptions.length) {
        newFilters.push({
          label: code.label.replace('*', ''),
          id: code.id,
          options: filterOptions,
          type: FilterType.structural,
        });
      }
    });
  }

  //handle demographic codes
  codes.demographic.forEach((code) => {
    const filterOptions: FilterOption[] =
      code.subcodes?.map((subcode) => {
        return {
          key: 'demographics',
          type: 'single',
          match: subcode.id,
          label: subcode.label,
        };
      }) ?? [];
    if (filterOptions.length) {
      newFilters.push({
        label: code.label,
        id: code.id,
        options: filterOptions,
        type: FilterType.demographic,
      });
    }
  });
  return newFilters;
};

export const getFilterByType = (filters: FilterGroup[], type: FilterType) =>
  filters.filter((group) => group.type === type);

export const getFiltersByLabel = (filters: FilterGroup[], label: string) =>
  filters.filter((group) => group.label === label);
