import React from 'react';
import JsxParser from 'react-jsx-parser';
import { Grid, Typography } from '@mui/material';

import StyledCallout from 'src/components/common/callouts/StyledCallout';
import { SectionStyle } from 'src/types/common';

interface FloatingCardProps extends SectionStyle {
  title: string;
  body: string;
  children: React.ReactElement;
}

const FloatingCard = ({
  title,
  body,
  children,
  texture,
}: FloatingCardProps) => {
  return (
    <StyledCallout texture={texture} transparent={false}>
      <Grid container padding={3}>
        <Grid item md={12} paddingLeft={{ md: 3 }}>
          <Typography variant="h6" color="white" marginBottom={2}>
            {/* @ts-ignore - jsx parser */}
            {title && <JsxParser jsx={title} />}
          </Typography>
        </Grid>
        <Grid item md={12} paddingLeft={{ md: 3 }}>
          {/* @ts-ignore - ignore */}
          <JsxParser jsx={body} />
        </Grid>
        <Grid item md={12} paddingLeft={{ md: 3 }}>
          {children}
        </Grid>
      </Grid>
    </StyledCallout>
  );
};

export default FloatingCard;
