import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import InteractiveMap from 'src/components/HighlightExplorer/InteractiveMap';
import appSelectors from 'src/redux/app/app-selectors';
import { InformationCard } from 'src/types/common';
import { LocationMetadata, MapItem } from 'src/types/map';
import InfoCard from '../common/cards/InfoCard';
import { getD3Locations } from '../HighlightExplorer/utils/utils';

interface LocationShowcaseProps {
  map_data: MapItem[];
  overview: InformationCard;
  location_cards: InformationCard[];
  locationType: string;
  zoomable: boolean;
}

function LocationShowcase({
  map_data,
  overview,
  location_cards,
  locationType,
  zoomable,
}: LocationShowcaseProps) {
  const highlights = useSelector(appSelectors.getHighlights);
  const [currLocation, setCurrLocation] = useState<string>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = (location: string) => {
    if (currLocation === location) {
      setCurrLocation(undefined);
    } else {
      setCurrLocation(location);
    }
  };

  const handleClose = () => {
    setCurrLocation(undefined);
  };
  const modifiedMapData = React.useMemo((): LocationMetadata[] => {
    if (!map_data) {
      return [];
    }
    return getD3Locations(map_data, highlights, [
      parseInt(currLocation ?? '0'),
    ]);
  }, [map_data, highlights, currLocation]);

  const locationInfo = React.useMemo(
    () => location_cards.find((card) => card.id === currLocation) ?? overview,
    [currLocation, location_cards, overview]
  );

  return (
    <Grid container direction="row-reverse" minHeight={{ lg: '500px' }}>
      <Grid
        item
        xs={12}
        lg={6}
        paddingTop={{ xs: 6, md: 9 }}
        sx={{ backgroundColor: 'white' }}
      >
        <InteractiveMap
          locations={modifiedMapData}
          onLocationClick={handleClick}
          locationType={locationType}
          onReset={handleClose}
          activeMapFilter={!!currLocation}
          zoomable={zoomable}
        />
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        sx={{
          backgroundColor: 'white',
        }}
      >
        <Box paddingTop={6} paddingX={{ xs: 3, sm: 6 }} paddingBottom={8}>
          <InfoCard {...locationInfo} transparent></InfoCard>
        </Box>
      </Grid>
    </Grid>
  );
}

export default LocationShowcase;
