import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Chip, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { cloneDeep } from 'lodash';

import appSelectors from 'src/redux/app/app-selectors';
import { Code } from 'src/types/common';

interface TopicChipsProps {
  code_ids: number[];
  light?: boolean;
}
const getMatchingCodes = (code_ids: number[], codes: Code[]) => {
  // Remove subcodes not in code_ids
  codes.forEach((code) => {
    code.subcodes = code.subcodes?.filter(({ id }) => code_ids.includes(id));
  });
  // remove codes without subcodes, not in code_ids, or a structural code
  return codes.filter(
    (code) =>
      (code_ids.includes(code.id) || code.subcodes?.length) &&
      !code.display_name.includes('*')
  );
};
function TopicChips({ code_ids, light }: TopicChipsProps) {
  const codes = useSelector(appSelectors.getCodes);
  const [subtopicVisible, setSubtopicVisible] = useState(false);
  const matchingCodes = getMatchingCodes(
    code_ids,
    cloneDeep(codes.analytic) ?? []
  );
  const hasChildren = matchingCodes.some((code) => code.subcodes?.length);

  return (
    <Grid container item xs={12} paddingTop={2}>
      <Stack sx={{ flexWrap: 'wrap' }} direction="row">
        {matchingCodes.map((code) => {
          return (
            <React.Fragment key={`topic-chips-${code.id}`}>
              <Box component="span" paddingTop={1} paddingRight={1}>
                <Chip
                  label={code.label}
                  data-testid="topic-chip"
                  size="small"
                  variant={'filled'}
                  sx={{
                    backgroundColor: code.embed_colors.background_color,
                    color: code.embed_colors.text_color,
                  }}
                />
              </Box>
              {subtopicVisible &&
                code.subcodes?.map((subcode) => {
                  return (
                    <Box component="span" paddingTop={1} paddingRight={1}>
                      <Chip
                        label={subcode.label}
                        size="small"
                        variant={'outlined'}
                        sx={{
                          backgroundColor:
                            subcode.embed_colors.background_color,
                          color: subcode.embed_colors.text_color,
                        }}
                      />
                    </Box>
                  );
                })}
            </React.Fragment>
          );
        })}
        {hasChildren && (
          <Button
            onClick={() => setSubtopicVisible(!subtopicVisible)}
            size="small"
            sx={{ paddingTop: '.7rem' }}
          >
            <Typography variant="caption" color={light ? '#CCC' : '#8A8A8A'}>
              {`${subtopicVisible ? 'Hide' : 'Show'}`} Subthemes
            </Typography>
          </Button>
        )}
      </Stack>
    </Grid>
  );
}

export default TopicChips;
