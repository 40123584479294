import { StoreState } from 'src/redux/store';
import {
  Codes,
  ConversationMetadata,
  Highlight,
  PageBase,
  PageEditMetadata,
  ServerError,
} from 'src/types/common';
import { FilterGroup } from 'src/types/filters';
//import { InsightsMetadata } from 'src/types/insights_metadata';
import { MapItem } from 'src/types/map';
import { OrganizationMetadata } from 'src/types/organization_metadata';
import { CustomRoute } from 'src/types/routes';

// selectors
export const selectors = {
  getOrganizationMetadata: (
    state: StoreState
  ): OrganizationMetadata | undefined => state.app.organization_metadata,
  getPage: (state: StoreState, page_data_id: string): PageBase | undefined =>
    state.app.pages.filter((page) => page.page_id === page_data_id)[0],
  getError: (state: StoreState): ServerError | undefined =>
    state.app.error_message,
  getCustomRoutes: (state: StoreState): CustomRoute[] => state.app.routes,
  //  getInsightsUpdateTimestamp: (state: StoreState): InsightsMetadata[] =>
  //    state.app.insights_data_timestamp,
  getInsightsDataTimestamp: (state: StoreState): string =>
    state.app.insights_data_timestamp,

  getConversations: (state: StoreState): ConversationMetadata[] | undefined =>
    state.app.conversations,
  getHighlights: (state: StoreState): Highlight[] => state.app.highlights ?? [],
  getCodes: (state: StoreState): Codes => state.app.codes,
  getMap: (state: StoreState): MapItem[] => state.app.map ?? [],
  getFilters: (state: StoreState): FilterGroup[] | undefined =>
    state.app.filters,
  getSaveMessage: (state: StoreState): string | undefined =>
    state.app.saveMessage,
  getRequestStatus: (state: StoreState): boolean => state.app.request_sent,
  getEditing: (state: StoreState): boolean => state.app.editing,
  getCanEdit: (state: StoreState): boolean => state.app.editAccess,
  getChangesMade: (state: StoreState): boolean => state.app.changes_made,
  getEditMetadata: (state: StoreState): PageEditMetadata[] =>
    state.app.page_edit_metadata,
  getEmbedUrl: (state: StoreState): string | undefined => state.app.embedURL,
};

export default selectors;
