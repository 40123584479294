import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface DropdownMenuProps {
  items: any[];
  selected?: string;
  label: string;
  handleChange: (event: SelectChangeEvent<any>, child: React.ReactNode) => void;
}

function DropdownMenu({
  items,
  selected,
  label,
  handleChange,
}: DropdownMenuProps) {
  return (
    <FormControl variant="filled" fullWidth sx={{ backgroundColor: 'white' }}>
      <InputLabel id="simple-select-label">{label}</InputLabel>
      <Select
        labelId="simple-select-label"
        id="simple-select"
        value={selected}
        label={label}
        onChange={handleChange}
      >
        {items.map((item: any) => (
          <MenuItem value={item.label} key={item.label}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default DropdownMenu;
