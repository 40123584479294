import React from 'react';
import { useSelector } from 'react-redux';

import appSelectors from 'src/redux/app/app-selectors';

export const useTexture = (secondary?: boolean) => {
  const organization_metadata = useSelector(
    appSelectors.getOrganizationMetadata
  );
  const getTexture = React.useCallback(
    (secondary?: boolean) =>
      URL.createObjectURL(
        new Blob(
          [
            TEXTURE.replace(
              `fill="#000000"`,
              `fill="${
                secondary
                  ? organization_metadata?.texture_colors.secondary
                  : organization_metadata?.texture_colors.primary
              }"`
            ),
          ],
          {
            type: 'image/svg+xml',
          }
        )
      ),
    [organization_metadata]
  );

  return React.useMemo(() => ({ getTexture }), [getTexture]);
};

const TEXTURE = `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="2160.000000pt" height="2160.000000pt" viewBox="0 1300 1950.000000 100.000000"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,2160.000000) scale(0.10000,-0.100000)"
fill="#000000" stroke="none">
<path d="M107 19293 c-4 -3 -7 -24 -7 -45 0 -37 1 -38 35 -38 24 0 35 5 35 15
0 8 4 15 9 15 5 0 12 -35 14 -77 7 -123 19 -152 60 -153 13 0 17 9 17 40 0 22
5 40 10 40 6 0 10 -9 10 -20 0 -13 7 -20 20 -20 22 0 28 -26 7 -33 -18 -6 10
-37 34 -37 12 0 19 -7 19 -20 0 -11 5 -20 10 -20 6 0 10 -10 10 -23 0 -25 17
-47 37 -47 7 0 13 11 13 25 0 14 5 25 10 25 6 0 10 7 10 15 0 8 -4 15 -10 15
-5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 14 10 30 0 20 -5 30 -15 30 -8 0 -15
7 -15 15 0 8 -14 18 -32 23 -28 7 -34 13 -36 40 -4 36 22 46 31 12 6 -25 67
-29 67 -5 0 8 -6 15 -13 15 -27 0 -57 37 -57 69 0 21 -5 31 -15 31 -8 0 -15 7
-15 16 0 36 -25 44 -138 44 -60 0 -112 -3 -115 -7z"/>
<path d="M430 19278 c0 -52 11 -70 39 -64 14 3 20 3 14 0 -7 -3 -13 -12 -13
-20 0 -8 -7 -14 -15 -14 -8 0 -15 -7 -15 -15 0 -8 7 -15 15 -15 8 0 15 -7 15
-15 0 -18 36 -20 43 -2 10 28 19 -7 18 -72 -1 -57 2 -71 14 -71 8 0 15 -9 15
-20 0 -15 7 -20 25 -20 32 0 33 28 2 36 -23 5 -24 8 -19 82 3 42 9 79 13 80
15 6 10 32 -6 32 -8 0 -15 9 -15 20 0 11 -4 20 -10 20 -5 0 -10 5 -10 10 0 6
7 10 15 10 17 0 18 4 9 38 -6 20 -12 22 -70 22 -60 0 -64 -2 -64 -22z"/>
<path d="M990 19290 c0 -5 5 -10 11 -10 5 0 8 -4 4 -10 -10 -16 6 -60 22 -60
13 0 14 -10 9 -54 -9 -70 0 -105 30 -112 13 -3 24 -7 24 -8 0 -1 5 -28 11 -60
10 -53 9 -59 -11 -76 -12 -10 -28 -16 -36 -13 -16 6 -19 23 -4 23 6 0 10 14
10 30 0 20 -5 30 -15 30 -8 0 -15 5 -15 10 0 6 -7 10 -15 10 -8 0 -15 -4 -15
-10 0 -5 -7 -10 -15 -10 -9 0 -15 -9 -15 -24 0 -18 -7 -26 -27 -31 -15 -3 -31
-12 -36 -18 -4 -7 -5 0 -1 16 5 23 3 27 -15 27 -12 0 -21 -4 -21 -10 0 -5 -7
-10 -15 -10 -9 0 -15 9 -15 25 0 18 7 26 25 31 24 6 36 34 15 34 -5 0 -10 6
-10 14 0 13 -41 26 -81 26 -14 0 -19 -7 -19 -29 0 -16 6 -32 13 -34 7 -3 5 -6
-5 -6 -12 -1 -18 6 -18 19 0 15 -7 20 -25 20 -18 0 -25 -5 -25 -19 0 -11 4
-21 8 -23 5 -1 12 -15 16 -30 5 -22 3 -28 -10 -28 -23 0 -11 -24 14 -28 22 -3
32 -22 12 -22 -15 0 -12 -19 5 -36 8 -9 15 -26 15 -40 0 -13 5 -24 10 -24 6 0
10 -7 10 -15 0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -8 5 -15 10 -15 6 0
10 -7 10 -15 0 -9 9 -15 21 -15 12 0 23 -8 26 -20 3 -11 12 -20 19 -20 21 0
17 -21 -6 -27 -11 -3 -20 -11 -20 -18 0 -8 -9 -17 -20 -20 -27 -9 -25 -26 5
-49 14 -10 25 -23 25 -28 0 -18 53 -58 76 -58 19 0 24 5 24 25 0 14 5 25 10
25 6 0 10 -4 10 -10 0 -5 6 -10 14 -10 8 0 16 -7 20 -15 9 -23 63 -19 69 5 3
11 12 20 21 20 9 0 16 8 16 17 0 13 3 14 12 5 7 -7 24 -12 39 -12 40 0 59 -10
59 -31 0 -10 7 -19 15 -19 9 0 15 -9 15 -25 0 -16 6 -25 15 -25 10 0 15 10 15
26 0 25 32 64 52 64 13 0 9 28 -4 33 -10 4 -10 6 0 6 7 1 15 -6 18 -14 7 -17
34 -21 34 -5 0 6 9 10 20 10 11 0 20 -6 20 -12 -1 -18 -25 -48 -39 -48 -6 0
-11 -9 -11 -20 0 -13 7 -20 20 -20 11 0 20 7 20 15 0 12 3 12 20 -2 11 -10 20
-25 20 -34 0 -10 6 -20 13 -22 17 -8 -12 -27 -41 -27 -21 0 -22 -4 -18 -37 3
-21 3 -29 -1 -18 -3 11 -11 24 -19 28 -8 4 -14 13 -14 18 0 16 -27 39 -44 39
-9 0 -16 -6 -16 -14 0 -9 -7 -12 -20 -9 -11 3 -20 10 -20 15 0 5 -10 8 -22 6
-49 -7 -26 -98 24 -98 11 0 27 -7 35 -16 10 -10 25 -13 40 -9 19 4 26 1 30
-15 3 -11 9 -20 13 -20 5 0 11 -13 15 -30 7 -31 38 -42 49 -17 5 10 7 10 12 0
7 -15 54 -17 54 -3 0 6 7 10 15 10 8 0 15 -4 15 -10 0 -5 -6 -10 -14 -10 -8 0
-17 -13 -21 -30 -5 -21 -13 -30 -26 -30 -34 0 -22 -23 35 -64 14 -10 26 -25
26 -32 0 -7 5 -14 11 -16 6 -2 -24 -2 -65 0 -65 4 -76 2 -76 -12 0 -9 -4 -16
-10 -16 -5 0 -10 -9 -10 -20 0 -15 -7 -20 -25 -20 -16 0 -25 -6 -25 -15 0 -8
5 -15 10 -15 6 0 10 -9 10 -20 0 -14 7 -20 21 -20 18 0 20 4 15 28 -5 20 -4
23 3 11 14 -22 2 -89 -15 -89 -8 0 -14 5 -14 10 0 6 -6 10 -14 10 -15 0 -26
42 -26 97 0 19 -7 41 -15 49 -25 25 -18 35 20 28 34 -6 35 -5 35 25 0 21 5 31
15 31 8 0 15 -4 15 -10 0 -5 7 -10 15 -10 21 0 20 56 -1 64 -9 3 -29 6 -45 6
-19 0 -29 5 -29 15 0 8 -9 23 -20 32 -11 10 -20 23 -20 30 0 7 -9 13 -20 13
-13 0 -20 7 -20 20 0 11 -7 20 -15 20 -8 0 -15 7 -15 15 0 8 -4 15 -10 15 -5
0 -10 6 -10 13 0 7 -9 23 -20 35 -17 18 -18 22 -5 22 11 0 15 11 15 45 0 33
-4 45 -15 45 -8 0 -15 -4 -15 -10 0 -5 -13 -10 -30 -10 -20 0 -30 5 -30 15 0
8 -4 15 -10 15 -5 0 -10 -18 -10 -41 0 -37 -2 -40 -22 -33 -13 4 -26 12 -30
18 -4 6 -10 7 -14 3 -4 -4 -13 6 -20 23 -13 30 -44 42 -44 16 0 -8 -7 -16 -15
-20 -11 -4 -15 -20 -15 -55 0 -34 -4 -51 -15 -55 -10 -3 -15 -20 -16 -48 0
-24 -3 -37 -6 -30 -2 6 -15 12 -28 12 -16 0 -25 7 -29 24 -9 35 3 56 30 56 14
0 24 6 24 14 0 8 5 16 11 18 23 8 -1 45 -32 52 -16 3 -32 14 -36 23 -4 10 -11
22 -17 26 -8 6 -7 10 2 14 23 9 12 32 -23 44 -19 7 -35 19 -35 26 0 7 -4 13
-10 13 -5 0 -10 5 -10 10 0 6 7 10 15 10 8 0 15 7 15 15 0 9 -7 18 -15 21 -8
4 -15 12 -15 20 0 8 -9 14 -20 14 -19 0 -20 -4 -14 -52 7 -58 18 -78 43 -79
10 0 11 -2 4 -6 -15 -6 -18 -83 -3 -83 6 0 10 -4 10 -10 0 -5 -13 -10 -30 -10
-22 0 -30 5 -30 18 0 10 -9 26 -21 35 -19 17 -20 25 -14 88 l7 69 -30 0 c-17
0 -38 -7 -47 -16 -9 -9 -18 -14 -21 -11 -7 6 44 57 56 57 6 0 10 9 10 20 0 11
-7 20 -15 20 -8 0 -15 7 -15 16 0 8 -6 22 -14 30 -19 19 -56 18 -56 -1 0 -8 6
-15 13 -15 8 0 9 -3 1 -8 -6 -4 -14 -14 -17 -22 -3 -9 -6 -4 -6 13 -1 15 -5
27 -11 27 -5 0 -10 11 -10 25 0 18 -5 25 -20 25 -15 0 -20 -7 -20 -29 0 -16 6
-31 14 -34 8 -3 17 -19 21 -36 4 -17 11 -31 16 -31 5 0 9 -9 9 -19 0 -11 7
-27 16 -35 14 -15 14 -16 0 -16 -12 0 -15 -8 -14 -32 2 -24 -2 -33 -14 -36
-15 -3 -18 4 -18 33 0 28 -5 38 -20 42 -11 3 -20 12 -20 19 0 8 -4 14 -9 14
-5 0 -11 9 -14 20 -6 24 -47 28 -47 5 0 -26 -19 -16 -26 13 -4 15 -12 30 -18
35 -8 5 -6 10 4 16 12 8 10 14 -13 35 -30 28 -35 46 -12 46 8 0 15 7 15 15 0
8 5 15 10 15 6 0 10 9 10 20 0 11 -4 20 -10 20 -5 0 -10 7 -10 15 0 8 -6 15
-13 15 -7 0 -18 9 -24 19 -10 16 -9 18 8 14 14 -4 19 0 19 16 0 12 6 21 15 21
8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 -7 -15 -15 0 -8 -7 -15 -15 -15 -8
0 -15 5 -15 10 0 6 -7 10 -15 10 -8 0 -15 9 -15 20 0 22 -12 25 -41 10 -24
-14 -28 -90 -4 -90 8 0 15 -8 15 -19 0 -14 -6 -17 -25 -13 -22 4 -25 1 -25
-22 0 -29 16 -66 34 -78 7 -5 4 -8 -6 -8 -13 0 -18 -8 -18 -29 0 -17 -4 -32
-10 -36 -15 -9 -48 17 -55 43 -9 35 -29 52 -64 52 -17 0 -31 4 -31 9 0 5 -7
17 -16 25 -14 15 -14 16 0 16 9 0 16 -7 16 -15 0 -9 9 -15 25 -15 26 0 35 24
13 33 -21 9 12 27 48 27 27 0 32 5 37 29 3 15 3 38 0 50 -6 24 -33 29 -33 6 0
-8 -4 -15 -10 -15 -5 0 -10 6 -10 14 0 7 -3 28 -6 45 -5 24 -3 31 10 31 9 0
16 8 16 20 0 11 -3 20 -7 20 -11 0 -33 50 -33 72 0 10 -4 18 -10 18 -5 0 -10
7 -10 15 0 8 -9 15 -20 15 -13 0 -20 7 -20 19 0 10 -7 22 -15 25 -14 5 -15 -9
-14 -100 l1 -105 29 6 c22 5 29 3 29 -9 0 -9 -4 -16 -10 -16 -13 0 -13 -37 -1
-42 5 -1 13 -15 16 -30 5 -21 4 -28 -8 -28 -9 0 -17 14 -21 39 -3 21 -13 41
-21 45 -13 5 -15 -12 -15 -113 0 -133 8 -211 22 -211 5 0 17 8 26 17 16 16 17
15 21 -3 2 -10 8 -22 14 -26 6 -4 12 -22 13 -40 0 -18 4 -52 8 -75 5 -29 4
-43 -3 -43 -6 0 -11 9 -11 20 0 11 -4 20 -9 20 -5 0 -12 14 -16 30 -4 17 -11
30 -16 30 -5 0 -9 9 -9 20 0 11 -6 20 -14 20 -8 0 -17 6 -19 13 -3 6 -6 -18
-6 -54 -1 -48 3 -70 14 -79 20 -17 20 -64 0 -56 -13 5 -14 -17 -12 -161 2
-119 6 -168 15 -171 7 -2 12 -12 12 -22 0 -11 10 -28 22 -40 12 -11 18 -23 15
-27 -4 -3 -7 -1 -7 5 0 7 -11 12 -24 12 -18 0 -26 7 -29 23 -2 12 -5 -8 -6
-45 -1 -76 5 -98 26 -98 12 0 14 8 10 35 -5 30 -3 35 14 35 16 0 19 -7 19 -50
0 -27 5 -50 10 -50 6 0 10 -7 10 -15 0 -17 18 -52 38 -73 6 -7 12 -22 12 -32
0 -10 4 -20 9 -22 5 -1 3 -9 -5 -16 -8 -7 -14 -8 -14 -2 0 14 -27 13 -33 -2
-4 -10 -6 -10 -6 0 -1 6 -8 12 -16 12 -8 0 -15 7 -15 15 0 8 -11 17 -24 21
-14 3 -27 13 -29 22 -3 10 -5 3 -6 -13 -1 -20 7 -37 20 -49 16 -14 20 -25 15
-49 -3 -17 -12 -34 -19 -39 -8 -5 -13 -47 -15 -134 -2 -117 -1 -129 18 -146
11 -10 20 -25 20 -33 0 -8 5 -15 10 -15 6 0 10 -11 10 -25 0 -16 -6 -25 -15
-25 -9 0 -15 -9 -15 -25 0 -29 -16 -34 -23 -7 -3 9 -5 -41 -5 -112 0 -72 2
-136 5 -143 6 -15 33 -18 33 -3 0 21 20 9 26 -15 3 -14 12 -25 20 -25 15 0 18
-27 8 -54 -3 -9 -13 -16 -21 -16 -11 0 -13 -11 -11 -42 3 -41 5 -43 38 -48 29
-4 36 -10 38 -32 2 -19 10 -30 27 -34 15 -4 25 -15 29 -35 4 -20 11 -29 25
-29 31 0 83 -58 75 -84 -9 -28 -9 -29 -33 -21 -11 3 -23 15 -26 26 -4 10 -10
19 -15 19 -4 0 -13 14 -19 30 -5 17 -15 30 -20 30 -6 0 -11 7 -11 15 0 8 -6
15 -14 15 -8 0 -17 14 -21 30 -5 21 -13 30 -26 30 -10 0 -19 -7 -19 -15 0 -8
-4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -23 -29 -6 -36 21 -3 13 -10 24 -14 24
-5 0 -11 12 -13 28 -3 15 -4 -34 -4 -108 1 -134 1 -135 28 -161 16 -15 31 -43
35 -65 4 -21 13 -45 21 -52 7 -7 13 -24 13 -38 0 -17 5 -24 20 -24 15 0 20 7
20 25 0 14 5 25 10 25 6 0 10 10 11 23 0 15 2 17 6 7 3 -8 11 -18 17 -22 8 -5
7 -8 -1 -8 -8 0 -13 -14 -13 -38 0 -31 5 -41 24 -51 13 -8 29 -10 35 -7 6 4
11 0 11 -8 0 -9 7 -16 15 -16 8 0 15 5 15 10 0 6 7 10 15 10 8 0 15 -4 15 -10
0 -5 7 -10 15 -10 18 0 20 36 3 44 -10 5 -10 7 0 12 17 8 15 34 -2 34 -8 0
-16 -4 -18 -8 -1 -5 -16 -12 -33 -15 -16 -3 -24 -4 -17 -1 17 8 16 94 -1 94
-22 0 -57 36 -56 56 5 46 -2 74 -16 74 -8 0 -15 7 -15 16 0 13 2 13 15 2 8 -7
21 -18 29 -25 14 -12 58 -18 108 -15 l27 2 -5 77 c-2 43 -8 80 -13 83 -5 3
-12 20 -16 38 -5 25 -3 32 8 32 9 0 18 13 22 30 5 23 12 30 31 30 15 0 24 6
24 15 0 8 -4 15 -10 15 -5 0 -10 5 -10 11 0 16 26 39 44 39 12 0 16 10 16 39
0 22 -5 41 -12 43 -7 3 -6 7 5 11 9 3 17 2 17 -3 0 -6 7 -10 16 -10 13 0 15 9
10 58 -3 31 -3 50 1 42 3 -8 10 -18 15 -21 15 -10 16 -79 1 -79 -7 0 -13 -7
-13 -15 0 -8 5 -15 10 -15 6 0 10 -7 10 -16 0 -13 7 -15 35 -10 31 6 35 4 35
-14 0 -11 -7 -20 -15 -20 -8 0 -15 -9 -15 -20 0 -11 6 -20 14 -20 9 0 16 -18
20 -50 5 -41 10 -50 26 -50 17 0 20 -7 20 -46 0 -46 16 -74 43 -74 10 0 13 -7
10 -20 -3 -11 -12 -20 -19 -20 -15 0 -18 -29 -8 -67 4 -16 14 -23 29 -23 17 0
25 -7 27 -22 4 -30 38 -37 38 -9 0 12 -6 21 -12 21 -10 0 -10 2 -1 8 6 4 15
20 18 35 5 20 4 27 -8 27 -14 0 -27 28 -27 58 0 6 9 12 20 12 15 0 20 -7 20
-25 0 -14 5 -25 10 -25 6 0 10 -7 10 -16 0 -12 6 -15 25 -10 18 5 25 2 25 -9
0 -8 7 -15 15 -15 13 0 15 -9 9 -52 -4 -29 -11 -54 -15 -56 -15 -6 -10 -32 6
-32 8 0 15 -7 15 -15 0 -8 -9 -15 -20 -15 -25 0 -38 -29 -19 -41 11 -7 12 -12
2 -29 -17 -27 -16 -46 4 -70 37 -43 80 -17 89 54 5 34 11 46 24 46 14 0 17 10
17 55 0 40 -4 55 -13 55 -8 0 -14 7 -14 15 0 11 8 13 30 9 23 -5 30 -3 30 10
0 9 -7 16 -16 16 -20 0 -49 29 -39 38 4 4 10 1 12 -5 3 -7 11 -13 18 -13 7 0
16 6 18 13 3 7 6 6 6 -5 1 -9 10 -20 21 -23 11 -3 20 -13 20 -21 0 -8 5 -14
10 -14 6 0 10 -13 10 -30 0 -16 -4 -30 -10 -30 -18 0 -11 -20 15 -44 14 -13
25 -29 25 -35 0 -6 7 -14 15 -17 17 -7 21 -44 5 -44 -5 0 -10 -13 -10 -30 0
-16 5 -30 10 -30 6 0 10 -6 10 -14 0 -8 10 -16 23 -18 20 -3 22 -9 25 -68 2
-35 8 -68 15 -72 6 -4 8 -11 3 -15 -5 -4 -11 -2 -13 5 -6 17 -33 15 -33 -2 0
-8 14 -17 30 -21 23 -5 30 -12 30 -31 0 -13 5 -24 10 -24 6 0 10 -7 10 -15 0
-8 6 -33 12 -55 10 -31 18 -40 35 -40 16 0 23 6 23 20 0 11 -6 20 -13 20 -9 0
-13 18 -12 64 0 46 3 62 13 59 6 -3 12 -11 12 -19 0 -24 28 -15 34 11 10 39
-3 73 -30 79 -13 4 -24 11 -24 16 0 6 -6 10 -14 10 -8 0 -18 4 -21 10 -4 6 11
7 39 4 38 -6 46 -4 46 10 0 9 -4 16 -10 16 -5 0 -10 9 -10 20 0 16 -7 20 -34
20 -39 0 -57 25 -26 35 14 4 22 19 26 46 5 31 11 39 28 39 32 0 47 14 45 41
-4 46 1 69 16 69 10 0 15 10 15 29 0 17 6 31 16 35 26 10 34 7 34 -14 0 -11
-4 -20 -10 -20 -5 0 -10 -9 -10 -20 0 -11 4 -20 9 -20 5 0 11 -6 14 -13 4 -10
-3 -12 -24 -9 -24 4 -29 1 -29 -17 0 -11 4 -21 9 -21 6 0 11 -21 13 -47 l3
-48 53 -3 53 -3 -7 36 c-5 30 -4 34 10 29 13 -5 16 -21 16 -80 0 -72 -1 -74
-25 -74 -16 0 -25 -6 -25 -15 0 -8 5 -15 10 -15 6 0 10 -9 10 -20 0 -11 -7
-20 -15 -20 -8 0 -15 9 -15 20 0 11 -4 20 -10 20 -5 0 -10 7 -10 15 0 8 5 15
10 15 6 0 10 11 10 25 0 16 -6 25 -15 25 -8 0 -15 5 -15 10 0 6 -7 10 -15 10
-8 0 -15 -4 -15 -10 0 -5 -9 -10 -20 -10 -17 0 -20 -7 -20 -45 0 -34 4 -45 15
-45 9 0 18 -7 21 -15 4 -8 12 -15 20 -15 8 0 14 -7 14 -15 0 -9 7 -18 15 -21
8 -4 15 -15 15 -25 0 -25 28 -24 50 1 17 20 18 20 24 -16 7 -32 10 -35 32 -30
20 5 27 3 31 -14 3 -11 9 -20 14 -20 20 0 7 -20 -16 -26 -34 -8 -32 -34 3 -35
15 0 21 -3 15 -6 -15 -6 -18 -73 -3 -73 6 0 10 -7 10 -15 0 -8 -7 -15 -15 -15
-9 0 -15 9 -15 25 0 20 -5 25 -25 25 -16 0 -25 6 -25 16 0 11 -5 14 -16 10 -9
-3 -18 -6 -20 -6 -2 0 -4 -7 -4 -15 0 -9 -7 -18 -15 -21 -17 -7 -21 -34 -5
-34 6 0 10 -4 10 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -9 -15 -20 0 -17 7 -20 40
-20 22 0 40 4 40 10 0 5 7 7 15 4 8 -4 15 -2 15 4 0 16 59 20 71 5 20 -23 47
-15 52 15 4 25 4 25 6 -5 0 -21 6 -33 15 -33 10 0 14 -15 13 -57 0 -32 3 -71
8 -87 6 -21 5 -27 -4 -22 -6 4 -11 18 -11 32 0 19 -5 24 -25 24 -20 0 -25 -5
-25 -26 0 -17 -6 -28 -20 -31 -14 -4 -20 -14 -20 -34 0 -15 -6 -32 -13 -36 -8
-4 -17 -29 -20 -55 -5 -39 -4 -48 8 -48 8 0 15 8 16 18 0 10 3 12 6 5 2 -7 10
-13 18 -13 7 0 15 -13 19 -30 7 -37 36 -41 36 -5 0 20 6 27 30 32 21 4 30 11
30 24 0 11 -4 19 -10 19 -5 0 -10 12 -10 26 0 17 -6 28 -20 31 -24 6 -26 19
-4 27 22 9 24 8 24 -10 0 -23 55 -74 79 -74 14 0 21 -6 21 -20 0 -11 5 -20 10
-20 6 0 10 -7 10 -15 0 -8 -4 -15 -10 -15 -5 0 -10 -8 -10 -17 0 -15 -11 -19
-57 -21 -66 -3 -71 -6 -55 -32 15 -24 16 -65 2 -91 -13 -23 -40 -26 -40 -4 0
8 -7 15 -15 15 -11 0 -15 -12 -15 -50 0 -38 4 -50 15 -50 10 0 15 -10 15 -30
0 -25 -4 -30 -25 -30 -23 0 -35 -22 -17 -33 4 -2 1 -2 -6 -1 -23 6 -42 -8 -42
-32 0 -15 -6 -24 -15 -24 -8 0 -15 5 -15 10 0 6 -11 10 -25 10 -24 0 -25 2
-19 40 5 33 4 40 -10 40 -9 0 -16 -7 -16 -14 0 -8 -12 -21 -27 -28 -33 -16
-44 -58 -16 -59 14 0 15 -2 3 -6 -31 -12 -43 -63 -15 -63 9 0 18 -7 21 -15 4
-8 18 -15 33 -16 14 0 20 -3 14 -6 -15 -6 -17 -33 -3 -33 6 0 10 -16 10 -35 0
-19 -4 -35 -10 -35 -5 0 -10 -16 -10 -36 0 -34 1 -35 30 -29 23 5 30 3 30 -8
0 -9 -14 -17 -35 -21 -19 -4 -35 -13 -35 -20 0 -21 -88 -15 -94 7 -2 10 1 17
10 17 9 0 14 11 14 30 0 23 5 30 19 30 11 0 21 9 25 23 9 34 8 37 -14 37 -14
0 -18 5 -13 18 4 9 13 22 21 28 9 7 12 18 8 32 -10 30 -32 28 -40 -3 -4 -14
-13 -25 -22 -25 -12 0 -14 12 -8 75 l7 75 -37 0 c-25 0 -36 4 -36 15 0 17 -26
21 -32 5 -1 -5 -4 15 -4 45 -1 34 -7 60 -17 69 -17 18 -57 22 -57 6 0 -5 -7
-10 -15 -10 -8 0 -15 -7 -15 -16 0 -10 7 -14 23 -12 13 2 22 -2 21 -9 -1 -7
-2 -20 -2 -28 -2 -10 -13 -15 -37 -15 -19 0 -35 -4 -35 -10 0 -5 6 -10 14 -10
13 0 26 -20 26 -41 0 -14 -30 -11 -46 5 -8 8 -14 21 -14 30 0 8 -9 17 -19 21
-10 3 -22 17 -25 30 -4 14 -11 25 -16 25 -6 0 -10 7 -10 15 0 7 -8 19 -17 24
-10 6 -12 11 -5 11 6 0 12 7 12 16 0 11 -8 14 -32 12 -32 -3 -33 -4 -35 -55
-1 -36 2 -53 11 -53 7 0 18 -16 26 -35 8 -19 20 -35 28 -35 11 0 13 -9 8 -37
-3 -21 -6 -48 -6 -60 0 -13 -6 -23 -13 -23 -7 0 -20 -9 -30 -20 -15 -18 -16
-23 -3 -35 19 -19 46 -19 46 0 0 8 9 15 20 15 13 0 20 -7 20 -20 0 -13 7 -20
20 -20 16 0 20 -7 20 -30 0 -16 4 -30 9 -30 5 0 11 -9 14 -20 4 -17 0 -20 -33
-20 -22 0 -45 7 -55 16 -12 13 -19 14 -30 4 -22 -19 -18 -38 14 -71 17 -16 33
-38 36 -49 4 -11 10 -20 15 -20 5 0 14 -9 20 -20 13 -24 -2 -27 -24 -5 -18 18
-33 19 -39 3 -3 -7 -6 2 -6 20 -1 25 -5 32 -21 32 -16 0 -20 -6 -18 -32 3 -30
1 -33 -24 -30 -22 2 -28 -1 -28 -16 0 -23 25 -32 88 -32 31 0 42 -4 42 -15 0
-8 7 -15 15 -15 11 0 13 -8 8 -32 -4 -18 -12 -39 -20 -46 -7 -7 -13 -20 -13
-28 0 -22 -17 -16 -24 9 -9 33 -8 37 9 37 8 0 15 6 15 14 0 10 -11 14 -35 13
-35 -1 -35 -1 -35 -48 0 -29 5 -49 13 -52 6 -3 2 -6 -9 -6 -17 -1 -23 7 -28
34 -7 34 -36 51 -36 20 0 -8 -7 -15 -15 -15 -19 0 -19 -29 0 -36 17 -7 21 -34
5 -34 -5 0 -10 -7 -10 -16 0 -12 9 -15 38 -13 60 3 62 2 56 -31 -6 -29 -5 -30
29 -30 30 0 34 -3 30 -20 -4 -14 0 -20 11 -20 9 0 16 -8 16 -20 0 -11 5 -20
10 -20 6 0 10 9 10 20 0 11 5 20 10 20 6 0 10 9 10 21 0 36 34 19 38 -19 2
-17 7 -32 11 -32 5 0 11 -9 14 -20 5 -18 0 -20 -44 -20 -37 0 -49 -4 -49 -15
0 -8 -6 -15 -14 -15 -8 0 -17 -7 -20 -16 -9 -23 -8 -24 15 -24 11 0 27 -7 35
-15 21 -21 41 -19 47 5 3 11 10 20 16 20 13 0 15 -27 2 -32 -5 -1 -13 -15 -16
-30 -6 -24 -4 -28 14 -28 12 0 21 -4 21 -10 0 -5 9 -10 20 -10 11 0 20 5 20
10 0 6 7 10 15 10 8 0 15 5 15 10 0 6 6 10 14 10 22 0 76 -34 76 -47 0 -7 9
-13 19 -13 26 0 51 -10 51 -21 0 -5 -7 -9 -15 -9 -8 0 -15 5 -15 10 0 6 -9 10
-20 10 -15 0 -20 -7 -20 -29 0 -32 -14 -41 -67 -41 -33 0 -35 -2 -28 -27 3
-15 15 -34 26 -44 10 -9 19 -23 19 -32 0 -8 7 -17 15 -21 21 -8 19 -26 -4 -26
-38 0 -51 -11 -51 -46 0 -24 -5 -34 -15 -34 -8 0 -15 -6 -15 -12 0 -10 -2 -10
-8 -1 -4 7 -26 15 -49 19 -42 6 -42 6 -48 -30 -4 -20 -11 -36 -16 -36 -5 0 -9
-7 -9 -15 0 -8 5 -15 10 -15 6 0 10 -7 10 -15 0 -8 8 -15 18 -16 13 0 14 -2 2
-6 -33 -13 -57 -63 -30 -63 14 0 13 -27 -2 -32 -17 -6 0 -28 23 -28 10 0 19
-4 19 -10 0 -5 -4 -10 -9 -10 -4 0 -11 -17 -14 -37 -5 -30 -2 -40 19 -60 14
-13 30 -23 35 -23 29 0 54 63 28 72 -9 3 -13 24 -22 111 -2 16 -10 33 -18 38
-12 7 -11 9 3 9 9 0 19 10 21 23 l4 22 2 -22 c0 -13 5 -23 10 -23 24 0 41 14
41 35 0 36 11 55 31 55 20 0 26 16 9 22 -14 5 0 48 16 48 8 0 14 9 14 19 0 35
12 51 36 51 20 0 24 5 24 30 0 17 -4 30 -10 30 -5 0 -10 9 -10 20 0 13 7 20
20 20 11 0 20 7 20 15 0 8 5 15 10 15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 5 -10 10 0 6 7 10 16 10 14 0 15 -7 10 -40 -5 -32 -3 -40 9 -40 16 0 21
-36 16 -117 -2 -30 2 -42 12 -45 13 -3 13 -5 1 -16 -12 -11 -14 -10 -14 3 0 8
-7 15 -15 15 -10 0 -15 -11 -15 -35 0 -40 -8 -43 -38 -16 -23 19 -29 41 -12
41 6 0 10 7 10 15 0 9 -9 15 -24 15 -20 0 -24 -6 -30 -40 -3 -22 -3 -52 0 -65
5 -21 3 -25 -16 -25 -19 0 -21 -4 -15 -30 4 -19 3 -30 -4 -30 -6 0 -11 -7 -11
-15 0 -8 5 -15 10 -15 6 0 10 5 10 10 0 6 7 10 15 10 8 0 15 3 15 8 0 4 20 8
45 9 45 1 66 13 78 46 3 9 16 17 27 17 19 0 21 -4 15 -32 -11 -54 -16 -68 -25
-68 -5 0 -12 -16 -16 -35 -3 -19 -10 -35 -15 -35 -17 0 -9 -28 9 -33 16 -4 16
-5 0 -6 -29 -2 -22 -31 7 -31 16 0 25 6 25 15 0 8 3 15 6 15 3 0 12 -7 20 -15
9 -8 11 -15 6 -15 -5 0 -12 -14 -16 -31 -4 -19 -13 -33 -26 -36 -17 -4 -20
-14 -20 -55 0 -42 -3 -49 -16 -44 -9 3 -19 6 -24 6 -4 0 -10 9 -13 20 -3 13 0
20 9 20 9 0 14 11 14 30 0 17 -4 30 -10 30 -5 0 -10 7 -10 15 0 8 5 15 10 15
6 0 10 16 10 35 0 46 -27 48 -33 3 -2 -18 -3 -9 -1 20 4 47 2 52 -16 52 -11 0
-20 -7 -20 -15 0 -9 -12 -27 -27 -41 -20 -19 -24 -27 -15 -36 15 -15 16 -48 2
-48 -5 0 -10 7 -10 15 0 10 -11 15 -35 15 -39 0 -47 -20 -18 -48 16 -16 16
-17 0 -20 -39 -8 -11 -42 34 -42 22 0 24 -8 4 -34 -11 -14 -26 -18 -76 -17
-59 1 -61 0 -55 -22 3 -12 6 -34 6 -49 0 -18 5 -28 15 -28 10 0 15 10 15 28 0
15 3 22 8 15 4 -6 20 -15 35 -18 37 -10 35 -35 -2 -35 -16 0 -31 -6 -34 -12
-3 -9 -13 -4 -30 15 -14 15 -31 27 -37 27 -5 0 -10 6 -10 14 0 7 -14 19 -30
26 -17 7 -30 16 -30 21 0 5 -7 9 -15 9 -10 0 -15 -10 -15 -29 0 -25 5 -30 28
-34 l27 -4 -27 -2 c-23 -1 -28 -6 -28 -26 0 -16 -6 -25 -15 -25 -8 0 -15 -4
-15 -10 0 -5 4 -10 10 -10 5 0 12 -17 16 -37 4 -21 11 -41 17 -45 6 -4 4 -12
-6 -23 -15 -15 -17 -15 -17 -1 0 9 -4 16 -10 16 -5 0 -10 9 -10 20 0 15 -7 20
-25 20 -14 0 -25 5 -25 10 0 6 -10 10 -23 10 -29 0 -49 -33 -41 -68 l7 -27
-21 24 c-27 30 -52 21 -52 -19 0 -24 4 -30 20 -30 18 0 20 -4 14 -35 -5 -28
-3 -35 10 -35 9 0 16 5 16 10 0 15 40 12 56 -5 23 -22 49 -18 70 10 10 14 24
25 31 25 7 0 13 5 13 10 0 6 9 10 20 10 14 0 20 7 20 21 0 17 5 20 33 17 28
-3 32 -7 35 -35 2 -25 -1 -33 -12 -33 -9 0 -16 -7 -16 -16 0 -14 -1 -14 -16 0
-21 22 -46 20 -67 -4 -19 -22 -23 -50 -7 -50 6 0 10 -15 10 -34 0 -19 7 -41
15 -50 17 -17 20 -36 5 -36 -5 0 -10 -9 -10 -20 0 -11 -6 -20 -14 -20 -10 0
-16 -13 -19 -37 -2 -23 -4 -12 -5 30 -1 37 -6 67 -12 67 -5 0 -10 7 -10 15 0
8 5 15 10 15 6 0 10 9 10 20 0 23 -28 27 -36 5 -4 -8 -10 -15 -15 -15 -5 0 -9
7 -9 15 0 8 5 15 10 15 22 0 8 30 -17 36 -15 4 -34 13 -41 21 -18 17 -32 17
-32 -2 0 -8 -7 -15 -15 -15 -11 0 -13 -11 -9 -52 2 -29 2 -47 -2 -40 -3 6 -17
12 -30 12 -15 0 -24 6 -24 15 0 8 -4 15 -10 15 -5 0 -10 16 -10 35 0 19 -4 35
-10 35 -5 0 -10 18 -10 39 0 41 -20 71 -46 71 -8 0 -14 -7 -14 -15 0 -8 5 -15
10 -15 6 0 10 -7 10 -15 0 -19 -7 -19 -26 -1 -8 8 -14 34 -14 60 0 34 -4 46
-15 46 -8 0 -15 7 -15 16 0 8 -7 23 -16 31 -12 12 -14 27 -10 55 5 30 3 38 -9
38 -8 0 -15 6 -15 14 0 25 -64 37 -73 14 -4 -10 -6 -10 -6 0 -1 6 -8 12 -16
12 -8 0 -15 5 -15 10 0 6 9 10 20 10 11 0 20 7 20 15 0 10 11 15 35 15 33 0
35 -2 29 -25 -5 -18 -2 -25 9 -25 8 0 18 6 20 13 3 7 6 5 6 -5 1 -12 9 -18 26
-18 26 0 35 24 13 33 -10 4 -10 6 0 6 7 1 12 15 12 36 0 28 4 35 20 35 11 0
20 -4 20 -10 0 -21 30 -9 36 15 3 14 10 25 15 25 15 0 10 60 -5 76 -21 20 -36
17 -36 -6 0 -18 -4 -20 -40 -14 -33 5 -40 4 -40 -10 0 -12 -9 -16 -35 -16 -29
0 -35 -4 -35 -20 0 -19 -4 -20 -37 -14 -21 4 -41 11 -44 17 -8 12 1 47 12 47
5 0 9 9 9 20 0 16 -7 20 -36 20 -34 0 -36 -2 -30 -25 5 -18 2 -25 -8 -25 -14
0 -33 86 -22 98 3 3 6 0 6 -6 0 -17 17 -15 40 5 24 21 26 43 5 43 -8 0 -15 7
-15 15 0 8 4 15 9 15 5 0 11 8 14 18 3 13 6 10 11 -10 6 -19 15 -28 34 -30 19
-2 28 -10 30 -25 2 -16 -2 -23 -12 -23 -26 0 -20 -47 6 -54 51 -13 65 -8 72
29 4 19 11 35 16 35 6 0 10 7 10 16 0 16 -23 44 -37 44 -11 0 -30 43 -25 58 2
6 19 14 38 18 29 5 34 10 34 35 0 24 -5 29 -35 35 -37 7 -46 21 -20 30 17 7
21 34 5 34 -5 0 -10 12 -10 28 -1 26 -20 62 -33 62 -15 0 -47 -36 -47 -52 0
-13 -8 -18 -30 -18 -20 0 -30 -5 -30 -15 0 -8 -9 -15 -20 -15 -11 0 -20 6 -20
14 0 8 -9 18 -21 21 -15 5 -19 12 -15 28 3 12 6 25 6 30 0 4 14 7 30 7 20 0
30 -5 30 -15 0 -10 10 -15 26 -15 21 0 25 4 21 19 -3 14 0 17 14 14 14 -4 19
0 19 15 0 11 7 22 15 26 20 7 19 136 -2 136 -7 0 -15 11 -19 25 -3 14 -12 25
-20 25 -8 0 -14 10 -14 25 0 29 26 25 35 -6 4 -10 11 -19 16 -19 5 0 9 -7 9
-15 0 -8 9 -15 20 -15 11 0 20 4 20 9 0 21 -13 41 -26 41 -9 0 -14 11 -14 30
0 20 -5 30 -15 30 -11 0 -15 12 -15 44 0 48 -9 56 -66 56 -38 0 -49 -28 -14
-37 26 -7 26 -23 0 -23 -13 0 -20 -7 -20 -20 0 -11 -4 -20 -10 -20 -5 0 -10
-7 -10 -15 0 -8 7 -15 15 -15 9 0 15 -9 15 -21 0 -24 22 -49 43 -49 24 0 38
-105 16 -112 -17 -6 -1 -48 18 -48 9 0 14 -2 11 -5 -9 -10 -118 -13 -118 -4 0
5 -4 9 -10 9 -5 0 -10 -9 -10 -19 0 -11 -6 -21 -12 -24 -21 -6 -48 4 -48 19 0
8 -4 14 -10 14 -5 0 -10 7 -10 15 0 8 4 15 9 15 5 0 11 9 14 20 3 11 12 20 21
20 11 0 16 9 16 30 0 36 -20 40 -52 11 -13 -13 -18 -26 -14 -38 6 -16 5 -15
-12 2 -10 11 -15 21 -11 23 15 6 10 22 -14 45 -13 12 -26 32 -29 44 -5 19 -3
20 23 11 16 -5 29 -17 29 -25 0 -10 6 -13 16 -9 9 3 18 6 20 6 2 0 4 9 4 20 0
11 -4 20 -9 20 -12 0 -28 50 -23 73 3 14 10 17 33 12 18 -4 29 -3 29 4 0 6 -4
11 -10 11 -17 0 -12 72 7 85 19 14 11 45 -11 45 -8 0 -26 -11 -40 -25 -14 -14
-34 -25 -46 -25 -11 0 -20 -4 -20 -10 0 -5 -7 -10 -16 -10 -27 0 -13 -53 18
-68 16 -7 28 -18 27 -25 0 -9 -2 -9 -6 1 -6 15 -39 16 -49 1 -3 -6 0 -15 7
-20 7 -4 10 -11 7 -15 -4 -3 -14 1 -23 10 -9 9 -21 16 -26 16 -5 0 -9 14 -9
30 0 27 -3 30 -30 30 -20 0 -30 5 -30 15 0 19 -37 20 -56 1 -16 -17 -19 -46
-4 -46 6 0 10 -16 10 -35 0 -24 5 -35 15 -35 8 0 15 -4 15 -10 0 -5 -4 -10 -9
-10 -5 0 -12 -10 -14 -22 l-4 -23 -2 23 c0 12 -5 22 -10 22 -5 0 -12 11 -15
24 -3 13 -13 26 -21 30 -12 4 -15 -5 -15 -56 0 -61 24 -121 57 -141 18 -11 16
-27 -2 -27 -8 0 -15 -7 -15 -15 0 -8 -9 -15 -20 -15 -18 0 -20 -6 -19 -77 1
-43 4 -66 6 -50 5 32 12 34 39 9 11 -11 26 -22 34 -25 8 -3 0 -6 -17 -6 -22
-1 -33 -6 -33 -16 0 -8 -7 -15 -15 -15 -16 0 -19 -17 -9 -44 8 -22 84 -23 84
-1 0 9 9 15 25 15 16 0 25 6 25 15 0 8 -8 15 -17 15 -12 0 -14 3 -6 8 6 4 13
20 15 36 4 38 28 47 28 12 0 -16 6 -26 15 -26 9 0 15 -9 15 -25 0 -14 -4 -25
-10 -25 -5 0 -10 -9 -10 -20 0 -11 5 -20 10 -20 6 0 10 -8 10 -18 0 -17 -9
-19 -92 -18 l-93 1 -2 -617 c-3 -543 -1 -618 12 -618 8 0 15 -7 15 -15 0 -9 7
-18 15 -21 15 -6 21 -34 7 -34 -9 0 -37 32 -45 51 -3 8 -5 -240 -5 -551 0
-311 3 -556 5 -546 7 26 33 53 33 34 0 -29 -13 -58 -25 -58 -11 0 -14 -120
-14 -640 -1 -353 2 -652 5 -665 4 -18 13 -25 30 -25 25 0 33 -15 12 -22 -15
-5 6 -30 34 -41 22 -8 24 -27 3 -27 -8 0 -15 -7 -15 -15 0 -8 -9 -15 -20 -15
-11 0 -20 7 -20 15 0 8 -7 15 -15 15 -13 0 -15 -129 -15 -1105 0 -998 2 -1105
16 -1105 8 0 22 -6 29 -14 12 -12 12 -15 0 -20 -8 -3 -15 -12 -15 -21 0 -8 -7
-15 -15 -15 -11 0 -15 -12 -15 -51 l0 -52 39 6 c24 3 50 15 65 31 27 26 34 46
16 46 -23 0 -7 21 20 26 16 4 30 10 30 15 0 5 6 9 14 9 16 0 26 21 26 56 0 13
4 24 9 24 5 0 13 9 16 20 3 11 11 20 16 20 14 0 39 26 39 39 0 15 9 14 26 -4
9 -8 29 -15 45 -15 16 0 29 -4 29 -9 0 -5 11 -8 25 -8 14 0 25 3 25 8 0 5 7 9
16 9 13 0 15 7 10 33 -4 17 -11 34 -16 35 -6 2 -10 19 -10 38 0 32 1 33 30 28
25 -5 30 -3 30 12 0 13 8 19 23 20 12 1 38 2 57 3 19 1 48 -1 64 -5 29 -7 29
-7 22 31 -4 21 -14 44 -22 52 -8 8 -14 19 -14 24 0 5 -7 9 -15 9 -11 0 -15
-12 -17 -42 -1 -41 -1 -41 -5 -8 -3 19 -8 37 -13 40 -20 12 -15 90 5 90 9 0
18 6 21 13 5 14 84 11 84 -4 0 -13 46 -59 59 -59 6 0 11 -9 11 -21 0 -12 8
-23 19 -26 21 -5 31 -31 31 -84 0 -25 5 -39 16 -43 13 -5 15 -15 11 -51 l-7
-45 36 0 c24 0 37 -5 41 -17 18 -47 63 -59 63 -18 0 14 -6 28 -14 31 -20 7
-28 44 -10 44 42 0 75 77 36 87 -14 3 -17 12 -14 39 2 19 8 34 13 34 14 0 11
58 -3 63 -10 4 -10 6 0 6 7 1 17 -10 22 -24 11 -30 30 -32 50 -5 8 11 22 20
32 20 25 0 23 -16 -3 -23 -17 -5 -20 -11 -14 -35 7 -36 -12 -54 -30 -29 -7 10
-20 17 -29 17 -24 0 -21 -47 4 -55 20 -6 29 -45 10 -45 -14 0 -12 -32 4 -53 7
-9 19 -17 25 -17 7 0 19 -7 28 -15 12 -12 35 -15 107 -13 79 3 95 0 111 -16
15 -14 28 -17 62 -12 34 4 43 3 43 -9 0 -8 7 -15 15 -15 19 0 21 56 3 63 -22
9 10 24 63 31 27 4 49 11 49 16 0 6 9 10 20 10 23 0 27 -18 5 -26 -16 -7 -21
-44 -6 -44 5 0 11 -12 13 -27 2 -20 11 -30 30 -37 33 -12 34 -11 43 37 6 30 4
37 -9 37 -11 0 -16 9 -16 31 0 22 -5 32 -20 36 -13 4 -20 14 -20 30 0 22 -4
25 -29 21 -24 -3 -32 1 -43 20 -10 20 -10 26 -1 29 7 3 13 16 13 29 0 39 17
28 24 -16 5 -34 10 -40 30 -40 14 0 27 6 29 13 4 10 6 10 6 0 1 -7 8 -13 16
-13 8 0 46 -31 83 -70 67 -69 92 -84 92 -55 0 8 -4 15 -10 15 -5 0 -10 9 -10
21 0 36 25 15 28 -24 3 -35 5 -37 38 -37 24 0 34 -5 34 -15 0 -8 5 -15 10 -15
6 0 10 -9 10 -20 0 -11 5 -20 11 -20 7 0 8 14 4 40 l-7 40 34 -1 c18 0 27 -3
20 -6 -16 -6 -16 -30 1 -37 10 -5 10 -7 0 -12 -19 -8 -16 -71 3 -78 25 -9 34
-7 34 8 0 8 9 18 20 21 20 6 28 35 10 35 -5 0 -10 7 -10 15 0 9 -10 15 -22 16
-16 0 -18 2 -8 6 8 3 18 11 22 17 5 7 8 4 8 -6 0 -10 5 -18 10 -18 6 0 20 -9
32 -20 12 -11 28 -20 36 -20 16 0 42 -24 42 -39 0 -6 7 -11 15 -11 8 0 15 -9
15 -21 0 -17 -4 -19 -23 -13 -12 3 -26 13 -30 20 -12 21 -97 14 -97 -8 0 -13
51 -58 66 -58 8 0 14 -4 14 -10 0 -5 5 -10 10 -10 6 0 10 7 10 15 0 8 5 15 10
15 6 0 10 -13 10 -30 0 -23 4 -30 20 -30 11 0 20 5 20 10 0 6 9 10 20 10 11 0
20 -4 20 -10 0 -5 -4 -10 -10 -10 -5 0 -10 -4 -10 -10 0 -5 9 -10 20 -10 11 0
20 -7 20 -15 0 -8 7 -15 15 -15 8 0 15 -4 15 -10 0 -14 27 -12 33 3 4 9 6 9 6
0 1 -15 31 -18 31 -3 0 6 9 10 20 10 13 0 20 7 20 20 0 22 -34 28 -46 9 -5 -8
-10 -6 -16 5 -5 9 -16 16 -23 16 -8 0 -15 4 -15 9 0 11 25 21 51 21 10 0 19 5
19 10 0 6 11 10 25 10 21 0 25 -5 25 -30 0 -23 5 -30 19 -30 27 0 31 10 24 53
-4 28 -10 37 -25 37 -14 0 -18 5 -14 16 3 9 6 18 6 20 0 2 9 4 20 4 12 0 20
-7 20 -16 0 -22 21 -41 58 -49 28 -6 32 -4 32 14 0 12 -4 21 -9 21 -5 0 -11 9
-14 20 -4 17 0 20 24 20 16 0 29 5 29 10 0 6 6 10 14 10 22 0 32 61 12 75 -20
15 -20 22 -1 41 12 13 17 12 35 -3 11 -10 20 -21 20 -26 0 -14 27 -37 44 -37
9 0 16 5 16 10 0 6 7 10 15 10 8 0 15 -4 15 -9 0 -5 14 -12 30 -16 17 -4 30
-13 30 -21 0 -8 5 -14 10 -14 6 0 10 -6 10 -14 0 -18 26 -27 56 -20 22 6 24 3
24 -30 0 -20 -4 -36 -10 -36 -5 0 -10 -9 -10 -20 0 -11 5 -20 10 -20 6 0 10
-7 10 -15 0 -10 -10 -15 -29 -15 -26 0 -29 -3 -29 -35 0 -30 7 -44 48 -92 14
-17 25 -16 42 0 11 12 16 12 26 0 11 -11 8 -13 -12 -13 -35 0 -54 -59 -18 -61
13 0 14 -2 2 -6 -8 -3 -19 -12 -23 -19 -11 -20 -67 -18 -67 1 0 9 7 18 15 21
25 10 17 34 -10 34 -16 0 -25 6 -25 15 0 8 7 15 15 15 24 0 17 29 -10 42 -14
6 -25 15 -25 20 0 4 -6 8 -12 8 -7 0 -20 5 -28 10 -13 9 -13 11 0 20 8 5 25
10 38 10 22 0 27 14 16 44 -7 19 -30 21 -37 4 -4 -10 -6 -10 -6 0 -1 6 -8 12
-16 12 -8 0 -15 7 -15 15 0 10 -10 15 -30 15 -16 0 -30 -5 -30 -11 0 -16 26
-39 47 -40 10 0 13 -3 6 -6 -7 -2 -13 -13 -13 -24 0 -13 -7 -19 -24 -19 -13 0
-26 -4 -28 -9 -3 -8 -65 -15 -105 -12 -18 1 -16 21 1 21 8 0 22 7 30 16 15 14
16 14 16 0 0 -9 6 -16 14 -16 28 0 46 12 46 31 0 15 -7 19 -35 19 -19 0 -35 5
-35 10 0 6 -13 10 -30 10 -16 0 -30 -4 -30 -9 0 -5 -9 -11 -20 -14 -29 -7 -29
-67 0 -67 11 0 20 -7 20 -15 0 -8 7 -15 15 -15 8 0 15 -4 15 -10 0 -5 -7 -10
-15 -10 -10 0 -15 -10 -15 -30 0 -21 -5 -30 -16 -30 -8 0 -13 4 -9 10 9 15 -6
60 -21 60 -8 0 -14 -7 -14 -15 0 -8 5 -15 10 -15 6 0 10 -7 10 -15 0 -8 -4
-15 -10 -15 -5 0 -10 -8 -10 -17 0 -13 -3 -14 -8 -5 -5 7 -33 13 -65 15 -54 2
-56 3 -59 32 -3 31 -38 51 -38 22 0 -8 -9 -17 -20 -20 -14 -3 -20 -14 -20 -31
0 -14 -4 -26 -10 -26 -5 0 -10 5 -10 10 0 6 -7 10 -16 10 -10 0 -15 -8 -13
-22 1 -13 -2 -31 -6 -41 -10 -23 -10 -23 53 -31 43 -6 52 -4 52 9 0 19 17 19
36 0 9 -8 24 -15 35 -15 10 0 19 -4 19 -10 0 -5 7 -10 15 -10 8 0 15 -4 15
-10 0 -5 7 -10 15 -10 18 0 20 16 3 23 -9 4 -10 8 -2 14 6 4 13 16 17 26 8 20
47 23 47 3 0 -8 7 -16 15 -20 8 -3 15 -12 15 -21 0 -8 5 -15 10 -15 6 0 10 -9
10 -20 0 -11 -4 -20 -10 -20 -5 0 -10 7 -10 15 0 8 -9 15 -21 15 -18 0 -20 -5
-17 -42 3 -40 5 -43 33 -46 55 -5 75 -12 75 -27 0 -20 -44 -20 -64 0 -16 17
-56 20 -56 5 0 -5 -7 -10 -16 -10 -12 0 -14 7 -9 30 5 23 3 30 -9 30 -9 0 -16
-4 -16 -9 0 -5 -9 -11 -20 -14 -11 -3 -20 -12 -20 -21 0 -9 8 -16 20 -16 11 0
20 -6 20 -14 0 -15 30 -26 73 -26 20 0 27 -5 27 -20 0 -16 7 -20 30 -20 17 0
30 -4 30 -9 0 -5 14 -11 31 -15 32 -6 59 -27 59 -45 0 -6 -18 -11 -40 -11 -29
0 -40 4 -40 15 0 8 -5 15 -11 15 -7 0 -9 -12 -5 -35 6 -31 4 -35 -14 -35 -11
0 -20 -7 -20 -15 0 -10 -11 -15 -35 -15 -23 0 -41 -7 -52 -20 -10 -11 -28 -20
-40 -20 -14 0 -23 -6 -23 -15 0 -11 12 -15 48 -15 26 0 71 -3 100 -6 48 -6 52
-5 52 15 0 25 22 28 44 6 22 -22 76 -31 76 -13 0 7 -9 20 -20 28 -11 8 -20 24
-20 36 0 17 8 23 34 28 19 4 37 13 40 21 4 10 14 13 36 9 20 -4 30 -3 30 5 0
8 5 7 15 -3 8 -8 15 -19 15 -25 0 -6 16 -11 35 -11 19 0 35 -4 35 -10 0 -5 7
-10 15 -10 8 0 15 -7 15 -15 0 -9 7 -18 15 -21 8 -4 15 -15 15 -25 0 -10 5
-19 10 -19 6 0 10 -4 10 -10 0 -5 -11 -10 -25 -10 -16 0 -25 -6 -25 -15 0 -10
-10 -15 -29 -15 -16 0 -31 6 -35 15 -3 8 -16 15 -30 15 -13 0 -26 7 -30 15 -4
11 2 15 23 15 46 0 53 52 8 64 -42 11 -67 7 -67 -12 0 -10 -9 -25 -20 -35 -11
-9 -20 -24 -20 -33 0 -8 -7 -22 -15 -30 -13 -14 -15 -14 -15 2 0 14 -7 16 -42
11 -24 -3 -44 -11 -46 -16 -2 -6 -11 -11 -19 -11 -9 0 -13 -6 -9 -15 3 -8 -1
-21 -8 -28 -17 -16 -16 -67 1 -84 13 -15 33 -17 33 -4 0 5 11 12 25 15 22 6
25 12 25 51 0 38 3 45 20 45 11 0 20 -4 20 -10 0 -5 14 -10 30 -10 20 0 30 -5
30 -15 0 -11 -12 -15 -45 -15 l-45 0 0 -40 c0 -30 4 -40 16 -40 18 0 44 -23
44 -39 0 -6 -4 -11 -10 -11 -5 0 -10 5 -10 10 0 6 -11 10 -25 10 -14 0 -25 -4
-25 -10 0 -5 -7 -10 -15 -10 -8 0 -15 7 -15 16 0 14 -7 15 -42 10 -24 -4 -46
-14 -51 -21 -4 -8 -19 -15 -32 -15 -15 0 -25 -6 -25 -14 0 -8 -15 -17 -35 -20
-19 -4 -35 -11 -35 -16 0 -6 -7 -10 -15 -10 -7 0 -19 -8 -24 -17 -6 -10 -11
-12 -11 -5 0 6 -11 12 -24 12 -25 0 -36 -21 -36 -72 0 -25 4 -28 34 -28 30 0
35 4 41 30 6 27 25 43 25 21 0 -5 14 -12 30 -16 23 -5 30 -12 30 -31 0 -13 -4
-24 -10 -24 -5 0 -10 -4 -10 -10 0 -16 27 -12 34 5 3 8 12 12 21 9 25 -10 21
-19 -30 -63 -10 -8 -39 -13 -71 -12 -41 0 -56 -4 -61 -15 -6 -15 6 -28 74 -82
23 -17 31 -52 13 -52 -5 0 -10 -13 -10 -30 0 -25 4 -30 25 -30 16 0 25 6 25
16 0 32 40 54 97 54 40 0 53 -4 53 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -7 -15
-15 0 -10 -10 -15 -34 -15 -52 0 -65 -9 -77 -52 -9 -36 -3 -71 8 -40 3 6 11
12 19 12 8 0 14 6 14 14 0 8 7 16 15 20 9 3 15 0 15 -9 0 -8 5 -15 10 -15 6 0
10 -10 10 -21 0 -17 5 -20 25 -17 23 5 25 2 25 -33 0 -21 5 -39 13 -42 7 -2 8
-8 3 -13 -5 -5 -11 -4 -13 4 -3 6 -16 12 -29 12 -19 0 -24 -5 -24 -25 0 -14
-4 -25 -10 -25 -5 0 -10 5 -10 10 0 6 -16 10 -35 10 -24 0 -35 -5 -35 -15 0
-8 -7 -15 -15 -15 -8 0 -15 9 -15 20 0 11 -6 20 -14 20 -8 0 -16 5 -18 11 -2
6 -19 11 -38 12 -33 2 -35 0 -33 -27 3 -37 5 -38 51 -40 31 0 36 -3 26 -13
-22 -23 -84 -21 -84 2 0 8 -7 15 -16 15 -8 0 -12 5 -9 10 3 6 3 19 0 30 -5 15
-15 20 -41 20 -28 0 -34 4 -34 21 0 18 -5 21 -30 17 -16 -3 -30 -1 -30 4 0 4
-7 8 -15 8 -8 0 -15 5 -15 10 0 15 -29 12 -46 -4 -8 -8 -14 -30 -15 -48 0 -18
-3 -27 -6 -20 -2 6 -11 12 -19 12 -8 0 -14 6 -14 13 0 25 -17 39 -39 32 -25
-8 -31 -65 -6 -65 9 0 12 -6 9 -15 -8 -20 -31 -19 -51 1 -11 10 -16 31 -15 60
2 43 2 44 -31 44 -20 0 -39 8 -50 20 -22 26 -22 24 8 55 20 19 36 25 70 25 24
0 47 -4 50 -10 3 -5 -1 -10 -10 -10 -12 0 -15 -6 -11 -22 3 -13 3 -17 0 -10
-10 22 -34 13 -34 -13 0 -16 6 -25 15 -25 8 0 15 -7 15 -15 0 -8 5 -15 10 -15
6 0 10 7 10 15 0 8 7 15 15 15 8 0 15 7 15 15 0 8 5 15 10 15 6 0 10 -11 10
-24 0 -35 10 -56 26 -56 20 0 19 69 -1 76 -19 8 -19 24 0 24 8 0 15 -4 15 -10
0 -5 16 -12 35 -16 20 -3 35 -12 35 -20 0 -8 9 -14 19 -14 11 0 23 -4 26 -10
3 -5 15 -10 26 -10 10 0 19 5 19 10 0 12 43 13 56 1 5 -5 24 -12 41 -16 29 -6
33 -4 33 14 0 16 -6 21 -25 21 -14 0 -25 5 -25 10 0 6 -7 10 -15 10 -9 0 -15
9 -15 21 0 19 -4 20 -34 15 -37 -7 -46 3 -46 50 0 21 5 24 33 23 17 0 26 -3
20 -6 -21 -8 -15 -33 7 -33 11 0 20 7 20 15 0 9 9 15 25 15 19 0 25 5 25 21 0
19 4 20 40 14 43 -7 51 2 25 29 -8 8 -15 19 -15 25 0 6 -9 11 -20 11 -12 0
-20 -7 -20 -16 0 -12 -6 -15 -26 -10 -29 8 -54 29 -54 45 0 6 -13 11 -30 11
-16 0 -30 -4 -30 -10 0 -5 -9 -10 -20 -10 -18 0 -20 -4 -14 -35 5 -27 3 -35
-9 -35 -8 0 -17 13 -21 30 -6 31 -36 43 -36 15 0 -25 -17 -17 -24 10 -8 33 -2
42 34 50 17 4 30 11 30 17 0 10 25 38 34 38 2 0 6 -10 8 -22 2 -18 12 -25 46
-32 23 -5 45 -6 49 -2 13 13 -20 55 -46 58 -21 2 -27 9 -29 33 -2 16 -9 33
-15 37 -8 5 -6 8 6 8 9 0 17 7 17 15 0 8 -9 15 -20 15 -31 0 -24 15 12 25 18
6 34 18 36 28 3 14 -4 17 -33 17 -35 0 -36 1 -30 30 6 27 4 30 -19 30 -19 0
-26 -5 -26 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -7 -10 -15 0 -8 7 -15 16 -15
11 0 14 -8 12 -32 -3 -35 -28 -52 -28 -19 0 16 -6 21 -25 21 -22 0 -25 -4 -25
-35 0 -28 4 -35 20 -35 13 0 20 -7 20 -20 0 -11 -4 -20 -8 -20 -14 0 -62 25
-62 32 0 3 -13 9 -29 13 -20 4 -31 14 -35 31 -4 13 -11 24 -17 24 -19 0 -39
-28 -39 -54 0 -22 4 -26 31 -26 22 0 32 -5 36 -20 3 -13 14 -20 29 -20 20 0
24 -5 24 -30 0 -28 -3 -30 -35 -30 -34 0 -47 -13 -22 -23 17 -8 -11 -27 -40
-27 -29 0 -63 -29 -63 -52 0 -13 -8 -18 -30 -18 -23 0 -30 4 -30 20 0 10 -7
23 -17 28 -12 7 -17 25 -18 66 l-2 56 43 0 44 0 0 40 c0 29 -4 40 -15 40 -8 0
-15 5 -15 10 0 6 -13 10 -29 10 l-29 0 2 76 2 76 -25 -6 c-14 -3 -41 -9 -58
-12 -18 -4 -33 -11 -33 -15 0 -5 -7 -9 -15 -9 -8 0 -15 -9 -15 -20 0 -15 7
-20 25 -20 14 0 30 -9 37 -20 7 -11 18 -20 25 -20 8 0 13 -14 13 -39 0 -33 -4
-40 -25 -45 -24 -6 -37 -46 -15 -46 18 0 10 -45 -10 -58 -12 -7 -20 -8 -20 -2
0 13 -47 13 -55 0 -10 -16 -25 -11 -25 8 0 10 -8 27 -19 38 -10 11 -22 30 -25
42 -4 12 -14 22 -22 22 -8 0 -14 7 -14 15 0 8 -4 15 -10 15 -5 0 -10 14 -10
30 0 23 -4 30 -20 30 -22 0 -28 -25 -7 -33 6 -3 0 -6 -14 -6 -38 -1 -56 -51
-19 -51 11 0 20 5 20 10 0 6 6 10 13 10 18 0 40 -47 27 -55 -6 -4 -10 -15 -10
-26 0 -14 -7 -19 -29 -19 -16 0 -31 -6 -34 -12 -8 -22 5 -76 19 -82 19 -7 74
4 74 15 0 19 28 8 33 -13 9 -32 1 -38 -50 -38 -36 0 -46 -4 -50 -20 -3 -11 -9
-20 -14 -20 -5 0 -9 -11 -9 -25 0 -32 -23 -32 -40 0 -10 19 -22 25 -58 27 -49
3 -73 28 -28 28 14 0 26 -4 26 -10 0 -5 7 -10 15 -10 8 0 15 7 15 15 0 8 -7
15 -15 15 -8 0 -15 7 -15 15 0 9 -8 19 -17 22 -10 4 -22 12 -26 20 -4 7 -15
13 -22 13 -22 0 -18 14 8 25 17 8 23 20 25 53 2 28 8 42 18 42 8 0 14 -4 14
-10 0 -5 11 -10 25 -10 16 0 25 6 25 15 0 9 -9 15 -23 15 -12 0 -30 9 -40 20
-9 11 -13 20 -8 20 5 0 12 20 15 45 4 25 9 45 12 45 8 0 44 -40 44 -50 0 -5
11 -10 24 -10 21 0 24 5 24 44 1 45 1 45 -36 48 -35 3 -37 5 -34 36 3 27 0 32
-17 32 -13 0 -21 -7 -21 -16 0 -10 -6 -14 -15 -10 -22 8 -45 -4 -45 -24 0 -10
4 -20 9 -22 4 -1 11 -12 15 -23 4 -16 -1 -23 -24 -33 -22 -10 -30 -21 -30 -38
0 -14 -7 -27 -15 -30 -25 -10 -17 -34 11 -34 17 0 28 -6 31 -20 3 -11 9 -20
14 -20 5 0 9 -4 9 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -7 -15 -15 0 -9 -9 -15
-21 -15 -22 0 -49 -22 -49 -39 0 -6 8 -11 19 -11 13 0 20 -9 23 -29 3 -21 1
-28 -9 -24 -7 3 -13 9 -13 13 0 5 -10 12 -22 16 -41 13 -62 47 -36 57 10 4 10
8 -1 15 -8 6 -15 29 -16 54 -3 67 -3 68 -35 68 -20 0 -30 -5 -30 -15 0 -8 -7
-15 -15 -15 -8 0 -15 -9 -15 -20 0 -11 5 -20 10 -20 6 0 10 -7 10 -15 0 -10
-10 -15 -30 -15 -20 0 -30 5 -30 15 0 8 7 15 15 15 11 0 15 11 15 39 0 22 7
46 15 55 8 8 15 19 15 24 0 5 11 13 25 16 23 6 25 11 25 61 0 48 -2 55 -20 55
-22 0 -28 -35 -7 -43 7 -4 6 -6 -5 -6 -9 -1 -24 6 -33 15 -12 13 -19 14 -30 4
-8 -7 -15 -20 -15 -29 0 -13 -9 -15 -45 -13 -30 3 -45 9 -45 18 0 8 -9 14 -19
14 -11 0 -23 9 -26 20 -5 14 -15 20 -37 20 -30 0 -30 0 -24 42 5 29 3 44 -6
50 -9 5 -4 8 15 8 15 0 27 -4 27 -10 0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6
-7 10 -15 10 -19 0 -19 19 0 26 9 4 15 19 15 40 0 31 -2 34 -31 34 -26 0 -29
-3 -24 -20 4 -14 2 -20 -9 -20 -8 0 -18 9 -21 20 -3 11 -15 20 -26 20 -10 0
-19 5 -19 10 0 6 -13 10 -30 10 -23 0 -30 -4 -30 -20 0 -11 -7 -20 -15 -20 -8
0 -15 -7 -15 -15 0 -8 5 -15 10 -15 21 0 9 -20 -15 -26 -14 -3 -25 -10 -25
-15 0 -5 -7 -9 -15 -9 -8 0 -15 7 -15 15 0 8 -9 15 -20 15 -16 0 -20 -7 -20
-35 0 -32 2 -35 30 -35 26 0 30 -4 30 -28 0 -23 7 -31 35 -42 19 -8 35 -17 35
-22 0 -4 7 -8 15 -8 10 0 15 -10 15 -34 0 -19 3 -41 6 -50 7 -19 60 -22 68 -3
3 6 2 -7 -1 -30 -4 -35 -3 -43 10 -43 9 0 17 11 20 27 4 26 4 26 6 -4 1 -28 5
-33 26 -33 19 0 25 5 25 22 0 20 1 20 18 5 10 -10 26 -17 35 -17 12 0 17 -8
17 -26 0 -19 -4 -25 -15 -20 -8 3 -29 3 -45 -1 -24 -4 -30 -11 -30 -30 0 -21
-2 -23 -15 -10 -7 8 -17 26 -21 41 -8 35 -44 36 -44 2 0 -14 5 -28 10 -31 6
-3 10 -19 10 -36 0 -27 2 -29 40 -29 29 0 40 -4 40 -15 0 -9 -9 -15 -25 -15
-20 0 -25 -5 -25 -25 0 -14 5 -25 10 -25 6 0 10 -13 10 -30 0 -16 -5 -30 -11
-30 -14 0 -49 -39 -49 -53 0 -6 -9 0 -20 13 -11 13 -20 29 -20 37 0 7 -7 13
-15 13 -8 0 -15 7 -15 15 0 8 -4 15 -10 15 -5 0 -10 16 -10 35 0 28 -4 35 -19
35 -10 0 -21 -9 -24 -20 -3 -14 -14 -20 -31 -20 -22 0 -26 -4 -26 -30 0 -27 3
-30 30 -30 20 0 30 -5 30 -15 0 -8 5 -15 10 -15 6 0 10 -9 10 -20 0 -13 9 -22
25 -26 20 -5 24 -11 19 -30 -4 -17 -1 -26 10 -30 9 -3 16 -15 16 -25 0 -14 -7
-19 -27 -18 -16 0 -22 3 -16 6 7 2 13 9 13 14 0 16 -36 46 -65 53 -33 8 -55
29 -55 50 0 20 -14 20 -34 0 -9 -8 -16 -11 -16 -5 0 6 -13 11 -30 11 -20 0
-30 -5 -30 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -4 -15 -9 0 -5 -13 -12 -29 -16
-32 -7 -65 -55 -43 -63 35 -11 57 -62 27 -62 -11 0 -15 -11 -15 -40 0 -42 8
-47 58 -34 21 6 32 34 12 34 -5 0 -10 9 -10 20 0 11 7 20 15 20 8 0 15 7 15
15 0 8 -7 15 -15 15 -9 0 -18 12 -22 30 -3 17 -10 30 -16 30 -6 0 -8 2 -5 5 3
4 17 3 32 -1 19 -4 26 -13 26 -31 0 -13 5 -33 10 -44 13 -23 60 -26 60 -4 0 8
4 15 9 15 5 0 12 8 14 17 4 15 5 14 6 -2 1 -30 20 -45 55 -45 17 0 48 -3 68
-6 32 -6 35 -8 22 -21 -18 -19 -18 -43 1 -43 8 0 15 5 15 11 0 7 11 0 25 -15
13 -14 31 -26 40 -26 8 0 15 -9 15 -20 0 -11 7 -20 15 -20 8 0 15 -9 15 -20 0
-16 7 -20 36 -20 24 0 34 -4 31 -12 -4 -14 -68 -12 -77 2 -3 5 -19 10 -35 12
-24 2 -31 8 -33 31 -3 23 -8 27 -36 27 -17 0 -37 -5 -44 -12 -9 -9 -12 -9 -12
0 0 7 -14 12 -35 12 -34 0 -35 -1 -35 -40 0 -29 -4 -40 -15 -40 -8 0 -15 -9
-15 -20 0 -16 7 -20 33 -21 17 0 26 -3 19 -6 -6 -2 -12 -16 -12 -29 0 -20 5
-24 30 -24 17 0 30 5 30 10 0 6 14 10 30 10 19 0 30 -5 30 -14 0 -24 18 -29
76 -21 44 5 54 10 54 26 0 16 7 19 40 19 36 0 40 -2 40 -25 0 -23 4 -25 44
-25 24 0 51 3 60 6 19 7 21 30 4 37 -10 4 -10 6 0 6 25 2 0 51 -27 51 -5 0
-11 10 -13 23 -2 14 -11 23 -25 25 -17 3 -23 10 -23 28 0 13 -4 24 -10 24 -5
0 -10 16 -10 35 0 19 5 35 10 35 6 0 10 6 10 13 0 8 -18 19 -40 26 -34 10 -41
17 -50 51 -13 50 -4 90 21 90 10 0 19 7 19 15 0 8 -6 15 -14 15 -8 0 -15 14
-18 35 -3 24 0 35 9 35 7 0 13 -7 13 -15 0 -16 20 -20 88 -16 37 2 44 0 49
-18 3 -12 9 -21 14 -21 5 0 9 -7 9 -15 0 -8 -4 -15 -10 -15 -5 0 -10 -10 -10
-21 0 -20 4 -21 46 -16 40 4 47 2 51 -14 7 -24 43 -25 43 -1 0 27 -12 39 -47
47 -26 5 -33 12 -33 31 0 15 6 24 15 24 8 0 15 -4 15 -10 0 -15 48 -12 53 3 5
15 13 9 21 -20 6 -17 3 -23 -8 -23 -22 0 -20 -19 1 -26 72 -24 75 -27 69 -66
-3 -20 -2 -39 3 -42 5 -3 7 -15 4 -26 -4 -15 0 -20 16 -20 11 0 21 -4 21 -10
0 -14 27 -12 33 3 4 9 8 8 16 -3 8 -12 6 -16 -9 -18 -11 -2 -20 -10 -20 -18 0
-8 -7 -14 -15 -14 -8 0 -15 7 -15 15 0 8 -9 15 -20 15 -16 0 -20 -7 -20 -30 0
-30 14 -40 24 -17 5 9 7 9 12 0 3 -7 14 -13 24 -13 13 0 17 -5 13 -20 -3 -13
-14 -20 -29 -20 -35 0 -30 -29 6 -36 16 -4 30 -10 30 -15 0 -5 9 -9 20 -9 11
0 20 5 20 10 0 6 5 10 10 10 6 0 10 -7 10 -15 0 -8 -9 -15 -20 -15 -11 0 -20
-4 -20 -10 0 -5 -11 -10 -25 -10 -13 0 -29 -8 -35 -17 -10 -17 -10 -17 -20 0
-6 9 -17 17 -23 17 -12 0 -47 -34 -47 -45 0 -3 52 -5 115 -4 63 1 115 5 115
10 0 5 7 9 15 9 8 0 15 -4 15 -10 0 -14 146 -12 189 2 47 17 51 23 51 86 0 28
5 52 10 52 15 0 12 47 -2 53 -10 4 -10 6 0 6 7 1 15 -6 18 -14 6 -16 34 -21
34 -7 0 14 36 52 49 52 6 0 11 -4 11 -10 0 -20 28 -9 34 12 11 42 7 58 -14 58
-11 0 -20 -4 -20 -10 0 -15 -19 -12 -36 4 -12 13 -10 15 13 18 24 2 29 8 31
36 3 31 6 33 30 27 32 -8 28 -2 35 -52 4 -33 2 -43 -9 -43 -17 0 -19 -41 -2
-58 17 -17 48 -15 48 3 0 8 7 15 15 15 22 0 18 -23 -6 -44 -16 -13 -20 -25
-15 -47 4 -22 2 -29 -9 -29 -8 0 -15 -4 -15 -9 0 -5 -9 -7 -20 -4 -23 6 -27
33 -5 33 8 0 15 7 15 15 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 19 -5 19 -30
-2 -14 -12 -20 -30 -20 -60 0 -40 2 -43 27 -43 l26 0 -21 -19 c-12 -10 -22
-28 -22 -40 0 -19 5 -21 60 -21 52 0 60 2 60 19 0 11 -6 22 -12 24 -8 3 -6 6
5 6 14 1 17 10 17 48 0 45 0 46 20 28 22 -20 70 -14 70 10 0 8 9 15 20 15 11
0 20 5 20 10 0 6 7 10 15 10 8 0 15 -4 15 -10 0 -5 -4 -10 -10 -10 -5 0 -10
-7 -10 -15 0 -8 5 -15 10 -15 6 0 10 -9 10 -20 0 -13 7 -20 20 -20 11 0 20 -6
20 -14 0 -25 -12 -46 -26 -46 -8 0 -14 -5 -14 -10 0 -7 27 -10 68 -9 81 1 102
6 102 24 0 15 -42 31 -57 22 -15 -9 -31 7 -38 36 -5 20 -4 27 8 27 9 0 17 14
21 35 4 19 11 35 16 35 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0
9 -9 15 -21 15 -17 0 -20 -5 -16 -21 5 -19 3 -21 -25 -15 -17 3 -34 6 -39 6
-5 0 -9 7 -9 15 0 8 -7 15 -15 15 -16 0 -18 15 -9 70 6 34 8 35 45 35 24 0 44
6 49 15 8 13 10 12 10 -2 0 -10 5 -18 11 -18 18 0 36 -24 44 -58 7 -32 13 -35
93 -44 12 -2 23 -6 22 -10 0 -5 0 -24 0 -43 0 -28 -4 -35 -20 -35 -11 0 -20
-4 -20 -9 0 -5 -3 -17 -6 -26 -5 -13 -2 -16 17 -13 20 2 25 -2 27 -24 3 -29
32 -40 32 -13 0 9 9 15 24 15 13 0 27 6 29 13 4 9 6 9 6 0 1 -7 10 -13 21 -13
26 0 26 -26 0 -33 -27 -7 -25 -33 2 -41 51 -13 93 1 56 18 -10 5 -10 7 0 12
17 8 15 31 -3 38 -8 3 -15 12 -15 21 0 8 -4 15 -10 15 -5 0 -10 23 -10 50 0
38 4 50 15 50 8 0 15 -6 15 -14 0 -17 24 -28 45 -20 26 10 17 34 -15 41 -23 4
-30 11 -30 29 0 27 24 33 34 9 3 -10 19 -15 46 -15 31 0 40 4 40 18 0 11 7 6
20 -16 15 -24 20 -50 20 -97 l0 -65 30 0 c21 0 30 -5 30 -16 0 -20 28 -43 53
-44 9 0 17 -4 17 -10 0 -5 19 -10 41 -10 l41 0 -7 38 c-4 20 -11 39 -16 40
-15 6 -10 22 6 22 8 0 15 -4 15 -10 0 -5 7 -10 15 -10 8 0 15 -6 15 -14 0 -8
7 -16 15 -20 19 -7 19 -26 0 -26 -8 0 -15 -4 -15 -10 0 -5 7 -10 15 -10 8 0
15 5 15 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -6 86 -10 238 -10 306 0 462
9 462 26 0 7 -4 14 -9 16 -5 2 -3 9 5 16 12 11 14 7 14 -23 l0 -35 214 0 c206
0 215 1 221 20 8 26 25 26 25 0 0 -19 7 -20 140 -20 127 0 142 2 160 20 25 25
30 25 30 1 0 -17 9 -19 115 -20 73 -1 115 2 115 9 0 5 -20 10 -44 10 -31 0
-46 5 -50 16 -4 11 -18 15 -48 14 -24 -1 -51 -1 -60 -1 -22 1 -24 31 -3 31 9
0 25 9 37 20 24 22 38 26 38 10 0 -5 -4 -10 -10 -10 -5 0 -10 -7 -10 -16 0
-12 7 -14 31 -9 25 6 31 4 36 -14 4 -17 13 -21 43 -21 24 0 40 -5 44 -15 7
-20 41 -19 62 2 16 16 16 16 10 -5 -6 -19 -4 -22 17 -22 14 0 28 6 30 13 4 9
8 9 15 -2 7 -9 19 -11 36 -7 28 7 35 30 11 40 -8 3 -15 13 -15 21 0 13 6 15
33 10 40 -9 45 -14 28 -24 -7 -5 -10 -14 -7 -20 4 -6 2 -11 -3 -11 -6 0 -11
-4 -11 -10 0 -14 27 -12 33 3 4 9 6 9 6 0 1 -9 23 -13 76 -13 l75 0 0 34 c0
31 -3 34 -40 40 -22 3 -40 10 -40 15 0 5 -15 14 -32 22 -18 7 -37 19 -41 26
-12 20 -67 17 -68 -4 0 -11 -3 -13 -6 -5 -3 7 -24 12 -53 12 -43 0 -48 2 -43
20 4 13 0 20 -10 20 -8 0 -17 12 -20 29 -5 22 -13 30 -36 34 -31 7 -43 27 -16
27 8 0 15 7 15 16 0 11 10 6 32 -16 27 -28 36 -31 68 -26 l36 6 -4 -35 -4 -35
66 0 c50 0 66 -3 66 -14 0 -8 11 -17 25 -20 14 -4 25 -13 25 -22 0 -11 8 -14
28 -12 23 2 28 8 30 36 3 33 2 34 -26 27 -43 -9 -82 3 -82 26 0 10 -4 19 -9
19 -15 0 -19 61 -4 75 16 16 17 25 2 25 -6 0 -17 7 -25 15 -9 8 -11 15 -5 15
6 0 11 7 11 15 0 26 28 16 35 -12 3 -15 12 -31 18 -35 9 -6 9 -8 0 -8 -7 0
-13 -7 -13 -15 0 -8 9 -15 20 -15 13 0 20 7 20 20 0 11 5 20 10 20 14 0 12 27
-2 34 -10 5 -10 7 -1 12 20 9 15 34 -7 34 -11 0 -20 -4 -20 -10 0 -5 -4 -10
-10 -10 -5 0 -10 7 -10 15 0 8 7 15 15 15 19 0 31 67 16 92 -9 14 -9 29 -1 58
9 33 15 40 35 40 18 0 25 5 25 20 0 20 -15 24 -82 23 -14 0 -18 9 -18 34 0 23
-5 33 -15 33 -8 0 -15 -7 -15 -15 0 -8 -3 -15 -7 -15 -14 0 -31 49 -26 70 4
15 0 20 -16 20 -18 0 -21 -6 -21 -45 0 -32 4 -45 14 -45 8 0 23 -15 34 -34 17
-28 18 -35 6 -40 -21 -8 -18 -56 4 -56 12 0 14 -2 4 -8 -7 -5 -14 -22 -15 -38
-2 -27 0 -29 30 -27 25 2 31 0 27 -12 -4 -8 -10 -15 -15 -15 -5 0 -9 -7 -9
-16 0 -14 -2 -14 -16 1 -9 9 -23 14 -31 11 -8 -3 -23 -6 -34 -6 -10 0 -19 -7
-19 -15 0 -8 7 -15 17 -15 9 0 14 -3 10 -6 -8 -8 -77 15 -77 27 0 5 9 9 19 9
30 0 51 11 51 26 0 8 -4 14 -10 14 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 7
10 15 0 8 7 15 15 15 20 0 19 26 -1 34 -23 9 -64 -4 -64 -20 0 -18 -15 -18
-34 1 -18 18 -33 19 -39 3 -3 -7 -6 -1 -6 14 -1 17 -8 29 -19 32 -14 5 -17 14
-14 36 4 25 1 30 -16 30 -11 0 -22 -6 -25 -12 -2 -7 -8 -10 -11 -6 -9 7 23 42
47 51 9 3 24 -1 33 -11 11 -10 35 -17 60 -18 41 -1 42 0 48 35 10 59 7 89 -10
106 -9 9 -13 19 -9 23 4 4 10 1 12 -5 3 -7 11 -13 18 -13 7 0 16 6 18 13 3 7
6 5 6 -5 1 -21 41 -25 41 -5 0 6 7 3 16 -8 8 -11 12 -26 9 -33 -3 -7 -2 -24 1
-38 5 -18 13 -24 35 -24 19 0 29 5 29 15 0 10 11 15 35 15 24 0 35 5 35 15 0
8 -6 15 -14 15 -8 0 -16 6 -19 13 -3 9 -10 7 -24 -6 -18 -16 -22 -16 -34 -3
-32 35 -39 46 -45 70 -5 20 -2 26 10 26 9 0 16 6 16 14 0 17 -83 32 -88 16 -5
-13 -32 -13 -32 0 0 6 -5 10 -11 10 -14 0 -39 -25 -39 -39 0 -6 -18 -11 -40
-11 -28 0 -40 4 -40 14 0 10 -13 16 -37 18 -31 2 -39 7 -41 26 -4 26 -33 30
-56 6 -15 -14 -16 -14 -16 0 0 17 -9 20 -34 10 -17 -7 -22 -40 -7 -46 5 -1 13
-15 16 -30 6 -23 4 -26 -14 -21 -12 3 -21 9 -21 14 0 5 -13 9 -29 9 -32 0 -47
-23 -35 -54 7 -19 -5 -20 -41 -4 -29 13 -34 50 -10 74 8 9 15 11 15 5 0 -6 20
-11 45 -11 33 0 45 4 45 15 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 8 7 15 15
15 10 0 15 11 15 35 0 28 -4 35 -20 35 -12 0 -20 -7 -20 -16 0 -18 -23 -44
-39 -44 -6 0 -11 5 -11 10 0 6 5 10 10 10 6 0 10 11 10 25 0 14 -4 25 -9 25
-5 0 -14 13 -20 30 -6 18 -6 30 0 30 5 0 9 6 9 14 0 8 7 16 15 20 8 3 15 10
15 16 0 12 24 40 35 40 10 0 35 -28 35 -39 0 -6 -9 -16 -20 -23 -26 -16 -26
-38 0 -38 11 0 20 6 20 14 0 8 7 16 15 20 10 3 15 -1 15 -13 0 -11 5 -21 11
-23 5 -2 13 -22 16 -45 6 -42 21 -57 48 -47 19 8 20 34 1 34 -17 0 -26 32 -19
74 4 29 8 32 34 28 21 -3 29 0 29 11 0 10 -11 17 -27 19 -28 3 -45 28 -19 28
9 0 16 -4 16 -10 0 -5 6 -10 13 -10 7 0 20 -9 30 -20 20 -24 37 -26 37 -5 0 8
7 15 15 15 8 0 15 -4 15 -10 0 -14 19 -13 35 3 52 46 68 49 59 12 -4 -18 -2
-25 9 -25 22 0 31 -32 13 -46 -24 -17 -19 -34 9 -34 14 0 25 -4 25 -10 0 -5 9
-10 20 -10 14 0 20 7 20 24 0 13 -4 26 -9 28 -4 2 -12 13 -15 26 -6 16 -3 22
8 22 11 0 16 10 16 31 0 26 -3 31 -20 26 -13 -3 -20 0 -20 9 0 7 11 16 25 20
27 7 35 34 10 34 -8 0 -15 7 -15 15 0 18 -36 20 -43 3 -3 -8 -6 -6 -6 5 -1 22
-51 24 -51 2 0 -8 5 -15 10 -15 6 0 10 -7 10 -15 0 -8 -14 -17 -35 -21 -20 -3
-35 -12 -35 -20 0 -9 -11 -14 -30 -14 -27 0 -30 3 -30 30 0 23 -4 30 -20 30
-11 0 -20 -7 -20 -15 0 -35 -42 3 -54 48 -5 20 -3 27 9 27 8 0 15 5 15 10 0 6
-7 10 -15 10 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -15 -10 -21 0 -19 13 6 30 42
29 51 42 44 67 -5 22 -4 23 15 13 11 -6 20 -15 20 -20 0 -6 9 -10 20 -10 11 0
20 4 20 10 0 11 47 25 72 22 9 -2 19 2 23 7 4 7 12 7 25 -1 23 -14 26 -12 35
25 7 25 5 27 -25 27 -28 0 -30 2 -16 15 17 17 36 20 36 5 0 -5 7 -10 15 -10
10 0 15 10 15 30 0 20 -5 30 -15 30 -22 0 -18 31 5 37 23 6 27 31 6 39 -8 3
-28 -2 -45 -11 -33 -17 -42 -49 -16 -59 22 -8 18 -26 -5 -26 -17 0 -20 7 -20
50 0 49 -1 50 -29 50 -16 0 -31 6 -34 13 -5 17 3 47 14 47 5 0 9 7 9 15 0 8 7
15 15 15 8 0 15 -7 15 -15 0 -9 9 -15 25 -15 15 0 25 6 25 14 0 8 4 16 9 18 5
2 13 15 16 31 6 23 4 27 -14 27 -14 0 -21 6 -21 19 0 16 -7 19 -45 19 -43 -1
-45 -3 -45 -30 0 -22 -3 -26 -12 -18 -7 6 -24 19 -37 30 -13 11 -19 20 -13 20
16 0 15 27 0 33 -8 3 -7 6 4 6 18 1 37 43 21 48 -6 2 -8 15 -5 28 3 20 0 25
-17 25 -16 0 -21 -6 -21 -26 0 -22 -4 -25 -27 -22 -27 3 -28 4 -27 68 1 38 6
66 13 68 14 5 15 42 1 42 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 -4 10 -10
0 -5 16 -10 35 -10 28 0 35 4 35 20 0 18 2 19 20 8 11 -7 20 -23 20 -35 0 -13
5 -23 10 -23 6 0 10 -11 10 -25 0 -18 5 -25 19 -25 16 0 20 -8 23 -37 2 -27 8
-39 21 -41 16 -3 18 1 12 26 -5 24 -2 30 14 35 14 3 21 0 21 -9 0 -8 7 -14 16
-14 12 0 15 -6 10 -22 -3 -13 -6 -29 -6 -35 0 -9 -10 -11 -32 -6 -27 5 -30 4
-25 -11 4 -10 7 -20 7 -22 0 -2 13 -4 29 -4 20 0 30 6 34 20 3 11 13 20 22 20
13 0 16 6 11 22 -3 13 -6 26 -6 31 0 4 -7 7 -15 7 -16 0 -17 14 -20 160 -1 56
0 61 8 33 8 -25 16 -33 33 -33 13 0 27 8 33 17 5 10 13 14 18 10 4 -5 0 -13
-10 -18 -23 -14 -22 -39 3 -39 12 0 20 7 20 17 0 13 3 14 12 5 19 -19 28 -14
28 14 0 14 7 32 15 41 16 15 11 54 -6 61 -23 9 7 52 35 52 9 0 16 5 16 10 0 6
-9 10 -20 10 -11 0 -20 5 -20 10 0 6 7 10 15 10 10 0 15 10 15 30 0 20 -5 30
-15 30 -8 0 -15 -4 -15 -10 0 -5 -11 -12 -25 -16 -24 -6 -36 -34 -15 -34 6 0
10 -4 10 -10 0 -5 -7 -10 -15 -10 -20 0 -20 49 0 56 21 9 19 32 -5 39 -11 3
-20 11 -20 16 0 5 -11 9 -25 9 -16 0 -25 -6 -25 -15 0 -8 -7 -15 -15 -15 -8 0
-15 -7 -15 -15 0 -8 -9 -15 -20 -15 -11 0 -20 -7 -20 -15 0 -8 9 -15 20 -15
11 0 20 5 20 10 0 6 7 10 15 10 8 0 15 -9 15 -19 0 -36 -12 -51 -40 -51 -16 0
-30 -7 -34 -15 -3 -8 -12 -15 -20 -15 -8 0 -17 -6 -20 -12 -3 -9 -8 -8 -16 5
-14 22 -70 24 -70 1 0 -21 22 -41 51 -47 23 -4 23 -4 2 -6 -16 -1 -23 -7 -23
-22 0 -13 6 -20 18 -18 45 4 52 0 51 -33 -1 -18 -3 -25 -6 -16 -2 9 -17 20
-34 23 -16 4 -29 11 -29 16 0 5 -18 9 -40 9 -22 0 -40 -4 -40 -10 0 -5 -4 -10
-10 -10 -5 0 -10 11 -10 24 0 15 -7 27 -17 30 -10 3 3 3 30 0 42 -5 47 -3 47
14 0 21 -18 32 -51 32 -23 0 -25 18 -4 26 10 4 15 20 15 49 0 62 7 75 42 75
16 0 39 5 51 11 16 9 22 9 25 0 4 -12 60 -15 86 -5 9 3 16 12 16 19 0 16 23
25 66 25 33 0 34 1 34 39 0 35 2 38 25 33 18 -3 25 0 25 12 0 9 -8 16 -19 16
-33 0 -51 -11 -51 -31 0 -10 -4 -19 -9 -19 -13 0 -24 37 -16 50 8 13 -4 50
-16 50 -19 0 -39 28 -39 54 0 14 5 26 10 26 6 0 10 14 10 30 0 20 -5 30 -15
30 -8 0 -15 -4 -15 -8 0 -5 -9 -19 -20 -32 -15 -17 -20 -19 -20 -7 0 8 -8 18
-17 20 -10 3 -3 5 15 6 27 1 32 5 32 26 0 21 -6 26 -40 31 -24 4 -40 12 -40
20 0 8 -9 14 -20 14 -22 0 -28 -25 -7 -33 7 -3 5 -6 -5 -6 -10 -1 -18 -8 -18
-16 0 -8 -4 -15 -10 -15 -5 0 -10 -13 -10 -29 0 -23 -5 -30 -22 -33 -37 -5
-37 -91 0 -101 9 -3 -11 -5 -45 -6 -35 0 -63 -5 -63 -11 0 -5 -4 -10 -10 -10
-5 0 -10 7 -10 15 0 9 -9 15 -24 15 -13 0 -26 5 -28 10 -2 6 -27 13 -56 17
-42 5 -52 9 -52 24 0 12 -7 19 -20 19 -15 0 -20 6 -19 28 0 15 3 21 6 15 6
-15 33 -17 33 -3 0 6 7 10 15 10 8 0 15 -4 15 -10 0 -5 -4 -10 -10 -10 -5 0
-10 -7 -10 -15 0 -11 12 -15 45 -15 43 0 45 1 45 30 0 32 26 43 36 15 4 -9 19
-15 40 -15 32 0 34 2 34 35 0 19 5 35 10 35 6 0 10 7 10 15 0 8 -7 15 -15 15
-8 0 -15 5 -15 10 0 18 48 30 116 30 36 0 64 4 64 10 0 6 6 10 14 10 8 0 22 7
30 16 15 14 16 14 16 0 0 -9 6 -16 14 -16 8 0 16 -5 18 -10 5 -16 120 -18 125
-2 3 6 11 12 19 12 19 0 18 26 -2 34 -27 10 -44 7 -44 -9 0 -8 -9 -15 -20 -15
-11 0 -20 5 -20 10 0 15 -47 12 -53 -2 -3 -7 -6 -1 -6 15 -1 17 -6 27 -16 27
-8 0 -15 3 -15 6 0 11 29 34 44 34 29 0 16 27 -15 34 -53 10 -59 8 -59 -24 0
-20 -5 -30 -15 -30 -8 0 -20 -9 -27 -20 -13 -20 -38 -28 -38 -11 0 5 9 15 19
22 11 8 22 32 26 57 6 38 5 42 -14 42 -14 0 -21 6 -21 20 0 13 7 20 20 20 11
0 20 7 20 15 0 8 5 15 11 15 14 0 43 30 35 37 -3 3 -17 1 -31 -5 -29 -13 -38
-3 -29 33 5 21 3 25 -15 25 -16 0 -21 -6 -21 -24 0 -23 -4 -25 -78 -32 -87 -8
-122 -29 -122 -76 0 -16 -4 -28 -10 -28 -5 0 -10 -6 -10 -14 0 -7 -9 -16 -20
-19 -13 -3 -20 0 -20 9 0 17 -37 37 -56 30 -8 -3 -14 1 -14 10 0 22 -25 44
-49 44 -14 0 -21 6 -21 19 0 11 3 22 8 24 12 8 102 -5 96 -14 -9 -14 8 -59 22
-59 8 0 14 -4 14 -10 0 -5 7 -10 15 -10 8 0 15 7 15 15 0 8 -4 15 -10 15 -5 0
-10 5 -10 11 0 16 26 39 44 39 9 0 16 5 16 10 0 6 -13 10 -30 10 -16 0 -30 5
-30 10 0 6 6 10 14 10 8 0 21 6 28 14 26 26 56 29 75 7 18 -22 57 -28 68 -11
12 20 -7 50 -38 59 -18 5 -24 10 -14 10 10 1 26 9 35 18 15 15 17 15 20 1 4
-19 45 -32 73 -23 12 4 19 15 19 31 0 20 -5 24 -30 24 -16 0 -30 -4 -30 -10 0
-5 -4 -10 -10 -10 -5 0 -10 11 -10 25 0 16 6 25 15 25 8 0 15 -4 15 -10 0 -5
7 -10 15 -10 8 0 15 9 15 20 0 16 7 20 35 20 31 0 35 3 35 25 0 16 -6 25 -15
25 -19 0 -19 17 0 36 26 27 18 44 -20 44 -26 0 -35 -4 -36 -17 0 -11 -3 -13
-6 -5 -2 6 -13 12 -23 12 -25 0 -50 10 -50 20 0 5 17 10 37 12 27 2 39 8 41
21 2 10 -3 17 -12 17 -9 0 -16 8 -16 20 0 13 -7 20 -19 20 -12 0 -21 9 -25 25
-8 30 -1 45 20 45 8 0 14 7 14 15 0 9 6 12 15 9 8 -4 31 -3 50 0 28 6 35 12
35 30 0 34 -40 52 -80 36 -17 -7 -33 -10 -37 -6 -3 3 -1 6 5 6 7 0 12 7 12 15
0 8 -9 15 -20 15 -11 0 -20 7 -20 15 0 8 -7 15 -15 15 -8 0 -15 -4 -15 -9 0
-5 -23 -11 -50 -13 -42 -2 -50 0 -50 15 0 9 -4 17 -10 17 -13 0 -23 44 -15 69
5 17 15 21 51 21 43 0 44 0 44 34 0 24 -5 35 -17 38 -15 3 -15 4 1 21 10 9 27
16 39 16 17 -1 18 -2 5 -6 -32 -8 -16 -93 17 -93 11 0 35 28 35 40 0 15 50 60
66 60 8 0 14 8 14 18 0 14 9 17 57 17 l58 0 3 33 c3 27 0 32 -17 32 -12 0 -21
5 -21 10 0 6 -9 10 -20 10 -11 0 -20 -4 -20 -10 0 -18 -18 -10 -23 10 -3 11
-12 20 -21 20 -20 0 -20 -7 -2 -26 17 -16 10 -54 -10 -54 -8 0 -14 -4 -14 -9
0 -5 -11 -12 -25 -15 -14 -4 -25 -13 -25 -21 0 -8 -4 -15 -10 -15 -5 0 -10 14
-10 30 0 18 -5 30 -13 30 -9 0 -12 9 -9 29 2 16 -1 32 -7 35 -16 10 -61 -5
-61 -20 0 -8 -8 -14 -19 -14 -14 0 -17 6 -13 25 2 14 8 25 13 25 5 0 9 14 9
30 0 27 -3 30 -30 30 -16 0 -30 5 -30 10 0 6 -9 10 -20 10 -15 0 -20 -7 -20
-25 0 -14 -4 -25 -9 -25 -5 0 -12 -8 -14 -17 -4 -16 -5 -16 -6 1 -1 15 -6 17
-28 11 -15 -3 -32 -12 -37 -20 -6 -7 -30 -16 -52 -20 l-42 -6 5 48 c3 26 8 50
10 53 3 3 29 5 59 4 46 0 54 3 54 18 0 10 -7 18 -15 18 -8 0 -15 7 -15 15 0 8
9 15 20 15 11 0 28 9 37 20 13 15 30 20 65 20 41 0 48 3 48 20 0 11 5 20 10
20 6 0 10 7 10 15 0 8 5 15 10 15 6 0 10 14 10 30 0 17 5 30 10 30 6 0 10 -4
10 -10 0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 7 10 15 10 8 0 15 -4 15 -10 0
-5 14 -10 30 -10 25 0 30 4 30 25 0 18 5 25 19 25 11 0 22 8 24 18 3 10 5 6 6
-10 1 -22 6 -28 25 -28 20 0 24 6 29 42 7 53 4 58 -39 58 -19 0 -34 5 -34 10
0 6 -11 10 -25 10 -16 0 -25 -6 -25 -15 0 -8 -9 -15 -19 -15 -14 0 -18 5 -14
20 4 16 0 20 -19 20 -59 0 -118 -13 -118 -26 0 -9 -11 -14 -30 -14 -21 0 -30
5 -30 16 0 22 -25 44 -52 45 -13 0 -17 3 -11 6 7 2 13 13 13 24 0 11 9 27 20
36 11 10 20 25 20 35 0 25 -31 23 -37 -2 -6 -24 -17 -25 -42 -4 -11 10 -16 24
-14 35 8 30 -28 20 -40 -11 -5 -13 -6 -13 -6 0 -1 18 33 50 53 50 8 0 17 11
20 25 8 32 56 37 56 6 0 -11 5 -23 10 -26 6 -3 10 -28 10 -56 0 -34 4 -49 14
-49 23 0 66 48 66 75 0 14 5 25 10 25 6 0 10 9 10 20 0 15 7 20 25 20 18 0 25
5 25 20 0 11 7 20 15 20 8 0 15 5 15 10 0 13 37 13 42 0 2 -6 20 -13 41 -17
30 -4 37 -3 37 11 0 9 -5 16 -12 16 -6 0 -9 2 -6 5 9 9 38 -7 38 -21 0 -8 7
-14 15 -14 10 0 15 10 15 30 0 20 -5 30 -15 30 -8 0 -15 5 -15 10 0 6 -7 10
-15 10 -8 0 -15 5 -15 10 0 6 -9 10 -20 10 -11 0 -20 -4 -20 -10 0 -21 -20 -9
-26 15 -8 34 -31 31 -68 -8 -33 -35 -75 -67 -88 -67 -5 0 -8 11 -8 25 0 16 -6
25 -15 25 -9 0 -15 9 -15 25 0 16 -6 25 -15 25 -8 0 -15 -4 -15 -10 0 -16 -37
-11 -44 6 -10 25 -7 34 9 34 17 0 39 46 31 67 -3 7 -12 13 -20 13 -8 0 -18 9
-21 20 -3 11 -10 20 -16 20 -36 0 -66 74 -34 86 8 4 15 13 15 21 0 12 2 12 16
-1 8 -9 24 -16 34 -16 14 0 21 -10 26 -35 7 -40 26 -45 51 -15 21 24 41 26 49
5 4 -10 20 -15 44 -15 32 0 39 4 44 25 8 33 -3 75 -20 75 -8 0 -14 7 -14 15 0
32 -44 13 -59 -25 -9 -24 -10 -24 -10 8 -1 17 -5 32 -10 32 -5 0 -11 9 -14 20
-3 13 0 20 9 20 9 0 15 12 16 33 3 68 5 83 13 105 4 12 14 22 21 22 8 0 14 5
14 10 0 6 7 10 15 10 11 0 13 -8 9 -30 -4 -16 -3 -46 0 -66 7 -34 9 -36 37
-30 27 5 29 3 29 -24 0 -19 6 -30 18 -33 16 -4 16 -5 0 -6 -11 -1 -18 -9 -18
-21 0 -11 -4 -20 -10 -20 -5 0 -10 -20 -10 -45 0 -33 4 -45 15 -45 8 0 15 -9
15 -20 0 -15 7 -20 25 -20 15 0 25 6 25 15 0 8 -11 17 -25 21 -24 6 -36 34
-15 34 6 0 10 7 10 15 0 8 9 15 20 15 11 0 20 7 20 15 0 9 8 19 18 22 46 18
56 59 17 69 -24 6 -35 24 -15 24 6 0 10 9 10 20 0 16 7 20 35 20 33 0 35 -2
33 -32 -3 -28 1 -34 20 -36 12 -2 22 -10 22 -18 0 -35 52 -59 64 -29 8 21 -3
45 -20 45 -8 0 -14 9 -14 20 0 11 3 20 8 20 11 0 32 75 32 115 0 25 7 43 24
58 29 27 45 87 23 87 -8 0 -17 -7 -21 -15 -3 -8 -10 -15 -16 -15 -14 0 -13 27
1 32 6 2 13 25 16 51 5 38 3 47 -10 47 -8 0 -21 -13 -28 -29 -8 -17 -24 -33
-36 -36 -18 -4 -23 -13 -23 -35 0 -20 -5 -30 -15 -30 -8 0 -15 -7 -15 -15 0
-9 9 -15 24 -15 28 0 39 -31 30 -79 -7 -33 -12 -35 -63 -25 -21 5 -31 12 -31
25 0 11 -7 19 -15 19 -9 0 -15 9 -15 24 0 15 -7 26 -19 29 -16 4 -18 11 -13
53 2 27 9 51 13 52 16 6 10 32 -7 32 -13 0 -15 6 -9 28 3 15 11 28 16 30 5 2
9 11 9 20 0 14 9 16 50 14 32 -3 50 0 50 7 0 6 -7 11 -15 11 -8 0 -15 4 -15 9
0 5 -3 16 -6 24 -4 11 5 17 35 22 43 7 81 0 81 -16 0 -6 7 -17 15 -25 21 -21
19 -31 -7 -30 -18 1 -24 -5 -26 -26 -2 -16 1 -28 7 -28 6 0 11 7 11 16 0 11 6
14 20 9 27 -9 60 3 60 21 0 8 5 14 10 14 6 0 10 7 10 15 0 8 5 15 10 15 6 0
10 7 10 15 0 8 -9 15 -20 15 -11 0 -20 -4 -20 -10 0 -5 -7 -10 -15 -10 -8 0
-15 5 -15 10 0 6 -11 10 -25 10 -16 0 -25 6 -25 15 0 10 10 15 30 15 17 0 30
-4 30 -10 0 -5 16 -10 36 -10 33 0 36 2 30 23 -3 12 -6 28 -6 35 0 18 -59 15
-66 -3 -7 -17 -34 -21 -34 -5 0 6 7 13 15 16 18 7 20 41 2 58 -7 7 -34 13 -61
12 -31 -1 -51 4 -53 12 -3 6 -14 12 -25 12 -18 0 -20 -5 -15 -37 3 -21 3 -30
0 -20 -2 10 -18 20 -34 24 -20 4 -29 12 -29 24 0 11 -7 19 -15 19 -8 0 -15 7
-15 15 0 8 -7 15 -15 15 -8 0 -15 5 -15 10 0 6 -7 10 -15 10 -10 0 -16 16 -21
49 -7 54 -2 51 -96 67 -31 5 -37 3 -43 -15 -7 -25 -20 -27 -29 -6 -3 8 -11 15
-19 15 -9 0 -12 9 -9 30 3 23 0 30 -12 30 -9 0 -16 -4 -16 -10 0 -5 -9 -10
-20 -10 -11 0 -20 5 -20 10 0 6 7 10 15 10 8 0 15 8 15 18 0 12 2 14 8 4 9
-13 32 -17 95 -14 30 2 37 6 37 22 0 17 5 19 25 14 24 -6 25 -4 25 43 0 52 25
93 56 93 14 0 14 -2 0 -16 -24 -23 -19 -34 14 -34 30 0 41 15 18 22 -8 3 -4
12 9 25 l22 22 -2 -27 c-2 -21 4 -27 31 -35 17 -6 32 -16 32 -23 0 -8 7 -14
15 -14 9 0 15 -9 15 -25 0 -21 5 -25 29 -25 26 0 41 14 41 41 0 5 -15 9 -32
10 -18 0 -27 3 -21 6 7 2 13 13 13 24 0 11 9 27 20 36 11 10 20 23 20 30 0 7
7 13 15 13 19 0 20 29 1 36 -25 10 -25 32 0 59 13 14 24 34 24 43 0 32 -12 62
-25 62 -7 0 -15 9 -18 20 -4 15 0 20 15 20 23 0 48 23 48 44 0 9 -7 16 -15 16
-8 0 -15 5 -15 10 0 6 9 10 21 10 16 0 19 4 14 20 -5 14 -2 20 9 20 9 0 16 -4
16 -10 0 -5 9 -10 19 -10 23 0 38 37 22 53 -19 19 -12 27 24 27 19 0 35 5 35
10 0 6 7 10 15 10 23 0 19 47 -5 55 -24 8 -40 -1 -40 -21 0 -26 -15 -14 -26
21 -6 19 -7 35 -3 35 5 0 9 7 9 15 0 16 -42 30 -65 21 -20 -7 -19 -36 1 -36
14 0 14 -2 0 -15 -8 -8 -19 -15 -25 -15 -6 0 -15 -9 -21 -20 -6 -11 -17 -20
-25 -20 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -15 -10 -19 0 -19 19 0 26 8 4 15 13
15 21 0 13 -8 14 -42 9 -24 -4 -44 -11 -46 -16 -6 -16 -32 -11 -32 6 0 30 -35
10 -37 -21 -3 -62 -12 -75 -53 -75 -35 0 -38 2 -44 35 -6 32 -10 35 -41 35
-19 0 -35 -4 -35 -9 0 -5 -14 -11 -30 -14 -36 -8 -40 -35 -9 -57 12 -8 27 -20
33 -26 6 -6 18 -13 26 -17 9 -3 10 -6 3 -6 -17 -1 -17 -28 0 -34 7 -3 5 -6 -5
-6 -10 -1 -18 -5 -18 -11 0 -5 -16 -12 -35 -16 -25 -4 -35 -11 -35 -25 0 -11
-4 -19 -10 -19 -5 0 -10 5 -10 10 0 6 -7 10 -15 10 -24 0 -17 17 10 24 18 5
25 13 25 31 0 16 -6 25 -16 25 -11 0 -14 6 -8 23 3 12 11 23 15 25 15 6 10 32
-6 32 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 5 15 10 0 6 7 10 15 10 8 0 15
7 15 15 0 8 5 15 10 15 14 0 12 27 -2 34 -10 5 -10 7 0 12 6 3 12 14 12 25 0
10 7 19 15 19 8 0 15 7 15 16 0 9 11 27 26 40 26 25 44 31 44 14 0 -5 14 -10
30 -10 28 0 30 3 30 35 0 28 -4 35 -20 35 -20 0 -29 33 -11 44 12 8 61 -17 61
-31 0 -7 9 -13 19 -13 18 0 27 7 59 44 17 21 15 56 -3 56 -8 0 -15 7 -15 15 0
8 -4 15 -10 15 -16 0 -12 37 5 44 19 7 19 36 0 36 -8 0 -15 5 -15 10 0 6 -9
10 -20 10 -23 0 -75 49 -85 79 -8 26 -75 31 -75 6 0 -8 -4 -15 -10 -15 -5 0
-10 -11 -10 -25 0 -39 -25 -30 -40 14 -7 22 -19 42 -26 45 -22 8 -17 36 6 36
11 0 20 -4 20 -10 0 -16 27 -12 34 5 3 9 19 15 41 15 28 0 35 -4 35 -19 0 -14
8 -21 28 -23 21 -2 28 -9 30 -30 3 -22 9 -28 28 -28 13 0 33 9 44 20 22 22 26
40 8 40 -6 0 -9 2 -6 5 10 10 68 -8 68 -21 0 -19 18 -18 38 3 12 12 13 18 4
21 -6 2 -12 8 -12 14 0 13 26 38 39 38 6 0 11 6 11 13 0 6 3 22 6 35 6 20 3
22 -30 22 -20 0 -36 -4 -36 -10 0 -5 -4 -10 -10 -10 -21 0 -9 20 15 26 20 5
25 12 25 36 0 30 -1 31 -44 26 l-45 -5 -6 38 c-4 22 -11 39 -16 39 -5 0 -9 7
-9 15 0 9 -7 18 -15 21 -8 4 -15 17 -15 29 0 13 -4 27 -10 30 -5 3 -10 15 -10
26 0 10 5 19 10 19 6 0 10 20 10 45 0 40 -3 45 -22 45 -16 0 -19 3 -10 9 18
11 21 123 4 129 -7 2 -12 15 -12 28 0 18 -5 24 -23 24 -23 0 -57 -31 -57 -51
0 -8 -13 -9 -40 -5 -31 5 -40 3 -40 -8 0 -8 -7 -17 -16 -20 -22 -9 -24 -8 -24
9 0 8 7 15 16 15 9 0 18 9 21 20 4 15 11 18 30 14 19 -5 25 -2 30 15 3 11 9
21 13 21 4 0 10 14 14 30 3 18 12 30 21 30 8 0 15 9 15 20 0 13 -7 20 -20 20
-16 0 -20 7 -20 30 0 22 -5 30 -17 30 -10 0 -28 3 -40 6 -17 5 -23 2 -23 -10
0 -9 4 -16 9 -16 5 0 13 -15 16 -32 4 -18 13 -37 19 -41 6 -4 -6 -5 -26 -1
-31 5 -38 3 -38 -10 0 -10 -9 -16 -25 -16 -16 0 -25 6 -25 15 0 9 -9 15 -25
15 -18 0 -25 -5 -25 -19 0 -10 7 -21 14 -24 13 -5 13 -10 -1 -38 -13 -25 -14
-38 -5 -61 6 -16 6 -28 1 -28 -5 0 -9 6 -9 14 0 8 -11 17 -25 20 -14 4 -25 12
-25 20 0 7 -8 23 -18 34 -17 19 -22 20 -50 10 -56 -21 -55 -81 1 -94 19 -4 33
-2 35 4 5 17 32 2 32 -19 0 -10 5 -19 10 -19 6 0 10 -7 10 -15 0 -8 7 -15 15
-15 8 0 15 5 15 10 0 6 16 10 35 10 19 0 35 -4 35 -10 0 -5 9 -10 21 -10 22 0
49 22 49 39 0 6 7 11 15 11 8 0 15 -7 15 -15 0 -21 27 -19 39 3 6 9 10 13 10
7 0 -25 -28 -45 -63 -45 -25 0 -36 -4 -36 -15 0 -8 -9 -15 -20 -15 -11 0 -20
5 -20 10 0 16 -27 12 -34 -5 -6 -17 2 -45 14 -45 12 0 19 -40 13 -72 -4 -19
-13 -30 -29 -34 -23 -6 -35 -34 -14 -34 6 0 10 -4 10 -10 0 -5 -16 -10 -35
-10 -28 0 -35 -4 -35 -20 0 -15 -7 -20 -26 -20 -23 0 -26 -3 -22 -29 4 -26 1
-30 -24 -33 -22 -4 -28 -1 -28 14 0 10 -7 18 -15 18 -8 0 -15 7 -15 15 0 8 -4
15 -9 15 -6 0 -11 15 -13 33 -2 25 -7 32 -24 31 -14 0 -20 4 -17 13 3 7 9 13
14 13 11 0 12 27 2 33 -5 2 -2 2 5 0 30 -7 49 16 49 57 0 25 -4 40 -12 40 -15
0 -25 22 -25 56 0 15 -6 24 -15 24 -19 0 -19 7 0 26 8 9 18 33 21 55 4 21 10
39 15 39 4 0 11 10 15 22 7 25 28 48 42 48 14 0 62 44 62 58 0 7 7 12 15 12 8
0 15 9 15 20 0 13 7 20 19 20 11 0 21 5 23 12 3 7 22 11 51 9 46 -2 47 -1 42
22 -4 15 -1 30 9 40 17 17 21 37 7 37 -5 0 -11 11 -13 25 -5 22 -2 25 22 25
30 0 44 27 34 66 -4 16 0 23 15 27 16 4 21 13 21 39 l0 34 -62 1 c-40 2 -65
-2 -70 -10 -5 -8 -8 -4 -8 11 0 25 -15 29 -36 8 -22 -22 -17 -45 11 -52 14 -3
25 -10 25 -15 0 -12 -27 -12 -32 0 -1 5 -15 13 -30 16 -22 6 -28 4 -28 -9 0
-9 -8 -16 -20 -16 -17 0 -19 5 -14 24 4 17 0 27 -13 35 -10 6 -12 11 -5 11 14
0 17 30 2 30 -5 0 -10 4 -10 9 0 5 17 11 39 15 35 5 39 10 45 41 4 21 13 35
21 35 8 0 15 5 15 10 0 6 5 10 11 10 6 0 22 10 35 23 28 26 32 73 7 80 -16 4
-16 5 -2 6 32 2 34 61 2 61 -21 0 -51 -32 -35 -38 20 -6 14 -19 -13 -26 -14
-4 -25 -13 -25 -21 0 -8 -4 -15 -10 -15 -13 0 -13 12 0 46 14 36 5 43 -20 14
-17 -20 -20 -21 -20 -5 0 15 -6 17 -35 12 -19 -3 -35 -2 -35 4 0 5 -12 9 -26
9 -17 0 -28 -6 -31 -20 -3 -11 -12 -20 -20 -20 -12 0 -14 -7 -10 -29 8 -40 -2
-71 -24 -71 -11 0 -19 5 -19 10 0 6 -7 10 -15 10 -8 0 -15 -4 -15 -10 0 -5 -4
-10 -10 -10 -16 0 -12 57 5 64 8 3 15 12 15 21 0 8 5 15 10 15 6 0 10 9 10 20
0 11 7 20 15 20 8 0 15 7 15 15 0 10 11 15 35 15 19 0 35 5 35 10 0 6 7 10 15
10 8 0 15 7 15 15 0 8 -7 15 -15 15 -10 0 -15 11 -15 35 0 38 -12 44 -37 19
-12 -11 -13 -18 -4 -27 15 -15 14 -27 -4 -27 -11 0 -15 11 -15 40 0 29 4 40
15 40 8 0 15 5 15 11 0 6 7 3 16 -6 23 -23 47 -15 59 20 5 17 15 34 22 38 7 4
13 19 13 32 0 14 5 25 10 25 15 0 12 47 -2 53 -27 11 18 27 78 27 60 0 62 -1
67 -29 6 -31 -1 -71 -13 -71 -4 0 -10 14 -14 31 -9 46 -56 83 -56 44 0 -8 -4
-15 -10 -15 -5 0 -10 -7 -10 -15 0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0
-8 9 -15 20 -15 11 0 20 -7 20 -15 0 -10 10 -15 30 -15 19 0 30 -5 30 -14 0
-8 6 -16 12 -18 9 -3 8 -9 -5 -22 -15 -16 -17 -16 -17 -1 0 21 -24 45 -44 45
-9 0 -16 -8 -16 -19 0 -23 25 -51 46 -51 8 0 14 -4 14 -9 0 -5 -26 -12 -57
-16 -32 -4 -61 -12 -65 -19 -6 -8 -8 -8 -8 2 0 6 -7 12 -15 12 -16 0 -18 -12
-9 -47 5 -19 12 -21 65 -20 50 1 59 4 61 22 4 30 67 35 75 6 4 -15 14 -21 37
-22 17 -1 23 -3 14 -6 -28 -7 -23 -50 7 -57 14 -4 25 -13 25 -21 0 -8 5 -15
10 -15 6 0 10 7 10 15 0 8 7 15 15 15 11 0 15 12 15 50 0 43 -3 50 -19 50 -11
0 -22 -8 -24 -17 -4 -14 -5 -12 -6 5 0 12 4 22 9 22 6 0 10 5 10 11 0 6 12 24
28 39 46 49 52 58 52 80 0 24 -15 26 -36 5 -9 -8 -23 -15 -32 -14 -11 0 -12 2
-5 6 7 2 13 18 13 34 0 23 -4 29 -21 29 -18 0 -19 2 -6 15 8 7 26 17 41 21 14
3 26 12 26 20 0 8 9 14 20 14 24 0 27 25 4 34 -26 10 -64 -4 -64 -25 0 -10 -6
-19 -14 -19 -8 0 -16 -4 -18 -10 -1 -5 -18 -13 -37 -16 -32 -7 -34 -6 -28 17
4 13 7 32 7 42 0 14 9 17 46 17 37 0 52 5 70 25 13 14 28 25 34 25 11 0 13 11
4 34 -7 17 -34 22 -34 6 0 -5 -4 -10 -10 -10 -5 0 -10 18 -10 40 0 33 -3 40
-20 40 -11 0 -20 5 -20 10 0 6 -7 10 -15 10 -8 0 -15 -6 -15 -14 0 -20 -53
-73 -58 -58 -7 23 -22 12 -22 -18 0 -28 -2 -30 -40 -30 -22 0 -40 5 -40 10 0
6 -5 10 -11 10 -7 0 -9 -10 -5 -25 4 -17 2 -25 -7 -25 -7 0 -20 -9 -30 -20
-11 -13 -29 -20 -52 -20 -22 0 -38 -6 -41 -15 -8 -19 -24 -20 -24 -1 0 8 14
17 31 21 31 7 69 33 69 47 0 19 37 48 62 48 18 0 28 5 28 15 0 8 -4 15 -9 15
-5 0 -12 13 -16 29 -6 29 -26 41 -72 41 -19 0 -23 -6 -23 -30 0 -19 5 -30 14
-30 9 0 12 -8 8 -25 -2 -14 -10 -25 -17 -25 -7 0 -16 -6 -18 -12 -3 -7 -6 -3
-6 10 -1 12 -5 22 -11 22 -5 0 -10 18 -10 40 0 33 -3 40 -20 40 -11 0 -20 -4
-20 -10 0 -5 -20 -10 -45 -10 -25 0 -45 5 -45 10 0 6 5 10 10 10 6 0 10 11 10
25 0 22 4 25 34 25 30 0 34 3 39 33 2 17 2 38 0 45 -6 14 -73 17 -73 2 0 -23
-21 -7 -26 20 -8 35 -44 44 -44 10 0 -11 -7 -20 -15 -20 -8 0 -15 -7 -15 -16
0 -20 -24 -44 -44 -44 -9 0 -16 7 -16 15 0 8 7 15 15 15 8 0 15 7 15 15 0 18
-26 20 -33 3 -4 -10 -6 -10 -6 0 -1 17 -31 15 -31 -3 0 -9 -9 -15 -21 -15 -24
0 -64 -38 -74 -69 -3 -10 -13 -22 -21 -25 -13 -5 -14 0 -9 24 6 26 4 30 -14
30 -15 0 -21 6 -21 21 0 18 -4 20 -27 14 -16 -3 -32 -13 -36 -21 -12 -21 -37
-17 -37 6 0 11 7 20 15 20 8 0 17 14 21 35 3 20 12 35 20 35 8 0 14 5 14 10 0
6 7 10 15 10 9 0 16 10 16 28 2 24 2 24 6 4 4 -20 8 -22 41 -16 20 4 39 11 40
15 2 5 12 9 23 9 11 0 22 7 25 15 4 8 10 15 15 15 5 0 9 -7 9 -15 0 -8 9 -15
20 -15 11 0 20 -7 20 -15 0 -8 7 -15 17 -15 15 0 15 -1 0 -18 -21 -23 -22 -42
-2 -42 19 0 55 31 55 47 0 7 9 13 20 13 11 0 20 7 20 15 0 8 5 15 10 15 6 0
10 -9 10 -20 0 -11 7 -20 15 -20 9 0 15 9 15 25 0 23 4 26 33 24 80 -6 137 1
137 16 0 8 7 15 15 15 8 0 15 7 15 15 0 8 -7 15 -16 15 -13 0 -15 6 -9 28 3
15 11 28 15 30 5 2 12 19 16 38 3 19 10 34 15 34 14 0 11 38 -3 44 -10 5 -10
7 0 12 6 3 12 10 12 16 0 7 -25 9 -75 4 -70 -6 -75 -6 -75 13 0 11 -6 26 -13
33 -10 10 -11 17 -3 25 27 29 36 43 42 67 3 14 10 26 15 26 5 0 9 7 9 15 0 8
8 15 19 15 34 0 43 83 11 100 -21 11 -60 -2 -60 -20 0 -6 -7 -10 -15 -10 -8 0
-15 9 -15 19 0 11 7 26 15 35 8 8 15 18 15 23 0 5 9 16 20 26 11 9 20 21 20
27 0 5 -9 10 -20 10 -11 0 -20 -4 -20 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -4
-15 -9 0 -5 -7 -11 -14 -14 -10 -4 -13 -1 -9 9 3 7 9 14 14 14 5 0 9 9 9 20 0
11 -4 20 -10 20 -18 0 -11 20 15 44 28 26 34 66 10 66 -8 0 -15 4 -15 10 0 15
-33 50 -47 50 -7 0 -13 -9 -13 -20 0 -11 5 -20 10 -20 6 0 10 -4 10 -10 0 -5
-7 -10 -15 -10 -9 0 -15 9 -15 24 0 21 -16 36 -40 36 -4 0 -10 -9 -13 -20 -4
-15 -14 -20 -41 -20 -25 0 -36 -4 -36 -15 0 -8 -6 -15 -14 -15 -9 0 -12 7 -9
20 3 13 14 20 29 20 17 0 24 6 24 19 0 11 6 26 13 33 6 7 18 25 26 41 14 27
31 36 31 17 0 -5 -4 -10 -10 -10 -5 0 -10 -7 -10 -15 0 -8 7 -15 15 -15 8 0
15 5 15 10 0 6 7 10 15 10 8 0 15 4 15 10 0 5 14 23 30 38 17 16 30 34 30 41
0 6 15 11 35 11 45 0 48 27 5 43 -16 6 -30 15 -30 19 0 4 -11 8 -25 8 -14 0
-25 -4 -25 -9 0 -5 -11 -12 -24 -15 -14 -3 -26 -14 -28 -24 -3 -9 -4 2 -4 26
0 28 -5 46 -14 52 -10 6 -11 10 -3 10 7 0 16 12 19 28 8 31 20 42 51 42 12 0
23 5 25 12 6 18 44 -24 52 -57 10 -39 26 -52 77 -60 24 -4 47 -13 52 -19 5 -8
10 -7 18 4 8 11 12 12 16 3 2 -7 13 -13 23 -13 15 0 17 -7 12 -47 -6 -51 11
-73 55 -73 21 0 23 5 23 46 0 25 6 52 13 59 32 37 37 46 37 70 0 18 5 25 20
25 11 0 20 5 20 11 0 7 -14 8 -42 4 -57 -10 -78 -22 -78 -44 0 -11 -7 -22 -16
-25 -22 -8 -32 -7 -17 2 6 4 15 20 18 35 7 25 5 27 -23 27 -21 0 -31 6 -35 20
-4 15 0 20 14 20 10 0 19 -7 19 -15 0 -8 7 -15 15 -15 20 0 32 50 15 60 -10 6
-6 14 13 34 14 14 33 26 42 26 8 0 23 9 32 20 10 11 23 20 30 20 19 0 16 27
-4 34 -20 8 -113 -14 -113 -27 0 -4 -7 -1 -16 7 -24 24 -42 19 -49 -14 -5 -25
-11 -30 -36 -30 -22 0 -29 5 -29 19 0 11 -3 26 -6 35 -4 11 -1 16 10 16 9 0
16 5 16 10 0 6 11 10 25 10 14 0 25 -4 25 -10 0 -5 7 -10 15 -10 9 0 15 9 15
25 0 14 -7 28 -15 31 -19 8 -20 24 -1 24 11 0 13 9 9 35 -5 31 -3 35 16 35 17
0 21 -6 21 -30 0 -23 5 -30 19 -30 11 0 27 7 35 16 9 8 16 11 16 5 0 -6 7 -11
15 -11 8 0 15 5 15 10 0 6 20 10 45 10 45 0 45 0 45 34 0 39 -14 48 -77 45
-55 -2 -73 -8 -73 -25 0 -8 -7 -14 -15 -14 -11 0 -15 12 -15 44 0 38 3 45 25
50 14 4 25 11 25 16 0 6 7 10 15 10 8 0 15 -4 15 -10 0 -5 8 -10 19 -10 14 0
21 10 25 35 4 19 11 35 16 35 16 0 11 47 -5 54 -9 3 -15 18 -15 35 0 16 -6 31
-15 35 -8 3 -15 10 -15 16 0 5 7 10 15 10 8 0 15 -7 15 -16 0 -9 7 -14 18 -12
23 4 29 70 8 91 -9 9 -16 30 -16 47 0 23 -5 33 -19 37 -11 3 -22 11 -25 19 -3
8 -19 14 -36 14 -16 0 -30 5 -30 10 0 15 -67 12 -74 -2 -5 -10 -7 -10 -12 0
-3 6 -14 12 -24 12 -17 0 -18 -6 -13 -56 6 -60 -1 -74 -44 -74 -13 0 -23 -4
-23 -10 0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 4 10 9 10 5 0 13 14 16 32
4 17 17 40 29 52 12 12 28 36 35 54 8 17 17 32 21 32 4 0 10 14 14 30 5 27 36
44 36 20 0 -5 10 -10 21 -10 18 0 21 4 16 29 -9 44 2 71 29 71 13 0 24 5 24
10 0 6 7 10 15 10 8 0 15 -7 15 -15 0 -8 9 -15 20 -15 16 0 20 7 20 35 0 24 5
35 15 35 8 0 15 -7 15 -15 0 -22 27 -18 40 5 6 11 15 20 19 20 5 0 11 -9 14
-20 6 -24 55 -28 63 -5 4 8 12 15 20 15 17 0 19 36 2 43 -10 4 -9 8 2 16 11 8
16 7 18 -4 7 -33 46 -6 63 45 13 35 29 40 29 8 0 -15 10 -27 27 -35 37 -17 43
-16 43 7 0 11 -6 20 -14 20 -7 0 -16 9 -19 20 -3 13 0 20 9 20 8 0 14 -7 14
-15 0 -8 9 -15 20 -15 17 0 20 7 20 45 0 33 4 45 15 45 8 0 15 7 15 15 0 10
-11 15 -35 15 -19 0 -35 -4 -35 -9 0 -5 -11 -12 -25 -15 -14 -4 -25 -11 -25
-17 0 -6 -7 -4 -15 5 -8 8 -15 22 -15 30 0 9 -8 16 -20 16 -11 0 -20 -7 -20
-15 0 -8 5 -15 10 -15 6 0 10 -15 10 -34 0 -39 -15 -48 -60 -39 -24 4 -30 2
-30 -11 0 -16 -2 -16 -15 -2 -20 20 -20 76 0 76 8 0 15 -7 15 -15 0 -16 17
-19 44 -9 19 7 21 50 4 57 -10 4 -10 6 0 6 14 1 17 61 3 61 -5 0 -11 9 -14 21
-5 18 -2 19 22 14 20 -4 34 0 52 16 16 14 20 23 12 26 -24 9 -13 23 17 23 27
0 30 3 30 30 0 24 -4 30 -21 30 -12 0 -17 4 -14 11 10 16 -3 57 -20 61 -11 2
-12 7 -4 18 8 11 12 12 16 3 2 -7 13 -13 24 -13 10 0 19 -6 19 -14 0 -8 7 -16
15 -20 8 -3 15 -15 15 -26 0 -11 6 -20 13 -20 21 0 57 39 57 60 0 11 5 20 10
20 6 0 10 14 10 30 0 19 6 33 17 37 21 8 53 40 53 53 0 6 7 10 15 10 8 0 15 7
15 15 0 8 -4 15 -9 15 -5 0 -11 9 -14 20 -3 13 -14 20 -30 20 -30 0 -37 16
-37 80 0 27 -3 55 -6 64 -7 17 -54 22 -54 6 0 -5 -9 -10 -21 -10 -11 0 -36
-16 -55 -36 -23 -26 -34 -32 -34 -20 0 9 -7 16 -15 16 -8 0 -15 -7 -15 -15 0
-8 -7 -15 -15 -15 -8 0 -15 5 -15 10 0 16 -28 12 -32 -5 -2 -11 -7 -12 -18 -4
-11 8 -12 12 -2 16 6 2 12 13 12 23 0 40 11 50 56 50 24 0 44 -4 44 -10 0 -5
14 -10 30 -10 20 0 30 5 30 15 0 9 9 15 24 15 13 0 27 6 30 13 5 10 7 10 12 0
3 -7 16 -13 29 -13 13 0 30 -7 39 -15 8 -8 19 -15 25 -15 6 0 11 -9 11 -19 0
-30 11 -51 26 -51 8 0 14 -7 14 -15 0 -10 11 -15 35 -15 19 0 35 5 35 10 0 6
9 10 19 10 11 0 23 -4 26 -10 3 -5 22 -10 41 -10 29 0 34 3 34 24 0 13 4 26
10 28 6 2 7 22 2 53 l-8 50 43 -1 c47 -2 65 -11 46 -22 -6 -4 -14 -24 -18 -45
-5 -23 -13 -37 -22 -37 -11 0 -14 -6 -9 -22 3 -13 6 -26 6 -30 0 -16 50 -8 50
7 0 8 5 15 10 15 6 0 10 7 10 15 0 8 7 15 15 15 9 0 15 9 15 21 0 11 7 27 15
35 19 20 19 54 0 54 -8 0 -15 5 -15 10 0 6 -10 10 -22 11 l-23 0 23 9 c12 5
22 16 22 25 0 8 5 15 11 15 16 0 39 26 39 44 0 19 -40 30 -86 23 -25 -4 -34
-2 -34 8 0 7 -3 20 -6 29 -4 12 0 16 15 16 12 0 21 -4 21 -10 0 -21 30 -9 36
15 5 19 13 25 35 25 19 0 29 -5 29 -15 0 -8 7 -15 15 -15 8 0 15 -4 15 -9 0
-6 14 -11 32 -13 31 -3 53 -28 25 -28 -17 0 -27 -21 -27 -56 0 -15 -6 -24 -15
-24 -11 0 -15 -12 -15 -44 0 -24 -4 -46 -9 -48 -13 -5 -22 -41 -13 -55 4 -6
20 -15 35 -18 21 -6 27 -4 27 9 0 9 6 16 14 16 8 0 17 9 21 19 3 11 16 23 28
27 12 4 25 12 29 18 5 7 8 4 8 -6 0 -10 7 -18 15 -18 8 0 15 9 15 20 0 11 -7
20 -15 20 -8 0 -15 5 -15 10 0 6 5 10 10 10 6 0 10 5 10 10 0 6 -7 10 -15 10
-8 0 -15 4 -15 10 0 5 -11 12 -25 16 -18 4 -25 13 -25 30 0 23 3 25 73 39 11
2 17 -3 17 -15 0 -10 10 -20 23 -24 35 -9 47 -7 47 9 0 8 5 15 10 15 6 0 10 9
10 20 0 11 -7 20 -16 20 -11 0 -14 8 -12 33 3 28 7 32 36 35 20 2 32 9 32 18
0 8 -4 14 -10 14 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 7 10 15 0 8 -8 15
-17 15 -28 0 -41 13 -47 45 -3 17 -3 23 0 15 13 -29 44 -40 44 -15 0 8 5 15
10 15 6 0 10 5 10 11 0 21 -35 38 -77 39 -36 0 -43 3 -43 20 0 21 33 60 50 60
5 0 9 16 8 36 -1 20 4 39 10 42 6 4 13 19 17 34 4 14 15 28 26 31 14 4 19 14
19 41 0 38 18 49 27 16 5 -20 43 -29 43 -10 0 6 5 10 10 10 16 0 12 -50 -5
-66 -18 -19 -19 -34 -2 -34 17 1 47 25 47 38 0 6 7 9 15 6 8 -4 15 -12 15 -20
0 -8 -7 -14 -15 -14 -8 0 -15 -9 -15 -20 0 -14 -7 -20 -21 -20 -26 0 -49 -23
-49 -49 0 -12 -4 -21 -10 -21 -5 0 -10 -7 -10 -16 0 -13 7 -15 31 -10 17 3 38
6 45 6 8 0 14 9 14 20 0 13 7 20 20 20 11 0 20 5 20 10 0 6 7 10 15 10 10 0
15 11 15 35 0 19 -4 35 -10 35 -5 0 -10 9 -10 20 0 17 7 20 46 20 l47 0 -5 63
c-6 76 -2 92 18 73 8 -8 14 -19 14 -25 0 -6 14 -11 30 -11 18 0 33 -6 36 -15
7 -17 74 -21 74 -5 0 6 9 10 20 10 19 0 26 -19 21 -58 -2 -10 6 -12 28 -7 24
5 31 3 31 -9 0 -11 9 -16 30 -16 26 0 30 4 30 26 0 19 7 28 29 37 21 8 31 19
35 41 3 17 8 46 12 64 5 28 4 32 -15 32 -11 0 -21 5 -21 10 0 14 -37 12 -44
-2 -3 -9 -8 -8 -16 5 -15 23 -40 22 -40 -3 0 -13 7 -20 19 -20 26 0 34 -15 16
-30 -9 -7 -27 -9 -47 -5 -58 12 -68 16 -68 31 0 11 -9 15 -27 14 -16 -1 -34
-2 -40 -1 -15 1 -18 31 -3 31 17 0 11 38 -7 44 -30 9 -153 4 -153 -5 0 -5 7
-12 15 -15 8 -4 15 -13 15 -21 0 -11 -8 -12 -37 -7 -21 4 -44 13 -51 21 -22
22 -56 15 -85 -18 -15 -17 -27 -35 -27 -41 0 -20 -26 -36 -43 -26 -21 11 -22
28 -2 28 10 0 15 10 15 29 0 26 -4 29 -45 35 -25 4 -45 11 -45 17 0 5 -43 8
-100 7 -55 -1 -100 -5 -100 -10 0 -4 7 -8 16 -8 13 0 15 -7 9 -39 -6 -37 6
-76 28 -92 5 -3 11 -26 14 -50 3 -24 12 -48 19 -52 19 -11 18 -27 -1 -27 -8 0
-15 -9 -15 -20 0 -15 7 -20 29 -20 27 0 31 -4 39 -42 5 -24 7 -46 5 -51 -2 -4
-29 -4 -59 -1 -50 6 -56 5 -61 -14 -5 -22 -36 -38 -53 -27 -16 10 -12 22 10
28 35 9 23 35 -20 42 -34 6 -40 11 -40 31 0 19 -5 24 -27 25 l-28 1 30 7 c37
9 45 5 45 -20 0 -23 23 -25 44 -4 8 8 19 15 25 15 6 0 11 5 11 10 0 6 -4 10
-10 10 -5 0 -10 10 -10 23 0 43 -12 67 -36 73 -24 6 -34 34 -13 34 14 0 39 25
39 39 0 6 -9 11 -19 11 -11 0 -26 3 -34 6 -8 3 -21 -2 -30 -10 -22 -22 -47
-20 -47 4 0 11 -7 20 -15 20 -8 0 -15 5 -15 10 0 6 -7 10 -15 10 -8 0 -15 9
-15 20 0 11 5 20 10 20 19 0 10 39 -11 45 -25 8 -39 -3 -19 -15 13 -8 12 -10
-2 -10 -10 0 -18 -7 -18 -15 0 -8 -7 -15 -16 -15 -14 0 -16 -8 -10 -47 3 -27
11 -49 16 -51 18 -6 11 -22 -10 -22 -15 0 -20 7 -20 25 0 20 -5 25 -25 25 -34
0 -34 35 0 44 14 4 25 12 25 19 0 7 9 19 20 27 28 20 25 30 -10 30 -16 0 -30
-4 -30 -10 0 -5 -7 -10 -15 -10 -8 0 -17 -11 -21 -25 -3 -14 -9 -25 -13 -25
-13 0 -32 42 -26 60 6 18 -1 19 -94 19 -58 0 -101 -4 -101 -9 0 -6 9 -10 20
-10 11 0 20 -7 20 -15 0 -8 5 -15 10 -15 6 0 10 -13 10 -30 0 -16 -4 -30 -9
-30 -5 0 -12 -9 -16 -19 -3 -10 -15 -21 -26 -24 -18 -5 -20 -2 -14 20 3 14 3
38 0 54 -6 27 -9 29 -46 23 -34 -6 -39 -4 -39 13 0 37 -20 43 -132 43 -107 0
-108 0 -108 -24 0 -15 -7 -26 -19 -29 -14 -3 -17 0 -14 14 3 11 9 19 14 19 5
0 9 5 9 10 0 6 -43 10 -110 10 -69 0 -110 -4 -110 -10 0 -7 -6 -7 -19 0 -11 5
-40 10 -65 10 -43 0 -46 -2 -47 -27 0 -16 -3 -22 -6 -15 -2 6 -14 13 -26 13
-17 1 -18 2 -4 6 75 20 -57 23 -811 23 l-829 -1 -1 -30 c-1 -29 1 -30 31 -25
18 3 26 3 20 0 -17 -7 -16 -22 1 -40 21 -21 26 -18 26 17 0 27 3 30 23 23 28
-9 41 -28 22 -32 -19 -4 -21 -68 -2 -75 6 -2 8 -8 4 -13 -5 -5 -13 -2 -18 8
-11 20 -74 22 -94 1 -8 -8 -15 -10 -15 -4 0 17 -27 13 -34 -4 -7 -19 -36 -20
-36 -1 0 8 -7 17 -15 20 -12 4 -15 19 -12 68 3 60 20 88 33 54 7 -17 34 -22
34 -7 0 5 3 16 6 25 8 20 -33 21 -96 2 l-45 -14 2 -61 c1 -54 -1 -61 -18 -61
-10 0 -19 6 -19 14 0 8 -4 16 -10 18 -5 2 -12 22 -16 46 -6 39 -5 42 16 42 13
0 20 5 18 13 -3 7 -28 13 -63 15 -82 4 -105 -3 -105 -33 0 -14 5 -25 10 -25 6
0 10 -4 10 -10 0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 11 0 6 -7 17 -15 26 -12
12 -19 13 -30 3 -12 -10 -15 -10 -15 2 0 20 -91 31 -125 16 -18 -8 -31 -7 -56
6 -32 16 -132 22 -163 10 -9 -3 -16 -15 -16 -25 0 -16 -8 -19 -42 -19 -24 0
-54 -3 -68 -7 -23 -6 -24 -5 -9 10 9 9 28 19 43 23 67 17 7 23 -204 23 -134 0
-230 -4 -230 -10 0 -5 -9 -9 -20 -9 -16 0 -20 -7 -20 -30 0 -27 -3 -30 -30
-30 -30 0 -31 1 -24 34 l6 34 -47 8 c-30 4 -50 3 -54 -4 -4 -7 -12 -8 -21 -2
-20 13 -130 13 -130 0 0 -5 -12 -10 -26 -10 -17 0 -28 -6 -31 -20 -3 -11 -9
-20 -14 -20 -13 0 -11 -57 1 -63 6 -3 5 -4 -2 -3 -9 2 -12 21 -10 65 l3 61
-111 0 c-67 0 -110 -4 -110 -10z m1242 -250 c3 -28 7 -53 10 -55 3 -3 19 -2
37 1 29 6 32 10 26 32 -5 22 -4 23 10 9 8 -9 15 -20 15 -26 0 -6 9 -15 20 -21
23 -13 28 -60 5 -60 -7 0 -18 -6 -22 -14 -9 -15 -23 -19 -65 -17 -21 1 -29 7
-34 26 -4 16 -13 25 -25 25 -10 0 -19 7 -19 15 0 8 -3 15 -7 15 -18 1 -42 28
-48 53 -7 26 -6 27 31 24 34 -2 39 1 42 21 6 39 19 24 24 -28z m604 24 c6 -22
10 -24 35 -19 23 5 28 4 23 -8 -3 -8 -16 -18 -30 -21 -35 -9 -30 -33 8 -38 45
-7 68 1 68 24 0 31 25 15 28 -18 2 -16 9 -32 15 -36 9 -6 9 -8 0 -8 -8 0 -13
-13 -13 -30 0 -19 5 -30 14 -30 15 0 29 -51 20 -75 -4 -8 -12 -15 -20 -15 -8
0 -14 -5 -14 -11 0 -6 8 -9 19 -6 14 3 17 0 14 -14 -7 -25 -29 -24 -36 1 -3
14 -14 20 -31 20 -16 0 -26 6 -26 15 0 8 7 15 15 15 8 0 15 7 15 15 0 8 -7 15
-15 15 -8 0 -15 7 -15 15 0 9 9 15 25 15 29 0 36 34 9 44 -25 10 -34 7 -34 -9
0 -8 -9 -15 -20 -15 -11 0 -20 7 -20 15 0 8 -9 15 -20 15 -11 0 -20 6 -20 14
0 8 -6 17 -12 19 -10 4 -9 8 2 16 12 9 13 13 2 19 -18 12 -23 102 -5 102 7 0
16 -11 19 -26z m2860 1 c4 -8 12 -15 20 -15 8 0 14 -6 14 -14 0 -19 -68 -26
-85 -9 -21 20 -35 15 -35 -12 0 -18 -5 -25 -20 -25 -13 0 -20 -7 -20 -20 0
-11 5 -20 10 -20 6 0 10 -7 10 -15 0 -9 -9 -15 -25 -15 -21 0 -25 5 -25 30 0
26 -4 30 -26 30 -24 0 -25 -2 -18 -45 5 -38 4 -45 -10 -45 -11 0 -16 9 -16 31
0 29 -1 30 -32 24 -18 -4 -34 -11 -36 -16 -2 -5 -10 -9 -18 -9 -8 0 -14 -7
-14 -15 0 -13 -3 -13 -13 -3 -12 10 -12 15 0 26 7 7 13 20 13 28 0 8 4 14 9
14 5 0 12 8 16 19 4 14 19 19 72 24 37 4 70 12 74 18 3 6 27 14 53 16 30 4 46
10 46 19 0 19 49 18 56 -1z m-199 -255 c6 -33 31 -40 57 -15 21 22 35 19 42
-10 9 -37 1 -51 -18 -31 -24 23 -38 20 -38 -9 0 -14 5 -25 10 -25 6 0 10 -23
10 -50 0 -36 4 -52 15 -56 23 -9 18 -24 -9 -24 -13 0 -27 -6 -29 -12 -3 -7 -6
-1 -6 15 -1 15 -5 27 -11 27 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 7 10 15
0 24 -61 20 -67 -5 -6 -23 -33 -27 -33 -5 0 8 6 15 14 15 8 0 16 10 18 23 2
16 9 21 26 20 14 -2 22 3 22 12 0 8 -4 15 -10 15 -5 0 -10 6 -10 14 0 13 -20
26 -41 26 -5 0 -9 -9 -9 -20 0 -13 -5 -18 -15 -14 -30 12 -6 64 29 64 9 0 16
7 16 15 0 27 20 16 27 -15z m-3927 -190 c24 0 29 -5 34 -34 5 -25 13 -36 31
-40 21 -5 25 -12 25 -41 0 -31 3 -35 25 -35 18 0 25 5 25 20 0 11 -4 20 -9 20
-6 0 -6 12 0 30 14 37 27 39 31 3 2 -22 9 -29 30 -31 42 -5 43 -30 1 -38 -34
-7 -35 -7 -29 -45 5 -31 3 -39 -9 -39 -8 0 -15 7 -15 15 0 9 -9 15 -21 15 -22
0 -49 22 -49 40 0 6 -7 13 -15 16 -8 4 -15 12 -15 19 0 15 -22 25 -57 25 -22
0 -24 -4 -23 -37 3 -65 1 -73 -20 -73 -11 0 -20 -4 -20 -10 0 -26 -18 -6 -23
26 -8 47 2 94 19 94 8 0 16 11 20 25 3 14 13 25 21 25 12 0 14 9 10 43 -3 23
-3 34 0 25 3 -12 14 -18 33 -18z m640 14 c0 -21 -24 -94 -31 -94 -5 0 -9 -13
-9 -30 0 -16 5 -30 10 -30 6 0 10 -13 10 -29 0 -27 14 -41 42 -41 13 0 -1 62
-16 72 -7 5 -6 8 4 8 9 0 22 -9 30 -20 8 -11 21 -20 30 -21 10 0 11 -2 3 -6
-7 -2 -13 -20 -13 -38 0 -18 6 -36 13 -39 8 -3 7 -8 -6 -16 -14 -9 -17 -19
-12 -37 7 -28 -2 -63 -16 -63 -5 0 -9 8 -9 19 0 23 -44 71 -65 71 -8 0 -15 9
-15 19 0 11 -5 21 -11 23 -6 2 -11 31 -10 72 0 67 0 68 -24 62 -18 -5 -25 -2
-25 8 0 8 -4 16 -9 18 -4 2 -12 13 -15 26 -5 16 -3 22 7 22 9 0 17 6 19 13 3
7 23 12 48 13 24 0 46 6 48 12 4 12 22 17 22 6z m-1902 -52 c18 -2 22 -8 22
-37 0 -28 4 -35 20 -35 13 0 20 -7 20 -20 0 -11 6 -20 14 -20 15 0 26 21 26
51 0 44 47 9 57 -43 3 -18 15 -40 25 -49 10 -9 18 -23 18 -32 0 -8 7 -17 15
-21 8 -3 15 -17 15 -31 0 -14 5 -25 11 -25 11 0 39 25 39 35 0 10 -28 35 -39
35 -6 0 -11 11 -11 25 0 14 5 25 10 25 6 0 10 9 10 19 0 28 39 15 46 -15 4
-14 16 -27 30 -30 27 -7 34 -44 9 -44 -8 0 -15 -4 -15 -10 0 -5 -6 -10 -14
-10 -31 0 -28 -80 5 -112 11 -11 15 -25 12 -39 -5 -18 -3 -21 14 -17 18 5 20
0 20 -53 1 -44 -3 -59 -13 -59 -9 0 -14 -12 -14 -37 0 -42 -14 -72 -27 -59 -4
5 -2 11 5 13 19 7 15 53 -5 54 -14 0 -15 2 -3 6 24 9 30 25 30 76 0 33 -4 47
-12 46 -28 -3 -38 2 -38 21 0 11 -6 20 -12 21 -10 0 -10 2 0 6 17 7 15 33 -3
33 -8 0 -15 -7 -15 -15 0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 -7 15 -15
15 -8 0 -15 -9 -15 -21 0 -11 -4 -17 -10 -14 -5 3 -10 17 -10 31 0 13 -4 24
-10 24 -5 0 -10 18 -10 40 0 22 -3 40 -7 40 -25 -1 -53 -14 -53 -26 0 -8 -9
-14 -19 -14 -10 0 -22 -7 -25 -16 -8 -21 3 -84 15 -84 4 0 19 -11 33 -24 19
-17 26 -34 26 -60 0 -20 5 -36 11 -36 14 0 49 -38 50 -54 4 -81 24 -126 56
-126 7 0 13 -7 13 -15 0 -24 -57 -19 -70 5 -6 11 -17 20 -26 20 -12 0 -13 8
-8 35 5 28 3 35 -10 35 -9 0 -16 7 -16 15 0 8 -7 15 -15 15 -8 0 -15 -9 -15
-20 0 -11 7 -20 15 -20 8 0 15 -7 15 -15 0 -8 -7 -15 -15 -15 -7 0 -19 -8 -24
-17 -6 -10 -11 -12 -11 -5 0 6 -11 12 -25 12 -21 0 -25 -5 -25 -29 0 -16 6
-31 15 -35 9 -3 15 -18 15 -36 0 -16 4 -30 8 -30 5 0 15 -12 23 -27 8 -16 18
-31 23 -34 4 -4 9 -36 11 -73 1 -50 -1 -66 -11 -66 -8 0 -14 -6 -15 -12 0 -10
-2 -10 -6 0 -8 19 -61 15 -66 -5 -4 -14 -5 -12 -6 5 0 12 -5 22 -11 22 -5 0
-10 14 -10 30 0 17 -4 30 -10 30 -5 0 -10 7 -10 15 0 8 8 15 19 15 13 0 20 -9
24 -30 5 -24 12 -30 32 -30 16 0 25 6 25 15 0 8 -4 15 -10 15 -5 0 -10 9 -10
19 0 30 -29 53 -56 47 -19 -5 -24 -2 -24 13 0 10 -7 21 -15 25 -8 3 -15 12
-15 21 0 9 -9 15 -23 15 -15 0 -29 9 -37 25 -8 16 -22 25 -37 25 -13 0 -23 5
-23 10 0 6 11 10 25 10 23 0 25 4 25 40 0 22 -3 40 -8 40 -4 0 -22 14 -41 30
-31 28 -60 95 -47 108 3 3 6 0 6 -6 0 -7 7 -12 15 -12 11 0 15 11 15 36 0 46
-17 62 -58 56 -25 -3 -32 -1 -32 12 0 10 9 16 25 16 16 0 25 6 25 15 0 8 -4
15 -9 15 -5 0 -11 18 -15 40 -3 22 -11 40 -16 40 -5 0 -12 11 -16 25 -3 14
-11 25 -18 26 -6 1 9 5 33 10 25 5 48 13 51 19 5 7 11 6 19 -4 7 -8 25 -17 40
-21 24 -5 30 -13 35 -44 3 -21 15 -48 27 -62 15 -18 19 -31 14 -57 -6 -33 -6
-33 27 -30 32 3 33 2 31 -34 -1 -21 2 -38 7 -38 6 0 10 7 10 15 0 8 7 15 15
15 8 0 15 9 15 20 0 11 -6 20 -12 20 -16 0 -38 46 -37 74 0 17 2 16 11 -6 5
-16 16 -28 24 -28 22 0 19 95 -5 117 -10 10 -21 36 -24 58 -4 22 -11 41 -17
43 -5 2 -10 26 -10 53 0 37 -4 49 -15 49 -10 0 -15 -10 -15 -30 0 -20 -4 -28
-13 -24 -16 6 -33 52 -29 79 3 20 27 24 110 17z m2113 -12 c17 0 49 -34 49
-53 0 -10 7 -25 15 -33 22 -22 18 -44 -6 -44 -18 0 -21 -5 -17 -35 3 -30 1
-35 -16 -35 -11 0 -28 -7 -38 -17 -17 -15 -18 -15 -18 0 0 10 -4 17 -10 17 -5
0 -10 8 -10 18 0 19 -24 44 -53 55 -11 4 -17 17 -17 37 0 27 -3 30 -32 31 -18
0 -26 3 -18 6 8 3 18 11 22 16 4 6 12 4 23 -6 9 -10 27 -17 41 -17 13 0 24 -4
24 -10 0 -5 10 -10 21 -10 20 0 21 4 16 44 -6 39 2 73 11 47 2 -6 8 -11 13
-11z m-1385 -265 c4 -8 18 -15 33 -15 18 0 22 -3 12 -9 -7 -5 -21 -7 -30 -4
-10 2 -23 -3 -30 -11 -8 -9 -22 -16 -32 -16 -16 0 -19 -8 -19 -41 0 -40 1 -41
28 -36 21 4 33 0 45 -14 21 -24 22 -39 2 -39 -17 0 -19 -18 -6 -39 11 -17 51
-5 51 15 0 8 8 14 19 14 13 0 20 -9 25 -30 4 -21 11 -30 25 -30 11 0 26 -3 35
-6 11 -4 16 -1 16 10 0 9 5 16 10 16 6 0 10 -13 10 -30 0 -23 -4 -30 -20 -30
-11 0 -20 -4 -20 -10 0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 -11 10 -25
10 -16 0 -25 6 -25 15 0 8 -4 15 -10 15 -5 0 -10 -7 -10 -15 0 -8 -4 -15 -10
-15 -16 0 -11 -27 6 -34 11 -4 13 -13 8 -28 -3 -13 -11 -24 -15 -26 -5 -2 -9
-10 -9 -18 0 -10 14 -14 48 -15 26 0 41 -3 35 -6 -7 -2 -13 -13 -13 -23 0 -10
6 -20 13 -23 23 -8 1 -17 -56 -21 -50 -3 -57 -2 -57 15 0 15 5 17 30 13 24 -5
30 -3 30 11 0 9 -13 22 -30 28 -31 12 -41 37 -15 37 10 0 15 10 15 30 0 23 -4
30 -20 30 -11 0 -20 7 -20 15 0 29 -37 58 -63 51 -22 -5 -26 -2 -31 28 -4 19
-13 37 -21 40 -24 9 -17 23 15 30 25 5 30 10 30 35 0 16 -6 31 -15 35 -8 3
-15 12 -15 21 0 8 7 15 15 15 8 0 15 4 15 9 0 5 10 11 23 13 14 2 23 11 25 26
4 25 20 29 28 7z m1155 -65 c14 0 19 -7 19 -25 0 -15 11 -36 25 -49 14 -14 25
-34 25 -50 0 -16 6 -26 14 -26 15 0 36 50 36 87 0 21 2 23 23 12 12 -6 34 -24
49 -40 15 -16 34 -29 42 -29 9 0 16 -8 16 -18 0 -14 -7 -17 -40 -14 -35 4 -40
2 -40 -17 0 -12 -4 -21 -9 -21 -5 0 -12 -13 -16 -30 -8 -36 -25 -40 -25 -5 0
14 -4 25 -10 25 -5 0 -10 -11 -10 -25 0 -14 -4 -25 -10 -25 -5 0 -10 -4 -10
-10 0 -5 7 -10 15 -10 8 0 15 -4 15 -10 0 -5 -9 -10 -20 -10 -13 0 -20 7 -20
20 0 15 -7 20 -29 20 -22 0 -30 6 -35 25 -4 14 -11 25 -16 25 -6 0 -10 7 -10
15 0 8 5 15 10 15 6 0 10 9 10 21 0 12 8 23 20 26 11 3 20 12 20 20 0 10 -7
14 -20 10 -15 -4 -20 0 -20 14 0 14 -7 19 -25 19 -16 0 -25 6 -25 15 0 9 -7
18 -15 21 -31 12 -1 38 33 28 8 -2 22 -4 33 -4z m3004 -49 c3 -11 3 -31 -1
-45 -4 -16 -2 -26 5 -26 6 0 11 7 11 16 0 11 6 14 23 8 12 -3 23 -7 23 -8 1 0
4 -21 8 -46 3 -25 3 -39 0 -32 -7 14 -34 17 -34 3 0 -5 -9 -13 -20 -16 -11 -3
-20 -11 -20 -16 0 -18 -25 -36 -56 -41 -27 -4 -30 -1 -26 16 4 15 2 18 -12 13
-10 -4 -22 -7 -27 -7 -5 0 -9 -22 -9 -50 0 -50 -13 -67 -23 -30 -3 12 -14 20
-27 20 -19 0 -22 -4 -18 -35 3 -23 0 -35 -8 -35 -17 0 -24 -37 -17 -85 5 -41
4 -42 -26 -48 -18 -4 -31 -13 -31 -22 0 -8 5 -15 10 -15 23 0 7 -21 -20 -26
-32 -7 -40 -28 -15 -38 22 -8 18 -36 -5 -36 -11 0 -20 -7 -20 -15 0 -10 -10
-15 -30 -15 -20 0 -33 -6 -37 -17 -11 -28 -29 -43 -62 -49 -17 -4 -31 -12 -31
-20 0 -20 -18 -27 -56 -20 -25 5 -34 12 -34 26 0 11 5 20 10 20 6 0 10 7 10
15 0 8 7 15 15 15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 -7 -15 -15 0 -8
-8 -15 -18 -15 -14 0 -19 8 -19 30 0 22 5 30 19 30 10 0 18 -4 18 -10 0 -5 13
-10 30 -10 23 0 31 5 36 25 5 21 12 25 40 25 24 0 34 5 34 15 0 8 4 15 9 15
14 0 31 58 31 106 0 24 -4 44 -10 44 -5 0 -10 11 -10 24 0 13 -7 26 -15 30 -8
3 -15 12 -15 21 0 10 -10 15 -30 15 -27 0 -30 3 -30 30 0 22 -5 30 -17 30 -14
0 -15 2 -5 9 8 4 16 27 19 50 4 31 10 41 23 41 10 0 20 5 22 12 6 17 28 0 28
-23 0 -10 6 -19 14 -19 19 0 28 -27 20 -66 -6 -34 -6 -34 30 -34 29 0 36 4 36
20 0 16 6 20 32 18 28 -3 34 1 36 20 3 16 10 22 28 22 15 0 24 6 24 15 0 8 -7
15 -15 15 -32 0 -13 19 23 22 31 3 37 7 36 26 0 12 5 22 13 22 7 0 13 7 13 15
0 20 40 55 62 55 10 0 18 5 18 10 0 19 48 11 55 -9z m-4455 -53 c0 -15 5 -28
10 -28 6 0 10 -10 10 -22 0 -39 12 -68 26 -68 8 0 14 -9 14 -19 0 -11 5 -23
10 -26 6 -4 7 -11 4 -17 -4 -6 0 -15 7 -20 11 -7 11 -11 2 -15 -27 -10 -5 -73
25 -73 5 0 13 -15 16 -34 4 -19 12 -37 19 -39 7 -3 4 -6 -5 -6 -12 -1 -18 -9
-18 -26 0 -16 -6 -25 -15 -25 -8 0 -15 -7 -15 -15 0 -8 6 -15 14 -15 8 0 17
-15 20 -35 12 -63 46 -35 46 38 0 15 5 27 10 27 6 0 10 -22 10 -49 0 -59 7
-81 27 -81 11 0 14 10 11 42 -3 44 10 58 34 35 8 -8 24 -18 36 -21 30 -10 28
-36 -3 -36 -24 0 -36 -25 -15 -32 5 -1 12 -18 16 -35 5 -26 3 -33 -10 -33 -9
0 -16 -8 -16 -20 0 -11 7 -20 15 -20 8 0 15 -7 15 -15 0 -8 7 -15 15 -15 8 0
15 7 15 15 0 8 5 15 10 15 6 0 10 -5 10 -11 0 -13 -25 -39 -38 -39 -5 0 -12
-9 -15 -20 -3 -11 -12 -20 -21 -20 -9 0 -16 -7 -16 -15 0 -18 26 -20 33 -2 3
6 3 -3 0 -20 -4 -26 -2 -33 11 -33 9 0 16 8 16 20 0 34 29 11 41 -34 6 -22 16
-45 23 -49 6 -5 -1 -6 -17 -2 -36 7 -67 -2 -67 -21 0 -8 -7 -14 -15 -14 -8 0
-15 -4 -15 -10 0 -16 -37 -12 -44 5 -7 19 -36 20 -36 1 0 -8 -6 -17 -12 -20
-10 -5 -10 -7 0 -12 20 -9 15 -51 -8 -57 -12 -3 -20 -14 -20 -26 0 -12 -4 -21
-10 -21 -5 0 -10 5 -10 11 0 7 -12 9 -32 6 -18 -3 -27 -3 -20 0 17 7 16 63 -2
63 -17 0 -27 33 -15 51 7 11 9 10 9 -3 0 -10 5 -18 10 -18 6 0 10 -7 10 -15 0
-8 7 -15 15 -15 11 0 15 11 15 36 0 34 2 36 25 30 27 -7 30 0 15 39 -13 33
-34 55 -54 55 -21 0 -21 -34 0 -54 14 -15 14 -16 0 -16 -9 0 -16 7 -16 15 0
18 -30 20 -31 3 0 -8 -3 -7 -6 2 -4 8 -12 20 -19 25 -22 16 -6 53 27 60 24 5
29 12 29 35 0 16 -5 30 -10 32 -6 2 -12 41 -13 91 -2 73 -5 87 -18 87 -10 0
-20 11 -23 25 -4 15 -16 27 -30 31 -30 7 -43 34 -17 34 16 0 19 8 19 52 0 30
5 54 11 56 17 6 14 52 -4 52 -8 0 -15 7 -15 15 0 8 -9 15 -20 15 -11 0 -20 -7
-20 -15 0 -9 -9 -15 -24 -15 -26 0 -36 23 -35 75 1 17 3 22 6 13 5 -20 8 -20
41 -12 12 4 22 13 22 20 0 8 6 14 14 14 8 0 16 7 20 15 7 20 -3 45 -19 45 -20
0 -30 21 -15 30 8 5 11 19 7 39 -5 23 -3 31 8 31 19 0 19 27 0 46 -8 8 -11 22
-8 31 6 14 8 14 20 -3 7 -10 13 -30 13 -46z m1020 -143 c0 -7 11 -16 25 -19
21 -5 25 -12 25 -41 0 -18 4 -36 9 -39 5 -3 12 -22 16 -42 7 -38 25 -64 44
-64 6 0 11 6 12 13 0 10 2 10 6 0 2 -7 11 -13 20 -13 17 0 43 -24 43 -39 0 -6
-7 -11 -16 -11 -14 0 -16 -8 -12 -45 5 -42 4 -45 -18 -45 -15 0 -24 6 -24 15
0 8 -7 15 -15 15 -8 0 -15 -9 -15 -20 0 -11 -4 -20 -10 -20 -5 0 -10 11 -10
25 0 20 -5 25 -24 25 -24 0 -25 3 -23 58 1 31 6 60 11 63 5 4 5 28 2 53 -6 37
-11 46 -27 46 -17 0 -19 -5 -13 -35 5 -29 3 -35 -11 -35 -9 0 -23 -6 -30 -12
-33 -30 -45 -38 -55 -38 -18 0 -11 17 10 23 17 4 20 14 20 56 0 37 -4 51 -14
51 -8 0 -16 7 -20 15 -3 8 -12 15 -21 15 -8 0 -15 7 -15 15 0 10 10 15 31 15
21 0 32 6 36 18 3 14 12 17 34 14 16 -2 29 -10 29 -17z m715 -114 c19 -7 35
-17 35 -22 0 -5 11 -9 25 -9 14 0 25 5 25 10 0 6 7 10 15 10 8 0 15 -4 15 -10
0 -5 11 -12 25 -16 14 -3 25 -10 25 -15 0 -5 16 -9 35 -9 24 0 35 5 35 15 0
10 10 15 30 15 21 0 30 -5 30 -16 0 -22 32 -53 38 -36 2 6 15 12 28 12 21 0
24 -5 24 -35 0 -24 -5 -35 -15 -35 -8 0 -15 -4 -15 -10 0 -15 -27 -12 -33 4
-4 9 -23 13 -69 12 -51 -1 -63 -4 -66 -18 -2 -11 3 -18 12 -18 11 0 16 -9 16
-30 0 -16 3 -30 8 -30 4 0 7 -14 8 -30 1 -17 -3 -30 -7 -30 -5 0 -9 7 -9 16 0
14 -7 16 -41 11 -29 -4 -42 -1 -46 9 -3 8 -11 14 -18 14 -19 0 -27 40 -9 47 8
3 14 17 15 32 1 32 17 76 18 49 1 -10 8 -18 17 -18 12 0 14 7 9 32 -4 17 -14
38 -21 46 -12 11 -16 11 -30 -2 -8 -9 -22 -16 -30 -16 -8 0 -14 -9 -14 -20 0
-11 5 -20 10 -20 6 0 10 -7 10 -15 0 -25 -17 -17 -24 11 -5 19 -12 25 -25 21
-14 -3 -17 0 -14 14 5 17 -1 19 -41 19 -25 0 -46 4 -46 9 0 5 -3 16 -6 25 -4
11 -1 16 10 16 12 0 16 11 18 43 1 33 2 36 5 11 3 -24 11 -33 38 -43z m-941
44 c3 -8 12 -15 21 -15 8 0 15 -7 15 -15 0 -8 9 -15 20 -15 25 0 17 -26 -11
-33 -17 -4 -18 -11 -12 -53 7 -52 21 -74 48 -74 15 0 15 -1 0 -16 -9 -9 -15
-26 -13 -38 3 -18 9 -21 48 -20 25 0 39 -1 33 -3 -7 -3 -13 -11 -13 -19 0 -8
-9 -14 -20 -14 -11 0 -20 -7 -20 -15 0 -17 -2 -18 -24 -9 -11 4 -16 19 -16 43
0 47 -30 67 -84 57 -32 -6 -36 -4 -36 14 0 11 -6 20 -14 20 -13 0 -30 39 -21
48 2 2 24 7 50 9 25 3 45 9 45 14 0 13 -46 59 -60 59 -15 0 -7 53 12 75 17 19
44 19 52 0z m-276 -111 c17 -13 10 -58 -13 -81 -10 -9 -15 -35 -15 -69 0 -47
-2 -54 -20 -54 -11 0 -20 -4 -20 -10 0 -5 -18 -10 -39 -10 -35 0 -40 3 -50 31
-6 17 -8 43 -4 57 4 18 1 29 -12 38 -22 16 -19 20 17 29 25 6 27 4 34 -30 5
-30 3 -40 -10 -47 -20 -12 -22 -58 -1 -58 8 0 15 6 15 14 0 9 12 16 28 18 24
3 27 7 27 41 0 29 4 37 18 37 22 0 24 45 2 54 -8 3 -15 12 -15 21 0 8 -8 16
-17 16 -15 1 -15 2 1 6 11 3 26 12 35 20 12 13 16 13 20 2 2 -8 11 -19 19 -25z
m3342 -14 c0 -5 7 -10 15 -10 8 0 15 -4 15 -10 0 -21 30 -9 31 13 1 17 2 18 6
4 4 -14 11 -16 36 -12 41 8 69 -12 61 -42 -4 -16 -14 -23 -30 -23 -15 0 -24
-6 -24 -15 0 -15 -26 -20 -80 -16 -14 1 -36 -8 -48 -19 -13 -13 -22 -16 -22
-8 0 7 12 22 26 35 21 18 25 27 19 53 -4 17 -13 34 -21 37 -19 7 -18 23 1 23
8 0 15 -4 15 -10z m-2766 -184 c3 -14 17 -28 33 -34 18 -7 22 -11 11 -11 -27
-1 -25 -51 2 -51 11 0 20 7 20 15 0 8 7 15 15 15 24 0 20 -77 -5 -92 -11 -7
-20 -23 -20 -36 0 -12 -6 -29 -12 -36 -11 -11 -15 -10 -28 4 -8 9 -26 20 -38
25 -19 5 -22 12 -17 28 18 58 18 67 -1 67 -11 0 -30 3 -43 7 -23 6 -24 4 -18
-30 4 -27 2 -37 -8 -37 -16 0 -26 38 -19 67 6 21 30 33 72 33 12 0 22 5 22 10
0 6 7 10 15 10 8 0 15 9 15 20 0 11 -4 20 -10 20 -5 0 -10 7 -10 15 0 24 17
17 24 -9z m-1144 -11 c0 -8 -7 -15 -15 -15 -8 0 -15 -9 -15 -20 0 -11 -4 -20
-10 -20 -15 0 -12 -29 4 -46 8 -8 29 -17 45 -20 24 -5 31 -3 31 9 0 8 -6 18
-12 20 -10 4 -10 6 -1 6 20 2 25 -18 18 -65 -6 -44 -29 -61 -41 -29 -4 8 -19
15 -35 15 -21 0 -27 4 -23 15 9 24 -5 82 -21 89 -26 10 -45 -5 -45 -35 0 -16
5 -29 10 -29 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10 -7 10 -15 0 -8 4
-15 10 -15 5 0 12 -16 16 -35 3 -19 10 -35 14 -35 4 0 11 -11 14 -25 7 -28 46
-36 46 -10 0 8 5 15 10 15 6 0 10 -9 10 -20 0 -11 -7 -20 -15 -20 -8 0 -15 -8
-16 -17 0 -11 -3 -13 -6 -5 -6 14 -63 17 -63 2 0 -5 -7 -10 -15 -10 -8 0 -15
-9 -15 -20 0 -13 7 -20 20 -20 11 0 20 -4 20 -10 0 -14 28 -12 33 3 9 24 21
-4 19 -43 -3 -37 -1 -40 23 -40 16 0 25 6 25 15 0 21 12 19 33 -5 21 -25 22
-76 2 -96 -21 -22 -19 -44 5 -44 11 0 20 7 20 15 0 8 7 15 15 15 10 0 15 -11
15 -35 0 -19 -4 -35 -10 -35 -5 0 -10 -7 -10 -15 0 -8 5 -15 10 -15 6 0 10 -7
10 -15 0 -10 11 -15 35 -15 23 0 35 5 35 14 0 10 7 12 21 9 20 -6 21 -3 15 29
-6 34 -6 34 24 30 25 -4 30 -1 30 17 0 14 -8 23 -25 27 -24 6 -36 34 -15 34
15 0 12 30 -2 31 -9 0 -8 3 2 6 14 6 27 15 56 42 17 15 19 14 28 -15 5 -16 2
-23 -12 -26 -21 -6 -40 -60 -23 -66 6 -2 11 -19 11 -38 0 -24 5 -34 15 -34 8
0 15 -7 15 -15 0 -8 11 -17 25 -21 14 -3 25 -10 25 -14 0 -5 9 -12 20 -15 16
-5 20 -15 20 -48 0 -53 11 -70 41 -65 20 2 24 8 23 36 -2 25 2 32 17 32 21 0
26 25 7 33 -7 3 -1 6 15 6 26 1 27 3 28 54 1 28 3 44 6 34 3 -10 18 -20 34
-24 16 -3 29 -12 29 -19 0 -8 7 -14 15 -14 8 0 15 -9 15 -20 0 -11 -4 -20 -10
-20 -5 0 -10 -11 -10 -25 0 -14 5 -25 10 -25 6 0 10 -9 10 -20 0 -11 -4 -20
-10 -20 -5 0 -10 -7 -10 -15 0 -8 -5 -15 -11 -15 -16 0 -38 31 -39 54 0 14 -5
17 -22 12 -13 -3 -31 -6 -40 -6 -10 0 -18 -7 -18 -15 0 -8 5 -15 10 -15 6 0
10 -4 10 -10 0 -13 -37 -50 -50 -50 -5 0 -10 -9 -10 -20 0 -11 -7 -23 -15 -26
-19 -8 -19 -51 -1 -70 18 -17 36 -18 37 -1 0 10 2 10 6 0 7 -18 31 -16 51 3
13 14 17 14 21 3 2 -8 11 -19 20 -26 14 -11 11 -12 -16 -13 -18 0 -33 -4 -33
-9 0 -5 -12 -11 -26 -14 -24 -4 -54 14 -54 34 0 5 -7 9 -15 9 -10 0 -15 10
-15 30 0 17 -4 30 -10 30 -5 0 -10 16 -10 35 0 28 -4 35 -20 35 -11 0 -20 -7
-20 -15 0 -12 -3 -12 -20 2 -11 10 -20 27 -20 39 0 12 -5 24 -12 26 -7 3 -6 7
5 11 12 5 17 1 17 -13 0 -15 7 -20 25 -20 21 0 25 5 25 30 0 35 -28 46 -90 36
-33 -5 -38 -4 -34 9 4 8 10 15 15 15 5 0 9 7 9 15 0 10 -10 15 -30 15 -20 0
-30 -5 -30 -15 0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15 0 -8 5 -15 10 -15 6 0 10 -14 10 -30 0 -26 5 -31 30 -36 25 -5
30 -11 30 -35 0 -23 5 -29 21 -29 17 0 20 -4 15 -22 -3 -13 -6 -33 -6 -44 0
-16 -8 -23 -29 -27 -22 -5 -31 -13 -36 -37 -4 -16 -9 -30 -12 -30 -13 0 -28
29 -29 58 -2 26 2 33 22 38 26 7 27 8 18 42 -8 28 -34 29 -34 1 0 -17 -2 -18
-14 -7 -7 8 -16 25 -20 38 -3 14 -3 19 0 13 7 -16 44 -17 44 -2 0 33 -22 49
-66 49 l-46 0 7 -32 c4 -18 13 -37 19 -41 8 -6 8 -10 -1 -14 -16 -6 -17 -43
-2 -43 6 0 4 -7 -5 -15 -23 -24 -44 -18 -48 13 -3 23 -8 27 -40 30 -29 2 -38
0 -38 -12 0 -29 -18 -17 -25 17 -4 17 -12 35 -18 39 -6 3 1 16 16 31 44 40 28
75 -28 61 -18 -4 -24 -1 -29 20 -3 14 -3 44 0 66 4 27 3 40 -5 40 -6 0 -11 7
-11 15 0 8 -7 15 -15 15 -8 0 -15 9 -15 19 0 10 -7 21 -15 25 -9 3 -15 19 -15
41 0 19 -4 35 -10 35 -5 0 -10 23 -10 50 0 28 5 50 10 50 6 0 10 11 10 25 0
16 6 25 16 25 9 0 18 9 21 20 3 11 9 20 14 20 15 0 10 27 -6 34 -8 3 -15 12
-15 21 0 8 -7 15 -15 15 -8 0 -15 5 -15 10 0 6 -9 10 -20 10 -15 0 -20 -7 -20
-25 0 -16 -6 -25 -15 -25 -8 0 -15 -7 -15 -15 0 -8 9 -15 19 -15 17 0 31 -18
31 -41 0 -5 -9 -9 -20 -9 -11 0 -20 6 -20 13 0 8 -9 24 -20 37 -11 13 -20 36
-20 52 0 15 -6 28 -12 29 -10 0 -10 2 0 6 6 2 12 13 12 23 0 16 -7 19 -40 17
-29 -1 -44 4 -53 18 -19 25 -47 13 -47 -20 0 -14 -5 -25 -11 -25 -15 0 -49
-39 -49 -57 0 -7 9 -13 20 -13 11 0 20 7 20 15 0 8 9 15 20 15 11 0 20 5 20
10 0 6 5 10 10 10 6 0 10 -7 10 -15 0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15
0 -8 5 -15 10 -15 6 0 10 -13 10 -30 0 -20 5 -30 15 -30 8 0 15 -7 15 -15 0
-8 -7 -15 -15 -15 -22 0 -18 -31 5 -37 11 -3 20 -13 20 -23 0 -10 5 -20 12
-22 8 -3 11 -19 10 -42 -2 -21 3 -45 9 -53 10 -12 10 -16 -2 -24 -8 -5 -20 -9
-26 -9 -9 0 -13 -20 -13 -65 0 -37 -4 -65 -10 -65 -5 0 -11 -8 -11 -17 -1 -17
-2 -17 -6 0 -2 9 -9 17 -14 17 -6 0 -3 8 6 17 14 13 16 28 11 70 -5 43 -3 53
9 53 10 0 15 10 15 30 0 17 -4 30 -10 30 -5 0 -10 7 -10 15 0 8 -7 15 -15 15
-8 0 -15 -7 -15 -15 0 -8 5 -15 10 -15 6 0 10 -13 10 -30 0 -23 -4 -30 -20
-30 -11 0 -20 7 -20 15 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 7 -6 18 -14 22
-24 13 -25 123 -2 123 9 0 14 12 14 36 0 30 -3 35 -19 31 -14 -4 -19 0 -19 14
0 10 -4 19 -10 19 -5 0 -10 9 -10 20 0 11 5 20 10 20 15 0 12 27 -4 33 -7 3
-16 28 -20 56 -4 28 -11 51 -16 51 -6 0 -10 23 -10 50 0 28 2 50 5 50 8 0 49
-24 56 -33 3 -4 29 -10 57 -14 34 -4 52 -10 52 -19 0 -8 7 -14 15 -14 9 0 15
-9 15 -21 0 -16 5 -20 20 -16 13 3 20 0 20 -9 0 -8 5 -14 10 -14 6 0 10 -6 10
-13 0 -14 35 -47 49 -47 12 0 18 20 26 88 7 52 -7 112 -26 112 -5 0 -9 5 -9
10 0 6 9 10 20 10 11 0 20 -7 20 -15 0 -8 5 -15 10 -15 6 0 10 -7 10 -15 0
-19 27 -19 46 -1 7 8 23 12 34 9 11 -3 20 0 20 6 0 6 6 11 14 11 8 0 16 7 20
15 3 8 10 15 16 15 5 0 10 -7 10 -15z m1503 -77 c12 -10 27 -22 34 -28 16 -13
33 -13 33 0 0 25 28 7 34 -21 11 -54 8 -69 -14 -69 -11 0 -20 -4 -20 -10 0 -5
-7 -10 -15 -10 -11 0 -15 12 -15 50 0 28 -5 50 -10 50 -12 0 -52 -22 -60 -32
-3 -3 -17 -9 -32 -13 -23 -6 -26 -4 -21 14 3 12 12 21 19 21 8 0 14 7 14 15 0
8 -7 15 -15 15 -8 0 -15 -6 -15 -14 0 -8 -9 -18 -20 -21 -11 -3 -20 -11 -20
-16 0 -5 -12 -9 -28 -9 -23 0 -29 6 -40 41 -7 23 -10 44 -7 47 3 3 5 0 5 -6 0
-8 17 -12 45 -12 36 0 51 5 69 25 13 14 28 25 35 25 6 0 12 6 12 13 1 6 4 1 6
-13 3 -13 15 -32 26 -42z m515 33 c14 -11 22 -30 22 -50 0 -32 -16 -42 -26
-17 -13 34 -104 29 -104 -5 0 -10 5 -19 10 -19 9 0 60 -47 60 -55 0 -1 -9 -12
-20 -25 -32 -37 -26 -53 15 -46 l35 7 0 -41 c0 -28 4 -40 14 -40 8 0 18 -10
22 -22 4 -13 12 -26 18 -30 7 -5 5 -8 -5 -8 -9 0 -26 9 -39 20 -28 24 -43 25
-64 5 -12 -13 -15 -34 -12 -105 3 -72 1 -90 -10 -90 -8 0 -14 -7 -14 -15 0
-12 9 -13 45 -8 38 5 45 3 45 -11 0 -9 8 -16 20 -16 15 0 20 7 20 25 0 14 5
25 10 25 14 0 12 27 -2 34 -7 3 -1 3 14 0 23 -5 29 -2 38 21 11 30 -3 95 -21
95 -5 0 -9 5 -9 10 0 6 7 10 15 10 10 0 15 10 15 30 0 17 4 30 9 30 4 0 6 -11
4 -24 -4 -18 1 -25 19 -29 32 -9 35 -9 52 9 9 8 16 11 16 6 0 -13 47 -24 71
-17 19 6 27 35 10 35 -14 0 -24 38 -17 66 7 26 26 33 26 9 0 -8 8 -23 18 -33
16 -16 17 -22 5 -37 -23 -33 -17 -48 18 -41 32 6 59 27 59 45 0 6 6 11 14 11
9 0 12 -8 8 -25 -3 -18 0 -25 12 -25 9 0 16 -6 16 -14 0 -8 6 -16 13 -19 6 -2
9 -8 5 -12 -4 -4 -21 -2 -39 4 -53 18 -78 14 -85 -14 -3 -14 -10 -25 -15 -25
-5 0 -9 -7 -9 -15 0 -8 5 -15 10 -15 6 0 10 -4 10 -10 0 -24 -20 -7 -31 28
-21 65 -69 85 -69 28 0 -29 -4 -34 -30 -39 -17 -4 -30 -13 -30 -22 0 -26 33
-55 62 -55 22 0 28 -5 29 -22 4 -118 12 -138 52 -138 10 0 17 8 17 20 0 11 -4
20 -10 20 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15 0 -8 9 -15 20
-15 11 0 20 5 20 10 0 6 7 10 15 10 9 0 18 7 21 15 10 25 24 17 24 -14 0 -16
3 -43 6 -60 6 -26 11 -31 35 -31 22 0 29 -5 29 -19 0 -11 4 -22 10 -26 5 -3 7
-12 3 -20 -3 -8 0 -15 6 -15 6 0 11 -7 11 -15 0 -8 -7 -15 -15 -15 -9 0 -15 9
-15 21 0 18 -4 20 -25 15 -51 -13 -89 -85 -52 -99 6 -3 -3 -6 -20 -6 -30 -1
-33 -4 -39 -41 -5 -29 -11 -40 -24 -40 -36 0 -50 -12 -50 -41 0 -16 -4 -29
-10 -29 -5 0 -10 7 -10 15 0 8 -7 15 -15 15 -18 0 -88 67 -76 72 5 2 12 24 16
51 6 39 5 47 -9 47 -9 0 -16 7 -16 15 0 8 5 15 10 15 6 0 10 13 10 29 0 35
-23 43 -97 34 -39 -4 -53 -10 -54 -22 -1 -14 -2 -14 -6 1 -2 9 -13 20 -24 23
-10 4 -19 13 -19 21 0 8 -10 14 -25 14 -16 0 -25 -6 -25 -15 0 -20 -26 -19
-34 1 -10 25 -7 34 9 34 16 0 21 16 6 22 -5 2 -13 15 -16 31 -6 21 -4 27 9 27
29 0 17 30 -14 37 -32 6 -36 18 -31 96 2 35 1 37 -28 37 -17 0 -31 5 -31 10 0
6 7 10 15 10 8 0 15 5 15 11 0 21 30 40 51 34 18 -6 20 -3 17 27 -3 29 -7 33
-35 36 -21 2 -33 9 -33 18 0 8 5 14 10 14 17 0 11 20 -13 43 -34 31 -40 65
-16 85 25 22 26 62 0 62 -18 0 -37 27 -26 38 3 3 5 0 5 -6 0 -23 28 -12 34 13
7 28 21 33 30 10 10 -28 34 -16 40 20 6 33 9 35 45 35 23 0 47 -7 59 -19z
m-1098 -106 c0 -19 4 -35 9 -35 5 0 11 -9 14 -20 3 -13 17 -22 37 -27 31 -6
32 -7 27 -47 -3 -22 -11 -42 -16 -44 -17 -6 -14 -62 3 -62 11 0 14 -17 13 -75
-2 -52 -6 -75 -14 -75 -7 0 -15 -11 -19 -25 -5 -20 -13 -25 -36 -25 -27 0 -30
-2 -24 -25 5 -20 3 -25 -14 -25 -11 0 -20 -4 -20 -10 0 -5 -9 -10 -20 -10 -11
0 -20 -7 -20 -15 0 -8 -7 -15 -15 -15 -12 0 -14 7 -9 28 4 15 11 30 16 33 6 3
12 28 14 54 3 26 12 54 20 63 11 10 14 25 10 44 -4 20 -1 30 9 34 25 10 17 34
-10 34 -16 0 -25 6 -25 15 0 8 7 15 15 15 8 0 15 -4 15 -9 0 -12 48 -23 71
-16 19 7 27 35 9 35 -5 0 -10 14 -10 30 0 20 -5 30 -15 30 -8 0 -15 7 -15 15
0 8 5 15 10 15 26 0 5 28 -29 38 -51 16 -48 17 -55 -22 -4 -19 -12 -37 -19
-39 -9 -4 -9 -7 1 -18 14 -16 16 -49 2 -49 -5 0 -10 5 -10 10 0 6 -16 10 -35
10 -19 0 -35 -5 -35 -10 0 -27 -42 32 -47 66 -6 36 -11 45 -40 79 -13 14 -18
45 -7 45 12 0 54 -45 54 -57 0 -14 31 -17 68 -7 25 7 31 34 7 34 -8 0 -15 7
-15 15 0 8 7 15 15 15 8 0 16 6 16 13 1 6 4 -1 8 -18 7 -31 41 -49 41 -21 0 9
5 16 10 16 6 0 10 8 10 18 0 24 8 32 32 32 14 0 18 -8 18 -35z m1190 6 c0 -5
14 -12 30 -16 17 -4 31 -12 31 -18 4 -36 5 -159 1 -149 -2 7 -10 12 -18 12 -8
0 -14 7 -14 15 0 10 -10 15 -30 15 -16 0 -30 5 -30 10 0 6 -9 10 -21 10 -18 0
-20 4 -14 33 4 17 11 34 15 37 5 3 12 18 15 33 7 25 35 39 35 18z m-873 -131
c10 -11 27 -20 39 -20 18 0 24 -8 29 -40 9 -53 2 -61 -50 -53 -48 6 -51 2 -39
-44 6 -24 30 -31 36 -12 4 11 88 33 96 24 3 -3 -2 -5 -11 -5 -12 0 -17 -9 -18
-32 0 -18 -4 -41 -8 -52 -7 -17 -8 -17 -23 -2 -21 20 -38 21 -38 1 0 -8 -7
-15 -15 -15 -10 0 -15 10 -15 30 0 23 -4 30 -20 30 -11 0 -20 -4 -20 -9 0 -5
-8 -11 -19 -14 -14 -3 -17 0 -14 14 3 11 12 19 19 19 22 0 16 31 -6 37 -21 6
-24 12 -14 37 8 20 24 21 24 1 0 -8 7 -15 15 -15 8 0 15 9 15 20 0 11 -4 20
-10 20 -5 0 -10 7 -10 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15 0 8 7 15 15 15
8 0 15 6 15 14 0 13 13 23 33 25 4 1 15 -8 24 -19z m423 -7 c25 -22 26 -43 2
-43 -35 0 -59 -51 -29 -63 6 -3 2 -6 -11 -6 -16 -1 -23 -7 -21 -18 3 -33 -1
-43 -16 -43 -8 0 -15 -9 -15 -20 0 -11 -7 -20 -15 -20 -8 0 -15 -9 -15 -20 0
-12 -7 -20 -16 -20 -13 0 -15 7 -11 29 6 28 -11 81 -25 81 -5 0 -8 6 -8 14 0
7 -9 16 -21 19 -28 7 -19 31 13 35 19 2 27 -2 31 -17 7 -28 47 -29 47 -1 0 15
7 20 25 20 18 0 25 5 25 20 0 11 -4 20 -10 20 -5 0 -10 5 -10 10 0 6 9 10 20
10 11 0 20 7 20 15 0 20 15 19 40 -2z m-1113 -125 c5 14 33 16 33 2 0 -5 -7
-10 -15 -10 -17 0 -20 -17 -4 -22 5 -2 13 -22 16 -46 5 -38 8 -42 34 -42 27 0
42 23 21 32 -5 2 -12 14 -15 28 -5 22 -2 27 28 36 40 13 60 3 69 -31 4 -14 13
-25 21 -25 8 0 15 -5 15 -11 0 -6 7 -18 16 -27 18 -17 14 -94 -5 -100 -14 -5
-14 -32 -1 -32 6 0 10 -7 10 -15 0 -10 -10 -15 -29 -15 -16 0 -31 6 -35 15 -3
8 -12 15 -21 15 -8 0 -15 4 -15 8 0 4 -16 14 -35 21 -19 7 -35 19 -35 27 0 8
-10 14 -25 14 -18 0 -25 -5 -25 -19 0 -12 -9 -21 -25 -25 -23 -6 -25 -11 -25
-66 0 -33 -2 -60 -5 -60 -3 0 -14 9 -25 20 -20 20 -27 50 -11 50 18 0 23 54 7
66 -10 6 -16 26 -16 47 0 25 -8 44 -25 61 -26 26 -32 46 -15 46 6 0 10 7 10
15 0 8 5 15 10 15 6 0 10 -17 10 -39 0 -35 3 -39 31 -45 40 -8 49 -1 49 35 0
16 5 29 10 29 16 0 12 27 -5 34 -8 3 -15 11 -15 17 0 8 5 7 17 -2 13 -11 17
-11 20 -1z m1127 -110 c3 -13 6 -33 6 -46 0 -27 21 -52 44 -52 9 0 16 7 16 15
0 8 8 15 18 15 14 0 17 -9 17 -52 0 -40 4 -53 16 -56 11 -2 18 7 22 28 9 42
26 37 33 -10 6 -35 10 -40 33 -41 l26 -1 -27 -10 c-16 -5 -28 -16 -28 -24 0
-20 -28 -17 -39 5 -6 10 -22 22 -36 25 -14 4 -25 11 -25 16 0 6 -16 10 -35 10
-28 0 -35 4 -35 20 0 11 -7 20 -15 20 -16 0 -18 6 -15 61 1 21 -5 43 -14 52
-24 24 -20 47 8 47 16 0 26 -7 30 -22z m-296 -59 c3 -40 14 -58 35 -59 4 0 7
18 7 40 0 51 17 52 22 1 2 -29 -1 -42 -14 -50 -23 -14 -25 -71 -3 -71 8 0 15
-4 15 -10 0 -5 6 -10 14 -10 13 0 26 -20 26 -40 0 -4 -13 -10 -30 -13 -21 -5
-30 -12 -30 -27 0 -22 12 -25 42 -9 17 9 19 8 14 -9 -4 -10 -4 -27 0 -36 8
-21 34 -21 35 2 1 16 2 16 6 0 7 -29 51 -23 59 7 3 14 12 25 20 25 18 0 18
-25 -1 -44 -20 -20 -20 -21 15 -54 32 -31 40 -62 15 -62 -8 0 -16 -8 -16 -17
-1 -17 -2 -17 -6 0 -7 25 -63 24 -63 -2 0 -10 -7 -24 -16 -32 -10 -8 -13 -21
-9 -36 8 -32 -14 -52 -48 -46 -20 4 -27 1 -27 -11 0 -9 -7 -16 -15 -16 -8 0
-15 5 -15 10 0 6 -13 10 -30 10 -16 0 -30 -4 -30 -10 0 -19 -38 -10 -43 10 -3
11 -1 20 4 20 5 0 9 11 9 25 0 20 5 25 25 25 23 0 25 3 25 50 0 28 -4 50 -10
50 -5 0 -10 9 -10 20 0 11 5 20 10 20 15 0 12 29 -4 46 -16 16 -76 19 -76 4 0
-5 -7 -10 -15 -10 -8 0 -15 -9 -15 -19 0 -10 7 -21 15 -25 8 -3 15 -12 15 -21
0 -8 5 -15 10 -15 6 0 10 -7 10 -16 0 -11 -5 -14 -16 -10 -9 3 -19 6 -24 6 -4
0 -10 10 -14 23 -7 30 -52 55 -68 39 -15 -15 -28 -15 -28 -2 0 6 7 10 15 10
11 0 15 12 15 49 0 31 -6 55 -15 65 -8 8 -15 19 -15 24 0 5 -9 12 -20 15 -23
6 -27 27 -5 27 18 0 20 40 3 41 -10 0 -10 2 0 6 6 2 12 10 12 17 0 8 10 11 30
8 27 -4 44 -22 20 -22 -5 0 -10 -18 -10 -40 0 -29 4 -40 15 -40 10 0 15 -10
15 -29 0 -22 6 -30 25 -35 35 -9 65 13 65 46 0 21 5 28 20 28 11 0 20 5 20 10
0 6 9 10 20 10 16 0 20 7 20 33 0 48 -11 77 -27 77 -10 0 -14 -7 -11 -18 4
-15 1 -18 -18 -14 -17 3 -24 0 -24 -12 0 -9 -4 -16 -10 -16 -5 0 -10 -8 -11
-17 0 -14 -2 -15 -6 -3 -3 8 -11 18 -17 23 -7 5 -5 11 7 18 21 12 23 49 2 49
-8 0 -15 7 -15 15 0 9 9 15 24 15 13 0 26 7 30 15 3 8 15 15 26 15 12 0 20 7
20 16 0 9 6 14 13 12 6 -3 13 -24 15 -49z m1127 -9 c-4 -11 -4 -34 0 -50 6
-29 8 -29 53 -23 26 3 49 10 50 15 5 11 32 10 32 -2 0 -5 9 -10 20 -10 11 0
20 -7 20 -15 0 -8 5 -15 10 -15 6 0 10 -16 10 -35 0 -19 -4 -35 -10 -35 -5 0
-10 -7 -10 -15 0 -13 -4 -12 -20 3 -14 13 -18 25 -14 37 8 19 -3 19 -48 0 -14
-5 -18 -2 -18 14 0 17 -6 21 -29 21 -16 0 -32 -6 -34 -12 -3 -7 -6 -2 -6 12
-1 14 -10 34 -22 43 -12 11 -18 25 -15 33 3 9 6 24 6 35 0 11 7 19 16 19 11 0
14 -6 9 -20z m1121 5 c9 -8 11 -15 5 -15 -6 0 -11 -13 -11 -30 0 -28 2 -30 40
-30 24 0 40 -5 40 -12 0 -17 41 -58 57 -58 8 0 16 -7 19 -15 4 -8 13 -15 20
-15 8 0 17 -6 20 -14 3 -8 16 -17 29 -20 28 -7 51 -76 25 -76 -17 0 -51 -40
-49 -58 6 -36 -11 -62 -41 -62 -19 0 -30 -6 -33 -17 -3 -11 -5 -7 -6 10 -1 20
-6 27 -20 27 -11 0 -26 7 -35 15 -8 8 -21 15 -28 15 -10 0 -9 3 2 10 9 6 17 5
21 -2 10 -15 59 -1 59 17 0 8 7 15 15 15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8
0 -15 9 -15 19 0 20 -18 31 -51 31 -15 0 -19 -7 -19 -35 0 -24 -5 -35 -15 -35
-8 0 -15 6 -15 14 0 8 -5 16 -11 18 -5 2 -12 21 -14 42 -2 30 -12 47 -39 71
-20 17 -36 37 -36 43 0 7 -4 12 -10 12 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0
10 6 10 13 0 7 9 20 20 30 11 9 20 24 20 32 0 19 27 19 46 0z m-2541 -45 c18
-8 17 -9 -8 -9 -29 -1 -57 -21 -57 -40 0 -6 5 -11 10 -11 6 0 10 -16 10 -35 0
-32 2 -35 30 -35 23 0 30 -4 30 -20 0 -11 4 -20 9 -20 5 0 11 -12 14 -26 3
-20 -1 -28 -19 -36 -22 -10 -34 -38 -16 -38 4 0 12 -16 18 -35 10 -33 10 -35
-12 -35 -19 0 -24 5 -24 25 0 20 -5 25 -24 25 -37 0 -48 -21 -41 -82 16 -147
14 -140 41 -134 23 6 24 4 24 -44 0 -38 -4 -50 -15 -50 -8 0 -15 6 -15 14 0
14 -21 26 -46 26 -19 0 -18 -26 1 -34 9 -3 15 -18 15 -36 0 -33 16 -39 35 -13
16 22 38 7 29 -20 -4 -12 -12 -27 -18 -34 -29 -32 -36 -43 -36 -53 0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 -11 10 -25 10 -18 0 -25 5 -25 20 0 15 7
20 25 20 22 0 25 4 25 35 0 31 -3 35 -25 35 -14 0 -25 4 -25 8 0 5 -9 19 -20
32 -11 13 -20 36 -20 51 0 18 -6 29 -17 32 -14 4 -12 5 5 6 12 0 22 5 22 11 0
5 7 7 17 3 14 -5 15 -1 10 35 -4 32 -2 42 9 42 9 0 14 12 14 35 0 33 -2 35
-35 35 -34 0 -49 20 -25 35 6 4 8 21 4 42 l-7 36 34 -7 c19 -3 36 -11 37 -16
5 -13 32 -13 32 0 0 6 7 10 15 10 8 0 15 9 15 20 0 11 -4 20 -9 20 -9 0 -25
56 -22 81 1 10 -8 19 -25 23 -27 7 -50 -3 -26 -11 14 -5 16 -33 2 -33 -5 0
-10 7 -10 15 0 9 -9 15 -22 15 l-22 0 22 20 c12 11 26 20 32 20 5 0 10 14 10
30 0 30 24 43 32 18 3 -8 12 -5 28 10 26 24 26 24 55 12z m-185 -84 c0 -8 -13
-17 -30 -21 -25 -5 -30 -12 -30 -35 0 -28 0 -28 61 -28 42 1 64 -3 70 -13 13
-21 11 -29 -6 -23 -8 4 -31 3 -50 0 -26 -5 -36 -13 -41 -31 -6 -24 -24 -35
-24 -15 0 6 -18 10 -40 10 -22 0 -40 -4 -40 -9 0 -5 -11 -12 -25 -15 -14 -4
-25 -13 -25 -21 0 -8 -4 -15 -10 -15 -5 0 -10 14 -10 31 0 22 5 32 20 36 11 3
20 11 20 18 0 8 8 11 21 8 18 -5 20 -3 15 14 -9 28 3 53 25 53 14 0 19 7 19
25 0 20 5 25 25 25 14 0 25 5 25 10 0 6 7 10 15 10 8 0 15 -6 15 -14z m1140
-151 c0 -14 -4 -25 -10 -25 -5 0 -10 -9 -10 -20 0 -11 4 -20 10 -20 5 0 12
-16 16 -35 3 -20 12 -35 20 -35 8 0 14 -9 14 -19 0 -16 -6 -19 -35 -18 -30 2
-34 5 -29 22 4 11 11 22 15 23 16 6 9 32 -8 32 -36 0 -46 -16 -45 -74 0 -42
-3 -56 -14 -56 -8 0 -14 5 -14 10 0 6 -7 10 -15 10 -10 0 -15 -10 -15 -30 0
-19 5 -30 14 -30 19 0 46 -27 46 -46 0 -8 -7 -14 -15 -14 -8 0 -15 -7 -15 -15
0 -8 -7 -15 -15 -15 -11 0 -15 11 -15 39 0 36 -3 41 -35 52 -23 9 -38 22 -41
36 -4 13 -10 23 -15 23 -5 0 -9 7 -9 15 0 8 7 15 16 15 13 0 15 7 10 38 l-7
37 63 3 c35 1 65 7 66 12 2 6 12 10 21 10 16 0 17 7 13 50 -4 46 -3 50 17 50
16 0 21 -6 21 -25z m700 -45 c0 -5 23 -12 50 -16 40 -5 50 -10 50 -25 0 -11
-4 -19 -10 -19 -5 0 -10 -13 -10 -30 0 -27 3 -30 30 -30 24 0 30 -4 30 -21 0
-19 4 -21 31 -15 17 3 37 6 44 6 7 0 15 9 18 20 3 13 14 20 28 20 18 0 20 -3
11 -12 -15 -15 -16 -58 -2 -58 6 0 10 -11 10 -25 0 -18 -5 -25 -20 -25 -11 0
-20 -4 -20 -10 0 -5 -15 -10 -34 -10 -35 0 -46 13 -46 51 0 17 -6 20 -42 17
-39 -3 -44 -6 -53 -35 -5 -18 -15 -33 -22 -33 -17 0 -17 15 1 32 19 20 31 108
14 108 -23 -1 -48 -26 -48 -49 0 -20 -2 -21 -15 -7 -8 8 -15 19 -15 25 0 6
-11 11 -25 11 -14 0 -25 -4 -25 -10 0 -5 -4 -10 -10 -10 -5 0 -10 14 -10 31 0
23 9 41 35 65 19 18 35 40 35 48 0 9 5 16 10 16 6 0 10 -4 10 -10z m-120 -61
c0 -23 -23 -89 -32 -89 -13 0 -9 -30 5 -31 10 0 10 -2 0 -6 -7 -2 -13 -11 -13
-19 0 -12 8 -13 35 -8 31 5 35 4 35 -15 0 -13 -6 -21 -16 -21 -9 0 -18 -9 -21
-20 -3 -11 -12 -20 -19 -20 -8 0 -14 9 -14 20 0 16 -7 20 -30 20 -23 0 -30 5
-30 19 0 11 -5 21 -12 23 -9 3 -9 11 1 35 7 16 9 41 6 55 -6 22 -3 26 25 31
17 4 38 14 46 22 18 18 34 20 34 4z m-177 -69 c-3 -12 1 -22 11 -26 25 -10 19
-34 -8 -34 -16 0 -27 -7 -31 -20 -3 -11 -15 -20 -26 -20 -10 0 -19 -7 -19 -15
0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -7 -15
-16 0 -23 25 -44 54 -44 19 0 26 -5 26 -20 0 -11 5 -20 10 -20 6 0 10 9 10 20
0 11 4 20 9 20 5 0 11 8 13 17 2 13 14 17 55 18 51 0 52 0 34 -20 -26 -28 -35
-63 -21 -80 22 -27 4 -45 -45 -45 -25 0 -45 -3 -45 -7 0 -5 -3 -18 -6 -30 -5
-17 -2 -23 10 -23 9 0 16 -8 16 -20 0 -16 7 -20 35 -20 22 0 38 6 41 15 10 24
21 17 26 -15 3 -20 0 -30 -8 -30 -7 0 -15 -6 -17 -12 -3 -8 -6 -6 -6 5 -1 19
-31 24 -31 5 0 -7 -3 -23 -6 -35 -5 -18 -2 -23 14 -23 11 0 23 5 25 10 3 6 4
5 3 -2 -4 -19 -42 -21 -58 -3 -7 9 -19 22 -25 30 -7 7 -13 23 -13 34 0 17 -6
21 -34 21 -28 0 -35 -4 -40 -25 -4 -14 -13 -25 -21 -25 -11 0 -15 13 -15 50 0
39 -3 50 -16 50 -9 0 -18 -9 -21 -20 -6 -23 -23 -27 -23 -6 0 8 7 16 15 20 22
8 18 36 -5 36 -17 0 -19 4 -14 28 8 34 10 36 23 27 6 -3 11 -17 11 -30 0 -19
7 -24 40 -30 54 -9 80 1 80 31 0 13 -6 24 -13 24 -7 0 -19 9 -27 20 -8 11 -20
20 -27 20 -6 0 -16 6 -20 14 -4 7 -15 16 -23 19 -12 4 -12 6 2 6 11 1 16 10
16 35 0 47 12 96 23 96 5 0 9 7 9 15 0 8 -5 15 -10 15 -6 0 -5 6 2 14 7 8 15
10 16 5 2 -5 15 -9 28 -9 18 0 24 5 24 23 0 25 17 47 36 47 7 0 10 -8 7 -20z
m-1981 -83 c9 -10 18 -23 20 -27 2 -7 38 -14 111 -19 20 -2 4 -21 -18 -21 -25
0 -37 -29 -12 -31 10 0 10 -2 0 -6 -7 -2 -13 -11 -13 -19 0 -8 -7 -14 -15 -14
-8 0 -15 9 -15 20 0 15 -7 20 -26 20 -25 0 -26 -2 -21 -37 4 -21 11 -39 17
-41 15 -5 12 -32 -4 -32 -25 0 -46 30 -46 66 0 24 -4 34 -16 34 -12 0 -14 7
-9 30 4 19 3 30 -4 30 -6 0 -11 18 -11 39 0 36 2 39 23 33 12 -3 30 -14 39
-25z m1274 -77 c8 0 14 -7 14 -16 0 -9 11 -27 25 -40 16 -15 25 -34 25 -54 0
-25 4 -30 25 -30 16 0 25 -6 25 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -7 -15 -15
0 -8 4 -15 9 -15 6 0 11 -15 13 -32 2 -28 8 -34 31 -36 15 -2 27 -5 27 -8 0
-14 -44 -44 -65 -44 -27 0 -28 -2 -19 -49 5 -24 12 -31 30 -31 21 0 24 5 24
35 0 27 4 35 18 35 33 0 62 -12 62 -26 0 -8 7 -14 15 -14 8 0 15 -7 15 -15 0
-8 4 -15 9 -15 5 0 13 -9 16 -20 6 -20 35 -28 35 -10 0 6 7 10 15 10 9 0 15 9
15 24 0 13 -7 26 -15 30 -8 3 -15 15 -15 26 0 15 -7 20 -29 20 -16 0 -31 6
-34 13 -3 9 5 12 29 9 26 -3 34 0 34 12 0 9 -6 16 -14 16 -7 0 -16 9 -19 20
-6 25 -52 27 -65 4 -8 -14 -12 -13 -34 9 -37 37 -42 57 -18 79 24 22 26 38 5
38 -8 0 -17 11 -21 25 -3 14 -11 25 -16 25 -5 0 -3 7 6 15 17 17 36 20 36 5 0
-5 -4 -10 -10 -10 -5 0 -10 -7 -10 -15 0 -8 9 -15 20 -15 11 0 20 7 20 15 0
27 17 16 24 -15 4 -24 11 -30 31 -30 21 0 25 5 25 30 0 17 5 30 10 30 6 0 10
7 10 16 0 8 6 21 13 28 10 11 15 10 25 -1 8 -7 8 -13 2 -13 -5 0 -10 -7 -10
-15 0 -8 5 -15 10 -15 6 0 10 -6 10 -14 0 -8 8 -17 18 -19 9 -3 -2 -5 -24 -6
-27 -1 -44 -6 -48 -16 -3 -8 -12 -15 -21 -15 -8 0 -15 -9 -15 -20 0 -11 5 -20
10 -20 13 0 50 -37 50 -50 0 -5 9 -10 20 -10 11 0 20 -6 20 -14 0 -9 12 -16
32 -18 49 -5 48 -28 -1 -28 -44 0 -54 -16 -26 -44 8 -8 15 -32 15 -54 0 -26
-5 -42 -15 -46 -8 -3 -15 -12 -15 -21 0 -8 -4 -15 -10 -15 -5 0 -10 -13 -10
-29 0 -27 3 -29 35 -28 34 1 49 21 23 30 -17 5 9 57 28 57 7 0 14 6 17 13 3 9
7 9 17 0 10 -9 8 -15 -9 -29 -14 -12 -21 -30 -21 -52 0 -29 3 -34 20 -29 13 3
20 0 20 -9 0 -8 7 -14 15 -14 8 0 15 -9 15 -20 0 -13 7 -20 20 -20 16 0 20 7
20 35 0 19 5 35 10 35 6 0 10 -9 10 -20 0 -11 7 -20 15 -20 8 0 15 -4 15 -9 0
-5 3 -16 6 -24 6 -18 -28 -31 -73 -28 -24 2 -33 -2 -33 -13 0 -9 -4 -16 -10
-16 -5 0 -10 -9 -10 -20 0 -11 7 -20 15 -20 8 0 15 -4 15 -10 0 -5 -7 -10 -15
-10 -8 0 -15 -9 -15 -19 0 -10 7 -21 15 -25 8 -3 15 -12 15 -21 0 -8 6 -15 13
-15 15 0 47 35 48 53 0 9 2 9 6 0 11 -28 33 -13 33 22 0 26 3 33 15 29 8 -4
15 -12 15 -19 0 -7 13 -15 29 -18 26 -5 30 -12 40 -57 14 -63 14 -70 1 -70 -5
0 -10 5 -10 10 0 26 -41 50 -85 50 -38 0 -45 -3 -45 -20 0 -11 -4 -20 -9 -20
-5 0 -12 -11 -15 -25 -4 -14 -11 -25 -16 -25 -6 0 -10 -9 -10 -20 0 -11 5 -20
10 -20 16 0 12 -57 -5 -64 -9 -3 -15 0 -15 9 0 8 -9 15 -20 15 -14 0 -20 -7
-20 -24 0 -13 -6 -27 -12 -29 -10 -4 -9 -8 2 -17 11 -9 12 -13 3 -17 -7 -2
-13 -11 -13 -18 0 -7 6 -15 13 -18 6 -3 -8 -2 -33 0 -48 6 -63 13 -42 20 22 8
13 33 -12 33 -31 0 -46 -38 -22 -55 9 -7 16 -20 16 -29 0 -19 21 -21 38 -4 9
9 12 9 12 -1 0 -9 8 -11 25 -7 18 5 25 2 25 -8 0 -15 30 -26 73 -26 15 0 27
-4 27 -10 0 -5 9 -10 20 -10 11 0 20 7 20 15 0 8 -4 15 -10 15 -5 0 -18 8 -28
18 -17 17 -17 19 10 46 l28 27 0 -30 c0 -23 5 -31 18 -31 15 0 18 -6 14 -30
-3 -22 0 -30 11 -30 21 0 27 23 22 78 -3 26 -3 41 1 35 7 -16 64 -17 64 -2 0
6 7 3 16 -5 9 -9 32 -16 55 -16 32 0 39 -3 39 -20 0 -14 7 -20 24 -20 17 0 28
-9 38 -30 7 -16 16 -30 20 -30 5 0 8 -7 8 -15 0 -10 10 -15 30 -15 26 0 29 -2
18 -14 -7 -8 -14 -10 -16 -5 -7 19 -43 8 -54 -16 -20 -44 29 -76 58 -38 17 24
26 12 28 -34 0 -27 -6 -45 -22 -61 -28 -28 -28 -27 -36 3 -5 21 -12 25 -41 25
-19 0 -35 -4 -35 -10 0 -5 -7 -10 -15 -10 -8 0 -15 7 -15 15 0 8 9 15 20 15
11 0 20 6 20 14 0 17 -62 34 -68 18 -1 -5 -12 -8 -22 -5 -14 4 -20 14 -20 34
0 16 -4 29 -9 29 -5 0 -11 9 -14 21 -5 18 -11 20 -36 14 -27 -6 -31 -4 -31 14
0 12 -6 21 -15 21 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -15 -10 -11 0 -15 -11 -15
-40 0 -29 4 -40 15 -40 8 0 15 -4 15 -10 0 -5 11 -12 25 -16 27 -7 35 -34 10
-34 -8 0 -15 -9 -15 -20 0 -23 25 -27 37 -7 4 8 20 17 36 21 26 6 27 5 27 -29
0 -19 -4 -35 -10 -35 -5 0 -10 -6 -10 -14 0 -23 -22 -31 -35 -13 -7 10 -17 17
-23 17 -6 0 -14 9 -17 20 -4 13 -16 20 -33 21 -17 1 -21 3 -9 6 23 6 22 50 -2
63 -11 5 -33 10 -50 10 -25 0 -31 -4 -31 -21 0 -19 -1 -19 -16 -5 -8 9 -20 16
-25 16 -13 0 -11 -24 3 -38 9 -9 9 -18 0 -37 -6 -13 -9 -32 -6 -40 10 -25 31
-17 38 15 6 34 36 42 36 10 0 -11 4 -20 9 -20 5 0 14 -13 20 -30 6 -18 6 -30
0 -30 -5 0 -9 -7 -9 -15 0 -8 -7 -15 -15 -15 -16 0 -19 -17 -9 -44 7 -17 34
-22 34 -6 0 6 7 10 15 10 11 0 15 11 16 38 0 20 3 31 6 25 2 -7 11 -13 18 -13
7 0 16 6 19 13 3 8 8 7 16 -5 7 -12 25 -18 48 -19 20 -1 29 -3 20 -6 -11 -3
-18 -15 -19 -31 0 -15 -3 -21 -6 -14 -2 6 -13 12 -23 12 -10 0 -21 -6 -24 -12
-5 -10 -7 -10 -12 0 -8 16 -44 15 -44 -2 0 -7 3 -28 6 -45 6 -27 10 -31 40
-31 19 0 34 5 34 10 0 6 7 10 15 10 19 0 22 -90 3 -91 -7 0 -30 -2 -50 -4 -33
-4 -39 -1 -44 20 -3 14 -10 25 -15 25 -5 0 -9 16 -9 35 0 31 -3 35 -25 35 -14
0 -25 5 -25 10 0 6 -11 10 -25 10 -16 0 -25 -6 -25 -15 0 -8 5 -15 10 -15 6 0
10 -7 10 -15 0 -10 -10 -15 -30 -15 -27 0 -30 3 -30 30 0 17 -3 30 -7 30 -5 0
-18 3 -30 6 -21 6 -23 3 -23 -30 0 -20 4 -36 10 -36 5 0 12 -17 16 -37 4 -21
13 -43 20 -50 16 -17 51 -17 58 0 3 8 8 7 16 -5 14 -22 34 -23 54 -2 9 8 16
11 16 5 0 -6 6 -11 14 -11 7 0 16 -9 19 -20 6 -23 57 -29 57 -6 0 13 20 26 41
26 5 0 9 -7 9 -15 0 -8 7 -15 15 -15 8 0 15 5 15 10 0 6 7 10 15 10 8 0 15 6
15 14 0 8 -7 21 -15 30 -8 8 -15 24 -15 35 0 12 6 21 15 21 8 0 15 7 15 15 0
19 31 35 69 35 17 0 31 5 31 10 0 6 7 10 15 10 10 0 15 10 15 30 0 20 5 30 15
30 8 0 15 -4 15 -9 0 -5 9 -11 20 -14 23 -6 27 -27 5 -27 -21 0 -19 -30 3 -46
16 -13 16 -15 0 -28 -19 -14 -25 -56 -8 -56 6 0 10 -9 10 -20 0 -17 -7 -20
-45 -20 -43 0 -45 1 -45 30 0 20 -5 30 -15 30 -9 0 -18 7 -21 15 -7 17 -34 21
-34 5 0 -5 -7 -10 -15 -10 -9 0 -15 -9 -15 -24 0 -14 7 -31 15 -40 8 -8 15
-22 15 -30 0 -9 7 -16 15 -16 8 0 15 -9 15 -20 0 -13 7 -20 20 -20 15 0 20 7
21 28 1 21 2 22 6 7 3 -11 10 -23 17 -27 7 -5 5 -8 -5 -8 -20 0 -36 -50 -19
-60 14 -9 2 -40 -17 -40 -21 0 -43 25 -43 49 0 12 -6 21 -14 21 -8 0 -16 10
-18 22 -5 38 -35 34 -42 -6 -8 -41 -31 -53 -53 -26 -22 26 -43 26 -43 0 0 -11
6 -20 14 -20 19 0 28 -27 11 -33 -7 -3 -22 -3 -33 -1 -17 5 -20 -1 -25 -48 -4
-36 -13 -58 -27 -70 -15 -12 -20 -28 -20 -62 0 -25 5 -46 10 -46 6 0 10 6 10
14 0 8 7 16 15 20 10 3 15 -1 15 -13 0 -11 6 -22 13 -24 6 -3 0 -6 -15 -6 -21
-1 -29 -7 -34 -26 -3 -14 -12 -25 -19 -25 -12 0 -25 -21 -25 -41 0 -5 7 -9 15
-9 8 0 15 -4 15 -10 0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 -7 10 -15 10
-8 0 -15 -4 -15 -9 0 -5 -13 -12 -29 -16 -23 -5 -30 -13 -35 -41 -4 -19 -11
-34 -16 -34 -6 0 -10 -7 -10 -16 0 -12 6 -15 26 -10 32 8 74 52 74 77 0 11 5
19 10 19 6 0 10 -6 10 -14 0 -8 15 -17 36 -21 47 -8 66 -26 47 -43 -10 -10
-13 -10 -13 3 0 9 -9 15 -21 15 -17 0 -20 -4 -15 -22 3 -13 6 -28 6 -34 0 -18
52 -28 61 -12 6 9 9 6 9 -10 0 -16 -7 -26 -20 -29 -13 -3 -20 -14 -20 -28 0
-16 -9 -26 -30 -35 -31 -13 -41 -40 -15 -40 8 0 15 -7 15 -16 0 -13 6 -15 27
-9 15 3 31 12 35 18 5 7 8 0 8 -15 0 -18 5 -28 15 -28 8 0 15 -4 15 -10 0 -5
-7 -10 -15 -10 -8 0 -15 -5 -15 -11 0 -16 26 -39 44 -39 9 0 16 -8 16 -20 0
-11 -4 -20 -10 -20 -5 0 -10 7 -10 15 0 8 -4 15 -10 15 -5 0 -10 -4 -10 -10 0
-5 -7 -10 -15 -10 -8 0 -15 7 -15 15 0 8 -7 15 -15 15 -11 0 -15 -11 -15 -40
0 -29 4 -40 15 -40 8 0 15 -5 15 -11 0 -23 -34 -42 -81 -47 -40 -4 -49 -8 -49
-24 0 -13 -6 -18 -17 -16 -25 5 -27 25 -4 33 12 4 21 15 21 26 0 11 4 18 9 15
16 -10 71 6 71 21 0 10 -10 13 -37 11 l-38 -3 -3 48 c-3 46 -4 47 -38 47 -18
0 -34 -4 -34 -10 0 -5 -11 -10 -25 -10 -13 0 -29 -8 -34 -17 -9 -16 -10 -15
-10 7 0 26 17 74 18 53 1 -7 12 -13 26 -13 21 0 25 5 25 30 0 18 -5 30 -13 30
-9 0 -12 11 -9 35 3 27 1 35 -12 35 -9 0 -16 7 -16 15 0 8 -4 15 -9 15 -5 0
-7 9 -4 20 6 21 -5 24 -45 14 -19 -5 -23 -12 -20 -38 3 -28 6 -31 36 -31 30 0
32 -2 32 -37 0 -52 -9 -68 -36 -68 -18 0 -24 -6 -25 -22 -1 -13 -3 -43 -4 -67
l-3 -44 55 6 55 6 -6 -31 c-3 -17 -13 -36 -22 -43 -20 -14 -11 -55 12 -55 8 0
22 -9 31 -20 10 -11 23 -20 30 -20 20 0 16 -20 -9 -46 -28 -29 -38 -30 -38 -6
0 28 -35 63 -54 56 -19 -7 -21 -40 -3 -48 10 -5 10 -7 0 -12 -19 -9 -16 -22
12 -49 25 -26 45 -32 45 -15 0 6 5 10 10 10 14 0 60 46 60 60 0 5 7 10 15 10
8 0 15 7 15 15 0 8 5 15 10 15 6 0 10 -4 10 -9 0 -6 17 -11 37 -13 27 -2 39
-8 41 -20 2 -13 -3 -18 -18 -18 -27 0 -50 -22 -50 -49 0 -12 -6 -21 -14 -21
-14 0 -26 -21 -26 -46 0 -22 67 -19 73 4 4 13 5 11 6 -5 1 -13 7 -23 16 -23
10 0 15 -10 15 -30 0 -27 3 -30 30 -30 17 0 30 -4 30 -10 0 -5 14 -10 30 -10
17 0 30 5 30 10 0 6 7 10 15 10 8 0 15 9 15 20 0 16 7 20 35 20 l35 0 0 -65
c0 -59 2 -65 21 -65 18 0 20 4 15 25 -4 14 -2 25 4 25 5 0 10 -7 10 -15 0 -10
10 -15 30 -15 19 0 30 5 30 14 0 11 27 25 51 26 4 0 10 -12 14 -27 3 -15 12
-31 18 -35 8 -5 6 -8 -5 -8 -11 0 -18 -8 -18 -19 0 -11 -5 -23 -11 -26 -6 -4
-15 -17 -20 -29 -9 -19 -9 -19 -4 4 13 54 9 100 -9 100 -19 0 -27 -24 -19 -60
4 -23 2 -30 -10 -30 -8 0 -17 6 -20 13 -3 9 -7 9 -19 -1 -13 -10 -16 -9 -21 8
-3 11 -12 20 -21 20 -9 0 -16 5 -16 10 0 6 -7 10 -15 10 -8 0 -15 -6 -15 -14
0 -13 -28 -26 -57 -26 -7 0 -13 9 -13 20 0 11 7 20 15 20 8 0 15 7 15 15 0 8
-7 15 -15 15 -8 0 -15 -7 -15 -15 0 -9 -9 -15 -24 -15 -13 0 -26 -6 -29 -14
-3 -7 -19 -16 -36 -19 -17 -4 -31 -11 -31 -17 0 -17 26 -40 44 -40 24 0 19
-27 -5 -33 -18 -5 -20 -11 -14 -34 3 -15 10 -30 14 -33 5 -3 11 -15 15 -27 8
-25 26 -29 57 -13 16 9 19 7 19 -14 0 -37 -26 -58 -65 -50 -29 5 -33 3 -39
-20 -3 -14 -11 -26 -16 -26 -15 0 -12 -18 7 -40 20 -23 73 -28 73 -6 0 19 19
31 31 19 14 -14 -2 -53 -22 -53 -47 0 -109 -44 -109 -77 0 -19 -5 -23 -30 -23
-23 0 -30 -4 -30 -20 0 -11 5 -20 10 -20 6 0 10 -7 10 -15 0 -8 -4 -15 -10
-15 -19 0 -10 -28 10 -33 20 -5 29 -47 10 -47 -5 0 -10 -7 -10 -15 0 -8 -4
-15 -9 -15 -5 0 -12 -8 -14 -17 -3 -10 -5 3 -6 30 -1 48 -12 58 -37 33 -17
-18 -18 -33 -1 -39 7 -4 6 -6 -4 -6 -20 -1 -35 47 -20 62 6 6 11 24 11 39 0
15 5 28 10 28 6 0 10 -4 10 -10 0 -5 7 -10 15 -10 9 0 15 9 15 25 0 16 -6 25
-16 25 -11 0 -14 6 -8 23 4 12 10 24 14 27 4 3 10 15 14 28 4 12 12 22 17 22
5 0 9 10 10 23 0 12 3 16 6 10 7 -18 30 -16 37 2 9 25 -5 74 -24 80 -35 11
-68 -5 -75 -36 -4 -16 -11 -29 -16 -29 -5 0 -13 -10 -17 -21 -8 -20 -10 -20
-24 -5 -22 22 -118 23 -118 1 0 -8 -7 -15 -15 -15 -10 0 -15 -10 -15 -29 0
-16 -5 -31 -12 -33 -18 -6 1 -28 24 -28 31 0 13 -13 -19 -14 -32 -1 -65 28
-56 50 3 8 9 14 14 14 14 0 11 108 -3 113 -7 2 -9 8 -5 13 4 5 13 2 18 -6 8
-11 12 -12 16 -2 7 17 33 15 33 -3 0 -10 10 -15 30 -15 20 0 30 5 30 15 0 10
-10 15 -30 15 -16 0 -30 5 -30 10 0 6 -7 10 -15 10 -8 0 -17 11 -21 25 -3 14
-14 25 -23 26 -10 0 -11 2 -3 6 14 6 16 33 2 33 -5 0 -10 9 -10 19 0 11 -7 27
-16 35 -14 15 -14 16 0 16 9 0 16 7 16 15 0 17 18 19 39 6 10 -6 10 -10 1 -16
-15 -9 -4 -72 16 -92 8 -8 14 -21 14 -29 0 -22 20 -39 63 -55 20 -7 37 -19 37
-26 0 -7 7 -13 15 -13 8 0 15 9 15 19 0 13 -9 21 -30 26 -24 5 -30 12 -30 32
0 13 7 31 15 40 9 8 13 23 10 33 -6 20 18 30 73 30 22 0 32 5 32 15 0 22 17
18 23 -5 3 -11 12 -20 21 -20 9 0 16 -7 16 -15 0 -10 -10 -15 -30 -15 -20 0
-30 -5 -30 -15 0 -8 8 -15 19 -15 10 0 33 -3 50 -6 25 -5 31 -3 31 11 0 10 7
23 16 29 16 12 11 66 -7 66 -5 0 -9 7 -9 15 0 8 5 15 10 15 6 0 10 7 10 15 0
10 -10 15 -28 15 -15 0 -35 9 -45 20 -9 11 -24 20 -32 20 -8 0 -15 -9 -15 -20
0 -11 4 -20 9 -20 5 0 11 -9 14 -19 3 -14 1 -18 -9 -14 -8 3 -20 0 -28 -6 -7
-6 -16 -8 -20 -4 -4 4 -11 1 -16 -7 -5 -8 -13 -11 -17 -6 -4 5 -2 11 5 13 6 3
12 20 12 39 0 19 5 34 10 34 6 0 10 6 10 14 0 8 7 16 15 20 8 3 15 12 15 21 0
11 -12 15 -42 16 -24 1 -35 3 -25 6 21 5 23 33 2 33 -8 0 -15 9 -15 20 0 29
-22 25 -42 -7 -15 -25 -18 -26 -19 -8 -1 11 -1 28 0 38 2 11 -5 17 -18 17 -12
0 -21 7 -21 15 0 9 -9 15 -24 15 -13 0 -26 -7 -30 -15 -3 -8 -12 -15 -21 -15
-11 0 -15 -10 -15 -36 0 -32 3 -36 20 -31 11 3 27 -1 34 -9 8 -8 27 -14 43
-15 16 0 22 -3 13 -6 -35 -14 -56 -63 -27 -63 8 0 9 -2 1 -8 -6 -4 -14 -17
-18 -29 -4 -13 -14 -23 -22 -23 -19 0 -18 19 1 26 17 7 21 34 5 34 -5 0 -10 7
-10 16 0 12 -7 15 -25 12 -22 -5 -24 -2 -19 18 5 18 2 24 -10 24 -10 0 -19 11
-23 30 -5 29 -7 30 -49 26 l-44 -4 0 49 c0 32 4 49 13 50 25 3 42 -3 50 -17
11 -19 87 -19 87 -1 0 8 -19 20 -42 27 -51 17 -58 25 -58 67 0 17 -6 34 -12
36 -8 3 -6 6 4 6 10 1 20 12 24 26 3 14 10 25 15 25 15 0 10 27 -5 33 -8 3
-25 3 -38 0 l-23 -6 25 26 c30 32 61 36 53 8 -3 -14 2 -20 22 -25 27 -7 51 3
28 11 -7 3 -13 9 -13 15 0 17 43 49 58 43 21 -8 62 6 62 21 0 8 -7 14 -15 14
-8 0 -15 7 -15 15 0 8 -14 17 -35 21 -19 3 -35 10 -35 15 0 5 -7 9 -15 9 -9 0
-15 -9 -15 -25 0 -14 -4 -25 -10 -25 -5 0 -10 7 -10 15 0 8 -7 15 -15 15 -8 0
-15 5 -15 10 0 6 -9 10 -20 10 -10 0 -23 7 -28 16 -5 9 -18 14 -28 12 -14 -2
-20 -13 -22 -41 -2 -20 -7 -37 -13 -37 -5 0 -9 -9 -9 -20 0 -14 7 -20 23 -21
13 0 16 -3 8 -6 -19 -8 -51 -41 -51 -53 0 -5 12 -13 28 -16 15 -4 30 -12 34
-18 13 -19 9 -46 -7 -46 -8 0 -15 -4 -15 -10 0 -5 -11 -10 -25 -10 -14 0 -25
5 -25 10 0 6 -13 10 -30 10 -27 0 -30 -3 -30 -30 0 -20 -5 -30 -15 -30 -8 0
-15 -9 -15 -20 0 -11 5 -20 10 -20 6 0 10 -7 10 -16 0 -18 23 -44 39 -44 6 0
11 5 11 10 0 6 7 10 15 10 8 0 15 -7 15 -15 0 -8 5 -15 10 -15 21 0 9 -18 -17
-25 -15 -3 -35 -15 -44 -26 -11 -13 -18 -16 -21 -8 -3 8 -14 10 -31 5 -36 -9
-38 -46 -2 -46 14 0 25 -4 25 -10 0 -5 16 -10 35 -10 19 0 35 5 35 10 0 18 25
10 43 -15 15 -22 16 -25 2 -25 -8 0 -15 5 -15 10 0 6 -7 10 -16 10 -12 0 -15
-6 -11 -21 5 -20 3 -21 -44 -15 -45 6 -49 5 -49 -14 0 -11 7 -20 15 -20 23 0
18 -13 -15 -40 -32 -27 -40 -60 -14 -60 12 0 14 -7 9 -32 -4 -18 -11 -35 -15
-38 -5 -3 -10 -16 -11 -30 -3 -15 -12 -26 -24 -29 -11 -2 -24 -8 -28 -15 -6
-8 -10 -8 -14 2 -6 16 -31 15 -49 -2 -23 -23 -17 -44 13 -48 29 -3 47 -38 20
-38 -8 0 -17 -14 -21 -31 -10 -50 -7 -59 14 -59 11 0 20 4 20 10 0 15 33 50
42 44 16 -10 48 8 48 27 0 12 7 19 20 19 11 0 20 5 20 10 0 6 14 10 30 10 23
0 30 5 30 19 0 15 10 19 47 24 54 6 85 -5 38 -13 -21 -4 -31 -12 -33 -28 -2
-12 -8 -22 -13 -22 -18 0 -9 -29 11 -35 11 -3 20 -13 20 -22 0 -8 9 -17 20
-20 23 -6 29 -53 6 -53 -8 0 -16 -5 -18 -10 -2 -6 -17 -8 -36 -4 -29 6 -32 10
-32 41 0 33 -1 35 -27 28 -15 -3 -31 -12 -35 -18 -5 -8 -8 -6 -8 6 0 17 -25
23 -47 9 -6 -4 -14 -19 -18 -34 -5 -21 -15 -29 -41 -34 -19 -3 -34 -10 -34
-15 0 -5 -7 -9 -16 -9 -28 0 -11 -35 19 -38 20 -3 27 -9 27 -25 0 -12 3 -39 6
-59 6 -37 6 -38 -22 -38 -60 0 -74 -10 -75 -49 -1 -20 -3 -28 -6 -18 -3 10
-18 20 -34 24 -16 3 -29 12 -29 19 0 9 -12 15 -32 15 -24 1 -27 3 -10 6 12 2
22 9 22 14 0 5 5 9 10 9 6 0 10 -7 10 -15 0 -8 9 -15 20 -15 16 0 20 7 20 30
0 20 -5 30 -15 30 -16 0 -21 36 -5 42 5 2 12 22 16 46 6 37 4 42 -12 43 -11 0
-14 3 -6 6 16 6 16 40 0 47 -10 5 -10 7 0 12 6 3 12 14 12 25 0 10 -6 19 -12
20 -10 0 -10 2 0 6 16 6 16 30 0 37 -10 5 -10 7 0 12 17 8 15 44 -2 44 -8 0
-22 -10 -30 -22 l-16 -22 0 22 c0 18 -6 22 -30 22 -37 0 -41 27 -5 36 27 7 35
34 10 34 -8 0 -15 5 -15 10 0 6 7 13 15 16 8 4 15 12 15 20 0 8 5 14 10 14 6
0 10 7 10 15 0 8 7 15 15 15 8 0 15 9 15 20 0 11 3 20 8 20 4 0 17 3 29 6 18
5 21 3 16 -15 -5 -18 -1 -21 30 -21 19 0 38 6 40 13 3 7 6 5 6 -5 1 -10 6 -18
11 -18 6 0 10 -11 10 -25 0 -14 5 -25 10 -25 6 0 10 7 10 15 0 8 5 15 10 15
19 0 11 48 -10 55 -11 3 -20 13 -20 21 0 21 -50 18 -51 -3 -1 -17 -2 -17 -6 0
-2 9 -9 17 -14 17 -5 0 -9 7 -9 15 0 18 -36 20 -43 3 -3 -8 -6 -6 -6 5 -1 9
-8 17 -16 17 -8 0 -15 7 -15 15 0 8 6 15 13 15 15 0 57 44 57 59 0 6 9 11 20
11 13 0 20 7 20 20 0 11 5 20 10 20 6 0 10 7 10 15 0 8 -7 15 -15 15 -9 0 -15
9 -15 25 0 15 6 25 14 25 8 0 21 3 30 6 11 4 16 1 16 -10 0 -9 -4 -16 -10 -16
-5 0 -10 -7 -10 -15 0 -8 7 -15 15 -15 8 0 15 -7 15 -15 0 -8 7 -15 15 -15 8
0 15 6 15 14 0 9 7 12 20 9 17 -5 20 0 20 31 0 29 -4 36 -20 36 -11 0 -20 7
-20 15 0 10 -11 15 -35 15 -34 0 -35 1 -35 40 0 36 -3 40 -24 40 -13 0 -26 -6
-29 -12 -2 -7 -8 -1 -12 15 -4 15 -13 27 -21 27 -8 0 -14 7 -14 15 0 8 9 15
20 15 11 0 20 7 20 15 0 10 11 15 35 15 29 0 35 4 35 21 0 16 -5 20 -20 16
-13 -3 -20 0 -20 9 0 17 27 18 44 1 14 -13 29 -74 30 -119 1 -20 -4 -28 -16
-28 -11 0 -18 -8 -18 -20 0 -11 7 -20 15 -20 8 0 15 -4 15 -10 0 -5 7 -10 15
-10 8 0 15 4 15 9 0 5 9 11 20 14 24 6 27 37 4 37 -14 0 -14 2 1 16 10 10 14
25 11 38 -3 12 -6 29 -6 39 0 11 -8 17 -24 17 -20 0 -24 6 -28 37 -6 50 1 83
18 83 11 0 13 9 8 40 -5 32 -4 40 9 40 9 0 19 9 22 20 9 29 -4 99 -23 121 -19
22 -42 25 -42 5 0 -8 -13 -17 -30 -21 -21 -5 -30 -13 -30 -26 0 -10 5 -19 10
-19 6 0 20 -8 31 -19 15 -13 18 -25 13 -45 -3 -14 -10 -26 -15 -26 -5 0 -9 -7
-9 -15 0 -8 -9 -15 -20 -15 -11 0 -20 6 -20 14 0 17 -24 28 -45 20 -26 -10
-17 -34 15 -40 25 -5 30 -11 30 -35 0 -23 5 -29 23 -30 l22 -2 -22 -4 c-13 -2
-23 -9 -23 -14 0 -5 -4 -9 -10 -9 -5 0 -10 6 -10 13 0 8 -13 13 -34 14 -36 1
-46 -13 -46 -64 0 -14 -6 -23 -15 -23 -11 0 -15 -11 -15 -40 0 -33 3 -40 20
-40 16 0 20 -7 20 -35 0 -24 -5 -35 -15 -35 -10 0 -15 -10 -15 -30 0 -16 -4
-30 -10 -30 -5 0 -10 -7 -10 -15 0 -8 9 -15 19 -15 15 0 20 -9 23 -40 2 -23 1
-45 -2 -49 -3 -4 -24 -10 -47 -14 -37 -4 -43 -3 -43 13 0 10 4 20 9 22 11 4
21 108 10 108 -4 0 -19 -4 -33 -10 -21 -7 -25 -17 -28 -54 -3 -38 -5 -44 -18
-35 -11 8 -12 12 -2 16 6 2 12 18 12 34 0 16 -6 29 -12 30 -7 0 1 5 18 10 19
5 34 17 37 30 7 27 -2 79 -14 79 -5 0 -9 5 -9 10 0 6 7 10 15 10 8 0 15 7 15
15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15 0 8 -4 15 -10 15 -5 0 -10 -11 -10 -25
0 -20 -5 -25 -25 -25 -26 0 -35 -24 -12 -33 6 -3 0 -6 -15 -6 -18 -1 -28 -6
-28 -16 0 -8 -4 -15 -10 -15 -5 0 -10 5 -10 10 0 6 -7 10 -15 10 -8 0 -15 -4
-15 -10 0 -5 -20 -10 -45 -10 -45 0 -45 0 -45 -35 0 -28 -4 -35 -20 -35 -14 0
-20 -7 -20 -21 0 -16 5 -20 20 -16 13 3 20 0 20 -10 0 -14 23 -43 34 -43 3 0
6 7 6 15 0 8 5 15 10 15 6 0 10 -22 10 -49 0 -40 -3 -50 -20 -54 -17 -4 -20 0
-20 29 0 19 -4 34 -10 34 -5 0 -10 7 -10 15 0 20 -27 19 -48 -2 -12 -12 -13
-18 -4 -21 17 -5 15 -22 -2 -22 -8 0 -18 9 -21 20 -3 11 -11 20 -16 20 -5 0
-9 7 -9 15 0 8 5 15 10 15 6 0 10 7 10 15 0 9 -9 15 -25 15 -18 0 -25 -5 -25
-20 0 -11 -7 -20 -15 -20 -13 0 -20 22 -28 90 -3 21 -57 26 -57 6 0 -9 -7 -16
-15 -16 -10 0 -15 10 -15 29 0 28 1 28 46 23 40 -4 46 -2 51 16 3 11 11 23 19
26 8 3 14 12 14 21 0 8 7 15 15 15 17 0 18 4 9 38 -5 17 -14 22 -40 22 -24 0
-34 5 -34 15 0 8 -7 15 -15 15 -10 0 -15 10 -15 30 0 18 6 33 15 36 8 4 15 12
15 19 0 7 5 15 11 17 15 5 2 104 -17 123 -8 7 -14 27 -14 43 0 25 5 30 30 35
21 5 31 14 36 32 3 14 10 25 15 25 5 0 9 10 10 23 0 12 3 16 6 10 2 -7 11 -13
19 -13 8 0 14 -4 14 -9 0 -5 7 -17 16 -25 14 -15 14 -16 0 -16 -12 0 -16 -10
-16 -35 0 -19 4 -35 9 -35 4 0 6 -11 3 -25 -4 -23 -2 -25 37 -25 22 0 41 3 41
8 0 4 3 18 7 31 5 21 3 23 -14 19 -12 -3 -27 -2 -34 3 -10 6 -6 9 14 9 15 0
27 6 28 13 0 10 2 10 6 0 2 -7 11 -13 18 -13 8 0 17 -9 20 -20 7 -24 21 -25
41 -6 16 17 19 46 4 46 -5 0 -10 7 -10 15 0 8 4 15 8 15 17 0 62 46 62 63 0 9
5 17 10 17 6 0 10 5 10 11 0 16 -26 39 -44 39 -10 0 -16 9 -16 25 0 18 -5 25
-20 25 -16 0 -20 7 -20 34 0 19 -4 36 -9 38 -4 2 -12 13 -15 26 -6 16 -3 22 8
22 10 0 16 -9 16 -25 0 -16 6 -25 15 -25 11 0 15 12 15 45 0 39 -3 45 -25 51
-22 6 -25 11 -25 56 0 35 4 48 13 46 6 -3 13 -14 15 -26 2 -12 7 -22 12 -22 4
0 11 -11 14 -25 7 -27 36 -35 36 -10 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 8
5 15 11 15 6 0 17 7 25 15 22 22 64 20 64 -4 0 -49 -13 -81 -32 -81 -18 0 -19
-5 -14 -35 6 -28 4 -35 -8 -35 -20 0 -56 -31 -56 -47 0 -7 7 -13 15 -13 8 0
15 3 15 8 0 4 12 13 27 19 22 11 28 10 42 -6 9 -10 26 -21 39 -25 12 -4 22
-12 22 -17 0 -5 7 -9 15 -9 8 0 15 7 15 15 0 8 -4 15 -10 15 -5 0 -10 5 -10
10 0 6 13 10 29 10 39 0 61 9 61 26 0 8 9 14 20 14 23 0 27 28 6 36 -8 3 -17
12 -20 19 -3 9 0 12 10 8 7 -3 14 -9 14 -14 0 -5 11 -9 25 -9 14 0 25 5 25 10
0 6 11 10 25 10 22 0 25 4 25 35 0 24 -5 35 -15 35 -8 0 -15 -4 -15 -9 0 -5
-11 -12 -25 -15 -14 -4 -25 -11 -25 -16 0 -6 -9 -10 -20 -10 -11 0 -20 5 -20
10 0 6 -7 10 -15 10 -8 0 -15 7 -15 15 0 9 -7 18 -15 21 -9 4 -15 19 -15 35 0
29 -14 39 -24 17 -3 -9 -8 -8 -16 5 -8 12 -24 17 -55 17 -41 0 -45 2 -45 25 0
16 6 25 15 25 8 0 15 -7 15 -15 0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -4 15
-10 15 -5 0 -10 12 -10 26 0 24 2 26 48 24 65 -3 70 -2 75 18 3 9 5 2 6 -15 1
-28 5 -33 26 -33 17 0 25 6 26 18 0 14 2 14 9 -3 13 -30 12 -35 -10 -35 -11 0
-20 -7 -20 -15 0 -8 -6 -15 -14 -15 -16 0 -29 -44 -21 -71 9 -27 55 -26 55 1
0 11 -4 20 -10 20 -5 0 -10 7 -10 15 0 10 10 15 30 15 23 0 31 5 36 25 4 18
13 25 31 25 16 0 27 7 30 20 3 11 11 20 18 20 7 0 15 10 18 21 5 19 10 21 51
15 50 -8 61 5 29 30 -16 12 -15 13 15 14 22 0 32 5 32 15 0 8 -4 15 -10 15 -5
0 -10 5 -10 10 0 6 7 10 15 10 8 0 15 7 15 15 0 8 3 15 6 15 3 0 12 -7 20 -15
24 -23 44 -18 44 10 0 16 -6 25 -15 25 -9 0 -15 9 -15 25 0 14 -4 25 -10 25
-5 0 -10 7 -10 15 0 9 9 15 25 15 30 0 34 27 8 51 -10 9 -26 26 -35 38 -22 26
-48 27 -55 1 -4 -15 -13 -19 -37 -17 -28 2 -31 -1 -34 -28 -8 -63 -52 -54 -52
11 0 40 -2 44 -25 44 -21 0 -25 -5 -25 -28 0 -16 9 -39 20 -52 12 -13 18 -30
15 -38 -3 -7 -5 -18 -5 -23 0 -5 -7 -9 -15 -9 -8 0 -15 -7 -15 -15 0 -8 7 -15
15 -15 8 0 15 5 15 10 0 6 16 10 35 10 35 0 50 22 23 33 -10 4 -10 6 0 6 7 1
15 -6 18 -14 4 -8 11 -15 18 -15 35 -1 76 -70 41 -70 -8 0 -15 -8 -16 -17 0
-11 -3 -13 -6 -5 -5 14 -43 17 -43 3 0 -5 -13 -12 -29 -16 -16 -3 -32 -13 -35
-21 -3 -8 -19 -14 -36 -14 -23 0 -30 4 -30 20 0 14 -7 20 -21 20 -20 0 -21 4
-15 45 6 37 4 45 -8 45 -9 0 -16 -4 -16 -10 0 -5 -7 -10 -15 -10 -11 0 -15 11
-15 39 0 31 4 41 21 46 11 4 23 4 26 1 4 -3 1 -6 -5 -6 -7 0 -12 -7 -12 -15 0
-8 7 -15 15 -15 8 0 15 7 15 15 0 8 6 15 14 15 8 0 17 7 20 16 19 51 -40 83
-77 43 l-22 -24 -3 35 c-2 19 -9 38 -15 42 -7 5 0 8 16 8 15 0 27 5 27 10 0 6
7 10 15 10 8 0 15 7 15 15 0 8 5 15 10 15 6 0 10 9 10 20 0 11 5 20 10 20 6 0
10 20 10 45 0 39 -3 45 -21 45 -15 0 -19 4 -15 16 3 9 6 20 6 25 0 5 7 9 15 9
11 0 15 12 15 50 0 43 -3 50 -20 50 -13 0 -20 -7 -20 -20 0 -15 -9 -22 -30
-27 -28 -5 -30 -9 -25 -34 9 -38 -4 -99 -21 -99 -9 0 -14 12 -14 35 0 32 -2
35 -30 35 -29 0 -30 -2 -30 -45 0 -33 -4 -45 -15 -45 -8 0 -15 8 -15 17 0 24
-23 83 -32 83 -4 0 -8 7 -8 15 0 8 -7 15 -15 15 -18 0 -20 -30 -2 -31 8 0 7
-3 -3 -6 -8 -3 -23 -14 -34 -25 -11 -10 -32 -18 -48 -18 -23 0 -28 -4 -28 -24
0 -13 7 -26 15 -30 8 -3 15 -12 15 -20 0 -7 7 -16 15 -20 8 -3 15 -12 15 -21
0 -8 7 -15 15 -15 15 0 19 -10 16 -41 -1 -9 7 -19 19 -22 24 -6 29 -57 5 -57
-19 0 -19 -17 0 -36 9 -10 13 -24 9 -40 -5 -18 -2 -24 10 -24 9 0 16 -7 16
-15 0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 -4 15 -9 15 -5 0 -13 10 -17
22 -10 31 -30 49 -48 42 -20 -8 -21 -60 -1 -68 8 -3 15 -15 15 -26 0 -13 7
-20 20 -20 11 0 20 -4 20 -10 0 -15 57 -12 63 3 4 7 6 6 6 -5 1 -9 5 -18 10
-20 5 -1 13 -16 17 -32 7 -27 6 -29 -20 -22 -22 5 -26 3 -26 -14 0 -15 -8 -22
-30 -26 -23 -5 -30 -12 -30 -30 0 -24 -8 -28 -42 -25 -28 3 -78 -28 -78 -49 0
-10 -4 -22 -10 -25 -5 -3 -10 3 -10 14 0 16 -6 21 -25 21 -18 0 -25 5 -25 20
0 11 -7 20 -15 20 -8 0 -15 -9 -15 -20 0 -11 7 -20 15 -20 8 0 15 -7 15 -15 0
-8 6 -15 13 -16 10 0 10 -2 0 -6 -7 -2 -13 -13 -13 -23 0 -10 -7 -20 -15 -24
-12 -4 -15 3 -15 34 0 22 -4 40 -10 40 -5 0 -10 11 -10 25 0 14 -4 25 -10 25
-5 0 -10 7 -10 15 0 8 7 15 16 15 20 0 44 24 44 45 0 14 6 16 27 11 33 -9 43
5 43 62 0 29 5 45 15 48 9 4 15 19 15 35 0 22 -5 29 -20 29 -11 0 -20 5 -20
10 0 15 -19 12 -36 -4 -16 -16 -19 -76 -4 -76 6 0 10 -6 10 -14 0 -17 -31 -26
-87 -26 -45 0 -48 5 -37 48 6 21 34 32 34 12 0 -5 7 -10 15 -10 8 0 15 7 15
15 0 8 7 15 15 15 23 0 19 47 -5 55 -15 5 -20 15 -19 38 0 18 3 26 6 20 2 -7
13 -13 24 -13 23 0 27 48 4 56 -9 4 -15 19 -15 35 0 16 -4 29 -9 29 -12 0 -41
28 -41 40 0 4 9 6 20 3 11 -3 20 -12 20 -19 0 -8 7 -14 15 -14 8 0 15 5 15 10
0 6 6 10 14 10 8 0 17 6 19 13 3 6 6 2 6 -10 1 -16 7 -23 21 -23 16 0 20 7 20
30 0 23 -4 30 -20 30 -11 0 -20 7 -20 15 0 18 17 20 22 3 3 -9 9 -8 21 4 21
21 22 74 2 82 -10 4 -13 14 -9 35 5 24 2 30 -15 36 -14 4 -21 15 -21 31 0 18
-5 24 -21 24 -14 0 -19 5 -17 17 5 25 29 28 35 4 3 -12 9 -21 14 -21 5 0 9 -6
9 -13 0 -8 15 -13 40 -14 34 -2 40 1 40 18 0 10 -7 19 -15 19 -8 0 -15 7 -15
16 0 11 5 14 16 10 9 -3 20 -6 25 -6 5 0 9 -11 9 -25 0 -14 5 -25 10 -25 6 0
10 5 10 10 0 23 19 8 25 -20 5 -23 12 -30 31 -30 17 0 24 6 24 19 0 11 -6 22
-12 25 -10 5 -10 7 0 12 18 8 15 54 -3 54 -11 0 -15 12 -15 44 0 26 -5 46 -12
49 -10 4 -10 6 -1 6 6 1 15 -3 18 -9 3 -5 13 -10 22 -10 11 0 14 7 11 25 -4
21 -2 24 16 20 23 -6 41 3 47 22 6 17 25 3 33 -24 6 -17 3 -23 -8 -23 -9 0
-16 -4 -16 -10 0 -5 9 -10 20 -10 11 0 20 -7 20 -15 0 -8 5 -15 10 -15 6 0 10
-4 10 -10 0 -16 -27 -12 -34 5 -3 8 -12 15 -21 15 -8 0 -15 7 -15 15 0 8 -7
15 -16 15 -13 0 -15 -7 -10 -31 3 -17 6 -42 6 -55 0 -18 6 -24 21 -24 11 0 27
-6 34 -12 47 -43 65 -49 65 -24 0 9 7 12 20 9 15 -4 20 0 21 13 1 17 2 17 6 2
3 -11 14 -18 29 -18 19 0 24 5 24 25 0 14 -4 25 -10 25 -5 0 -10 5 -10 11 0
14 25 39 39 39 6 0 11 8 12 18 0 10 3 12 6 5 2 -7 18 -13 34 -13 19 0 29 -5
29 -15 0 -19 37 -20 56 -1 16 17 19 46 4 46 -5 0 -10 4 -10 10 0 5 7 7 15 4 8
-4 15 -15 15 -25 0 -15 8 -19 33 -20 17 0 26 -3 18 -6 -7 -3 -22 -14 -32 -24
-24 -24 -26 -94 -3 -103 27 -11 35 -7 45 24 8 22 17 30 35 30 17 0 24 6 24 20
0 11 -4 20 -9 20 -5 0 -11 9 -14 20 -3 13 0 20 9 20 18 0 18 27 0 46 -18 17
-42 18 -50 2 -3 -7 -3 -1 0 15 5 20 2 27 -10 27 -9 0 -16 7 -16 15 0 8 5 15
10 15 6 0 10 -4 10 -10 0 -5 23 -12 50 -16 49 -6 50 -7 50 -40 0 -27 4 -34 20
-34 11 0 20 -4 20 -10 0 -5 18 -10 40 -10 30 0 40 4 40 16 0 9 -9 18 -20 21
-11 3 -20 12 -20 19 0 8 -7 14 -15 14 -14 0 -20 27 -16 73 2 11 -5 17 -18 17
-12 0 -21 7 -21 15 0 8 -7 15 -15 15 -8 0 -15 9 -15 20 0 11 5 20 11 20 5 0
15 14 20 30 6 17 15 30 20 30 5 0 9 7 9 15 0 8 7 15 15 15 8 0 15 5 15 10 0 6
7 10 15 10 19 0 65 47 65 66 0 12 -29 34 -44 34 -9 0 -53 -45 -59 -60 -3 -8
-6 2 -6 23 -1 26 -5 37 -16 37 -9 0 -18 12 -21 30 -4 17 -11 30 -16 30 -5 0
-16 7 -24 15 -9 8 -22 15 -30 15 -8 0 -14 -7 -14 -15 0 -8 4 -15 9 -15 5 0 12
-16 15 -35 5 -25 13 -36 29 -41 13 -3 28 -18 34 -35 10 -26 9 -29 -8 -29 -10
0 -19 7 -19 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15 0 8 -4 15 -9 15 -18 0 -33
-31 -29 -59 2 -17 1 -28 -3 -26 -4 3 -28 2 -53 -1 -42 -6 -46 -9 -46 -35 0
-25 4 -29 28 -29 31 0 62 -30 62 -60 0 -17 7 -20 40 -20 36 0 40 -2 40 -25 0
-26 -1 -26 -70 -30 -19 0 -53 -5 -74 -9 -34 -8 -38 -7 -26 6 8 8 24 12 37 10
20 -4 23 0 23 27 0 21 -5 31 -15 31 -8 0 -15 7 -15 16 0 22 -25 44 -49 44 -12
0 -21 6 -21 15 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 9 -7 18 -15 21 -8 4 -15
11 -15 17 0 7 4 7 12 -1 20 -20 88 -12 88 10 0 34 -60 50 -86 23 -18 -17 -24
-18 -44 -5 -8 5 -11 13 -6 17 5 4 11 2 13 -4 6 -18 23 -16 23 2 0 8 5 15 10
15 6 0 10 10 10 21 0 19 4 20 34 15 27 -5 37 -3 45 11 5 10 13 14 17 10 5 -5
2 -12 -6 -17 -23 -14 -4 -23 35 -16 l35 7 0 59 c0 47 -4 64 -20 80 -29 29 -43
25 -35 -10 6 -27 4 -30 -18 -30 -14 0 -32 -7 -41 -15 -8 -8 -19 -15 -25 -15
-6 0 -11 7 -11 15 0 9 -9 15 -25 15 -16 0 -25 -6 -25 -15 0 -9 -9 -15 -25 -15
-22 0 -25 -4 -25 -35 0 -28 -4 -35 -20 -35 -13 0 -20 -7 -20 -20 0 -11 7 -20
15 -20 8 0 15 -4 15 -10 0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 -22 10
-50 10 -49 0 -50 0 -50 -31 0 -28 -3 -31 -22 -25 -13 3 -29 6 -35 6 -7 0 -13
7 -13 15 0 10 -10 15 -30 15 -20 0 -30 5 -30 15 0 8 -7 15 -15 15 -8 0 -15 -7
-15 -15 0 -8 -4 -15 -9 -15 -5 0 -15 -2 -23 -5 -19 -7 -34 6 -42 35 -3 14 -3
19 0 13 3 -7 15 -13 26 -13 15 0 19 5 15 20 -3 12 0 20 8 20 16 0 25 22 25 59
0 28 -30 61 -55 61 -8 0 -15 9 -15 20 0 16 -7 20 -30 20 -20 0 -30 5 -30 15 0
8 5 15 10 15 6 0 10 9 10 20 0 11 -7 20 -15 20 -8 0 -15 5 -15 10 0 6 -13 10
-30 10 -16 0 -30 -4 -30 -10 0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 5 10
10 10 14 0 12 28 -2 33 -7 2 -1 2 15 0 15 -3 27 0 27 6 0 6 -5 11 -11 11 -26
0 -59 51 -59 91 0 36 14 53 22 27 2 -6 26 -17 54 -24 53 -14 123 -3 125 19 0
9 2 9 6 0 9 -23 33 -14 33 12 0 15 -6 25 -14 25 -14 0 -28 34 -18 44 14 14 42
5 42 -14 0 -11 5 -20 10 -20 6 0 10 -9 10 -20 0 -11 -4 -20 -10 -20 -16 0 -11
-60 7 -78 16 -16 16 -17 0 -20 -20 -4 -23 -28 -4 -35 6 -3 -5 -3 -25 0 -31 5
-38 2 -38 -11 0 -9 4 -16 9 -16 4 0 6 -11 3 -25 -8 -44 6 -75 33 -75 18 0 25
5 25 19 0 11 -5 21 -12 23 -8 3 -4 12 10 26 28 28 29 28 37 -8 8 -36 45 -43
45 -9 0 15 4 19 16 15 9 -3 20 -6 25 -6 5 0 9 -7 9 -15 0 -8 -7 -15 -15 -15
-10 0 -15 -10 -15 -30 0 -31 -14 -36 -72 -31 -12 2 -18 -5 -18 -18 0 -14 6
-21 19 -21 11 0 26 -7 35 -15 8 -8 18 -15 23 -15 11 0 43 -37 43 -50 0 -6 5
-10 11 -10 7 0 9 11 5 28 -3 15 -3 21 0 15 3 -7 17 -13 30 -13 19 0 24 5 24
25 0 14 -4 25 -10 25 -5 0 -10 11 -10 24 0 14 -7 32 -16 40 -14 15 -14 16 0
16 9 0 16 5 16 10 0 6 7 10 16 10 11 0 14 6 9 20 -5 14 -2 20 9 20 11 0 16 10
16 34 0 28 -4 35 -25 40 -18 5 -25 13 -25 31 0 16 6 25 15 25 8 0 15 7 15 15
0 8 9 15 21 15 16 0 19 4 14 20 -5 14 -2 20 9 20 9 0 16 -4 16 -10 0 -5 7 -10
15 -10 11 0 15 11 15 40 0 38 -2 40 -29 40 -25 0 -30 -5 -34 -27 -4 -26 -4
-26 -6 5 -1 27 -5 32 -26 32 -16 0 -25 -6 -25 -16 0 -9 -9 -18 -19 -21 -17 -4
-19 -14 -19 -81 0 -72 2 -79 29 -104 35 -33 36 -38 4 -38 -16 0 -25 6 -25 15
0 10 -11 15 -35 15 -19 0 -35 3 -35 8 0 4 -3 17 -6 30 -5 16 -2 22 10 22 9 0
16 7 16 15 0 9 -9 15 -25 15 -21 0 -25 5 -25 31 0 18 4 28 10 24 11 -7 40 18
40 34 0 6 9 11 20 11 11 0 20 4 20 9 0 21 -13 41 -26 41 -8 0 -14 10 -14 25 0
14 -6 25 -12 26 -10 0 -10 2 0 6 17 7 15 30 -3 37 -15 5 -20 42 -9 70 10 25
34 19 34 -9 0 -21 5 -25 30 -25 20 0 30 5 30 15 0 8 -4 15 -9 15 -12 0 -21 39
-10 46 5 3 12 -2 15 -11 4 -9 14 -15 23 -14 33 3 41 -2 41 -25 0 -13 3 -31 6
-40 7 -19 34 -21 35 -3 0 10 2 10 6 0 3 -8 24 -13 51 -14 26 0 41 -3 35 -6
-18 -7 -16 -78 1 -92 8 -7 30 -11 48 -9 26 2 34 8 36 27 2 13 15 34 28 46 32
30 31 55 -1 55 -16 0 -25 6 -25 15 0 8 -4 15 -10 15 -5 0 -10 -4 -10 -10 0
-24 -18 -7 -22 20 -3 20 0 30 8 30 7 0 16 15 20 33 5 21 9 26 12 15 2 -10 10
-18 18 -18z m986 -170 c-4 -30 -4 -30 42 -30 25 0 46 -4 46 -10 0 -5 14 -10
30 -10 25 0 30 4 30 25 0 14 -4 25 -10 25 -5 0 -10 7 -10 15 0 8 7 15 15 15 9
0 15 -10 15 -26 0 -14 7 -32 15 -40 13 -14 17 -14 30 0 8 8 15 26 15 40 0 36
27 33 35 -4 5 -25 11 -30 36 -30 18 0 29 -5 29 -14 0 -7 9 -16 20 -19 23 -6
27 -27 6 -27 -7 0 -16 -9 -19 -20 -3 -11 -12 -20 -21 -20 -11 0 -16 -9 -16
-30 0 -18 5 -30 13 -30 29 0 47 -39 47 -105 0 -37 -4 -65 -10 -65 -5 0 -10 -8
-11 -17 0 -11 -3 -13 -6 -5 -2 6 -11 12 -19 12 -8 0 -14 9 -14 20 0 11 7 20
15 20 28 0 16 30 -15 37 -30 6 -43 33 -15 33 8 0 15 9 15 20 0 13 -7 20 -20
20 -11 0 -20 5 -20 10 0 16 -47 12 -54 -5 -3 -8 -12 -15 -21 -15 -8 0 -15 -5
-15 -11 0 -6 -7 -4 -15 5 -8 8 -15 20 -15 26 0 6 7 18 15 26 8 9 15 26 15 40
0 13 5 24 10 24 6 0 10 11 10 25 0 21 -5 25 -30 25 -20 0 -30 -5 -30 -15 0 -8
-4 -15 -10 -15 -14 0 -13 -33 2 -48 9 -9 9 -12 -1 -12 -7 0 -15 9 -18 20 -4
14 -14 20 -34 20 -16 0 -29 5 -29 10 0 22 -40 9 -46 -15 -7 -27 -24 -34 -24
-10 0 8 5 15 10 15 6 0 10 14 10 30 0 20 -5 30 -15 30 -8 0 -15 7 -15 15 0 8
-4 15 -10 15 -5 0 -10 7 -10 15 0 15 10 19 43 16 9 -1 17 4 17 13 0 9 4 16 8
16 5 0 7 -13 4 -30z m-348 -185 c3 -8 10 -15 15 -15 13 0 91 -76 91 -89 0 -6
7 -11 15 -11 8 0 15 -5 15 -10 0 -6 -7 -13 -15 -16 -8 -4 -15 -13 -15 -21 0
-8 -7 -13 -17 -11 -12 2 -17 15 -19 48 -1 24 -7 46 -13 48 -6 2 -11 10 -11 18
0 8 -4 14 -10 14 -5 0 -10 -9 -10 -20 0 -11 -7 -20 -15 -20 -8 0 -15 -7 -15
-15 0 -8 -4 -15 -10 -15 -5 0 -10 -12 -11 -27 0 -17 -3 -22 -6 -13 -4 8 -13
21 -20 29 -8 8 -12 22 -10 32 3 10 1 34 -3 54 -7 30 -5 35 11 35 10 0 19 5 19
10 0 16 27 12 34 -5z m400 -420 c-4 -8 -10 -15 -15 -15 -5 0 -9 -9 -9 -21 0
-18 6 -20 48 -19 26 1 53 1 60 1 17 -1 15 -41 -3 -41 -9 0 -15 -9 -15 -24 0
-14 7 -31 15 -40 8 -8 15 -19 15 -25 0 -6 7 -11 15 -11 22 0 18 -20 -10 -46
-14 -13 -25 -34 -25 -48 0 -35 -10 -56 -27 -56 -12 0 -14 8 -9 39 6 36 4 39
-27 52 -51 21 -74 52 -69 93 3 22 10 36 18 36 19 0 18 29 -1 36 -16 7 -21 44
-6 44 5 0 12 14 16 30 4 17 13 30 20 30 9 0 12 -6 9 -15z m643 -306 c5 -16 11
-19 32 -14 14 3 21 2 15 -2 -6 -4 -14 -21 -18 -37 -8 -33 -28 -56 -49 -56 -8
0 -17 -9 -20 -20 -3 -11 -13 -20 -22 -20 -9 0 -13 5 -10 11 9 14 -4 79 -15 79
-13 0 -50 -37 -50 -50 0 -5 -9 -10 -20 -10 -24 0 -27 31 -5 54 8 8 15 22 15
30 0 9 7 16 14 16 18 0 66 -43 66 -59 0 -6 6 -11 14 -11 23 0 18 67 -5 73 -26
7 -24 25 4 30 43 8 49 7 54 -14z m-436 -249 c28 0 39 -4 39 -15 0 -8 7 -15 15
-15 10 0 15 -10 15 -30 0 -23 -5 -30 -19 -30 -11 0 -23 -4 -26 -10 -3 -5 -19
-7 -35 -3 -26 5 -30 2 -30 -16 0 -11 -6 -21 -14 -21 -9 0 -12 7 -9 20 3 11 0
20 -6 20 -6 0 -11 6 -11 14 0 8 -9 18 -20 21 -23 7 -60 -2 -60 -16 0 -5 -9 -9
-20 -9 -11 0 -20 7 -20 15 0 10 10 15 30 15 25 0 30 4 30 25 0 14 7 28 15 31
8 4 15 12 15 19 0 21 43 23 58 3 9 -12 27 -18 53 -18z m-2411 -202 c11 6 26 9
35 6 17 -7 21 -34 4 -34 -7 0 -9 -12 -5 -35 6 -28 4 -35 -9 -35 -9 0 -18 -7
-21 -15 -8 -21 -21 -19 -27 5 -3 11 -14 20 -24 20 -18 0 -19 -6 -16 -57 1 -32
0 -51 -4 -43 -3 8 -11 18 -17 22 -8 5 -7 8 2 8 6 0 12 5 12 11 0 6 -6 17 -12
24 -42 46 -42 47 -25 57 23 12 22 55 0 61 -10 3 -3 5 15 6 23 1 32 6 32 19 1
14 4 13 21 -7 17 -21 23 -23 39 -13z m2434 -13 c-4 -30 -3 -45 5 -45 6 0 11
11 11 24 0 13 7 26 15 30 8 3 15 12 15 21 0 8 5 15 10 15 6 0 10 -9 10 -20 0
-12 -7 -20 -16 -20 -12 0 -15 -6 -10 -25 5 -18 2 -25 -9 -25 -8 0 -15 -8 -16
-17 0 -11 -3 -13 -6 -5 -15 37 -78 -15 -68 -56 6 -23 5 -23 -11 -7 -12 12 -27
15 -51 12 -25 -5 -33 -2 -33 10 0 9 -6 13 -14 10 -8 -3 -17 1 -20 9 -11 28 -6
44 12 43 10 0 12 -3 5 -6 -18 -7 -16 -33 2 -33 8 0 15 7 15 15 0 8 5 15 10 15
6 0 10 8 11 18 0 10 3 12 6 5 7 -18 43 -16 43 1 0 8 -5 16 -11 18 -6 2 -9 13
-6 23 5 19 5 19 12 -2 8 -24 55 -34 55 -11 0 10 29 46 38 47 2 1 0 -19 -4 -44z
m-2256 -325 l33 0 -7 -60 c-6 -52 -4 -60 10 -60 9 0 16 5 16 12 0 23 13 -2 15
-29 3 -49 5 -53 20 -53 8 0 15 -6 15 -14 0 -8 6 -17 13 -20 6 -3 -1 -3 -18 0
-33 6 -45 19 -45 48 0 15 -5 17 -26 12 -20 -5 -27 -3 -31 14 -5 20 -28 28 -35
11 -1 -5 -18 -12 -37 -16 l-34 -7 7 36 c6 29 4 36 -9 36 -8 0 -15 7 -15 15 0
8 -4 15 -10 15 -13 0 -13 -96 0 -121 7 -13 7 -19 0 -19 -5 0 -10 -13 -10 -30
0 -26 -3 -30 -27 -29 -16 0 -22 3 -15 6 14 5 17 33 3 33 -40 0 -60 -61 -25
-74 25 -10 84 4 84 20 0 8 5 14 10 14 6 0 10 7 10 15 0 10 10 15 28 15 42 0
72 11 72 26 0 22 28 5 34 -22 4 -15 14 -24 26 -24 22 0 27 -16 8 -23 -10 -3
-11 -13 -2 -45 12 -45 10 -52 -18 -52 -15 0 -18 -8 -18 -45 0 -33 4 -45 15
-45 10 0 15 10 15 29 0 17 5 33 10 36 6 4 10 -8 10 -29 0 -20 4 -36 9 -36 5 0
12 -16 15 -35 4 -19 11 -35 16 -35 6 0 10 -4 10 -10 0 -5 -7 -10 -15 -10 -8 0
-24 -9 -35 -20 -24 -24 -36 -25 -44 -4 -11 28 -6 54 9 54 19 0 19 17 1 36 -18
17 -36 18 -37 2 0 -10 -2 -10 -6 0 -7 17 -33 15 -33 -3 0 -8 7 -15 15 -15 10
0 15 -10 15 -29 0 -16 -6 -31 -15 -35 -8 -3 -15 -12 -15 -21 0 -8 7 -15 15
-15 21 0 19 -17 -2 -22 -34 -9 -43 2 -43 53 0 37 -5 53 -20 66 -11 10 -20 23
-20 30 0 7 -4 13 -8 13 -4 0 -12 15 -17 33 -9 28 -14 32 -42 29 -24 -1 -33 2
-33 13 0 8 -10 16 -22 16 l-23 2 23 4 c21 4 33 43 13 43 -5 0 -12 14 -16 30
-5 25 -12 30 -35 30 -26 0 -29 -4 -36 -45 -6 -41 -9 -45 -34 -45 -29 0 -40 15
-40 56 0 18 5 24 23 24 31 0 44 11 52 43 4 15 11 27 16 27 5 0 9 9 9 19 0 11
5 23 11 27 17 10 30 -17 23 -48 -6 -31 6 -36 30 -13 8 8 19 15 25 15 18 0 22
20 16 74 -7 54 -7 54 -51 66 -25 7 -44 19 -44 26 0 19 19 18 26 -1 7 -18 114
-22 114 -4 0 25 43 50 75 44 11 -3 35 -5 53 -5z m1822 -85 c0 -9 9 -15 25 -15
14 0 25 -4 25 -10 0 -15 19 -12 36 5 33 32 54 9 54 -58 0 -21 -6 -30 -20 -34
-14 -4 -20 -14 -20 -34 0 -16 -4 -29 -10 -29 -5 0 -10 -7 -10 -15 0 -8 -8 -15
-17 -15 -10 0 -28 -3 -40 -6 -18 -5 -21 -3 -16 15 4 16 1 21 -16 21 -12 0 -21
6 -21 14 0 8 -9 18 -20 21 -14 4 -20 15 -20 35 0 15 -7 30 -15 34 -19 7 -20
36 -2 36 8 0 20 11 27 25 15 28 60 35 60 10z m-66 -181 c3 -9 6 -27 6 -40 0
-20 5 -24 30 -24 20 0 30 -5 30 -15 0 -8 -7 -15 -16 -15 -12 0 -14 -7 -9 -30
5 -22 3 -32 -9 -36 -24 -9 -19 -33 9 -40 14 -3 25 -13 25 -23 0 -9 3 -25 6
-37 5 -18 -1 -23 -35 -32 -37 -9 -41 -8 -41 9 0 10 -7 19 -15 19 -8 0 -15 9
-15 19 0 11 -6 22 -12 25 -9 3 -8 8 5 16 9 6 17 17 17 25 0 16 -51 29 -80 20
-14 -5 -20 -2 -20 9 0 9 -7 16 -15 16 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0
15 -4 15 -10 0 -5 9 -10 20 -10 13 0 20 7 20 20 0 11 5 20 10 20 6 0 10 7 10
15 0 21 -30 19 -31 -2 0 -14 -2 -15 -6 -3 -3 8 -11 19 -17 23 -8 6 -7 10 2 14
6 2 12 11 12 18 0 28 94 46 104 19z m476 -35 c0 -28 2 -29 45 -29 34 0 45 -4
45 -15 0 -9 7 -18 15 -21 25 -10 17 -34 -10 -34 -14 0 -25 -4 -25 -10 0 -5 -7
-10 -15 -10 -18 0 -19 -12 -3 -28 9 -9 8 -12 -5 -12 -9 0 -17 -7 -17 -15 0 -8
-7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 16 15 9 0 14 8 13 18 -3 32 1 42
16 42 8 0 15 8 15 18 0 16 -8 18 -57 17 -84 -2 -83 -2 -83 -35 0 -21 -5 -30
-16 -30 -13 0 -15 -6 -9 -27 3 -16 10 -29 15 -31 12 -4 -21 -42 -37 -42 -7 0
-13 -7 -13 -15 0 -8 -4 -15 -9 -15 -5 0 -11 -9 -14 -20 -3 -12 -14 -20 -26
-20 -12 0 -21 -4 -21 -10 0 -5 -4 -10 -10 -10 -5 0 -10 7 -10 15 0 9 9 15 23
15 31 0 47 36 40 89 -4 32 -2 42 11 47 17 7 22 34 6 34 -5 0 -10 7 -10 15 0 8
-9 15 -20 15 -11 0 -20 -7 -20 -15 0 -8 -7 -15 -15 -15 -9 0 -18 -7 -21 -15
-10 -25 -37 -18 -30 8 3 12 6 32 6 45 0 18 5 22 34 22 41 0 66 8 66 22 0 22
58 67 91 70 55 5 69 -1 69 -33z m228 -397 c-4 -2 -8 -10 -8 -18 0 -8 -10 -14
-25 -14 -22 0 -25 -4 -25 -35 l0 -35 51 0 c43 0 50 -3 45 -16 -3 -9 -6 -20 -6
-25 0 -5 -7 -9 -15 -9 -8 0 -15 -9 -15 -20 0 -13 -7 -20 -20 -20 -13 0 -20 7
-20 20 0 11 -7 20 -15 20 -8 0 -15 5 -15 10 0 24 -31 8 -36 -19 -4 -20 -14
-31 -30 -35 -18 -5 -24 -14 -25 -34 0 -15 -3 -21 -6 -14 -2 6 -11 12 -19 12
-8 0 -14 6 -14 14 0 25 12 46 25 46 9 0 12 17 11 58 -1 57 -1 57 27 54 17 -2
27 2 27 10 0 7 3 23 6 36 5 17 2 22 -14 22 -11 0 -28 -7 -36 -16 -15 -14 -16
-13 -16 13 0 25 4 28 35 30 25 1 35 -3 35 -13 0 -9 11 -14 30 -14 19 0 30 5
30 14 0 23 34 24 41 1 3 -11 2 -21 -3 -23z m-432 -312 c-5 -31 -3 -42 9 -46
20 -8 20 -64 0 -64 -8 0 -15 -9 -15 -20 0 -11 7 -20 15 -20 8 0 15 -9 15 -20
0 -24 13 -26 34 -5 17 17 36 20 36 5 0 -5 -11 -12 -25 -16 -27 -7 -44 -46 -28
-67 6 -6 6 -23 2 -37 -4 -16 -7 -19 -8 -7 -1 24 -32 22 -38 -3 -3 -11 -9 -20
-13 -20 -20 0 -40 13 -40 26 0 8 -10 14 -26 14 -22 0 -25 -4 -21 -27 2 -16 2
-22 0 -15 -3 6 -9 12 -14 12 -11 0 -49 32 -49 42 0 5 7 8 15 8 8 0 15 5 15 10
0 6 -4 10 -10 10 -5 0 -10 7 -10 15 0 8 7 15 15 15 8 0 15 -7 15 -15 0 -9 9
-15 24 -15 13 0 26 -5 28 -11 8 -25 48 10 48 41 0 17 5 30 10 30 6 0 10 7 10
15 0 10 -17 16 -60 21 -33 4 -60 11 -60 15 0 5 7 9 15 9 10 0 15 10 15 30 0
17 4 30 10 30 5 0 12 11 16 25 5 19 13 25 35 25 19 0 29 5 29 15 0 8 -4 15
-10 15 -5 0 -10 5 -10 10 0 6 7 10 16 10 14 0 15 -7 10 -40z m474 -171 c0 -6
-6 -12 -14 -15 -8 -3 -17 -17 -21 -32 -4 -15 -15 -35 -26 -45 -10 -10 -19 -27
-19 -37 0 -11 -4 -20 -10 -20 -5 0 -10 -7 -10 -15 0 -9 -9 -15 -25 -15 -16 0
-25 -6 -25 -15 0 -8 -4 -15 -10 -15 -14 0 -12 -38 2 -42 17 -6 -14 -38 -36
-38 -9 0 -16 -4 -16 -9 0 -5 -6 -11 -12 -14 -10 -3 -12 8 -10 44 2 27 8 49 13
49 5 0 12 18 15 40 4 22 10 40 15 40 5 0 9 11 9 25 0 14 -6 25 -12 25 -23 0
16 20 40 20 19 0 22 -5 22 -35 0 -24 5 -35 15 -35 9 0 15 9 15 24 0 13 6 27
13 29 9 4 9 8 -2 15 -11 8 -9 14 8 31 11 11 26 21 31 21 6 0 10 9 10 19 0 14
5 18 20 14 11 -3 20 -9 20 -14z m-1997 -199 c19 -22 23 -50 7 -50 -5 0 -10 -9
-10 -19 0 -14 -5 -18 -20 -14 -18 5 -20 1 -17 -46 2 -28 6 -54 10 -57 3 -4 20
-1 37 6 17 7 33 10 37 6 3 -3 -1 -6 -10 -6 -12 0 -17 -9 -17 -30 0 -20 -5 -30
-15 -30 -8 0 -23 -9 -32 -20 -11 -12 -30 -20 -50 -20 -30 0 -33 -3 -33 -29 0
-16 -6 -31 -12 -34 -25 -8 13 -37 48 -37 27 0 34 4 34 20 0 11 5 20 11 20 15
0 59 -42 59 -57 0 -7 5 -13 10 -13 6 0 10 -7 10 -15 0 -8 -7 -15 -15 -15 -8 0
-15 -5 -15 -11 0 -6 -13 -22 -30 -36 -16 -13 -30 -31 -30 -38 0 -9 -17 -16
-44 -20 -35 -6 -46 -12 -49 -29 -4 -20 -4 -20 -6 2 0 12 6 25 14 28 8 4 15 12
15 20 0 8 -7 14 -15 14 -8 0 -15 5 -15 10 0 6 -7 10 -15 10 -8 0 -15 -4 -15
-10 0 -5 -9 -10 -20 -10 -16 0 -20 -7 -20 -35 0 -49 -14 -44 -28 10 -15 55
-15 85 -2 85 6 0 10 7 10 15 0 8 5 15 10 15 6 0 10 7 10 15 0 8 -4 15 -10 15
-5 0 -10 11 -10 25 0 16 6 25 15 25 16 0 18 12 9 59 -5 21 -12 31 -25 31 -27
0 -24 28 4 32 20 3 22 8 22 59 0 41 -5 61 -19 75 -14 13 -21 15 -24 6 -4 -13
-62 -17 -62 -4 0 4 5 14 11 22 8 11 12 12 16 3 2 -7 11 -13 18 -13 7 0 15 6
18 13 3 9 8 8 22 -3 14 -11 19 -12 22 -2 8 21 21 13 28 -17 5 -22 13 -30 36
-35 17 -3 37 -13 45 -21 29 -29 41 -17 48 48 13 112 18 122 49 87z m-603 -209
c0 -9 8 -11 28 -7 15 3 21 3 15 0 -25 -11 -14 -34 17 -34 20 0 30 -5 30 -15 0
-7 6 -18 14 -22 7 -4 17 -18 20 -30 6 -19 4 -23 -13 -23 -12 0 -21 -7 -21 -15
0 -8 -4 -15 -10 -15 -5 0 -10 -9 -10 -20 0 -11 6 -20 13 -20 7 0 13 -15 14
-34 1 -29 -3 -35 -23 -40 -13 -4 -24 -11 -24 -16 0 -6 -7 -10 -15 -10 -8 0
-15 -9 -15 -20 0 -25 -16 -26 -22 -2 -7 24 1 42 18 42 8 0 14 10 14 25 0 22
-4 25 -35 25 -31 0 -35 3 -35 25 0 16 -6 25 -15 25 -17 0 -21 46 -5 52 5 2 11
39 12 83 3 80 3 81 25 70 13 -5 23 -16 23 -24z m-674 -336 c3 -19 10 -35 15
-35 5 0 9 -7 9 -15 0 -8 -6 -15 -14 -15 -8 0 -22 -7 -30 -16 -21 -20 -20 -124
0 -124 9 0 14 -13 14 -37 0 -75 16 -97 59 -86 15 4 21 13 21 34 0 19 5 29 15
29 10 0 15 -10 15 -34 0 -35 13 -46 51 -46 10 0 19 -7 19 -15 0 -8 5 -15 10
-15 6 0 10 -13 10 -30 0 -28 -3 -30 -35 -30 -33 0 -35 -2 -35 -35 0 -40 -20
-47 -47 -15 -10 11 -23 20 -30 20 -25 0 -12 18 17 24 37 7 41 36 6 36 -13 0
-26 -5 -28 -12 -3 -8 -22 -11 -56 -9 -67 3 -95 -12 -88 -47 3 -15 1 -34 -4
-42 -9 -13 -11 -13 -20 0 -15 23 -12 70 5 76 9 4 15 19 15 35 0 22 5 29 20 29
19 0 20 4 14 51 -5 37 -3 53 6 59 17 11 -8 100 -29 100 -11 0 -13 -11 -8 -50
5 -39 3 -50 -8 -50 -8 0 -19 -8 -25 -17 -9 -17 -11 -17 -21 1 -6 10 -20 25
-30 34 -23 20 -26 72 -4 72 20 0 19 20 -1 35 -15 10 -15 13 2 30 17 17 19 17
22 2 6 -28 32 -19 39 13 4 23 11 30 29 30 20 0 24 5 24 29 0 17 4 32 9 35 5 4
7 -2 4 -13 -6 -23 10 -34 37 -26 23 7 27 45 5 45 -16 0 -20 12 -8 23 14 14 22
6 29 -28z m2419 -45 c8 0 18 7 23 16 7 12 11 13 17 4 4 -6 2 -17 -4 -23 -19
-19 -12 -29 14 -23 18 5 25 2 25 -9 0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15
0 -9 -7 -18 -15 -21 -29 -12 -13 -51 28 -67 26 -10 21 -22 -17 -37 -33 -13
-50 -41 -43 -72 2 -7 2 -10 0 -5 -3 4 -16 7 -29 7 -20 0 -24 -5 -24 -30 0 -30
24 -43 32 -18 4 11 75 14 85 4 3 -2 7 -23 10 -45 5 -33 10 -41 27 -41 30 0 66
-11 66 -20 0 -4 -32 -10 -71 -12 -47 -2 -73 -8 -79 -18 -7 -11 -10 -6 -10 18
0 25 -5 35 -20 39 -11 3 -20 9 -20 14 0 15 -47 10 -53 -5 -3 -8 -3 -24 0 -37
4 -15 3 -20 -5 -15 -7 4 -12 16 -12 27 0 14 -7 19 -25 19 -35 0 -36 47 -1 56
19 5 25 14 30 53 6 48 32 91 55 91 6 0 11 7 11 15 0 8 -4 15 -10 15 -5 0 -10
20 -10 45 0 34 4 45 15 45 13 0 15 10 12 48 -3 26 -3 39 0 30 2 -10 11 -18 18
-18z m-1155 -111 c0 -5 -4 -9 -10 -9 -14 0 -13 -43 2 -58 9 -9 5 -12 -20 -12
-37 0 -45 -26 -9 -33 19 -4 18 -4 -6 -6 l-28 -1 3 -70 c1 -38 0 -71 -3 -74 -2
-2 -15 -6 -29 -9 -14 -2 -19 -2 -12 0 6 3 12 18 12 34 0 26 -4 29 -30 29 -16
0 -30 5 -30 10 0 6 5 10 10 10 6 0 10 9 10 21 0 12 8 23 20 26 22 6 36 58 26
98 -6 23 -3 25 24 25 23 0 30 5 30 19 0 14 5 18 20 14 11 -3 20 -9 20 -14z
m734 -24 c-4 -8 -10 -15 -15 -15 -15 0 -10 -22 9 -36 16 -13 16 -13 0 -14 -10
0 -18 -4 -18 -10 0 -5 -9 -10 -20 -10 -14 0 -20 -7 -21 -22 l-2 -23 -4 23 c-5
23 -11 26 -37 16 -16 -6 -22 -49 -8 -57 4 -2 1 -2 -6 0 -8 1 -20 3 -28 3 -7 0
-17 12 -20 27 -4 15 -13 33 -20 40 -15 16 -44 17 -44 2 0 -6 -7 -17 -15 -25
-8 -9 -15 -24 -15 -35 0 -10 7 -19 15 -19 8 0 15 -9 15 -20 0 -11 -4 -20 -10
-20 -5 0 -14 -15 -19 -32 l-9 -33 -1 33 c-1 18 -6 32 -13 33 -10 0 -10 2 0 6
6 2 12 11 12 19 0 8 -6 14 -12 15 -10 0 -10 2 0 6 17 7 15 33 -3 33 -8 0 -15
7 -15 15 0 8 -4 15 -10 15 -5 0 -10 9 -10 19 0 15 6 19 28 18 23 -2 28 2 30
26 4 34 28 35 36 2 6 -24 36 -36 36 -15 0 6 7 10 15 10 11 0 13 -8 9 -30 -6
-27 -4 -30 20 -30 14 0 26 4 26 9 0 5 12 12 28 16 28 7 62 37 62 54 0 5 8 12
18 14 9 3 18 5 19 6 1 0 0 -6 -3 -14z m-1534 -144 c0 -11 -4 -23 -10 -26 -5
-3 -10 -25 -10 -47 l0 -41 39 6 38 5 6 -85 c3 -47 11 -91 18 -99 15 -19 49
-18 49 1 0 9 10 15 26 15 25 0 25 -1 19 -53 -5 -42 -4 -56 9 -66 20 -17 20
-31 0 -31 -24 0 -44 -26 -44 -57 0 -15 -4 -23 -8 -17 -4 6 -17 14 -28 18 -17
5 -23 18 -28 54 -13 96 -16 102 -42 102 -13 0 -24 -6 -25 -12 0 -10 -2 -10 -6
0 -2 6 -13 12 -24 12 -21 0 -22 1 -13 24 3 9 15 16 25 16 10 0 19 5 19 12 0
19 -51 59 -83 65 -33 7 -30 29 4 26 18 -2 27 4 36 25 16 35 6 72 -18 72 -11 0
-19 7 -19 15 0 8 -7 15 -15 15 -9 0 -15 10 -15 26 0 23 3 25 29 19 20 -4 33
-1 43 9 18 21 28 20 28 -3z m760 -161 c0 -11 -4 -20 -10 -20 -5 0 -10 -20 -10
-45 0 -33 4 -45 15 -45 8 0 17 -14 21 -35 7 -40 23 -45 44 -14 40 57 44 57 52
-7 5 -37 14 -64 25 -71 14 -11 8 -13 -40 -13 -50 0 -57 2 -57 20 0 16 -7 20
-30 20 -23 0 -30 -4 -30 -20 0 -11 4 -20 10 -20 5 0 12 -22 16 -50 7 -56 34
-72 34 -20 0 28 3 30 35 30 19 0 35 -3 36 -7 4 -42 3 -63 -3 -63 -4 0 -8 -18
-8 -40 0 -22 -4 -40 -10 -40 -5 0 -10 5 -10 10 0 6 -6 10 -14 10 -15 0 -26
-21 -26 -51 0 -10 -4 -19 -10 -19 -5 0 -10 7 -10 15 0 8 -4 15 -10 15 -5 0
-10 23 -10 50 0 28 -4 50 -9 50 -5 0 -13 26 -17 57 -7 57 -21 82 -46 84 -10 0
-10 2 0 6 17 7 15 33 -3 33 -12 0 -14 7 -9 28 4 15 11 29 16 30 4 2 8 16 8 32
0 23 -5 29 -32 35 -18 4 -27 9 -20 12 6 2 12 15 12 28 0 21 6 24 43 28 23 2
45 4 50 5 4 1 7 -7 7 -18z m417 6 c4 -7 15 -16 23 -19 12 -4 12 -6 -2 -6 -21
-1 -31 -31 -22 -65 4 -19 13 -26 30 -26 20 0 24 5 24 29 0 24 3 28 20 24 24
-6 28 -53 5 -53 -8 0 -15 -8 -16 -17 0 -11 -3 -13 -6 -5 -2 6 -15 12 -28 12
-20 0 -25 -7 -31 -35 -5 -31 -10 -35 -36 -35 -39 0 -65 -25 -47 -46 9 -11 9
-18 1 -26 -9 -9 -12 -9 -12 0 0 7 -15 12 -37 12 -21 0 -44 4 -52 9 -21 14 22
51 60 51 30 0 31 1 27 40 -2 22 0 40 4 40 22 0 52 47 54 84 1 23 2 42 3 44 4
8 31 0 38 -12z m2663 -35 c3 -4 18 -11 34 -15 39 -10 24 -21 -23 -17 -26 1
-40 -2 -44 -12 -8 -21 4 -137 14 -137 5 0 9 -18 9 -41 0 -33 3 -40 17 -37 9 2
20 14 24 28 7 24 7 24 8 3 1 -13 7 -23 14 -24 10 0 10 -2 0 -6 -18 -7 -16 -33
2 -33 12 0 14 -7 9 -27 -4 -16 -11 -29 -16 -31 -4 -2 -8 -11 -8 -20 0 -14 -2
-15 -9 -2 -6 8 -7 18 -4 23 9 15 -7 57 -22 57 -8 0 -15 -4 -15 -10 0 -5 -7
-10 -15 -10 -8 0 -15 -4 -15 -10 0 -5 -6 -10 -14 -10 -9 0 -12 7 -9 19 6 24
-13 61 -32 61 -9 0 -15 9 -15 21 0 18 -4 20 -22 14 -13 -3 -33 -9 -45 -12 -16
-4 -23 -13 -23 -30 0 -16 -7 -27 -20 -30 -11 -3 -20 -9 -20 -13 0 -4 -9 -10
-20 -13 -12 -3 -20 -14 -20 -26 0 -14 6 -21 20 -21 11 0 20 -4 20 -10 0 -5 -4
-10 -8 -10 -5 0 -14 -16 -22 -35 -11 -28 -19 -35 -42 -35 -21 0 -28 5 -28 19
0 14 5 18 20 14 14 -4 20 0 20 11 0 9 -8 17 -17 17 -17 1 -17 2 0 6 11 3 17
14 17 34 0 16 5 29 10 29 21 0 9 28 -15 34 -24 6 -36 36 -15 36 6 0 10 9 10
20 0 11 -4 20 -10 20 -22 0 -8 19 19 25 16 3 32 13 35 22 4 9 13 13 22 9 33
-13 62 4 68 40 4 19 13 34 20 34 14 0 26 21 26 47 0 33 49 55 100 46 14 -3 27
-8 30 -12z m-3575 -23 c8 -26 16 -34 38 -36 15 -2 27 -9 27 -18 0 -20 37 -18
50 4 10 16 10 16 20 0 11 -18 53 -24 84 -12 17 7 22 34 6 34 -5 0 -10 5 -10
10 0 6 7 10 15 10 8 0 15 5 15 10 0 28 86 -4 94 -35 11 -39 7 -65 -9 -59 -8 4
-15 12 -15 20 0 10 -13 14 -41 14 -38 0 -41 -2 -37 -24 3 -15 0 -27 -8 -30 -8
-3 -14 -11 -14 -19 0 -21 -24 -66 -37 -72 -9 -3 -13 6 -13 29 0 36 -15 46 -72
46 -16 0 -28 5 -28 10 0 6 -7 10 -15 10 -8 0 -15 -7 -15 -15 0 -8 7 -15 15
-15 8 0 15 -6 15 -14 0 -8 7 -22 16 -30 14 -15 10 -16 -45 -16 -54 0 -61 2
-61 20 0 11 5 20 10 20 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0
8 7 15 16 15 14 0 16 8 11 42 -3 23 -12 45 -21 50 -8 5 -13 15 -10 23 9 24 19
18 29 -17z m1199 -23 c7 -28 1 -45 -15 -45 -11 0 -39 -25 -39 -34 0 -15 47 -4
60 14 18 25 70 28 70 4 0 -8 8 -23 17 -32 16 -16 16 -17 0 -20 -12 -3 -17 -14
-17 -38 0 -27 -4 -34 -20 -34 -11 0 -20 -4 -20 -10 0 -15 -60 -12 -76 4 -16
17 -19 56 -4 56 6 0 10 7 10 15 0 9 -9 15 -25 15 -34 0 -33 4 13 55 21 23 14
32 -33 39 -73 11 -56 34 26 35 42 1 47 -2 53 -24z m-2040 -77 c6 -4 11 -32 10
-68 l-1 -60 33 0 c19 0 34 5 34 10 0 6 7 10 15 10 8 0 15 5 15 10 0 6 9 10 20
10 17 0 20 -7 20 -40 0 -29 -4 -40 -15 -40 -8 0 -15 7 -15 15 0 20 -26 19 -34
-1 -11 -28 -6 -44 12 -45 10 0 12 -3 5 -6 -7 -2 -13 -11 -13 -19 0 -8 -9 -14
-20 -14 -11 0 -20 -7 -20 -15 0 -8 7 -15 15 -15 8 0 15 -4 15 -10 0 -5 -7 -10
-15 -10 -8 0 -15 -3 -15 -7 0 -12 -84 -11 -92 1 -4 6 -8 21 -8 34 0 23 9 26
63 22 21 -1 27 3 27 19 0 12 -6 21 -15 21 -8 0 -15 9 -15 20 0 11 -7 20 -15
20 -11 0 -14 7 -9 24 5 20 2 24 -21 24 -24 0 -25 -3 -20 -29 6 -25 4 -29 -15
-29 -18 0 -21 4 -17 22 10 38 -4 108 -22 109 -13 1 -13 2 2 6 9 2 17 11 17 19
0 9 11 14 35 14 29 0 35 4 36 23 1 20 2 20 6 2 3 -11 10 -23 17 -27z m499 -35
c-9 -3 -13 -27 -13 -69 0 -50 -3 -64 -15 -64 -8 0 -15 -2 -15 -4 0 -2 -3 -11
-6 -20 -4 -11 -1 -16 10 -16 18 0 17 3 21 -70 2 -30 8 -56 14 -58 14 -5 14
-32 1 -32 -5 0 -10 -7 -10 -15 0 -8 7 -15 15 -15 8 0 15 -9 15 -20 0 -28 30
-26 31 3 1 16 2 18 6 5 5 -21 29 -24 37 -5 5 10 7 10 12 0 3 -7 12 -13 20 -13
24 0 15 -29 -11 -36 -14 -3 -25 -12 -25 -20 0 -16 -16 -17 -84 -8 -29 4 -46
12 -46 20 0 8 5 14 10 14 6 0 10 7 10 15 0 8 -4 15 -9 15 -4 0 -14 27 -20 59
-11 54 -15 60 -41 66 -22 5 -27 10 -22 23 8 22 28 42 41 42 6 0 11 16 11 35 0
24 -5 35 -15 35 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -15 -10 -27 0 -16 18 15 25
30 7 43 35 15 35 -8 0 -15 7 -15 15 0 8 6 15 13 15 6 0 25 16 41 35 16 19 34
35 40 34 6 0 5 -3 -1 -6z m1717 -33 c0 -16 5 -30 10 -30 6 0 10 -9 10 -20 0
-11 -5 -20 -11 -20 -18 0 -39 -27 -39 -51 0 -19 4 -21 34 -16 30 5 33 3 29
-14 -3 -10 -11 -19 -18 -19 -13 0 -25 -30 -25 -67 0 -13 -4 -23 -10 -23 -5 0
-10 -6 -10 -13 0 -21 -38 -47 -70 -47 -23 0 -30 -4 -30 -20 0 -17 -7 -20 -41
-20 -40 0 -41 0 -34 32 11 46 31 61 81 61 30 0 44 5 44 14 0 7 5 13 10 13 6 0
10 23 10 50 0 46 -2 50 -24 50 -13 0 -27 -6 -29 -12 -4 -10 -8 -10 -15 1 -14
19 -32 5 -32 -26 0 -22 3 -24 25 -19 35 9 33 -19 -2 -31 -35 -12 -63 -6 -63
13 0 10 -12 14 -40 14 -33 0 -40 3 -40 20 0 11 -4 20 -10 20 -5 0 -10 7 -10
15 0 13 -7 15 -35 9 -39 -7 -45 4 -17 32 16 16 17 16 20 1 5 -27 32 -20 32 8
0 14 5 25 10 25 6 0 10 -7 10 -15 0 -8 9 -15 20 -15 11 0 20 -6 20 -13 0 -14
35 -47 49 -47 5 0 11 9 14 20 3 11 12 20 21 20 9 0 16 7 16 15 0 11 10 13 50
8 l50 -6 2 39 c1 21 2 51 1 67 -1 20 4 27 18 27 14 0 19 -7 19 -30z m-1033
-80 c3 -11 12 -20 19 -20 19 0 18 -19 -1 -26 -8 -4 -15 -12 -15 -20 0 -8 -7
-14 -15 -14 -8 0 -15 -7 -15 -15 0 -8 7 -15 15 -15 8 0 15 7 15 15 0 9 9 15
25 15 26 0 30 -16 25 -95 -2 -36 -8 -44 -19 -27 -4 7 -34 15 -67 18 -36 3 -62
11 -67 20 -4 8 -15 14 -22 14 -11 0 -15 -12 -15 -45 0 -51 -25 -64 -36 -20 -7
27 -34 35 -34 10 0 -9 -9 -15 -21 -15 -20 0 -21 -3 -14 -72 4 -40 10 -90 15
-110 4 -22 4 -38 -1 -38 -5 0 -9 7 -9 15 0 8 -7 15 -15 15 -8 0 -15 5 -15 10
0 6 -15 11 -32 11 -18 1 -25 3 -15 6 13 3 17 15 17 49 0 27 6 52 15 60 8 9 15
27 15 40 0 23 -3 24 -35 18 -30 -5 -34 -4 -29 10 4 10 19 20 35 23 32 6 39 30
12 40 -25 9 -63 51 -63 69 0 7 -7 14 -15 14 -17 0 -20 37 -4 60 8 11 12 12 16
3 3 -8 25 -13 58 -13 29 0 60 -3 69 -6 23 -9 20 -31 -5 -37 -18 -5 -20 -11
-15 -33 11 -44 38 -73 69 -74 15 0 27 3 27 6 0 10 -28 34 -39 34 -25 0 -9 29
19 35 21 5 30 12 30 27 0 17 4 19 30 13 28 -6 30 -5 30 24 0 21 5 31 15 31 8
0 15 -4 15 -10 0 -5 5 -10 10 -10 6 0 10 7 10 15 0 22 21 18 27 -5z m497 -58
c-4 -18 -10 -32 -15 -32 -5 0 -9 -16 -9 -35 0 -31 3 -35 25 -35 20 0 25 5 25
25 0 16 6 25 15 25 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 5 -15 10 0 14
27 12 34 -2 5 -10 7 -10 12 0 11 23 21 12 28 -27 3 -22 10 -41 16 -43 5 -1 11
-19 12 -38 3 -32 0 -35 -24 -38 -30 -4 -40 -35 -15 -46 10 -5 10 -7 0 -12 -7
-3 -13 -14 -13 -25 0 -13 7 -19 24 -19 20 0 25 6 31 40 7 41 25 56 25 21 0
-11 5 -23 10 -26 6 -3 10 -19 10 -36 0 -16 5 -29 10 -29 6 0 10 -7 10 -15 0
-8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 23 -27 29 -45 12 -17 -18 -20 -37 -5
-37 6 0 10 -7 10 -15 0 -8 7 -15 15 -15 8 0 15 -7 15 -15 0 -14 -27 -20 -72
-16 -15 2 -18 -6 -18 -43 0 -34 -4 -46 -15 -46 -13 0 -21 34 -16 64 5 26 -11
54 -34 60 -32 8 -32 24 0 32 25 6 34 29 14 36 -6 2 -11 17 -11 33 0 16 5 31
11 33 6 2 11 16 11 32 0 21 -6 31 -22 36 -35 11 -48 30 -48 68 0 53 -23 50
-65 -9 -14 -19 -14 -19 -15 6 0 20 6 28 25 33 14 4 25 13 25 21 0 8 6 15 13
15 6 0 23 14 37 29 14 16 26 28 28 27 1 -2 -1 -17 -4 -34z m-1508 -52 c8 0 14
-9 14 -20 0 -15 7 -20 25 -20 14 0 29 -8 35 -19 16 -31 12 -48 -12 -47 -14 1
-24 -6 -26 -16 -3 -14 3 -18 22 -18 19 0 26 -5 26 -20 0 -11 5 -20 10 -20 6 0
10 -15 10 -34 0 -19 -4 -38 -10 -41 -6 -4 -10 5 -10 19 0 19 -7 27 -25 32 -14
3 -32 4 -40 0 -10 -4 -15 1 -15 14 0 16 -7 20 -35 20 -24 0 -35 -5 -35 -15 0
-8 -4 -15 -10 -15 -5 0 -10 4 -10 9 0 5 -8 12 -17 14 -11 3 -7 5 10 6 16 1 27
7 27 15 0 9 7 12 19 9 15 -4 19 3 25 53 4 33 3 60 -3 63 -5 3 -6 20 -3 36 4
20 7 24 10 13 2 -10 10 -18 18 -18z m2125 0 c5 0 9 -7 9 -15 0 -8 9 -19 20
-25 11 -6 20 -17 20 -25 0 -11 12 -15 45 -15 25 0 45 -4 45 -10 0 -5 -4 -10
-10 -10 -5 0 -11 -12 -11 -27 l-2 -28 -4 28 c-5 33 -39 37 -73 7 -13 -11 -35
-20 -50 -20 -22 0 -29 -6 -36 -32 -5 -17 -15 -33 -22 -36 -17 -5 -15 25 2 31
14 6 17 34 19 149 0 35 4 48 16 50 9 2 17 -2 19 -9 2 -7 8 -13 13 -13z m279
10 c0 -11 -4 -20 -10 -20 -5 0 -10 -9 -10 -20 0 -13 -7 -20 -20 -20 -17 0 -20
-7 -20 -45 0 -32 6 -52 20 -67 26 -28 25 -36 -2 -40 -15 -2 -24 -11 -26 -26
-3 -20 -7 -22 -38 -16 -27 5 -34 3 -34 -10 0 -9 -7 -16 -15 -16 -8 0 -15 -7
-15 -15 0 -21 -7 -19 -35 11 -22 24 -26 25 -40 11 -18 -18 -20 -37 -4 -37 7 0
0 -11 -15 -25 -15 -14 -36 -25 -47 -25 -10 0 -19 -6 -20 -12 0 -10 -2 -10 -6
0 -2 6 -9 12 -15 12 -10 0 -38 25 -38 34 0 3 10 6 23 6 45 0 67 12 67 35 0 14
7 25 20 28 12 3 20 14 20 26 0 12 6 21 14 21 8 0 17 6 19 13 3 6 3 -3 0 -20
-4 -26 -2 -33 11 -33 9 0 16 8 16 20 0 11 7 20 15 20 8 0 15 -7 15 -15 0 -8 7
-15 15 -15 8 0 15 7 15 16 0 13 7 15 35 10 35 -7 35 -7 35 29 0 19 -4 35 -10
35 -5 0 -10 16 -10 35 0 19 5 35 10 35 6 0 10 7 10 15 0 13 -9 15 -59 9 -39
-4 -61 -3 -65 5 -4 6 -1 11 7 11 8 0 17 7 21 15 3 8 12 15 21 15 8 0 15 5 15
10 0 6 7 10 16 10 18 0 44 -23 44 -39 0 -6 6 -11 14 -11 16 0 31 34 21 50 -3
5 3 10 14 10 14 0 21 -6 21 -20z m-1770 -11 c0 -24 -22 -49 -44 -49 -9 0 -16
-7 -16 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -7 -15 -15 0 -8 9 -15 20 -15 11 0
20 7 20 15 0 8 5 15 10 15 6 0 10 -20 10 -45 0 -32 4 -45 14 -45 8 0 17 6 19
13 3 7 6 5 6 -5 1 -11 9 -18 22 -18 15 0 19 -4 15 -16 -3 -9 -6 -22 -6 -30 0
-11 -11 -13 -55 -8 -60 7 -69 -2 -36 -35 24 -24 30 -63 12 -85 -18 -22 -31
-20 -31 4 0 14 -7 20 -24 20 -13 0 -26 7 -30 15 -3 8 -15 15 -26 15 -30 0 -24
51 8 65 18 8 22 15 17 34 -3 13 -13 30 -21 38 -8 8 -12 23 -9 33 3 9 1 30 -5
45 -9 22 -8 32 4 43 12 13 20 13 45 2 35 -14 61 -8 61 15 0 20 21 44 38 45 6
0 12 -9 12 -21z m371 -156 c2 -32 -3 -47 -21 -63 -25 -22 -16 -64 11 -57 15 4
19 0 19 -18 0 -33 -10 -55 -26 -55 -14 0 -30 -62 -20 -78 3 -6 -2 -8 -14 -5
-17 5 -20 0 -20 -31 0 -43 20 -48 40 -11 17 32 30 32 30 1 0 -16 -7 -27 -20
-31 -11 -3 -20 -15 -20 -26 0 -10 -4 -19 -10 -19 -14 0 -12 -27 3 -34 10 -5
10 -7 0 -12 -15 -7 -17 -54 -3 -54 6 0 10 -20 10 -45 0 -45 0 -45 36 -45 31 0
34 -2 20 -15 -8 -8 -19 -15 -25 -15 -6 0 -11 -9 -11 -20 0 -11 7 -20 15 -20
12 0 13 -9 8 -45 -5 -37 -3 -45 10 -45 9 0 19 11 23 24 12 47 64 43 64 -5 0
-26 4 -29 30 -29 23 0 30 -4 30 -20 0 -11 -4 -20 -10 -20 -5 0 -10 5 -10 10 0
19 -32 10 -37 -10 -3 -11 -14 -20 -24 -20 -12 0 -19 7 -19 20 0 17 -7 20 -40
20 -33 0 -40 -3 -40 -19 0 -11 8 -22 18 -25 9 -3 0 -3 -20 0 -35 5 -38 3 -38
-21 0 -30 41 -75 68 -75 12 0 22 -9 25 -21 5 -18 2 -20 -19 -15 -33 9 -74 -3
-74 -21 0 -18 37 -53 57 -53 18 0 17 -49 -2 -56 -8 -4 -15 -12 -15 -19 0 -7 7
-15 15 -19 21 -8 19 -23 -5 -29 -11 -3 -20 -12 -20 -21 0 -9 -7 -16 -15 -16
-8 0 -15 9 -15 20 0 11 -7 20 -15 20 -8 0 -15 7 -15 15 0 8 5 15 10 15 15 0
12 29 -5 46 -14 15 -11 44 6 44 5 0 9 15 9 34 0 36 -18 51 -51 41 -13 -4 -19
-1 -19 9 0 32 13 46 41 46 26 0 29 3 29 35 0 19 -4 35 -10 35 -5 0 -10 6 -10
14 0 18 -39 17 -58 -1 -13 -13 -42 -7 -42 8 0 5 7 9 16 9 10 0 14 7 12 23 -2
16 -11 23 -30 25 -26 3 -42 32 -18 32 6 0 10 13 10 29 0 24 -5 30 -30 34 -40
8 -60 -1 -61 -27 0 -11 -3 -15 -6 -8 -6 14 -43 16 -43 2 0 -5 -7 -10 -15 -10
-8 0 -15 -7 -15 -15 0 -10 -10 -15 -28 -15 -26 0 -28 2 -24 33 6 39 18 49 55
41 20 -4 27 -2 27 10 0 9 8 16 20 16 11 0 23 7 26 15 7 18 31 20 37 3 5 -16
110 -14 115 2 2 5 12 10 23 10 12 0 19 7 19 20 0 11 -6 20 -14 20 -8 0 -17 15
-21 36 -3 20 -13 43 -21 51 -23 24 -16 40 20 47 48 9 66 62 24 68 -17 2 -24
11 -26 33 -2 17 -10 34 -18 38 -8 4 -14 15 -14 22 0 8 -4 15 -10 15 -5 0 -10
7 -10 15 0 8 6 15 14 15 12 0 14 13 11 60 -3 47 -1 60 11 60 11 0 14 -15 14
-60 0 -33 -4 -60 -10 -60 -5 0 -10 -13 -10 -30 0 -23 5 -31 25 -36 31 -8 75
30 75 64 0 12 5 22 10 22 6 0 10 11 10 25 0 14 -4 25 -9 25 -5 0 -12 11 -15
25 -5 20 -13 25 -36 25 -17 0 -30 2 -30 4 0 2 -3 13 -6 23 -5 17 -5 17 6 1 19
-31 40 -22 38 15 -3 29 0 32 27 35 17 2 34 10 38 18 11 19 27 18 28 -3z m1465
-119 c-9 -34 -24 -49 -59 -58 -34 -8 -35 -32 -2 -40 18 -5 25 -13 25 -31 0
-13 8 -29 18 -35 16 -11 16 -11 -1 -6 -62 20 -67 19 -67 -8 0 -21 5 -26 25
-26 35 0 31 -17 -6 -25 -30 -7 -31 -9 -26 -51 4 -38 2 -44 -14 -44 -11 0 -19
7 -19 15 0 10 -10 15 -30 15 -20 0 -30 5 -30 15 0 10 10 15 28 15 47 0 54 12
56 94 l2 76 -33 0 c-18 0 -33 5 -33 10 0 6 9 10 20 10 13 0 20 7 20 20 0 11 5
20 10 20 6 0 10 9 10 20 0 17 7 20 50 20 28 0 50 5 50 10 0 6 3 10 6 10 3 0 3
-12 0 -26z m-2386 -11 c0 -6 6 -13 13 -16 7 -2 12 -21 11 -50 -1 -39 2 -47 18
-47 10 0 18 7 18 16 0 9 6 14 13 11 6 -2 11 -16 9 -31 -2 -15 2 -34 8 -41 15
-17 4 -55 -16 -55 -8 0 -14 -7 -14 -15 0 -9 -9 -15 -21 -15 -19 0 -21 -4 -15
-32 3 -18 13 -36 21 -41 8 -4 15 -11 15 -15 0 -12 -52 -32 -82 -32 -34 0 -37
-22 -8 -47 11 -10 20 -23 20 -31 0 -7 7 -15 15 -18 19 -8 55 31 55 58 0 10 7
18 15 18 8 0 15 7 15 15 0 8 6 15 14 15 17 0 29 44 20 73 -6 19 -5 19 8 6 9
-8 19 -26 23 -39 10 -32 31 -52 52 -49 50 5 56 -11 18 -47 -27 -25 -32 -44
-13 -44 9 0 9 -3 0 -12 -18 -18 -14 -28 12 -28 13 0 26 -7 30 -15 3 -8 12 -15
21 -15 8 0 15 -7 15 -15 0 -10 10 -15 30 -15 20 0 30 -5 30 -15 0 -8 -4 -15
-10 -15 -5 0 -10 -7 -10 -15 0 -8 5 -15 12 -15 6 0 8 -3 5 -7 -8 -7 -47 31
-47 45 0 18 -29 34 -50 27 -28 -9 -25 -33 5 -41 30 -7 33 -24 5 -24 -45 0 -82
73 -71 134 5 22 -10 56 -24 56 -8 0 -15 -4 -15 -10 0 -5 -5 -10 -11 -10 -36 0
-57 -131 -24 -144 8 -3 15 -12 15 -21 0 -8 5 -15 10 -15 6 0 10 -7 10 -16 0
-12 -6 -15 -21 -11 -31 8 -79 -3 -79 -18 0 -7 -7 -16 -16 -19 -21 -8 -84 3
-84 15 0 5 -6 9 -13 9 -8 0 -11 12 -9 38 4 43 -25 86 -55 80 -14 -3 -17 -12
-15 -38 1 -19 7 -36 12 -38 6 -2 10 -16 10 -32 0 -20 6 -31 20 -35 24 -8 26
-21 4 -29 -29 -11 -44 -6 -44 14 0 13 -7 20 -20 20 -13 0 -20 -7 -20 -20 0
-11 -4 -20 -10 -20 -5 0 -10 14 -10 30 0 17 -4 30 -10 30 -5 0 -10 -11 -10
-25 0 -14 -4 -25 -10 -25 -5 0 -10 -7 -10 -15 0 -8 5 -15 10 -15 15 0 30 -44
18 -51 -6 -4 -7 -16 -3 -28 8 -25 65 -31 65 -7 0 9 7 12 20 9 16 -4 20 0 21
18 1 17 3 19 6 7 2 -10 9 -18 14 -18 5 0 9 -9 9 -20 0 -20 15 -25 44 -14 11 4
16 20 17 53 1 38 2 42 9 21 14 -43 11 -76 -10 -98 -27 -29 -25 -41 9 -48 23
-5 31 -13 36 -35 6 -25 11 -29 42 -29 35 0 35 0 29 -36 -4 -20 -4 -48 -1 -64
5 -24 12 -29 51 -35 41 -6 63 -25 29 -25 -20 0 -19 -37 2 -58 16 -16 15 -17
-5 -20 -14 -2 -22 -10 -22 -23 0 -10 -4 -19 -10 -19 -5 0 -10 -11 -10 -25 0
-21 5 -25 30 -25 28 0 30 3 30 35 0 24 5 35 15 35 43 0 77 82 40 96 -23 9 -18
24 9 24 21 0 24 -6 28 -42 6 -61 0 -78 -29 -78 -16 0 -27 -7 -30 -20 -3 -11
-9 -20 -14 -20 -5 0 -9 -9 -9 -20 0 -11 7 -20 15 -20 8 0 15 -7 15 -16 0 -25
26 -44 59 -44 28 0 31 3 31 30 0 17 -4 30 -10 30 -5 0 -10 7 -10 15 0 8 7 15
15 15 8 0 15 -7 15 -15 0 -8 7 -15 15 -15 8 0 15 -4 15 -8 0 -5 16 -15 35 -22
23 -9 34 -19 30 -28 -3 -7 -5 -25 -5 -38 0 -22 -4 -24 -50 -24 -27 0 -50 5
-50 10 0 6 -12 10 -28 10 -15 0 -35 9 -45 20 -9 11 -24 20 -32 20 -10 0 -15
10 -15 30 0 28 -3 30 -35 30 -24 0 -35 -5 -35 -15 0 -8 5 -15 10 -15 6 0 10
-13 10 -30 0 -25 -4 -30 -25 -30 -14 0 -25 5 -25 10 0 6 -9 10 -21 10 -12 0
-23 8 -26 20 -7 26 -73 30 -73 4 0 -28 -31 -49 -67 -45 -18 2 -16 31 2 31 11
0 15 12 15 50 0 36 -4 50 -14 50 -22 0 -27 27 -5 33 13 3 19 14 19 31 0 27
-24 37 -33 14 -3 -7 -6 4 -6 24 -1 20 -8 43 -16 52 -19 19 -19 36 0 36 8 0 15
6 15 13 0 6 3 21 6 32 3 12 -3 29 -16 43 -11 12 -16 22 -11 22 5 0 13 9 16 20
5 15 15 20 41 20 24 0 34 5 34 15 0 8 -9 15 -20 15 -11 0 -20 5 -20 10 0 6 -9
10 -20 10 -11 0 -20 -4 -20 -10 0 -5 -11 -12 -25 -16 -14 -3 -25 -10 -25 -15
0 -5 -7 -9 -15 -9 -8 0 -19 17 -26 40 -9 28 -19 42 -35 46 -30 8 -49 74 -20
74 8 0 16 7 20 15 9 24 -4 45 -28 45 -37 0 -66 12 -66 26 0 8 5 14 10 14 6 0
10 9 10 20 0 11 -4 20 -10 20 -5 0 -10 7 -10 15 0 8 -7 15 -15 15 -9 0 -15 9
-15 24 0 25 4 27 62 40 18 4 30 15 34 30 3 14 16 27 29 30 28 7 75 48 75 65 0
19 25 7 29 -14 5 -26 26 -45 50 -45 17 0 21 6 21 30 0 16 -7 33 -16 38 -13 7
-16 33 -20 131 -3 106 -2 120 10 108 8 -8 18 -31 21 -51 7 -33 10 -36 41 -36
19 0 34 2 34 4 0 15 -24 66 -31 66 -5 0 -9 7 -9 15 0 9 -7 18 -15 21 -8 4 -15
12 -15 20 0 8 -7 14 -15 14 -8 0 -15 9 -15 20 0 11 5 20 10 20 6 0 10 14 10
30 0 17 -4 30 -10 30 -24 0 -8 29 18 32 50 6 72 3 72 -9z m1923 -80 c7 -36 2
-43 -34 -43 -25 0 -29 -4 -29 -26 0 -15 7 -32 17 -39 10 -8 17 -28 18 -56 3
-65 4 -69 20 -69 9 0 12 -6 9 -15 -4 -8 -12 -15 -20 -15 -8 0 -14 -4 -14 -10
0 -5 4 -10 10 -10 5 0 7 -7 3 -16 -6 -16 -7 -16 -18 0 -13 17 -55 23 -55 7 0
-5 -9 -11 -19 -14 -12 -3 -21 -15 -23 -33 -3 -26 -6 -29 -35 -26 -27 2 -33 -2
-33 -18 0 -17 7 -20 38 -20 l39 0 -9 -57 c-5 -32 -8 -70 -5 -85 2 -19 -1 -28
-10 -28 -7 0 -13 8 -13 18 0 26 -12 40 -42 47 -18 5 -28 2 -31 -7 -2 -7 -2 -2
1 12 3 14 6 51 6 83 l1 57 -37 0 c-21 0 -38 5 -38 10 0 6 16 10 35 10 38 0 42
11 29 79 -5 24 -12 31 -30 31 -17 0 -25 7 -30 24 -3 14 -12 27 -20 30 -8 3
-14 15 -14 27 0 20 3 21 52 15 57 -8 88 -28 88 -57 0 -15 8 -18 48 -18 77 0
102 8 102 31 0 18 -5 20 -39 15 -34 -4 -40 -2 -45 17 -9 33 -7 36 14 36 11 0
20 7 20 15 0 9 -9 15 -21 15 -22 0 -49 22 -49 39 0 16 20 13 26 -4 12 -30 32
-15 38 30 l7 45 43 0 c40 0 44 -3 49 -27z m2016 -83 c6 0 11 -4 11 -10 0 -5
-4 -10 -10 -10 -15 0 -12 -29 4 -46 24 -23 35 -16 48 31 13 47 19 50 77 39 17
-4 31 -13 31 -20 0 -9 11 -14 30 -14 25 0 30 -4 30 -25 0 -16 6 -25 15 -25 8
0 15 8 15 17 0 9 3 14 6 10 3 -3 3 -15 -1 -26 -4 -15 -15 -21 -35 -21 -21 0
-29 -6 -34 -25 -4 -14 -13 -25 -20 -25 -8 0 -16 -8 -19 -19 -3 -13 -18 -20
-51 -25 -25 -4 -46 -12 -46 -17 0 -6 -7 -4 -15 5 -25 25 -19 46 15 46 28 0 30
3 30 35 0 35 0 35 -45 35 -40 0 -45 -3 -46 -22 -1 -15 -3 -18 -6 -7 -3 11 -21
19 -56 24 -29 4 -45 10 -37 13 8 3 19 11 23 18 4 8 21 14 37 14 20 0 30 5 30
15 0 8 -4 15 -10 15 -5 0 -10 12 -10 28 1 24 2 25 14 10 7 -10 18 -18 25 -18z
m-2619 -41 c0 -5 -8 -9 -17 -9 -30 0 -42 -14 -49 -58 -6 -38 -5 -42 15 -42 11
0 21 5 21 10 0 6 20 10 45 10 33 0 45 4 45 15 0 8 5 15 10 15 10 0 6 -114 -6
-217 -5 -40 -24 -62 -24 -28 0 24 -85 20 -110 -5 -21 -21 -27 -50 -10 -50 6 0
10 -4 10 -10 0 -5 -13 -10 -29 -10 -24 0 -30 -5 -34 -30 -7 -31 -27 -42 -27
-15 0 8 5 15 10 15 16 0 11 60 -7 78 -16 16 -16 17 0 20 9 2 17 8 17 13 0 5 8
9 18 9 10 0 25 9 35 20 22 26 23 80 2 80 -8 0 -15 -4 -15 -9 0 -5 -9 -11 -20
-14 -12 -3 -20 0 -20 7 0 7 9 22 20 34 24 25 25 32 5 32 -11 0 -15 11 -15 40
0 30 -4 40 -16 40 -31 0 -46 -29 -46 -89 0 -54 -2 -60 -24 -65 -19 -5 -24 -13
-24 -36 0 -30 14 -41 23 -17 8 22 14 3 21 -60 7 -59 6 -63 -14 -63 -11 0 -23
6 -26 14 -3 8 -19 19 -35 25 -33 11 -39 31 -9 31 37 0 23 96 -16 106 -47 12
-15 94 36 94 15 0 20 7 20 28 0 21 7 32 25 40 14 6 25 16 25 21 0 5 20 12 45
15 25 4 45 11 45 16 0 10 55 17 83 11 9 -2 17 -7 17 -12z m866 6 c4 -8 16 -15
28 -16 14 0 16 -2 6 -6 -8 -3 -18 -11 -22 -17 -5 -7 -8 -4 -8 7 0 9 -7 17 -15
17 -8 0 -15 -7 -15 -15 0 -9 -7 -18 -15 -21 -8 -4 -15 -14 -16 -23 0 -10 -2
-11 -6 -3 -7 18 -63 16 -63 -3 0 -8 9 -15 20 -15 25 0 25 -10 1 -36 -10 -11
-21 -37 -25 -57 -7 -36 -26 -52 -26 -22 0 18 -26 20 -34 3 -2 -7 -4 17 -3 53
2 57 4 65 20 61 14 -3 17 2 17 31 0 28 5 37 22 43 14 4 25 1 33 -10 17 -22 26
-20 55 14 27 34 38 37 46 15z m1390 -15 c8 0 14 -7 14 -15 0 -8 6 -15 14 -15
8 0 16 7 20 15 3 8 10 15 16 15 5 0 10 -7 10 -15 0 -8 -4 -15 -10 -15 -5 0
-10 -9 -10 -20 0 -11 5 -20 10 -20 6 0 10 -7 10 -15 0 -8 9 -15 20 -15 11 0
20 5 20 10 0 6 7 10 15 10 8 0 15 -4 15 -10 0 -5 7 -10 15 -10 21 0 19 -26 -5
-50 -23 -23 -40 -26 -40 -6 0 8 -13 17 -30 21 -16 4 -30 13 -30 21 0 8 -10 14
-25 14 -16 0 -25 6 -25 15 0 24 -51 19 -57 -5 -3 -11 -9 -20 -14 -20 -5 0 -9
-7 -9 -15 0 -8 -4 -15 -9 -15 -6 0 -5 13 1 32 9 24 8 33 0 36 -19 6 -14 30 8
37 11 3 20 13 20 21 0 8 7 14 15 14 8 0 15 6 16 13 0 10 2 10 6 0 2 -7 11 -13
19 -13z m-2126 -333 c12 -4 11 -6 -2 -6 -10 -1 -18 -8 -18 -16 0 -19 31 -55
47 -55 7 0 13 -7 13 -15 0 -8 -7 -15 -15 -15 -10 0 -15 -10 -15 -28 0 -15 -9
-35 -20 -45 -11 -9 -20 -12 -20 -7 0 15 -57 12 -64 -2 -3 -7 -3 -1 0 15 5 23
3 27 -15 27 -12 0 -21 -6 -21 -15 0 -8 -4 -15 -8 -15 -16 0 -34 -47 -27 -69 3
-12 15 -21 26 -21 13 0 19 -7 19 -21 0 -18 5 -21 35 -17 30 3 35 1 35 -17 0
-25 -10 -40 -31 -47 -12 -5 -14 0 -11 21 3 21 -2 28 -25 38 -16 7 -44 10 -66
6 -31 -5 -37 -9 -37 -31 0 -14 8 -31 18 -38 13 -10 14 -15 5 -24 -13 -14 -33
-5 -33 16 0 8 -7 14 -16 14 -8 0 -22 6 -30 14 -16 16 -19 86 -4 86 6 0 10 7
10 15 0 8 -4 15 -10 15 -5 0 -10 5 -10 10 0 6 7 10 16 10 26 0 54 40 54 77 0
33 1 34 29 28 40 -9 101 4 101 21 0 8 -8 14 -17 15 -11 0 -13 3 -5 6 6 2 12
11 12 19 0 8 6 14 14 14 8 0 18 9 21 20 3 11 13 20 21 20 8 0 14 6 14 13 0 8
3 9 8 1 4 -6 14 -14 22 -17z m708 -54 c2 -7 -3 -13 -12 -13 -9 0 -16 -7 -16
-15 0 -10 10 -15 30 -15 23 0 31 5 36 25 7 27 34 35 34 10 0 -8 5 -15 10 -15
6 0 10 -7 10 -15 0 -8 7 -15 15 -15 9 0 18 7 21 15 4 8 15 15 25 15 16 0 18
-5 13 -27 -8 -34 -3 -29 -40 -37 -22 -5 -36 -2 -47 8 -17 18 -77 22 -77 6 0
-5 -9 -10 -20 -10 -11 0 -20 -7 -20 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -7 -15
-15 0 -8 -6 -15 -14 -15 -8 0 -17 -6 -19 -12 -3 -7 -6 -1 -6 15 -1 22 -6 27
-26 27 -15 0 -25 6 -25 15 0 11 8 13 30 9 26 -6 30 -4 30 15 0 16 8 23 30 28
30 6 43 33 15 33 -8 0 -15 7 -15 16 0 19 61 16 68 -3z m692 -27 c0 -8 -11 -17
-24 -20 -27 -7 -39 -39 -30 -83 9 -45 -12 -107 -41 -123 -24 -13 -35 -40 -16
-40 15 0 23 -37 16 -70 -4 -17 -13 -30 -21 -30 -8 0 -14 -7 -14 -15 0 -8 -4
-15 -10 -15 -5 0 -11 -15 -12 -32 l-1 -33 -4 33 c-5 34 -28 44 -37 15 -4 -14
-4 -14 -3 2 1 12 11 21 25 23 12 2 22 8 22 13 0 6 7 17 15 25 21 21 19 34 -6
34 -17 0 -19 4 -13 23 3 12 11 24 15 25 13 5 11 30 -3 35 -7 2 -1 8 15 12 15
4 28 10 28 14 1 3 3 30 4 59 3 40 0 52 -11 52 -8 0 -14 7 -14 15 0 8 -7 15
-15 15 -8 0 -15 -7 -15 -15 0 -8 7 -15 15 -15 28 0 16 -51 -15 -62 -11 -4 -15
3 -17 26 -3 48 15 91 42 106 14 7 25 17 25 22 0 5 7 2 16 -6 20 -21 31 -20 51
4 20 23 33 26 33 6z m-1255 -126 c-3 -5 -3 -21 1 -35 4 -18 13 -25 30 -25 14
0 24 6 24 14 0 9 7 12 20 9 14 -4 20 -14 20 -32 0 -25 -3 -27 -29 -23 -23 3
-30 -1 -36 -17 -3 -12 -15 -21 -26 -21 -27 0 -23 -25 8 -50 29 -23 71 -20 86
6 4 8 15 14 22 14 8 0 15 5 15 11 0 17 27 39 49 39 14 0 21 6 21 20 0 11 4 20
9 20 5 0 11 10 14 21 5 17 11 20 29 15 13 -3 33 -6 46 -6 17 0 22 -6 22 -25 0
-19 -5 -25 -21 -25 -16 0 -19 -4 -14 -21 5 -15 2 -22 -14 -26 -12 -3 -21 -12
-21 -19 0 -8 -6 -14 -14 -14 -9 0 -12 7 -9 20 4 16 0 20 -22 20 -23 0 -26 -3
-21 -22 3 -13 6 -29 6 -35 0 -7 7 -13 15 -13 9 0 18 -7 21 -15 4 -8 10 -15 15
-15 17 0 49 34 49 52 0 13 8 18 30 18 20 0 30 -5 30 -15 0 -8 -6 -15 -14 -15
-11 0 -13 -9 -9 -37 3 -21 10 -39 15 -41 4 -2 8 -12 8 -23 0 -10 7 -19 15 -19
8 0 15 -7 15 -15 0 -11 7 -14 25 -9 18 4 25 2 25 -9 0 -8 -11 -18 -25 -21 -20
-5 -25 -13 -25 -36 0 -23 5 -30 19 -30 10 0 21 9 24 20 3 11 12 20 21 20 9 0
16 5 16 10 0 6 7 10 15 10 8 0 15 -4 15 -10 0 -5 8 -10 18 -10 11 0 13 -3 5
-8 -6 -4 -13 -21 -16 -37 l-4 -30 -2 33 c0 21 -6 32 -16 32 -8 0 -15 -7 -15
-15 0 -9 -6 -18 -14 -21 -8 -3 -18 -18 -21 -34 -3 -16 -13 -31 -21 -34 -22 -9
-18 -39 5 -33 14 3 18 1 14 -9 -6 -16 -33 -19 -33 -4 0 13 -67 13 -72 1 -1 -5
-16 -11 -33 -13 -27 -3 -30 0 -33 30 -3 26 -8 32 -27 32 -13 0 -27 -5 -30 -10
-10 -16 5 -50 21 -50 8 0 14 -7 14 -15 0 -8 -6 -15 -14 -15 -13 0 -26 -20 -26
-42 0 -5 12 -7 30 -3 23 5 30 3 30 -8 0 -9 3 -22 6 -31 4 -11 1 -16 -11 -16
-9 0 -19 -9 -22 -20 -5 -20 -43 -29 -43 -10 0 15 -50 12 -51 -2 0 -10 -2 -10
-6 0 -2 6 -13 12 -24 12 -14 0 -19 -7 -19 -25 0 -22 4 -24 58 -27 50 -3 57 -6
60 -25 3 -19 10 -23 43 -23 32 0 39 3 39 20 0 11 -4 20 -10 20 -5 0 -10 9 -10
20 0 11 4 20 9 20 5 0 14 14 21 30 7 17 16 30 21 30 5 0 9 7 9 15 0 8 -4 15
-10 15 -5 0 -10 7 -10 15 0 8 6 15 13 15 6 0 29 3 50 6 24 5 37 3 37 -5 0 -14
36 -51 50 -51 6 0 10 11 10 25 0 14 5 25 10 25 6 0 10 9 10 19 0 30 11 51 26
51 8 0 14 7 14 15 0 8 -9 15 -20 15 -18 0 -19 2 -8 20 7 11 22 20 34 20 16 0
23 8 28 30 5 25 10 30 36 30 26 0 30 -4 30 -26 0 -14 -7 -32 -15 -40 -8 -9
-15 -20 -15 -25 0 -5 7 -9 15 -9 8 0 15 -4 15 -9 0 -5 -7 -11 -15 -15 -8 -3
-15 -12 -15 -21 0 -8 -7 -15 -15 -15 -8 0 -15 -4 -15 -10 0 -5 -9 -10 -20 -10
-21 0 -29 -33 -10 -45 15 -9 60 6 60 21 0 8 7 14 15 14 9 0 15 -9 15 -22 0
-13 3 -34 5 -48 3 -14 3 -19 -1 -12 -10 20 -44 15 -44 -8 0 -18 6 -20 54 -19
l54 2 -4 36 c-2 20 -2 30 2 24 6 -15 34 -18 34 -4 0 5 15 11 34 13 27 3 33 1
28 -12 -3 -8 -12 -21 -19 -28 -11 -11 -13 -11 -13 2 0 9 -7 16 -15 16 -9 0
-15 -9 -15 -25 0 -16 -6 -25 -15 -25 -16 0 -19 -9 -9 -34 4 -10 18 -16 35 -16
19 0 29 -5 29 -15 0 -11 -11 -15 -40 -15 -37 0 -40 2 -40 29 l0 29 -65 -2
c-65 -1 -89 -17 -44 -29 15 -4 20 -11 15 -22 -3 -9 -6 -25 -6 -36 0 -10 -7
-19 -15 -19 -10 0 -15 11 -15 35 0 24 -5 35 -15 35 -8 0 -15 -4 -15 -10 0 -5
-4 -10 -9 -10 -5 0 -11 18 -15 40 -5 31 -11 40 -26 40 -11 0 -20 -4 -20 -10 0
-5 -16 -10 -35 -10 -19 0 -35 5 -35 10 0 6 -20 10 -45 10 -33 0 -45 -4 -45
-15 0 -10 10 -15 29 -15 25 0 31 -5 36 -30 4 -16 10 -30 15 -30 4 0 10 -9 13
-20 3 -12 14 -20 26 -20 12 0 21 -6 21 -15 0 -10 -10 -15 -34 -15 -29 0 -34
-4 -39 -30 -4 -16 -11 -30 -17 -30 -5 0 -10 14 -10 30 0 20 -5 30 -15 30 -8 0
-15 7 -15 15 0 8 -4 15 -10 15 -5 0 -10 -11 -10 -25 0 -16 -6 -25 -16 -25 -11
0 -14 -5 -10 -16 3 -9 6 -27 6 -40 0 -28 30 -34 31 -6 0 10 3 12 6 5 2 -7 11
-13 19 -13 8 0 14 -7 14 -15 0 -9 9 -15 25 -15 19 0 26 7 34 33 5 17 10 25 10
16 2 -29 91 -42 91 -13 0 14 2 14 15 0 21 -21 19 -54 -5 -68 -11 -7 -20 -19
-20 -26 0 -7 -13 -15 -30 -18 -16 -4 -30 -10 -30 -15 0 -5 -16 -9 -35 -9 -31
0 -35 3 -35 25 0 29 -14 32 -39 8 -22 -22 -26 -83 -6 -83 8 0 17 -11 21 -25 3
-14 12 -25 20 -25 8 0 14 10 14 25 0 31 7 31 34 1 11 -13 35 -27 52 -31 30 -6
32 -4 42 32 5 21 10 30 11 21 1 -10 7 -18 14 -18 6 0 12 -10 12 -22 0 -18 -6
-23 -29 -24 -34 -1 -46 -18 -46 -66 0 -29 -4 -38 -17 -38 -9 0 -25 -7 -35 -17
-17 -15 -18 -15 -18 0 0 10 -7 17 -15 17 -17 0 -18 -4 -9 -37 4 -13 13 -23 20
-23 8 0 14 -4 14 -10 0 -5 8 -10 18 -10 11 0 13 -3 5 -8 -6 -4 -14 -19 -18
-34 -5 -19 -2 -29 12 -37 16 -9 16 -12 3 -21 -8 -5 -23 -10 -32 -10 -10 0 -18
-7 -18 -15 0 -8 -4 -15 -9 -15 -5 0 -22 -3 -37 -6 -31 -6 -73 23 -87 59 -3 9
-15 17 -26 17 -18 0 -20 5 -14 31 6 31 6 31 -28 25 -19 -3 -28 -3 -21 0 15 7
16 41 1 46 -6 2 -12 19 -14 38 -2 19 -3 41 -4 48 -1 6 -8 12 -16 12 -8 0 -17
11 -21 25 -3 14 -10 25 -15 25 -5 0 -9 4 -9 9 0 5 15 8 33 7 29 -1 32 2 35 32
3 27 0 32 -17 32 -25 0 -27 13 -6 34 8 8 15 27 15 42 0 24 -3 26 -30 21 -22
-5 -30 -3 -30 8 0 8 -7 15 -15 15 -8 0 -15 5 -15 10 0 6 5 10 10 10 6 0 10 7
10 15 0 8 -4 15 -10 15 -5 0 -10 5 -10 11 0 14 25 39 39 39 6 0 11 16 11 35 0
32 -3 35 -28 35 -16 0 -39 9 -52 20 -13 11 -34 23 -46 26 -12 3 -25 12 -28 20
-3 8 -13 14 -21 14 -12 0 -15 6 -9 23 8 29 16 35 21 20 3 -7 11 -13 18 -13 7
0 15 -9 18 -20 4 -17 14 -20 56 -20 50 0 51 1 51 30 0 17 5 30 10 30 6 0 10 9
10 20 0 11 5 20 11 20 6 0 9 9 6 20 -3 13 0 20 9 20 23 0 57 37 51 54 -8 22
-54 20 -66 -1 -6 -10 -11 -12 -11 -5 0 18 -50 15 -50 -3 0 -27 -28 -4 -33 28
-3 18 -8 40 -12 50 -5 13 -2 17 14 17 12 0 21 5 21 10 0 6 14 10 30 10 23 0
30 -4 30 -20 0 -23 -2 -23 39 -9 17 6 31 17 31 25 0 8 9 14 20 14 11 0 20 5
20 10 0 6 9 10 20 10 11 0 20 -4 20 -10 0 -5 7 -10 15 -10 8 0 15 -9 15 -20 0
-16 7 -20 30 -20 27 0 30 3 30 30 0 23 -4 30 -20 30 -11 0 -20 7 -20 15 0 14
-56 35 -98 35 -17 0 -24 7 -28 30 -5 23 -12 30 -30 30 -15 0 -24 -6 -24 -15 0
-8 5 -15 10 -15 6 0 2 -10 -10 -22 -11 -12 -20 -28 -20 -35 0 -7 -11 -13 -25
-13 -16 0 -25 6 -25 15 0 8 4 15 9 15 18 0 23 54 7 66 -11 7 -16 27 -16 58 0
34 -4 46 -15 46 -8 0 -15 5 -15 10 0 6 -16 10 -35 10 -19 0 -35 -4 -35 -10 0
-5 -7 -10 -15 -10 -9 0 -18 -7 -21 -15 -4 -8 -15 -15 -26 -15 -19 0 -20 5 -15
46 2 26 9 49 14 53 13 8 33 68 33 97 0 17 -6 24 -20 24 -25 0 -26 18 -2 36 20
15 126 19 117 4z m-1107 -30 c8 -17 24 -33 38 -36 27 -7 31 -28 9 -50 -13 -14
-15 -14 -15 0 0 10 -9 16 -21 16 -18 0 -20 -4 -14 -27 3 -16 8 -44 9 -62 2
-19 8 -37 14 -39 15 -5 16 -42 2 -42 -5 0 -10 -6 -10 -14 0 -7 -10 -16 -23
-18 -20 -4 -22 -1 -19 29 3 25 0 33 -12 33 -9 0 -17 -8 -17 -17 -1 -17 -2 -17
-6 0 -7 27 -43 22 -43 -7 0 -13 6 -27 13 -29 6 -3 2 -6 -10 -6 -16 -1 -23 -7
-23 -21 0 -11 -7 -20 -15 -20 -8 0 -15 -7 -15 -15 0 -8 7 -15 15 -15 8 0 15 7
15 15 0 23 27 18 33 -7 8 -28 -7 -38 -55 -38 -27 0 -38 4 -38 15 0 8 -4 15
-10 15 -5 0 -10 7 -10 15 0 18 -46 21 -54 3 -3 -7 -4 7 -1 32 3 25 10 47 15
48 15 5 12 32 -2 33 -10 0 -10 2 0 6 6 2 12 11 12 18 0 17 37 8 44 -11 3 -9
-2 -14 -14 -14 -11 0 -20 -7 -20 -15 0 -8 7 -15 15 -15 8 0 15 -7 15 -16 0
-11 8 -14 33 -12 30 3 32 6 35 46 2 23 7 42 13 42 5 0 9 -4 9 -10 0 -5 18 -10
40 -10 l40 0 0 45 c0 31 -5 47 -15 51 -19 8 -19 31 0 51 11 10 14 25 9 49 -8
44 15 47 34 4z m2512 -19 c0 -5 12 -11 28 -13 22 -2 27 -8 28 -33 0 -17 -7
-38 -18 -48 -21 -21 -23 -43 -4 -54 7 -4 16 -30 19 -58 3 -27 3 -41 0 -31 -10
37 -52 63 -54 34 0 -10 -2 -10 -6 0 -2 6 -12 12 -21 13 -11 0 -12 2 -4 6 7 3
12 23 12 49 0 29 -4 44 -12 44 -9 0 -12 15 -10 50 3 41 7 50 23 50 10 0 19 -4
19 -9z m-1977 -152 c-5 -25 -2 -29 16 -29 11 0 21 5 21 10 0 6 6 10 13 10 10
0 13 -18 13 -65 -1 -55 -4 -65 -18 -65 -10 0 -18 -4 -18 -9 0 -5 -12 -11 -27
-13 -20 -2 -29 -9 -31 -25 -3 -18 1 -23 20 -23 27 0 78 21 78 33 0 4 23 7 50
7 43 0 50 3 50 20 0 13 7 20 20 20 16 0 20 7 20 35 0 31 3 35 25 35 17 0 25 6
25 18 1 16 1 16 14 0 19 -26 36 -22 36 7 0 28 15 34 24 9 11 -30 -4 -64 -30
-64 -25 0 -33 -15 -11 -23 6 -2 8 -8 4 -13 -4 -5 -12 -2 -17 5 -13 22 -40 -11
-40 -48 0 -33 26 -44 36 -16 9 22 34 18 34 -4 0 -11 6 -22 13 -25 10 -5 10 -7
0 -12 -26 -12 -14 -34 18 -34 24 0 30 -4 27 -17 -5 -22 -48 -20 -61 2 -4 8
-15 15 -22 15 -8 0 -15 5 -15 10 0 6 -7 10 -15 10 -8 0 -15 -4 -15 -10 0 -5
-7 -10 -15 -10 -20 0 -19 -26 1 -34 9 -3 28 -6 43 -6 14 0 34 -8 42 -19 16
-18 13 -18 -92 -14 -92 3 -109 1 -109 -12 0 -8 11 -17 25 -21 14 -3 25 -10 25
-15 0 -5 11 -9 25 -9 14 0 25 5 25 10 0 6 7 10 15 10 24 0 17 -36 -13 -67 -20
-20 -28 -39 -29 -65 0 -21 3 -38 8 -38 5 0 9 -4 9 -10 0 -5 -7 -10 -15 -10
-15 0 -19 10 -15 43 1 11 -7 21 -19 24 -12 3 -21 9 -21 14 0 5 -7 9 -15 9 -8
0 -15 -9 -15 -20 0 -13 7 -20 20 -20 11 0 20 -4 20 -10 0 -5 -6 -10 -14 -10
-21 0 -30 -62 -11 -76 24 -18 19 -53 -9 -60 -14 -3 -29 -3 -35 0 -15 10 -61
-5 -61 -19 0 -7 -12 -16 -26 -19 -22 -6 -25 -11 -20 -32 9 -34 -3 -94 -19 -94
-14 0 -60 -40 -68 -61 -4 -10 -6 -10 -6 4 -1 12 -9 17 -29 17 -17 0 -37 10
-52 28 -14 15 -37 33 -52 41 -31 16 -37 31 -13 31 8 0 15 9 15 20 0 11 -7 20
-15 20 -8 0 -15 -7 -15 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -6 -15 -14 0 -8 -16
-16 -41 -20 -39 -6 -41 -8 -34 -34 3 -15 11 -28 16 -30 5 -2 9 -10 9 -18 0 -8
6 -14 14 -14 14 0 26 -36 18 -58 -9 -28 -32 -11 -32 23 0 24 -5 35 -15 35 -8
0 -15 5 -15 10 0 6 -8 11 -17 11 -17 1 -17 2 0 6 9 2 17 11 17 19 0 12 -9 14
-45 8 -41 -6 -45 -4 -45 14 0 13 9 22 24 26 13 3 27 13 30 22 7 19 -3 64 -15
64 -5 0 0 9 11 20 25 25 26 50 1 57 -31 8 -35 33 -6 33 21 0 25 5 25 30 0 17
5 30 10 30 14 0 12 27 -2 33 -7 3 -1 6 15 6 16 1 27 7 27 15 0 13 58 31 68 21
3 -3 0 -5 -6 -5 -7 0 -12 -8 -12 -18 0 -10 -9 -24 -20 -32 -21 -15 -27 -40
-10 -40 6 0 10 -12 10 -26 0 -24 1 -25 48 -19 26 4 51 13 56 20 16 22 66 -14
66 -47 0 -21 5 -28 20 -28 11 0 20 -7 20 -15 0 -19 27 -19 46 -1 18 19 18 26
-3 42 -12 8 -15 16 -8 23 14 13 65 2 65 -15 0 -8 9 -14 20 -14 17 0 20 7 20
39 l0 39 -70 -1 c-61 -2 -70 0 -70 16 0 9 -6 17 -14 17 -8 0 -18 9 -21 20 -7
21 -55 29 -55 10 0 -5 -9 -10 -20 -10 -13 0 -20 7 -20 20 0 11 -7 23 -15 26
-8 4 -15 12 -15 20 0 8 -9 14 -20 14 -11 0 -20 4 -20 9 0 5 -13 12 -30 16 -16
4 -30 13 -30 20 0 7 -3 20 -6 29 -4 11 -1 16 10 16 9 0 16 8 16 19 0 23 22 40
28 23 2 -7 12 -12 23 -12 10 0 19 -4 19 -10 0 -5 9 -10 20 -10 21 0 26 30 13
79 -6 23 -8 23 -87 18 -45 -3 -73 -3 -63 -1 9 3 17 12 17 20 0 8 7 17 15 20 8
4 15 17 15 30 0 19 -5 24 -26 24 -23 0 -24 2 -10 15 8 8 19 15 25 15 15 0 14
17 -1 23 -10 4 -9 8 2 16 22 16 57 13 64 -4 3 -8 1 -15 -4 -15 -6 0 -10 -11
-10 -25 0 -15 6 -25 14 -25 8 0 17 -11 20 -24 4 -17 16 -26 37 -31 l32 -7 -6
-64 -6 -64 34 0 c33 0 35 2 35 35 0 24 -5 35 -15 35 -10 0 -15 9 -14 28 0 15
3 21 6 15 14 -36 51 2 59 61 5 31 11 46 22 46 8 0 24 10 35 23 l19 22 -6 -22
c-6 -19 -3 -23 13 -23 18 0 21 6 21 50 0 41 -3 50 -17 50 -11 0 -14 3 -7 8 6
4 13 15 17 24 4 11 13 15 26 11 16 -4 19 -11 14 -34z m-1233 -154 c0 -8 9 -15
20 -15 11 0 20 -7 20 -15 0 -8 4 -15 9 -15 10 0 20 -77 13 -97 -2 -8 -13 -11
-28 -7 -18 5 -24 2 -24 -10 0 -9 5 -16 10 -16 6 0 10 -9 10 -20 0 -11 -4 -20
-9 -20 -5 0 -14 -22 -21 -50 -7 -30 -17 -50 -26 -50 -8 0 -14 5 -14 10 0 13
-37 50 -49 50 -5 0 -12 -6 -14 -12 -3 -8 -6 -6 -6 5 -1 12 -9 17 -31 17 -23 0
-30 4 -30 20 0 11 7 20 15 20 8 0 15 7 15 15 0 8 -4 15 -10 15 -15 0 -12 60 3
61 25 2 52 -3 65 -13 7 -6 15 -8 18 -5 10 10 -7 47 -22 47 -19 0 -18 29 1 36
27 11 17 34 -14 34 -20 0 -30 -6 -34 -20 -4 -15 -14 -20 -41 -20 -25 0 -36 -4
-36 -15 0 -8 -4 -15 -10 -15 -5 0 -10 9 -10 20 0 25 -30 27 -31 3 -1 -16 -2
-16 -6 -1 -2 9 -12 19 -21 21 -13 4 -11 5 5 6 12 0 24 7 27 14 5 10 7 10 12 0
8 -19 74 -17 74 2 0 8 -4 15 -10 15 -5 0 -10 5 -10 10 0 6 38 10 95 10 78 0
95 -3 95 -15z m1590 -225 c0 -5 -4 -10 -10 -10 -5 0 -10 -9 -10 -20 0 -11 2
-20 4 -20 2 0 11 -3 20 -6 11 -4 16 -1 16 10 0 10 9 16 23 16 12 0 31 3 42 6
17 4 20 0 21 -26 1 -19 -4 -30 -12 -30 -8 0 -14 -6 -14 -14 0 -8 -13 -17 -30
-21 -39 -9 -43 -45 -5 -45 14 0 25 -4 25 -10 0 -5 -12 -10 -26 -10 -40 0 -23
-25 20 -28 23 -2 41 -10 49 -23 6 -10 17 -19 23 -19 7 0 10 -8 7 -20 -3 -11
-9 -20 -14 -20 -13 0 -11 -28 2 -32 7 -2 5 -9 -5 -17 -14 -11 -16 -11 -16 3 0
9 -7 16 -15 16 -8 0 -15 -6 -15 -12 0 -7 -3 -22 -6 -34 -3 -13 1 -28 11 -38 9
-9 12 -16 6 -16 -6 0 -12 -8 -12 -17 -1 -17 -2 -17 -6 0 -2 9 -9 17 -14 17 -5
0 -9 6 -9 14 0 13 -28 26 -57 26 -7 0 -13 -9 -13 -20 0 -11 5 -20 10 -20 6 0
10 -7 10 -15 0 -8 9 -15 20 -15 11 0 20 -7 20 -15 0 -8 7 -15 15 -15 8 0 15
-9 15 -20 0 -11 6 -20 14 -20 9 0 19 20 27 55 12 53 29 75 29 36 0 -13 9 -20
30 -24 27 -6 44 -37 20 -37 -5 0 -10 -6 -10 -13 0 -9 -10 -12 -30 -9 -18 3
-30 0 -30 -7 0 -6 4 -11 10 -11 5 0 12 -11 16 -25 3 -14 10 -25 15 -25 5 0 9
-9 9 -20 0 -11 -7 -20 -15 -20 -8 0 -15 -7 -15 -15 0 -8 -4 -15 -9 -15 -5 0
-17 -7 -26 -17 -10 -10 -19 -12 -23 -6 -4 5 -19 13 -34 17 -31 7 -36 21 -13
30 9 3 15 0 15 -9 0 -9 10 -15 25 -15 21 0 25 5 26 33 1 17 3 24 6 15 5 -22
33 -24 33 -3 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 8 -4 15 -10 15 -5 0 -10
-4 -10 -10 0 -15 -50 -12 -51 3 -1 6 -1 20 0 30 3 24 -30 22 -36 -3 -3 -11
-11 -20 -18 -20 -7 0 -16 -6 -18 -12 -3 -8 -6 -6 -6 5 -1 9 -8 17 -16 17 -13
0 -15 -7 -9 -37 8 -42 24 -62 51 -64 17 -1 17 -2 1 -6 -13 -3 -18 -15 -18 -39
0 -27 -4 -34 -20 -34 -11 0 -20 5 -20 10 0 6 7 10 15 10 8 0 15 7 15 15 0 17
-4 18 -37 9 -22 -6 -34 -44 -14 -44 6 0 5 -14 0 -35 -6 -19 -7 -40 -4 -45 9
-15 35 -12 35 4 0 22 26 26 49 8 12 -9 32 -26 46 -37 28 -22 33 -45 10 -45 -9
0 -15 9 -15 25 0 15 -6 25 -15 25 -8 0 -17 -11 -21 -25 -6 -24 -24 -35 -24
-15 0 6 -7 10 -15 10 -8 0 -15 7 -15 15 0 8 -4 15 -9 15 -15 0 -25 -21 -14
-29 6 -3 13 -16 17 -28 4 -12 19 -26 34 -32 15 -5 21 -10 15 -10 -19 -1 -16
-51 2 -51 8 0 15 6 16 13 0 10 2 10 6 0 6 -15 63 -18 63 -3 0 6 5 10 10 10 16
0 12 -47 -5 -54 -8 -3 -15 -12 -15 -21 0 -16 -16 -21 -22 -6 -1 4 -13 12 -25
15 -17 6 -23 3 -23 -8 0 -9 -11 -18 -25 -22 -14 -3 -25 -12 -25 -19 0 -8 -6
-20 -13 -27 -17 -16 -18 -50 -3 -60 8 -6 7 -11 -3 -17 -12 -8 -12 -12 -2 -25
8 -9 20 -16 27 -16 8 0 14 -7 14 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -7 -15 -15
0 -9 7 -18 15 -21 23 -9 18 -24 -7 -24 -39 0 -68 -12 -68 -26 0 -8 -9 -14 -19
-14 -25 0 -51 -26 -51 -51 0 -26 50 -26 72 0 11 13 24 18 37 14 24 -6 28 -23
6 -23 -10 0 -15 -11 -15 -35 0 -29 4 -35 23 -36 16 -1 18 -2 5 -6 -20 -5 -25
-33 -6 -33 9 0 9 -3 0 -12 -16 -16 -15 -45 1 -51 6 -3 -3 -3 -20 0 -26 4 -33
2 -33 -11 0 -9 6 -17 13 -17 6 -1 -1 -4 -18 -8 -36 -9 -55 -4 -55 15 0 8 -4
14 -9 14 -5 0 -7 8 -4 19 2 10 -3 25 -11 34 -21 21 -20 30 3 22 24 -7 71 4 71
17 0 15 -27 38 -43 38 -26 0 -47 12 -47 26 0 17 -14 18 -41 4 -17 -10 -19 -7
-19 30 0 24 7 48 15 57 14 14 15 13 9 -11 -4 -19 -2 -26 9 -26 8 0 17 7 21 15
3 8 12 15 21 15 8 0 15 4 15 9 0 5 10 11 21 14 19 5 21 11 15 41 -6 29 -4 36
9 36 8 0 15 7 15 15 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 8 7 15 15 15 8 0
15 9 15 20 0 11 -7 20 -15 20 -18 0 -20 16 -2 24 10 5 10 7 0 12 -22 10 -14
24 12 24 18 0 25 5 25 20 0 15 7 20 25 20 29 0 36 30 8 31 -14 0 -15 2 -3 6
26 11 40 31 40 57 0 16 -6 26 -15 26 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -15 -10
-12 0 -15 14 -15 65 0 58 -2 65 -20 65 -11 0 -20 7 -20 15 0 10 -10 15 -30 15
-23 0 -30 -4 -30 -20 0 -11 -6 -20 -12 -19 -18 1 -55 17 -42 18 6 1 14 19 18
41 3 22 11 40 16 40 6 0 10 15 10 33 0 19 8 42 20 55 11 12 20 30 20 42 0 13
7 20 20 20 11 0 20 5 20 10 0 6 7 10 15 10 9 0 18 7 21 15 4 8 12 15 20 15 8
0 14 10 14 25 0 14 4 25 10 25 5 0 13 12 16 26 5 18 1 30 -13 44 -14 13 -19
27 -15 49 2 17 9 31 14 31 13 0 38 26 38 39 0 6 -7 11 -15 11 -8 0 -15 7 -15
15 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 10 -10 15 -26 15 -24 0 -26 -3 -20
-30 5 -28 3 -30 -23 -30 -25 0 -29 4 -33 35 -5 43 0 52 35 60 15 4 28 15 28
24 1 9 4 3 8 -14 4 -21 13 -31 29 -33 12 -2 22 1 22 7 0 6 7 11 15 11 27 0 16
28 -14 35 -28 6 -28 6 -25 71 3 36 10 71 17 79 14 17 37 20 37 5z m-1530 -110
c0 -11 7 -20 15 -20 8 0 15 -4 15 -9 0 -5 16 -12 35 -15 20 -4 35 -13 35 -21
0 -7 7 -21 16 -29 14 -15 14 -16 0 -16 -11 0 -16 -9 -16 -30 0 -28 3 -30 35
-30 19 0 35 5 35 10 0 6 9 10 20 10 11 0 20 7 20 15 0 8 5 15 10 15 16 0 12
37 -5 44 -8 3 -15 10 -15 16 0 33 -81 57 -93 28 -3 -7 -6 -8 -6 -3 -2 29 15
35 71 23 42 -9 60 -18 69 -35 8 -14 22 -23 36 -23 14 0 23 -6 23 -15 0 -8 -4
-15 -10 -15 -5 0 -10 5 -10 10 0 6 -7 10 -15 10 -8 0 -15 -9 -15 -20 0 -11 5
-20 10 -20 6 0 10 -13 10 -30 0 -20 5 -30 15 -30 8 0 15 -9 15 -20 0 -19 4
-20 38 -14 30 5 35 4 25 -7 -7 -7 -13 -21 -13 -31 0 -11 -7 -18 -20 -18 -11 0
-20 5 -20 10 0 16 -27 11 -34 -6 -12 -32 -6 -54 14 -54 11 0 20 -4 20 -10 0
-5 13 -10 29 -10 26 0 30 -4 33 -32 2 -28 8 -34 31 -36 31 -4 37 19 12 44 -8
9 -14 25 -14 37 1 17 2 18 6 5 5 -19 33 -25 33 -8 0 6 7 10 15 10 8 0 15 -4
15 -10 0 -15 58 -12 63 3 2 6 8 8 13 4 5 -4 3 -12 -4 -16 -17 -10 -5 -61 14
-61 8 0 14 5 14 10 0 6 7 10 15 10 8 0 15 -4 15 -10 0 -5 7 -10 15 -10 8 0 15
5 15 10 0 6 4 10 8 10 5 0 9 -13 9 -30 0 -16 -4 -30 -9 -30 -4 0 -8 7 -8 15 0
8 -7 15 -15 15 -11 0 -15 -12 -15 -51 0 -46 2 -51 20 -46 11 3 20 1 20 -4 0
-5 -7 -9 -15 -9 -11 0 -15 -12 -15 -45 0 -25 5 -45 10 -45 6 0 10 -7 10 -15 0
-8 -4 -15 -9 -15 -12 0 -21 26 -21 62 0 32 -16 53 -46 61 -16 4 -15 5 4 6 17
1 22 6 20 18 -2 14 -16 18 -63 20 -33 1 -61 7 -63 13 -8 23 -22 8 -22 -25 0
-19 5 -35 11 -35 7 0 8 -11 4 -30 -5 -23 -3 -30 9 -30 9 0 16 -7 16 -15 0 -10
-10 -15 -29 -15 -24 0 -29 5 -35 31 -3 17 -6 43 -6 56 0 23 -3 25 -35 21 -24
-3 -35 0 -35 9 0 22 -48 15 -55 -7 -4 -14 -15 -20 -36 -20 -19 0 -29 -5 -29
-15 0 -8 7 -15 15 -15 15 0 18 -9 16 -46 -1 -12 5 -28 13 -37 19 -19 20 -34 4
-40 -10 -3 -10 -7 -1 -18 11 -13 17 -40 14 -66 -1 -18 -29 -16 -47 2 -9 8 -29
15 -45 15 -19 0 -29 -5 -29 -15 0 -8 7 -15 16 -15 14 0 15 -7 10 -39 -4 -28
-2 -44 9 -54 8 -9 11 -17 6 -19 -4 -2 -11 -24 -14 -50 -5 -39 -4 -48 8 -48 8
0 15 7 15 15 0 11 12 15 50 15 43 0 50 -3 50 -19 0 -33 11 -51 31 -51 10 0 19
5 19 10 0 6 -3 10 -8 10 -4 0 -8 19 -8 41 -1 23 -6 44 -11 48 -6 3 -13 19 -17
34 -6 25 -5 27 10 15 57 -47 64 -57 64 -103 0 -29 4 -45 13 -46 10 0 10 -2 0
-6 -22 -8 -14 -33 10 -33 24 0 47 37 47 77 0 14 6 23 15 23 8 0 15 7 15 15 0
8 -9 15 -20 15 -11 0 -20 -7 -20 -15 0 -9 -6 -12 -15 -9 -8 4 -15 10 -15 15 0
5 7 9 15 9 8 0 15 5 15 10 0 7 13 8 34 5 l33 -7 2 56 c1 44 -2 56 -14 56 -9 0
-15 9 -15 25 0 18 -5 25 -20 25 -11 0 -20 -4 -20 -10 0 -5 -7 -10 -15 -10 -8
0 -15 -7 -15 -15 0 -10 11 -15 33 -16 30 -1 30 -1 7 -9 -14 -4 -37 -5 -51 -3
-15 3 -30 -1 -38 -11 -16 -20 -51 -21 -51 -2 0 7 9 16 20 19 16 4 20 14 20 45
0 32 -4 42 -20 47 -11 3 -20 12 -20 18 0 7 -3 21 -6 33 -3 13 1 28 11 38 17
16 19 16 37 -3 10 -12 25 -21 33 -21 9 0 15 -10 15 -25 0 -20 5 -25 25 -25 20
0 25 5 25 25 0 14 5 25 10 25 6 0 10 -13 10 -30 0 -20 5 -30 15 -30 8 0 15 7
15 16 0 14 2 14 15 0 8 -8 15 -28 15 -45 0 -24 4 -31 20 -31 22 0 28 26 8 32
-9 3 -8 9 5 22 16 17 17 16 17 -18 0 -22 5 -36 13 -36 6 0 19 -5 27 -10 13 -9
13 -11 0 -20 -13 -9 -13 -11 0 -21 11 -8 12 -12 3 -16 -18 -7 -16 -30 3 -37
30 -12 42 -6 49 24 8 37 -1 68 -22 72 -12 2 -7 12 18 40 74 82 73 75 22 133
-17 18 -16 25 2 25 8 0 15 7 15 15 0 8 5 15 10 15 21 0 11 23 -26 63 -8 8 -8
15 0 23 19 20 34 -4 50 -78 5 -21 12 -28 30 -28 14 0 27 6 29 13 3 7 6 5 6 -5
1 -10 5 -18 10 -18 16 0 25 -42 22 -92 -2 -39 0 -48 13 -48 12 0 16 -10 16
-40 0 -26 5 -42 17 -48 12 -7 16 -22 15 -56 -2 -33 2 -48 13 -52 9 -4 15 -19
15 -35 0 -16 5 -29 10 -29 6 0 10 -6 10 -14 0 -8 6 -17 13 -19 6 -3 2 -6 -10
-6 -13 -1 -23 -5 -23 -11 0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 -11 10
-26 10 l-25 0 25 24 c33 29 33 46 2 46 -21 0 -26 -7 -37 -50 -7 -28 -19 -50
-26 -50 -7 0 -13 -11 -13 -25 0 -16 -6 -25 -15 -25 -9 0 -15 -9 -15 -25 0 -18
5 -25 20 -25 13 0 20 7 20 20 0 13 7 20 20 20 12 0 20 -7 20 -16 0 -8 6 -22
14 -30 17 -16 46 -19 46 -4 0 6 5 10 10 10 6 0 10 -9 10 -20 0 -24 20 -61 38
-73 6 -4 12 -21 12 -37 0 -25 4 -30 25 -30 23 0 25 -3 25 -51 0 -31 -6 -56
-15 -65 -13 -13 -15 -13 -15 -1 0 20 -24 28 -60 20 -27 -5 -44 -33 -20 -33 6
0 10 -7 10 -15 0 -18 17 -20 23 -2 6 16 14 8 22 -24 5 -21 2 -27 -14 -32 -14
-3 -23 -16 -27 -36 -7 -34 -24 -42 -24 -11 0 11 5 20 10 20 6 0 10 7 10 15 0
8 -4 15 -10 15 -5 0 -10 7 -10 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15 0 8 -7
15 -15 15 -21 0 -21 95 0 104 17 6 19 106 2 106 -5 0 -15 7 -21 16 -7 8 -30
18 -53 21 -41 5 -42 6 -48 54 -10 70 1 109 30 109 19 0 24 7 30 40 4 21 4 44
1 49 -4 5 2 17 11 27 13 12 14 19 5 22 -17 5 -15 22 3 22 8 0 15 7 15 15 0 18
-30 20 -31 3 0 -10 -2 -10 -6 0 -2 6 -10 12 -16 12 -7 0 -12 15 -13 35 -1 43
-6 49 -39 41 -24 -6 -36 -36 -15 -36 6 0 10 -7 10 -15 0 -8 -4 -15 -10 -15 -5
0 -10 -16 -10 -35 0 -22 -6 -38 -15 -41 -8 -4 -15 -17 -15 -30 0 -15 6 -24 15
-24 8 0 15 -7 15 -15 0 -8 5 -15 10 -15 6 0 10 -4 10 -10 0 -5 -13 -10 -30
-10 -28 0 -30 -2 -30 -40 0 -38 -13 -55 -22 -28 -3 8 -12 5 -28 -10 -13 -12
-27 -22 -32 -22 -13 0 -9 -20 7 -36 8 -9 15 -24 15 -34 0 -10 7 -20 15 -24 8
-3 15 -12 15 -21 0 -11 11 -15 45 -15 49 0 52 -4 57 -85 3 -38 5 -40 33 -38
17 0 24 -1 18 -4 -7 -2 -13 -13 -13 -23 0 -13 -5 -17 -20 -13 -14 4 -21 17
-26 50 -6 38 -10 45 -35 50 -16 3 -29 12 -29 19 0 24 -30 15 -31 -8 -2 -22 -2
-22 -6 -2 -3 16 -14 23 -45 28 -22 4 -42 5 -43 4 -1 -2 -5 -25 -9 -51 -5 -43
-4 -50 14 -54 22 -6 25 -23 11 -51 -5 -9 -12 -25 -15 -34 -7 -23 -96 -26 -96
-4 0 8 -9 18 -20 21 -21 7 -28 25 -10 25 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 18 -10 40 0 29 -4 40 -15 40 -11 0 -15 12 -15 45 0 43 1 45 30 45 21 0
30 5 30 16 0 9 9 18 20 21 23 6 27 33 6 33 -8 0 -16 6 -18 12 -5 14 -60 -35
-71 -62 -3 -9 -6 -4 -6 13 -1 15 -5 27 -11 27 -5 0 -10 7 -10 15 0 9 -9 15
-25 15 -22 0 -25 -4 -25 -40 0 -29 -4 -40 -15 -40 -8 0 -15 7 -15 15 0 8 -4
15 -10 15 -5 0 -10 14 -10 30 0 17 5 30 10 30 6 0 10 9 10 20 0 11 -4 20 -8
20 -5 0 -15 11 -22 25 -14 26 -55 35 -62 13 -3 -7 -19 -13 -37 -14 -25 -1 -35
3 -38 17 -3 11 -16 22 -30 25 -16 5 -26 16 -30 36 -5 26 -9 29 -32 23 -14 -4
-29 -12 -33 -18 -6 -9 -8 -9 -8 1 0 6 -7 12 -15 12 -9 0 -19 8 -22 18 -4 9
-12 21 -20 25 -22 14 -14 26 22 33 19 3 35 10 35 15 0 5 7 9 15 9 8 0 15 -4
15 -10 0 -5 16 -10 35 -10 19 0 35 5 35 10 0 6 7 10 15 10 11 0 15 12 15 50 0
31 4 50 11 50 14 0 59 47 59 61 0 5 -7 9 -15 9 -10 0 -15 10 -15 30 0 20 -5
30 -15 30 -8 0 -15 9 -15 20 0 13 -7 20 -20 20 -16 0 -20 -7 -20 -35 0 -23 -5
-35 -14 -35 -18 0 -24 28 -14 68 7 25 14 32 33 32 16 0 25 6 25 15 0 10 -10
15 -30 15 -28 0 -30 3 -30 35 0 22 -6 38 -15 41 -8 4 -15 12 -15 20 0 9 -11
14 -30 14 -28 0 -44 -23 -21 -32 5 -1 12 -20 16 -41 6 -32 5 -36 -9 -31 -9 3
-16 12 -16 20 0 8 -9 14 -20 14 -12 0 -20 -7 -20 -16 0 -9 -9 -18 -20 -21 -11
-3 -20 -9 -20 -14 0 -5 -4 -9 -10 -9 -21 0 -9 20 15 26 28 7 33 29 10 38 -9 3
-15 18 -15 35 0 16 -6 32 -12 34 -8 3 -6 6 5 6 22 1 24 49 2 57 -8 4 -15 18
-15 33 0 15 -9 37 -20 49 -12 12 -16 22 -10 22 5 0 10 9 10 20 0 21 20 25 55
10 19 -8 19 -9 1 -9 -14 -1 -17 -6 -12 -23 3 -13 6 -33 6 -44 0 -12 7 -25 16
-28 23 -9 54 3 54 20 0 8 13 17 30 21 17 3 30 9 30 14 0 5 4 9 9 9 5 0 15 -20
22 -44 13 -46 39 -63 73 -50 17 7 22 34 6 34 -5 0 -10 16 -10 35 0 19 5 35 10
35 6 0 10 7 10 16 0 12 -7 15 -30 12 -16 -2 -31 0 -33 5 -2 6 -20 6 -47 1 -28
-6 -57 -6 -77 0 -31 9 -45 26 -23 26 6 0 10 16 10 36 0 36 0 36 35 30 19 -4
35 -4 35 -1 0 17 -36 39 -66 39 -20 1 -34 6 -34 14 0 6 -6 12 -12 13 -10 0
-10 2 0 6 6 2 12 11 12 19 0 13 23 19 63 15 9 -1 17 4 17 13 0 9 5 16 10 16 6
0 10 -9 10 -20z m1123 -410 c7 0 6 -4 -2 -9 -18 -12 -20 -83 -3 -89 7 -2 12
-16 12 -32 0 -21 -6 -29 -25 -34 -14 -4 -25 -13 -25 -21 0 -8 11 -17 25 -21
18 -4 25 -13 25 -30 0 -19 5 -24 25 -24 16 0 25 6 25 15 0 8 -7 15 -15 15 -18
0 -20 27 -2 33 14 4 47 -29 47 -49 0 -7 5 -14 12 -16 6 -2 11 -21 10 -42 -1
-33 1 -37 21 -36 42 4 57 0 57 -15 0 -8 -9 -15 -20 -15 -16 0 -20 -7 -20 -35
0 -33 -2 -35 -35 -35 -19 0 -35 -4 -35 -10 0 -20 -18 -9 -24 15 -4 17 -14 25
-29 26 -12 0 -16 3 -9 6 6 2 12 11 12 19 0 8 10 14 25 14 14 0 25 -4 25 -10 0
-5 9 -10 20 -10 26 0 26 24 0 48 -13 12 -18 25 -14 35 4 12 1 17 -10 17 -9 0
-16 -5 -16 -10 0 -7 -6 -7 -19 0 -11 5 -27 7 -35 4 -13 -5 -16 1 -16 30 0 24
-7 42 -20 53 -11 10 -20 25 -20 35 0 10 -6 18 -14 18 -8 0 -16 17 -20 46 -10
73 -8 84 13 84 11 0 21 9 24 21 4 15 11 20 22 15 9 -3 22 -6 28 -6z m-1346
-120 c3 -11 9 -20 13 -20 4 0 11 -11 14 -25 4 -14 13 -25 21 -25 8 0 17 -11
21 -25 3 -14 12 -25 19 -25 10 0 13 -17 12 -65 l-2 -66 -37 7 c-31 5 -38 3
-38 -10 0 -9 -4 -16 -10 -16 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 7 10 15
0 8 -4 15 -9 15 -6 0 -13 20 -16 45 -7 43 -8 44 -51 51 -24 3 -44 10 -44 15 0
5 4 9 9 9 5 0 12 9 16 19 3 11 19 22 35 26 23 5 30 12 30 30 0 13 -6 26 -12
28 -10 4 -10 6 0 6 8 1 16 -8 19 -19z m3733 -45 c0 -16 -6 -25 -15 -25 -8 0
-15 -5 -15 -10 0 -6 12 -9 28 -8 24 3 27 -1 30 -31 3 -31 5 -33 33 -27 21 4
29 2 29 -8 0 -8 -6 -17 -12 -20 -10 -5 -10 -7 0 -12 6 -3 12 -19 12 -36 0 -29
1 -30 39 -23 29 5 42 2 56 -11 16 -16 16 -19 1 -29 -9 -7 -16 -17 -16 -24 0
-6 -11 -11 -24 -11 -13 0 -27 -6 -30 -12 -3 -9 -8 -8 -16 5 -9 14 -19 17 -38
12 -15 -4 -30 -12 -34 -18 -5 -7 -8 0 -8 16 0 15 -4 27 -10 27 -5 0 -10 11
-10 24 0 14 -7 31 -15 40 -8 8 -15 19 -15 25 0 6 -9 11 -20 11 -11 0 -20 -4
-20 -10 0 -5 -7 -10 -15 -10 -8 0 -15 7 -15 15 0 8 6 15 14 15 8 0 17 14 21
30 4 17 11 30 16 30 5 0 9 18 9 40 0 29 4 40 15 40 8 0 15 5 15 10 0 6 2 10 5
10 3 0 5 -11 5 -25z m-1784 -112 c19 -19 18 -53 -1 -53 -10 0 -15 -10 -15 -30
0 -21 5 -30 16 -30 20 0 44 24 44 44 0 9 7 16 16 16 8 0 22 7 30 15 14 13 14
17 0 31 -16 15 -20 24 -12 24 3 0 12 -7 20 -16 22 -21 22 -114 1 -114 -8 0
-15 -9 -15 -20 0 -11 7 -20 15 -20 9 0 15 9 15 21 0 19 4 21 41 16 74 -10 120
-89 59 -102 -16 -4 -30 -13 -30 -20 0 -8 -13 -15 -32 -17 -46 -4 -53 -38 -9
-38 41 0 46 -35 10 -71 -23 -23 -29 -24 -29 -3 0 11 -5 14 -16 10 -9 -3 -20
-6 -25 -6 -5 0 -9 -10 -9 -21 0 -24 43 -69 66 -69 10 0 14 -12 14 -40 0 -22 5
-40 10 -40 6 0 10 -4 10 -10 0 -5 -7 -10 -15 -10 -10 0 -15 -10 -15 -30 0 -23
4 -30 20 -30 11 0 20 5 20 10 0 6 5 10 10 10 21 0 9 -20 -15 -26 -16 -4 -25
-13 -25 -26 0 -17 -5 -19 -25 -14 -27 7 -28 4 -19 -31 5 -18 16 -24 55 -29 51
-7 66 -27 28 -37 -18 -5 -20 -11 -16 -34 4 -15 4 -22 1 -15 -3 6 -12 12 -20
12 -8 0 -14 6 -14 14 0 8 -7 16 -15 20 -8 3 -15 12 -15 21 0 8 -7 15 -15 15
-12 0 -15 -13 -15 -55 0 -42 3 -55 15 -55 8 0 15 -7 15 -15 0 -10 -10 -15 -31
-15 -31 0 -58 -19 -60 -42 0 -10 -2 -10 -6 0 -6 14 -33 16 -33 2 0 -5 -7 -10
-15 -10 -10 0 -15 10 -15 30 0 20 -5 30 -15 30 -8 0 -15 7 -15 15 0 17 -2 18
-24 9 -9 -3 -16 -17 -16 -30 0 -15 -6 -24 -15 -24 -8 0 -15 -9 -15 -20 0 -11
7 -20 15 -20 10 0 15 -11 15 -35 0 -32 2 -35 30 -35 23 0 30 -4 30 -20 0 -10
9 -27 20 -37 22 -21 17 -63 -7 -63 -8 0 -25 -12 -38 -26 -13 -14 -29 -23 -34
-19 -18 10 -12 22 13 29 21 5 23 11 24 60 l0 54 -39 -5 c-32 -5 -39 -3 -39 11
0 9 -4 16 -9 16 -4 0 -12 15 -17 32 -7 23 -18 36 -39 43 -17 5 -32 12 -35 16
-3 3 -13 8 -22 12 -26 9 -23 36 3 30 15 -4 22 0 26 16 3 11 9 21 13 21 5 0 15
3 24 6 12 4 16 0 16 -15 0 -12 5 -21 10 -21 14 0 12 37 -2 44 -10 5 -10 7 0
12 6 3 12 12 12 20 0 8 7 14 15 14 8 0 15 -4 15 -10 0 -5 14 -10 31 -10 22 0
32 -5 36 -20 3 -10 14 -22 25 -26 25 -8 78 14 78 32 0 8 7 14 15 14 10 0 15
10 15 30 0 26 -4 30 -26 30 -23 0 -24 2 -10 15 8 8 24 15 35 15 15 0 22 9 27
35 4 25 11 35 25 35 11 0 19 7 19 16 0 29 -31 47 -86 52 -30 2 -54 8 -54 13 0
5 -7 9 -15 9 -8 0 -15 -7 -15 -15 0 -8 5 -15 10 -15 6 0 10 -11 10 -25 0 -19
-5 -25 -23 -25 -25 0 -47 -17 -47 -37 0 -7 9 -13 20 -13 11 0 20 -6 20 -13 0
-19 -119 -20 -126 -2 -3 7 -14 15 -25 18 -23 6 -26 47 -4 47 8 0 15 5 15 10 0
6 5 10 10 10 6 0 10 -9 10 -20 0 -15 7 -20 25 -20 22 0 25 4 25 34 0 30 -4 35
-29 41 -35 8 -46 37 -20 56 25 18 26 80 2 97 -10 7 -25 17 -33 23 -11 8 -12
12 -2 16 6 2 12 13 12 23 0 23 33 29 74 15 19 -7 30 -7 33 0 8 22 19 33 43 39
14 3 19 3 13 0 -14 -6 -18 -34 -5 -34 31 1 52 15 52 35 0 24 -27 37 -65 31
-11 -2 -14 -1 -7 1 6 3 12 8 12 13 0 4 11 11 25 14 24 6 36 36 15 36 -5 0 -10
7 -10 15 0 10 -10 15 -29 15 -20 0 -30 6 -34 20 -4 15 0 20 14 20 10 0 19 -7
19 -15 0 -8 7 -15 15 -15 16 0 18 12 9 48 -4 15 -14 22 -30 22 -13 0 -24 5
-24 10 0 6 7 10 15 10 9 0 15 9 15 25 0 16 -6 25 -15 25 -8 0 -15 4 -15 9 0 5
7 11 15 15 8 3 15 12 15 21 0 8 5 15 10 15 6 0 11 8 12 18 0 9 3 2 5 -17 3
-19 11 -40 19 -48z m-321 -90 c-3 -15 -13 -32 -22 -38 -16 -12 -10 -55 8 -55
16 0 10 -29 -11 -50 -11 -11 -24 -20 -29 -20 -13 0 -22 -46 -15 -75 3 -14 14
-25 23 -26 10 0 11 -2 4 -6 -8 -3 -13 -24 -14 -51 0 -26 -3 -41 -6 -34 -2 6
-18 12 -34 12 -26 0 -29 -4 -29 -30 0 -23 -5 -30 -19 -30 -28 0 -33 14 -25 65
5 35 10 45 25 45 11 0 19 7 19 15 0 8 5 15 10 15 6 0 10 9 10 20 0 15 -7 20
-25 20 -14 0 -25 5 -25 10 0 15 -70 12 -71 -2 0 -10 -2 -10 -6 0 -10 24 -33
13 -33 -17 0 -16 -3 -36 -6 -45 -7 -17 -34 -22 -34 -6 0 14 -27 13 -32 -1 -3
-10 -32 -14 -75 -10 -26 2 -12 21 15 21 43 0 72 11 72 26 0 18 -32 32 -37 17
-3 -8 -13 -8 -33 0 -47 18 -35 27 34 27 44 0 69 5 80 15 8 8 24 15 35 15 11 0
26 7 35 15 17 17 36 20 36 5 0 -5 5 -10 10 -10 6 0 10 14 10 30 0 17 5 30 10
30 6 0 10 -4 10 -10 0 -5 18 -10 40 -10 l39 0 -3 58 c-4 61 11 90 48 91 15 1
17 -4 11 -26z m-1371 -315 c10 -39 7 -57 -15 -77 -14 -12 -19 -26 -15 -37 6
-16 18 -16 73 1 17 6 25 3 30 -11 9 -25 23 -34 52 -34 21 0 23 3 18 30 -6 30
-39 70 -57 70 -5 0 -10 7 -10 15 0 8 -7 15 -15 15 -8 0 -15 5 -15 10 0 6 11
10 24 10 13 0 26 6 29 13 2 6 8 8 13 4 5 -4 3 -12 -4 -16 -15 -9 -5 -41 13
-41 7 0 16 6 18 13 3 7 6 5 6 -5 1 -10 5 -18 11 -18 5 0 12 -16 16 -35 5 -30
10 -35 35 -35 19 0 29 -5 29 -15 0 -10 10 -15 30 -15 17 0 30 -4 30 -10 0 -5
-4 -10 -10 -10 -18 0 -10 -29 10 -35 15 -5 20 -15 20 -41 0 -32 -2 -34 -35
-34 -19 0 -35 -2 -35 -4 0 -2 -3 -11 -6 -20 -4 -10 -1 -16 9 -16 8 0 17 -7 21
-15 3 -9 19 -15 41 -15 19 0 35 -4 35 -10 0 -5 -16 -10 -35 -10 -40 0 -49 -24
-12 -33 12 -3 32 -9 45 -12 18 -6 22 -4 22 14 0 38 27 22 39 -23 6 -24 18 -46
26 -50 8 -3 15 -12 15 -21 0 -9 9 -15 23 -15 18 0 25 9 36 44 7 25 19 47 27
50 8 3 14 12 14 20 0 15 37 32 47 22 4 -3 1 -6 -5 -6 -7 0 -12 -9 -12 -20 0
-11 4 -20 9 -20 14 0 27 -51 23 -89 -2 -23 -9 -35 -23 -38 -13 -4 -19 -14 -19
-34 0 -16 -4 -29 -10 -29 -12 0 -13 -27 -1 -32 5 -1 12 -18 16 -35 5 -28 4
-32 -9 -27 -9 3 -16 10 -16 15 0 5 -9 9 -19 9 -26 0 -36 -36 -20 -68 11 -20
11 -26 2 -29 -7 -3 -13 -13 -13 -24 0 -16 -5 -18 -25 -13 -19 5 -25 2 -25 -10
0 -12 -10 -16 -35 -16 -24 0 -35 -5 -35 -15 0 -8 -7 -15 -15 -15 -18 0 -20
-27 -3 -32 8 -3 5 -12 -10 -28 -12 -13 -25 -20 -29 -17 -3 4 -1 7 5 7 14 0 17
67 3 72 -4 2 -12 13 -15 26 -7 19 -4 22 23 22 27 0 30 3 33 38 2 29 7 37 23
38 41 2 44 5 47 37 3 28 7 32 35 35 20 2 34 10 38 21 6 20 -13 71 -27 71 -4 0
-8 9 -8 21 0 17 -5 20 -32 17 -28 -2 -34 -8 -36 -30 -2 -20 1 -28 12 -28 8 0
17 6 19 13 3 6 6 2 6 -10 1 -17 -5 -23 -19 -23 -11 0 -20 -4 -20 -10 0 -5 -7
-10 -15 -10 -8 0 -15 4 -15 8 0 14 -36 52 -49 52 -6 0 -11 -7 -11 -15 0 -8 -4
-15 -10 -15 -5 0 -10 -7 -10 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -7 -15 -15 0
-8 -4 -15 -10 -15 -5 0 -10 5 -10 10 0 6 -9 10 -20 10 -11 0 -20 7 -20 15 0 8
-7 15 -15 15 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -15 -10 -23 0 -18 -28 8 -36 36
-11 52 -46 17 -37 -13 4 -20 0 -20 -10 0 -8 -7 -18 -16 -21 -12 -5 -14 -13 -9
-33 7 -31 21 -43 48 -43 14 0 16 5 11 25 -4 15 -2 25 5 25 6 0 11 11 11 25 0
16 6 25 15 25 8 0 15 -4 15 -10 0 -5 11 -10 25 -10 22 0 25 4 25 34 0 29 3 33
20 29 24 -6 27 -33 4 -33 -9 0 -14 -8 -13 -17 3 -33 -1 -43 -17 -43 -9 0 -18
-9 -21 -20 -3 -11 -9 -20 -14 -20 -5 0 -9 -9 -9 -20 0 -11 -6 -20 -14 -20 -7
0 -16 -10 -20 -22 -9 -36 -7 -48 8 -48 9 0 16 -18 20 -50 4 -27 11 -50 16 -50
6 0 10 -11 10 -25 0 -16 -6 -25 -15 -25 -8 0 -15 -9 -15 -20 0 -11 -4 -20 -10
-20 -5 0 -10 -22 -10 -50 0 -27 5 -50 10 -50 6 0 10 -13 10 -30 0 -19 -5 -30
-14 -30 -8 0 -17 -8 -20 -17 -3 -10 -3 1 -1 25 4 33 2 42 -10 42 -15 0 -17 8
-19 125 -1 45 -3 50 -26 53 -13 2 -29 11 -36 20 -6 9 -18 13 -28 9 -17 -7 -20
3 -10 40 3 12 15 25 25 28 29 10 24 55 -10 93 -17 17 -35 32 -41 32 -13 0 -62
-30 -72 -45 -5 -6 -8 -3 -8 8 0 9 -4 17 -10 17 -5 0 -10 5 -10 10 0 6 7 10 16
10 12 0 15 7 12 25 -5 23 -2 25 27 25 24 0 34 -5 38 -20 5 -21 37 -28 37 -9 0
7 7 17 14 23 19 14 32 66 17 66 -6 0 -11 -7 -11 -15 0 -8 -7 -15 -15 -15 -8 0
-15 -7 -15 -15 0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 -9 15 -20 15 -11 0
-20 7 -20 15 0 11 -11 15 -40 15 -22 0 -40 4 -40 9 0 4 11 6 25 3 18 -3 25 0
25 12 0 10 9 16 25 16 21 0 25 5 25 30 0 25 4 30 25 30 18 0 25 5 25 19 0 11
-6 21 -12 23 -8 3 -2 14 16 31 25 24 27 28 13 42 -9 8 -27 15 -41 15 -23 0
-26 4 -26 34 0 26 -5 36 -20 41 -11 3 -20 11 -20 16 0 5 -16 9 -35 9 -19 0
-35 -4 -35 -8 0 -5 -9 -19 -20 -32 -11 -13 -20 -19 -20 -13 0 5 -11 15 -24 22
-13 7 -29 24 -36 36 -11 20 -10 26 3 39 9 9 25 16 36 16 18 0 21 6 21 41 l0
41 46 -7 c43 -6 84 -33 84 -55 0 -5 5 -10 11 -10 6 0 9 9 6 19 -3 13 -1 18 8
15 7 -3 15 -14 18 -25 4 -14 14 -19 37 -19 29 0 32 -3 26 -22 -3 -13 -6 -35
-6 -50 0 -18 -5 -28 -15 -28 -9 0 -15 -9 -15 -25 0 -30 36 -36 43 -7 4 13 5
11 6 -5 0 -13 5 -23 10 -23 5 0 11 -9 14 -20 8 -30 57 -28 58 3 0 21 1 21 8 2
5 -11 4 -21 0 -23 -12 -5 -11 -49 1 -57 6 -3 10 -15 10 -26 0 -10 6 -19 13
-19 9 0 13 25 14 97 1 65 6 99 15 104 9 6 5 14 -15 33 -21 19 -27 34 -27 65 0
22 -3 46 -6 55 -8 20 -44 21 -44 1 0 -27 -49 -18 -60 11 -13 32 -34 54 -54 54
-9 0 -16 5 -16 10 0 6 -9 10 -19 10 -11 0 -26 7 -35 15 -8 8 -19 15 -25 15 -6
0 -11 16 -11 35 0 19 5 35 10 35 6 0 10 7 10 15 0 8 -4 15 -9 15 -12 0 -20 36
-14 60 8 29 49 27 57 -2z m3942 -189 c4 -7 -3 -19 -16 -29 -16 -12 -21 -23
-15 -38 3 -12 10 -22 15 -22 4 0 10 -9 13 -21 5 -17 10 -19 41 -13 l36 6 0
-38 c-1 -31 -3 -36 -11 -21 -14 23 -39 22 -39 -3 0 -11 7 -20 15 -20 20 0 19
-10 -2 -26 -25 -19 -43 -17 -43 5 0 14 -10 20 -47 25 l-48 6 0 45 c0 33 4 45
18 49 12 3 17 14 17 36 0 45 48 88 66 59z m-2070 -291 c4 -13 12 -26 18 -30 7
-5 4 -8 -6 -8 -11 0 -18 -8 -18 -20 0 -13 7 -20 20 -20 13 0 20 7 20 20 0 11
8 24 18 27 9 4 21 11 25 17 6 8 10 7 14 -1 2 -7 11 -13 19 -13 8 0 14 -4 14
-10 0 -5 -5 -10 -11 -10 -14 0 -39 -25 -39 -39 0 -6 7 -11 15 -11 8 0 15 -6
15 -14 0 -8 9 -18 21 -21 38 -13 18 -40 -32 -43 -42 -3 -47 -1 -51 21 -4 20
-1 23 14 19 12 -3 18 1 18 11 0 9 -13 18 -31 22 -24 5 -35 2 -50 -14 -11 -12
-26 -21 -34 -21 -18 0 -20 -26 -2 -33 7 -3 5 -6 -5 -6 -10 -1 -18 -8 -18 -16
0 -10 11 -15 35 -15 19 0 35 -4 35 -10 0 -14 27 -13 32 1 2 6 5 -12 6 -41 1
-32 -2 -49 -8 -45 -5 3 -10 17 -10 29 0 20 -7 25 -41 30 -34 5 -45 3 -60 -13
-23 -25 -24 -41 -3 -41 21 0 14 -34 -9 -38 -13 -3 -17 3 -17 27 0 28 -3 31
-29 31 -42 0 -61 10 -61 31 0 25 30 24 37 -1 9 -32 49 -26 56 9 3 15 3 38 0
50 -6 23 -33 29 -33 7 0 -24 -28 -26 -34 -3 -11 42 -7 55 14 50 17 -4 20 0 20
25 0 17 -3 32 -7 35 -5 2 -2 2 5 0 24 -5 62 9 62 23 0 8 -4 14 -10 14 -5 0
-10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15 0 -8 5 -15 10 -15 6 0 10 9 10
20 0 11 7 20 15 20 8 0 15 5 15 10 0 19 19 10 26 -12z m-576 -13 c0 -10 12
-16 38 -17 l37 -1 -39 -4 -38 -4 5 -42 c3 -23 12 -46 21 -52 8 -6 16 -24 18
-41 2 -24 -1 -30 -19 -32 -22 -3 -33 -32 -13 -32 6 0 10 -7 10 -15 0 -16 26
-21 32 -6 2 4 13 12 26 16 19 5 25 3 29 -15 3 -13 12 -20 22 -18 12 3 16 15
14 53 -1 27 -6 51 -12 53 -16 5 -13 22 3 22 8 0 16 5 18 12 5 15 28 1 28 -18
0 -8 -7 -17 -15 -20 -22 -9 -19 -23 11 -50 14 -13 20 -24 15 -24 -6 0 -11 -7
-11 -15 0 -8 4 -15 9 -15 7 0 31 -51 31 -64 0 -1 -24 -2 -53 -2 -28 -1 -44 1
-34 3 11 3 17 12 15 21 -3 12 -13 16 -36 14 -23 -1 -32 2 -32 13 0 26 -29 16
-36 -12 -10 -49 -17 -60 -21 -38 -2 11 -6 30 -8 41 -3 12 -14 24 -25 27 -12 3
-20 13 -20 26 0 12 -6 21 -15 21 -8 0 -15 -9 -15 -20 0 -13 -7 -20 -20 -20
-11 0 -20 6 -20 14 0 9 -17 16 -45 20 -25 4 -45 11 -45 17 0 5 -13 9 -30 9
-23 0 -30 -4 -30 -20 0 -11 7 -20 16 -20 11 0 14 -6 9 -21 -8 -24 30 -68 61
-69 10 0 30 -1 44 -2 14 -1 41 1 60 5 l35 7 2 -40 c0 -22 -2 -40 -5 -40 -25 1
-52 15 -52 26 0 22 -41 17 -47 -6 -4 -16 -11 -19 -27 -14 -11 4 -25 12 -29 18
-6 8 -10 8 -14 -1 -2 -7 -16 -13 -29 -13 -15 0 -24 -6 -24 -15 0 -8 7 -15 15
-15 8 0 15 -4 15 -10 0 -5 6 -10 14 -10 8 0 17 -11 20 -25 5 -20 13 -25 36
-25 17 0 30 5 30 10 0 6 7 10 15 10 9 0 18 7 21 15 12 31 31 15 30 -24 0 -33
-5 -41 -23 -46 -27 -6 -73 3 -73 16 0 5 -11 9 -25 9 -16 0 -25 6 -25 15 0 9
-9 15 -25 15 -19 0 -25 -5 -25 -21 0 -22 22 -49 39 -49 6 0 11 -5 11 -11 0 -7
10 -20 21 -30 14 -12 23 -35 26 -64 3 -25 10 -45 14 -45 19 0 8 -29 -13 -36
-24 -7 -48 17 -48 48 0 13 -8 18 -25 18 -26 0 -34 14 -12 24 10 5 10 7 0 12
-7 3 -13 20 -13 39 0 18 -3 47 -6 65 -7 32 -8 32 -53 27 -25 -3 -47 -10 -49
-16 -5 -16 -29 -13 -36 4 -7 19 -36 20 -36 0 0 -8 9 -19 20 -25 11 -6 20 -16
19 -23 0 -9 -2 -9 -6 1 -2 6 -11 12 -19 12 -8 0 -14 5 -14 10 0 6 -13 10 -29
10 -34 0 -54 -35 -30 -52 11 -7 11 -11 2 -15 -7 -2 -13 -11 -13 -19 0 -8 -4
-14 -9 -14 -5 0 -22 -5 -38 -11 -15 -7 -45 -11 -65 -10 -22 2 -38 -2 -38 -8 0
-6 -7 -11 -15 -11 -8 0 -15 -7 -15 -15 0 -8 5 -15 10 -15 6 0 10 -7 10 -15 0
-8 7 -15 15 -15 8 0 15 4 15 9 0 5 18 11 40 13 32 3 40 0 40 -13 0 -11 -11
-20 -31 -24 -44 -10 -94 -65 -59 -65 6 0 10 -9 10 -20 0 -11 -4 -20 -9 -20 -5
0 -13 9 -16 20 -3 11 -13 20 -21 20 -8 0 -14 7 -14 15 0 18 -26 20 -33 3 -3
-8 -6 -6 -6 5 -1 9 -6 17 -12 17 -20 0 -39 29 -39 59 0 22 5 31 16 31 26 0 11
33 -19 43 -35 11 -38 8 -40 -37 -2 -27 -6 -36 -19 -36 -10 0 -18 -7 -18 -15 0
-8 -6 -15 -13 -15 -9 0 -12 10 -9 30 2 17 8 30 13 30 5 0 9 11 9 25 0 18 5 25
19 25 11 0 21 4 23 10 2 5 24 13 51 16 42 6 47 5 47 -12 0 -17 8 -19 70 -20
l70 0 0 29 c0 20 -6 30 -20 34 -11 3 -20 10 -20 15 0 5 -13 14 -30 19 -16 6
-30 15 -30 20 0 5 -4 9 -10 9 -5 0 -10 -13 -10 -30 0 -16 -4 -30 -9 -30 -11 0
-21 52 -15 77 5 19 -11 43 -40 60 -7 5 -16 19 -20 33 -3 14 -3 19 0 13 3 -7
17 -13 30 -13 20 0 24 5 25 33 1 17 3 24 6 15 2 -10 11 -18 19 -18 8 0 14 -7
14 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -7 -15 -15 0 -8 7 -15 15 -15 8 0 15 7
15 15 0 8 7 15 15 15 10 0 15 -11 15 -35 0 -24 5 -35 15 -35 8 0 15 7 15 15 0
11 11 15 40 15 l40 0 0 45 c0 38 -3 45 -20 45 -15 0 -20 -7 -20 -25 0 -16 -6
-25 -15 -25 -8 0 -15 5 -15 10 0 6 3 10 8 10 7 0 32 47 32 62 0 4 -8 8 -19 8
-10 0 -21 9 -24 20 -6 23 23 60 47 60 12 0 16 10 16 44 0 39 3 45 27 51 15 3
31 12 35 18 4 7 8 -2 8 -19 0 -26 5 -33 25 -38 30 -8 45 -1 45 20 0 8 4 14 9
14 5 0 12 13 16 29 7 31 26 41 85 41 25 0 31 -3 21 -9 -12 -8 -13 -15 -4 -34
11 -25 11 -25 -28 -20 -35 5 -39 3 -39 -16 0 -13 -7 -29 -15 -37 -8 -9 -15
-11 -15 -5 0 6 -7 11 -15 11 -8 0 -15 -7 -15 -15 0 -8 5 -15 10 -15 6 0 10 -4
10 -10 0 -5 -9 -10 -21 -10 -11 0 -28 -8 -37 -17 -16 -16 -17 -16 -20 0 -5 24
-42 22 -42 -2 0 -10 7 -21 15 -25 8 -3 15 -17 15 -31 0 -14 -7 -28 -15 -31
-19 -8 -20 -34 -1 -34 8 0 18 9 21 20 5 16 15 20 46 20 21 0 39 5 39 10 0 6 9
10 20 10 22 0 29 35 8 42 -9 3 -8 9 5 22 9 10 17 22 17 27 0 5 11 9 24 9 16 0
26 7 29 21 5 18 10 20 39 14 18 -4 35 -11 36 -16 6 -16 62 -10 62 6 0 8 -6 15
-14 15 -21 0 -27 27 -8 35 9 3 18 1 20 -4 2 -6 10 -11 18 -11 8 0 14 -4 14
-10 0 -5 -4 -10 -10 -10 -5 0 -10 -7 -10 -15 0 -11 12 -15 50 -15 28 0 50 5
50 10 0 6 11 10 25 10 20 0 25 5 25 25 0 16 -6 25 -15 25 -12 0 -14 8 -9 40 5
32 4 40 -9 40 -8 0 -18 -6 -20 -12 -3 -8 -6 -5 -6 6 -1 14 -7 17 -23 13 -13
-3 -43 -2 -68 3 -25 4 -35 8 -22 9 12 0 22 7 22 16 0 12 15 15 75 15 60 0 75
-3 75 -15z m336 -395 c9 0 14 11 14 30 0 19 5 30 14 30 10 0 14 -9 13 -27 -2
-25 2 -28 31 -31 22 -2 34 2 38 12 8 20 24 21 24 1 0 -8 -4 -15 -9 -15 -6 0
-11 -15 -13 -32 -3 -32 -4 -33 -55 -36 -29 -2 -53 -7 -53 -13 0 -5 -6 -9 -14
-9 -10 0 -14 9 -13 28 2 24 -2 27 -30 30 -29 3 -33 7 -33 33 0 24 4 29 25 29
20 0 25 5 26 28 1 21 3 18 11 -15 5 -26 15 -43 24 -43z m-248 -137 c-29 -7
-21 -83 8 -83 8 0 14 5 14 11 0 8 6 7 17 -2 13 -11 17 -11 20 -1 3 6 16 12 29
12 25 0 34 24 12 33 -7 3 -3 6 10 6 18 1 22 -4 22 -29 0 -32 40 -80 66 -80 8
0 14 -7 14 -16 0 -11 -6 -14 -22 -8 -13 3 -24 11 -26 15 -2 5 -14 9 -27 9 -13
0 -30 7 -39 15 -22 22 -36 18 -36 -10 0 -18 7 -26 25 -31 33 -8 32 -20 -5 -33
-18 -6 -30 -6 -30 0 0 5 -9 9 -20 9 -11 0 -20 5 -20 10 0 6 -7 10 -15 10 -8 0
-15 7 -15 15 0 24 -32 52 -67 60 -29 6 -33 4 -33 -13 0 -11 -7 -22 -15 -26 -8
-3 -15 -10 -15 -16 0 -6 -7 -18 -15 -26 -13 -14 -15 -12 -15 15 0 19 8 38 20
48 11 10 20 26 20 36 0 14 5 17 19 12 11 -3 31 -3 45 1 20 5 26 12 26 35 0 26
3 29 33 28 17 -1 24 -3 15 -6z m162 -213 c0 -6 20 -10 44 -10 41 0 45 -2 51
-30 4 -16 13 -30 21 -30 8 0 14 -10 14 -25 0 -16 6 -25 15 -25 8 0 15 -4 15
-10 0 -15 29 -12 46 4 8 8 17 29 20 45 7 32 18 40 28 19 5 -10 7 -10 12 0 7
16 54 16 54 -1 0 -7 -10 -17 -22 -22 -16 -7 -25 -23 -31 -52 -5 -27 -14 -43
-23 -43 -8 0 -14 7 -14 15 0 8 -7 15 -15 15 -8 0 -15 -7 -15 -15 0 -8 5 -15
10 -15 6 0 10 -7 10 -15 0 -8 -4 -15 -9 -15 -5 0 -11 7 -15 15 -3 8 -12 15
-21 15 -8 0 -15 7 -15 15 0 19 -27 19 -46 1 -16 -16 -19 -43 -5 -48 5 -1 12
-15 16 -29 6 -24 3 -27 -27 -32 -79 -14 -98 -20 -98 -32 0 -8 -9 -10 -25 -7
-23 4 -24 7 -18 46 13 78 12 81 -27 79 -28 -2 -32 -1 -17 8 21 12 22 33 1 53
-8 9 -10 16 -4 16 6 0 13 -7 16 -15 3 -8 14 -17 25 -20 10 -4 19 -13 19 -22 0
-11 6 -14 21 -10 31 8 59 -13 59 -45 0 -24 4 -28 26 -28 22 0 25 3 20 23 -3
12 -6 27 -6 34 0 6 -9 15 -20 18 -13 4 -20 15 -20 31 0 15 -6 24 -15 24 -8 0
-15 -4 -15 -10 0 -5 -7 -10 -15 -10 -8 0 -15 7 -15 15 0 8 -6 15 -14 15 -8 0
-17 14 -21 31 -8 35 2 52 16 26 6 -9 14 -17 20 -17 15 0 -1 73 -20 88 -14 11
-13 11 7 2 12 -6 22 -15 22 -20z m2420 -700 c0 -5 11 -10 25 -10 14 0 25 5 25
10 0 6 7 10 15 10 8 0 15 -4 15 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -7 -15 -15
0 -8 5 -15 10 -15 6 0 10 -10 10 -21 0 -16 5 -20 18 -17 21 6 54 -23 34 -30
-19 -6 -15 -59 5 -67 10 -3 19 -2 21 3 5 17 62 -11 62 -30 0 -10 -7 -18 -15
-18 -8 0 -15 -5 -15 -11 0 -6 -7 -3 -16 5 -17 18 -20 18 -63 10 -17 -4 -31
-13 -31 -20 0 -8 -7 -14 -15 -14 -8 0 -15 9 -15 20 0 15 7 20 29 20 17 0 31 6
35 16 8 22 8 24 -8 24 -8 0 -17 11 -20 25 -11 44 -87 30 -97 -18 -3 -12 -8
-14 -19 -6 -11 8 -12 12 -2 16 14 6 16 33 2 33 -5 0 -10 7 -10 15 0 8 7 15 15
15 8 0 15 -4 15 -10 0 -16 47 -11 54 6 11 28 7 42 -14 47 -11 3 -20 12 -20 21
0 9 -4 16 -10 16 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-1789
-1070 c22 0 29 -5 29 -20 0 -11 7 -23 15 -26 17 -7 21 -34 5 -34 -14 0 -12
-27 3 -33 7 -3 5 -6 -5 -6 -10 -1 -20 -12 -24 -26 -6 -24 -34 -36 -34 -15 0 5
-11 12 -25 16 -20 5 -25 13 -25 36 0 21 -6 31 -20 35 -11 3 -20 9 -20 13 0 5
9 12 20 15 14 4 20 15 20 35 0 29 22 48 28 23 2 -7 16 -13 33 -13z m1865 -245
c3 -14 12 -25 20 -25 8 0 14 10 14 25 0 14 5 25 10 25 6 0 10 -7 10 -16 0 -14
8 -16 49 -11 46 5 50 4 55 -18 10 -38 -2 -56 -33 -48 -14 4 -36 7 -48 8 -13 0
-23 6 -23 13 0 7 -13 12 -31 12 l-31 0 4 -56 c3 -33 11 -60 19 -65 11 -7 11
-9 -3 -9 -10 0 -18 7 -18 15 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 8 -4 15 -9
15 -18 0 -41 32 -41 57 0 22 3 24 25 19 23 -6 25 -3 25 24 0 36 17 40 26 5z"/>
<path d="M3000 16831 c-5 -11 -10 -36 -10 -56 0 -33 2 -36 23 -31 12 3 16 3
10 0 -7 -3 -13 -19 -13 -35 0 -22 6 -30 25 -35 16 -4 30 -1 40 9 18 19 20 87
3 88 -7 0 -3 5 10 11 13 7 22 20 22 33 0 18 -7 24 -37 29 -56 9 -61 8 -73 -13z"/>
<path d="M2270 15125 c0 -8 5 -15 10 -15 20 0 9 -18 -15 -24 -23 -6 -36 -36
-15 -36 5 0 12 -16 16 -35 l6 -35 47 6 48 6 -4 -42 c-3 -40 -2 -42 21 -38 15
3 27 -1 35 -14 7 -11 7 -18 1 -18 -5 0 -10 -9 -10 -20 0 -15 7 -20 25 -20 22
0 25 4 25 35 0 24 5 35 15 35 19 0 19 27 1 46 -20 19 -36 18 -37 -3 0 -14 -2
-15 -6 -3 -7 16 -12 23 -46 63 -24 27 -47 36 -47 17 0 -5 -7 -10 -15 -10 -9 0
-15 9 -15 24 0 13 -6 27 -12 29 -7 3 -3 6 10 6 25 1 33 41 7 41 -8 0 -15 5
-15 10 0 6 -7 10 -15 10 -8 0 -15 -7 -15 -15z"/>
<path d="M2540 15071 c0 -11 6 -22 13 -25 6 -3 -5 -3 -25 0 -31 5 -38 3 -38
-10 0 -9 -4 -16 -10 -16 -16 0 -11 -27 6 -34 22 -9 24 -8 24 9 0 8 7 15 15 15
22 0 18 -31 -5 -37 -25 -7 -27 -50 -2 -56 13 -4 11 -5 -5 -6 -26 -1 -28 -13
-12 -60 9 -25 16 -31 40 -31 16 0 29 5 29 10 0 6 9 10 20 10 11 0 20 -4 20
-10 0 -5 7 -10 15 -10 8 0 15 7 15 15 0 8 4 15 9 15 13 0 21 27 15 45 -8 20
-44 19 -61 -2 -13 -16 -13 -16 -4 4 6 12 11 33 11 46 0 17 7 26 22 30 12 4 24
4 27 2 2 -3 -1 -5 -7 -5 -7 0 -12 -7 -12 -15 0 -8 7 -15 15 -15 8 0 15 7 15
15 0 8 7 15 16 15 10 0 14 7 12 23 -2 18 -8 22 -36 19 -26 -2 -32 2 -32 18 0
11 -7 20 -15 20 -8 0 -15 6 -15 14 0 7 -10 16 -22 20 -35 9 -38 8 -38 -13z"/>
<path d="M2580 14813 c-27 -11 -40 -31 -40 -63 0 -42 29 -70 79 -77 23 -3 41
-10 41 -14 0 -5 -4 -9 -10 -9 -5 0 -10 -9 -10 -20 0 -11 -7 -20 -15 -20 -8 0
-15 7 -15 15 0 11 -11 15 -40 15 -29 0 -40 -4 -40 -15 0 -8 6 -15 14 -15 8 0
18 -9 21 -20 3 -11 11 -20 16 -20 5 0 9 -14 9 -32 0 -36 20 -98 32 -98 4 0 8
-13 8 -29 0 -22 -6 -30 -25 -35 -14 -4 -36 -4 -50 0 -18 4 -25 2 -25 -9 0 -16
-20 -27 -51 -27 -11 0 -19 -7 -19 -16 0 -13 -7 -16 -32 -12 -41 7 -58 1 -58
-19 0 -10 6 -14 19 -10 11 2 24 -3 32 -14 15 -22 45 -25 53 -6 5 10 7 10 12 0
7 -15 44 -17 44 -3 0 15 29 12 46 -4 22 -22 17 -36 -16 -42 -39 -8 -38 -23 3
-58 20 -18 27 -31 20 -33 -31 -11 -2 -42 40 -43 15 0 6 29 -10 33 -17 4 -17 5
0 6 22 1 23 37 1 58 -8 9 -13 22 -10 30 3 8 6 27 6 43 0 23 5 30 30 35 23 5
30 12 30 31 0 13 5 24 10 24 6 0 10 -4 10 -10 0 -5 16 -10 35 -10 19 0 35 5
35 11 0 16 -26 39 -44 39 -9 0 -16 6 -16 13 0 6 -3 22 -6 35 -4 12 -2 22 4 22
6 0 12 -10 14 -22 4 -31 67 -47 85 -22 21 29 16 56 -9 54 -46 -4 -58 0 -58 20
0 12 -7 20 -16 20 -18 0 -44 23 -44 39 0 6 7 11 15 11 15 0 16 7 19 108 1 12
8 27 16 32 9 5 16 26 17 50 1 36 -1 40 -23 40 -13 0 -24 5 -24 10 0 6 -7 10
-15 10 -8 0 -15 -4 -15 -10 0 -5 7 -10 15 -10 8 0 15 -7 15 -15 0 -9 -9 -15
-24 -15 -13 0 -27 -6 -29 -12 -3 -7 -6 8 -6 33 -1 33 -6 49 -17 53 -9 3 -16 6
-17 5 -1 0 -9 -3 -17 -6z"/>
<path d="M2940 14431 c0 -5 -10 -11 -21 -14 -18 -5 -21 -11 -16 -36 6 -26 3
-31 -13 -31 -13 0 -20 -7 -20 -20 0 -11 6 -20 14 -20 8 0 17 -7 20 -16 9 -24
7 -28 -8 -15 -7 6 -22 10 -32 10 -37 -4 -54 1 -54 16 0 9 9 15 25 15 21 0 25
5 25 28 0 15 3 37 6 50 5 16 2 22 -10 22 -9 0 -16 -7 -16 -15 0 -9 -7 -18 -15
-21 -8 -4 -15 -14 -15 -23 0 -9 -9 -23 -20 -31 -25 -18 -25 -29 -2 -46 15 -12
14 -13 -15 -14 -17 0 -34 -6 -36 -12 -3 -8 -6 -6 -6 5 -2 31 -31 19 -31 -13 0
-20 -5 -30 -15 -30 -18 0 -20 -36 -2 -43 10 -4 10 -6 0 -6 -18 -1 -16 -31 2
-31 8 0 15 -5 15 -10 0 -6 -7 -13 -15 -16 -8 -4 -15 -13 -15 -21 0 -8 7 -21
15 -29 8 -9 15 -28 15 -44 0 -22 6 -29 27 -35 19 -4 31 -2 39 9 15 22 24 20
24 -4 0 -13 7 -20 19 -20 26 0 33 13 25 44 -4 19 -13 26 -37 28 -24 2 -33 8
-35 26 -2 12 -8 22 -13 22 -5 0 -9 5 -9 10 0 6 7 10 15 10 8 0 15 -7 15 -15 0
-10 10 -15 30 -15 20 0 30 5 30 15 0 8 9 15 20 15 14 0 20 7 20 21 0 16 -5 20
-20 16 -12 -3 -27 2 -36 11 -15 14 -15 17 0 27 9 7 16 22 16 34 0 12 6 21 15
21 15 0 20 -16 17 -52 -2 -26 38 -24 45 2 3 11 12 20 19 20 8 0 14 9 14 20 0
11 4 20 9 20 5 0 12 19 16 42 6 32 4 44 -9 53 -20 15 -21 45 -1 45 9 0 15 9
15 25 0 16 -6 25 -15 25 -8 0 -15 -4 -15 -9z"/>
<path d="M2430 14200 c0 -11 -7 -20 -15 -20 -21 0 -29 -24 -15 -49 8 -16 20
-21 50 -21 38 0 39 0 46 -45 3 -25 4 -49 0 -52 -3 -4 -6 1 -6 10 0 10 -7 17
-15 17 -8 0 -15 7 -15 15 0 8 -5 15 -11 15 -6 0 -17 -7 -25 -15 -9 -8 -30 -15
-49 -15 -18 0 -36 -6 -38 -12 -3 -8 -6 -6 -6 5 -1 9 -8 17 -16 17 -11 0 -15
-11 -15 -40 0 -28 -6 -45 -20 -57 -11 -10 -20 -30 -20 -45 0 -16 -4 -28 -10
-28 -5 0 -10 -7 -10 -15 0 -9 9 -15 24 -15 13 0 26 7 30 15 3 8 12 15 20 15 7
0 16 9 19 20 3 11 12 20 21 20 9 0 16 5 16 10 0 6 14 10 30 10 20 0 30 5 30
15 0 19 36 38 60 30 14 -4 20 -2 20 9 0 10 9 16 25 16 14 0 25 -4 25 -10 0 -5
-4 -10 -10 -10 -5 0 -10 -5 -10 -11 0 -18 55 -6 58 14 2 10 -3 17 -12 17 -9 0
-16 8 -16 20 0 11 5 20 10 20 6 0 10 7 10 15 0 10 -10 15 -28 15 -30 0 -72 29
-72 49 0 6 -4 11 -10 11 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 9 10 20 0
16 -7 20 -30 20 -23 0 -30 -4 -30 -20z"/>
<path d="M2060 13934 c0 -9 -11 -27 -25 -40 -27 -25 -32 -49 -12 -57 9 -4 9
-8 -2 -15 -12 -9 -12 -13 -2 -26 11 -13 11 -16 -3 -16 -9 0 -16 -7 -16 -15 0
-8 -7 -15 -15 -15 -8 0 -15 5 -15 10 0 6 -9 10 -20 10 -11 0 -20 -7 -20 -15 0
-8 -5 -15 -11 -15 -19 0 -4 -45 19 -55 29 -13 28 -32 -2 -60 -14 -12 -26 -36
-28 -51 -2 -21 -9 -30 -25 -32 -21 -3 -23 1 -23 37 0 22 7 46 15 55 8 8 18 35
21 60 6 38 4 46 -10 46 -9 0 -16 -4 -16 -10 0 -5 -7 -10 -15 -10 -10 0 -17
-14 -21 -40 -6 -34 -10 -40 -25 -35 -13 4 -19 1 -19 -9 0 -9 -4 -16 -10 -16
-14 0 -12 -27 3 -34 10 -5 10 -7 0 -12 -7 -3 -13 -19 -13 -35 0 -22 5 -29 19
-29 10 0 24 -7 32 -16 7 -8 21 -13 31 -11 10 3 18 1 18 -4 0 -16 28 -10 34 7
9 24 35 -1 29 -26 -4 -15 0 -20 16 -20 11 0 21 6 21 14 0 8 7 16 15 20 9 3 15
18 15 35 0 47 10 61 41 61 22 0 29 5 29 20 0 22 -26 28 -33 8 -3 -10 -2 131 2
163 1 4 12 10 26 13 16 4 25 13 25 26 0 11 5 20 10 20 6 0 10 7 10 15 0 8 -4
15 -10 15 -5 0 -10 -7 -10 -15 0 -8 -7 -15 -16 -15 -14 0 -15 7 -10 42 4 24
14 46 21 51 22 12 18 37 -5 37 -12 0 -20 -7 -20 -16z"/>
<path d="M2593 9543 c-22 -4 -33 -33 -13 -33 6 0 10 -9 10 -20 0 -17 -5 -19
-25 -14 -21 5 -25 3 -25 -14 0 -11 6 -23 13 -26 10 -5 10 -7 0 -12 -18 -8 -16
-34 2 -34 8 0 15 7 15 16 0 12 6 15 23 10 12 -3 25 -6 30 -6 14 0 7 -20 -8
-20 -8 0 -15 -9 -15 -20 0 -11 7 -20 15 -20 8 0 15 -9 15 -20 0 -11 -4 -20
-10 -20 -5 0 -10 -9 -10 -21 0 -19 5 -21 50 -17 35 2 50 -1 50 -9 0 -7 7 -13
15 -13 8 0 15 9 15 20 0 11 -5 20 -11 20 -14 0 -39 25 -39 39 0 6 -7 11 -15
11 -8 0 -15 6 -15 14 0 8 -6 17 -12 19 -8 3 -6 6 5 6 24 1 22 32 -3 38 -11 3
-20 9 -20 13 0 19 13 40 25 40 7 0 16 6 19 13 5 10 7 10 12 0 10 -21 44 -16
44 7 0 11 -4 20 -9 20 -5 0 -16 6 -23 13 -15 16 -72 26 -105 20z"/>
<path d="M6758 19286 c-30 -22 -21 -33 32 -40 28 -4 50 -11 50 -16 0 -5 11
-12 25 -16 24 -6 30 -16 25 -39 -1 -5 4 -27 11 -48 10 -32 10 -40 -1 -47 -9
-6 -11 -21 -7 -54 6 -41 5 -46 -13 -46 -18 0 -20 -4 -14 -42 4 -24 12 -46 18
-50 13 -9 -30 -10 -61 -2 -19 5 -23 13 -23 44 0 42 -3 44 -103 56 -31 4 -57
11 -57 15 0 5 -9 9 -20 9 -11 0 -20 -6 -20 -14 0 -11 -16 -13 -79 -10 l-80 3
-7 -46 c-6 -38 -4 -49 12 -60 22 -16 41 -16 84 -1 26 9 35 9 38 0 2 -7 10 -12
18 -12 8 0 14 -4 14 -10 0 -5 16 -10 35 -10 28 0 35 4 35 20 0 11 5 20 10 20
6 0 10 7 10 15 0 8 5 15 10 15 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10
-13 10 -30 0 -16 -5 -30 -10 -30 -6 0 -16 -11 -22 -25 -6 -14 -17 -25 -25 -25
-7 0 -13 -7 -13 -16 0 -12 6 -15 21 -11 12 3 22 0 26 -9 3 -8 16 -14 29 -14
18 0 24 -6 24 -21 0 -17 5 -20 21 -16 13 4 22 0 26 -11 4 -9 11 -20 17 -24 6
-4 1 -8 -11 -8 -18 0 -23 -6 -23 -24 0 -22 -16 -36 -41 -36 -5 0 -9 9 -9 20 0
11 -6 20 -14 20 -9 0 -16 12 -18 28 -2 19 -9 28 -25 30 -13 2 -23 10 -23 18 0
21 -32 54 -53 54 -28 0 -20 -64 9 -68 16 -2 20 -8 17 -23 -3 -10 -9 -19 -14
-19 -5 0 -9 -7 -9 -15 0 -8 5 -15 10 -15 6 0 10 -4 10 -10 0 -5 -9 -10 -20
-10 -11 0 -20 5 -20 10 0 6 -11 10 -25 10 -18 0 -25 -5 -25 -20 0 -11 4 -20 9
-20 5 0 11 -18 15 -40 5 -32 10 -40 29 -41 15 -1 11 -4 -12 -9 -19 -5 -38 -15
-43 -24 -12 -22 -35 -20 -41 4 -3 11 -1 20 4 20 5 0 9 7 9 16 0 8 7 22 15 30
22 23 18 43 -10 54 -23 9 -25 14 -22 56 3 25 12 55 21 65 20 24 20 36 -1 57
-12 12 -18 13 -21 4 -2 -6 -11 -12 -19 -12 -9 0 -13 6 -9 15 12 33 -24 64 -38
33 -5 -10 -7 -10 -12 0 -3 6 -14 12 -25 12 -14 0 -19 -7 -19 -30 0 -21 -5 -30
-17 -31 -33 -2 -58 3 -64 13 -4 5 -22 8 -40 7 -19 -2 -40 1 -47 5 -19 11 -62
-2 -62 -19 0 -8 5 -15 11 -15 6 0 17 -7 25 -15 9 -8 31 -15 50 -15 27 0 34 -4
34 -20 0 -11 -6 -20 -14 -20 -30 0 -76 -35 -76 -57 0 -13 6 -23 14 -23 18 0
10 -36 -10 -44 -8 -3 -14 -10 -14 -16 0 -6 -7 -18 -15 -26 -8 -9 -15 -24 -15
-35 0 -10 -4 -19 -10 -19 -5 0 -10 -7 -10 -15 0 -8 -4 -15 -10 -15 -6 0 -10
-28 -10 -65 0 -37 -4 -65 -10 -65 -16 0 -11 -37 6 -44 21 -8 24 -8 24 5 0 16
26 39 44 39 9 0 16 -8 16 -19 0 -30 -11 -51 -26 -51 -8 0 -14 -9 -14 -20 0
-11 7 -20 15 -20 8 0 15 8 15 18 0 12 3 13 8 5 4 -7 22 -15 38 -19 38 -7 64
15 64 57 0 22 -5 29 -20 29 -11 0 -20 5 -20 10 0 6 14 10 30 10 17 0 30 4 30
9 0 5 9 13 20 16 13 4 23 18 27 36 4 19 12 29 23 29 34 0 50 12 50 36 0 15 6
24 15 24 8 0 15 -4 15 -10 0 -5 11 -10 25 -10 17 0 25 -5 25 -18 0 -24 36 -62
58 -62 23 0 34 10 51 43 11 20 22 27 42 27 26 0 69 32 69 52 0 4 14 8 30 8 17
0 30 -4 30 -10 0 -5 7 -10 16 -10 19 0 54 37 54 57 0 7 7 13 15 13 10 0 15 11
15 35 0 38 8 42 59 24 17 -6 31 -17 31 -25 0 -19 39 -18 46 1 9 24 24 17 24
-10 0 -18 -5 -25 -20 -25 -11 0 -20 -7 -20 -15 0 -8 -4 -15 -10 -15 -5 0 -10
-4 -10 -10 0 -5 14 -10 31 -10 22 0 32 5 36 20 3 11 9 20 13 20 5 0 11 18 15
40 6 37 8 39 35 34 15 -4 31 -1 34 5 10 16 -3 51 -19 51 -8 0 -11 7 -8 20 3
11 11 20 18 20 9 0 13 14 13 44 -1 35 -5 46 -21 50 -29 7 -45 -3 -52 -35 -4
-16 -11 -29 -16 -29 -5 0 -9 -7 -9 -15 0 -10 -11 -15 -35 -15 -25 0 -35 4 -35
16 0 22 25 44 49 44 28 0 29 40 2 47 -11 3 -22 16 -25 29 -4 13 -11 24 -16 24
-6 0 -10 9 -10 20 0 16 7 20 30 20 20 0 30 5 30 15 0 8 -4 15 -9 15 -5 0 -11
18 -13 40 -3 38 -2 40 23 40 15 0 29 7 33 15 3 8 13 15 21 15 9 0 23 6 31 14
7 8 23 12 34 9 20 -5 21 -2 18 48 l-3 54 -52 3 c-38 2 -53 7 -53 17 0 8 -11
17 -25 21 -14 3 -25 10 -25 15 0 5 -12 10 -27 10 -17 1 -21 3 -10 6 18 5 24
33 6 33 -6 0 -17 7 -25 15 -10 9 -34 15 -65 15 -27 0 -49 5 -49 10 0 15 -62
12 -82 -4z"/>
<path d="M8398 19275 c-6 -14 -15 -25 -20 -25 -4 0 -8 -9 -8 -20 0 -11 5 -20
10 -20 6 0 10 -7 10 -15 0 -14 -23 -19 -75 -16 -33 2 -49 -15 -49 -53 0 -19 2
-37 5 -39 2 -3 25 -8 52 -11 43 -7 47 -6 47 13 0 24 28 29 48 9 23 -23 14 -48
-17 -48 -47 0 -61 -9 -61 -42 0 -17 -7 -36 -17 -43 -16 -12 -16 -14 -1 -29 9
-9 28 -16 42 -16 26 0 39 -29 14 -31 -88 -6 -111 -4 -116 13 -2 10 -8 18 -13
18 -5 0 -9 7 -9 15 0 8 5 15 10 15 6 0 10 9 10 21 0 17 -4 20 -28 14 -27 -6
-28 -4 -24 25 2 16 8 30 13 30 5 0 9 7 9 15 0 18 -36 20 -43 3 -4 -9 -8 -9
-14 -3 -32 39 -113 42 -113 4 0 -10 7 -19 15 -19 10 0 15 -10 15 -29 0 -25 4
-29 40 -35 34 -5 40 -10 40 -31 0 -16 -6 -25 -15 -25 -10 0 -15 -11 -15 -35 0
-29 4 -36 25 -41 26 -7 28 -10 25 -61 -1 -31 0 -32 49 -39 45 -6 51 -9 51 -30
0 -13 5 -24 10 -24 6 0 10 7 10 15 0 8 5 15 10 15 6 0 10 11 10 23 0 13 9 34
20 47 11 13 20 18 20 12 0 -7 9 -12 20 -12 15 0 20 7 20 25 0 16 6 25 15 25 8
0 15 9 15 20 0 11 7 20 15 20 8 0 15 9 15 20 0 11 -6 20 -14 20 -15 0 -32 37
-22 47 3 4 6 1 6 -5 0 -7 11 -12 25 -12 23 0 25 -3 25 -51 0 -42 3 -52 20 -56
24 -6 26 -19 5 -27 -21 -8 -19 -34 3 -39 16 -4 16 -5 0 -6 -13 -1 -20 -13 -24
-40 -7 -44 -26 -58 -49 -35 -17 18 -75 28 -91 17 -6 -4 -14 -29 -18 -55 -7
-44 -6 -48 13 -48 25 0 27 -13 6 -34 -20 -20 -20 -82 1 -90 33 -13 44 -6 44
24 0 23 4 30 20 30 29 0 29 -60 0 -67 -34 -9 -38 -63 -4 -63 21 0 17 -17 -5
-23 -15 -4 -19 -14 -18 -56 1 -42 4 -51 19 -51 11 0 18 7 18 20 0 17 7 20 40
20 29 0 40 4 40 15 0 8 -4 15 -10 15 -14 0 -12 29 3 44 7 8 23 17 35 20 14 5
22 15 22 31 0 24 -16 34 -53 36 -12 0 -16 3 -9 6 6 2 12 15 12 28 0 12 7 25
15 29 9 3 15 0 15 -8 0 -8 7 -17 16 -20 28 -11 54 -6 54 9 0 10 10 15 30 15
17 0 30 -4 30 -10 0 -5 14 -10 30 -10 17 0 30 5 30 10 0 6 9 10 20 10 11 0 20
-7 20 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -9 -15 -20 0 -16 7 -20 30 -20 23 0
31 -5 36 -25 4 -14 3 -29 0 -33 -4 -3 -10 4 -13 17 -5 21 -10 23 -47 18 -22
-3 -42 -11 -44 -16 -4 -13 -32 -15 -32 -2 0 5 -12 12 -27 16 -15 3 -31 11 -34
17 -5 8 -12 6 -23 -5 -21 -21 -20 -34 4 -42 11 -3 20 -13 20 -21 0 -8 4 -14 9
-14 6 0 11 -15 13 -32 3 -34 38 -55 38 -24 0 9 3 16 8 16 4 0 12 10 17 23 6
12 17 22 25 22 8 0 16 -10 18 -22 2 -15 -2 -23 -11 -23 -37 0 -29 -87 9 -96
13 -3 24 -12 24 -20 0 -8 6 -14 14 -14 7 0 16 -13 20 -30 4 -21 11 -30 26 -30
16 0 20 7 20 34 0 38 -21 66 -51 66 -12 0 -19 7 -19 20 0 11 -7 20 -15 20 -8
0 -15 7 -15 15 0 22 26 18 49 -7 16 -16 32 -21 68 -21 27 0 41 -3 33 -5 -23
-8 -30 -23 -30 -75 0 -35 4 -49 17 -54 17 -7 53 -39 53 -48 0 -3 -7 -5 -15 -5
-32 0 -10 -36 28 -45 15 -4 27 -11 27 -16 0 -23 31 -6 36 20 4 20 14 31 30 35
17 5 25 16 30 40 3 19 4 39 1 44 -8 12 29 42 53 42 11 0 20 6 20 14 0 9 17 16
45 20 25 4 45 11 45 17 0 20 28 7 34 -16 4 -14 12 -25 19 -25 8 0 11 -15 9
-50 -4 -45 -2 -50 17 -50 16 0 21 6 21 29 0 20 6 30 20 34 14 3 20 14 20 31 0
14 6 26 13 26 6 0 22 3 35 6 19 6 22 3 22 -24 0 -20 -7 -35 -19 -41 -11 -6
-21 -26 -25 -50 l-6 -40 -58 -6 c-86 -10 -92 -13 -92 -45 0 -22 -4 -28 -15
-24 -8 4 -15 13 -15 21 0 12 -8 14 -36 8 -53 -9 -76 -34 -68 -73 6 -27 11 -32
35 -32 19 0 29 -5 29 -15 0 -8 -6 -15 -14 -15 -8 0 -16 -6 -19 -14 -3 -7 -22
-16 -41 -20 -22 -4 -36 -13 -36 -21 0 -8 -4 -15 -10 -15 -5 0 -10 -11 -10 -25
0 -17 -6 -25 -17 -25 -29 -1 -53 -25 -53 -54 0 -23 -4 -26 -35 -26 -19 0 -35
-4 -35 -10 0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 -9 10 -20 10 -12 0 -20
-7 -20 -16 0 -17 -22 -14 -28 4 -2 7 3 12 12 12 13 0 16 11 16 50 0 38 -4 50
-15 50 -9 0 -18 7 -21 15 -4 8 -10 15 -16 15 -5 0 -16 -7 -24 -15 -9 -8 -24
-15 -35 -15 -11 0 -19 -7 -19 -17 0 -15 -9 -18 -48 -17 -26 0 -55 4 -65 9 -10
4 -20 5 -23 2 -11 -11 8 -67 22 -67 8 0 23 -9 33 -19 18 -18 19 -20 2 -35 -10
-9 -25 -16 -33 -16 -27 0 -48 -11 -48 -26 0 -8 5 -14 10 -14 6 0 10 -7 10 -15
0 -8 6 -15 14 -15 7 0 16 -9 19 -20 3 -11 12 -20 21 -20 9 0 16 -6 16 -13 0
-9 23 -12 80 -13 60 0 80 3 80 13 0 7 -11 13 -25 13 -14 0 -28 7 -31 15 -4 9
-19 15 -40 15 -19 0 -34 5 -34 10 0 6 6 10 14 10 8 0 17 6 19 13 3 7 6 4 6 -6
1 -17 8 -18 86 -12 57 4 85 3 85 -4 0 -6 7 -11 16 -11 12 0 15 -6 10 -24 -7
-29 12 -76 31 -76 7 0 13 -4 13 -10 0 -5 7 -10 15 -10 8 0 15 7 15 15 0 8 8
15 18 16 9 0 43 1 75 2 44 2 61 6 68 20 6 9 21 17 34 17 23 0 25 4 25 50 l0
50 -44 0 c-37 0 -45 4 -49 22 -4 12 -2 25 4 28 5 4 7 14 3 23 -4 11 0 19 10
23 20 8 21 50 1 58 -20 7 -8 36 16 36 20 0 59 -33 59 -50 0 -5 5 -10 10 -10 6
0 10 -7 10 -15 0 -8 11 -17 25 -21 14 -3 25 -10 25 -14 0 -5 8 -11 18 -13 9
-3 -2 -5 -25 -6 -41 -1 -43 -2 -43 -32 0 -46 40 -79 54 -44 11 29 26 15 26
-24 l0 -40 43 0 c71 0 77 5 77 57 0 26 -5 54 -10 62 -7 11 -4 16 13 20 16 4
23 14 25 38 3 33 19 43 28 17 4 -10 20 -15 50 -15 32 0 44 4 44 15 0 8 -4 15
-10 15 -5 0 -10 5 -10 10 0 6 7 10 15 10 8 0 15 -4 15 -10 0 -5 9 -10 20 -10
11 0 20 -7 20 -15 0 -9 12 -18 30 -21 21 -5 30 -12 30 -25 0 -15 -7 -19 -35
-19 -19 0 -35 -4 -35 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -9 -15 -20 0 -11 -6
-20 -14 -20 -15 0 -26 -21 -26 -51 0 -10 -4 -19 -10 -19 -5 0 -10 -13 -10 -29
0 -55 73 -54 85 0 4 17 13 33 20 35 7 3 15 18 18 33 4 20 14 31 32 35 14 4 25
11 25 16 0 6 9 10 20 10 17 0 20 -7 20 -45 0 -42 -2 -46 -29 -51 -20 -4 -31
-14 -35 -30 -5 -18 -17 -26 -51 -35 -25 -6 -45 -15 -45 -20 0 -5 -7 -9 -15 -9
-8 0 -15 -7 -15 -15 0 -12 -14 -15 -65 -15 -51 0 -65 3 -65 15 0 8 -9 15 -20
15 -11 0 -20 -7 -20 -15 0 -8 -7 -15 -15 -15 -11 0 -15 -11 -15 -40 0 -22 4
-40 10 -40 5 0 10 -28 12 -62 l3 -63 48 -3 c37 -2 47 0 47 12 0 9 -4 16 -10
16 -21 0 -9 30 15 36 20 5 25 13 25 35 0 38 25 48 51 20 11 -11 26 -21 34 -21
7 0 15 -6 17 -12 5 -15 30 6 41 35 5 14 19 17 72 17 l65 0 0 -31 c0 -22 -5
-32 -20 -36 -11 -3 -20 -14 -20 -24 0 -12 -7 -19 -19 -19 -11 0 -22 -6 -25
-12 -5 -10 -7 -10 -12 0 -3 6 -19 12 -35 12 -24 0 -29 -4 -29 -25 0 -18 -5
-25 -20 -25 -11 0 -20 -4 -20 -10 0 -5 7 -10 15 -10 8 0 15 -7 15 -15 0 -8 7
-15 15 -15 19 0 19 -16 0 -24 -20 -7 -42 2 -49 20 -3 8 -18 14 -34 14 -25 0
-30 -5 -35 -30 -6 -27 -27 -43 -27 -20 0 6 -15 10 -34 10 -29 0 -35 4 -41 27
-7 31 -21 43 -47 43 -13 0 -18 -8 -18 -29 0 -16 -5 -31 -11 -33 -13 -4 21 -68
36 -68 14 0 11 -73 -4 -78 -16 -5 -13 -32 4 -32 8 0 15 -4 15 -10 0 -16 27
-12 34 5 3 9 18 15 35 15 16 0 31 5 33 10 2 6 25 12 52 14 37 2 53 -2 66 -16
12 -14 25 -17 48 -12 18 4 32 13 32 20 0 19 29 18 36 -1 4 -8 17 -15 30 -15
15 0 24 -6 24 -15 0 -9 7 -18 15 -21 8 -4 15 -13 15 -21 0 -12 -9 -14 -46 -8
l-46 7 7 -40 c6 -37 5 -40 -14 -35 -14 3 -23 0 -27 -11 -3 -9 -12 -16 -20 -16
-8 0 -23 -9 -34 -20 -11 -11 -31 -22 -45 -26 -14 -3 -25 -10 -25 -15 0 -5 -5
-9 -11 -9 -13 0 -39 25 -39 39 0 5 -16 13 -37 17 -29 5 -39 3 -50 -11 -7 -11
-13 -13 -13 -7 0 7 -7 12 -15 12 -8 0 -15 5 -15 10 0 6 -7 10 -15 10 -22 0
-18 17 5 23 23 6 28 37 6 37 -8 0 -17 14 -21 30 -4 17 -11 30 -16 30 -5 0 -9
7 -9 15 0 9 -9 15 -25 15 -14 0 -25 -5 -25 -11 0 -6 -7 -18 -16 -27 -15 -15
-16 -13 -9 16 5 25 3 32 -9 32 -25 0 -39 -38 -32 -91 l7 -49 -30 0 c-16 0 -36
3 -45 6 -17 6 -23 54 -7 54 6 0 11 17 13 38 3 33 6 37 34 40 31 3 32 4 32 53
0 33 -4 49 -13 49 -28 0 -27 49 2 78 7 7 13 22 13 32 0 29 -71 37 -207 23 -57
-6 -73 -28 -28 -39 24 -6 36 -34 15 -34 -5 0 -10 -16 -10 -35 0 -35 0 -35 45
-35 25 0 45 -4 45 -10 0 -5 9 -10 19 -10 28 0 15 -39 -15 -46 -22 -6 -24 -11
-24 -76 0 -62 3 -71 26 -93 15 -14 31 -25 35 -25 5 0 9 -7 9 -16 0 -23 25 -44
52 -44 13 0 37 -9 53 -20 22 -14 39 -18 63 -14 41 8 122 -14 122 -32 0 -9 11
-14 30 -14 28 0 30 -3 30 -35 0 -19 -4 -35 -9 -35 -20 0 -41 13 -41 25 0 19
-35 28 -76 20 -21 -3 -34 -12 -34 -21 0 -8 -8 -18 -17 -21 -10 -4 -24 -13 -31
-20 -7 -7 -20 -13 -27 -13 -31 0 -43 -140 -19 -204 5 -12 0 -16 -21 -16 -26 0
-27 -2 -21 -35 6 -31 4 -35 -14 -35 -16 0 -20 -6 -20 -31 0 -18 -7 -37 -16
-43 -12 -9 -14 -19 -7 -46 12 -44 35 -49 67 -15 19 21 25 23 30 10 3 -8 13
-15 22 -15 13 0 15 6 11 29 -6 27 -3 30 25 36 18 4 32 15 35 26 3 10 12 19 19
19 8 0 14 4 14 9 0 5 14 12 30 16 17 4 30 13 30 20 0 7 -13 16 -30 20 -16 4
-30 13 -30 21 0 20 23 17 43 -6 20 -23 37 -26 37 -6 0 8 7 16 15 20 9 3 15 18
15 36 0 23 -4 30 -20 30 -28 0 -26 31 6 65 31 33 39 27 37 -31 -1 -37 1 -44
18 -44 10 0 19 -4 19 -10 0 -5 13 -10 29 -10 25 0 30 -5 35 -34 4 -19 12 -37
19 -39 7 -3 4 -6 -5 -6 -10 -1 -18 -8 -18 -17 0 -13 7 -15 31 -10 17 3 35 6
40 6 5 0 9 23 9 50 0 28 5 50 10 50 6 0 10 7 10 15 0 8 8 15 18 14 10 0 12 -3
5 -6 -7 -2 -13 -15 -13 -28 0 -23 17 -35 50 -35 16 0 17 -7 12 -66 l-5 -67 53
5 c28 3 56 12 62 19 7 11 11 11 15 2 2 -7 12 -13 21 -14 10 -1 -3 -5 -27 -10
-43 -8 -61 -21 -61 -46 0 -6 9 -14 19 -18 24 -7 36 -35 16 -35 -8 0 -15 7 -15
15 0 9 -9 15 -25 15 -21 0 -25 -5 -25 -30 0 -16 -4 -30 -10 -30 -5 0 -10 -13
-10 -30 0 -20 5 -30 15 -30 9 0 15 -9 15 -25 0 -16 6 -25 15 -25 8 0 15 -4 15
-10 0 -14 38 -12 43 3 7 19 14 6 22 -40 6 -36 4 -43 -9 -43 -9 0 -19 -7 -22
-15 -8 -20 -24 -19 -24 1 0 11 -6 14 -22 8 l-23 -6 23 19 c28 25 29 53 2 53
-11 0 -20 -6 -20 -13 0 -9 -10 -12 -30 -9 -16 2 -30 7 -30 11 0 4 -9 17 -20
29 -12 12 -16 22 -10 22 18 0 11 30 -7 31 -14 0 -15 2 -3 6 18 7 30 23 30 41
0 17 -20 15 -34 -3 -18 -24 -56 -8 -63 26 -6 29 -25 37 -51 21 -7 -5 -14 -31
-16 -58 -3 -27 -5 -52 -5 -56 -1 -4 -37 -10 -80 -14 -53 -4 -82 -11 -85 -20
-8 -21 -34 -17 -54 7 -9 12 -25 29 -34 38 -23 20 -23 33 2 54 11 9 20 12 20 7
0 -6 14 -10 30 -10 25 0 30 4 30 25 0 28 -32 65 -55 65 -8 0 -15 -7 -15 -15 0
-10 -10 -15 -28 -15 -15 0 -33 -5 -40 -12 -16 -16 -15 -45 1 -51 7 -3 4 -6 -7
-6 -16 -1 -18 -7 -14 -46 3 -27 1 -45 -4 -45 -6 0 -8 -9 -5 -21 3 -12 0 -22
-9 -26 -8 -3 -14 -16 -14 -28 0 -18 7 -25 30 -30 17 -4 30 -11 30 -16 0 -5 9
-9 20 -9 11 0 20 7 20 15 0 8 5 15 10 15 6 0 10 7 10 16 0 12 -7 14 -30 9 -23
-5 -30 -3 -30 9 0 27 54 20 70 -9 15 -29 70 -37 70 -10 0 8 7 15 15 15 8 0 15
6 15 14 0 13 41 26 81 26 15 0 19 -7 19 -35 0 -24 5 -35 15 -35 10 0 16 -16
21 -50 4 -32 11 -50 20 -50 8 0 14 -7 14 -15 0 -10 -10 -15 -29 -15 -25 0 -29
-4 -28 -22 1 -13 7 -29 14 -36 19 -19 17 -92 -2 -92 -8 0 -15 -4 -15 -10 0 -5
-11 -10 -23 -10 -21 0 -24 4 -21 38 3 53 3 52 17 52 10 0 13 16 11 58 -2 75
-2 72 -19 72 -10 0 -15 -10 -15 -30 0 -16 -4 -30 -9 -30 -5 0 -11 -10 -13 -22
-3 -20 -4 -21 -20 -5 -21 21 -58 22 -58 2 0 -8 6 -15 13 -15 7 0 18 -14 24
-31 11 -28 10 -32 -7 -36 -11 -3 -20 -11 -20 -18 0 -19 -30 -46 -41 -39 -18
11 -58 -6 -64 -26 -3 -11 -14 -20 -24 -20 -27 0 -44 -26 -37 -54 7 -29 -10
-38 -55 -29 -25 5 -35 1 -53 -18 -13 -14 -26 -31 -29 -39 -4 -11 -6 -11 -6 -2
-1 16 -26 42 -40 42 -5 0 -11 24 -13 53 l-3 52 -33 -3 c-19 -1 -40 -9 -49 -17
-8 -8 -23 -15 -34 -15 -10 0 -19 -7 -19 -15 0 -11 -11 -15 -42 -14 -24 1 -33
4 -20 6 36 7 25 63 -13 63 -2 0 -17 -14 -34 -31 -36 -35 -40 -59 -11 -66 11
-3 20 -12 20 -19 0 -9 11 -14 30 -14 27 0 30 -3 30 -34 0 -19 3 -41 6 -49 4
-11 -2 -16 -18 -19 -32 -4 -39 -63 -9 -73 15 -4 21 -15 21 -36 0 -22 5 -29 20
-29 13 0 20 7 20 20 0 23 18 27 26 5 7 -17 34 -21 34 -5 0 6 16 10 35 10 25 0
35 -4 35 -16 0 -9 7 -14 18 -12 23 5 19 62 -8 90 -26 28 -26 48 0 48 22 0 28
25 8 33 -10 4 -10 6 0 6 8 1 16 -8 19 -19 4 -17 14 -20 54 -20 38 0 49 -4 49
-16 0 -8 7 -22 15 -30 8 -9 15 -24 15 -35 0 -10 5 -19 10 -19 6 0 10 -6 10
-14 0 -8 10 -17 23 -19 l22 -4 -22 -2 c-16 -1 -23 -7 -23 -21 0 -16 7 -20 34
-20 33 0 34 1 41 49 4 28 13 52 21 55 8 3 14 19 14 40 0 34 -1 35 -39 33 -24
-1 -44 5 -55 16 -16 16 -16 17 3 17 17 0 21 6 21 31 0 28 1 29 16 15 8 -9 24
-16 35 -16 15 0 19 7 19 34 0 28 3 33 19 29 14 -4 18 -13 17 -46 0 -24 4 -42
12 -45 17 -5 15 -32 -3 -32 -16 0 -19 -17 -9 -44 8 -20 34 -21 34 -1 0 8 6 15
14 15 8 0 17 6 19 13 4 10 6 10 6 0 1 -7 12 -13 26 -13 16 0 25 -6 25 -15 0
-9 9 -15 21 -15 18 0 20 4 15 25 -4 14 -2 25 4 25 5 0 10 -4 10 -10 0 -5 11
-10 24 -10 15 0 26 -7 29 -20 3 -11 12 -20 21 -20 9 0 16 8 16 20 0 13 7 20
20 20 11 0 20 -7 20 -15 0 -9 9 -15 25 -15 14 0 25 -4 25 -10 0 -5 -4 -10 -10
-10 -5 0 -10 -11 -10 -25 0 -20 6 -27 30 -31 31 -7 42 -24 15 -24 -8 0 -15 -9
-15 -20 0 -11 -7 -20 -15 -20 -8 0 -15 5 -15 10 0 6 -11 10 -24 10 -14 0 -28
-4 -31 -10 -3 -5 -22 -10 -41 -10 -27 0 -34 -4 -34 -20 0 -14 7 -20 24 -20 19
0 25 -7 30 -35 7 -34 8 -35 56 -35 28 0 50 -4 50 -10 0 -5 16 -10 35 -10 24 0
35 -5 35 -15 0 -8 6 -15 13 -15 8 0 9 -3 1 -8 -6 -4 -14 -19 -18 -34 -3 -16
-11 -28 -16 -28 -6 0 -10 -9 -10 -20 0 -11 5 -20 10 -20 6 0 10 -13 10 -30 0
-16 5 -30 11 -30 15 0 39 26 39 43 0 9 6 17 13 20 8 2 12 25 11 71 -2 65 -1
67 20 62 11 -3 36 -6 54 -6 21 0 32 -5 32 -14 0 -8 11 -17 25 -20 27 -7 34
-26 10 -26 -8 0 -15 5 -15 10 0 6 -7 10 -15 10 -8 0 -15 -7 -15 -15 0 -8 -7
-15 -15 -15 -8 0 -15 -4 -15 -10 0 -5 -13 -10 -30 -10 -25 0 -30 -4 -30 -24 0
-13 6 -27 13 -30 10 -5 10 -7 0 -12 -7 -3 -13 -12 -13 -19 0 -7 6 -16 13 -18
7 -3 5 -6 -5 -6 -10 -1 -18 -8 -18 -17 0 -13 8 -15 35 -12 27 3 34 1 30 -10
-3 -7 -5 -18 -5 -23 0 -5 -7 -9 -15 -9 -8 0 -15 -4 -15 -10 0 -5 -13 -10 -30
-10 -36 0 -41 -26 -10 -53 23 -19 40 -22 40 -7 0 6 7 10 15 10 8 0 15 -4 15
-10 0 -5 5 -10 10 -10 6 0 10 7 10 15 0 22 21 18 27 -5 3 -11 9 -20 14 -20 5
0 9 -6 9 -14 0 -8 7 -22 15 -30 14 -14 13 -16 -9 -22 -14 -3 -28 -16 -32 -27
-4 -12 -11 -25 -17 -28 -5 -4 -12 -30 -15 -58 l-5 -51 -42 0 c-24 0 -45 -4
-47 -10 -5 -14 -141 -17 -146 -2 -5 17 -52 15 -52 -2 0 -8 6 -17 13 -19 6 -3
2 -6 -10 -6 -41 -2 -25 -45 20 -56 15 -4 27 -10 27 -14 0 -5 17 -13 38 -20 29
-10 48 -10 78 -2 54 15 63 14 57 -8 -4 -14 1 -20 18 -23 21 -3 22 -4 6 -20 -9
-9 -17 -21 -17 -27 0 -6 -6 -11 -12 -11 -7 0 -23 -3 -34 -6 -16 -4 -23 0 -27
17 -5 17 -11 20 -29 15 -13 -3 -33 -6 -45 -6 -14 0 -23 -6 -23 -15 0 -8 7 -15
16 -15 9 0 18 -9 21 -20 3 -11 9 -20 14 -20 5 0 9 -13 9 -30 0 -20 5 -30 15
-30 19 0 21 -56 3 -63 -11 -4 -16 -107 -9 -184 1 -16 71 -18 71 -2 0 13 67 79
80 79 6 0 10 13 10 29 0 20 -6 30 -20 34 -13 3 -18 10 -14 21 7 18 26 20 52 7
9 -5 25 -12 36 -15 14 -5 17 -13 12 -36 -7 -39 2 -60 26 -60 14 0 18 -5 14
-16 -3 -9 -6 -18 -6 -20 0 -2 -6 -4 -14 -4 -21 0 -28 -15 -20 -45 7 -27 31
-34 40 -10 7 17 54 21 54 5 0 -5 -7 -10 -15 -10 -8 0 -15 -5 -15 -11 0 -7 9
-9 23 -5 12 3 28 6 35 6 6 0 12 6 12 13 0 7 8 20 18 28 18 15 19 14 19 -28 l0
-43 -55 0 -55 0 6 -50 c5 -39 3 -50 -8 -50 -17 0 -35 -31 -35 -59 0 -12 -4
-21 -10 -21 -5 0 -10 -7 -10 -15 0 -8 4 -15 9 -15 14 0 24 -41 13 -52 -5 -5
-19 -7 -31 -3 -17 5 -21 15 -21 46 0 26 -5 39 -13 39 -7 0 -24 12 -36 26 -22
25 -22 26 -1 19 11 -3 34 -4 50 0 23 5 30 12 30 30 0 12 7 25 15 29 21 8 19
28 -6 50 -17 14 -41 19 -100 21 l-79 2 0 -49 c0 -34 -5 -50 -15 -54 -17 -7
-21 -64 -5 -64 6 0 10 -9 10 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -13 -10 -30 0
-20 5 -30 15 -30 8 0 15 -7 15 -15 0 -11 -11 -15 -39 -15 -71 0 -131 -12 -131
-26 0 -9 -11 -14 -29 -14 -16 0 -31 -4 -33 -9 -1 -5 -9 -3 -16 5 -11 12 -10
14 3 14 8 0 15 5 15 11 0 14 38 49 54 50 6 1 23 0 38 -1 63 -4 87 22 47 50
-12 8 -27 21 -35 28 -15 14 -44 16 -44 2 0 -5 -4 -10 -10 -10 -5 0 -10 7 -10
15 0 10 -11 15 -35 15 -24 0 -35 5 -35 15 0 8 5 15 10 15 6 0 10 7 10 15 0 8
9 15 19 15 33 0 51 11 51 31 0 15 -7 19 -31 19 -17 0 -37 7 -45 15 -25 24
-105 20 -128 -7 -13 -14 -15 -23 -8 -26 21 -7 12 -19 -18 -25 -33 -7 -38 -21
-15 -43 8 -9 15 -24 15 -35 0 -10 -7 -19 -15 -19 -9 0 -15 -10 -16 -22 -1 -17
-2 -19 -6 -5 -2 9 -13 17 -24 17 -26 0 -53 -26 -45 -45 3 -9 0 -12 -10 -8 -7
3 -14 9 -14 13 0 5 -13 11 -30 15 -16 4 -30 13 -30 21 0 8 -9 14 -20 14 -16 0
-20 -7 -20 -30 0 -21 -5 -30 -16 -30 -20 0 -53 -39 -54 -62 0 -25 -35 -23 -59
3 -26 28 -56 19 -47 -15 4 -18 1 -25 -14 -29 -17 -4 -19 -13 -17 -73 1 -46 -2
-69 -10 -71 -18 -6 -16 -21 6 -45 15 -15 34 -21 80 -23 34 -1 61 1 61 6 0 5
-7 9 -15 9 -8 0 -15 5 -15 10 0 6 -7 10 -15 10 -8 0 -15 7 -15 15 0 9 -7 18
-15 21 -28 11 -15 24 25 24 22 0 40 -3 40 -8 0 -13 54 -67 80 -79 l25 -12 -32
0 c-18 -1 -33 -5 -33 -10 0 -5 -9 -13 -20 -16 -12 -4 -20 -15 -20 -30 0 -16
-7 -25 -25 -29 -16 -4 -25 -13 -25 -26 0 -17 7 -20 44 -20 38 0 45 3 50 25 4
14 11 25 16 25 6 0 10 6 10 14 0 22 29 46 47 39 17 -7 53 -39 53 -48 0 -3 -7
-5 -15 -5 -8 0 -15 5 -15 10 0 6 -9 10 -21 10 -13 0 -20 -6 -18 -17 3 -35 -1
-43 -26 -43 -18 0 -25 -5 -25 -20 0 -16 7 -20 33 -20 18 0 39 -6 46 -12 11
-10 14 -10 18 0 6 14 33 16 33 2 0 -5 7 -10 15 -10 8 0 15 -8 15 -19 0 -34
-100 -43 -127 -11 -10 11 -30 20 -46 20 -20 0 -30 -6 -34 -20 -3 -11 -11 -20
-18 -20 -7 0 -16 -6 -19 -12 -5 -10 -7 -10 -12 0 -3 7 -25 12 -55 12 l-49 0 0
-34 c0 -43 -21 -52 -37 -14 -7 15 -19 28 -28 28 -8 0 -15 5 -15 10 0 15 -57
12 -63 -2 -3 -8 -6 -6 -6 5 -1 22 -61 25 -61 2 0 -28 -20 -15 -27 16 -4 24
-10 29 -24 26 -15 -4 -19 0 -19 19 0 20 -5 24 -30 24 -20 0 -30 -5 -30 -15 0
-8 -7 -15 -15 -15 -8 0 -15 -9 -15 -20 0 -13 7 -20 18 -20 15 0 18 -6 14 -29
-3 -25 0 -29 30 -36 18 -4 27 -9 21 -12 -7 -2 -13 -11 -13 -19 0 -19 -39 -18
-46 1 -4 8 -12 15 -20 15 -8 0 -14 10 -14 25 0 14 -6 25 -12 25 -27 0 -40 -19
-42 -61 l-3 -44 -45 3 c-51 4 -59 -7 -50 -66 5 -35 32 -45 32 -11 0 11 7 27
15 35 13 13 17 12 35 -6 26 -26 26 -50 0 -50 -14 0 -20 -7 -20 -21 0 -20 4
-21 42 -16 23 3 45 13 50 21 5 9 22 16 38 16 20 0 30 5 30 15 0 10 10 15 30
15 23 0 30 4 30 20 0 11 -4 20 -10 20 -5 0 -10 7 -10 15 0 8 4 15 10 15 5 0
12 11 16 25 5 20 13 25 36 25 32 0 58 -20 58 -44 0 -9 8 -16 19 -16 19 0 31
-18 31 -46 0 -8 -9 -14 -20 -14 -11 0 -20 5 -20 10 0 21 -30 9 -36 -15 -3 -14
-10 -25 -15 -25 -5 0 -9 -6 -9 -14 0 -9 17 -16 46 -21 26 -3 53 -9 60 -12 16
-6 19 -33 3 -33 -14 0 -39 -25 -39 -39 0 -6 -4 -11 -10 -11 -5 0 -10 -9 -10
-20 0 -11 -4 -20 -10 -20 -5 0 -10 -13 -10 -30 0 -16 5 -30 10 -30 6 0 10 -4
10 -10 0 -5 -11 -10 -25 -10 -20 0 -27 -6 -31 -30 -4 -16 -10 -30 -15 -30 -5
0 -9 -9 -9 -20 0 -16 7 -20 30 -20 23 0 30 4 30 20 0 11 5 20 10 20 6 0 10 9
10 20 0 15 7 20 25 20 15 0 25 6 25 14 0 8 13 17 30 21 29 6 47 35 35 56 -8
13 -56 3 -67 -14 -5 -8 -8 -6 -8 6 0 9 6 17 14 17 15 0 26 21 26 51 0 23 18
25 26 4 4 -8 18 -15 34 -15 28 0 29 2 35 65 7 63 8 65 36 65 34 0 48 -19 26
-35 -18 -13 -23 -35 -8 -35 5 0 11 -13 15 -30 4 -24 11 -30 30 -30 19 0 25 6
28 28 3 23 8 27 41 30 20 2 37 7 37 13 0 5 5 9 10 9 6 0 10 -7 10 -15 0 -8 5
-15 11 -15 8 0 10 -14 6 -45 -5 -36 -4 -45 8 -45 8 0 15 7 15 15 0 23 31 18
37 -6 4 -15 13 -20 37 -19 54 3 56 2 56 -36 0 -29 -3 -35 -16 -30 -9 3 -19 6
-24 6 -4 0 -10 9 -13 21 -4 13 -12 20 -24 17 -14 -2 -19 -14 -21 -50 -2 -29
-8 -48 -18 -52 -8 -3 -14 0 -14 8 0 8 -6 17 -12 19 -8 3 -6 6 5 6 29 2 19 29
-14 36 -30 7 -56 -3 -31 -12 21 -8 13 -23 -13 -23 -22 0 -25 4 -25 35 0 36
-15 44 -54 29 -18 -7 -22 -64 -5 -64 13 0 59 -41 59 -52 0 -5 -8 -8 -18 -8
-10 0 -28 -9 -40 -20 -18 -17 -22 -18 -22 -5 0 8 7 15 15 15 20 0 19 26 -1 34
-25 10 -34 7 -34 -9 0 -10 -10 -15 -27 -15 -16 0 -46 -2 -68 -5 -22 -3 -34 -3
-27 1 7 3 12 24 12 50 0 45 0 45 -32 42 -31 -3 -33 -6 -36 -45 -3 -37 -6 -42
-33 -48 -29 -7 -35 -19 -23 -49 9 -23 23 -20 51 11 20 21 27 23 34 12 13 -21
11 -39 -6 -39 -8 0 -15 -8 -16 -17 0 -11 -3 -13 -6 -5 -10 25 -33 12 -33 -18
0 -16 -4 -30 -10 -30 -5 0 -10 -9 -10 -20 0 -11 -4 -20 -10 -20 -17 0 -11 -25
10 -48 11 -12 20 -26 20 -32 0 -15 57 -12 63 4 8 21 -4 100 -17 108 -7 5 -4 8
7 8 9 0 17 4 17 9 0 5 9 11 20 14 15 4 20 0 20 -13 0 -10 6 -21 13 -24 8 -3 7
-8 -5 -16 -25 -16 -24 -66 2 -73 16 -4 20 -14 20 -50 0 -40 3 -46 25 -51 14
-4 30 -4 35 -1 4 3 6 -4 3 -15 -3 -11 -9 -20 -14 -20 -5 0 -9 -6 -9 -14 0 -8
-9 -18 -20 -21 -23 -7 -27 -35 -5 -35 9 0 15 -9 15 -24 0 -57 57 -44 66 15 l7
39 63 0 c36 0 64 -4 64 -10 0 -5 -7 -10 -15 -10 -28 0 -15 -28 15 -35 17 -4
30 -13 30 -20 0 -10 17 -15 57 -17 53 -3 56 -4 40 -20 -24 -24 -21 -38 8 -38
14 0 28 -7 31 -15 4 -9 19 -15 35 -15 16 0 29 -4 29 -10 0 -14 27 -12 33 3 3
6 6 2 6 -10 1 -13 6 -23 11 -23 6 0 10 -7 10 -15 0 -26 37 -17 44 10 6 23 25
36 26 18 1 -5 3 -32 5 -60 3 -48 2 -53 -17 -53 -19 0 -20 -4 -14 -47 3 -27 11
-49 16 -51 6 -2 10 -12 10 -23 0 -14 -7 -19 -25 -19 -18 0 -25 -5 -25 -20 0
-11 7 -20 15 -20 8 0 15 -4 15 -10 0 -5 -9 -10 -20 -10 -13 0 -20 7 -20 20 0
16 -7 20 -30 20 -23 0 -30 -4 -30 -20 0 -15 -7 -20 -25 -20 -18 0 -25 5 -25
20 0 17 -7 20 -45 20 l-45 0 0 -50 c0 -49 1 -50 30 -50 17 0 30 -4 30 -10 0
-5 -4 -10 -10 -10 -5 0 -10 -7 -10 -15 0 -8 -9 -15 -20 -15 -31 0 -90 -62 -90
-94 0 -28 24 -36 34 -11 7 19 46 20 46 1 0 -8 -15 -17 -35 -20 -21 -4 -35 -13
-35 -21 0 -18 82 -14 88 4 6 17 31 13 54 -9 l22 -20 -31 0 c-18 0 -33 -6 -37
-15 -3 -8 -12 -15 -21 -15 -8 0 -15 -4 -15 -9 0 -5 -10 -11 -21 -14 -19 -5
-21 -11 -15 -41 6 -30 4 -35 -9 -30 -8 3 -15 16 -15 28 0 37 -12 66 -26 66 -8
0 -14 -7 -14 -15 0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 5 15 10 15 16 0
12 27 -5 34 -17 6 -9 36 10 36 16 0 29 50 16 63 -12 12 -101 0 -101 -14 0 -5
-12 -9 -28 -9 -29 0 -67 -34 -57 -50 8 -14 85 -13 85 1 0 5 5 7 11 4 7 -5 5
-13 -5 -24 -9 -10 -16 -30 -16 -44 0 -15 -4 -27 -10 -27 -5 0 -10 -7 -10 -15
0 -8 7 -15 15 -15 9 0 19 -8 22 -17 4 -10 11 -21 17 -25 8 -6 6 -11 -3 -17
-13 -8 -13 -12 -1 -26 13 -15 12 -17 -5 -14 -13 3 -21 14 -23 32 -2 15 -8 27
-13 27 -5 0 -9 7 -9 15 0 11 -11 15 -38 15 -26 0 -42 6 -52 20 -8 11 -20 20
-28 20 -7 0 -15 7 -18 15 -4 8 -15 15 -25 15 -10 0 -19 -7 -19 -15 0 -16 -9
-19 -34 -9 -20 8 -21 24 -1 24 23 0 19 62 -5 83 -11 9 -20 23 -20 31 0 8 -7
17 -16 20 -22 9 -24 8 -24 -9 0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -8 5
-15 10 -15 6 0 10 -9 10 -20 0 -16 -7 -20 -30 -20 -34 0 -42 -25 -10 -33 23
-6 27 -27 5 -27 -9 0 -15 -9 -15 -25 0 -18 -5 -25 -20 -25 -21 0 -24 -9 -14
-59 4 -17 13 -31 20 -31 8 0 14 -9 14 -20 0 -18 4 -20 43 -14 23 4 44 11 45
16 5 11 42 10 42 -2 0 -5 7 -10 15 -10 8 0 15 -9 15 -20 0 -13 7 -20 20 -20
13 0 20 -7 20 -20 0 -13 -7 -20 -19 -20 -25 0 -32 -21 -24 -75 3 -28 11 -45
19 -45 19 0 18 -27 -2 -34 -9 -3 -29 -6 -45 -6 -19 0 -29 -5 -29 -15 0 -8 -9
-15 -20 -15 -10 0 -23 -6 -27 -14 -12 -21 -53 -31 -53 -13 0 8 7 17 15 21 8 3
15 12 15 21 0 11 10 15 36 15 28 0 35 3 32 18 -2 11 -14 18 -36 20 -17 2 -32
7 -32 13 0 5 -16 9 -36 9 -26 0 -43 -7 -60 -25 -13 -14 -32 -25 -44 -25 -13 0
-20 -7 -20 -20 0 -11 5 -20 12 -20 9 0 9 -3 0 -12 -17 -17 -15 -58 3 -58 8 0
15 -6 15 -14 0 -8 11 -17 25 -20 24 -6 36 -36 15 -36 -5 0 -10 -7 -10 -15 0
-8 5 -15 10 -15 16 0 12 -27 -5 -34 -8 -3 -15 -17 -15 -31 0 -14 -4 -25 -10
-25 -5 0 -10 7 -10 15 0 8 -7 15 -15 15 -12 0 -15 -12 -14 -55 2 -51 4 -55 26
-55 14 0 23 -6 23 -15 0 -11 11 -15 40 -15 29 0 40 4 40 15 0 8 -7 15 -15 15
-8 0 -15 5 -15 11 0 6 7 17 15 25 23 24 18 44 -10 44 -14 0 -25 4 -25 9 0 11
25 21 51 21 10 0 19 5 19 11 0 7 11 0 25 -15 14 -15 35 -26 50 -26 18 0 29 -6
32 -20 6 -23 43 -27 43 -5 0 8 5 15 10 15 6 0 10 8 11 18 0 10 3 12 6 5 2 -7
15 -13 28 -13 28 0 45 -26 45 -69 0 -30 2 -31 44 -31 36 0 48 -5 65 -26 13
-16 27 -24 36 -20 10 3 15 -1 15 -12 0 -29 -13 -39 -58 -46 -24 -3 -50 -13
-58 -21 -17 -16 -19 -45 -3 -45 6 0 4 -7 -5 -15 -8 -8 -19 -15 -25 -15 -6 0
-11 -9 -11 -20 0 -11 7 -20 15 -20 8 0 15 -9 15 -20 0 -14 7 -20 24 -20 20 0
26 -8 37 -45 11 -35 18 -45 35 -45 13 0 24 8 27 20 4 15 14 20 36 20 28 0 31
3 31 30 0 23 -5 30 -19 30 -11 0 -27 -7 -35 -16 -9 -8 -16 -11 -16 -5 0 6 -7
11 -15 11 -8 0 -15 -4 -15 -10 0 -15 -17 -12 -24 5 -8 21 21 45 55 45 26 0 29
3 29 30 0 17 5 30 10 30 6 0 10 -4 10 -10 0 -5 12 -10 28 -10 15 0 34 -5 42
-10 13 -9 13 -11 0 -20 -8 -5 -21 -10 -27 -10 -7 0 -13 -9 -13 -20 0 -16 7
-20 30 -20 17 0 30 -4 30 -10 0 -5 7 -10 15 -10 8 0 15 -7 15 -16 0 -9 9 -18
20 -21 11 -3 20 -12 20 -19 0 -8 5 -14 11 -14 14 0 -11 -24 -33 -33 -13 -5
-18 -1 -18 13 0 14 -7 20 -22 20 -39 0 -68 -12 -68 -26 0 -8 -10 -14 -21 -14
-20 0 -21 -4 -15 -46 7 -51 21 -70 62 -81 29 -8 29 -8 -10 -13 -38 -5 -64 -30
-31 -30 13 0 15 -7 9 -35 -5 -29 -3 -35 11 -35 10 0 26 10 37 22 14 16 21 19
30 10 17 -17 48 -15 48 3 0 16 35 21 45 5 9 -15 -6 -60 -21 -60 -8 0 -14 5
-14 10 0 6 -7 10 -15 10 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -15 -10 -19 0 -19
-26 0 -34 8 -3 15 -10 15 -16 0 -13 37 -50 50 -50 6 0 10 6 10 13 0 7 9 20 20
30 11 9 20 26 20 37 0 11 5 20 10 20 6 0 10 -9 10 -20 0 -26 30 -26 37 -1 7
27 33 15 33 -14 0 -13 -6 -25 -12 -27 -9 -3 -7 -10 6 -23 14 -14 15 -20 6 -23
-7 -3 -19 3 -26 12 -7 10 -30 20 -52 23 l-39 5 -5 -47 c-7 -60 -15 -67 -60
-53 -31 9 -37 16 -42 49 -6 40 -20 49 -40 26 -15 -19 -106 -11 -106 9 0 8 -4
14 -10 14 -5 0 -10 7 -10 15 0 9 -7 18 -15 21 -16 7 -21 34 -6 34 5 0 12 14
16 30 4 16 11 30 16 30 5 0 9 7 9 15 0 22 -86 21 -108 -2 -16 -16 -17 -14 -20
22 -2 23 -11 46 -23 56 -10 9 -19 21 -19 28 0 6 -4 11 -10 11 -5 0 -10 9 -10
19 0 45 -55 37 -65 -9 -4 -17 -13 -30 -21 -30 -17 0 -19 -26 -1 -33 7 -3 5 -6
-5 -6 -10 -1 -18 6 -18 14 0 10 -17 16 -60 21 -58 6 -60 8 -60 36 0 16 -6 31
-14 34 -8 3 -17 16 -20 28 -6 25 -38 36 -107 36 -39 0 -39 0 -39 -40 0 -39 1
-40 35 -40 24 0 35 -5 35 -15 0 -10 10 -15 30 -15 17 0 30 -4 30 -10 0 -5 11
-10 25 -10 14 0 25 -5 25 -11 0 -20 29 -39 59 -39 26 0 31 -4 31 -25 0 -27 24
-35 34 -10 3 8 10 15 16 15 5 0 10 -7 10 -15 0 -19 7 -19 26 0 23 23 41 18 47
-14 7 -31 27 -41 90 -41 36 0 37 -1 22 -16 -12 -13 -15 -26 -10 -55 6 -32 4
-39 -9 -39 -20 0 -21 -17 -2 -36 18 -17 72 -19 79 -1 3 6 6 -11 6 -40 1 -31 6
-53 14 -57 8 -3 7 -8 -5 -16 -12 -7 -18 -24 -18 -45 0 -19 -4 -35 -9 -35 -5 0
-13 -10 -16 -22 -6 -15 -1 -26 15 -38 26 -20 21 -55 -11 -65 -30 -10 -24 -95
7 -95 8 0 14 -6 14 -14 0 -11 19 -17 70 -22 50 -5 70 -11 70 -21 0 -7 -6 -16
-14 -19 -30 -12 -30 -104 0 -104 8 0 14 -4 14 -10 0 -16 57 -12 64 5 4 10 20
15 51 15 25 0 45 5 45 10 0 6 7 10 15 10 8 0 15 -7 15 -15 0 -8 7 -15 15 -15
8 0 15 -7 15 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -9 -15 -20 0 -17 -7 -20 -45
-20 -43 0 -45 -1 -45 -30 0 -16 5 -30 10 -30 6 0 10 -6 10 -14 0 -8 5 -16 12
-18 16 -6 1 -58 -17 -58 -11 0 -15 11 -15 36 0 35 -1 36 -34 32 -38 -4 -46 7
-46 65 0 31 -20 57 -44 57 -11 0 -16 9 -16 29 0 31 -14 41 -61 41 -21 0 -29
-5 -30 -17 0 -11 -3 -13 -6 -5 -6 14 -33 16 -33 2 0 -5 -9 -10 -20 -10 -11 0
-20 5 -20 10 0 35 -75 -16 -93 -63 -8 -22 -57 -23 -57 -1 0 20 24 44 44 44 9
0 16 8 16 19 0 11 9 23 20 26 13 4 20 15 20 31 0 19 -5 24 -25 24 -16 0 -25
-6 -25 -15 0 -12 -15 -15 -75 -15 -58 0 -75 -3 -75 -14 0 -8 5 -16 12 -18 9
-3 9 -6 0 -12 -7 -4 -12 -2 -12 3 0 6 -9 11 -19 11 -32 0 -35 19 -7 47 26 26
38 93 16 93 -5 0 -10 7 -10 15 0 9 -9 15 -25 15 -16 0 -25 6 -25 15 0 8 5 15
10 15 6 0 10 7 10 15 0 10 -10 15 -30 15 -27 0 -30 -3 -30 -30 0 -20 5 -30 15
-30 25 0 17 -27 -11 -34 -33 -9 -64 1 -64 20 0 8 -9 14 -20 14 -17 0 -20 -7
-20 -39 0 -34 -3 -38 -20 -34 -17 5 -20 0 -20 -26 0 -25 4 -31 21 -31 12 0 30
-11 41 -25 14 -18 29 -25 54 -25 19 0 34 -4 34 -10 0 -5 7 -10 15 -10 8 0 15
-7 15 -15 0 -9 10 -15 28 -16 16 -1 20 -3 10 -6 -10 -2 -18 -8 -18 -13 0 -13
-55 -22 -94 -14 -38 7 -50 24 -16 24 13 0 20 7 20 20 0 26 -30 26 -60 0 -20
-17 -22 -23 -12 -46 7 -14 12 -36 12 -49 0 -20 -5 -25 -25 -25 -14 0 -25 -4
-25 -10 0 -20 -18 -9 -24 15 -8 30 -36 65 -52 65 -12 0 -24 44 -24 86 0 13 -4
24 -9 24 -5 0 -12 12 -16 27 -3 15 -12 32 -18 36 -9 6 -9 10 1 14 24 10 13 40
-18 47 -25 5 -30 10 -30 36 0 23 -4 30 -20 30 -24 0 -27 -32 -4 -54 8 -9 11
-16 5 -16 -14 0 -14 -27 -1 -32 6 -2 13 -21 17 -43 3 -22 12 -44 19 -50 13
-10 17 -41 15 -103 -1 -25 -6 -35 -21 -39 -11 -3 -20 -9 -20 -14 0 -5 -7 -9
-16 -9 -14 0 -16 -8 -11 -46 2 -26 9 -49 15 -53 6 -3 14 -20 18 -36 10 -40 44
-64 44 -30 0 20 16 19 24 -1 9 -24 7 -34 -7 -34 -18 0 -67 -42 -67 -57 0 -6
-7 -13 -15 -17 -8 -3 -15 -12 -15 -21 0 -8 -7 -15 -15 -15 -8 0 -15 -4 -15
-10 0 -5 -7 -10 -16 -10 -21 0 -41 -22 -47 -51 -3 -19 -5 -17 -6 9 -1 38 -21
62 -50 62 -14 0 -21 -6 -21 -20 0 -16 -7 -20 -37 -20 -79 0 -108 10 -122 41
-14 32 -46 39 -65 15 -7 -10 -21 -13 -38 -9 -32 6 -38 23 -8 23 24 0 26 16 5
37 -13 12 -27 14 -73 7 -52 -8 -57 -12 -60 -35 -3 -23 1 -27 31 -33 19 -4 42
-4 51 0 12 5 16 0 16 -20 0 -16 6 -26 15 -26 8 0 15 -4 15 -10 0 -5 11 -10 24
-10 19 0 23 -4 19 -20 -3 -11 -12 -20 -20 -20 -8 0 -21 -6 -29 -14 -16 -17
-19 -56 -4 -56 21 0 9 -18 -16 -24 -15 -4 -33 -14 -42 -22 -13 -14 -13 -17 -1
-25 10 -7 11 -11 2 -17 -6 -4 -14 -18 -18 -32 -5 -21 -1 -27 25 -38 31 -14 41
-42 15 -42 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -15 -10 -9 0 -15 -9 -15 -24 0
-22 -2 -23 -40 -13 -40 11 -66 3 -40 -13 9 -6 13 -24 11 -56 l-2 -47 33 7 c18
3 38 12 43 20 6 7 22 15 38 18 21 4 27 1 27 -12 0 -18 19 -30 47 -30 17 0 43
-24 43 -39 0 -6 16 -11 35 -11 21 0 35 5 35 13 0 8 2 9 8 1 4 -6 17 -14 30
-18 13 -4 22 -15 22 -26 0 -16 -5 -19 -31 -13 -31 6 -31 6 -24 -28 4 -19 18
-44 31 -56 13 -12 24 -33 24 -47 0 -14 5 -28 10 -31 6 -3 10 -29 10 -57 l0
-51 -40 7 c-36 6 -40 4 -40 -14 0 -11 -4 -20 -10 -20 -5 0 -10 -7 -10 -15 0
-16 26 -21 32 -6 2 4 13 12 26 15 16 6 22 3 22 -8 0 -10 9 -16 25 -16 15 0 25
6 25 14 0 8 10 18 21 21 35 11 69 -34 69 -90 0 -32 -4 -45 -14 -45 -7 0 -16 9
-19 20 -4 15 -14 20 -42 20 -51 0 -70 -21 -56 -62 6 -17 11 -35 11 -39 0 -12
-33 2 -46 19 -16 22 -56 6 -52 -21 3 -29 -9 -47 -33 -47 -11 0 -19 -7 -19 -16
0 -11 -6 -14 -22 -8 -13 3 -24 11 -26 15 -5 14 -40 11 -45 -3 -4 -10 -6 -10
-6 0 -1 6 -8 12 -16 12 -8 0 -15 9 -15 20 0 15 -9 22 -30 26 -23 5 -30 12 -30
30 0 15 -6 24 -15 24 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -15 -10 -10 0 -15 -10
-15 -26 0 -23 3 -26 30 -22 20 3 30 0 30 -9 0 -7 5 -13 10 -13 16 0 12 -18
-10 -40 -24 -24 -30 -25 -30 -5 0 8 -9 15 -20 15 -11 0 -20 -7 -20 -15 0 -8 6
-15 14 -15 8 0 17 -7 20 -16 9 -22 8 -24 -9 -24 -8 0 -15 4 -15 10 0 5 -11 12
-25 16 -14 3 -25 12 -25 20 0 9 -11 14 -30 14 -28 0 -30 3 -30 35 0 41 3 42
29 14 13 -14 26 -19 40 -14 24 7 28 30 6 39 -8 3 -15 13 -15 22 0 12 -6 15
-25 10 -17 -4 -27 -2 -31 8 -7 17 -49 29 -75 21 -23 -7 -25 -43 -4 -51 8 -4
15 -15 15 -25 0 -22 29 -59 47 -59 7 0 13 -7 13 -15 0 -10 10 -15 30 -15 20 0
30 -5 30 -15 0 -9 6 -18 14 -21 24 -9 28 -33 4 -26 -15 4 -19 0 -18 -14 5 -47
1 -54 -35 -54 -24 0 -35 -5 -35 -15 0 -11 11 -15 40 -15 29 0 40 4 40 15 0 19
19 19 26 0 4 -8 12 -15 20 -15 19 0 18 -26 -1 -34 -8 -3 -15 -12 -15 -21 0
-10 -10 -15 -30 -15 -16 0 -30 5 -30 10 0 22 -38 9 -44 -14 -8 -33 2 -56 25
-56 10 0 19 -4 19 -10 0 -5 14 -10 30 -10 17 0 30 -4 30 -10 0 -5 14 -10 30
-10 17 0 30 5 30 10 0 6 11 10 25 10 24 0 35 15 15 22 -15 5 0 78 16 78 8 0
14 -7 14 -15 0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -9 9 -15 25 -15 23 0
25 4 25 40 0 22 -4 40 -10 40 -15 0 -12 19 5 36 13 14 15 12 15 -15 0 -23 5
-31 18 -31 13 0 17 -9 19 -36 2 -38 17 -64 39 -64 8 0 14 -9 14 -20 0 -11 7
-20 15 -20 9 0 15 -9 15 -25 0 -21 -6 -26 -39 -31 -25 -4 -41 -13 -44 -23 -2
-9 -2 1 0 22 3 23 1 37 -6 37 -6 0 -11 -7 -11 -15 0 -8 -7 -15 -15 -15 -8 0
-15 -4 -15 -10 0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 11 0 8 -14 10 -44 6 -42
-6 -44 -5 -50 23 -4 22 -11 30 -26 30 -11 0 -20 -4 -20 -10 0 -5 -7 -10 -15
-10 -8 0 -15 7 -15 15 0 8 -7 15 -15 15 -11 0 -15 -12 -15 -49 0 -43 3 -50 23
-54 16 -3 14 -5 -9 -6 -24 -1 -33 -7 -38 -23 -9 -33 -8 -35 14 -41 23 -6 27
-27 5 -27 -8 0 -15 -7 -15 -15 0 -8 -4 -15 -10 -15 -20 0 -9 -29 13 -33 22 -4
22 -4 -2 -6 -19 -1 -22 -5 -17 -24 3 -12 6 -28 6 -34 0 -7 9 -13 20 -13 11 0
20 -7 20 -15 0 -8 7 -15 15 -15 10 0 15 10 15 30 0 17 5 30 10 30 6 0 10 7 10
15 0 8 5 15 11 15 6 0 8 9 4 20 -5 17 -2 20 24 20 17 0 31 -4 31 -10 0 -5 -4
-10 -10 -10 -5 0 -10 -9 -10 -20 0 -11 -7 -20 -15 -20 -8 0 -15 -7 -15 -15 0
-8 5 -15 11 -15 6 0 3 -7 -5 -16 -20 -20 -20 -34 0 -34 18 0 44 23 44 39 0 6
7 11 15 11 11 0 15 -11 15 -40 0 -22 -4 -40 -10 -40 -13 0 -13 -27 0 -32 5 -1
12 -22 16 -45 6 -39 5 -43 -15 -43 -11 0 -21 -6 -21 -14 0 -8 -4 -16 -9 -18
-4 -1 -11 -20 -15 -40 -7 -37 -6 -38 24 -38 16 0 30 -4 30 -10 0 -5 12 -10 26
-10 23 0 26 3 22 30 -4 24 -1 30 14 30 11 0 18 -7 18 -20 0 -11 5 -20 10 -20
6 0 10 -13 10 -30 0 -25 4 -30 25 -30 21 0 25 5 25 30 0 17 5 30 10 30 6 0 10
7 10 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15 0 8 -4 15 -10 15 -6 0 0 10 11 21
17 17 23 19 30 8 41 -68 69 -78 69 -24 0 19 -4 35 -10 35 -5 0 -10 7 -10 15 0
8 -5 15 -10 15 -7 0 -6 8 1 22 17 32 35 48 53 48 9 0 16 8 16 20 0 12 -7 20
-16 20 -22 0 -41 21 -49 58 l-7 32 56 0 c43 0 56 3 56 15 0 8 5 15 11 15 15 0
49 39 49 57 0 7 5 13 10 13 6 0 10 8 11 18 0 11 4 7 10 -10 18 -49 72 -49 67
0 -2 18 2 27 13 30 19 3 32 -41 22 -77 -4 -15 -1 -21 11 -21 19 0 22 -29 4
-31 -7 -1 -22 -2 -33 -3 -15 -1 -20 -8 -20 -29 0 -20 -8 -31 -27 -42 -16 -7
-28 -10 -28 -4 0 14 -17 11 -34 -7 -20 -20 -21 -73 -1 -80 8 -4 15 -12 15 -19
0 -7 6 -16 13 -18 6 -3 -5 -6 -25 -6 -21 -1 -38 -5 -38 -10 0 -5 -9 -11 -20
-14 -15 -4 -20 0 -20 15 0 16 -5 19 -22 14 -13 -3 -29 -6 -35 -6 -7 0 -13 -7
-13 -15 0 -8 7 -15 15 -15 8 0 15 -4 15 -10 0 -5 -4 -10 -10 -10 -5 0 -10 -4
-10 -10 0 -5 7 -10 16 -10 12 0 14 -7 9 -30 -5 -23 -3 -30 9 -30 9 0 16 -7 16
-15 0 -8 -6 -15 -14 -15 -8 0 -22 -8 -32 -17 -10 -10 -36 -19 -63 -21 -25 -1
-49 -8 -54 -15 -7 -9 -10 -9 -14 1 -6 16 -23 16 -23 -1 0 -9 -19 -13 -62 -14
-35 0 -57 1 -50 4 14 6 16 33 2 33 -5 0 -10 9 -10 20 0 22 -20 26 -51 10 -13
-8 -25 -7 -39 0 -11 6 -31 8 -45 4 -19 -5 -25 -13 -25 -35 0 -24 4 -29 25 -29
24 0 44 -24 31 -37 -3 -3 -29 -5 -57 -4 -44 1 -50 -1 -47 -16 3 -15 13 -18 63
-16 41 2 61 7 67 18 5 8 16 15 23 15 8 0 15 7 15 16 0 14 1 14 16 0 13 -13 13
-18 2 -29 -7 -7 -21 -16 -32 -20 -13 -5 -17 -15 -14 -36 4 -28 3 -29 -29 -24
-21 4 -33 2 -33 -6 0 -6 7 -11 16 -11 12 0 15 -6 10 -22 -3 -13 -6 -27 -6 -31
0 -4 -27 -5 -59 -1 -52 6 -60 4 -64 -12 -5 -19 -5 -19 -6 0 -1 13 -6 17 -21
13 -11 -3 -27 1 -34 9 -8 8 -19 14 -24 14 -5 0 -12 9 -15 20 -4 17 0 20 24 20
19 0 29 5 29 15 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 8 -9 15 -20 15 -11 0
-20 -4 -20 -10 0 -5 -4 -10 -10 -10 -5 0 -10 7 -10 15 0 8 -9 15 -20 15 -11 0
-20 -7 -20 -15 0 -8 -6 -15 -14 -15 -8 0 -16 16 -19 42 -3 24 -5 43 -4 44 1 1
23 4 50 7 42 6 47 5 47 -13 0 -20 15 -25 44 -14 20 8 21 34 1 34 -29 0 -15 18
19 24 28 5 46 21 46 42 0 2 -11 4 -25 4 -14 0 -25 5 -25 10 0 6 11 10 25 10
25 0 31 13 19 44 -4 9 -18 16 -35 16 -27 0 -28 -2 -35 -60 -4 -39 -11 -60 -20
-60 -9 0 -11 10 -7 35 7 46 -9 46 -53 0 -18 -19 -37 -35 -43 -35 -6 0 -11 -9
-11 -20 0 -11 -7 -20 -15 -20 -19 0 -31 -48 -16 -63 6 -6 11 -24 11 -39 0 -15
5 -28 12 -28 6 0 4 -8 -7 -20 -19 -21 -14 -68 8 -82 23 -15 47 -8 47 12 0 26
16 26 23 0 4 -14 14 -20 35 -20 31 0 42 -17 42 -67 0 -42 82 -43 94 -1 3 13 6
33 6 44 0 14 8 24 23 28 39 10 95 6 109 -8 8 -7 24 -17 36 -20 12 -4 22 -12
22 -17 0 -21 29 -7 38 19 9 24 10 22 10 -23 0 -27 -2 -42 -5 -32 -6 21 -63 24
-63 3 0 -7 -7 -19 -15 -26 -19 -16 -19 -30 0 -30 8 0 15 -4 15 -10 0 -5 -7
-10 -15 -10 -8 0 -15 -9 -15 -20 0 -14 7 -20 23 -20 44 0 67 12 67 34 0 12 9
29 20 39 11 9 20 12 20 7 0 -15 19 -12 37 6 13 12 27 14 62 9 51 -8 61 -13 45
-23 -6 -4 -14 -19 -18 -34 -4 -19 -13 -28 -26 -28 -38 0 -70 -22 -70 -48 0
-23 32 -62 52 -62 4 0 8 -7 8 -15 0 -8 -7 -15 -16 -15 -20 0 -44 24 -44 44 0
9 -7 16 -15 16 -8 0 -15 -4 -15 -10 0 -5 -9 -10 -20 -10 -15 0 -20 7 -20 25 0
20 -5 25 -24 25 -13 0 -38 3 -55 6 -24 5 -31 3 -31 -10 0 -9 -4 -16 -10 -16
-5 0 -10 -7 -10 -15 0 -8 -7 -15 -16 -15 -9 0 -18 -9 -21 -20 -3 -11 -10 -20
-16 -20 -6 0 -27 -17 -47 -38 -32 -35 -37 -37 -50 -24 -10 12 -11 17 -2 20 7
2 12 14 12 27 0 45 9 53 52 49 32 -3 46 1 60 16 19 20 25 80 8 80 -5 0 -10 7
-10 15 0 8 7 15 16 15 12 0 15 6 10 23 -3 12 -6 25 -6 30 0 14 -58 7 -64 -8
-3 -8 -12 -15 -21 -15 -8 0 -15 -4 -15 -10 0 -5 -13 -10 -29 -10 -26 0 -30 -3
-29 -30 1 -17 -2 -33 -5 -36 -3 -3 -34 -8 -69 -11 -61 -6 -62 -6 -44 12 14 14
17 27 12 54 -3 20 -6 44 -6 54 0 11 -8 17 -22 17 -39 0 -68 -12 -68 -26 0 -8
-7 -14 -15 -14 -10 0 -15 -10 -15 -27 0 -52 -11 -73 -36 -73 -15 0 -24 6 -24
15 0 8 -11 17 -25 21 -16 4 -25 13 -25 25 0 14 -7 19 -29 19 -39 0 -61 -9 -61
-25 0 -16 22 -25 61 -25 16 0 29 -4 29 -10 0 -5 -4 -10 -10 -10 -5 0 -10 -7
-10 -15 0 -8 5 -15 10 -15 16 0 12 -27 -5 -34 -9 -3 -15 -18 -15 -35 0 -34
-23 -46 -70 -36 -23 5 -30 3 -30 -9 0 -9 -4 -16 -8 -16 -5 0 -9 -15 -10 -34
-2 -50 30 -59 67 -19 22 24 29 27 39 17 15 -17 15 -31 0 -36 -26 -9 6 -28 48
-28 24 0 44 -4 44 -10 0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -21
0 -19 7 10 34 32 30 33 50 1 44 -18 -4 -26 0 -30 15 -7 28 1 37 30 37 13 0 24
4 24 9 0 5 9 11 20 14 13 3 20 0 20 -9 0 -8 7 -17 15 -20 17 -7 21 -34 5 -34
-27 0 -3 -50 30 -63 9 -3 10 -6 3 -6 -23 -2 -13 -29 12 -35 24 -6 36 -26 15
-26 -5 0 -10 -6 -10 -14 0 -20 -52 -28 -67 -10 -6 9 -30 18 -52 21 -33 5 -41
3 -41 -11 0 -10 9 -16 25 -16 16 0 25 -6 25 -15 0 -8 9 -15 20 -15 11 0 20 -4
20 -9 0 -5 54 -9 120 -9 l120 -1 0 25 c0 13 5 24 10 24 6 0 10 7 10 15 0 8 4
15 10 15 5 0 23 13 38 30 49 50 62 41 62 -40 l0 -71 68 3 c61 3 67 5 70 25 2
13 7 23 12 23 5 0 11 12 13 28 2 15 5 3 6 -25 1 -49 3 -53 25 -53 19 0 25 7
30 35 6 32 9 34 22 20 20 -20 41 -19 48 3 3 9 4 0 1 -21 -3 -31 -1 -37 15 -37
28 0 50 11 50 24 0 6 9 19 20 29 19 16 20 16 20 -6 0 -16 9 -28 25 -35 36 -17
453 -16 473 1 12 9 16 9 19 0 4 -10 50 -13 189 -13 177 0 184 1 184 20 0 13 7
20 20 20 13 0 20 -7 20 -20 0 -19 7 -20 186 -20 181 0 185 0 179 20 -10 33 22
37 54 6 l28 -26 276 1 c263 2 277 3 277 20 0 15 8 19 35 19 23 0 35 5 35 14 0
8 7 16 15 20 12 4 15 -3 15 -35 l0 -39 205 1 c113 1 205 5 205 10 0 5 -4 9 -9
9 -5 0 -7 18 -4 40 5 35 3 41 -18 49 -18 7 -19 10 -6 10 21 1 23 31 2 31 -8 0
-15 5 -15 10 0 6 25 10 55 10 44 0 55 -3 55 -16 0 -12 6 -15 23 -10 12 3 48 6
80 6 54 0 57 1 57 25 0 14 -6 25 -13 25 -7 0 -23 9 -35 20 -23 22 -42 26 -42
10 0 -5 -25 -10 -55 -10 -54 0 -55 0 -49 26 5 20 2 26 -15 31 -11 3 -21 12
-21 20 0 12 -7 14 -32 9 -18 -3 -35 -11 -39 -17 -9 -16 -150 -14 -159 1 -4 7
-5 21 -3 31 4 14 0 19 -15 19 -11 0 -23 -6 -25 -12 -4 -10 -8 -9 -16 2 -8 10
-13 12 -18 4 -5 -6 -18 -14 -30 -18 -13 -4 -23 -15 -23 -26 0 -14 -5 -18 -17
-13 -10 4 -23 13 -28 21 -12 14 -65 7 -65 -9 0 -5 -7 -9 -15 -9 -8 0 -15 5
-15 10 0 6 7 10 16 10 9 0 18 9 21 20 4 14 14 20 34 20 25 0 29 4 29 29 0 16
-6 36 -14 44 -8 7 -16 25 -18 38 -2 17 -10 25 -27 27 -46 7 -62 42 -19 42 15
0 18 8 18 45 0 31 -5 47 -15 51 -8 4 -15 12 -15 20 0 8 -9 14 -20 14 -11 0
-20 5 -20 10 0 6 -15 11 -32 11 -18 1 -25 3 -15 6 9 2 17 11 17 19 0 11 6 13
23 8 12 -3 24 -11 25 -15 5 -12 52 -11 52 1 0 6 14 10 30 10 23 0 30 4 30 20
0 11 -6 20 -14 20 -7 0 -16 14 -20 30 -5 27 -36 44 -36 20 0 -20 -15 -9 -28
20 -13 28 -42 42 -42 20 0 -5 -22 -10 -50 -10 -43 0 -50 -3 -50 -20 0 -14 7
-20 23 -20 23 0 55 -16 47 -23 -3 -3 -25 -7 -50 -8 -42 -4 -45 -2 -46 21 -1
50 -3 52 -33 45 -26 -5 -29 -3 -34 25 -9 42 -41 42 -35 0 3 -20 0 -30 -9 -30
-7 0 -13 9 -13 20 0 13 -7 20 -20 20 -11 0 -20 5 -20 10 0 6 -8 10 -17 10 -14
0 -18 9 -20 36 -2 44 -4 47 -36 39 -18 -5 -28 -16 -32 -36 -4 -16 -13 -29 -21
-29 -8 0 -14 -7 -14 -15 0 -8 -7 -15 -15 -15 -10 0 -15 11 -15 35 0 28 -4 35
-20 35 -16 0 -20 7 -20 34 0 55 8 66 41 59 25 -5 31 -2 36 16 3 14 13 21 29
21 15 0 24 6 24 15 0 8 5 15 10 15 6 0 10 7 10 15 0 19 24 19 42 1 8 -7 24
-17 36 -20 16 -6 22 -15 22 -38 0 -28 2 -30 28 -24 23 6 21 3 -13 -18 -22 -14
-50 -26 -62 -26 -14 0 -23 -6 -23 -15 0 -9 9 -15 24 -15 13 0 26 -5 28 -11 2
-5 36 -12 76 -14 l72 -4 0 35 c0 19 5 34 10 34 6 0 10 13 10 29 0 29 -14 41
-51 41 -16 0 -18 5 -13 28 14 64 12 62 74 62 64 0 74 6 64 45 -5 20 -12 25
-36 25 -21 0 -31 6 -35 20 -4 14 -14 20 -33 20 -20 0 -29 6 -34 22 -8 32 -7
38 4 38 6 0 10 -7 10 -15 0 -11 11 -15 40 -15 28 0 40 4 40 14 0 8 -6 17 -12
19 -7 3 8 6 35 6 26 1 47 6 47 11 0 6 7 10 15 10 11 0 15 -12 15 -46 0 -35 5
-50 23 -65 36 -30 58 -25 55 13 -3 28 1 34 20 36 17 3 22 -2 22 -17 0 -12 5
-21 10 -21 6 0 10 -7 10 -15 0 -8 -7 -15 -15 -15 -21 0 -19 -29 2 -37 25 -10
53 -36 53 -49 0 -7 6 -15 13 -17 7 -3 5 -6 -5 -6 -10 -1 -18 -8 -18 -16 0 -8
-4 -15 -10 -15 -5 0 -10 -11 -10 -25 0 -23 4 -25 44 -25 31 0 46 -5 50 -16 9
-22 -4 -64 -19 -64 -7 0 -16 -7 -19 -16 -9 -24 -7 -54 4 -54 6 0 10 -7 10 -15
0 -8 5 -15 10 -15 6 0 10 -9 10 -20 0 -11 7 -20 15 -20 8 0 15 -7 15 -15 0 -8
-6 -15 -13 -15 -10 0 -14 -24 -15 -100 -1 -82 1 -100 13 -100 8 0 15 -4 15
-10 0 -14 27 -12 33 3 4 9 6 9 6 0 2 -23 29 -14 35 11 5 19 14 25 39 28 28 3
32 7 35 35 3 30 1 33 -22 33 -16 0 -26 -6 -26 -15 0 -8 -6 -15 -14 -15 -9 0
-12 7 -9 20 3 13 14 20 29 20 15 0 24 6 24 15 0 9 -9 15 -25 15 -21 0 -25 5
-26 33 -2 44 1 61 12 65 5 2 9 12 9 23 0 10 5 19 11 19 6 0 17 7 25 15 9 8 29
15 45 15 19 0 29 5 29 15 0 14 -59 22 -137 17 -17 0 -23 5 -23 19 0 10 -4 19
-10 19 -5 0 -9 12 -9 28 1 16 3 20 6 10 3 -12 14 -18 34 -18 16 0 29 -4 29
-10 0 -5 9 -10 20 -10 26 0 35 39 20 84 -9 28 -17 36 -36 36 -13 0 -24 -6 -24
-12 0 -10 -3 -9 -9 1 -5 8 -29 15 -57 16 -27 1 -52 3 -56 4 -11 2 -10 41 1 41
5 0 12 20 15 45 7 41 10 45 42 51 27 5 34 11 34 30 0 15 6 24 15 24 8 0 15 -6
15 -14 0 -8 6 -17 13 -20 10 -5 10 -7 0 -12 -7 -3 -13 -20 -13 -38 0 -19 -8
-38 -20 -49 -11 -9 -20 -12 -20 -7 0 6 -9 10 -20 10 -13 0 -20 -7 -20 -20 0
-16 7 -20 33 -20 66 0 77 8 77 55 0 47 14 60 62 56 16 -2 30 4 36 15 7 12 26
19 65 22 46 4 56 8 61 26 6 26 -3 68 -18 79 -8 6 -7 10 2 14 17 7 15 43 -2 43
-8 0 -22 -7 -30 -16 -15 -14 -16 -14 -16 0 0 9 7 16 15 16 29 0 15 28 -17 35
-17 4 -38 14 -46 21 -20 20 -42 17 -42 -6 0 -12 7 -20 16 -20 13 0 15 -6 9
-27 -9 -38 -9 -38 -71 -35 -41 2 -56 -1 -60 -13 -10 -26 -34 -17 -40 15 -7 33
-24 38 -48 15 -8 -8 -19 -15 -25 -15 -6 0 -11 -7 -11 -15 0 -8 -4 -15 -10 -15
-5 0 -10 5 -10 10 0 6 -11 10 -25 10 -18 0 -26 -7 -31 -25 -7 -27 -19 -32 -28
-12 -5 9 -7 9 -12 0 -9 -20 -21 -15 -28 12 -5 19 -13 25 -34 25 -42 0 -72 11
-72 25 0 8 -13 16 -30 20 -16 4 -30 11 -30 16 0 17 -21 9 -50 -21 -16 -17 -34
-30 -40 -30 -5 0 -10 -7 -10 -15 0 -8 -7 -15 -15 -15 -8 0 -15 5 -15 10 0 6
-7 10 -15 10 -9 0 -15 9 -15 25 0 16 6 25 15 25 8 0 15 5 15 10 0 16 22 12 35
-6 8 -11 19 -14 33 -10 31 10 28 36 -3 36 -21 0 -27 7 -37 45 -7 25 -18 45
-25 45 -7 0 -13 9 -13 20 0 11 -7 20 -15 20 -8 0 -15 7 -15 15 0 8 -7 15 -16
15 -13 0 -15 -7 -10 -32 3 -18 11 -35 18 -39 8 -5 6 -12 -5 -24 -10 -9 -17
-12 -17 -6 0 17 -48 13 -70 -6 -11 -10 -20 -26 -20 -36 0 -22 -18 -42 -48 -51
-19 -7 -22 -4 -22 23 0 24 -4 31 -20 31 -16 0 -20 -7 -20 -30 0 -21 -5 -30
-16 -30 -9 0 -18 -9 -21 -20 -3 -11 -9 -20 -14 -20 -5 0 -9 -7 -9 -15 0 -11
-11 -15 -40 -15 -22 0 -40 -4 -40 -10 0 -5 -13 -10 -30 -10 -25 0 -30 4 -30
25 0 18 -5 25 -20 25 -11 0 -20 -4 -20 -10 0 -5 -7 -10 -15 -10 -8 0 -15 5
-15 10 0 6 -8 10 -17 11 -14 0 -15 2 -3 6 8 3 17 9 20 12 9 10 49 31 61 31 6
0 17 7 25 15 9 8 24 15 35 15 14 0 19 7 19 30 0 20 -5 30 -15 30 -8 0 -15 7
-15 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15 0 8 7 15 16 15 11 0 15 6 11 20 -7
26 -4 25 31 -10 33 -33 86 -41 96 -15 3 8 12 15 21 15 10 0 15 10 15 29 0 23
5 30 27 34 37 7 43 2 43 -36 0 -22 -3 -28 -9 -19 -13 21 -46 10 -49 -16 -2
-12 1 -22 7 -22 6 0 11 -7 11 -15 0 -12 13 -15 55 -15 32 0 55 4 55 11 0 14
25 39 39 39 6 0 11 16 11 35 0 24 -5 35 -15 35 -8 0 -15 6 -15 14 0 8 -8 17
-17 19 -17 4 -17 5 0 6 10 0 26 8 34 17 14 13 14 17 2 24 -18 12 -5 23 28 24
28 1 28 2 28 69 0 44 4 67 12 67 6 0 13 4 15 9 2 4 13 12 26 15 16 5 22 3 22
-7 0 -9 15 -17 40 -21 35 -5 40 -10 46 -41 5 -30 10 -35 35 -35 35 0 36 6 4
36 -35 32 -31 44 14 44 22 0 42 5 44 13 4 9 6 9 6 0 1 -7 10 -13 21 -13 16 0
20 7 20 31 0 22 -5 32 -20 36 -11 3 -20 9 -20 14 0 5 -16 9 -35 9 -19 0 -35
-5 -35 -11 0 -23 -30 -39 -73 -39 -60 0 -67 20 -22 63 19 19 35 39 35 44 0 6
10 13 21 16 18 5 20 11 14 34 -3 15 -7 29 -8 30 -2 1 -33 3 -70 5 -45 2 -67 7
-67 16 0 6 -7 12 -15 12 -12 0 -15 14 -15 63 0 50 4 66 20 80 28 23 39 21 46
-8 7 -27 34 -35 34 -10 0 8 9 15 20 15 14 0 20 -7 20 -21 0 -19 4 -21 33 -16
17 3 26 3 20 0 -23 -9 -14 -32 15 -35 22 -2 28 -9 30 -33 2 -16 9 -33 15 -37
9 -6 9 -10 0 -16 -6 -4 -14 -25 -18 -46 l-8 -39 31 6 c37 8 67 35 76 70 9 38
-2 57 -34 57 -24 0 -27 3 -23 25 3 13 1 37 -4 52 -13 41 -5 73 18 73 14 0 19
7 19 26 0 14 7 32 15 40 13 14 15 13 15 -10 0 -14 5 -26 10 -26 6 0 10 -9 10
-19 0 -11 9 -23 20 -26 11 -3 20 -12 20 -20 0 -19 28 -45 50 -45 10 0 20 9 23
20 3 11 12 20 21 20 9 0 16 5 16 10 0 6 7 10 15 10 8 0 15 -4 15 -10 0 -5 8
-10 18 -11 12 0 13 -2 3 -6 -19 -8 -51 -41 -51 -53 0 -5 -11 -10 -24 -10 -44
0 -52 -19 -51 -127 1 -104 12 -133 49 -133 9 0 16 -8 16 -20 0 -31 27 -23 34
10 7 36 26 40 26 5 0 -18 5 -25 20 -25 17 0 20 7 20 50 l0 50 -45 0 c-25 0
-45 5 -45 10 0 6 11 10 25 10 16 0 25 6 25 15 0 8 6 15 13 15 6 0 22 3 35 6
18 5 22 2 22 -15 0 -11 -4 -21 -10 -21 -15 0 -12 -67 3 -74 10 -5 10 -7 0 -12
-7 -3 -13 -14 -13 -24 0 -29 -11 -50 -26 -50 -9 0 -14 -12 -14 -34 0 -44 9
-66 26 -66 8 0 14 -10 14 -26 0 -35 21 -53 61 -53 89 0 124 31 49 43 -22 3
-40 11 -40 16 0 6 5 10 10 10 6 0 10 7 10 15 0 10 10 15 30 15 17 0 30 5 30
10 0 6 -11 10 -25 10 -14 0 -25 5 -25 10 0 6 -9 10 -19 10 -13 0 -21 -9 -26
-30 -9 -40 -50 -43 -59 -4 -10 37 2 63 30 70 19 5 24 13 24 38 0 17 5 38 10
46 6 10 10 11 10 3 0 -7 7 -13 15 -13 10 0 15 10 15 29 0 22 6 31 23 37 12 4
25 12 29 18 4 6 8 -4 8 -21 0 -23 5 -33 15 -33 10 0 15 -10 15 -31 0 -22 5
-32 20 -36 16 -4 20 -14 20 -48 0 -34 -4 -44 -20 -48 -14 -4 -20 -14 -20 -35
0 -17 -6 -32 -15 -36 -11 -4 -15 -20 -15 -56 0 -38 4 -50 15 -50 8 0 15 -9 15
-20 0 -13 7 -20 18 -20 17 0 19 8 17 74 -2 59 1 73 11 69 8 -3 14 -9 14 -14 0
-5 9 -9 20 -9 14 0 20 7 20 23 0 13 13 33 30 47 36 31 38 50 5 50 -21 0 -25 5
-25 30 0 20 -5 30 -15 30 -8 0 -15 9 -15 20 0 11 5 20 10 20 6 0 10 16 10 35
0 25 -4 35 -16 35 -9 0 -18 9 -21 20 -4 16 -14 20 -44 20 -22 0 -39 5 -39 10
0 6 -9 10 -19 10 -13 0 -21 8 -23 22 -2 17 -11 24 -30 26 -33 4 -34 6 -8 32
24 24 40 26 40 5 0 -8 8 -15 18 -15 10 0 23 -5 30 -12 9 -9 33 -13 135 -17 9
-1 17 -8 17 -16 0 -8 5 -15 10 -15 6 0 10 -7 10 -15 0 -8 -4 -15 -10 -15 -5 0
-10 -7 -10 -15 0 -10 10 -15 30 -15 19 0 30 5 30 14 0 9 7 12 20 9 11 -3 20
-9 20 -13 0 -4 20 -13 45 -20 43 -12 61 -30 30 -30 -8 0 -15 -7 -15 -15 0 -8
7 -15 15 -15 8 0 15 5 15 10 0 6 9 10 20 10 17 0 19 -5 14 -25 -7 -28 1 -32
22 -10 9 8 24 15 35 15 10 0 19 5 19 10 0 6 9 10 19 10 11 0 26 6 33 13 7 7
21 16 31 20 11 4 17 14 15 24 -4 23 -38 30 -38 9 0 -9 -4 -16 -10 -16 -5 0
-10 16 -10 36 0 23 -7 43 -18 53 -11 9 -22 33 -26 53 -6 35 -9 37 -41 36 -34
-1 -34 -2 -33 -45 1 -42 -10 -70 -19 -46 -8 22 -52 63 -68 63 -12 0 -14 8 -9
40 5 33 4 40 -10 40 -10 0 -16 9 -16 25 0 14 5 25 10 25 6 0 10 12 10 26 0 27
0 27 -60 21 -59 -7 -59 -6 -43 13 10 11 30 20 45 20 24 0 28 4 28 30 0 17 -4
30 -10 30 -16 0 -12 27 5 34 21 8 19 43 -5 63 -24 21 -26 33 -5 33 8 0 15 -7
15 -15 0 -22 27 -18 40 5 6 12 21 20 35 20 16 0 25 6 25 15 0 8 -4 15 -10 15
-5 0 -10 7 -10 15 0 25 -31 31 -48 9 -10 -13 -16 -15 -19 -6 -3 6 -11 12 -19
12 -19 0 -18 26 1 34 9 3 42 3 74 0 53 -6 58 -5 64 15 3 11 12 21 21 21 11 0
16 9 16 30 0 23 -5 31 -24 36 -14 3 -30 3 -35 -1 -6 -3 -11 1 -11 9 0 9 -4 16
-10 16 -5 0 -10 9 -10 19 0 30 37 37 71 12 15 -12 33 -21 39 -21 5 0 10 -13
10 -30 0 -16 -4 -30 -10 -30 -5 0 -10 -9 -10 -20 0 -13 9 -22 25 -26 15 -4 28
-16 31 -30 5 -21 12 -24 50 -24 24 0 44 5 44 10 0 19 31 10 54 -15 13 -14 30
-25 39 -25 9 0 19 -9 22 -20 6 -19 35 -29 35 -12 0 5 11 15 25 22 18 9 25 22
25 42 0 15 -4 28 -9 28 -5 0 -12 14 -16 30 -5 22 -14 32 -33 37 -15 3 -30 10
-33 15 -3 5 -29 11 -57 15 -44 5 -52 3 -52 -11 0 -9 -4 -16 -10 -16 -5 0 -10
14 -10 30 0 33 -14 38 -46 16 -20 -14 -20 -13 -20 72 0 48 -4 98 -7 112 -4 14
-4 19 -1 13 3 -7 12 -13 20 -13 19 0 18 57 -1 76 -8 9 -15 22 -15 30 0 8 7 17
15 20 8 4 16 16 16 28 1 16 2 17 6 4 3 -11 14 -18 29 -18 18 0 24 5 24 23 0
13 5 28 11 34 9 9 9 31 1 85 -7 46 -8 74 -2 76 6 2 10 10 10 18 0 8 5 14 10
14 6 0 10 -4 10 -10 0 -5 7 -10 15 -10 8 0 15 -7 15 -15 0 -11 11 -15 38 -15
26 0 42 -6 52 -20 8 -11 22 -20 31 -20 28 0 49 -11 49 -26 0 -8 -4 -14 -10
-14 -5 0 -10 -16 -10 -35 0 -31 -3 -35 -25 -35 -14 0 -25 -4 -25 -9 0 -5 -11
-12 -25 -15 -17 -4 -25 -13 -25 -28 0 -19 -4 -20 -56 -15 -31 3 -62 3 -70 0
-19 -7 -18 -33 1 -33 8 0 15 -4 15 -10 0 -5 7 -10 15 -10 8 0 15 -7 15 -15 0
-9 14 -16 38 -20 97 -16 92 -13 92 -56 0 -34 4 -41 27 -50 15 -5 38 -8 50 -4
16 4 23 1 23 -10 0 -10 10 -15 34 -15 26 0 35 5 40 23 9 35 7 47 -9 47 -8 0
-15 4 -15 9 0 4 -20 11 -45 14 -49 5 -66 14 -48 25 6 4 14 22 18 40 4 19 13
32 22 32 10 0 14 6 10 20 -4 14 0 20 12 20 21 0 46 29 55 63 3 15 11 27 16 27
16 0 12 40 -6 57 -11 11 -14 26 -10 46 3 16 6 38 6 48 0 23 45 27 54 4 3 -9
19 -15 41 -15 19 0 35 -4 35 -10 0 -5 -7 -10 -15 -10 -19 0 -19 -7 -1 -26 18
-17 32 -18 40 -1 5 10 7 10 12 0 7 -15 84 -18 84 -3 0 6 9 10 19 10 11 0 23 5
26 10 3 6 12 10 18 9 9 0 9 -2 0 -6 -15 -6 -17 -33 -3 -33 6 0 10 -7 10 -15 0
-8 7 -15 15 -15 8 0 15 -7 15 -15 0 -11 11 -15 36 -15 38 0 57 -13 68 -48 5
-13 16 -22 28 -22 17 0 20 5 16 35 -4 32 -2 35 23 35 19 0 30 -6 34 -20 8 -26
35 -26 35 0 0 14 5 18 17 13 11 -4 12 -8 5 -11 -17 -5 -15 -32 3 -32 8 0 15 6
15 14 0 7 14 16 31 20 47 9 59 7 59 -9 0 -8 -4 -15 -10 -15 -5 0 -12 -16 -16
-35 -4 -21 -13 -37 -25 -40 -18 -6 -19 -13 -13 -86 7 -76 6 -79 -15 -79 -17 0
-21 -6 -21 -30 0 -23 5 -30 19 -30 11 0 23 9 26 20 4 13 15 20 31 20 24 0 54
20 54 36 0 4 -7 12 -17 19 -9 7 -13 17 -9 23 11 19 26 14 26 -8 0 -13 7 -20
20 -20 11 0 20 -4 20 -10 0 -5 16 -10 36 -10 l37 0 -6 47 c-5 35 -4 43 4 31 5
-10 17 -18 24 -18 8 0 15 -9 15 -20 0 -11 5 -20 10 -20 6 0 10 -15 10 -34 0
-28 -4 -34 -26 -39 -16 -3 -28 0 -31 9 -9 23 -60 16 -88 -11 -14 -14 -25 -30
-25 -35 0 -6 -4 -10 -9 -10 -5 0 -12 -16 -15 -35 -4 -19 -11 -35 -16 -35 -20
0 -9 -28 13 -34 35 -9 47 -7 47 9 0 8 7 15 15 15 21 0 19 27 -2 34 -10 2 -1 2
21 -1 25 -4 41 -1 46 7 12 20 50 -6 50 -35 0 -13 6 -26 13 -28 7 -3 5 -6 -5
-6 -10 -1 -18 -8 -18 -16 0 -8 -9 -15 -20 -15 -13 0 -20 -7 -20 -20 0 -13 7
-20 20 -20 11 0 20 -4 20 -10 0 -5 -4 -10 -10 -10 -5 0 -12 -16 -16 -35 -3
-19 -12 -35 -19 -35 -11 0 -24 -29 -25 -52 0 -5 29 -8 65 -8 51 0 65 3 65 15
0 8 5 15 10 15 17 0 11 22 -10 40 -12 10 -23 36 -26 62 -6 45 -6 45 27 51 23
3 38 0 45 -9 6 -8 24 -14 40 -14 45 0 51 -10 56 -83 4 -72 14 -87 56 -87 13 0
22 -6 22 -15 0 -20 46 -20 54 1 9 22 8 24 -9 24 -9 0 -15 9 -15 25 0 14 -7 28
-15 31 -8 4 -15 12 -15 20 0 10 12 12 50 7 49 -6 50 -5 50 21 0 23 -4 26 -36
26 -43 0 -64 19 -64 58 0 53 -8 62 -55 62 -34 0 -44 4 -48 20 -3 11 1 23 9 28
8 4 16 32 19 66 5 56 21 86 44 86 6 0 11 -4 11 -9 0 -10 69 -25 77 -17 3 2 2
18 -2 35 -5 24 -12 31 -31 31 -16 0 -24 -6 -25 -17 0 -11 -3 -13 -6 -5 -5 14
-43 17 -43 3 0 -5 -16 -11 -35 -13 -29 -3 -35 -1 -35 14 0 10 -4 18 -10 18 -5
0 -10 13 -10 29 0 23 -5 31 -22 34 -13 2 -5 5 18 6 73 2 104 -8 104 -35 0 -15
6 -24 16 -24 20 0 44 24 44 44 0 9 7 16 15 16 8 0 15 4 15 9 0 5 11 11 25 13
17 4 25 1 25 -8 0 -18 56 -19 63 -1 4 8 8 9 14 1 4 -6 23 -14 41 -17 18 -4 40
-14 48 -22 9 -9 34 -15 60 -15 27 0 52 -6 60 -15 9 -8 11 -15 5 -15 -6 0 -11
-7 -11 -15 0 -8 -11 -17 -25 -21 -14 -3 -25 -10 -25 -15 0 -5 -6 -9 -14 -9 -8
0 -22 -8 -32 -17 -13 -13 -19 -14 -22 -5 -2 6 -16 12 -30 12 -34 0 -54 -29
-46 -70 6 -28 5 -30 -25 -30 -21 0 -31 5 -31 15 0 8 -9 15 -20 15 -16 0 -20
-7 -20 -30 0 -23 4 -30 20 -30 11 0 20 -4 20 -10 0 -19 38 -49 67 -52 25 -3
28 -7 26 -34 -3 -29 0 -32 31 -38 35 -7 66 0 66 14 0 9 27 16 108 25 48 6 52
5 52 -14 0 -12 -4 -21 -10 -21 -16 0 -12 -27 5 -34 29 -11 17 -33 -15 -28 -27
4 -30 2 -30 -22 0 -23 4 -26 27 -24 25 3 28 -1 33 -32 5 -33 7 -35 45 -37 60
-2 85 6 85 28 0 10 6 19 14 19 26 0 46 -12 46 -27 0 -13 10 -15 53 -10 28 3
46 3 40 0 -7 -2 -13 -13 -13 -24 0 -14 7 -19 30 -19 23 0 30 4 30 20 0 11 7
20 15 20 8 0 15 9 15 20 0 13 7 20 20 20 11 0 20 5 20 11 0 6 7 4 15 -5 8 -8
15 -19 15 -24 0 -14 36 -52 49 -52 6 0 11 -7 11 -15 0 -12 -13 -15 -59 -15
-54 0 -59 -2 -65 -24 -5 -19 -12 -24 -34 -23 -24 2 -27 -1 -25 -26 1 -15 9
-35 19 -44 15 -16 15 -18 -10 -39 -31 -27 -33 -44 -7 -44 14 0 21 -9 26 -31 9
-39 3 -44 -53 -44 -46 0 -47 -1 -50 -32 -3 -26 1 -33 15 -34 10 0 13 -3 6 -6
-7 -2 -13 -12 -13 -20 0 -11 6 -14 23 -9 12 3 25 6 29 6 3 0 8 8 10 17 3 15 6
15 25 -6 31 -33 77 -26 81 13 2 16 -2 32 -10 37 -11 7 -11 9 1 9 9 0 12 9 9
30 -4 29 -3 31 24 25 24 -6 28 -3 28 14 0 12 6 21 14 21 20 0 29 -30 11 -36
-19 -8 -19 -50 0 -58 26 -9 45 5 45 34 0 19 6 29 20 33 15 4 20 0 20 -14 0
-11 -7 -22 -15 -25 -8 -4 -15 -13 -15 -21 0 -11 8 -14 28 -11 24 3 27 0 29
-27 2 -16 4 -39 5 -50 2 -16 -2 -19 -19 -17 -24 3 -30 -12 -14 -36 12 -19 58
-5 64 20 5 16 11 19 35 13 26 -5 30 -3 36 21 8 30 -1 45 -14 24 -5 -8 -13 -11
-17 -6 -4 5 -2 11 5 13 22 8 13 40 -13 47 -14 4 -25 13 -25 21 0 8 -6 15 -14
15 -17 0 -29 27 -15 35 5 4 28 4 49 0 30 -5 40 -11 40 -26 0 -10 7 -19 15 -19
8 0 15 -7 15 -15 0 -21 33 -19 58 4 28 24 28 35 -3 49 -30 14 -32 32 -5 39 11
3 20 12 20 19 0 8 7 14 15 14 8 0 15 5 15 10 0 6 7 10 15 10 17 0 21 -26 5
-32 -5 -2 -12 -18 -16 -35 -5 -26 -3 -33 10 -33 9 0 16 -4 16 -10 0 -5 -4 -10
-10 -10 -20 0 -9 -28 13 -34 35 -9 47 -7 47 9 0 8 5 15 10 15 14 0 12 47 -2
54 -10 5 -10 7 0 12 16 8 15 32 -3 50 -8 9 -15 24 -15 35 0 10 7 19 15 19 8 0
15 7 15 15 0 8 7 15 15 15 26 0 16 28 -15 42 -32 14 -40 28 -15 28 8 0 15 8
16 18 0 10 3 12 6 5 7 -18 33 -16 33 2 0 8 -6 15 -14 15 -8 0 -17 7 -20 16 -5
12 -14 14 -38 9 -18 -4 -36 -12 -40 -18 -8 -13 -15 -8 -18 13 -5 35 1 40 45
40 31 0 47 5 55 18 10 16 10 16 20 0 15 -25 44 -24 37 2 -4 15 0 20 14 20 10
0 19 4 19 9 0 10 70 29 88 23 14 -4 16 -32 2 -32 -16 0 -11 -27 6 -34 27 -10
44 -7 44 9 0 8 5 15 10 15 6 0 10 -7 10 -15 0 -8 5 -15 10 -15 6 0 10 7 10 15
0 10 10 15 29 15 16 0 36 7 45 15 13 13 15 13 22 -7 4 -13 12 -26 18 -30 6 -4
-3 -8 -20 -8 -20 0 -34 -6 -38 -16 -8 -21 -8 -20 10 -38 11 -11 17 -40 19 -94
2 -42 4 -85 4 -94 1 -21 37 -25 44 -5 2 7 8 8 13 3 5 -5 4 -11 -4 -13 -16 -6
-16 -30 1 -36 6 -3 1 -6 -12 -6 -13 -1 -30 -9 -37 -18 -13 -17 -13 -17 -14 0
0 24 -30 22 -30 -3 0 -11 -4 -20 -10 -20 -5 0 -10 -11 -10 -24 0 -21 -5 -25
-37 -28 -28 -2 -39 -8 -41 -22 -3 -15 1 -18 22 -14 21 4 26 1 26 -14 0 -11 7
-26 15 -34 8 -9 15 -22 15 -30 0 -8 -7 -14 -15 -14 -9 0 -15 9 -15 25 0 18 -5
25 -20 25 -11 0 -20 -7 -20 -15 0 -8 -9 -15 -20 -15 -17 0 -20 7 -20 39 0 22
-6 42 -12 45 -10 5 -10 7 0 12 13 6 17 44 4 44 -4 0 -12 8 -18 18 -10 15 -6
17 34 19 25 1 49 8 53 15 7 10 9 9 9 -4 0 -12 8 -18 21 -18 20 0 21 4 15 54
-6 42 -4 57 8 69 20 20 21 47 1 47 -8 0 -15 7 -15 15 0 20 -50 32 -60 15 -7
-11 -16 -11 -49 -1 -51 15 -61 9 -61 -35 0 -19 -4 -34 -10 -34 -5 0 -10 9 -10
20 0 11 -7 20 -15 20 -10 0 -15 -10 -15 -30 0 -23 -4 -30 -20 -30 -11 0 -20
-4 -20 -10 0 -5 11 -10 25 -10 18 0 23 -4 19 -15 -4 -8 -1 -15 6 -15 14 0 40
-25 40 -39 0 -6 -6 -11 -13 -11 -8 0 -20 -11 -27 -25 -15 -28 -30 -33 -30 -10
0 20 -15 19 -40 -2 -24 -21 -25 -36 -4 -57 20 -20 54 -21 54 -1 0 10 10 15 30
15 23 0 30 -4 30 -20 0 -11 -5 -20 -11 -20 -19 0 -39 -28 -39 -54 0 -26 -13
-32 -62 -27 -14 2 -18 -5 -18 -32 0 -31 4 -35 31 -41 48 -9 51 -8 46 22 -2 15
-2 21 0 14 3 -6 9 -12 14 -12 5 0 9 -7 9 -15 0 -8 -4 -15 -10 -15 -17 0 -11
-28 8 -33 14 -4 13 -5 -4 -6 -11 0 -30 8 -42 19 -12 12 -35 20 -54 20 -31 0
-33 2 -35 37 -1 21 -7 39 -12 41 -14 5 -14 32 -1 32 6 0 10 14 10 31 0 31 0
31 -40 25 -36 -6 -40 -4 -40 14 0 11 -4 20 -10 20 -5 0 -10 -4 -10 -9 0 -5
-11 -12 -25 -15 -24 -6 -37 -46 -15 -46 6 0 10 -4 10 -10 0 -5 -7 -10 -15 -10
-8 0 -15 -7 -15 -15 0 -8 7 -15 16 -15 11 0 9 -6 -9 -22 -13 -13 -27 -35 -31
-50 -5 -23 -12 -28 -36 -28 -23 0 -30 -4 -30 -20 0 -15 7 -20 25 -20 14 0 25
4 25 9 0 5 18 11 40 13 28 2 40 0 40 -9 0 -7 6 -13 13 -13 7 0 24 -13 37 -30
28 -32 50 -39 50 -14 0 11 5 14 16 10 9 -3 18 -6 20 -6 2 0 4 -9 4 -19 0 -14
-5 -18 -20 -14 -11 3 -20 0 -20 -6 0 -14 36 -51 50 -51 6 0 10 -9 10 -20 0
-11 -4 -20 -10 -20 -15 0 -12 -29 4 -46 18 -17 73 -18 90 -1 7 6 15 27 19 45
7 34 28 62 46 62 6 0 11 -4 11 -10 0 -5 -4 -10 -10 -10 -5 0 -10 -9 -10 -20 0
-13 7 -20 20 -20 11 0 20 7 20 15 0 8 5 15 10 15 6 0 10 7 10 15 0 8 -7 15
-15 15 -23 0 -19 41 5 47 11 3 20 10 20 15 0 5 7 16 15 24 8 9 15 22 15 30 0
8 -7 14 -15 14 -8 0 -15 5 -15 10 0 6 -9 10 -20 10 -11 0 -20 5 -20 10 0 6 14
10 30 10 17 0 30 -4 30 -10 0 -5 7 -10 15 -10 9 0 15 9 15 25 0 14 5 25 10 25
6 0 10 -9 10 -20 0 -25 35 -43 85 -44 36 -1 40 -4 43 -29 3 -25 32 -41 32 -17
0 6 19 10 43 10 61 0 87 9 87 31 0 12 7 19 18 18 10 0 12 -3 5 -6 -23 -9 -14
-33 11 -33 16 0 27 7 31 20 8 26 35 27 28 1 -3 -13 2 -21 16 -26 25 -8 91 4
91 17 0 5 7 2 17 -7 11 -12 25 -15 46 -11 16 3 38 6 48 6 10 0 20 8 24 19 3
12 15 18 33 18 15 -1 32 1 37 2 6 2 22 3 38 2 19 -1 27 -6 27 -20 0 -10 7 -21
15 -25 8 -3 15 -12 15 -19 0 -21 32 -46 37 -30 3 7 31 15 64 19 32 4 59 11 59
17 0 5 11 -2 25 -17 25 -27 45 -34 45 -16 0 16 18 12 40 -10 11 -11 20 -31 20
-45 0 -18 5 -25 20 -25 11 0 20 -6 20 -14 0 -8 11 -17 24 -20 13 -3 31 -9 40
-12 12 -5 16 0 16 19 0 24 3 25 40 20 30 -4 40 -2 41 8 10 99 10 99 -27 99
-24 0 -34 -5 -34 -15 0 -19 -36 -55 -55 -55 -8 0 -15 9 -15 20 0 11 5 20 11
20 6 0 17 6 24 13 29 25 40 33 58 40 9 3 17 11 17 17 0 5 9 19 20 30 11 11 20
29 20 40 0 17 4 19 28 14 38 -9 38 -11 13 -35 -14 -13 -20 -27 -16 -40 4 -13
2 -19 -9 -19 -21 0 -20 -25 1 -50 21 -24 73 -28 73 -5 0 9 9 15 23 15 12 0 32
3 45 6 18 5 22 2 22 -16 0 -12 -7 -28 -15 -36 -20 -21 -19 -44 3 -45 10 0 12
-3 4 -6 -6 -2 -12 -11 -12 -19 0 -11 -10 -14 -32 -13 -79 6 -108 0 -108 -21 0
-12 -7 -20 -16 -20 -13 0 -15 -8 -12 -34 3 -25 -1 -38 -14 -48 -10 -7 -18 -19
-19 -28 0 -9 -2 -11 -5 -3 -3 7 -32 15 -65 19 -49 5 -59 10 -59 25 0 11 5 19
10 19 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0 -12 11 -16 25 -5 22 -12 25 -49
25 -37 0 -45 -4 -51 -22 -4 -13 -12 -26 -18 -30 -8 -5 -7 -11 2 -20 6 -6 12
-18 12 -24 0 -17 -44 -64 -60 -64 -14 0 -5 -53 11 -58 5 -2 15 -9 22 -16 7 -7
29 -16 49 -20 40 -7 58 -33 30 -43 -9 -4 -23 -13 -30 -20 -7 -7 -22 -13 -33
-13 -15 0 -19 -7 -19 -31 0 -23 4 -30 18 -28 37 3 42 -1 25 -21 -21 -24 -41
-26 -49 -5 -8 19 -21 19 -40 1 -18 -19 -18 -36 1 -36 8 0 15 -9 15 -19 0 -11
6 -22 13 -24 7 -3 5 -6 -5 -6 -10 -1 -18 -8 -18 -16 0 -8 -4 -15 -10 -15 -15
0 -12 -57 3 -63 7 -3 5 -6 -5 -6 -12 -1 -18 6 -18 19 0 32 -30 24 -36 -10 -4
-16 -10 -30 -15 -30 -5 0 -9 -7 -9 -15 0 -9 9 -15 21 -15 19 0 20 -4 15 -35
-5 -27 -3 -37 9 -41 22 -9 19 -51 -5 -57 -15 -4 -20 0 -20 14 0 16 -7 19 -45
19 l-46 0 7 35 c5 30 4 35 -13 35 -11 0 -29 -9 -41 -20 -20 -19 -22 -19 -20
-3 4 45 -3 73 -17 73 -10 0 -15 -10 -15 -31 0 -22 -5 -32 -20 -36 -11 -3 -20
-10 -20 -15 0 -5 -7 -17 -16 -25 -22 -22 -11 -67 22 -98 15 -14 31 -25 35 -25
5 0 9 -7 9 -15 0 -8 5 -15 10 -15 6 0 10 -9 10 -20 0 -11 -4 -20 -10 -20 -5 0
-10 -7 -10 -16 0 -13 7 -15 36 -11 63 10 127 -12 74 -26 -24 -6 -26 -35 -3
-44 32 -13 53 -38 53 -64 0 -18 6 -29 18 -32 14 -4 15 -5 1 -6 -17 -1 -29 -29
-29 -68 0 -14 -6 -23 -15 -23 -8 0 -15 5 -15 10 0 6 -7 10 -15 10 -9 0 -15 10
-15 26 0 23 -3 25 -30 19 -22 -5 -32 -3 -36 9 -8 20 -34 21 -34 1 0 -8 -7 -15
-15 -15 -10 0 -15 11 -15 35 0 19 5 35 10 35 6 0 10 7 10 15 0 19 -17 19 -36
0 -19 -19 -39 -19 -58 0 -8 8 -19 15 -25 15 -6 0 -11 9 -11 20 0 11 7 20 15
20 8 0 15 -7 15 -15 0 -8 9 -15 20 -15 11 0 20 5 20 10 0 6 12 10 28 10 75 1
99 34 32 45 -21 4 -44 4 -50 0 -5 -3 -10 1 -10 9 0 17 -26 22 -32 6 -2 -5 -22
-12 -45 -16 -43 -7 -43 -6 -43 25 0 21 5 31 15 31 8 0 15 7 15 15 0 8 11 17
25 21 20 5 25 13 25 36 0 16 -6 36 -14 44 -17 16 -46 19 -46 3 0 -16 -26 -39
-44 -39 -9 0 -16 -7 -16 -15 0 -8 8 -15 17 -15 13 0 14 -3 5 -12 -9 -9 -15 -9
-24 0 -18 18 -38 14 -38 -8 0 -24 -6 -25 -26 -6 -9 9 -10 16 -3 18 17 6 -8 88
-26 88 -8 0 -15 -4 -15 -10 0 -5 -4 -10 -10 -10 -5 0 -10 7 -10 15 0 8 7 15
15 15 8 0 15 7 15 15 0 18 16 20 23 3 4 -10 8 -10 14 -2 4 6 18 14 31 18 12 4
22 13 22 19 0 9 2 9 8 1 4 -6 22 -15 40 -19 31 -6 32 -6 32 29 0 29 4 37 24
42 14 3 27 12 30 20 3 8 12 14 21 14 9 0 15 9 15 25 0 14 -4 25 -10 25 -5 0
-10 18 -10 40 0 22 -4 40 -10 40 -5 0 -10 6 -10 14 0 8 -6 17 -12 19 -10 4
-10 6 0 6 7 1 12 16 12 40 0 47 -12 52 -142 56 -81 3 -88 2 -88 -16 0 -10 -4
-19 -10 -19 -5 0 -10 5 -10 10 0 6 -7 10 -15 10 -9 0 -15 9 -15 21 0 29 -37
59 -72 59 -21 0 -28 -5 -28 -20 0 -11 7 -20 15 -20 8 0 15 -4 15 -10 0 -5 -9
-10 -20 -10 -11 0 -20 -4 -20 -10 0 -5 -9 -10 -20 -10 -11 0 -20 -4 -20 -10 0
-5 -6 -10 -14 -10 -8 0 -22 -7 -30 -16 -14 -13 -16 -13 -16 0 0 8 -7 22 -16
30 -8 9 -11 16 -5 16 6 0 11 7 11 15 0 9 -9 15 -25 15 -16 0 -25 -6 -25 -15 0
-8 -7 -15 -15 -15 -8 0 -15 -9 -15 -19 0 -11 -12 -32 -26 -48 -20 -22 -24 -34
-19 -60 6 -32 5 -33 -35 -39 -24 -4 -40 -12 -40 -20 0 -8 -4 -14 -10 -14 -5 0
-10 -9 -10 -20 0 -23 28 -27 36 -5 9 23 23 18 30 -12 4 -15 14 -34 22 -42 12
-13 12 -16 -2 -26 -18 -13 -22 -35 -6 -35 5 0 12 -11 16 -25 3 -14 10 -25 15
-25 5 0 9 -9 9 -20 0 -15 7 -20 25 -20 16 0 25 6 25 15 0 8 5 15 10 15 6 0 10
7 10 15 0 8 -4 15 -10 15 -5 0 -10 9 -10 20 0 15 7 20 25 20 14 0 25 5 25 10
0 6 5 10 10 10 6 0 10 -13 10 -30 0 -28 2 -30 39 -30 22 0 42 -5 44 -12 4 -10
7 -10 15 0 5 7 25 15 45 19 30 4 37 3 37 -11 0 -9 -4 -16 -10 -16 -5 0 -10 -9
-10 -20 0 -11 -7 -20 -15 -20 -8 0 -15 -6 -15 -12 0 -7 -3 -23 -6 -34 -5 -19
-1 -23 35 -29 34 -6 41 -11 40 -28 0 -25 -17 -69 -18 -49 -1 7 -14 12 -31 12
-23 0 -30 -4 -30 -20 0 -12 7 -20 18 -20 13 0 17 -9 19 -45 1 -34 -2 -45 -13
-45 -8 0 -17 -7 -20 -15 -4 -8 -12 -15 -20 -15 -8 0 -15 -10 -15 -22 -1 -17
-2 -19 -6 -5 -2 9 -11 17 -20 17 -11 0 -14 -8 -11 -28 4 -26 2 -28 -31 -26
-43 1 -91 -16 -91 -33 0 -7 11 -13 25 -13 14 0 25 -4 25 -10 0 -5 -4 -10 -10
-10 -14 0 -12 -28 3 -33 7 -2 8 -8 3 -13 -5 -5 -11 -4 -13 4 -3 6 -18 12 -34
12 -22 0 -30 -6 -35 -25 -3 -14 -12 -25 -20 -25 -8 0 -14 -5 -14 -10 0 -6 -6
-5 -14 2 -8 7 -10 14 -5 16 12 5 12 42 -1 42 -5 0 -12 11 -16 25 -4 16 -13 25
-25 25 -14 0 -19 7 -19 25 0 14 -4 25 -10 25 -5 0 -10 7 -10 15 0 8 5 15 10
15 6 0 10 16 10 35 0 24 -5 35 -15 35 -8 0 -15 5 -15 10 0 14 -27 12 -34 -2
-3 -9 -8 -8 -16 4 -13 22 -50 24 -50 3 0 -8 -4 -15 -10 -15 -16 0 -12 -32 5
-44 13 -9 14 -23 9 -68 -6 -50 -10 -57 -33 -63 -14 -4 -28 -10 -31 -15 -10
-16 -70 -16 -70 0 0 6 9 10 20 10 11 0 20 7 20 15 0 8 5 15 10 15 6 0 10 7 10
15 0 8 5 15 10 15 6 0 10 9 10 20 0 13 -7 20 -20 20 -11 0 -20 -6 -20 -14 0
-7 -9 -16 -20 -19 -11 -3 -20 -1 -20 4 0 5 -7 9 -15 9 -8 0 -15 5 -15 10 0 6
-22 10 -49 10 -39 0 -51 -4 -54 -17 -4 -14 -5 -12 -6 5 -1 12 -7 22 -16 22 -9
0 -15 9 -15 25 0 16 6 25 15 25 9 0 15 -9 15 -25 0 -14 5 -25 10 -25 6 0 10
11 10 25 0 21 5 25 31 25 30 0 31 1 25 32 -3 17 -9 34 -12 37 -3 3 -32 6 -65
6 -32 -1 -59 3 -59 8 0 5 -7 15 -15 23 -19 19 -19 34 -1 34 9 0 17 15 21 40 6
34 11 40 31 40 13 0 24 5 24 10 0 6 24 10 53 10 66 0 97 9 97 26 0 8 5 14 10
14 6 0 10 -7 10 -15 0 -9 7 -18 15 -21 9 -4 15 -19 15 -40 0 -27 4 -34 19 -34
15 0 19 9 23 49 5 49 24 81 50 81 7 0 21 7 31 14 20 16 24 91 5 99 -10 4 -10
6 0 6 6 1 12 7 12 15 0 11 -15 14 -62 13 l-63 -2 -3 -47 c-2 -33 -8 -48 -17
-48 -7 0 -16 -8 -18 -17 -4 -16 -5 -16 -6 1 -1 17 -7 18 -63 14 -72 -6 -88 -2
-88 18 0 8 -10 14 -25 14 -15 0 -25 -6 -25 -15 0 -16 -43 -31 -59 -20 -6 3
-11 13 -11 21 0 8 -5 14 -11 14 -6 0 -17 7 -25 15 -10 9 -34 15 -65 15 -37 0
-49 -4 -49 -15 0 -8 -6 -15 -12 -15 -26 0 -40 -17 -33 -39 8 -25 32 -28 38 -4
4 17 5 17 6 1 0 -10 7 -19 14 -19 6 -1 -1 -4 -18 -8 -23 -5 -31 -12 -33 -33
-3 -22 2 -27 27 -33 17 -4 31 -11 31 -16 0 -14 38 -11 43 4 3 6 6 -2 6 -19 1
-26 6 -33 27 -38 15 -4 33 -14 41 -21 13 -13 11 -15 -16 -15 -29 0 -31 -3 -31
-35 0 -24 -5 -35 -15 -35 -8 0 -15 6 -15 14 0 14 -71 25 -185 29 -45 2 -50 0
-53 -20 -3 -17 2 -23 16 -23 25 0 33 -18 20 -44 -6 -10 -7 -33 -4 -50 7 -37
-2 -96 -15 -96 -5 0 -9 7 -9 15 0 8 -9 15 -20 15 -16 0 -20 -7 -20 -30 0 -30
23 -43 33 -17 3 6 6 0 6 -14 1 -27 40 -57 60 -45 7 4 11 -1 11 -13 0 -13 -7
-21 -16 -21 -12 0 -15 -7 -12 -25 3 -14 1 -25 -3 -25 -5 0 -9 5 -9 10 0 6 -13
10 -30 10 -18 0 -30 5 -30 13 0 18 -43 53 -48 39 -2 -7 -15 -12 -28 -12 -15 0
-24 -6 -24 -15 0 -8 5 -15 10 -15 6 0 10 -9 10 -20 0 -11 -4 -20 -10 -20 -21
0 -9 -30 15 -36 39 -10 30 -24 -15 -24 -34 0 -42 -4 -50 -25 -10 -26 -17 -29
-44 -19 -14 5 -14 8 -1 21 21 21 21 113 0 113 -9 0 -18 7 -21 15 -8 19 -64 20
-64 2 0 -24 -34 -53 -57 -49 -16 2 -23 -2 -23 -12 0 -11 -9 -16 -30 -16 -23 0
-30 4 -30 20 0 13 7 20 20 20 11 0 20 4 20 9 0 5 9 13 20 16 21 7 26 42 11 80
-5 11 -4 33 2 49 8 22 7 31 -1 34 -7 2 -12 10 -12 18 0 7 -11 16 -25 20 -14 3
-25 10 -25 15 0 5 -16 9 -35 9 -30 0 -35 -3 -35 -23 0 -28 31 -46 78 -45 35 1
53 -9 36 -20 -6 -4 -15 -18 -19 -32 -4 -14 -13 -28 -19 -33 -8 -5 -7 -10 4
-16 21 -14 13 -17 -52 -24 -49 -5 -57 -3 -61 14 -6 23 -67 28 -67 5 0 -8 -17
-16 -45 -19 -51 -7 -85 -36 -85 -71 0 -27 15 -34 68 -34 29 -1 33 2 29 19 -3
12 0 19 9 19 8 0 14 9 14 20 0 13 7 20 20 20 11 0 20 -7 20 -15 0 -8 6 -15 14
-15 7 0 16 -14 20 -31 3 -18 14 -38 24 -45 16 -13 16 -15 0 -28 -26 -20 -22
-34 12 -41 17 -4 30 -11 30 -16 0 -5 16 -9 35 -9 l35 0 0 50 0 50 48 0 c26 0
42 -4 36 -8 -6 -4 -15 -22 -19 -40 -5 -25 -3 -32 9 -32 9 0 16 -4 16 -10 0 -5
9 -10 20 -10 13 0 20 -7 20 -19 0 -23 -23 -30 -69 -20 -18 4 -45 3 -58 -2 -13
-5 -42 -9 -64 -9 -35 0 -39 3 -39 25 0 23 -3 25 -50 25 -38 0 -50 -4 -50 -15
0 -8 9 -15 20 -15 13 0 20 -7 20 -20 0 -13 -7 -20 -20 -20 -11 0 -20 5 -20 10
0 6 -16 10 -35 10 -23 0 -35 -5 -35 -14 0 -11 -12 -14 -47 -13 l-48 2 0 -35 0
-35 85 -2 c113 -2 140 3 140 29 0 16 5 19 29 13 23 -5 30 -2 35 12 3 10 4 1 1
-19 l-4 -38 105 0 c97 0 104 1 104 20 0 13 7 20 20 20 13 0 20 7 20 20 0 11 7
20 15 20 8 0 15 5 15 10 0 21 30 9 36 -15 3 -14 10 -25 15 -25 5 0 9 -7 9 -15
0 -8 -4 -15 -10 -15 -5 0 -10 -4 -10 -10 0 -6 33 -9 80 -8 44 1 80 5 80 10 0
4 14 8 30 8 17 0 30 5 30 10 0 19 28 10 33 -10 5 -18 14 -20 91 -20 51 0 86 4
86 10 0 6 -16 10 -35 10 -31 0 -35 3 -35 25 0 16 6 25 15 25 8 0 15 -4 15 -10
0 -15 57 -12 63 3 3 6 6 -5 6 -25 l1 -37 178 4 c97 3 192 5 211 4 18 0 39 5
47 12 11 9 15 8 20 -4 5 -14 55 -16 445 -16 425 1 439 1 439 20 0 10 5 19 10
19 6 0 10 -9 10 -20 0 -15 7 -20 27 -20 43 0 133 23 133 35 0 6 3 5 8 -1 4 -6
22 -15 40 -19 25 -5 32 -3 32 9 0 9 5 16 10 16 6 0 10 7 10 15 0 8 -7 15 -15
15 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -15 -10 -8 0 -15 6 -15 14 0 8 7 21 15 30
8 8 15 22 15 30 0 23 27 20 33 -4 3 -11 12 -20 21 -20 12 0 16 -10 16 -37 0
-58 7 -63 84 -58 37 3 72 7 77 11 5 3 9 0 9 -5 0 -6 14 -11 30 -11 17 0 30 3
30 8 0 4 11 7 25 7 14 0 25 -3 25 -7 0 -5 27 -8 61 -8 52 0 62 3 66 20 7 27
66 28 85 2 12 -17 30 -19 196 -19 100 0 182 4 182 9 0 4 7 8 15 8 8 0 15 -4
15 -10 0 -6 30 -10 70 -10 40 0 70 4 70 10 0 6 -7 10 -15 10 -8 0 -15 7 -15
15 0 8 5 15 10 15 6 0 10 11 10 25 0 19 5 25 21 25 12 0 23 8 26 20 3 11 9 20
14 20 16 0 9 28 -8 32 -15 3 -15 4 1 21 25 24 50 18 54 -14 2 -20 -2 -28 -17
-32 -23 -6 -29 -37 -7 -37 12 0 25 -28 26 -52 0 -4 -15 -8 -32 -10 -25 -2 -33
-8 -33 -23 0 -18 7 -20 63 -22 34 -1 62 3 62 8 0 5 6 9 14 9 8 0 17 11 20 25
4 14 11 25 16 25 6 0 10 6 10 14 0 8 7 17 16 20 21 8 21 8 27 -14 4 -15 0 -20
-13 -20 -11 0 -20 -10 -24 -25 -4 -14 -11 -25 -16 -25 -6 0 -10 -4 -10 -10 0
-6 57 -10 150 -10 143 0 150 1 150 20 0 11 5 20 10 20 15 0 12 67 -2 73 -7 3
2 6 20 6 32 1 40 -7 23 -24 -6 -6 -13 -29 -17 -52 l-7 -43 37 0 c29 0 36 4 36
20 0 11 5 20 10 20 6 0 10 -9 10 -20 0 -19 9 -19 618 -15 339 2 631 3 647 3
17 0 91 -1 165 -3 79 -2 140 1 147 7 8 6 19 6 32 -1 11 -6 58 -11 105 -11 70
0 86 3 86 15 0 21 -34 53 -65 61 -27 7 -35 24 -11 24 8 0 18 9 21 20 10 30 25
24 25 -10 0 -25 4 -30 23 -30 12 0 29 9 37 20 9 13 26 20 47 20 18 0 33 5 33
11 0 5 5 7 11 3 7 -4 7 -9 2 -13 -6 -3 -13 -32 -17 -64 l-6 -58 57 3 c46 2 59
7 61 21 2 12 -3 17 -17 17 -14 0 -21 6 -21 20 0 11 7 20 15 20 8 0 15 7 15 15
0 11 -11 15 -37 16 -21 0 -31 3 -23 6 8 3 19 12 23 20 5 8 16 11 28 8 13 -4
19 -2 19 9 0 9 11 18 24 22 27 6 39 26 30 49 -4 9 0 15 10 15 11 0 16 9 16 30
0 26 -4 30 -26 30 -17 0 -28 6 -31 20 -3 11 -9 20 -14 20 -5 0 -9 7 -9 15 0 8
5 15 10 15 6 0 10 -4 10 -10 0 -5 16 -10 35 -10 24 0 35 5 35 15 0 8 -7 15
-15 15 -8 0 -15 6 -15 14 0 26 -82 31 -140 9 -8 -3 -25 -5 -37 -4 -15 2 -23
-3 -23 -13 0 -10 -10 -14 -34 -13 -37 2 -40 27 -2 27 33 1 56 24 63 63 l5 34
-62 7 c-55 6 -65 5 -81 -13 -11 -11 -19 -34 -19 -51 0 -23 -4 -30 -19 -30 -15
0 -21 10 -26 40 -8 49 -2 60 36 60 22 0 29 5 29 20 0 11 7 20 15 20 8 0 15 7
15 15 0 8 -4 15 -10 15 -5 0 -10 5 -10 10 0 16 57 12 64 -5 7 -20 66 -20 66 0
0 11 12 15 50 15 45 0 50 2 47 22 -2 20 3 23 45 24 45 1 47 0 54 -29 5 -22 2
-34 -10 -42 -9 -7 -16 -17 -16 -24 0 -6 -15 -11 -35 -11 -19 0 -35 -4 -35 -10
0 -5 -13 -10 -30 -10 -20 0 -30 5 -30 15 0 8 -7 15 -15 15 -8 0 -15 -5 -15
-10 0 -6 7 -13 15 -16 20 -8 19 -48 -1 -68 -18 -19 -10 -36 17 -36 22 0 49 22
49 39 0 6 11 13 25 17 14 3 25 12 25 20 0 8 7 14 15 14 8 0 15 7 15 15 0 10
10 15 30 15 23 0 30 5 30 18 0 11 9 29 20 42 28 32 28 100 1 100 -11 0 -21 -5
-23 -12 -5 -14 -81 -13 -86 2 -2 5 -12 10 -22 10 -10 0 -20 5 -22 11 -6 19
-69 0 -67 -19 3 -36 -2 -52 -15 -52 -9 0 -16 12 -18 33 -2 27 -8 33 -30 35
-22 3 -28 -1 -28 -17 0 -11 -7 -26 -15 -35 -8 -8 -15 -19 -15 -25 0 -6 -9 -11
-20 -11 -11 0 -20 5 -20 10 0 6 -13 10 -30 10 -16 0 -30 -4 -30 -10 0 -5 -16
-10 -35 -10 -19 0 -35 -4 -35 -10 0 -5 7 -10 15 -10 8 0 15 -4 15 -10 0 -15
-29 -12 -46 4 -16 16 -19 76 -4 76 6 0 10 -4 10 -10 0 -21 30 -9 36 15 3 14
13 25 20 25 10 0 13 -7 8 -25 -5 -21 -3 -25 14 -25 21 0 38 32 27 50 -3 5 3
10 14 10 15 0 21 6 21 21 0 19 4 21 33 15 17 -4 34 -11 35 -16 5 -15 42 -12
59 5 10 10 13 24 9 40 -6 22 -3 25 24 25 16 0 30 5 30 10 0 6 7 10 15 10 8 0
15 7 15 16 0 14 -11 16 -80 12 l-80 -5 0 28 c0 15 -6 30 -12 33 -10 5 -10 7 0
12 14 7 16 34 2 34 -5 0 -10 7 -10 15 0 8 -4 15 -10 15 -5 0 -10 16 -10 35 0
19 -4 35 -10 35 -5 0 -10 5 -10 10 0 16 22 12 35 -6 8 -11 19 -14 33 -10 25 8
30 36 7 36 -17 0 -19 9 -6 29 11 18 31 5 31 -20 0 -18 6 -20 48 -17 40 3 47 6
50 26 2 12 8 22 13 22 5 0 9 7 9 15 0 8 9 15 20 15 11 0 20 5 20 10 0 6 7 10
15 10 20 0 19 20 -2 36 -27 20 -45 17 -38 -6 4 -11 2 -20 -4 -20 -6 0 -11 -7
-11 -15 0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 -7 15 -15 15 -8 0 -15 5
-15 10 0 6 -10 10 -22 11 -13 0 -17 3 -10 6 18 7 15 31 -7 38 -30 9 -51 -5
-51 -35 0 -19 6 -30 18 -33 16 -4 16 -5 0 -6 -10 0 -18 -5 -18 -11 0 -5 -9
-10 -19 -10 -15 0 -19 6 -18 28 2 24 -2 27 -30 30 -25 2 -33 8 -33 23 0 10 7
19 15 19 8 0 15 7 15 15 0 8 7 15 15 15 8 0 15 7 15 15 0 11 -12 15 -51 15
-46 0 -51 -2 -46 -20 4 -15 0 -20 -14 -20 -10 0 -19 -4 -19 -9 0 -11 -30 -19
-47 -14 -18 6 -16 23 2 23 13 0 15 10 11 50 -4 39 -2 50 9 50 8 0 15 -4 15
-10 0 -12 37 -13 42 -2 2 5 16 12 32 16 26 6 27 6 12 -9 -22 -22 -20 -45 4
-45 11 0 20 5 20 10 0 6 9 10 19 10 37 0 48 24 49 104 0 66 -2 76 -17 76 -24
0 -61 -37 -61 -60 0 -11 -4 -20 -10 -20 -5 0 -10 15 -10 34 0 28 -4 35 -25 40
-23 6 -36 26 -16 26 4 0 20 11 35 25 26 24 35 55 16 55 -5 0 -10 11 -10 25 0
15 6 25 14 25 8 0 21 7 29 15 13 13 14 10 10 -25 -4 -26 -2 -40 6 -40 6 0 11
7 11 15 0 8 7 15 15 15 10 0 15 10 15 30 0 35 17 39 49 11 16 -14 20 -26 15
-45 -5 -21 -2 -27 21 -36 34 -13 65 -13 65 0 0 6 -11 10 -25 10 -14 0 -25 5
-25 10 0 6 11 10 25 10 14 0 25 -4 25 -10 0 -5 9 -10 19 -10 20 0 37 42 25 61
-3 6 7 22 24 37 16 15 33 42 37 60 3 18 13 32 21 32 9 0 14 11 14 30 0 23 -4
30 -20 30 -11 0 -20 -7 -20 -15 0 -9 -9 -15 -25 -15 -26 0 -35 -24 -12 -33 6
-3 2 -6 -10 -6 -12 -1 -28 -11 -34 -23 -6 -13 -20 -31 -30 -41 -16 -15 -19
-15 -19 -2 0 10 -10 15 -30 15 -20 0 -30 -5 -30 -15 0 -19 -26 -20 -34 0 -3 8
1 21 9 29 18 17 19 36 4 36 -7 0 -20 10 -31 23 -17 19 -18 22 -4 25 19 4 22
48 4 55 -8 3 -6 6 5 6 10 1 17 9 17 21 0 27 -30 26 -37 -1 -4 -12 -17 -22 -34
-26 -22 -4 -29 -11 -29 -28 0 -13 7 -25 15 -29 8 -3 15 -12 15 -21 0 -22 -31
-18 -37 5 -7 25 -73 30 -73 5 0 -8 -4 -15 -10 -15 -5 0 -10 5 -10 10 0 6 -7
10 -15 10 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 5
10 10 10 6 0 10 6 10 14 0 8 13 17 29 21 16 3 32 13 35 22 4 11 14 13 32 9 33
-9 64 1 64 20 0 9 16 30 35 48 36 35 46 66 20 66 -8 0 -15 -4 -15 -10 0 -5 -7
-10 -15 -10 -8 0 -15 7 -15 15 0 9 -9 15 -21 15 -19 0 -21 -5 -17 -41 3 -39 3
-40 -22 -34 -39 10 -50 45 -14 45 18 0 18 24 1 42 -22 22 -28 48 -12 48 8 0
15 7 15 15 0 11 11 15 43 15 23 0 53 3 67 5 14 3 19 3 13 -1 -16 -7 -17 -31
-2 -36 6 -2 13 -26 17 -53 5 -52 20 -75 46 -75 11 0 16 -9 16 -28 0 -16 8 -39
19 -50 21 -25 61 -29 61 -7 0 8 5 15 10 15 6 0 10 23 10 50 0 43 -3 50 -20 50
-11 0 -20 7 -20 15 0 8 -7 15 -15 15 -8 0 -15 9 -15 19 0 11 -6 22 -12 25 -10
5 -10 7 -1 12 28 13 12 25 -24 18 -34 -6 -36 -6 -30 17 4 13 7 27 7 32 0 4 6
7 14 7 21 0 28 15 20 45 -5 21 -12 25 -40 25 -24 0 -34 5 -34 15 0 8 7 15 15
15 17 0 39 46 31 66 -3 8 0 11 10 7 7 -3 14 -9 14 -14 0 -5 14 -9 30 -9 29 0
30 2 30 45 0 26 5 45 11 45 6 0 9 9 6 20 -3 13 0 20 9 20 9 0 14 11 14 31 0
29 2 30 30 24 28 -6 30 -4 30 20 0 24 -3 26 -25 21 -18 -5 -25 -2 -25 9 0 19
-26 20 -34 0 -3 -8 -12 -15 -20 -15 -7 0 -16 -9 -19 -20 -7 -26 -27 -26 -27 0
0 27 -26 26 -33 -1 -7 -27 2 -79 14 -79 5 0 9 -6 9 -14 0 -8 8 -17 18 -19 13
-4 13 -5 -4 -6 -12 0 -27 -7 -33 -15 -7 -8 -20 -12 -30 -9 -15 4 -17 12 -13
54 l5 50 -34 -3 c-29 -2 -36 -8 -44 -35 -12 -43 -25 -42 -25 2 0 28 4 35 20
35 11 0 20 7 20 15 0 8 -9 15 -20 15 -11 0 -20 -7 -20 -15 0 -10 -10 -15 -30
-15 -22 0 -30 -5 -31 -17 0 -11 -3 -13 -6 -5 -2 6 -11 12 -19 12 -8 0 -14 7
-14 15 0 8 5 15 10 15 16 0 11 37 -6 44 -26 10 -34 7 -34 -14 0 -13 -7 -20
-19 -20 -23 0 -40 22 -23 28 14 4 16 32 3 32 -5 0 -11 -4 -13 -9 -1 -4 -13
-12 -25 -15 -20 -7 -23 -4 -23 23 0 26 -4 31 -35 37 -25 4 -35 11 -35 25 0 15
5 17 30 13 25 -5 30 -3 30 13 0 10 -8 25 -19 32 -11 8 -16 21 -14 32 4 14 0
19 -15 19 -11 0 -22 -6 -25 -12 -2 -8 -8 -9 -13 -4 -5 5 -4 11 4 13 17 7 15
43 -3 43 -8 0 -15 -7 -15 -15 0 -8 -7 -15 -15 -15 -11 0 -15 -11 -15 -39 0
-33 -3 -38 -19 -34 -13 3 -18 13 -18 31 2 70 -3 102 -18 102 -8 0 -15 7 -15
16 0 20 -24 44 -44 44 -8 0 -19 7 -24 16 -5 9 -22 17 -39 18 -28 1 -29 2 -19
38 6 21 14 38 18 38 4 0 8 7 8 15 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 8 -11
17 -25 21 -20 5 -24 11 -19 30 6 23 4 24 -45 24 -28 0 -51 -4 -51 -10 0 -5 -9
-10 -20 -10 -11 0 -20 -7 -20 -15 0 -8 7 -15 15 -15 8 0 15 -9 15 -21 0 -16
-4 -19 -22 -13 -13 3 -23 7 -24 8 -9 16 -22 113 -18 137 5 31 2 35 -25 47 -22
10 -31 20 -31 38 0 13 5 24 10 24 6 0 10 -6 10 -14 0 -23 104 -17 108 7 2 10
-3 17 -11 17 -11 0 -17 18 -22 65 -3 36 -11 65 -16 65 -5 0 -4 6 3 13 10 11
15 12 25 1 7 -7 13 -20 13 -28 0 -9 5 -16 11 -16 18 0 41 30 37 48 -4 15 0 17
23 14 55 -8 24 59 -33 73 -16 4 -28 13 -28 21 0 8 -7 14 -15 14 -8 0 -15 -9
-15 -19 0 -10 7 -21 15 -25 8 -3 15 -10 15 -16 0 -5 -7 -10 -15 -10 -8 0 -15
4 -15 8 0 11 -35 52 -44 52 -4 0 -6 -12 -5 -27 7 -88 -15 -130 -27 -53 -5 32
-12 42 -31 46 -14 4 -21 9 -15 13 5 3 13 25 17 49 6 39 25 60 25 27 0 -8 7
-15 15 -15 8 0 15 -7 15 -15 0 -8 4 -15 10 -15 6 0 7 24 4 60 -6 65 -6 65 59
60 23 -1 27 2 27 25 0 21 2 24 13 13 11 -10 11 -16 0 -38 -7 -14 -13 -31 -13
-37 0 -7 -4 -13 -10 -13 -5 0 -10 -11 -10 -25 0 -25 13 -31 44 -19 17 7 22 34
6 34 -5 0 -10 9 -10 20 0 11 6 20 14 20 8 0 16 4 18 9 2 4 13 12 26 15 16 6
22 3 22 -8 0 -10 -9 -16 -25 -16 -16 0 -25 -6 -25 -15 0 -8 5 -15 10 -15 6 0
10 -8 10 -18 0 -10 9 -25 20 -35 25 -21 30 -21 30 -2 0 8 7 15 15 15 22 0 18
31 -5 37 -11 3 -20 12 -20 19 0 19 29 18 36 -1 4 -8 13 -15 21 -15 10 0 12 -6
7 -22 -3 -13 -11 -24 -15 -26 -14 -5 -11 -60 4 -65 10 -4 10 -6 0 -6 -15 -1
-18 -51 -3 -51 17 0 50 34 50 52 0 10 -7 18 -15 18 -8 0 -15 4 -15 9 0 5 7 11
15 15 9 3 15 0 15 -9 0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -4 15 -10 15 -5 0
-10 5 -10 10 0 6 7 10 16 10 13 0 15 7 11 34 -4 25 -1 37 14 50 24 20 39 21
39 1 0 -21 101 -15 124 8 10 9 31 17 49 16 18 0 25 -3 17 -6 -24 -10 -40 -30
-40 -52 0 -38 77 -22 93 19 4 12 6 11 6 -2 1 -10 8 -18 16 -18 11 0 15 11 15
40 0 29 -4 40 -15 40 -8 0 -15 -4 -15 -10 0 -5 -9 -10 -19 -10 -15 0 -21 10
-26 43 -4 23 -13 45 -19 49 -6 5 -2 8 12 8 15 0 22 -6 22 -20 0 -11 7 -20 15
-20 8 0 15 7 15 15 0 8 7 15 15 15 8 0 15 7 15 15 0 17 -39 35 -76 35 -15 0
-24 6 -24 15 0 8 -9 23 -20 32 -11 10 -20 21 -20 26 0 5 7 1 16 -7 8 -9 22
-16 30 -16 8 0 14 -7 14 -15 0 -8 5 -15 10 -15 12 0 13 35 1 76 -7 26 -5 34
10 45 22 15 25 35 7 41 -9 3 -8 9 5 22 9 10 17 26 17 37 0 10 7 19 15 19 10 0
15 11 15 35 0 19 5 35 10 35 6 0 10 11 10 25 0 18 5 25 20 25 11 0 20 5 20 10
0 6 -7 10 -16 10 -12 0 -15 6 -10 27 5 17 3 29 -6 35 -10 8 -10 11 0 15 20 8
15 43 -7 43 -11 0 -22 -6 -24 -12 -3 -8 -6 -6 -6 5 -1 11 -9 17 -26 17 -14 0
-25 5 -25 10 0 6 11 10 25 10 20 0 25 5 25 25 0 18 5 25 19 25 22 0 31 19 31
61 l0 30 -57 -3 c-57 -3 -58 -4 -59 -33 -2 -64 -3 -67 -26 -59 -13 3 -24 11
-26 15 -5 14 -24 11 -36 -6 -6 -8 -27 -17 -46 -20 -29 -5 -31 -4 -12 4 12 6
22 17 22 26 0 8 6 15 14 15 8 0 17 8 19 18 4 16 5 16 6 0 2 -37 51 -7 51 31 0
14 6 21 20 21 12 0 20 7 20 18 0 9 3 27 6 40 6 20 3 22 -30 22 -34 0 -36 1
-36 35 0 19 5 35 10 35 14 0 12 28 -2 32 -8 3 -1 17 20 37 39 40 46 99 11 108
-17 5 -19 11 -13 37 3 17 10 33 15 34 5 2 9 11 9 19 0 28 -74 10 -109 -26 -17
-17 -35 -31 -41 -31 -5 0 -10 -6 -10 -14 0 -7 -13 -16 -30 -19 -21 -5 -30 -12
-30 -27 0 -11 5 -20 10 -20 6 0 10 -11 10 -25 0 -28 -15 -33 -24 -9 -5 13 -16
16 -54 11 -26 -2 -39 -3 -29 0 9 2 17 10 17 17 0 6 13 23 30 36 33 28 41 76
13 82 -16 3 -16 4 0 20 9 9 17 21 17 27 0 5 6 14 13 18 8 4 16 16 20 26 3 9
16 17 27 17 11 0 20 4 20 9 0 5 13 12 29 16 21 4 31 14 36 33 4 15 11 30 17
33 5 3 11 23 13 44 4 39 17 55 48 55 13 0 17 8 17 35 0 37 -25 50 -36 20 -4
-8 -12 -15 -20 -15 -8 0 -14 -9 -14 -20 0 -11 -7 -20 -15 -20 -9 0 -18 -7 -21
-15 -11 -27 -24 -16 -24 20 0 19 5 35 10 35 15 0 12 19 -4 36 -19 18 -36 18
-36 -1 0 -8 -9 -15 -20 -15 -11 0 -20 -5 -20 -11 0 -6 -7 -4 -15 5 -17 16 -21
76 -5 76 6 0 10 -4 10 -10 0 -5 4 -10 10 -10 5 0 8 19 6 43 -2 23 -1 30 1 15
6 -37 39 -46 64 -19 11 12 28 21 39 21 20 0 28 18 28 68 1 25 -3 32 -19 32
-10 0 -19 -7 -19 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -7 -15 -15 0 -8 5 -15 12
-15 6 0 9 -2 7 -5 -3 -2 -15 -2 -27 2 -18 4 -22 12 -22 49 0 41 2 44 28 44 54
0 72 10 72 41 0 24 4 28 27 26 22 -2 29 3 31 21 2 14 10 22 24 22 16 0 19 5
14 23 -3 12 -6 26 -6 30 0 12 -61 -3 -78 -19 -7 -8 -22 -14 -33 -14 -10 0 -19
-4 -19 -10 0 -5 -7 -10 -15 -10 -9 0 -15 9 -15 25 0 16 6 25 15 25 9 0 15 9
15 25 0 14 9 33 20 42 24 21 28 68 5 60 -8 -4 -17 -1 -21 5 -5 7 10 9 50 4
l56 -7 0 35 c0 20 -4 36 -10 36 -5 0 -10 -4 -10 -10 0 -5 -11 -10 -25 -10 -16
0 -25 6 -25 15 0 16 -20 19 -58 9 -19 -5 -22 -12 -18 -30 5 -17 2 -24 -9 -24
-8 0 -15 -4 -15 -10 0 -5 5 -10 10 -10 6 0 10 -11 10 -25 0 -20 -5 -25 -25
-25 -32 0 -45 -28 -22 -45 21 -15 22 -25 2 -25 -21 0 -19 -28 3 -33 15 -4 15
-5 1 -6 -32 -2 -34 -61 -3 -61 8 0 14 -7 14 -15 0 -8 -4 -15 -9 -15 -5 0 -11
-18 -15 -40 -4 -24 -12 -40 -20 -40 -8 0 -16 -7 -20 -15 -3 -8 -17 -15 -31
-15 -16 0 -25 -6 -25 -15 0 -8 -4 -15 -9 -15 -5 0 -17 -7 -25 -16 -9 -8 -16
-11 -16 -5 0 6 -7 11 -15 11 -8 0 -15 -4 -15 -10 0 -5 -4 -10 -10 -10 -26 0
-6 20 30 30 43 11 49 23 26 46 -25 24 -46 16 -72 -31 -16 -29 -34 -30 -34 -1
0 16 6 26 14 26 8 0 17 14 21 30 5 25 12 30 35 30 34 0 70 34 70 66 0 12 6 25
13 28 10 5 10 7 0 12 -24 11 -14 33 15 31 23 -2 28 2 30 26 2 15 8 27 13 27 5
0 9 5 9 10 0 6 -11 10 -25 10 -21 0 -25 5 -25 29 0 42 -26 64 -67 58 -28 -5
-33 -3 -33 14 0 14 7 19 29 19 24 0 31 5 36 30 4 16 4 39 0 50 -4 13 -2 20 7
20 15 0 48 34 48 50 0 14 16 21 34 14 9 -3 16 -17 16 -30 0 -35 45 -34 54 1 4
14 13 25 22 25 11 0 13 6 9 20 -4 11 -2 20 4 20 6 0 11 9 11 20 0 22 17 26 40
9 11 -8 12 -12 3 -16 -18 -7 -16 -33 2 -33 8 0 15 -4 15 -10 0 -5 -7 -10 -15
-10 -19 0 -55 -31 -55 -47 0 -7 9 -13 20 -13 11 0 20 -6 20 -14 0 -31 60 -35
61 -3 0 15 2 17 6 7 8 -21 24 -30 56 -30 17 0 27 -5 27 -15 0 -8 9 -15 20 -15
13 0 20 7 20 20 0 11 -5 20 -11 20 -6 0 -3 7 5 16 9 8 16 22 16 30 0 8 4 14 9
14 11 0 21 38 14 56 -7 18 -21 18 -41 -2 -12 -13 -17 -14 -20 -4 -3 7 3 19 12
25 27 20 20 38 -11 30 -16 -3 -29 -11 -31 -16 -8 -22 -32 -7 -32 20 0 25 -4
29 -37 35 -21 3 -48 6 -61 6 -29 0 -50 20 -45 41 3 11 11 15 25 11 16 -4 19 0
18 19 -1 13 -2 30 -1 37 1 17 21 15 21 -3 0 -21 28 -19 33 3 4 16 5 16 6 0 1
-11 9 -18 21 -18 11 0 20 -7 20 -15 0 -8 7 -15 16 -15 8 0 13 -4 10 -9 -3 -5
-3 -23 0 -41 6 -28 10 -31 33 -25 14 4 41 7 59 7 21 0 32 4 32 14 0 9 11 14
35 14 25 0 35 4 35 16 0 25 -26 44 -60 44 -23 0 -33 5 -37 19 -7 29 -73 43
-73 16 0 -8 -4 -15 -9 -15 -11 0 -20 58 -14 98 6 35 23 44 23 11 0 -15 5 -20
23 -17 18 2 23 10 25 45 3 35 7 44 26 49 16 4 26 15 30 36 9 48 8 52 -24 66
-28 13 -42 42 -20 42 16 0 11 47 -6 54 -27 10 -54 7 -54 -8 0 -8 -6 -16 -14
-19 -10 -4 -12 0 -9 14 3 10 9 19 13 19 4 0 10 14 14 30 3 18 12 30 21 30 9 0
18 7 21 15 4 8 12 15 20 15 8 0 14 9 14 20 0 11 5 20 10 20 6 0 10 7 10 15 0
16 -26 21 -32 6 -7 -17 -46 -22 -61 -6 -9 8 -18 15 -22 15 -16 0 -35 -32 -35
-59 0 -26 -4 -31 -25 -31 -26 0 -35 23 -14 36 6 3 8 18 5 33 -3 14 -3 20 0 14
8 -18 34 -16 34 2 0 11 12 15 50 15 l50 0 0 70 c0 62 -2 70 -19 70 -11 0 -21
9 -24 22 -7 24 3 41 10 21 3 -7 16 -13 29 -13 20 0 24 5 24 30 0 20 -5 30 -15
30 -8 0 -15 7 -15 15 0 8 7 15 15 15 10 0 15 10 15 30 0 23 4 30 20 30 11 0
20 -4 20 -10 0 -5 7 -10 15 -10 18 0 20 26 3 33 -10 4 -10 6 0 6 18 1 38 22
46 48 8 25 -2 73 -16 73 -17 0 -38 -28 -38 -50 0 -12 -4 -18 -10 -15 -5 3 -10
15 -10 26 0 22 -16 25 -36 5 -17 -18 -19 -82 -1 -90 6 -3 0 -3 -15 0 -34 7
-51 -22 -25 -41 21 -15 22 -35 2 -35 -8 0 -15 -4 -15 -9 0 -5 -22 -11 -50 -15
-35 -5 -50 -11 -50 -22 0 -8 6 -17 14 -20 22 -8 27 -32 10 -48 -8 -8 -18 -25
-23 -38 -7 -15 -10 -18 -10 -7 -1 9 -8 19 -16 23 -8 3 -15 12 -15 21 0 9 -9
15 -25 15 -25 0 -36 25 -13 32 6 2 11 18 11 36 0 19 5 32 13 32 6 0 15 16 18
35 4 19 11 35 16 35 6 0 10 9 10 20 0 11 -7 20 -15 20 -8 0 -15 7 -15 15 0 8
6 15 14 15 8 0 16 6 19 14 6 15 47 29 47 15 0 -5 11 -9 25 -9 18 0 25 5 25 19
0 11 9 22 19 26 11 3 22 12 25 20 3 8 17 15 30 15 26 0 32 10 38 70 2 19 10
26 31 28 15 2 28 6 28 10 1 4 3 35 4 70 l2 62 -33 0 c-19 0 -34 -4 -34 -10 0
-5 7 -10 15 -10 10 0 15 -10 15 -30 0 -34 -19 -41 -27 -10 -7 25 -33 26 -33 2
0 -15 -6 -18 -28 -14 -16 2 -33 11 -39 21 -13 21 -5 81 12 81 7 0 16 6 18 13
4 9 6 9 6 0 1 -18 31 -16 31 2 0 11 8 13 30 9 24 -5 30 -3 30 11 0 9 9 19 20
22 11 3 20 12 20 19 0 17 32 18 47 3 16 -16 43 12 43 46 0 14 7 28 15 31 19 8
19 34 0 34 -9 0 -15 9 -14 23 1 16 2 18 6 5 2 -10 14 -18 25 -18 18 0 19 4 14
33 -17 80 -18 97 -7 97 6 0 11 9 11 20 0 11 -4 20 -10 20 -5 0 -10 -9 -10 -20
0 -11 -7 -20 -15 -20 -10 0 -15 10 -15 30 0 25 4 30 25 30 16 0 25 6 25 15 0
8 5 15 10 15 6 0 10 9 10 21 0 18 -4 20 -45 13 -48 -7 -54 -2 -30 22 8 9 15
29 15 45 0 20 5 29 16 29 13 0 15 7 9 38 -4 20 -11 38 -16 40 -5 2 -9 10 -9
18 0 8 -8 14 -17 14 -12 0 -14 3 -6 8 22 14 26 58 7 77 -18 18 -30 120 -13
109 14 -8 49 6 49 21 0 13 -21 25 -46 25 -8 0 -14 -7 -14 -15 0 -8 -6 -15 -13
-15 -21 0 -77 -57 -77 -79 0 -11 -9 -25 -20 -31 -14 -7 -20 -7 -20 0 0 5 6 10
14 10 8 0 16 16 20 40 3 22 11 40 16 40 6 0 10 9 10 20 0 11 -7 20 -15 20 -8
0 -15 7 -15 15 0 9 7 18 15 21 8 4 16 16 16 28 1 20 2 20 6 1 5 -19 20 -35 36
-35 4 0 7 16 7 35 0 24 5 35 15 35 8 0 15 9 15 19 0 16 -7 20 -40 19 -25 0
-40 -5 -40 -13 0 -13 -21 -25 -45 -25 -8 0 -11 7 -8 20 3 11 9 20 14 20 5 0 9
7 9 16 0 8 5 12 10 9 15 -9 50 4 50 20 0 7 -7 16 -16 19 -21 8 -64 -3 -64 -17
0 -6 -3 -8 -6 -4 -10 10 7 47 22 47 8 0 14 7 14 15 0 10 10 15 30 15 17 0 30
5 30 10 0 6 6 10 14 10 8 0 17 11 20 25 4 14 11 25 16 25 6 0 10 9 10 20 0 19
-4 20 -67 14 -44 -4 -73 -12 -80 -22 -11 -13 -13 -10 -13 21 0 30 4 37 20 37
15 0 20 7 20 29 0 22 6 30 25 35 14 4 25 13 25 21 0 9 -10 15 -25 15 -16 0
-25 -6 -25 -16 0 -9 -11 -25 -25 -36 -14 -11 -25 -24 -25 -29 0 -6 -6 -16 -12
-23 -7 -8 -20 -23 -28 -35 -8 -11 -20 -21 -26 -21 -14 0 -27 -46 -18 -62 4 -5
1 -14 -5 -18 -16 -9 -3 -60 15 -60 8 0 14 7 14 15 0 8 9 15 20 15 17 0 19 -5
14 -25 -5 -20 -2 -25 12 -26 11 0 14 -3 7 -6 -7 -2 -15 -20 -19 -39 -3 -19
-10 -34 -15 -34 -14 0 -11 -28 4 -33 6 -2 0 -2 -15 0 -46 7 -68 -5 -68 -38 0
-19 -5 -29 -15 -29 -23 0 -18 -30 10 -56 33 -30 32 -57 -2 -73 -16 -8 -36 -17
-45 -22 -11 -6 -18 -5 -18 1 0 5 -7 10 -15 10 -8 0 -15 -7 -15 -15 0 -8 -6
-15 -14 -15 -10 0 -16 -13 -18 -37 l-3 -38 -22 23 c-13 12 -27 22 -33 22 -5 0
-10 9 -10 20 0 11 -7 20 -15 20 -8 0 -15 7 -15 15 0 8 4 15 9 15 5 0 11 9 14
20 3 11 12 20 20 20 8 0 17 -9 20 -20 3 -14 14 -20 32 -20 18 0 29 6 32 20 3
11 14 20 24 20 14 0 19 7 19 30 0 17 5 30 10 30 6 0 10 7 10 15 0 8 -7 15 -15
15 -8 0 -15 7 -15 15 0 21 -28 19 -33 -2 -4 -17 -5 -17 -6 0 0 9 -5 17 -11 17
-5 0 -10 7 -10 15 0 9 9 15 25 15 14 0 25 5 25 10 0 6 11 10 24 10 13 0 27 6
30 13 5 10 7 10 12 0 8 -17 22 -16 40 1 20 21 17 46 -5 46 -23 0 -30 15 -24
56 5 28 2 32 -26 37 -26 6 -31 11 -31 37 0 20 5 30 15 30 10 0 15 -10 15 -30
0 -33 13 -38 36 -16 16 17 19 46 4 46 -12 0 -13 37 -1 42 5 2 12 18 16 37 7
31 6 34 -15 28 -19 -5 -21 -3 -15 21 8 32 16 41 22 25 5 -15 43 -17 43 -3 0 6
-9 19 -20 30 -12 12 -20 33 -20 55 0 19 -4 35 -9 35 -12 0 -24 66 -16 90 8 26
25 26 25 0 0 -11 5 -20 10 -20 6 0 10 4 10 10 0 5 11 12 25 16 14 3 25 13 25
21 0 8 9 17 20 20 34 9 38 63 4 63 -27 0 -14 30 16 37 17 3 30 11 30 18 0 8 8
11 20 8 15 -4 20 0 20 16 0 11 5 21 10 21 6 0 10 7 10 15 0 8 -4 15 -10 15 -9
0 6 22 38 55 6 8 12 24 12 37 0 47 12 68 36 68 13 0 24 5 24 10 0 6 9 10 20
10 16 0 20 7 20 30 0 23 4 30 20 30 11 0 20 5 20 10 0 6 7 10 15 10 8 0 15 9
15 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 11 -7 20 -15 20 -8 0 -15 -6
-15 -14 0 -7 -9 -16 -20 -19 -17 -4 -20 -14 -20 -57 0 -43 -3 -51 -16 -46 -9
3 -22 6 -30 6 -8 0 -14 7 -14 15 0 8 -7 15 -16 15 -13 0 -15 -7 -10 -34 6 -31
5 -34 -14 -29 -20 5 -28 23 -10 23 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0 -10
7 -10 15 0 8 5 15 10 15 6 0 15 11 20 25 5 14 14 25 20 25 5 0 10 7 10 15 0 8
-4 15 -10 15 -16 0 -12 57 5 64 9 3 15 18 15 35 0 22 5 30 25 35 42 11 30 66
-15 66 -5 0 -11 -8 -13 -17 -3 -13 -5 -11 -6 6 0 13 -9 30 -18 37 -12 8 -18
26 -18 55 0 40 -2 44 -32 52 -45 13 -63 3 -63 -33 0 -18 -6 -33 -15 -36 -9 -4
-15 -19 -16 -38 0 -17 -3 -25 -6 -18 -6 16 -21 15 -39 -2 -18 -19 -18 -32 1
-40 8 -3 15 -11 14 -18 0 -10 -2 -10 -6 0 -6 14 -43 16 -43 2 0 -5 -4 -10 -10
-10 -5 0 -10 14 -10 30 0 23 4 30 20 30 13 0 20 7 20 19 0 16 -7 19 -52 18
-32 -1 -53 4 -56 11 -2 7 -23 12 -48 12 -30 0 -46 5 -50 15 -4 8 -15 15 -25
15 -10 0 -19 -7 -19 -15 0 -8 -4 -15 -10 -15 -5 0 -10 9 -10 20 0 11 5 20 10
20 6 0 10 7 10 15 0 8 -4 15 -10 15 -18 0 -10 21 17 46 18 17 29 21 32 13 2
-7 -4 -18 -13 -24 -21 -16 -20 -21 4 -45 21 -21 70 -28 70 -10 0 6 7 10 15 10
8 0 15 -5 15 -11 0 -19 28 -39 54 -39 22 0 26 5 26 29 0 24 5 29 31 35 17 3
47 6 65 6 38 0 45 18 18 44 -8 9 -11 16 -5 16 6 0 11 9 11 20 0 11 5 20 10 20
6 0 10 7 10 15 0 8 5 15 10 15 6 0 10 7 10 15 0 8 7 15 15 15 8 0 15 -9 15
-20 0 -11 -4 -20 -10 -20 -5 0 -10 -6 -10 -14 0 -16 79 -31 93 -17 11 11 -1
71 -14 71 -5 0 -9 9 -9 20 0 11 7 20 15 20 9 0 15 9 15 25 0 14 5 25 10 25 6
0 10 11 10 25 0 18 5 25 19 25 16 0 21 10 26 54 6 47 9 53 27 48 20 -5 38 11
38 36 0 6 -7 12 -16 12 -8 0 -22 -6 -29 -12 -39 -35 -49 -40 -85 -45 -35 -5
-40 -9 -46 -39 -3 -19 -12 -34 -20 -34 -9 0 -14 -11 -14 -30 0 -28 -3 -30 -35
-30 -24 0 -35 5 -35 14 0 8 -14 17 -31 21 -31 7 -35 28 -6 33 10 2 17 -3 17
-12 0 -9 8 -16 20 -16 11 0 20 7 20 15 0 8 -4 15 -10 15 -5 0 -10 5 -10 11 0
13 -20 33 -42 42 -24 9 -23 33 2 40 11 3 20 12 20 21 0 9 5 16 10 16 6 0 10
-7 10 -15 0 -8 5 -15 10 -15 6 0 10 5 10 10 0 6 7 10 15 10 13 0 15 10 12 49
l-5 50 37 3 c33 3 36 6 39 36 2 17 7 32 13 32 14 0 11 38 -3 42 -9 3 -8 9 5
22 35 35 17 63 -43 68 -43 3 -45 2 -43 -23 1 -22 -4 -28 -29 -33 -17 -3 -33
-4 -36 -1 -3 2 -6 26 -7 52 -1 26 -7 49 -13 51 -7 2 -12 10 -12 19 0 22 25 9
28 -15 3 -21 26 -30 36 -14 2 4 2 27 -1 50 -4 29 -10 42 -21 42 -13 0 -13 2 2
13 38 27 -12 79 -58 61 -17 -6 -30 -39 -22 -53 8 -12 -13 -71 -25 -71 -16 0
-10 -28 7 -34 12 -5 15 -14 10 -40 -4 -21 -2 -36 4 -38 20 -6 0 -36 -21 -31
-14 4 -19 0 -19 -16 0 -15 6 -21 21 -21 17 0 20 -4 15 -22 -3 -13 -6 -26 -6
-30 0 -15 -20 -8 -20 7 0 8 -7 15 -15 15 -8 0 -15 -4 -15 -10 0 -5 -11 -10
-25 -10 -14 0 -25 5 -25 10 0 15 -19 12 -37 -6 -14 -13 -14 -17 -2 -24 7 -5
10 -13 5 -17 -5 -4 -11 -2 -13 5 -3 6 -12 12 -21 13 -11 0 -12 2 -4 6 7 3 12
24 12 53 0 39 4 49 20 53 17 4 20 12 17 59 -2 44 2 57 21 76 12 12 33 22 47
22 22 0 25 4 25 35 0 24 -5 35 -15 35 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -16
-10 -9 0 -18 -9 -21 -20 -3 -11 -8 -20 -12 -21 -48 -5 -81 -2 -81 8 0 7 -9 17
-20 23 -12 6 -20 21 -20 35 0 21 -5 25 -32 25 -31 0 -33 2 -34 35 0 27 4 35
17 35 17 0 35 43 24 60 -4 6 9 10 28 10 30 0 35 4 41 30 8 40 24 38 32 -5 5
-31 10 -35 36 -35 16 0 37 -7 45 -16 17 -17 62 -11 75 9 5 8 8 6 8 -5 0 -11 8
-18 20 -18 11 0 20 -4 20 -10 0 -5 11 -10 25 -10 19 0 25 5 25 21 0 31 -25 51
-55 43 -23 -6 -25 -4 -25 29 0 19 -5 37 -12 39 -6 2 -8 9 -5 15 8 12 47 6 47
-8 0 -5 20 -9 45 -9 43 0 46 2 52 30 3 17 9 30 14 30 5 0 9 7 9 15 0 9 -9 15
-25 15 -16 0 -25 6 -25 15 0 8 -5 15 -11 15 -7 0 -9 12 -5 35 6 31 4 35 -14
35 -11 0 -20 -7 -20 -16 0 -11 -6 -14 -22 -8 -13 3 -24 11 -26 15 -6 15 -69
10 -76 -6 -10 -27 -36 -17 -36 14 0 27 3 29 55 36 38 4 55 11 55 21 0 8 7 14
15 14 8 0 15 6 15 14 0 8 7 16 15 20 9 3 15 18 15 36 0 22 5 30 18 30 16 0 18
9 19 65 0 36 -2 65 -4 65 -2 0 -11 -7 -19 -15 -22 -21 -44 -19 -44 5 0 11 -7
20 -15 20 -8 0 -15 8 -15 19 0 10 -3 31 -6 47 -5 28 -2 34 46 92 6 6 18 12 26
12 11 0 12 -3 4 -12 -40 -48 -50 -63 -50 -75 0 -7 7 -13 16 -13 17 0 44 23 44
37 0 20 26 63 38 63 6 0 12 6 12 14 0 8 4 16 10 18 5 2 12 18 16 36 5 25 3 32
-9 32 -10 0 -17 11 -19 27 -2 17 -10 29 -20 31 -14 3 -18 -4 -19 -30 0 -18 -3
-26 -6 -18 -3 8 -11 19 -17 23 -8 6 -7 10 2 14 21 8 13 23 -13 23 -20 0 -25
-5 -26 -27 -1 -17 -3 -21 -6 -10 -4 17 -33 25 -33 8 0 -5 -13 -11 -30 -14 -31
-7 -43 -37 -15 -37 8 0 15 -7 15 -15 0 -10 10 -15 29 -15 l29 0 -19 -37 c-10
-21 -19 -43 -19 -50 0 -7 -11 -13 -25 -13 -15 0 -28 -7 -32 -17 -11 -28 -36
-53 -53 -53 -13 0 -15 7 -12 30 4 25 1 30 -17 30 -12 0 -21 -6 -21 -15 0 -28
-27 -15 -34 16 -9 47 -8 49 20 49 23 0 25 3 19 30 -5 23 -3 30 9 30 9 0 16 7
16 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 16 0 13 2 13 18 -2 9 -10 17 -31 17
-48 0 -21 5 -32 18 -34 14 -3 17 4 17 32 0 25 4 36 15 36 8 0 15 7 15 15 0 9
7 18 15 21 8 4 15 13 15 20 0 8 7 17 15 20 22 9 19 51 -5 59 -24 8 -40 -1 -40
-21 0 -8 -6 -14 -14 -14 -9 0 -12 7 -9 20 3 11 9 20 14 20 5 0 9 20 9 45 0 31
5 47 15 51 8 4 15 12 15 19 0 12 45 31 63 26 11 -2 37 25 37 38 0 6 -4 11 -10
11 -18 0 -10 31 14 53 14 13 28 35 31 50 4 15 13 27 21 27 8 0 14 8 14 19 0
13 9 20 30 25 33 6 38 20 15 42 -20 20 -20 64 0 64 8 0 15 -8 15 -17 0 -15 10
-18 60 -19 52 -1 60 1 60 17 0 11 5 19 10 19 15 0 12 50 -2 50 -7 0 -5 5 5 11
9 5 17 17 17 24 0 8 5 15 10 15 6 0 10 11 10 25 0 16 -6 25 -15 25 -8 0 -15
-4 -15 -10 0 -5 -13 -10 -30 -10 -23 0 -30 -4 -30 -20 0 -11 7 -20 15 -20 8 0
15 -4 15 -10 0 -5 -7 -10 -15 -10 -10 0 -15 -11 -15 -35 0 -41 -26 -49 -36
-11 -3 15 -15 27 -27 29 -18 3 -17 5 6 6 23 1 27 5 27 31 0 17 5 30 10 30 6 0
10 7 10 15 0 8 -4 15 -10 15 -5 0 -12 11 -16 25 -4 16 -13 25 -26 25 -16 0
-19 -5 -16 -25 4 -17 1 -25 -8 -25 -8 0 -16 -16 -20 -40 -6 -34 -10 -40 -30
-40 -15 0 -24 6 -24 15 0 8 5 15 10 15 6 0 10 7 10 15 0 8 7 15 15 15 24 0 18
27 -7 34 -35 9 -38 8 -38 -14 0 -11 -7 -23 -15 -26 -8 -4 -15 -13 -15 -21 0
-25 -35 -10 -39 17 -1 14 -8 28 -15 32 -7 5 -5 8 7 8 9 0 25 9 34 19 12 14 24
18 45 14 21 -4 28 -2 28 10 0 8 -7 17 -15 21 -24 9 -17 24 14 31 37 8 60 75
26 75 -8 0 -15 -7 -15 -15 0 -8 -9 -15 -20 -15 -11 0 -20 -7 -20 -15 0 -18
-30 -20 -31 -2 -1 6 0 24 1 39 2 35 -40 59 -71 40 -25 -16 -29 -15 -29 8 0 14
-9 21 -35 26 -35 6 -49 24 -20 24 8 0 15 7 15 15 0 8 9 19 21 26 17 9 20 18
17 54 -2 31 0 41 10 38 6 -3 12 -11 12 -19 0 -8 9 -14 20 -14 15 0 20 7 20 25
0 16 -6 25 -15 25 -8 0 -15 7 -15 15 0 8 -7 15 -15 15 -9 0 -18 -7 -21 -15 -4
-8 -12 -15 -20 -15 -8 0 -14 7 -14 15 0 8 9 15 20 15 16 0 20 7 20 30 0 20 5
30 15 30 8 0 15 -9 15 -19 0 -11 7 -27 16 -35 20 -21 74 -21 74 -1 0 8 9 15
20 15 11 0 20 -4 20 -10 0 -5 14 -10 30 -10 l30 0 0 80 c0 47 -4 80 -10 80
-16 0 -12 27 5 34 17 6 20 51 5 61 -14 9 -50 -4 -50 -18 0 -7 -10 -12 -23 -12
-20 0 -22 4 -18 38 2 30 0 37 -14 37 -9 0 -19 -9 -22 -20 -3 -13 -14 -20 -29
-20 -18 0 -24 -5 -24 -22 0 -43 -12 -68 -31 -68 -12 0 -19 -7 -19 -20 0 -11
-7 -20 -15 -20 -8 0 -15 6 -15 14 0 18 -18 24 -57 19 -29 -5 -33 -9 -39 -48
-4 -31 -12 -45 -25 -48 -20 -5 -27 17 -9 28 18 11 11 57 -10 62 -17 5 -17 7
-5 20 19 19 19 48 0 67 -19 18 -19 26 -1 26 8 0 17 -15 20 -35 4 -19 11 -35
16 -35 6 0 10 -7 10 -15 0 -8 7 -15 15 -15 9 0 15 9 15 25 0 16 6 25 15 25 9
0 15 9 15 25 0 14 -4 25 -10 25 -5 0 -10 11 -10 25 0 15 -6 25 -14 25 -8 0
-17 11 -20 25 -4 14 -13 25 -21 25 -8 0 -15 7 -15 15 0 23 -31 18 -37 -6 -5
-16 -11 -19 -34 -14 -18 4 -29 2 -29 -4 0 -17 -27 -13 -34 5 -8 22 -8 24 7 24
6 0 18 15 26 33 18 40 25 44 60 39 22 -3 30 -10 35 -33 5 -27 36 -43 36 -19 0
6 9 10 20 10 11 0 20 -4 20 -10 0 -5 -9 -10 -21 -10 -14 0 -19 -5 -17 -17 4
-24 51 -29 72 -7 9 9 20 13 24 9 4 -4 1 -10 -5 -12 -7 -3 -13 -16 -13 -29 0
-19 5 -24 24 -24 14 0 26 6 29 13 2 6 2 0 0 -15 -4 -23 -1 -28 16 -28 15 0 22
9 27 35 3 19 10 35 15 35 5 0 9 7 9 15 0 10 10 15 30 15 27 0 30 3 30 30 0 36
27 52 45 27 13 -19 35 -23 35 -7 0 6 7 10 15 10 8 0 15 -5 15 -10 0 -6 -7 -13
-15 -16 -8 -4 -15 -12 -15 -20 0 -8 -7 -14 -15 -14 -8 0 -15 -9 -15 -20 0 -11
7 -20 15 -20 8 0 15 9 15 20 0 11 7 20 15 20 8 0 15 -7 15 -15 0 -9 7 -18 15
-21 17 -7 22 -94 5 -94 -5 0 -10 -9 -10 -20 0 -16 7 -20 30 -20 17 0 30 -4 30
-10 0 -5 -9 -10 -20 -10 -11 0 -20 -7 -20 -15 0 -8 -4 -15 -10 -15 -5 0 -10
-11 -10 -25 0 -14 5 -25 10 -25 6 0 10 -7 10 -15 0 -13 7 -15 38 -9 20 4 40
12 45 18 5 8 10 6 17 -4 7 -11 5 -16 -4 -18 -13 -3 -30 -31 -24 -38 2 -1 23 0
47 3 45 6 46 7 56 66 4 23 11 37 20 37 16 0 19 28 9 68 -5 17 -14 22 -40 22
-27 0 -34 4 -34 20 0 11 -4 20 -10 20 -5 0 -10 7 -10 16 0 9 -9 18 -20 21 -23
6 -27 31 -5 39 21 8 19 34 -2 34 -15 0 -18 9 -18 45 0 25 2 40 3 35 6 -16 32
-12 32 5 0 8 -4 15 -10 15 -5 0 -10 5 -10 11 0 13 25 39 39 39 5 0 13 10 17
22 3 12 13 28 20 36 8 7 14 20 14 28 0 8 7 14 15 14 8 0 15 5 15 10 0 13 -57
13 -65 0 -3 -5 -19 -7 -35 -3 -23 4 -30 2 -30 -11 0 -10 9 -16 23 -17 17 -1
18 -2 4 -6 -10 -3 -20 -17 -23 -33 -4 -16 -14 -30 -25 -33 -10 -3 -19 -12 -19
-21 0 -9 -4 -16 -10 -16 -5 0 -10 18 -10 40 0 36 -2 40 -25 40 -14 0 -25 5
-25 10 0 6 -5 10 -11 10 -13 0 -39 -25 -39 -37 0 -14 -94 -36 -98 -24 -5 14
-32 14 -32 1 0 -5 5 -10 10 -10 6 0 10 -7 10 -15 0 -8 -5 -15 -11 -15 -6 0 -9
-12 -7 -26 3 -29 -30 -61 -39 -36 -3 6 -17 12 -33 12 -16 0 -30 7 -34 15 -3 8
-15 15 -26 15 -18 0 -20 4 -14 22 4 13 20 35 36 50 22 20 30 39 35 77 3 28 10
51 14 51 5 0 9 7 9 15 0 11 -12 15 -45 15 -25 0 -45 4 -45 8 0 4 20 14 45 21
25 7 45 17 45 22 0 16 28 10 34 -7 12 -31 83 -4 99 36 8 20 8 -29 1 -57 -4
-13 -13 -23 -20 -23 -8 0 -14 -9 -14 -20 0 -11 -7 -20 -15 -20 -8 0 -15 7 -15
15 0 8 -7 15 -15 15 -8 0 -15 -4 -15 -10 0 -5 -9 -10 -20 -10 -11 0 -20 -7
-20 -15 0 -8 6 -15 13 -15 7 0 23 -11 35 -25 24 -27 62 -34 62 -11 0 7 9 16
19 19 15 4 19 13 19 38 -1 18 4 35 10 37 7 2 12 10 12 18 0 8 7 17 15 20 18 7
20 31 3 37 -7 2 -9 8 -5 13 11 11 27 -15 27 -43 0 -26 27 -32 36 -9 3 8 12 12
20 9 8 -3 14 0 14 6 0 6 7 11 15 11 10 0 15 10 15 30 0 17 5 30 10 30 6 0 10
7 10 15 0 9 -9 15 -25 15 -14 0 -28 -7 -31 -15 -4 -8 -12 -15 -19 -15 -7 0
-16 -6 -18 -12 -4 -10 -6 -10 -6 -1 -1 6 4 15 9 18 24 15 6 25 -45 25 -30 0
-55 -4 -55 -10 0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 13 -63 13 -76 1 -5
-6 -29 -6 -61 0 -28 5 -100 8 -160 7 -103 -3 -108 -4 -111 -25 -2 -13 -10 -23
-19 -23 -11 0 -14 6 -9 25 7 28 -7 33 -30 10 -9 -8 -20 -15 -25 -15 -5 0 -9 7
-9 15 0 8 -9 15 -20 15 -16 0 -20 -7 -20 -30 0 -16 -4 -30 -10 -30 -5 0 -10 7
-10 15 0 9 -9 15 -25 15 -16 0 -25 6 -25 15 0 13 -22 15 -135 15 l-135 0 0
-39 c0 -32 -4 -41 -22 -47 -13 -4 -25 -10 -28 -15 -3 -5 -6 15 -5 44 l0 52
-197 2 c-179 2 -197 1 -201 -15 -4 -16 -5 -16 -6 1 -1 16 -25 17 -346 17
l-345 0 0 -25 c0 -18 -5 -25 -20 -25 -13 0 -20 7 -20 19 0 16 -10 21 -46 27
-57 8 -157 -1 -162 -16 -2 -5 -8 -10 -14 -10 -5 0 -16 -7 -24 -15 -20 -20 -44
-19 -44 1 0 22 -25 44 -49 44 -16 0 -21 -6 -21 -25 0 -22 -4 -25 -34 -25 -26
0 -35 5 -39 21 -5 17 -11 20 -31 15 -14 -3 -26 -2 -26 3 0 6 -126 10 -320 10
-181 0 -320 -3 -320 -8 0 -5 4 -11 10 -13 5 -1 12 -18 16 -36 6 -28 4 -31 -10
-26 -9 4 -20 20 -23 36 -5 26 -10 29 -33 26 -20 -3 -26 0 -23 9 4 10 -41 13
-231 12 -142 0 -236 -5 -236 -10 0 -6 -8 -6 -19 1 -13 6 -213 10 -575 10 -485
0 -556 -2 -556 -15 0 -19 -19 -19 -26 0 -5 13 -65 15 -450 15 -348 0 -444 -3
-444 -12 0 -10 -2 -10 -8 -1 -11 17 -653 18 -664 0 -6 -9 -8 -9 -8 0 0 10
-185 12 -831 13 l-831 0 4 -30 c4 -23 1 -30 -13 -30 -9 0 -19 -7 -23 -15 -3
-8 -11 -15 -18 -14 -10 0 -10 2 0 6 6 2 12 11 12 19 0 8 5 14 10 14 6 0 10 11
10 25 l0 25 -390 -1 -390 0 0 -29 c0 -35 -9 -37 -40 -10 -20 17 -32 19 -69 14
-36 -5 -47 -3 -57 11 -10 13 -21 16 -48 11 -20 -3 -52 -9 -71 -12 -23 -4 -35
-2 -35 5 0 15 -63 14 -78 -1 -10 -10 -17 -10 -32 0 -15 9 -110 12 -390 11
-326 -2 -403 -6 -358 -21 6 -2 12 -25 13 -51 2 -26 -1 -44 -6 -42 -12 8 -99
-16 -99 -26 0 -5 -7 -9 -15 -9 -8 0 -15 5 -15 10 0 6 -7 10 -15 10 -8 0 -15 8
-15 18 0 33 12 62 26 62 8 0 14 5 14 10 0 6 7 10 15 10 8 0 15 7 15 15 0 19
-66 21 -73 3 -4 -10 -6 -10 -6 0 -1 7 -16 12 -41 12 -33 0 -42 -4 -52 -25z
m7308 -110 c-1 -41 -2 -71 -4 -65 -7 21 -36 8 -80 -37 -26 -28 -47 -42 -50
-35 -2 7 -13 12 -24 12 -17 0 -20 -5 -16 -29 4 -26 1 -30 -28 -35 -57 -11 -80
14 -67 75 4 21 12 28 37 32 38 5 56 -1 56 -19 0 -8 9 -14 20 -14 11 0 20 5 20
10 0 6 9 19 21 31 14 14 19 30 17 52 -3 28 0 32 23 35 14 2 37 16 50 32 13 16
24 29 24 30 1 0 1 -34 1 -75z m964 -135 c0 -15 30 -12 31 3 0 7 3 6 6 -3 13
-33 63 -57 63 -30 0 6 5 10 10 10 6 0 10 -9 10 -20 0 -11 -4 -20 -10 -20 -5 0
-10 -18 -10 -40 0 -29 -4 -40 -15 -40 -9 0 -15 -10 -16 -27 -1 -17 -3 -21 -6
-10 -3 11 -14 17 -35 17 -21 0 -31 6 -35 20 -3 11 -9 20 -14 20 -5 0 -9 7 -9
15 0 8 5 15 10 15 6 0 10 11 10 25 0 20 -5 25 -25 25 -14 0 -25 5 -25 10 0 6
7 10 15 10 8 0 15 7 15 15 0 8 7 15 15 15 8 0 15 -4 15 -10z m-7560 -34 c0 -8
-5 -18 -11 -22 -6 -3 -8 -17 -5 -30 4 -13 3 -24 -1 -24 -5 0 -18 -3 -30 -6
-17 -5 -23 -2 -23 9 0 22 -15 29 -45 21 -38 -10 -34 -48 7 -60 33 -10 51 -34
25 -34 -9 0 -18 -15 -23 -37 -4 -23 -9 -31 -11 -20 -3 9 -9 17 -14 17 -5 0 -9
14 -9 31 0 24 -6 32 -30 40 -22 8 -30 17 -30 35 0 13 4 24 9 24 5 0 11 9 14
20 3 11 12 20 21 20 9 0 16 7 16 15 0 12 14 15 70 15 54 0 70 -3 70 -14z
m-380 -131 c0 -18 58 -30 104 -21 31 6 34 5 29 -14 -3 -11 -9 -20 -14 -20 -5
0 -9 -7 -9 -15 0 -8 -8 -15 -17 -14 -11 0 -13 3 -5 6 16 6 16 33 -1 33 -7 0
-20 -9 -30 -20 -9 -11 -25 -20 -35 -20 -11 0 -27 -9 -37 -20 -10 -11 -24 -20
-32 -20 -7 0 -13 -6 -14 -12 0 -10 -2 -10 -6 0 -2 6 -16 12 -29 12 -13 0 -24
6 -24 13 0 6 -3 22 -6 33 -5 18 0 22 30 28 29 6 36 11 36 31 0 22 4 25 53 34
4 0 7 -6 7 -14z m348 -292 c-41 -10 -16 -46 60 -85 12 -6 22 -17 22 -25 0 -7
11 -13 25 -13 22 0 25 -4 25 -36 l0 -37 40 7 c25 4 40 2 40 -4 0 -5 -5 -10
-11 -10 -6 0 -17 -6 -24 -14 -17 -16 -65 -9 -65 9 0 8 -10 17 -22 21 -12 4
-33 20 -47 35 -14 16 -33 29 -43 29 -10 0 -18 7 -18 15 0 9 -9 15 -24 15 -27
0 -41 33 -30 75 5 22 11 25 48 24 22 -1 33 -3 24 -6z m7462 -13 c0 -5 7 -10
15 -10 8 0 15 -9 15 -20 0 -16 -7 -20 -35 -20 -25 0 -35 -4 -35 -16 0 -9 -9
-18 -20 -21 -20 -5 -26 -35 -14 -67 8 -20 44 -21 44 -1 0 22 17 18 23 -5 6
-24 30 -26 51 -4 9 8 16 11 16 5 0 -25 31 -9 37 19 3 17 9 30 14 30 5 0 9 13
9 29 0 25 5 30 34 35 19 4 36 12 39 19 2 6 2 -17 -1 -53 -2 -36 -8 -66 -13
-68 -12 -5 -11 -32 1 -32 6 0 10 -7 10 -15 0 -8 -4 -15 -10 -15 -5 0 -10 7
-10 15 0 9 -9 15 -25 15 -23 0 -25 -4 -25 -45 0 -48 -14 -60 -24 -21 -9 38
-76 47 -76 10 0 -10 -12 -14 -44 -14 -30 0 -46 -5 -50 -15 -3 -8 -10 -15 -16
-15 -13 0 -13 37 0 42 5 2 12 18 16 36 l7 32 -57 0 c-53 0 -56 1 -56 25 0 16
6 25 15 25 8 0 15 8 15 18 1 18 22 52 34 52 3 0 6 -7 6 -15 0 -8 9 -15 20 -15
11 0 20 7 20 15 0 8 -7 15 -15 15 -31 0 -14 29 20 36 19 3 35 10 35 15 0 5 7
9 15 9 8 0 15 -4 15 -10z m430 -154 c0 -17 -15 -36 -29 -36 -26 0 -41 -21 -41
-57 0 -23 -5 -33 -15 -33 -10 0 -15 -10 -15 -30 0 -16 4 -30 9 -30 5 0 12 -11
15 -25 4 -14 13 -25 21 -25 8 0 15 -4 15 -10 0 -5 7 -10 15 -10 10 0 15 -10
15 -30 0 -20 -5 -30 -15 -30 -8 0 -15 -4 -15 -10 0 -5 7 -10 15 -10 11 0 15
-11 15 -40 0 -33 4 -42 23 -50 l22 -9 -22 0 c-19 -1 -23 -7 -23 -31 0 -29 -1
-30 -47 -30 l-46 0 22 19 c13 11 27 21 32 23 5 2 12 24 15 51 6 39 4 47 -10
47 -9 0 -16 -7 -16 -15 0 -8 -4 -15 -10 -15 -5 0 -10 -9 -10 -20 0 -11 -4 -20
-10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 27 10 60 0 59 -1 60 -27 60
-39 0 -49 9 -63 58 -7 23 -17 42 -22 42 -4 0 -8 7 -8 15 0 8 5 15 10 15 6 0
10 -7 10 -15 0 -8 9 -15 20 -15 11 0 20 6 20 14 0 8 6 21 14 28 7 8 17 24 20
36 4 12 12 22 17 22 5 0 9 16 9 35 0 34 1 35 40 35 22 0 40 -2 40 -4z m-1678
-180 c4 -6 0 -13 -9 -15 -37 -7 -61 -61 -28 -61 10 0 15 -10 15 -30 0 -17 5
-30 13 -30 6 0 4 -5 -5 -11 -10 -5 -18 -17 -18 -24 0 -9 -10 -15 -24 -15 -20
0 -26 -8 -37 -45 -15 -49 -24 -56 -34 -25 -3 11 -13 20 -22 20 -10 0 -13 6 -9
16 3 9 6 20 6 25 0 5 11 9 25 9 22 0 25 4 25 34 0 22 -6 36 -16 40 -24 9 -44
7 -44 -5 0 -23 -30 -41 -60 -34 -29 6 -30 5 -30 -28 0 -20 6 -37 13 -40 7 -2
8 -8 3 -13 -5 -5 -11 -4 -13 4 -9 26 -53 -1 -53 -32 0 -9 -4 -16 -10 -16 -16
0 -12 57 5 64 22 8 20 63 -2 69 -14 4 -12 5 5 6 21 1 33 31 12 31 -13 0 -13
33 2 65 11 25 28 34 28 15 0 -5 9 -10 20 -10 11 0 20 7 20 15 0 10 11 15 35
15 24 0 35 -5 35 -15 0 -8 7 -15 15 -15 8 0 15 -9 15 -20 0 -11 7 -20 15 -20
8 0 15 5 15 10 0 6 7 10 15 10 8 0 15 5 15 10 0 6 -4 10 -10 10 -18 0 -11 48
8 53 22 6 55 -3 64 -17z m808 -96 c8 -15 21 -20 50 -20 33 0 40 3 40 20 0 11
5 20 10 20 11 0 13 -42 4 -77 -7 -26 -34 -32 -34 -8 0 8 -6 15 -14 15 -8 0
-16 -16 -19 -42 -3 -24 -11 -44 -16 -46 -6 -2 -11 -12 -11 -22 0 -10 -5 -20
-11 -22 -8 -3 -8 -20 2 -62 11 -49 10 -59 -2 -67 -8 -5 -18 -8 -24 -8 -5 0 -4
3 3 6 15 6 16 53 1 53 -13 0 -34 -20 -42 -40 -3 -8 -6 6 -6 33 -1 34 3 47 13
47 8 0 17 12 21 27 3 15 12 32 18 36 9 6 9 10 0 14 -7 2 -13 11 -13 18 0 8 -7
21 -16 29 -8 9 -11 16 -5 16 6 0 11 7 11 15 0 8 7 15 15 15 22 0 20 57 -2 63
-17 4 -17 5 -1 6 10 0 22 -8 28 -19z m-440 -114 c0 -13 -6 -26 -12 -28 -9 -3
-8 -9 5 -22 23 -23 22 -26 -13 -26 -20 0 -30 5 -30 15 0 8 -9 15 -20 15 -11 0
-20 -6 -20 -12 0 -10 -3 -10 -9 0 -13 21 -46 10 -49 -16 -3 -19 1 -22 27 -22
16 0 36 -6 43 -14 8 -8 23 -16 34 -18 11 -3 1 -8 -26 -12 -25 -5 -48 -14 -52
-20 -5 -7 -8 -5 -8 7 0 10 -8 17 -20 17 -15 0 -20 7 -20 29 0 25 -4 29 -39 35
-37 6 -51 20 -51 52 0 8 9 14 20 14 11 0 20 -7 20 -15 0 -9 9 -15 25 -15 16 0
25 6 25 15 0 8 9 15 20 15 11 0 20 -4 20 -10 0 -5 9 -10 20 -10 11 0 20 5 20
10 0 6 5 10 10 10 6 0 10 -7 10 -15 0 -8 8 -15 19 -15 13 0 20 9 25 30 7 36
26 40 26 6z m2634 -101 c7 -38 28 -46 45 -17 8 12 13 14 18 5 5 -6 25 -14 46
-18 78 -15 80 -44 10 -121 -27 -29 -39 -30 -46 -4 -3 11 -12 20 -21 20 -9 0
-16 6 -16 14 0 15 -21 26 -52 26 -16 0 -19 -5 -14 -24 4 -17 -1 -31 -20 -51
-14 -15 -26 -26 -28 -24 -12 15 -24 145 -15 161 6 10 7 27 4 38 -5 16 0 19 32
23 21 2 41 4 44 5 4 1 10 -14 13 -33z m-1454 1 c0 -13 6 -26 12 -28 9 -3 8 -9
-5 -22 -9 -10 -17 -23 -17 -31 0 -7 -6 -15 -12 -18 -10 -3 -10 -7 1 -20 12
-15 11 -17 -3 -17 -9 0 -16 7 -16 15 0 8 -7 15 -15 15 -8 0 -15 -4 -15 -10 0
-5 -7 -10 -15 -10 -10 0 -15 -11 -15 -35 0 -19 4 -35 9 -35 5 0 11 -9 14 -20
3 -13 0 -20 -9 -20 -8 0 -17 -7 -20 -15 -4 -8 -10 -15 -15 -15 -5 0 -9 7 -9
15 0 8 5 15 10 15 6 0 10 7 10 15 0 8 -7 15 -15 15 -10 0 -15 11 -15 35 0 44
-25 48 -33 5 -8 -37 -23 -39 -31 -5 -4 14 -11 25 -16 25 -6 0 -10 9 -10 20 0
11 7 20 15 20 8 0 15 9 15 20 0 11 5 20 10 20 6 0 10 7 10 15 0 9 10 15 26 15
14 0 23 -4 19 -10 -10 -16 5 -50 21 -50 8 0 14 5 14 10 0 6 16 10 35 10 24 0
35 5 35 15 0 8 5 15 10 15 6 0 10 11 10 25 0 14 -4 25 -10 25 -5 0 -10 5 -10
10 0 6 7 10 15 10 9 0 15 -9 15 -24z m-870 -226 c20 -20 28 -80 11 -80 -6 0
-13 -20 -16 -44 -7 -47 -20 -56 -77 -56 -16 0 -28 -4 -28 -10 0 -5 -7 -10 -15
-10 -22 0 -19 52 5 80 11 13 20 29 20 37 0 7 7 13 16 13 23 0 43 24 50 59 8
37 8 37 34 11z m-968 -31 c2 -6 32 -13 66 -15 39 -2 69 -10 78 -19 14 -13 14
-15 -1 -15 -12 0 -16 -6 -12 -19 3 -12 -1 -22 -9 -25 -8 -3 -14 1 -14 9 0 8
-4 15 -10 15 -5 0 -10 -16 -10 -36 0 -43 30 -73 75 -75 17 -1 23 -3 13 -6 -10
-2 -18 -11 -18 -18 0 -7 -5 -15 -10 -17 -6 -2 -12 -30 -13 -62 -2 -36 -7 -61
-15 -64 -6 -2 -12 -10 -12 -18 0 -8 -4 -14 -10 -14 -5 0 -10 -13 -10 -30 0
-16 -4 -30 -10 -30 -5 0 -10 5 -10 10 0 23 -29 8 -33 -17 -2 -18 -5 -11 -6 19
0 27 -6 48 -13 51 -7 2 -1 2 15 0 23 -4 27 -1 28 19 1 17 3 18 6 6 2 -10 11
-18 19 -18 10 0 14 13 14 51 l0 51 -32 -7 -33 -7 25 23 c38 37 54 77 36 95 -8
8 -26 14 -40 14 -19 0 -26 5 -26 20 0 13 -7 20 -20 20 -11 0 -20 6 -20 14 0 9
8 12 26 8 24 -4 25 -2 19 19 -14 43 -65 70 -65 34 0 -8 5 -15 10 -15 6 0 10
-4 10 -10 0 -5 -7 -10 -15 -10 -8 0 -15 7 -15 16 0 12 -6 15 -25 10 -18 -5
-25 -2 -25 9 0 10 10 15 30 15 20 0 30 5 30 15 0 17 27 20 32 4z m1488 -14 c0
-14 -4 -25 -10 -25 -5 0 -10 -7 -10 -15 0 -8 -9 -19 -20 -25 -25 -13 -27 -62
-4 -85 15 -15 14 -15 -10 -9 -18 4 -26 2 -26 -7 0 -12 -2 -12 -9 0 -5 8 -16
11 -25 8 -10 -4 -16 -1 -16 8 0 9 -10 15 -25 15 -14 0 -25 -4 -25 -9 0 -5 -9
-13 -20 -16 -23 -7 -28 -59 -7 -69 6 -3 1 -3 -13 0 -14 4 -26 11 -28 15 -2 5
-10 9 -17 9 -7 0 -15 9 -18 20 -3 12 0 20 7 20 6 0 19 9 29 20 9 11 24 20 32
20 8 0 15 4 15 9 0 5 6 11 14 14 21 8 56 -4 56 -19 0 -8 7 -14 15 -14 18 0 21
76 3 82 -14 5 18 41 45 51 9 3 17 11 17 17 0 5 11 10 25 10 20 0 25 -5 25 -25z
m-1960 -31 c0 -20 5 -24 30 -24 22 0 30 5 31 18 0 12 3 11 10 -7 7 -17 15 -22
32 -18 35 9 61 -17 60 -59 -1 -43 -3 -45 -30 -38 -12 3 -24 17 -27 30 -4 13
-13 24 -22 24 -20 0 -44 -24 -44 -44 0 -9 -7 -16 -15 -16 -8 0 -15 -7 -15 -15
0 -8 -9 -15 -20 -15 -27 0 -30 -60 -2 -61 12 0 11 -3 -7 -10 -19 -7 -22 -15
-19 -39 3 -20 0 -30 -9 -30 -7 0 -13 7 -13 15 0 8 -8 15 -17 15 -12 0 -14 3
-6 8 6 4 15 20 18 35 4 15 11 27 16 27 5 0 9 9 9 20 0 16 -7 20 -30 20 -20 0
-30 5 -30 15 0 9 -9 15 -21 15 -19 0 -20 1 -3 17 13 13 19 14 22 5 7 -23 30
-12 37 19 6 27 10 30 36 24 26 -6 29 -4 29 20 0 23 -4 26 -21 22 -18 -5 -20
-2 -15 16 3 12 6 27 6 34 0 7 7 10 15 7 8 -4 15 -17 15 -30z m2434 11 c3 -8
-1 -21 -9 -29 -18 -17 -19 -26 -4 -26 16 0 39 -26 39 -44 0 -9 5 -16 11 -16 6
0 8 -9 4 -21 -10 -29 3 -69 21 -69 12 0 15 6 10 25 -5 18 -2 27 9 31 25 10 18
34 -10 34 -16 0 -25 6 -25 15 0 8 5 15 10 15 14 0 12 27 -2 33 -8 3 -6 6 5 6
9 1 17 -6 17 -14 0 -18 26 -20 34 -2 5 10 7 10 12 0 3 -7 14 -13 24 -13 12 0
20 -10 24 -36 6 -30 3 -38 -18 -55 -14 -11 -26 -27 -26 -35 0 -8 -7 -14 -15
-14 -11 0 -15 -12 -15 -45 0 -38 3 -45 20 -45 12 0 20 7 21 18 0 13 2 14 6 2
3 -8 11 -18 17 -22 7 -5 5 -8 -5 -8 -17 0 -34 -44 -24 -60 3 -6 -1 -7 -9 -4
-9 3 -16 12 -16 20 0 8 -7 14 -15 14 -8 0 -15 7 -15 15 0 8 -8 15 -17 15 -11
0 -14 3 -7 8 14 9 27 72 15 72 -26 0 -41 -15 -41 -40 0 -16 -7 -30 -15 -34 -8
-3 -15 -12 -15 -21 0 -8 -4 -15 -9 -15 -4 0 -11 -21 -14 -46 -5 -47 -18 -64
-50 -64 -10 0 -25 -7 -34 -15 -21 -21 -22 -18 -16 37 4 37 10 49 26 54 36 11
49 31 37 54 -7 13 -7 20 0 20 5 0 10 16 10 35 0 19 -4 35 -10 35 -15 0 -12 19
4 36 20 20 26 18 26 -11 0 -18 5 -25 20 -25 16 0 20 7 20 30 0 20 -5 30 -15
30 -18 0 -20 16 -2 24 10 5 10 7 0 12 -7 3 -13 16 -13 29 0 17 -7 24 -31 29
-17 3 -35 3 -40 0 -5 -3 -9 4 -9 15 0 12 -4 21 -10 21 -5 0 -10 9 -10 20 0 11
7 20 15 20 8 0 15 -6 15 -14 0 -15 45 -30 60 -21 6 4 10 17 10 31 0 26 15 32
24 9z m-7100 -217 c-2 -34 1 -38 22 -38 13 0 24 -4 24 -10 0 -5 11 -12 25 -16
14 -3 25 -10 25 -15 0 -5 7 -9 15 -9 8 0 15 5 15 10 0 6 10 10 23 10 21 0 22
-4 22 -70 0 -72 10 -91 45 -89 30 2 61 -13 56 -28 -3 -8 -6 -19 -6 -24 0 -5
-7 -9 -15 -9 -8 0 -15 5 -15 10 0 6 -20 10 -45 10 -36 0 -51 -5 -69 -25 -30
-32 -44 -32 -48 3 -2 19 -9 28 -25 30 -13 2 -23 10 -23 18 0 8 -4 14 -10 14
-5 0 -10 9 -10 20 0 11 6 20 14 20 8 0 16 6 19 13 2 6 2 -1 -1 -16 -3 -20 2
-34 17 -48 35 -33 51 -25 51 26 0 25 5 45 10 45 6 0 10 7 10 15 0 8 -4 15 -10
15 -5 0 -10 9 -10 20 0 17 -7 20 -39 20 -22 0 -46 6 -53 14 -8 7 -34 16 -60
20 -45 7 -46 8 -50 49 -3 23 -3 45 -2 48 2 3 25 6 52 5 l48 -1 -2 -37z m6030
-13 c4 -14 11 -25 17 -25 8 0 7 -5 -1 -15 -9 -11 -22 -14 -40 -10 -25 6 -28 3
-34 -29 -4 -19 -13 -37 -21 -40 -22 -8 -18 -36 4 -36 11 0 26 6 33 13 20 21
58 28 58 11 0 -20 23 -17 39 5 18 24 51 17 44 -10 -4 -14 0 -19 16 -19 27 0
26 -3 -3 -34 -28 -32 -36 -32 -36 -5 0 16 -5 20 -20 16 -13 -3 -20 0 -20 9 0
8 -7 14 -15 14 -8 0 -15 -4 -15 -9 0 -5 -23 -11 -50 -13 -35 -2 -50 1 -50 10
0 25 -29 11 -35 -18 -5 -23 -12 -30 -31 -30 -28 0 -34 -30 -6 -31 13 0 14 -2
2 -6 -25 -10 -42 -33 -36 -49 4 -11 1 -14 -12 -12 -12 3 -16 12 -14 33 2 17 8
32 13 33 14 5 11 23 -6 36 -18 13 -9 56 11 56 8 0 14 9 14 20 0 11 -7 20 -15
20 -12 0 -12 3 2 20 10 11 23 20 30 20 7 0 13 -9 13 -20 0 -16 7 -20 34 -20
39 0 50 11 41 40 -7 22 -31 27 -38 8 -3 -8 -6 -6 -6 5 -1 9 -5 17 -11 17 -5 0
-10 7 -10 15 0 9 9 15 25 15 13 0 28 7 33 16 6 11 11 13 16 5 11 -17 56 -14
56 4 0 24 17 17 24 -10z m2170 -108 c-3 -10 -10 -17 -15 -17 -5 0 -9 -12 -9
-27 0 -16 -5 -35 -10 -43 -9 -13 -11 -13 -21 0 -8 11 -12 12 -16 3 -7 -18 -23
-16 -23 2 0 8 -9 15 -20 15 -11 0 -20 5 -20 10 0 6 -7 10 -15 10 -8 0 -15 -4
-15 -10 0 -5 -7 -10 -15 -10 -9 0 -18 -6 -21 -14 -3 -8 -12 -17 -19 -20 -9 -3
-12 0 -8 10 3 7 9 14 14 14 20 0 7 28 -16 34 -17 4 -25 13 -25 27 0 17 -5 20
-24 17 -17 -3 -23 -1 -19 8 6 16 41 25 46 11 4 -14 99 -36 155 -37 39 0 43 2
37 20 -5 14 -2 20 9 20 9 0 16 7 16 15 0 13 4 12 20 -3 13 -12 18 -25 14 -35z
m-1934 -23 c0 -21 4 -25 20 -21 13 3 20 0 20 -9 0 -19 26 -18 34 1 4 11 18 15
49 13 31 -2 43 -7 40 -15 -3 -7 -9 -13 -14 -13 -5 0 -9 -9 -9 -20 0 -13 -7
-20 -19 -20 -24 0 -37 -17 -46 -57 -3 -18 -13 -34 -21 -34 -35 -3 -113 -4
-104 -1 14 5 12 29 -2 36 -10 5 -10 7 0 12 6 3 12 12 12 20 0 8 9 14 20 14 26
0 27 40 2 59 -12 9 -17 21 -14 37 6 33 32 31 32 -2z m306 -14 c13 0 24 5 24
10 0 6 9 10 20 10 11 0 20 -4 20 -10 0 -5 10 -11 23 -11 16 -1 18 -2 5 -6 -22
-5 -24 -33 -3 -33 8 0 15 -6 15 -14 0 -28 -12 -46 -31 -46 -10 0 -19 -7 -19
-15 0 -8 -4 -15 -10 -15 -5 0 -10 -16 -10 -35 0 -27 -4 -35 -18 -35 -19 0 -62
-31 -62 -44 0 -11 -27 -36 -38 -36 -6 0 -13 -6 -16 -12 -2 -7 -2 14 1 47 3 33
10 62 15 63 13 6 9 70 -4 76 -7 3 2 3 20 0 39 -7 43 10 11 47 -17 20 -18 25
-6 27 8 2 18 15 22 30 4 15 10 21 12 15 3 -7 16 -13 29 -13z m-1406 -156 c0
-13 7 -15 30 -10 29 5 30 4 30 -29 0 -19 5 -35 10 -35 6 0 10 5 10 10 0 6 5
17 12 24 17 18 35 -1 40 -40 4 -41 34 -44 43 -5 5 24 12 31 31 31 13 0 24 -4
24 -10 0 -5 -4 -10 -9 -10 -6 0 -11 -23 -13 -52 -2 -30 -8 -53 -15 -56 -8 -2
-13 5 -13 17 0 14 -6 21 -20 21 -12 0 -20 -7 -20 -16 0 -13 -2 -13 -14 -2 -7
8 -17 24 -20 36 -5 16 -15 22 -36 22 -20 0 -31 6 -35 21 -8 24 -40 59 -55 59
-5 0 -10 9 -10 20 0 11 7 20 15 20 8 0 15 -7 15 -16z m1468 -70 c-10 -21 -9
-27 3 -35 23 -14 59 -11 59 6 0 8 7 15 15 15 8 0 15 6 15 14 0 8 5 18 12 22
16 10 2 -58 -16 -71 -7 -6 -16 -20 -20 -33 -4 -12 -12 -22 -17 -22 -5 0 -9 -8
-9 -19 0 -10 -7 -24 -15 -31 -8 -7 -15 -21 -15 -31 0 -11 -4 -19 -10 -19 -5 0
-10 7 -10 15 0 8 -7 15 -16 15 -12 0 -14 7 -9 30 5 23 3 30 -9 30 -16 0 -21
21 -17 68 2 29 30 58 68 71 2 1 -3 -11 -9 -25z m-1147 -85 c-31 -29 -27 -49 9
-49 23 0 30 -4 30 -20 0 -15 7 -20 25 -20 14 0 25 5 25 10 0 6 20 10 45 10 25
0 45 -4 45 -10 0 -5 -7 -10 -15 -10 -32 0 -25 -59 9 -82 8 -6 8 -10 -1 -16 -6
-4 -14 -19 -18 -34 -12 -46 -60 -45 -66 2 -5 33 -42 39 -66 11 -25 -30 -33
-26 -33 15 0 28 -5 37 -20 41 -11 3 -23 17 -27 32 -3 14 -11 29 -16 33 -6 4
-4 13 6 23 10 9 17 26 17 38 0 46 12 66 39 69 33 4 39 -18 12 -43z m-5021 26
c0 -7 8 -18 17 -23 12 -7 17 -24 18 -66 1 -56 0 -56 -32 -62 -18 -3 -33 -10
-33 -15 0 -5 -13 -9 -30 -9 -16 0 -30 5 -30 10 0 6 -7 10 -15 10 -8 0 -15 -4
-15 -10 0 -5 -4 -10 -10 -10 -18 0 -11 18 10 25 12 4 20 15 20 27 0 12 13 33
28 47 16 14 35 41 43 59 15 33 29 42 29 17z m6344 -20 c13 -14 31 -25 40 -25
16 0 16 -1 2 -16 -26 -26 -18 -34 34 -34 55 0 61 -7 30 -33 -25 -21 -66 -22
-74 -1 -5 12 -13 14 -33 9 -16 -4 -31 -12 -35 -18 -6 -9 -8 -8 -8 1 0 21 -26
42 -52 42 -21 1 -21 2 -6 13 9 7 20 25 23 40 5 17 14 27 26 27 10 0 19 5 19
10 0 16 9 12 34 -15z m-6171 -55 c6 -22 39 -26 81 -10 17 6 26 6 26 0 0 -6 5
-10 11 -10 14 0 39 -25 39 -40 0 -6 7 -13 15 -16 8 -3 17 -21 21 -40 3 -19 12
-34 19 -34 13 0 25 -21 25 -46 0 -8 -7 -14 -15 -14 -9 0 -20 -10 -25 -22 -8
-20 -9 -18 -9 15 -1 20 -5 37 -11 37 -5 0 -10 9 -10 20 0 11 -7 20 -15 20 -8
0 -15 4 -15 9 0 5 -22 14 -50 21 -27 7 -50 16 -50 20 0 4 -9 10 -19 13 -14 3
-21 15 -23 38 -3 31 -6 34 -35 34 -20 0 -33 5 -33 12 0 8 13 13 34 13 25 0 35
-5 39 -20z m7911 -46 c13 -5 16 -22 16 -80 0 -59 3 -74 15 -74 9 0 15 9 15 25
0 16 6 25 16 25 19 0 64 40 64 56 0 7 7 14 15 18 8 3 15 12 15 21 0 8 5 15 10
15 6 0 10 -7 10 -15 0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 7 15 15 15 10 0
15 -11 15 -35 0 -19 -4 -35 -10 -35 -5 0 -10 -13 -10 -30 0 -23 -4 -30 -20
-30 -11 0 -20 5 -20 10 0 6 7 10 15 10 8 0 15 6 15 14 0 17 -24 28 -45 20 -17
-7 -21 -54 -5 -54 6 0 10 -7 10 -15 0 -27 -18 -16 -25 15 -4 17 -13 30 -22 30
-10 0 -14 -6 -10 -20 4 -15 0 -20 -14 -20 -10 0 -19 -7 -19 -15 0 -10 -10 -15
-30 -15 -28 0 -30 -3 -30 -35 0 -19 4 -35 9 -35 14 0 23 -35 15 -64 -9 -35
-24 -33 -24 4 0 25 -4 30 -25 30 -15 0 -25 -6 -25 -14 0 -8 -7 -16 -15 -20 -8
-3 -15 -12 -15 -21 0 -9 -9 -15 -24 -15 -16 0 -27 -7 -31 -19 -5 -17 -10 -18
-35 -9 -17 5 -30 14 -30 20 0 15 27 38 44 38 11 0 16 9 16 26 0 23 3 25 30 19
24 -5 30 -3 30 10 0 9 -6 23 -14 31 -17 16 -46 19 -46 4 0 -5 -7 -10 -15 -10
-8 0 -15 5 -15 11 0 16 26 39 44 39 9 0 16 5 16 10 0 6 -6 10 -14 10 -8 0 -18
9 -21 20 -8 27 3 60 20 60 8 0 17 11 21 25 3 14 11 25 16 25 5 0 16 7 24 15
18 17 17 17 38 9z m-1954 -43 c0 -6 -7 -11 -15 -11 -8 0 -15 -4 -15 -10 0 -5
-13 -10 -29 -10 -22 0 -30 -6 -35 -25 -4 -15 -16 -27 -31 -31 -14 -3 -25 -13
-25 -21 0 -17 -24 -43 -41 -43 -15 0 -6 -93 10 -98 22 -7 11 -32 -14 -32 -20
0 -25 -5 -25 -25 0 -14 4 -25 9 -25 5 0 12 -11 15 -25 4 -14 11 -25 16 -25 6
0 10 -7 10 -15 0 -8 8 -15 19 -15 24 0 34 -45 15 -65 -8 -7 -14 -22 -14 -33 0
-16 -5 -19 -22 -14 -12 3 -17 7 -10 10 14 4 16 32 2 32 -5 0 -10 6 -10 14 0
23 -31 48 -51 43 -14 -4 -19 0 -19 15 0 11 8 22 20 25 23 6 27 33 5 33 -8 0
-15 7 -15 15 0 8 5 15 10 15 6 0 10 14 10 30 0 17 -4 30 -8 30 -21 0 -50 38
-53 71 -4 32 -2 35 15 31 17 -5 18 -1 12 34 -4 21 -12 41 -17 42 -5 2 -9 8 -9
13 0 15 60 10 76 -6 22 -22 54 -19 54 4 0 14 5 18 20 14 16 -4 20 0 20 21 0
16 -5 26 -15 26 -20 0 -19 16 1 24 29 11 64 7 64 -9 0 -8 5 -15 10 -15 6 0 10
5 10 10 0 6 5 17 12 24 10 11 15 11 25 0 7 -7 13 -17 13 -23z m-870 -237 c0
-22 3 -24 23 -18 12 3 24 11 25 15 7 17 20 9 26 -16 4 -14 11 -25 16 -25 6 0
10 -7 10 -15 0 -8 -7 -15 -15 -15 -10 0 -15 -10 -15 -34 0 -31 -13 -46 -40
-46 -5 0 -10 17 -12 37 -2 28 -8 39 -22 41 -11 3 -26 10 -34 18 -7 8 -22 14
-33 14 -10 0 -19 4 -19 9 0 5 -9 11 -20 14 -23 6 -27 27 -5 27 8 0 15 -4 15
-10 0 -5 11 -10 25 -10 15 0 25 6 25 14 0 14 14 24 38 25 6 1 12 -10 12 -25z
m1074 -190 c3 -9 6 -24 6 -35 0 -18 5 -20 60 -24 14 -1 35 -2 48 -3 24 -2 31
-32 7 -32 -8 0 -15 -7 -15 -15 0 -9 -8 -19 -17 -22 -10 -4 -27 -16 -38 -28
-17 -18 -23 -19 -33 -8 -24 25 -42 15 -48 -27 -4 -22 -10 -40 -15 -40 -5 0 -9
-7 -9 -15 0 -10 -11 -15 -35 -15 -33 0 -35 2 -35 35 0 29 4 35 22 35 22 0 78
54 78 77 0 8 -13 13 -35 13 -33 0 -35 2 -35 35 0 19 5 35 10 35 6 0 10 7 10
15 0 9 9 15 25 15 14 0 25 5 25 10 0 16 17 12 24 -6z m-6772 -280 c-4 -22 -2
-24 20 -19 16 4 31 1 41 -9 17 -18 47 -21 47 -6 0 6 9 10 20 10 11 0 20 -4 20
-9 0 -5 13 -12 29 -16 36 -8 47 -36 41 -107 -1 -22 -2 -21 -11 4 -6 16 -22 31
-40 37 -16 5 -29 17 -29 25 0 10 -9 16 -21 16 -16 0 -20 -5 -16 -20 3 -13 0
-20 -9 -20 -8 0 -14 -7 -14 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -9 -15 -20 0
-11 4 -20 9 -20 5 0 11 -7 15 -15 4 -10 -1 -15 -14 -15 -11 0 -20 7 -20 15 0
8 -6 15 -14 15 -9 0 -15 16 -18 49 -3 28 -12 57 -22 67 -24 24 -10 90 20 94 1
0 -1 -11 -4 -26z m6842 -26 c-3 -13 -11 -24 -15 -26 -13 -5 -11 -22 5 -38 17
-16 33 -18 38 -5 4 11 21 14 66 12 31 -1 32 -2 32 -46 0 -25 -4 -45 -9 -45 -5
0 -16 -3 -25 -6 -11 -4 -16 -1 -16 10 0 19 -16 21 -23 4 -4 -10 -8 -9 -16 2
-8 11 -27 15 -73 14 -35 -1 -57 0 -50 3 14 6 16 33 2 33 -5 0 -10 16 -10 35 0
30 3 35 23 35 12 0 28 9 35 20 18 29 45 27 36 -2z m-6336 -188 c14 -31 55 -42
66 -17 3 6 3 0 0 -15 -5 -20 -2 -30 10 -34 28 -11 17 -31 -19 -37 -19 -3 -47
-1 -63 5 -22 9 -36 8 -61 -3 -30 -13 -32 -17 -29 -56 3 -42 2 -43 -27 -43 -62
0 -73 8 -77 58 -5 56 -1 72 18 72 8 0 17 7 20 15 7 16 50 21 56 6 2 -5 25 -11
53 -13 41 -2 50 0 53 15 2 12 -3 17 -18 17 -16 0 -20 4 -16 16 3 9 6 22 6 30
0 24 14 16 28 -16z m4792 -121 c0 -10 -4 -19 -10 -19 -5 0 -10 -7 -10 -16 0
-12 7 -14 33 -9 17 4 34 11 35 16 6 15 32 10 32 -6 0 -8 5 -15 10 -15 6 0 10
-13 10 -30 0 -16 5 -30 10 -30 6 0 10 7 10 16 0 9 6 14 13 11 8 -3 11 -26 9
-77 -1 -41 -4 -76 -7 -78 -2 -2 -38 -5 -80 -6 -73 -1 -75 0 -75 23 0 26 25 50
53 51 11 0 17 8 17 25 0 16 6 25 15 25 9 0 15 9 15 21 0 17 -4 20 -22 16 -40
-11 -110 4 -116 25 -2 10 -8 18 -13 18 -5 0 -9 7 -9 15 0 8 5 15 10 15 6 0 10
6 10 14 0 15 26 25 48 18 6 -2 12 -13 12 -23z m1206 -133 c7 -8 18 -16 23 -18
5 -1 13 -15 16 -30 6 -22 3 -27 -17 -30 -22 -3 -23 -6 -19 -65 2 -53 0 -63
-13 -63 -9 0 -16 -6 -16 -14 0 -15 -25 -24 -52 -18 -11 2 -18 13 -18 28 0 20
-5 24 -29 24 -16 0 -31 -6 -35 -15 -12 -32 -26 -13 -26 35 0 28 5 50 10 50 6
0 10 -6 10 -13 0 -17 36 -47 56 -47 26 0 44 26 50 74 4 25 11 46 15 46 5 0 9
9 9 20 0 11 -4 20 -10 20 -5 0 -10 7 -10 15 0 19 37 20 56 1z m-6593 -56 c-4
-15 0 -20 15 -20 11 0 23 6 25 13 4 9 8 8 16 -3 15 -21 10 -110 -6 -110 -7 0
-13 7 -13 15 0 22 -27 18 -40 -5 -13 -25 -30 -26 -37 -1 -3 13 -17 20 -50 25
-80 11 -83 10 -83 -28 0 -26 -5 -35 -21 -39 -16 -4 -20 -11 -16 -26 3 -14 0
-21 -9 -21 -8 0 -14 -7 -14 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -7 -15 -15 0 -8
-7 -15 -15 -15 -8 0 -15 -6 -15 -12 0 -7 -6 -4 -12 6 -8 14 -8 24 1 37 6 11
11 30 11 44 0 14 5 25 10 25 6 0 10 -4 10 -10 0 -21 30 -9 36 15 3 14 10 25
15 25 5 0 9 16 9 35 0 19 5 35 10 35 6 0 10 7 10 15 0 15 32 21 93 16 17 -1
29 4 33 13 3 9 17 16 30 16 13 0 24 5 24 10 0 6 4 10 9 10 5 0 7 -9 4 -20z
m6187 -171 c0 -19 4 -21 32 -15 18 3 36 13 41 21 12 21 27 18 27 -5 0 -11 4
-20 9 -20 5 0 5 -14 -1 -34 -7 -23 -7 -52 0 -92 6 -36 7 -63 1 -68 -5 -6 -9
-18 -9 -27 0 -13 -9 -16 -45 -15 -35 1 -45 5 -45 18 0 33 12 48 41 48 32 0 43
29 12 31 -11 0 -13 3 -5 6 22 9 13 33 -13 33 -16 0 -25 -6 -25 -15 0 -8 -5
-15 -10 -15 -7 0 -8 13 -5 33 5 24 2 39 -9 50 -9 8 -16 23 -16 32 0 28 -35 13
-38 -17 -3 -29 -20 -36 -64 -30 -35 5 -42 25 -18 52 15 17 30 22 70 22 37 -1
50 3 50 14 0 8 5 14 10 14 6 0 10 -10 10 -21z m1020 -133 c0 -8 -11 -24 -25
-37 -13 -13 -25 -30 -25 -36 0 -7 -7 -13 -15 -13 -8 0 -15 -9 -15 -20 0 -16 7
-20 34 -20 32 0 34 -2 39 -41 3 -23 10 -45 15 -48 5 -3 13 -20 16 -37 7 -30 5
-32 -25 -36 -18 -3 -41 -3 -51 0 -15 3 -15 6 -3 18 16 16 35 18 35 4 0 -5 5
-10 10 -10 25 0 2 40 -30 53 -10 3 -7 6 8 6 24 1 25 4 16 38 -7 26 -27 35 -60
27 -15 -4 -24 -13 -24 -25 0 -15 -7 -19 -35 -19 -19 0 -35 -4 -35 -10 0 -5 -7
-10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 17 0 18 2 9 24 -8 20 -34 21
-34 1 0 -10 -6 -12 -17 -8 -29 11 -43 30 -43 57 0 19 7 27 35 36 19 6 35 16
35 21 0 5 27 9 59 9 75 0 91 6 91 35 0 17 7 25 23 28 12 2 23 5 25 6 1 0 2 -5
2 -13z m1516 -151 c-31 -23 -19 -38 27 -32 23 2 36 3 30 0 -15 -6 -17 -43 -3
-43 6 0 10 -11 10 -25 0 -22 4 -25 35 -25 28 0 35 -4 35 -20 0 -11 7 -20 15
-20 8 0 15 5 15 10 0 6 7 10 15 10 9 0 12 -6 9 -15 -4 -8 -1 -15 5 -15 28 0 5
-37 -37 -59 -25 -13 -30 -41 -7 -41 26 0 17 -19 -12 -26 -15 -4 -34 -13 -41
-21 -21 -21 -52 -16 -52 8 0 13 -6 20 -17 18 -42 -4 -51 2 -56 34 -5 33 7 67
23 67 6 0 10 -7 10 -15 0 -8 7 -15 16 -15 18 0 44 -23 44 -39 0 -6 5 -11 11
-11 7 0 9 8 4 23 -4 12 -9 35 -12 50 -4 21 -13 29 -34 34 -16 3 -29 9 -29 13
0 10 -37 23 -70 25 -14 1 -35 2 -47 3 -17 2 -23 8 -23 25 0 25 33 67 53 67 7
0 20 8 29 18 31 34 63 53 67 41 2 -7 -4 -18 -13 -24z m-7905 -195 c12 0 19 -7
19 -19 0 -10 7 -21 15 -25 11 -4 14 -13 9 -31 -5 -19 -2 -25 10 -25 9 0 16 7
16 15 0 9 9 15 24 15 19 0 23 -4 19 -20 -3 -11 -9 -20 -14 -20 -15 0 -10 -19
11 -40 22 -22 40 -26 40 -10 0 21 30 9 36 -15 5 -20 0 -31 -22 -50 -31 -27
-44 -31 -44 -15 0 6 -7 10 -15 10 -8 0 -15 6 -16 13 -1 6 -2 19 -3 27 -2 8 -3
23 -4 33 -1 10 -10 17 -22 17 -11 0 -20 7 -20 15 0 10 -10 15 -31 15 -30 0
-31 1 -24 40 4 26 3 40 -4 40 -6 0 -11 12 -10 28 0 15 3 21 6 15 2 -7 13 -13
24 -13z m-127 -36 c10 -4 16 -18 16 -35 0 -25 -4 -29 -26 -29 -22 0 -25 -3
-20 -22 3 -13 6 -29 6 -35 0 -7 9 -13 20 -13 11 0 20 6 20 14 0 18 50 30 90
21 25 -5 30 -11 30 -36 0 -33 -27 -69 -50 -68 -10 0 -11 2 -2 6 16 6 15 21 -2
39 -19 18 -46 18 -46 -1 0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -8 -4 -15
-9 -15 -19 0 -41 -32 -41 -61 0 -25 -4 -29 -26 -29 -17 0 -28 -6 -31 -20 -3
-11 -14 -20 -24 -20 -15 0 -19 -7 -19 -35 0 -19 -4 -35 -10 -35 -5 0 -10 -9
-10 -20 0 -13 -7 -20 -20 -20 -15 0 -20 -7 -20 -25 0 -14 -8 -29 -20 -35 -11
-6 -20 -17 -20 -25 0 -8 -4 -15 -10 -15 -5 0 -12 -11 -16 -25 -3 -14 -12 -25
-19 -25 -7 0 -22 -3 -32 -7 -17 -7 -17 -6 0 11 9 9 17 31 17 47 0 19 5 29 15
29 22 0 18 37 -5 43 -37 9 -24 34 17 33 28 -1 38 3 41 17 2 10 -3 17 -12 17
-26 0 -18 29 9 36 14 3 25 9 25 13 0 3 3 17 7 30 5 21 3 23 -16 18 -20 -5 -22
-2 -19 31 3 33 6 37 33 40 32 4 45 16 45 45 0 10 -7 17 -18 17 -10 0 -25 9
-35 20 -11 13 -29 20 -53 20 l-37 0 6 -50 c5 -45 4 -50 -13 -50 -11 0 -20 -6
-20 -13 0 -14 -35 -47 -50 -47 -22 0 -9 -38 15 -44 14 -4 25 -11 25 -16 0 -6
8 -10 18 -11 10 0 12 -3 5 -6 -7 -2 -13 -17 -13 -33 0 -19 -6 -29 -19 -33 -27
-7 -37 3 -51 51 -7 23 -17 42 -22 42 -16 0 -9 68 8 74 13 5 15 15 9 54 -4 26
-12 51 -18 55 -9 6 -9 10 1 14 6 2 12 16 12 29 0 18 5 24 21 24 24 0 49 22 49
44 0 21 43 21 64 1 8 -8 21 -15 30 -15 8 0 22 -8 32 -17 13 -13 19 -14 22 -5
5 16 20 15 38 -2 18 -19 18 -26 -1 -26 -9 0 -15 -9 -15 -25 0 -18 5 -25 20
-25 11 0 20 5 20 10 0 6 7 10 15 10 8 0 15 9 15 20 0 11 7 23 15 26 18 7 20
31 3 36 -6 2 1 17 18 35 32 34 35 36 58 27z m6176 -59 c0 -38 -3 -45 -20 -45
-11 0 -20 -7 -20 -15 0 -8 -9 -15 -20 -15 -11 0 -20 5 -20 10 0 6 -7 10 -16
10 -12 0 -15 -10 -13 -47 3 -40 0 -48 -14 -47 -9 0 -32 1 -51 2 -19 1 -41 9
-50 17 -8 8 -21 15 -28 16 -10 0 -10 2 0 6 14 6 16 33 2 33 -19 0 -10 17 16
30 15 8 28 26 31 43 5 23 10 28 27 23 12 -3 26 -6 33 -6 7 0 11 -12 10 -27 -2
-22 3 -29 22 -31 12 -2 34 -1 47 2 20 5 24 13 24 46 0 25 -5 40 -12 40 -9 0
-9 3 1 9 29 18 51 -6 51 -54z m1676 1 c8 -8 14 -19 14 -25 0 -15 40 -14 40 2
1 6 9 2 19 -10 20 -24 86 -33 93 -14 2 5 16 7 31 4 23 -5 28 -10 23 -26 -8
-33 5 -107 20 -107 7 0 15 6 17 13 3 7 6 5 6 -5 1 -10 8 -18 16 -18 11 0 13
-8 8 -32 -6 -28 -4 -31 15 -26 12 4 22 2 22 -3 0 -5 -11 -9 -24 -9 -14 0 -28
-5 -31 -11 -5 -6 -12 -4 -20 7 -7 11 -19 15 -30 11 -19 -8 -95 12 -95 25 0 4
7 8 15 8 10 0 15 10 15 30 0 28 -3 30 -35 30 -32 0 -35 -2 -35 -30 0 -32 -1
-33 -24 -24 -11 4 -13 14 -9 35 4 22 1 30 -13 36 -37 14 -55 39 -48 67 5 20 2
26 -9 26 -21 0 -30 16 -22 40 7 24 21 25 41 6z m-2586 -81 c0 -14 -4 -25 -10
-25 -5 0 -10 -7 -10 -15 0 -8 -11 -31 -25 -51 -14 -20 -25 -42 -25 -49 0 -7
-7 -15 -15 -19 -8 -3 -15 -12 -15 -20 0 -7 -7 -16 -15 -20 -8 -3 -15 -17 -15
-31 0 -14 -3 -25 -7 -25 -5 -1 -27 -5 -50 -9 -27 -5 -43 -5 -43 2 0 5 -13 7
-29 4 -27 -6 -29 -4 -33 26 l-3 32 45 -2 c56 -2 80 4 80 23 0 9 11 14 35 14
28 0 35 4 35 20 0 11 7 20 15 20 8 0 15 7 15 15 0 8 -4 15 -9 15 -5 0 -12 12
-16 27 -3 15 -12 31 -18 35 -8 5 -6 8 6 8 9 0 17 4 17 9 0 5 16 12 35 15 19 4
35 11 35 16 0 6 5 10 10 10 6 0 10 -11 10 -25z m480 -75 c0 -11 -7 -20 -15
-20 -9 0 -18 -6 -21 -14 -3 -8 -18 -18 -34 -21 -28 -6 -40 -27 -40 -72 0 -14
-6 -23 -15 -23 -8 0 -15 -9 -15 -20 0 -11 7 -20 15 -20 9 0 18 -7 21 -15 4 -8
12 -15 20 -15 8 0 14 7 14 15 0 17 26 20 36 4 3 -6 1 -15 -6 -19 -6 -4 -10
-20 -8 -36 2 -21 9 -30 26 -32 15 -2 22 2 22 12 0 30 19 16 26 -19 3 -19 9
-35 13 -35 3 0 12 -9 19 -21 16 -26 1 -49 -33 -49 -13 0 -25 -7 -29 -15 -8
-23 -35 -19 -29 4 6 25 -20 33 -69 22 -22 -5 -38 -5 -38 0 0 5 6 9 14 9 13 0
26 20 26 41 0 5 -9 9 -20 9 -13 0 -20 7 -20 19 0 19 -25 31 -66 31 -15 0 -24
8 -28 25 -4 14 -13 25 -21 25 -18 0 -20 27 -3 32 14 5 -6 30 -32 41 -12 4 -11
6 3 6 9 1 17 6 17 11 0 6 9 10 20 10 13 0 20 7 20 20 0 11 5 20 10 20 6 0 10
7 10 15 0 8 7 15 16 15 9 0 18 9 21 20 3 11 14 20 25 20 15 0 17 -4 10 -17
-12 -24 -13 -83 -1 -83 5 0 9 7 9 15 0 8 5 15 10 15 6 0 10 7 10 15 0 9 7 18
15 21 8 4 15 12 15 20 0 8 6 14 13 14 7 0 18 9 25 20 7 11 19 20 27 20 8 0 15
-9 15 -20z m-5404 -20 c14 0 24 -6 24 -15 0 -8 14 -17 35 -21 20 -3 35 -12 35
-20 0 -8 6 -14 13 -14 7 0 20 -9 30 -20 20 -24 31 -25 50 -5 8 7 28 17 43 20
16 3 23 2 17 -1 -7 -3 -13 -12 -13 -20 0 -8 -7 -14 -15 -14 -17 0 -16 2 -21
-64 -4 -56 -1 -51 -36 -60 -24 -6 -27 -3 -30 26 -2 27 -8 34 -29 36 -23 3 -28
-2 -38 -35 -10 -37 -10 -37 -5 15 4 45 2 52 -13 53 -17 1 -17 2 0 6 10 3 17
14 17 29 0 28 -17 32 -35 8 -14 -19 -55 -11 -55 12 0 8 -4 14 -10 14 -5 0 -10
-7 -10 -15 0 -8 -4 -15 -10 -15 -5 0 -10 9 -10 20 0 11 -9 28 -20 37 -28 24
-25 31 15 39 19 3 35 11 36 18 0 7 2 7 6 -1 2 -7 16 -13 29 -13z m5828 -37
c-8 -31 20 -31 49 1 29 31 72 43 84 23 4 -8 15 -17 23 -20 10 -4 7 -6 -7 -6
-13 -1 -23 -5 -23 -11 0 -5 -18 -10 -40 -10 l-40 0 0 -56 0 -56 33 7 c29 5 30
4 15 -8 -10 -8 -18 -19 -18 -26 0 -6 -11 -11 -24 -11 -23 0 -24 3 -21 48 6 66
-1 82 -36 82 -20 0 -29 -5 -29 -16 0 -13 -6 -15 -27 -9 -16 3 -29 11 -31 16
-2 5 -19 9 -37 9 -34 0 -34 0 -31 -44 1 -25 -1 -43 -6 -40 -4 3 -8 11 -8 19 0
7 -6 16 -12 19 -10 5 -10 7 0 12 15 7 16 44 1 44 -6 0 -4 7 5 15 8 9 33 15 58
15 35 0 47 5 67 29 22 26 25 27 42 13 12 -11 16 -24 13 -39z m-5896 -162 c17
-16 22 -26 14 -29 -6 -2 -12 -8 -12 -14 0 -15 -48 -58 -66 -58 -7 0 -14 5 -14
10 0 6 -6 10 -14 10 -9 0 -13 -13 -12 -42 0 -25 -4 -43 -12 -46 -6 -2 -12 -19
-12 -38 0 -28 -4 -34 -21 -34 -12 0 -23 8 -26 20 -3 11 -9 20 -14 20 -5 0 -9
14 -9 30 0 17 4 30 10 30 5 0 12 16 16 35 5 30 10 35 34 35 29 0 40 18 40 68
0 41 62 85 68 48 2 -11 16 -32 30 -45z m202 -196 c0 -8 7 -15 15 -15 8 0 15
-4 15 -10 0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 5 10 10 10 6 0 10 -16 10
-35 0 -24 -5 -35 -15 -35 -10 0 -15 -10 -15 -30 0 -19 5 -30 14 -30 8 0 17
-12 21 -27 3 -15 12 -31 18 -35 8 -5 6 -8 -5 -8 -10 0 -18 -7 -18 -15 0 -12
-7 -14 -27 -9 -16 4 -29 8 -31 9 -1 1 -4 29 -6 61 -2 33 -4 65 -5 72 -1 6 -12
12 -25 12 -29 0 -89 20 -98 33 -12 19 3 35 38 41 19 4 34 11 34 16 0 6 7 10
15 10 8 0 15 -7 15 -15z m4060 -87 c0 -10 -7 -18 -16 -18 -12 0 -14 -9 -12
-37 3 -35 6 -38 31 -36 18 1 27 -3 27 -13 0 -8 7 -14 15 -14 9 0 18 -7 21 -15
4 -8 12 -15 20 -15 19 0 18 -26 -1 -34 -8 -3 -15 -16 -16 -28 0 -15 -3 -18 -6
-8 -8 20 -29 40 -42 40 -6 0 -11 5 -11 10 0 6 -11 10 -25 10 -14 0 -25 -4 -25
-10 0 -5 -7 -10 -15 -10 -11 0 -15 11 -15 40 0 29 -4 40 -15 40 -8 0 -15 9
-15 20 0 11 -6 20 -14 20 -14 0 -33 67 -22 78 3 3 6 -3 6 -14 0 -21 41 -64 60
-64 8 0 11 10 7 29 -5 24 -2 30 15 34 15 4 19 11 15 27 -5 21 -5 21 14 4 10
-9 19 -25 19 -36z m1570 -9 c0 -14 38 -11 43 4 2 7 8 8 13 3 5 -5 4 -11 -3
-13 -7 -3 -13 -13 -13 -24 0 -17 6 -18 60 -13 41 3 60 1 60 -6 0 -17 -24 -37
-38 -32 -7 2 -29 1 -48 -2 -27 -6 -34 -12 -34 -30 0 -13 7 -26 15 -30 29 -11
15 -26 -25 -26 -36 0 -40 2 -40 26 0 14 -7 32 -15 40 -8 9 -15 31 -15 50 0 25
-4 34 -17 35 -17 1 -17 2 0 6 9 2 17 10 17 18 0 8 7 11 20 8 11 -3 20 -9 20
-14z m400 -59 c0 -5 10 -10 21 -10 20 0 21 -4 15 -40 -6 -39 -6 -40 24 -40 27
0 30 3 30 33 1 25 3 28 10 16 6 -10 6 -38 0 -75 -6 -32 -10 -62 -10 -66 0 -5
-4 -8 -10 -8 -5 0 -10 7 -10 15 0 11 -11 15 -40 15 -22 0 -40 -4 -40 -10 0 -5
-4 -10 -10 -10 -20 0 -11 48 10 53 27 7 26 37 -1 37 -12 0 -23 8 -26 20 -3 11
-9 20 -14 20 -5 0 -9 7 -9 15 0 8 5 15 10 15 6 0 10 7 10 15 0 8 9 15 20 15
11 0 20 -4 20 -10z m-170 -40 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 9 10 20
10 15 0 20 -6 19 -27 -1 -18 -3 -22 -6 -10 -8 30 -38 16 -38 -18 0 -19 -6 -31
-16 -33 -12 -2 -15 4 -11 28 4 25 1 30 -16 30 -11 0 -23 -7 -26 -16 -10 -27
-7 -54 7 -54 9 0 12 -10 9 -30 -3 -23 0 -30 12 -30 9 0 16 -7 16 -15 0 -9 9
-15 25 -15 18 0 25 5 25 20 0 11 -4 20 -10 20 -19 0 -10 41 15 65 34 35 40 32
43 -20 1 -35 -2 -45 -14 -45 -10 0 -14 -6 -11 -15 4 -8 2 -17 -3 -20 -16 -10
-11 -45 5 -45 8 0 15 -4 15 -10 0 -5 7 -10 15 -10 8 0 15 -7 15 -15 0 -8 7
-15 15 -15 8 0 15 7 15 15 0 8 9 15 19 15 11 0 22 6 24 13 3 6 5 -1 5 -18 0
-16 -2 -24 -5 -17 -2 6 -11 12 -18 12 -15 0 -30 -53 -20 -70 11 -18 -6 -100
-21 -100 -20 0 -17 -47 4 -59 9 -6 11 -11 5 -11 -7 0 -13 -11 -13 -25 0 -24
-2 -25 -65 -25 -74 0 -88 -18 -30 -39 41 -14 95 -6 95 15 0 8 7 14 15 14 12 0
14 -7 9 -27 -4 -16 -13 -32 -21 -36 -7 -4 -13 -15 -13 -22 0 -8 -5 -15 -10
-15 -6 0 -20 -9 -32 -19 -26 -25 -17 -51 17 -51 19 0 25 -5 26 -22 0 -13 2
-39 3 -58 4 -48 -2 -70 -21 -70 -10 0 -13 6 -9 16 13 33 -4 62 -41 69 -32 6
-34 9 -28 33 3 15 11 29 16 30 12 5 12 42 -1 42 -5 0 -10 14 -10 30 0 19 -5
30 -14 30 -8 0 -17 -13 -21 -30 -4 -16 -10 -30 -15 -30 -5 0 -11 -8 -13 -17
-3 -10 -3 1 0 24 9 68 -5 153 -25 154 -14 0 -14 2 -2 6 8 3 17 9 20 12 11 13
51 31 70 31 17 0 20 7 21 43 0 23 3 36 6 30 7 -18 30 -16 37 3 3 9 6 31 6 50
0 19 5 34 10 34 6 0 10 7 10 15 0 14 -34 29 -56 24 -33 -6 -74 3 -74 16 0 8 7
15 15 15 8 0 15 7 15 15 0 13 -14 15 -80 13 -43 -1 -81 0 -84 3 -3 3 -1 20 4
38 5 18 6 46 3 62 -5 25 -2 31 16 36 15 4 21 13 21 34 0 26 3 29 35 29 19 0
35 5 35 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-836 -35 c3 -8 -1 -21 -9 -29
-8 -8 -15 -28 -15 -45 0 -21 -5 -31 -15 -31 -8 0 -15 -7 -15 -15 0 -8 -7 -15
-15 -15 -8 0 -15 -9 -15 -20 0 -16 7 -20 30 -20 17 0 30 5 30 10 0 6 7 10 16
10 9 0 18 9 21 20 3 11 12 20 19 20 20 0 17 -18 -5 -30 -24 -12 -35 -43 -26
-70 9 -27 32 -25 39 4 3 13 12 26 20 29 10 4 12 0 9 -14 -4 -13 0 -19 10 -19
9 0 19 -9 22 -20 3 -11 13 -20 21 -20 8 0 14 -4 14 -10 0 -5 -4 -10 -9 -10 -5
0 -12 -13 -16 -30 -6 -27 -35 -44 -35 -20 0 6 5 10 10 10 6 0 10 9 10 20 0 14
-7 20 -23 20 -40 0 -78 -64 -42 -72 10 -2 12 -7 5 -18 -9 -13 -11 -13 -21 0
-8 11 -12 12 -16 3 -8 -20 -23 -15 -23 7 0 11 4 20 9 20 14 0 23 37 16 60 -7
21 -55 29 -55 10 0 -22 -26 -8 -38 20 -10 22 -21 30 -40 31 -19 1 -21 3 -9 6
14 4 17 11 13 29 -5 17 -2 26 9 30 17 7 21 44 5 44 -5 0 -10 7 -10 15 0 8 4
15 9 15 5 0 13 9 16 20 3 11 15 20 26 20 10 0 19 5 19 10 0 6 7 10 15 10 8 0
15 7 15 15 0 19 16 19 24 0z m243 -25 c3 -11 9 -20 14 -20 5 0 9 -7 9 -15 0
-8 7 -15 16 -15 13 0 11 -6 -10 -25 -14 -13 -26 -29 -26 -35 0 -5 -9 -10 -20
-10 -21 0 -24 -10 -14 -47 5 -17 14 -23 33 -23 15 0 33 -9 41 -20 8 -11 22
-20 32 -20 11 0 18 7 18 20 0 11 7 20 15 20 8 0 15 7 15 15 0 9 -9 15 -25 15
-20 0 -25 5 -25 25 0 28 26 34 60 13 17 -11 22 -10 30 2 6 10 10 11 10 3 0 -7
7 -13 15 -13 9 0 15 -9 15 -25 0 -22 5 -25 38 -26 26 -1 30 -3 13 -6 -13 -3
-31 -12 -40 -21 -15 -14 -16 -13 -19 21 -3 29 -7 37 -23 37 -14 0 -19 -7 -19
-30 0 -20 -5 -30 -15 -30 -8 0 -15 -4 -15 -10 0 -5 7 -10 15 -10 8 0 15 -4 15
-10 0 -5 -11 -10 -25 -10 -18 0 -25 -5 -25 -19 0 -11 -8 -27 -17 -37 -13 -13
-14 -19 -5 -22 14 -4 16 -32 2 -32 -6 0 -16 12 -22 26 -9 20 -9 30 0 45 19 30
15 42 -13 37 -17 -4 -25 -1 -25 8 0 8 -7 14 -15 14 -8 0 -15 -7 -15 -15 0 -8
-6 -15 -14 -15 -9 0 -12 8 -8 25 2 15 0 25 -7 25 -6 0 -11 17 -11 40 0 30 -4
40 -16 40 -13 0 -15 7 -10 35 7 34 6 35 -22 35 -17 0 -32 -6 -35 -14 -4 -9
-14 -12 -26 -9 -17 4 -20 11 -14 32 8 34 5 32 51 38 34 5 41 2 47 -16 4 -15
15 -21 36 -21 25 0 29 4 29 29 0 20 -6 31 -17 34 -16 4 -16 5 0 6 11 1 21 -7
24 -19z m-1451 -130 c8 0 14 -7 14 -15 0 -8 7 -15 15 -15 18 0 20 -17 4 -22
-8 -3 -8 -10 2 -26 17 -27 39 -28 46 -3 3 11 11 22 18 25 7 2 16 18 20 35 10
48 25 36 25 -20 0 -43 -3 -50 -16 -45 -10 4 -24 -2 -35 -15 -25 -29 -25 -59 1
-59 11 0 20 6 20 14 0 8 14 17 30 21 17 4 30 11 30 16 0 5 11 9 25 9 14 0 25
5 25 10 0 18 22 10 27 -10 3 -11 9 -20 14 -20 20 0 7 -28 -15 -34 -20 -5 -25
-15 -33 -68 l-10 -63 -2 63 c0 40 -6 69 -15 78 -19 18 -36 18 -36 -1 0 -8 5
-15 10 -15 6 0 10 -7 10 -15 0 -8 -6 -15 -13 -15 -19 0 -57 -47 -57 -70 0 -11
-4 -20 -10 -20 -5 0 -10 18 -10 40 0 29 -4 40 -15 40 -9 0 -15 9 -15 25 0 14
-5 25 -11 25 -19 0 -34 24 -35 57 -2 32 -23 63 -43 63 -6 0 -11 -7 -11 -15 0
-8 4 -15 9 -15 5 0 11 -9 14 -20 4 -17 2 -18 -14 -9 -10 5 -19 16 -19 24 0 8
-4 15 -10 15 -20 0 -9 18 15 24 17 4 25 14 26 29 0 12 3 16 6 10 2 -7 11 -13
19 -13z m1860 -14 c8 -8 14 -22 14 -32 0 -13 6 -16 35 -11 26 4 35 2 35 -9 0
-8 8 -14 18 -15 10 0 12 -3 5 -6 -19 -7 -17 -63 2 -63 8 0 15 7 15 15 0 8 5
15 10 15 6 0 10 -7 10 -15 0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -8 -7
-15 -15 -15 -8 0 -15 -9 -15 -20 0 -15 -7 -20 -25 -20 -14 0 -25 5 -25 10 0 6
-7 10 -15 10 -9 0 -15 -9 -15 -25 0 -14 -4 -25 -10 -25 -5 0 -10 -6 -10 -14 0
-7 -9 -16 -21 -19 -18 -5 -20 -11 -14 -36 4 -20 3 -31 -4 -31 -6 0 -11 5 -11
10 0 6 -7 10 -15 10 -8 0 -15 -7 -15 -15 0 -21 -17 -19 -22 3 -8 34 3 51 38
58 19 3 34 12 34 20 0 8 7 14 15 14 8 0 15 7 15 15 0 8 -9 15 -19 15 -11 0
-23 9 -26 20 -3 11 -13 20 -21 20 -8 0 -14 5 -14 10 0 14 -28 12 -32 -2 -8
-23 -38 18 -37 49 1 17 3 21 6 11 3 -12 14 -18 34 -18 16 0 29 -4 29 -10 0 -5
7 -10 15 -10 8 0 15 5 15 11 0 11 25 39 35 39 4 0 13 -7 21 -15 9 -8 11 -15 5
-15 -6 0 -11 -11 -11 -24 0 -16 7 -26 22 -29 32 -9 68 2 68 20 0 12 -7 14 -30
10 -22 -5 -30 -3 -30 8 0 8 5 15 10 15 14 0 13 19 -2 35 -30 34 -38 45 -38 55
0 6 -7 13 -15 16 -17 7 -20 24 -4 24 6 0 17 -6 25 -14z m2258 -349 c16 -12 16
-14 -8 -27 -31 -16 -36 -59 -8 -61 16 -1 16 -2 0 -6 -22 -5 -24 -43 -3 -43 20
0 31 -65 15 -95 -18 -34 -38 -39 -32 -9 4 21 1 24 -27 24 -24 0 -32 -5 -37
-25 -3 -14 -12 -25 -19 -25 -14 0 -25 -22 -25 -51 0 -23 -18 -25 -27 -3 -3 9
3 23 16 35 23 22 28 59 8 59 -15 0 -47 -35 -48 -53 0 -9 -2 -9 -6 1 -7 18 -31
15 -36 -5 -4 -14 -5 -12 -6 5 -1 13 5 22 14 22 8 0 15 7 15 16 0 12 -7 15 -27
11 l-28 -6 28 12 c35 14 37 42 3 33 -30 -7 -56 2 -56 20 0 10 8 12 30 8 23 -5
30 -3 30 10 0 9 5 16 10 16 6 0 10 18 10 40 0 22 -4 40 -10 40 -5 0 -10 5 -10
10 0 21 30 9 36 -15 6 -26 29 -34 37 -12 2 6 8 9 12 5 4 -4 -5 -18 -19 -31
-14 -13 -26 -27 -26 -31 0 -8 48 -8 89 0 32 7 44 34 15 34 -25 0 -10 55 22 85
31 28 43 30 68 12z m-3467 -67 c3 -16 12 -30 19 -30 19 0 18 -29 -1 -36 -8 -4
-15 -10 -15 -16 0 -17 65 -75 93 -82 27 -6 38 -26 15 -26 -7 0 -29 -3 -49 -6
-33 -6 -36 -4 -35 17 1 21 -4 24 -34 24 -31 0 -35 3 -38 28 -2 15 -8 27 -13
27 -14 0 -11 28 3 32 9 3 8 9 -5 22 -24 24 -21 43 8 50 14 4 25 11 25 16 0 23
21 7 27 -20z m728 -31 c5 -23 11 -30 25 -26 13 3 16 0 13 -14 -4 -13 0 -19 13
-20 17 -1 17 -2 2 -6 -10 -2 -18 -11 -18 -19 0 -19 59 -19 66 1 9 22 44 19 44
-3 0 -10 5 -23 12 -30 7 -7 20 -12 30 -12 10 0 18 -7 18 -15 0 -8 9 -15 20
-15 11 0 20 5 20 10 0 6 7 10 15 10 10 0 15 -10 15 -29 0 -16 4 -31 8 -33 12
-4 24 -58 13 -58 -4 0 -22 -3 -38 -6 -26 -5 -30 -3 -29 18 1 19 -4 23 -26 21
-22 -1 -28 3 -28 18 0 22 -42 27 -60 7 -6 -7 -20 -24 -31 -38 l-21 -25 8 30
c4 17 11 32 16 33 11 5 10 32 -2 32 -5 0 -10 7 -10 15 0 8 -6 15 -13 15 -24 0
-54 46 -61 94 -4 25 -11 46 -17 46 -5 0 -9 7 -9 15 0 27 18 16 25 -16z m-6971
-95 c9 -4 16 -18 16 -34 0 -16 7 -30 16 -34 12 -5 15 -17 12 -54 l-3 -47 78
-3 c74 -3 77 -4 77 -27 0 -18 5 -25 20 -25 11 0 20 5 20 10 0 6 6 10 14 10 7
0 16 14 20 30 4 21 11 30 26 30 13 0 20 7 20 19 0 10 7 21 15 25 8 3 15 17 15
31 0 20 5 25 25 25 14 0 25 -4 25 -10 0 -5 -4 -10 -10 -10 -5 0 -10 -18 -10
-40 0 -38 2 -40 29 -40 36 0 83 17 93 33 4 7 8 24 8 40 0 17 5 27 15 27 8 0
15 -4 15 -10 0 -5 7 -10 15 -10 9 0 18 -7 21 -15 4 -8 12 -15 20 -15 8 0 14
-7 14 -15 0 -8 -9 -15 -20 -15 -21 0 -24 -10 -14 -47 4 -13 14 -23 24 -23 10
0 25 -6 32 -14 8 -7 37 -17 65 -21 39 -5 54 -3 66 9 24 24 34 19 42 -20 6 -34
8 -36 36 -30 21 4 31 1 35 -9 4 -8 12 -15 20 -15 8 0 14 -7 14 -15 0 -8 8 -15
18 -16 10 0 12 -3 5 -6 -16 -6 -17 -33 -2 -33 6 0 5 -6 -3 -14 -38 -40 -51
-60 -40 -64 17 -5 15 -22 -3 -22 -10 0 -15 -10 -15 -30 0 -29 -24 -44 -32 -19
-2 5 -22 13 -46 16 -26 3 -42 11 -42 19 0 8 -7 14 -15 14 -8 0 -15 5 -15 10 0
6 7 10 15 10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15 5 -15 10 0 6 5 10 10
10 6 0 10 7 10 15 0 10 -10 15 -29 15 -23 0 -30 5 -35 26 -4 15 -4 39 -1 55
l7 29 -66 0 c-37 0 -66 4 -66 10 0 6 -9 10 -20 10 -11 0 -20 -4 -20 -10 0 -5
-7 -10 -15 -10 -14 0 -18 -18 -19 -90 0 -11 -12 -15 -48 -15 -32 0 -48 4 -48
12 0 7 -7 13 -15 13 -8 0 -15 5 -15 10 0 6 -7 10 -15 10 -8 0 -15 5 -15 10 0
16 -32 11 -46 -7 -13 -17 -15 -17 -28 0 -7 9 -22 17 -32 18 -11 0 -13 3 -6 6
6 2 12 17 12 33 0 24 -5 28 -36 33 -37 6 -74 -6 -74 -23 0 -6 -13 -10 -30 -10
-16 0 -30 5 -30 10 0 6 -7 10 -15 10 -8 0 -15 9 -15 20 0 13 -7 20 -20 20 -14
0 -21 9 -26 35 -4 22 -12 35 -23 36 -14 1 -13 2 2 6 12 3 17 14 17 37 0 34 10
96 15 96 2 0 10 -3 19 -6z m6716 -54 c0 -11 4 -20 9 -20 5 0 12 -11 15 -25 5
-19 13 -25 34 -26 19 -1 13 -4 -20 -11 -42 -9 -48 -13 -49 -37 0 -14 -3 -20
-6 -13 -6 15 -53 16 -53 1 0 -6 -7 -3 -16 5 -8 9 -20 16 -25 16 -6 0 -7 9 -4
20 5 16 2 20 -14 20 -14 0 -21 -6 -21 -20 0 -11 -4 -20 -10 -20 -5 0 -10 5
-10 10 0 6 -9 10 -19 10 -14 0 -18 5 -14 19 4 15 16 20 54 23 32 2 50 -1 54
-9 9 -24 25 -13 25 17 0 25 4 30 25 30 16 0 25 6 25 15 0 8 5 15 10 15 6 0 10
-9 10 -20z m2550 0 c0 -5 9 -10 20 -10 11 0 20 -7 20 -15 0 -10 10 -15 30 -15
20 0 30 -5 30 -15 0 -7 8 -19 18 -25 14 -8 12 -9 -9 -4 -22 4 -27 1 -33 -18
-9 -36 -7 -48 9 -48 8 0 15 9 15 19 0 14 5 18 20 14 23 -6 27 -23 5 -23 -8 0
-15 -6 -15 -13 0 -7 -20 -18 -44 -26 -48 -14 -70 -7 -112 35 -18 19 -20 29
-14 70 4 27 13 50 19 52 6 2 11 10 11 18 0 8 7 14 15 14 8 0 15 -4 15 -10z
m-3461 -62 c7 -23 26 -53 41 -67 26 -22 28 -29 23 -68 -5 -34 -3 -43 10 -43 8
0 17 7 21 15 3 8 12 15 21 15 9 0 18 -7 21 -15 7 -17 34 -21 34 -5 0 6 9 20
20 32 11 12 20 30 20 40 0 13 8 18 30 18 17 0 30 5 30 10 0 6 5 10 10 10 6 0
10 -7 10 -15 0 -8 -5 -15 -11 -15 -11 0 -39 -24 -39 -34 0 -3 5 -6 11 -6 6 0
17 -6 25 -14 16 -17 19 -36 4 -36 -5 0 -10 5 -10 10 0 6 -16 10 -36 10 -34 0
-36 -2 -30 -25 6 -23 -8 -37 -16 -17 -1 5 -22 12 -46 16 l-44 6 4 -37 3 -38
-59 -3 -58 -3 7 33 c4 18 7 48 8 66 0 19 6 32 14 32 16 0 18 17 2 22 -5 2 -13
22 -16 45 -5 35 -9 42 -34 47 -27 5 -42 26 -19 26 6 0 10 7 10 15 0 32 27 13
39 -27z m3902 -40 c1 -7 0 -25 -1 -39 -1 -19 5 -28 19 -32 14 -3 19 -10 15
-21 -4 -11 -19 -16 -50 -16 -39 0 -44 -3 -45 -22 -1 -17 -2 -19 -6 -5 -2 9
-11 17 -20 17 -11 0 -14 -7 -9 -27 3 -16 3 -22 0 -15 -3 6 -12 12 -20 12 -14
0 -16 -5 -18 -45 -1 -11 -8 -20 -16 -20 -10 0 -14 8 -13 23 2 13 -3 22 -11 22
-8 0 -16 7 -20 15 -3 8 -15 15 -26 15 -15 0 -20 7 -20 29 0 24 4 28 19 24 15
-4 20 1 23 19 2 17 10 23 28 24 134 4 127 3 134 29 7 25 35 35 37 13z m-3495
-23 c4 -8 10 -15 16 -15 5 0 16 7 24 15 9 8 23 15 32 14 11 0 12 -2 5 -6 -7
-2 -13 -11 -13 -19 0 -8 -7 -14 -15 -14 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -15
-10 -8 0 -15 -9 -15 -20 0 -11 7 -20 15 -20 8 0 15 5 15 10 0 20 18 9 24 -15
4 -14 11 -25 17 -25 6 0 4 -7 -5 -15 -8 -8 -22 -15 -30 -15 -10 0 -16 -9 -16
-25 0 -14 -4 -25 -9 -25 -5 0 -12 11 -15 25 -4 14 -13 25 -20 25 -9 0 -16 17
-20 45 -4 25 -11 45 -17 45 -5 0 -9 13 -9 29 0 20 -6 31 -17 34 -10 3 4 5 30
6 34 1 49 -3 53 -14z m615 -65 c10 0 19 -4 19 -10 0 -5 7 -10 15 -10 10 0 15
10 15 30 0 30 24 43 32 18 5 -14 -14 -58 -24 -58 -5 0 -8 -7 -8 -15 0 -8 7
-15 15 -15 8 0 15 -4 15 -10 0 -18 -20 -11 -44 15 -23 24 -46 33 -46 17 0 -5
-9 -18 -21 -30 -18 -20 -19 -24 -5 -38 9 -8 16 -24 16 -35 0 -10 7 -19 15 -19
8 0 15 9 15 20 0 11 7 20 15 20 10 0 15 -10 15 -30 0 -20 5 -30 15 -30 8 0 15
7 15 15 0 9 9 15 25 15 30 0 34 -25 6 -40 -11 -5 -42 -10 -70 -10 -44 0 -51 3
-51 20 0 11 -7 20 -15 20 -8 0 -15 9 -15 20 0 13 -7 20 -20 20 -15 0 -20 7
-20 29 0 16 -6 32 -12 34 -7 3 -1 6 15 6 35 1 38 31 2 31 -18 0 -25 5 -25 20
0 11 5 20 10 20 6 0 10 -4 10 -10 0 -5 7 -10 15 -10 8 0 15 -4 15 -10 0 -5 7
-10 15 -10 10 0 15 11 16 33 1 23 3 26 6 10 3 -15 12 -23 24 -23z m249 -85 c0
-22 5 -35 13 -35 9 0 12 -10 9 -30 -5 -36 23 -54 43 -27 13 18 35 23 35 9 0
-5 11 -14 25 -20 13 -6 23 -17 21 -24 -3 -7 -7 -25 -10 -40 -4 -16 -13 -28
-21 -28 -8 0 -15 -9 -15 -20 0 -11 -7 -20 -15 -20 -10 0 -15 10 -15 30 0 20
-5 30 -15 30 -8 0 -15 -6 -15 -14 0 -23 -125 -11 -150 14 l-20 20 20 20 c11
11 25 20 30 20 6 0 11 10 12 23 1 12 2 29 4 37 1 8 2 29 3 47 1 30 10 38 49
42 7 1 12 -12 12 -34z m420 -5 c0 -23 5 -29 31 -34 34 -6 79 1 79 14 0 4 9 10
20 13 13 3 20 0 20 -9 0 -8 -7 -14 -15 -14 -10 0 -15 -11 -15 -35 0 -19 5 -35
10 -35 6 0 10 -7 10 -15 0 -8 7 -15 15 -15 10 0 15 -10 15 -30 0 -20 5 -30 15
-30 9 0 15 -9 15 -25 0 -14 -4 -25 -9 -25 -5 0 -12 -13 -16 -30 -4 -16 -11
-30 -16 -30 -5 0 -9 -9 -9 -20 0 -15 7 -20 25 -20 26 0 72 -37 59 -48 -3 -4
-9 -1 -11 6 -3 6 -15 12 -28 12 -13 0 -30 7 -39 15 -23 23 -36 18 -36 -16 0
-34 19 -59 45 -59 14 0 16 -6 11 -35 -6 -30 -4 -35 12 -35 11 0 22 9 25 20 4
15 14 20 37 20 27 0 31 -3 28 -22 -2 -18 -11 -24 -35 -26 -29 -3 -33 -7 -33
-31 0 -27 -2 -28 -47 -26 -46 1 -48 3 -53 36 -5 36 -19 41 -67 25 -17 -5 -23
-3 -23 7 0 10 8 13 25 9 18 -3 25 0 25 12 0 9 -4 16 -10 16 -5 0 -10 20 -10
45 0 25 5 45 10 45 6 0 11 8 11 18 1 16 2 16 6 0 8 -30 30 -20 37 17 4 21 13
35 21 35 8 0 15 7 15 15 0 8 -7 15 -15 15 -11 0 -15 11 -15 38 0 42 -7 56 -32
65 -12 4 -18 17 -18 37 0 17 -5 30 -12 31 -10 0 -10 2 0 6 27 11 12 33 -23 33
-28 0 -35 -4 -35 -20 0 -11 -4 -20 -10 -20 -5 0 -10 13 -10 29 0 20 -6 30 -20
34 -12 3 -20 14 -20 26 0 12 -4 21 -10 21 -23 0 -8 19 18 22 41 5 52 -1 52
-32z m1756 -108 c16 3 36 7 44 9 9 3 16 -4 18 -18 3 -18 10 -23 33 -23 16 0
29 5 29 11 0 24 30 40 62 34 32 -6 33 -8 30 -51 -2 -24 -8 -44 -13 -44 -5 0
-9 -16 -9 -35 0 -30 -2 -34 -14 -23 -8 7 -10 15 -5 16 12 5 11 62 -1 62 -5 0
-10 7 -10 15 0 8 -9 15 -20 15 -17 0 -20 -7 -20 -44 0 -40 -2 -44 -29 -49 -40
-8 -71 2 -71 24 0 11 5 19 10 19 6 0 10 -4 10 -10 0 -5 9 -10 20 -10 13 0 20
7 20 20 0 11 -6 20 -14 20 -7 0 -16 9 -19 20 -8 30 -48 25 -77 -10 -25 -29
-50 -39 -50 -19 0 6 7 17 15 25 8 9 15 27 15 42 0 19 3 23 9 13 6 -8 19 -12
37 -9z m-3181 -82 c3 -11 11 -20 16 -20 5 0 9 -8 9 -19 0 -32 22 -81 36 -81 8
0 14 -4 14 -10 0 -5 -9 -10 -19 -10 -11 0 -26 -3 -35 -6 -21 -8 -21 -54 -1
-54 11 0 15 -12 17 -40 0 -22 1 -46 2 -52 1 -7 -15 -15 -34 -18 -35 -5 -35 -6
-33 -52 1 -38 -2 -47 -18 -51 -10 -3 -19 -1 -19 4 0 5 -6 9 -14 9 -38 0 -27
73 14 90 22 9 29 20 33 51 6 48 -1 79 -19 79 -9 0 -14 11 -14 30 0 27 3 30 30
30 39 0 43 35 5 44 -29 7 -32 21 -10 42 8 9 15 24 15 35 0 25 17 25 25 -1z
m3365 -108 c0 -4 -5 -13 -11 -19 -15 -15 8 -39 45 -49 14 -3 26 -10 26 -15 0
-5 11 -9 25 -9 20 0 25 5 25 25 0 14 5 25 10 25 6 0 10 -13 10 -28 0 -39 -33
-54 -72 -34 -16 7 -35 11 -43 8 -9 -3 -15 1 -15 9 0 22 -28 18 -35 -5 -4 -13
-15 -20 -31 -20 -21 0 -24 -4 -24 -39 0 -22 6 -42 13 -44 6 -3 -3 -3 -20 0
-32 5 -33 5 -35 -33 -1 -22 -2 -47 -1 -56 0 -10 -6 -18 -14 -18 -8 0 -13 12
-13 30 0 22 -5 30 -17 31 -11 0 -13 3 -5 6 6 2 12 11 12 19 0 8 -6 14 -12 15
-10 0 -10 2 0 6 18 7 15 53 -3 53 -9 0 -15 9 -15 25 0 16 6 25 15 25 8 0 15
-9 15 -20 0 -13 7 -20 20 -20 13 0 20 7 20 20 0 11 7 20 15 20 8 0 17 11 21
25 3 14 10 25 15 25 5 0 9 6 9 13 0 7 10 17 23 23 27 14 47 17 47 6z m-2294
-71 c4 -17 13 -32 20 -35 10 -4 12 -14 8 -31 -5 -19 -2 -25 10 -25 9 0 16 5
16 10 0 6 16 10 35 10 22 0 38 6 41 15 9 23 21 18 28 -10 12 -47 76 -89 76
-50 0 10 10 15 30 15 36 0 41 -25 10 -58 -11 -12 -20 -26 -20 -32 0 -5 9 -10
20 -10 11 0 20 -7 20 -15 0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -8 -4
-15 -10 -15 -5 0 -10 14 -10 30 0 26 -4 30 -27 31 -16 0 -22 3 -15 6 17 7 15
33 -3 33 -8 0 -15 4 -15 9 0 6 -15 11 -32 13 -28 3 -33 6 -30 27 4 23 2 24
-50 18 -29 -4 -65 -7 -80 -7 -21 0 -28 -6 -33 -31 -4 -17 -13 -33 -21 -36 -8
-3 -14 -13 -15 -22 0 -13 -2 -13 -6 -1 -3 8 -12 19 -20 23 -10 6 -12 19 -7 43
5 27 3 34 -10 34 -25 0 -19 17 9 24 14 4 25 13 25 22 0 9 9 18 20 21 11 3 23
16 26 29 8 33 23 30 30 -5z m-546 -141 c0 -15 -7 -20 -25 -20 -21 0 -25 -5
-25 -30 0 -16 5 -30 11 -30 18 0 46 -33 31 -38 -6 -2 -13 -13 -13 -25 -1 -17
-2 -18 -6 -4 -2 9 -11 17 -19 17 -8 0 -14 7 -14 15 0 8 -2 15 -4 15 -2 0 -11
3 -20 6 -13 5 -16 -1 -16 -25 0 -25 -5 -32 -27 -37 -15 -4 -34 -14 -42 -22
-13 -12 -16 -12 -23 7 -4 11 -11 21 -17 21 -15 0 -19 61 -4 67 16 6 17 29 1
37 -10 5 -10 7 0 12 6 3 12 12 12 20 0 8 9 14 19 14 17 0 20 -8 23 -47 2 -36
7 -49 21 -51 12 -2 17 3 17 17 0 17 6 21 30 21 23 0 30 4 30 20 0 11 -4 20
-10 20 -14 0 -12 27 3 33 6 3 24 6 40 6 21 1 27 -4 27 -19z m280 -40 c0 -5 -7
-10 -15 -10 -8 0 -15 -7 -15 -15 0 -9 9 -15 25 -15 32 0 45 -28 22 -45 -10 -7
-17 -22 -17 -33 0 -16 8 -23 35 -28 19 -3 35 -12 35 -19 0 -7 6 -16 13 -18 6
-3 0 -6 -15 -6 -14 -1 -29 -7 -31 -13 -3 -8 -6 -6 -6 5 -1 18 -12 21 -48 11
-26 -7 -32 -34 -8 -34 18 0 20 -17 3 -22 -14 -5 6 -30 32 -41 12 -4 11 -6 -2
-6 -10 -1 -18 -8 -18 -16 0 -22 -21 -18 -27 5 -3 11 -14 20 -24 20 -22 0 -24
16 -8 109 6 43 7 73 0 92 -9 25 -7 29 8 29 13 0 20 10 25 30 5 27 36 44 36 20z
m1360 5 c0 -3 -8 -13 -19 -24 -10 -10 -21 -25 -25 -32 -5 -11 -7 -11 -12 -1
-7 14 -44 16 -44 2 0 -5 -13 -10 -29 -10 -17 0 -31 -6 -35 -15 -7 -20 4 -78
18 -87 17 -11 -2 -22 -41 -22 -20 0 -38 5 -41 12 -5 17 -32 15 -32 -3 0 -8 5
-15 10 -15 6 0 10 -11 10 -25 0 -14 -4 -25 -10 -25 -5 0 -10 5 -10 10 0 6 -7
10 -15 10 -8 0 -15 -4 -15 -10 0 -5 -11 -10 -25 -10 -14 0 -25 5 -25 10 0 12
-47 13 -52 1 -1 -5 -9 -3 -16 5 -7 8 -8 14 -2 14 5 0 10 7 10 15 0 8 -4 15
-10 15 -5 0 -10 6 -10 13 0 9 15 12 53 10 71 -4 97 3 97 27 0 13 7 20 20 20
11 0 20 -7 20 -15 0 -8 7 -15 15 -15 10 0 17 14 21 40 6 36 10 41 48 52 22 7
45 18 49 26 6 9 18 11 38 7 22 -5 29 -3 29 9 0 9 7 16 15 16 8 0 15 -2 15 -5z
m-2340 -89 c0 -18 6 -25 23 -28 18 -2 21 -8 19 -35 -3 -27 0 -33 17 -33 10 0
22 6 25 13 3 8 8 7 16 -6 6 -9 20 -26 31 -36 12 -11 17 -25 13 -34 -8 -23 -25
-21 -31 3 -3 11 -13 20 -23 20 -10 0 -25 7 -34 15 -24 25 -56 19 -56 -10 0
-15 -6 -25 -14 -25 -7 0 -28 -3 -45 -6 -27 -6 -31 -4 -31 15 0 12 -6 21 -15
21 -8 0 -15 5 -15 10 0 6 -8 10 -17 11 -11 0 -13 3 -5 6 6 2 12 12 12 22 0 15
7 16 70 7 38 -5 73 -6 77 -1 5 4 2 10 -4 12 -7 3 -13 11 -13 19 0 8 -4 14 -10
14 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0 10 -11 10 -24z m-5598 -26 c-4 -25
-9 -30 -33 -30 -34 0 -37 -13 -9 -37 11 -10 20 -28 20 -40 0 -14 6 -23 15 -23
9 0 15 -9 15 -25 0 -16 -6 -25 -15 -25 -10 0 -15 -10 -15 -28 0 -26 -3 -28
-30 -24 -17 2 -30 10 -30 18 0 22 -33 16 -58 -10 -17 -18 -21 -31 -16 -50 3
-14 10 -26 15 -26 5 0 9 -7 9 -15 0 -26 -19 -17 -26 12 -9 33 -27 53 -48 53
-9 0 -16 5 -16 10 0 6 -9 10 -20 10 -20 0 -80 -52 -80 -70 0 -5 -8 -10 -17 -9
-11 0 -13 3 -5 6 17 7 15 33 -3 33 -8 0 -15 7 -15 15 0 8 -4 15 -9 15 -5 0
-11 14 -14 30 -4 19 -13 30 -24 31 -16 1 -16 2 -1 6 9 2 18 14 20 26 3 19 8
21 46 19 33 -3 42 0 42 12 0 10 9 16 25 16 14 0 25 5 25 10 0 6 11 10 25 10
22 0 25 -4 25 -35 0 -31 3 -35 26 -35 24 0 26 3 22 39 -2 22 0 37 4 35 21 -13
48 8 48 37 0 26 3 29 33 29 20 0 39 8 50 20 22 26 24 25 19 -10z m7202 -55
c-5 -38 -3 -46 12 -52 48 -18 74 -77 46 -103 -26 -25 -52 3 -52 57 0 32 -4 43
-15 43 -8 0 -16 -8 -16 -17 -1 -17 -2 -17 -6 -1 -8 32 -73 34 -73 2 0 -19 -19
-18 -26 1 -4 8 -13 15 -21 15 -11 0 -14 6 -9 23 3 12 6 30 6 41 0 15 6 17 38
13 20 -3 49 -9 65 -12 23 -6 27 -4 27 14 0 12 6 21 16 21 12 0 14 -8 8 -45z
m-902 -15 c-2 -17 0 -30 6 -30 7 0 7 -12 -1 -35 -9 -31 -8 -37 13 -55 13 -11
29 -20 35 -20 17 0 29 -50 16 -63 -8 -8 -17 -4 -31 11 -12 12 -30 22 -40 22
-11 0 -20 6 -20 14 0 15 -53 32 -69 22 -12 -8 -41 21 -41 40 0 8 -4 14 -10 14
-14 0 -13 97 1 102 6 2 41 4 78 6 l68 2 -5 -30z m418 -124 c0 -8 5 -16 10 -18
6 -2 11 -25 12 -51 0 -31 5 -47 13 -47 14 0 28 -27 17 -32 -4 -2 -41 -2 -84 0
l-76 4 -4 49 -3 50 33 -6 c31 -6 32 -5 32 30 0 31 3 35 25 35 15 0 25 -6 25
-14z m1520 -42 c0 -25 4 -34 18 -35 10 0 12 -3 5 -6 -19 -7 -17 -63 2 -63 8 0
15 7 15 15 0 8 4 15 9 15 5 0 11 8 13 18 2 9 4 -6 4 -33 l0 -51 -45 2 c-25 0
-47 6 -49 13 -2 6 -10 11 -18 11 -8 0 -14 9 -14 20 0 11 5 20 10 20 6 0 10 9
10 20 0 11 -4 20 -10 20 -5 0 -10 9 -10 20 0 16 -7 20 -35 20 -29 0 -36 -4
-41 -25 -3 -14 -12 -25 -19 -25 -7 0 -15 -6 -18 -12 -2 -7 -8 -9 -13 -5 -5 5
-1 13 9 18 10 6 17 22 17 39 0 31 7 34 113 36 47 1 47 1 47 -32z m-1124 -64
c23 0 34 -5 34 -14 0 -8 12 -16 28 -19 l27 -4 -32 -8 c-26 -6 -33 -13 -33 -31
0 -22 4 -24 49 -24 40 0 50 -4 54 -19 3 -14 -2 -21 -19 -25 -31 -8 -34 -55 -4
-63 11 -3 20 -12 20 -19 0 -19 29 -18 36 1 8 19 24 20 24 1 0 -10 13 -16 37
-18 27 -2 39 -8 41 -20 2 -11 -3 -18 -12 -18 -11 0 -16 -9 -16 -26 0 -17 -6
-28 -20 -31 -11 -3 -20 -12 -20 -19 0 -8 -7 -17 -15 -20 -8 -4 -15 -12 -15
-20 0 -8 -9 -14 -20 -14 -11 0 -20 -4 -20 -10 0 -5 -4 -10 -10 -10 -21 0 -9
20 15 26 20 5 25 13 25 36 l0 30 -60 -4 c-53 -4 -60 -2 -60 14 0 13 -8 18 -25
18 -14 0 -25 -4 -25 -10 0 -20 -18 -9 -24 14 -6 24 -47 43 -66 31 -6 -4 -10 1
-10 9 0 10 -9 16 -25 16 -16 0 -25 -6 -25 -15 0 -8 -7 -15 -16 -15 -10 0 -14
-7 -12 -22 4 -28 58 -40 58 -14 0 18 2 19 24 10 9 -3 16 -12 16 -20 0 -8 7
-14 15 -14 24 0 20 -56 -4 -63 -11 -2 -21 -15 -24 -28 -4 -22 -4 -22 -6 4 0
15 -5 27 -10 27 -5 0 -12 14 -16 30 -5 23 -12 30 -29 30 -28 0 -66 31 -66 54
0 9 7 16 16 16 18 0 49 28 41 37 -3 3 -19 1 -35 -5 -27 -9 -29 -8 -24 13 3 20
0 23 -37 23 -40 1 -41 0 -41 -34 0 -19 -4 -34 -9 -34 -5 0 -16 -3 -25 -6 -12
-4 -16 0 -16 16 0 15 -5 20 -17 18 -12 -2 -18 -13 -18 -32 0 -17 -6 -31 -15
-34 -20 -7 -30 -22 -30 -49 0 -18 -6 -23 -25 -23 -32 0 -45 -47 -42 -148 1
-39 -1 -72 -5 -72 -11 0 -43 34 -51 53 -4 11 -1 17 8 17 8 0 15 7 15 15 0 22
-57 20 -62 -2 -2 -10 -2 23 0 72 3 68 8 93 20 103 15 11 17 10 20 -7 4 -25 52
-30 52 -6 0 8 5 15 10 15 6 0 10 -7 10 -15 0 -8 7 -15 16 -15 14 0 16 8 11 48
-3 26 -3 41 0 35 2 -7 12 -13 20 -13 12 0 14 7 8 28 -3 15 -11 29 -16 30 -5 2
-9 12 -9 23 0 14 7 19 30 19 23 0 31 5 36 24 5 21 13 25 47 28 39 3 42 5 50
41 11 45 22 48 31 7 5 -25 10 -30 35 -30 16 0 33 -4 36 -10 3 -5 17 -10 31
-10 17 0 24 5 24 20 0 42 60 9 75 -42 4 -12 13 -26 19 -30 8 -6 7 -8 -1 -8 -7
0 -13 -7 -13 -16 0 -12 6 -15 23 -10 12 3 25 6 30 6 4 0 6 19 5 43 -2 23 1 59
5 80 8 37 7 38 -15 32 -13 -4 -24 -12 -26 -16 -5 -12 -62 -12 -62 0 0 5 9 11
20 14 15 4 20 14 20 36 0 17 5 31 10 31 6 0 11 10 11 23 1 16 2 18 6 5 3 -13
15 -18 39 -18z m-6974 -153 c4 -27 58 -39 58 -13 0 9 8 16 19 16 13 0 20 -10
24 -35 8 -49 30 -69 68 -61 29 6 30 5 14 -16 -8 -13 -15 -33 -15 -45 0 -19 -5
-23 -30 -23 -27 0 -30 3 -30 31 0 26 -3 30 -19 26 -15 -3 -21 1 -23 17 -4 28
-55 36 -65 10 -3 -10 -15 -26 -25 -36 -17 -17 -18 -17 -18 7 0 14 -4 25 -9 25
-15 0 -19 50 -5 65 8 8 17 28 20 45 5 24 9 28 20 19 7 -6 14 -20 16 -32z
m6098 -62 c0 -8 6 -15 14 -15 8 0 18 -9 21 -20 6 -20 35 -28 35 -10 0 6 7 10
15 10 8 0 15 5 15 10 0 6 6 10 14 10 8 0 17 6 19 13 3 7 6 5 6 -5 1 -10 6 -18
11 -18 6 0 10 -7 10 -15 0 -8 6 -15 13 -16 10 0 10 -2 0 -6 -7 -2 -13 -9 -13
-15 0 -5 -6 -15 -12 -22 -7 -8 -20 -23 -28 -35 -15 -22 -40 -29 -40 -11 0 6 5
10 10 10 6 0 10 8 10 19 0 14 -10 21 -35 25 -19 4 -35 11 -35 16 0 6 -5 10
-11 10 -17 0 -39 -27 -39 -48 0 -15 -5 -18 -25 -14 -24 4 -26 7 -21 56 3 28 5
56 5 63 1 10 10 14 54 22 4 0 7 -6 7 -14z m2582 -19 c-3 -15 8 -16 92 -13 74
2 96 0 96 -10 0 -8 7 -16 15 -19 8 -4 15 -12 15 -19 0 -10 -14 -13 -57 -9 -32
2 -71 2 -86 -2 -24 -5 -28 -10 -24 -32 2 -15 2 -21 0 -14 -6 17 -33 15 -33 -2
0 -7 -9 -16 -20 -19 -17 -4 -20 0 -20 28 0 29 -4 34 -30 40 -32 7 -39 22 -15
31 8 4 15 11 15 17 0 15 33 46 45 43 6 -2 9 -11 7 -20z m-691 -104 c-21 -7
-11 -32 13 -32 13 0 31 -3 40 -6 19 -7 21 -50 4 -57 -10 -4 -10 -8 1 -15 11
-8 9 -15 -15 -37 -24 -22 -35 -26 -64 -21 -19 3 -52 6 -72 6 -35 0 -38 2 -38
29 0 17 5 33 10 36 13 8 13 55 0 55 -5 0 -7 7 -4 15 10 25 34 17 34 -10 0 -15
6 -25 14 -25 17 0 31 34 21 51 -4 6 2 13 14 16 12 3 21 10 21 15 0 6 7 4 16
-3 10 -8 12 -15 5 -17z m-7470 -42 c5 0 9 -20 9 -45 0 -33 -4 -45 -15 -45 -8
0 -15 -9 -15 -20 0 -11 -4 -20 -10 -20 -5 0 -12 -23 -16 -51 -6 -48 -8 -50
-33 -44 -40 10 -58 59 -26 71 16 7 21 54 5 54 -5 0 -2 9 7 20 10 11 24 20 31
20 10 0 12 7 8 27 -8 28 8 47 32 38 8 -3 18 -5 23 -5z m4311 -13 c4 -7 2 -13
-7 -15 -8 -2 -15 -8 -15 -13 0 -5 -7 -9 -15 -9 -9 0 -15 -9 -15 -25 0 -14 4
-25 10 -25 5 0 12 -11 16 -24 8 -35 105 -61 131 -35 13 13 33 4 33 -17 0 -8
-9 -14 -21 -14 -18 0 -20 -4 -14 -29 6 -28 4 -30 -29 -37 -23 -4 -36 -12 -37
-23 -1 -15 -2 -15 -6 0 -4 18 -32 29 -71 29 -17 0 -24 7 -28 30 -5 23 -12 30
-30 30 -35 0 -31 29 5 36 l29 6 -2 80 -1 80 30 -6 c16 -4 32 -12 37 -19z
m2628 -82 c0 -18 5 -25 20 -25 11 0 20 4 20 9 0 5 9 11 20 14 15 4 20 0 20
-14 0 -10 3 -19 8 -19 4 0 12 -8 18 -17 10 -16 7 -18 -22 -19 -66 -1 -118 -53
-56 -55 21 -1 22 -2 7 -6 -11 -3 -24 -11 -28 -19 -10 -18 -27 -18 -27 -1 0 14
-35 47 -50 47 -6 0 -10 -6 -10 -14 0 -9 -7 -12 -20 -9 -14 4 -20 0 -20 -10 0
-9 9 -19 20 -22 11 -3 20 -13 20 -21 0 -8 5 -14 10 -14 6 0 10 -7 10 -15 0 -8
-4 -15 -10 -15 -5 0 -10 -9 -10 -20 0 -15 7 -20 25 -20 14 0 28 7 31 15 9 22
18 18 32 -15 10 -21 21 -30 38 -30 27 0 33 -24 8 -34 -25 -10 -34 -7 -34 9 0
9 -9 15 -25 15 -20 0 -25 -5 -25 -24 0 -17 -7 -26 -25 -30 -14 -4 -30 -4 -35
-1 -5 3 -21 3 -35 -1 -19 -5 -25 -12 -25 -35 0 -16 5 -29 10 -29 6 0 10 -7 10
-15 0 -9 9 -15 25 -15 16 0 25 6 25 15 0 8 5 15 10 15 6 0 10 7 10 15 0 8 7
15 15 15 10 0 15 -11 15 -35 0 -21 5 -35 13 -35 6 0 18 -13 25 -29 9 -20 22
-31 40 -34 21 -3 19 -4 -10 -6 -21 0 -38 -5 -38 -11 0 -5 -13 -10 -29 -10 -28
0 -29 1 -24 40 l6 40 -41 0 c-22 0 -42 -4 -44 -9 -1 -4 -28 -10 -59 -13 -49
-4 -55 -3 -43 10 8 8 12 24 9 36 -2 11 -2 15 1 9 3 -7 19 -13 35 -13 25 0 29
4 29 29 0 16 -6 31 -12 33 -9 3 -8 9 5 22 9 10 17 24 17 32 0 8 5 14 11 14 13
0 39 25 39 39 0 6 -11 13 -25 17 -14 3 -25 13 -25 21 0 8 -9 17 -20 20 -23 6
-27 23 -5 23 20 0 19 26 -1 34 -30 12 -42 6 -47 -24 -7 -31 -37 -43 -37 -15 0
8 7 15 15 15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 9 -15 20 0 11 -4 20
-10 20 -5 0 -10 7 -10 15 0 8 7 15 15 15 8 0 15 5 15 10 0 20 18 9 24 -15 9
-36 36 -32 36 5 0 20 5 30 15 30 7 0 18 8 24 17 12 23 24 11 25 -24 1 -21 7
-29 24 -31 15 -2 22 2 22 12 0 10 9 16 24 16 21 0 25 6 31 50 7 52 25 69 25
25z m-6878 -27 c3 -23 48 -40 48 -19 0 6 11 11 24 11 14 0 31 7 40 15 17 17
46 20 46 5 0 -5 7 -10 15 -10 8 0 15 -7 15 -15 0 -10 10 -15 30 -15 26 0 30 4
30 26 0 21 2 23 13 12 20 -19 27 -56 20 -103 l-5 -41 -70 4 c-69 4 -108 23
-108 52 0 6 -4 10 -10 10 -5 0 -10 7 -10 15 0 17 -8 18 -73 9 -30 -4 -48 -12
-51 -23 -3 -9 -3 1 0 23 8 57 40 88 46 44z m6389 -68 c19 0 29 5 29 15 0 11
12 15 52 15 40 0 49 -3 40 -12 -16 -16 -15 -28 3 -28 8 0 15 -7 15 -15 0 -8 7
-15 15 -15 8 0 15 -4 15 -10 0 -11 -16 -13 -68 -10 -51 3 -92 -10 -93 -28 -1
-15 -2 -15 -6 1 -5 18 -20 21 -47 11 -25 -10 -19 -34 9 -34 14 0 25 -4 25 -10
0 -5 -7 -10 -15 -10 -31 0 -14 -29 20 -36 25 -4 35 -11 35 -25 0 -11 -4 -19
-10 -19 -5 0 -10 -7 -10 -16 0 -14 7 -15 46 -9 41 6 45 5 42 -12 -2 -12 -14
-19 -34 -21 -42 -4 -94 -60 -94 -102 0 -23 -4 -30 -20 -30 -23 0 -27 28 -5 36
30 12 16 34 -20 34 -19 0 -35 5 -35 11 0 6 -6 17 -14 25 -18 18 -106 19 -107
2 0 -10 -2 -10 -6 0 -2 6 -15 12 -27 12 -12 0 -28 9 -36 20 -21 30 -40 25 -40
-10 0 -19 -5 -30 -14 -30 -11 0 -14 12 -13 45 2 40 5 45 29 51 16 3 28 10 28
15 0 5 9 0 20 -11 11 -11 20 -29 20 -40 0 -13 7 -20 20 -20 11 0 20 7 20 15 0
8 5 15 10 15 6 0 10 16 10 35 0 31 3 35 25 35 20 0 25 5 25 25 0 21 -5 25 -27
25 -20 0 -24 3 -14 9 8 5 11 16 8 25 -4 10 -1 16 8 16 8 0 15 7 15 15 0 8 -4
15 -10 15 -5 0 -10 7 -10 15 0 8 7 15 15 15 10 0 15 -10 15 -30 0 -25 4 -30
25 -30 14 0 25 4 25 9 0 5 10 11 23 13 15 2 23 11 26 28 4 29 24 46 29 25 2
-9 15 -15 33 -15z m-1661 -19 c0 -4 21 -11 46 -15 65 -9 78 -25 42 -56 -15
-13 -28 -28 -28 -34 0 -22 35 -46 69 -47 20 -1 28 -3 19 -6 -10 -2 -18 -9 -18
-14 0 -5 -6 -9 -14 -9 -7 0 -16 -9 -19 -21 -8 -29 -30 -19 -42 19 -5 18 -15
32 -22 32 -7 0 -13 9 -13 19 0 11 -6 22 -12 25 -10 5 -10 7 0 12 20 10 13 33
-12 40 -29 7 -46 -1 -46 -22 0 -19 -12 -18 -32 5 -9 10 -24 24 -34 29 -11 6
-15 14 -11 18 4 4 13 0 20 -10 9 -12 23 -17 42 -14 24 2 31 8 33 31 3 24 32
41 32 18z m-5210 -102 c0 -5 7 -9 15 -9 8 0 15 5 15 10 0 6 9 10 20 10 11 0
20 -4 20 -9 0 -5 -9 -11 -20 -14 -11 -3 -20 -12 -20 -21 0 -9 -4 -16 -10 -16
-5 0 -10 -7 -10 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -7 -15 -15 0 -8 -4 -15 -10
-15 -5 0 -10 -7 -10 -15 0 -8 -4 -15 -9 -15 -5 0 -13 -9 -16 -20 -9 -29 -22
-25 -28 10 -6 28 -10 30 -51 30 -30 0 -46 -5 -50 -15 -6 -18 2 -45 15 -45 5 0
9 -7 9 -15 0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 -7 15 -15 15 -8 0 -15
6 -15 14 0 10 -18 15 -60 18 -58 4 -88 16 -62 25 6 3 12 13 12 23 0 10 -6 20
-12 23 -19 6 -6 16 30 23 25 5 32 3 32 -10 0 -21 55 -22 64 -1 4 11 21 15 66
15 45 0 60 4 60 14 0 8 -6 17 -12 19 -7 3 8 6 35 6 34 1 47 5 47 16 0 9 6 12
15 9 8 -4 15 -10 15 -15z m781 11 c11 0 19 -7 19 -15 0 -8 -4 -15 -9 -15 -5 0
-12 -8 -14 -17 -4 -14 -5 -12 -6 5 -1 28 -31 30 -31 2 0 -11 4 -20 9 -20 5 0
11 -9 14 -20 3 -12 14 -20 26 -20 12 0 21 -6 21 -15 0 -8 -7 -15 -15 -15 -11
0 -15 -12 -15 -50 0 -43 -3 -50 -19 -50 -14 0 -22 -10 -26 -32 -10 -43 -26
-59 -42 -42 -16 16 -17 31 0 38 10 5 10 7 0 12 -7 3 -13 11 -13 18 0 6 -9 18
-20 26 -38 28 -24 40 45 40 68 0 77 8 59 54 -7 18 -64 23 -64 6 0 -5 -4 -10
-10 -10 -14 0 -12 48 2 52 8 3 5 12 -10 28 -37 40 -26 54 37 46 24 -3 52 -6
62 -6z m5179 -250 c0 -13 7 -20 20 -20 11 0 20 -4 20 -10 0 -5 7 -10 15 -10 8
0 15 5 15 10 0 21 61 10 84 -15 27 -29 32 -30 52 -10 9 9 34 15 60 15 28 0 44
4 44 13 0 9 2 9 8 1 4 -7 18 -14 32 -17 25 -4 25 -4 -2 -6 -29 -1 -41 -31 -14
-31 8 0 16 -5 18 -12 2 -6 30 -13 61 -16 47 -4 57 -8 57 -23 0 -14 7 -19 25
-19 16 0 25 -6 25 -15 0 -8 5 -15 10 -15 6 0 10 -9 10 -20 0 -27 -18 -25 -22
3 -4 27 -56 37 -73 15 -5 -8 -22 -18 -37 -22 -26 -6 -40 -36 -18 -36 6 0 10
-7 10 -15 0 -8 -7 -15 -15 -15 -8 0 -15 5 -15 10 0 18 -58 11 -64 -7 -6 -21 1
26 10 62 5 21 3 25 -17 26 -24 2 -24 2 -1 6 17 3 22 11 22 34 0 32 -11 37 -33
14 -10 -9 -17 -11 -19 -4 -2 6 -25 13 -51 16 -49 6 -56 -1 -41 -41 7 -17 44
-22 44 -6 0 6 11 10 25 10 32 0 32 -16 1 -24 -14 -3 -28 -15 -31 -27 -5 -15
-12 -19 -28 -15 -12 3 -28 6 -34 6 -7 0 -13 5 -13 11 0 7 -8 17 -17 24 -13 10
-17 26 -15 69 2 56 2 56 -27 56 -16 0 -31 -6 -35 -15 -8 -23 -20 -18 -34 15
-10 21 -21 30 -38 30 -13 0 -24 5 -24 10 0 6 7 10 15 10 8 0 15 5 15 10 0 6 5
10 10 10 6 0 10 -9 10 -20z m1080 -55 c0 -9 12 -18 29 -21 17 -4 32 -12 35
-19 2 -7 18 -16 35 -20 25 -5 31 -12 31 -32 0 -16 -7 -27 -20 -30 -11 -3 -20
-14 -20 -24 0 -21 20 -25 38 -7 8 8 12 9 12 1 0 -6 -5 -13 -12 -15 -9 -3 -8
-13 2 -39 8 -19 13 -44 12 -56 -3 -19 1 -23 22 -23 14 0 26 5 26 10 0 6 7 10
15 10 20 0 19 -6 -4 -29 -11 -11 -32 -21 -48 -23 -20 -2 -29 -9 -31 -25 -3
-19 1 -23 21 -23 23 0 25 -3 20 -38 -5 -36 -3 -39 28 -49 19 -6 26 -11 17 -12
-10 -1 -18 -8 -18 -16 0 -8 -7 -15 -15 -15 -8 0 -15 -5 -15 -11 0 -24 -30 -37
-98 -44 l-72 -7 0 31 c0 26 -4 31 -23 31 -15 0 -29 -9 -36 -24 -15 -26 -8 -46
15 -46 8 0 14 -4 14 -10 0 -5 8 -10 18 -10 11 0 29 -9 41 -19 13 -11 28 -22
34 -25 19 -7 -12 -24 -34 -19 -14 4 -19 0 -19 -16 0 -14 6 -21 20 -21 11 0 28
-9 37 -20 10 -11 23 -20 30 -20 8 0 13 -12 12 -32 -1 -19 -3 -25 -6 -15 -4 15
-12 18 -34 15 -25 -4 -29 -1 -29 19 0 16 -6 23 -20 23 -11 0 -20 7 -20 15 0 8
-7 15 -15 15 -8 0 -15 -7 -15 -15 0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8
-7 15 -15 15 -8 0 -15 -7 -15 -15 0 -9 -8 -19 -17 -22 -10 -4 -23 -12 -30 -19
-13 -13 -63 -7 -63 7 0 5 -7 9 -15 9 -8 0 -15 -4 -15 -10 0 -5 -6 -10 -14 -10
-8 0 -18 -9 -21 -20 -3 -11 -11 -20 -16 -20 -5 0 -9 -9 -9 -20 0 -11 7 -20 15
-20 8 0 15 -4 15 -9 0 -5 14 -12 30 -16 27 -6 44 -35 20 -35 -5 0 -13 -18 -16
-40 -4 -24 -12 -40 -20 -40 -16 0 -16 -5 -8 -37 9 -34 68 -32 100 3 28 29 30
41 8 46 -13 2 -11 8 10 31 19 21 34 27 66 27 29 0 40 4 40 15 0 8 7 15 15 15
8 0 15 -4 15 -9 0 -5 10 -11 21 -14 18 -5 20 -11 14 -34 -3 -15 -11 -29 -16
-31 -15 -6 -10 -32 6 -32 8 0 15 5 15 10 0 6 7 10 15 10 11 0 15 -12 15 -44 0
-41 -2 -45 -30 -51 -16 -4 -30 -11 -30 -16 0 -5 -9 -9 -20 -9 -13 0 -20 7 -20
20 0 11 -5 20 -12 20 -14 0 -77 -19 -100 -31 -10 -5 -26 -9 -37 -9 -33 0 -52
-28 -47 -68 3 -21 3 -29 0 -19 -3 9 -12 17 -20 17 -8 0 -17 7 -20 15 -6 16
-64 21 -64 6 0 -5 -8 -11 -19 -14 -16 -4 -17 -1 -7 24 6 16 17 29 24 29 10 0
12 7 8 23 -3 12 -6 28 -6 35 0 7 -14 12 -35 12 -19 0 -35 -4 -35 -10 0 -5 -16
-10 -35 -10 -24 0 -35 5 -35 15 0 8 -7 15 -16 15 -12 0 -14 7 -9 31 6 25 4 31
-14 36 -12 3 -21 9 -21 13 0 4 -9 10 -20 13 -25 6 -28 62 -4 71 30 12 57 6 76
-16 17 -20 17 -23 4 -26 -14 -3 -33 -39 -24 -47 1 -1 18 -6 36 -10 25 -5 32
-3 32 9 0 9 7 16 15 16 8 0 15 -4 15 -10 0 -16 27 -12 34 5 3 8 12 15 21 15 8
0 15 4 15 9 0 5 9 11 20 14 26 7 28 64 3 74 -28 11 -45 31 -31 35 7 3 12 18
13 34 0 24 5 30 28 32 15 2 27 8 27 13 0 5 16 9 35 9 24 0 35 5 35 15 0 8 -7
15 -16 15 -10 0 -14 7 -12 23 2 16 11 23 31 25 15 2 27 7 27 12 0 5 9 12 20
15 24 8 26 30 4 39 -20 8 -14 68 9 91 9 8 17 20 19 25 2 6 24 14 48 17 42 6
44 5 28 -10 -9 -10 -24 -17 -32 -17 -9 0 -16 -4 -16 -10 0 -5 21 -8 48 -7 42
2 47 5 46 25 0 12 3 22 8 22 13 0 38 26 38 39 0 6 11 11 25 11 14 0 25 5 25
10 0 6 -11 10 -24 10 -17 0 -26 7 -30 24 -5 20 -14 26 -53 32 -37 5 -49 3 -58
-10 -15 -20 -25 -21 -25 -1 0 10 -10 15 -30 15 -16 0 -30 5 -30 10 0 6 -18 10
-40 10 -22 0 -40 5 -40 10 0 6 -6 10 -14 10 -16 0 -26 21 -26 56 0 13 -6 24
-12 25 -7 0 -3 4 10 9 12 5 22 16 22 25 0 22 17 18 23 -5 3 -13 0 -20 -9 -20
-23 0 -16 -17 21 -54 28 -29 44 -35 92 -41 32 -3 73 -8 91 -11 19 -3 32 -1 32
6 0 5 -7 10 -15 10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 7 15 15 0 8 9 15
20 15 11 0 20 7 20 15 0 8 -9 19 -20 25 -12 6 -20 21 -20 35 0 16 -6 25 -15
25 -8 0 -15 -5 -15 -10 0 -6 -9 -20 -20 -32 -11 -12 -20 -27 -20 -34 0 -7 9
-16 20 -19 30 -10 24 -25 -10 -25 -16 0 -30 5 -30 10 0 6 -7 10 -15 10 -8 0
-15 9 -15 19 0 10 7 21 15 25 8 3 15 12 15 21 0 8 5 15 10 15 6 0 10 14 10 30
0 23 5 30 19 30 30 0 51 11 51 26 0 8 -4 14 -10 14 -5 0 -10 7 -10 15 0 8 7
15 15 15 8 0 15 -7 15 -15z m-7272 -137 c2 -4 12 -8 23 -8 14 0 19 -7 19 -26
0 -26 0 -26 59 -19 58 7 58 6 65 -22 4 -15 12 -31 17 -34 6 -3 -17 -9 -51 -12
-60 -7 -89 -22 -91 -49 0 -10 -2 -10 -6 0 -6 16 -31 15 -49 -3 -15 -15 -40
-10 -62 12 -7 7 -22 13 -33 13 -12 0 -19 7 -19 20 0 18 -6 20 -47 18 -46 -1
-83 -15 -83 -30 0 -22 37 -48 67 -48 18 0 33 -4 33 -10 0 -14 28 -12 33 3 2 6
8 8 13 4 5 -5 1 -13 -9 -18 -9 -6 -17 -19 -17 -29 0 -11 -4 -20 -10 -20 -5 0
-10 -11 -10 -25 0 -18 7 -26 25 -31 16 -4 25 -13 25 -25 0 -14 -7 -19 -25 -19
-23 0 -25 -3 -25 -50 0 -27 5 -50 10 -50 6 0 10 -13 10 -28 0 -28 -2 -29 -45
-28 -29 1 -45 5 -45 14 0 6 -6 12 -14 12 -15 0 -25 27 -19 48 8 22 33 13 33
-13 0 -40 27 -30 33 13 11 63 1 79 -50 85 -62 7 -77 31 -23 35 32 3 40 7 40
23 0 10 -7 19 -15 19 -8 0 -15 7 -15 15 0 8 -7 15 -15 15 -8 0 -15 5 -15 10 0
22 -38 9 -44 -15 -7 -28 -36 -35 -36 -9 0 14 -8 16 -42 11 -24 -3 -44 -11 -46
-16 -6 -19 -29 -12 -35 11 -4 12 -4 24 -2 27 3 2 5 -1 5 -7 0 -7 7 -12 15 -12
8 0 15 5 15 10 0 6 5 10 11 10 11 0 39 25 39 35 0 4 -7 13 -15 21 -8 9 -15 22
-15 30 0 8 7 17 15 20 8 4 15 15 15 25 0 10 -7 19 -15 19 -22 0 -18 17 5 23
13 3 20 0 20 -9 0 -8 6 -14 14 -14 7 0 16 -9 19 -20 3 -11 13 -20 22 -20 12 0
16 8 13 33 -3 29 0 32 27 35 16 2 33 9 37 15 6 9 8 9 8 0 0 -7 16 -15 35 -19
20 -3 35 -12 35 -20 0 -15 10 -17 34 -8 17 7 22 54 6 54 -5 0 -10 9 -10 20 0
11 7 20 15 20 8 0 15 -4 15 -10 0 -16 57 -12 64 5 4 11 13 14 33 9 16 -4 30
-11 31 -16z m6932 -48 c0 -33 -4 -60 -10 -60 -5 0 -10 -10 -11 -22 0 -13 -3
-17 -6 -10 -3 7 -25 12 -59 12 l-54 0 0 -63 c0 -51 4 -65 20 -77 21 -15 29
-80 10 -80 -5 0 -10 -7 -10 -15 0 -8 -9 -15 -20 -15 -13 0 -20 -7 -20 -20 0
-11 7 -20 15 -20 8 0 15 -7 15 -15 0 -8 6 -15 14 -15 26 0 29 -21 7 -41 -25
-23 -27 -39 -6 -39 8 0 15 -4 15 -9 0 -5 -7 -11 -15 -15 -8 -3 -15 -17 -15
-31 0 -24 -1 -25 -16 -11 -8 9 -27 16 -41 16 -33 0 -53 27 -46 63 4 21 2 27
-11 27 -9 0 -20 8 -23 18 -4 9 -11 20 -17 24 -6 4 -7 10 -3 14 4 4 13 0 20
-10 9 -12 23 -17 42 -14 29 3 30 5 30 53 0 49 -1 50 -32 53 -18 2 -33 7 -33
13 0 5 -7 9 -15 9 -8 0 -15 5 -15 10 0 6 -15 10 -34 10 -21 0 -36 6 -40 15 -4
11 1 15 19 15 27 0 28 3 19 38 -11 41 -94 25 -94 -18 0 -23 -37 -60 -60 -60
-11 0 -20 5 -20 11 0 15 42 59 58 59 19 0 14 28 -5 33 -10 3 -3 5 15 6 17 0
32 6 32 11 0 6 7 10 15 10 8 0 15 -4 15 -10 0 -15 39 -12 56 4 16 17 19 36 4
36 -16 0 -11 27 6 34 9 3 28 5 42 5 17 -1 21 -3 10 -6 -35 -9 -20 -33 20 -33
46 0 57 8 66 43 6 24 11 27 51 27 37 0 45 3 45 18 0 11 9 29 20 42 11 13 20
27 20 32 0 4 7 8 15 8 12 0 15 -13 15 -60z m-1447 -132 c9 -6 9 -8 0 -8 -7 0
-13 -9 -13 -20 0 -11 7 -20 15 -20 8 0 15 -4 15 -10 0 -15 21 -12 34 4 6 8 21
17 34 21 19 5 22 3 22 -19 0 -24 -3 -26 -46 -26 -46 0 -46 0 -42 -31 4 -22 0
-33 -11 -37 -9 -3 -19 -10 -23 -16 -5 -7 -8 -4 -8 7 0 9 -4 17 -10 17 -5 0
-10 9 -10 20 0 11 -7 20 -15 20 -8 0 -15 5 -15 10 0 6 -16 10 -35 10 -36 0
-50 22 -20 34 9 3 15 18 15 36 0 28 12 37 44 31 19 -4 46 12 47 27 1 9 4 4 6
-13 3 -16 10 -33 16 -37z m-388 32 c3 -11 4 -29 0 -39 -9 -30 30 -91 58 -91
10 0 13 -7 10 -19 -4 -16 -11 -18 -39 -14 -22 4 -34 2 -34 -6 0 -6 -18 -11
-44 -11 -24 0 -46 -4 -48 -9 -6 -17 -68 -18 -68 -1 0 6 9 10 20 10 11 0 20 5
20 10 0 6 10 8 24 4 32 -8 66 3 66 22 0 8 6 14 14 14 16 0 32 37 21 48 -4 4
-33 7 -66 6 -32 -1 -59 3 -59 7 0 5 4 9 9 9 5 0 12 11 15 25 4 16 13 25 26 25
10 0 25 7 32 15 18 20 36 18 43 -5z m958 -78 c23 0 47 4 52 8 6 5 31 6 58 3
l47 -6 -22 -19 c-15 -13 -30 -17 -50 -13 -23 5 -28 3 -29 -12 0 -10 -3 -13 -6
-5 -2 6 -11 12 -18 12 -14 0 -25 -30 -25 -72 0 -16 -4 -28 -10 -28 -5 0 -10
-6 -10 -13 0 -7 -10 -13 -22 -13 -107 -1 -116 -3 -123 -31 -3 -15 -3 -29 2
-30 4 -2 15 -8 23 -14 11 -8 12 -12 2 -16 -7 -2 -19 -11 -26 -19 -8 -8 -19
-14 -24 -14 -6 0 -12 -5 -14 -12 -2 -6 -9 -8 -16 -4 -14 9 -4 36 14 36 8 0 14
9 14 20 0 17 -7 20 -50 20 -43 0 -50 -3 -50 -20 0 -16 -7 -20 -30 -20 -27 0
-30 -3 -30 -30 0 -33 13 -38 36 -15 14 14 44 11 44 -5 0 -9 -36 -20 -68 -20
-16 0 -26 7 -29 20 -3 11 -11 20 -17 20 -8 0 -11 11 -8 30 2 17 8 30 13 30 5
0 9 7 9 15 0 8 -7 15 -15 15 -8 0 -15 9 -15 19 0 10 -7 22 -16 25 -29 11 -75
6 -80 -9 -9 -22 -24 -18 -24 5 0 28 35 60 66 60 15 0 35 10 46 22 l20 23 -8
-30 c-4 -17 -11 -31 -16 -33 -14 -6 -9 -32 7 -32 8 0 15 5 15 10 0 13 27 13
32 -1 5 -15 130 -17 135 -1 5 14 53 17 53 2 0 -17 40 -11 61 9 16 15 19 26 13
55 -5 31 -3 41 15 57 11 11 22 30 22 42 l2 22 4 -22 c4 -20 10 -23 46 -21z
m2522 8 c18 -8 17 -9 -8 -9 -27 -1 -57 -20 -57 -37 0 -4 7 -12 16 -18 16 -12
11 -66 -7 -66 -5 0 -9 -7 -9 -15 0 -8 5 -15 10 -15 6 0 10 -11 10 -24 0 -14
-4 -28 -10 -31 -5 -3 -10 -15 -10 -26 0 -10 -4 -19 -10 -19 -5 0 -10 5 -10 10
0 6 -13 10 -30 10 -16 0 -30 -4 -30 -10 0 -21 -20 -9 -26 15 -5 19 -13 25 -35
25 -22 0 -30 6 -35 25 -3 14 -12 25 -19 25 -14 0 -29 45 -20 60 4 6 13 10 21
10 8 0 14 5 14 10 0 6 7 10 15 10 8 0 15 -7 15 -15 0 -8 8 -15 19 -15 14 0 21
-10 25 -35 4 -19 11 -35 16 -35 6 0 10 -7 10 -15 0 -10 10 -15 30 -15 27 0 30
3 30 30 0 17 -4 30 -10 30 -16 0 -12 27 5 34 8 3 15 12 15 21 0 20 -56 20 -64
0 -3 -8 -12 -15 -21 -15 -8 0 -15 7 -15 15 0 8 -8 15 -17 16 -11 0 -13 3 -5 6
6 2 12 9 12 15 0 21 31 38 72 39 24 0 52 4 63 9 11 5 22 9 25 9 3 0 14 -4 25
-9z m-8311 -55 c33 -30 33 -41 1 -49 -19 -5 -25 -13 -25 -34 0 -21 -2 -24 -10
-12 -15 24 -113 17 -139 -9 -12 -12 -21 -23 -21 -26 0 -12 41 -45 56 -45 11 0
15 6 11 20 -4 15 0 20 14 20 25 0 25 -31 -1 -53 -11 -9 -20 -12 -20 -7 0 15
-29 12 -46 -4 -8 -8 -14 -22 -14 -30 0 -9 -7 -16 -15 -16 -8 0 -15 -7 -15 -16
0 -14 -1 -14 -16 0 -8 9 -25 16 -36 16 l-21 0 21 19 c12 10 22 23 22 30 0 6 5
11 10 11 6 0 10 7 10 15 0 8 7 15 15 15 8 0 15 5 15 10 0 6 -4 10 -10 10 -5 0
-10 7 -10 15 0 8 -9 15 -20 15 -11 0 -23 -7 -26 -15 -10 -26 -34 -17 -34 13 0
47 13 56 74 53 50 -2 56 0 56 18 0 25 16 36 24 16 3 -9 19 -15 41 -15 32 0 35
2 35 30 0 25 4 30 24 30 12 0 35 -11 50 -25z m8557 -85 c5 0 9 -11 9 -25 0
-14 -4 -25 -10 -25 -15 0 -12 -40 3 -41 10 0 10 -2 0 -6 -15 -6 -18 -63 -3
-63 6 0 10 -16 10 -35 0 -33 -2 -35 -34 -35 -19 0 -37 -6 -40 -12 -5 -10 -7
-10 -12 0 -6 14 -44 17 -44 3 0 -5 -13 -11 -29 -14 -16 -4 -31 -12 -34 -19 -2
-7 -2 -2 0 12 3 14 8 27 13 30 5 3 12 18 15 33 7 26 10 27 66 27 49 0 59 3 59
18 0 9 3 27 6 40 5 17 2 22 -14 22 -11 0 -28 -7 -36 -16 -9 -8 -32 -18 -51
-21 -19 -3 -35 -10 -35 -14 0 -5 -7 -9 -15 -9 -8 0 -24 -9 -35 -20 -11 -11
-24 -20 -30 -20 -5 0 -10 -15 -11 -32 0 -18 -3 -27 -6 -20 -6 14 -63 17 -63 2
0 -5 -6 -10 -14 -10 -13 0 -30 65 -21 81 3 4 19 10 35 14 17 4 30 10 30 15 0
4 9 10 20 13 13 3 20 0 20 -9 0 -9 11 -14 30 -14 25 0 30 4 30 25 0 16 -6 25
-15 25 -8 0 -15 8 -14 18 0 10 3 12 6 5 7 -19 83 -17 83 2 0 8 -11 17 -25 21
-14 3 -25 10 -25 15 0 15 63 10 81 -7 12 -11 27 -14 44 -10 20 5 26 15 32 49
4 23 9 34 11 25 2 -10 8 -18 13 -18z m-9389 -22 c3 -30 6 -33 41 -36 28 -2 37
0 37 12 0 9 4 16 9 16 5 0 11 10 13 22 3 20 4 21 20 5 18 -19 48 -23 48 -7 0
16 27 11 34 -6 11 -29 6 -44 -14 -44 -13 0 -20 -7 -20 -19 0 -14 -5 -18 -20
-14 -15 4 -20 0 -20 -16 0 -11 -4 -21 -10 -21 -5 0 -10 -7 -10 -16 0 -12 -7
-14 -32 -9 -18 4 -34 11 -36 16 -2 5 -12 9 -23 9 -13 0 -19 7 -19 21 0 17 -5
20 -32 17 -29 -3 -33 -7 -36 -35 -4 -37 -11 -41 -34 -15 -25 27 -61 52 -75 52
-7 0 -13 9 -13 20 0 11 5 20 10 20 11 0 23 9 57 40 12 11 17 12 21 2 5 -17 42
-15 42 3 0 10 10 15 29 15 26 0 30 -4 33 -32z m5933 -68 c-10 -31 3 -57 35
-70 35 -14 38 -30 10 -50 -11 -8 -20 -19 -20 -26 0 -6 -12 -17 -27 -25 -43
-22 -53 -35 -53 -68 0 -28 -3 -30 -45 -37 -58 -8 -91 1 -98 26 -4 17 0 20 29
20 29 0 34 3 34 25 0 26 25 45 59 45 12 0 21 5 21 10 0 6 7 10 15 10 10 0 15
10 15 30 0 34 -2 35 -33 15 -25 -16 -67 -10 -67 10 0 8 13 16 29 20 20 4 31
14 35 30 4 15 16 27 31 31 14 3 25 10 25 15 0 5 3 9 6 9 3 0 2 -9 -1 -20z
m1125 -70 c0 -16 5 -30 10 -30 6 0 10 5 10 10 0 6 11 10 25 10 14 0 25 -4 25
-10 0 -5 9 -10 20 -10 26 0 34 -19 21 -53 -9 -24 -15 -27 -61 -27 -43 0 -50 3
-50 20 0 16 -7 20 -30 20 -24 0 -30 -4 -30 -21 0 -15 -4 -19 -16 -15 -9 3 -18
6 -20 6 -2 0 -4 7 -4 15 0 8 -11 17 -25 21 -44 11 -23 34 31 34 24 0 64 31 64
50 0 6 7 10 15 10 10 0 15 -10 15 -30z m-6402 -221 c5 -12 42 -11 42 1 0 6 7
10 15 10 8 0 15 -4 15 -10 0 -5 -4 -10 -10 -10 -5 0 -10 -16 -10 -35 0 -24 -5
-35 -15 -35 -8 0 -15 7 -15 15 0 9 -9 15 -25 15 -18 0 -25 -5 -25 -20 0 -11 7
-20 15 -20 19 0 21 -76 3 -83 -18 -5 -48 3 -48 14 0 12 -27 12 -32 0 -1 -5
-15 -12 -30 -16 -23 -6 -27 -3 -33 24 -4 17 -13 31 -21 31 -8 0 -14 7 -14 15
0 10 10 15 30 15 17 0 30 -4 30 -10 0 -5 11 -10 25 -10 25 0 33 18 15 36 -14
14 9 82 29 87 20 6 55 -3 59 -14z m8602 6 c0 -9 9 -15 25 -15 15 0 25 6 25 14
0 11 10 13 43 9 45 -6 59 -13 40 -20 -7 -3 -13 -11 -13 -19 0 -7 -11 -16 -25
-20 -14 -3 -25 -10 -25 -15 0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 15 0 15 -26 20
-54 9 -18 -7 -23 -64 -6 -64 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10 -13
10 -30 0 -28 2 -30 39 -30 35 0 39 -3 45 -30 3 -19 12 -30 23 -30 15 0 15 -1
1 -12 -9 -7 -18 -20 -21 -28 -4 -12 -6 -11 -6 3 -1 13 -10 17 -35 17 -19 0
-41 3 -49 6 -24 9 -50 -25 -43 -58 4 -21 2 -28 -9 -28 -10 0 -15 10 -15 30 0
27 -3 30 -34 30 -21 0 -36 6 -40 15 -3 8 -12 15 -21 15 -8 0 -15 9 -15 20 0
16 7 20 30 20 20 0 30 5 30 15 0 8 9 15 20 15 11 0 20 5 20 10 0 6 9 10 20 10
13 0 20 7 20 20 0 13 -7 20 -20 20 -11 0 -20 7 -20 15 0 8 -8 15 -17 15 -10 0
-24 4 -32 9 -12 8 -12 12 2 26 10 9 17 27 17 39 0 18 7 25 30 30 18 3 30 12
30 21 0 8 7 15 15 15 8 0 15 -7 15 -15z m-3560 -5 c0 -5 7 -10 16 -10 14 0 14
-1 0 -16 -21 -21 -20 -36 4 -64 11 -13 20 -24 20 -25 0 0 -16 1 -35 3 -27 3
-35 1 -35 -12 0 -9 -7 -19 -15 -22 -8 -4 -15 -15 -15 -25 0 -10 -7 -19 -15
-19 -9 0 -15 -9 -15 -25 0 -31 -21 -32 -39 -3 -10 16 -10 23 -2 26 6 2 11 10
11 18 0 16 35 54 50 54 6 0 10 16 10 35 0 28 -4 35 -20 35 -11 0 -20 -4 -20
-10 0 -5 -4 -10 -10 -10 -5 0 -10 7 -10 15 0 8 -4 15 -10 15 -5 0 -10 7 -10
16 0 13 8 15 50 9 41 -6 51 -4 56 9 7 17 34 22 34 6z m1292 -92 c-7 -7 -12
-22 -12 -34 0 -17 -4 -21 -20 -17 -11 3 -20 9 -20 14 0 14 -30 10 -47 -6 -12
-12 -12 -19 -3 -30 9 -11 8 -19 -4 -37 -18 -25 -21 -74 -6 -83 7 -4 6 -13 -1
-26 -5 -11 -7 -29 -4 -40 7 -21 -12 -89 -25 -89 -10 0 -20 36 -20 73 0 22 -4
27 -25 27 -14 0 -25 5 -25 11 0 7 11 22 25 35 24 22 36 84 16 84 -18 0 -21 31
-3 37 28 11 42 30 42 57 0 23 4 25 53 29 28 2 61 4 72 5 15 2 16 -1 7 -10z
m-354 -36 c2 -8 -8 -12 -28 -12 -23 0 -33 -5 -37 -20 -3 -11 -14 -20 -24 -20
-11 0 -19 -7 -19 -15 0 -9 -12 -18 -30 -21 -16 -4 -30 -13 -30 -20 0 -8 -7
-14 -15 -14 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -15 -10 -9 0 -15 -9 -15 -25 0
-16 -6 -25 -15 -25 -8 0 -15 -6 -16 -12 0 -10 -2 -10 -6 0 -2 6 -11 12 -18 12
-9 0 -14 18 -17 55 -3 51 -1 55 19 55 12 0 23 4 25 9 2 5 15 13 31 16 21 6 27
4 27 -9 0 -29 30 -17 37 14 4 23 11 30 28 30 14 0 25 7 28 20 4 16 12 19 53
16 26 -1 50 -8 52 -14z m-5355 -59 c12 -3 17 -15 17 -43 0 -21 4 -41 8 -44 16
-10 92 7 92 20 0 10 13 14 40 14 22 0 40 4 40 9 0 5 10 13 23 17 12 4 24 11
27 15 10 14 74 24 66 10 -4 -6 -12 -9 -17 -6 -5 4 -16 -1 -25 -9 -8 -9 -22
-16 -30 -16 -8 0 -14 -7 -14 -15 0 -9 -9 -15 -21 -15 -20 0 -21 -4 -16 -48 3
-26 1 -55 -4 -63 -5 -7 -9 -9 -9 -3 0 6 -26 14 -57 17 -32 3 -59 11 -61 17 -6
16 -32 12 -32 -5 0 -8 7 -15 15 -15 8 0 15 -5 15 -10 0 -13 -50 -33 -59 -24
-3 4 0 9 7 11 14 5 16 33 2 33 -5 0 -10 7 -10 15 0 9 -9 15 -25 15 -16 0 -25
6 -25 15 0 9 -9 15 -25 15 -20 0 -25 5 -25 24 0 13 -3 38 -6 56 l-7 32 49 -7
c27 -4 57 -9 67 -12z m8177 -142 c0 -5 -7 -12 -16 -15 -12 -5 -15 -16 -12 -41
3 -24 0 -35 -9 -35 -7 0 -13 -4 -13 -9 0 -5 -18 -11 -40 -15 -26 -4 -40 -11
-40 -21 0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -8 7 -15 16 -15 9 0 14 -7
12 -17 -2 -13 -13 -18 -33 -18 l-30 0 3 60 c1 32 6 62 11 65 5 3 10 14 12 25
3 15 13 21 42 23 20 2 37 7 37 13 0 5 11 9 25 9 14 0 28 7 31 15 7 16 24 21
24 6z m-3013 -77 c10 -9 22 -27 28 -38 6 -11 22 -26 35 -33 l25 -13 -32 0
c-18 0 -33 -4 -33 -10 0 -5 -16 -12 -35 -16 -19 -3 -35 -10 -35 -15 0 -23 -21
-6 -26 21 -7 32 -31 41 -40 15 -4 -8 -17 -15 -30 -15 -15 0 -24 -6 -24 -15 0
-8 -4 -15 -10 -15 -5 0 -7 13 -3 30 5 30 5 30 -41 30 -34 0 -46 -4 -46 -15 0
-22 -38 -18 -45 5 -3 11 -11 20 -16 20 -5 0 -9 5 -9 10 0 6 20 10 44 10 40 0
44 2 49 29 7 35 21 38 144 35 64 -1 86 -6 100 -20z m1413 7 c0 -5 16 -12 35
-15 30 -6 35 -11 35 -36 0 -23 -5 -30 -30 -35 -24 -5 -30 -12 -30 -32 0 -16 7
-27 20 -30 11 -3 20 -14 20 -24 0 -11 4 -19 10 -19 21 0 50 -36 50 -62 0 -20
6 -28 23 -31 12 -2 3 -5 -20 -6 -41 -1 -43 -3 -43 -31 0 -18 -5 -30 -13 -30
-9 0 -12 10 -9 30 2 17 8 30 13 30 21 0 7 38 -16 44 -24 6 -36 26 -15 26 6 0
10 11 10 25 0 17 -5 25 -17 25 -37 0 -48 -17 -46 -70 2 -38 -1 -50 -12 -50 -9
0 -15 -10 -15 -25 0 -18 7 -26 25 -31 14 -3 25 -10 25 -15 0 -15 -50 -10 -66
6 -9 8 -11 15 -5 15 19 0 12 48 -6 54 -33 9 -196 -8 -201 -21 -2 -9 7 -13 27
-13 17 0 31 -4 31 -10 0 -5 6 -10 14 -10 24 0 7 -38 -20 -44 -13 -4 -24 -11
-24 -16 0 -6 -9 -10 -20 -10 -13 0 -20 -7 -20 -20 0 -16 7 -20 35 -20 19 0 35
-4 35 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -4 -15 -9 0 -4 -11 -20 -25 -35 -27
-29 -32 -51 -14 -62 6 -4 8 -20 5 -39 -5 -22 -2 -36 9 -45 19 -16 19 -30 0
-30 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -15 -10 -8 0 -15 7 -15 16 0 11 -6 15
-20 11 -17 -4 -20 0 -20 29 0 19 -4 34 -10 34 -5 0 -10 7 -10 15 0 8 7 15 15
15 9 0 15 9 15 21 0 12 11 32 25 45 25 23 33 44 16 44 -16 0 -20 40 -5 54 18
19 18 44 0 62 -19 18 -22 18 -46 -6 -11 -11 -32 -19 -47 -19 -17 1 -21 3 -10
6 9 2 17 11 17 19 0 8 9 14 20 14 23 0 26 18 6 34 -9 7 -12 22 -9 44 4 25 10
32 28 32 16 0 25 8 29 25 4 14 4 30 1 36 -4 5 5 4 19 -3 14 -6 26 -15 26 -19
0 -10 46 -20 64 -13 9 3 16 12 16 20 0 8 9 14 20 14 11 0 23 6 26 14 3 8 19
18 35 21 16 4 29 11 29 16 0 5 7 9 15 9 11 0 15 12 15 50 0 38 4 50 15 50 9 0
18 7 21 15 7 16 24 21 24 6z m-6864 -44 c8 -4 14 -21 14 -37 0 -17 5 -30 10
-30 24 0 7 -31 -20 -36 -16 -4 -30 -12 -30 -20 0 -20 -15 -25 -42 -15 -23 10
-23 10 5 10 22 1 27 6 27 25 0 29 -31 39 -91 30 l-41 -7 7 -43 c6 -41 4 -44
-16 -44 -17 0 -20 -4 -15 -22 3 -13 6 -31 6 -40 0 -10 7 -18 15 -18 8 0 15 -4
15 -10 0 -5 -6 -10 -13 -10 -14 0 -47 -35 -47 -50 0 -6 -11 -10 -25 -10 -18 0
-25 -5 -25 -19 0 -20 -18 -31 -51 -31 -10 0 -19 -4 -19 -10 0 -5 -7 -10 -15
-10 -10 0 -14 -10 -13 -33 1 -35 -26 -77 -50 -77 -9 0 -12 -7 -9 -20 3 -11 1
-20 -4 -20 -5 0 -9 11 -9 25 0 20 -5 25 -25 25 -27 0 -29 -7 -25 -74 l2 -39
-46 5 c-36 4 -52 13 -78 42 -19 21 -41 36 -54 36 -22 0 -43 34 -30 47 3 4 6 1
6 -5 0 -7 13 -12 30 -12 23 0 31 5 36 24 3 14 15 27 29 30 13 3 26 14 28 24 4
13 5 11 6 -5 1 -26 11 -28 58 -11 33 12 43 32 21 42 -9 3 -8 8 5 16 13 9 16
20 13 48 -3 20 -3 29 0 20 4 -13 16 -18 40 -18 19 0 34 -4 34 -10 0 -5 10 -10
23 -10 31 0 77 22 77 38 0 6 5 12 10 12 6 0 10 11 10 25 0 18 -7 26 -24 31
-23 5 -23 7 -8 25 9 10 26 19 38 19 22 0 34 23 34 68 0 15 6 22 20 22 17 0 20
7 20 50 0 28 5 50 10 50 6 0 10 -4 10 -10 0 -5 9 -10 20 -10 11 0 20 5 20 10
0 6 7 10 15 10 8 0 15 -6 15 -14 0 -15 45 -30 61 -20 5 3 9 13 8 22 -1 10 0
26 1 37 1 15 3 16 7 5 3 -8 12 -19 19 -23z m4834 3 c0 -27 -3 -30 -30 -30 -16
0 -30 -4 -30 -10 0 -5 16 -10 35 -10 19 0 35 -5 35 -10 0 -6 11 -22 25 -36 27
-27 25 -44 -6 -44 -10 0 -19 -7 -19 -15 0 -8 -7 -15 -15 -15 -8 0 -15 7 -15
15 0 8 -7 15 -15 15 -9 0 -18 -7 -21 -15 -4 -9 -19 -15 -35 -15 -20 0 -29 -5
-29 -17 0 -9 -2 -14 -5 -11 -11 10 7 58 21 58 10 0 14 13 14 45 0 25 5 45 10
45 6 0 10 10 10 23 0 36 14 48 63 56 4 0 7 -12 7 -29z m-350 -30 c0 -16 7 -20
36 -20 19 0 42 -7 50 -15 21 -20 61 -19 74 3 8 12 13 13 16 5 3 -7 14 -13 25
-13 19 0 19 0 -1 -22 -11 -12 -20 -30 -20 -40 0 -10 -7 -18 -15 -18 -10 0 -15
-10 -15 -30 0 -16 -4 -30 -10 -30 -5 0 -10 7 -10 15 0 8 -6 15 -12 15 -12 0
-38 43 -38 63 0 12 -26 37 -39 37 -6 0 -11 -4 -11 -10 0 -5 -7 -10 -15 -10 -8
0 -15 -5 -15 -11 0 -17 -27 -39 -49 -39 -27 0 -34 15 -16 33 8 9 15 29 15 46
0 21 5 31 15 31 8 0 15 7 15 15 0 8 5 15 10 15 6 0 10 -9 10 -20z m1850 -30
c0 -5 26 -10 57 -12 53 -3 56 -4 40 -20 -9 -9 -17 -23 -17 -31 0 -8 -9 -22
-20 -32 -27 -24 -25 -37 10 -65 31 -27 39 -50 16 -50 -8 0 -22 -7 -30 -16 -15
-14 -16 -14 -16 0 0 13 -12 16 -55 16 -56 0 -63 -6 -49 -44 7 -19 34 -21 35
-3 0 10 2 10 6 0 2 -7 17 -13 33 -13 20 0 31 -6 35 -19 3 -11 12 -21 20 -23
31 -7 -3 -26 -56 -32 -45 -6 -60 -4 -72 8 -9 9 -26 16 -40 16 -18 0 -23 5 -23
23 1 19 -3 22 -32 19 -29 -2 -32 0 -20 12 7 7 21 23 30 35 11 13 29 21 48 21
26 0 30 4 30 28 1 35 22 62 49 62 14 0 21 6 21 19 0 20 -18 31 -51 31 -22 0
-23 3 -7 47 9 24 18 33 35 33 13 0 23 -4 23 -10z m1810 -101 c0 -16 26 -39 44
-39 9 0 16 -7 16 -15 0 -8 7 -15 15 -15 8 0 15 -4 15 -10 0 -5 -7 -10 -15 -10
-8 0 -15 -7 -15 -16 0 -12 7 -14 30 -9 23 5 30 3 30 -8 0 -11 -14 -16 -50 -19
-33 -2 -50 1 -50 8 0 14 -23 24 -56 24 -15 0 -24 -6 -24 -15 0 -8 -4 -15 -10
-15 -5 0 -10 6 -10 14 0 8 -6 16 -12 18 -9 3 -8 9 5 22 9 10 17 22 17 27 0 5
6 9 14 9 7 0 16 14 20 30 5 28 36 44 36 19z m-2226 -132 c10 -9 13 -17 7 -17
-6 0 -11 -7 -11 -15 0 -8 5 -15 10 -15 6 0 10 -7 10 -16 0 -9 9 -18 20 -21 27
-7 25 -19 -2 -27 -40 -10 -68 -7 -68 9 0 8 5 15 10 15 6 0 10 7 10 15 0 8 -9
15 -20 15 -11 0 -20 -4 -20 -9 0 -5 -14 -12 -31 -16 l-30 -7 4 -81 c2 -45 2
-72 0 -59 -3 12 -9 22 -14 22 -5 0 -9 14 -9 30 0 28 -2 30 -40 30 -33 0 -40
-3 -40 -20 0 -13 7 -20 20 -20 11 0 20 -4 20 -10 0 -5 -16 -10 -36 -10 -34 0
-36 1 -29 28 3 15 13 31 21 35 8 4 14 19 14 32 0 18 7 25 30 30 17 4 30 11 30
16 0 5 9 9 20 9 13 0 20 7 20 20 0 11 -7 20 -15 20 -9 0 -15 9 -15 21 0 20 4
21 59 17 37 -3 65 -10 75 -21z m1316 -32 c0 -8 -4 -15 -9 -15 -5 0 -11 -13
-14 -30 -4 -18 -13 -30 -22 -30 -9 0 -18 -7 -21 -15 -4 -10 -20 -15 -50 -15
-38 0 -44 -3 -50 -25 -3 -14 -10 -25 -15 -25 -5 0 -9 -7 -9 -15 0 -10 -10 -15
-29 -15 -21 0 -30 -6 -34 -22 -9 -33 -15 -18 -9 25 4 33 7 37 32 37 30 0 40
15 40 62 0 32 20 58 44 58 9 0 16 -7 16 -15 0 -25 57 -19 71 7 25 46 59 65 59
33z m-1056 -90 c-5 -28 -3 -35 10 -35 9 0 16 -4 16 -10 0 -5 8 -10 18 -10 14
0 17 -9 17 -53 0 -49 -1 -52 -27 -55 -16 -2 -28 -8 -28 -13 0 -5 -9 -9 -20 -9
-32 0 -23 18 12 25 44 10 78 45 43 45 -8 0 -15 5 -15 10 0 6 -13 10 -30 10
-19 0 -30 -5 -30 -14 0 -9 -12 -16 -32 -18 -28 -2 -34 -8 -36 -30 -3 -27 -15
-34 -52 -28 -8 1 -18 6 -21 12 -3 5 -25 12 -48 15 -22 3 -41 9 -41 14 0 4 -8
10 -17 13 -10 3 16 4 57 1 52 -3 80 0 93 9 22 17 21 22 -2 47 -19 21 -61 19
-61 -2 0 -5 -9 -9 -20 -9 -24 0 -26 16 -5 36 9 10 39 15 85 17 68 2 71 3 67
25 -3 14 1 22 9 22 8 0 14 5 14 10 0 6 11 10 25 10 24 0 25 -2 19 -35z m-1738
-40 c3 -14 10 -25 14 -25 4 0 11 -7 14 -15 5 -13 0 -15 -28 -10 -22 5 -38 2
-47 -8 -8 -8 -31 -17 -52 -21 -34 -6 -37 -9 -37 -42 0 -34 0 -35 -35 -28 -28
5 -35 3 -35 -10 0 -9 -4 -16 -10 -16 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10
7 10 15 0 8 7 15 15 15 8 0 15 3 16 8 1 4 2 22 4 40 4 45 22 63 39 40 16 -22
36 -23 36 -3 0 8 9 15 20 15 11 0 20 6 20 14 0 14 14 24 37 25 7 1 15 -10 19
-24z m2963 0 c3 -3 0 -9 -7 -14 -19 -11 -28 -71 -10 -71 17 0 48 35 49 58 1
16 2 16 6 0 2 -10 13 -18 24 -18 14 0 19 -7 19 -25 0 -14 4 -25 9 -25 5 0 11
-7 14 -14 4 -10 1 -13 -8 -10 -8 3 -17 15 -21 26 -5 16 -12 19 -37 14 -37 -8
-68 -34 -65 -55 4 -30 -14 -81 -28 -81 -8 0 -14 -9 -14 -20 0 -22 -15 -27 -25
-9 -4 6 2 19 14 30 24 23 28 59 6 59 -8 0 -15 -4 -15 -10 0 -20 -28 -9 -34 13
-9 34 -8 37 14 37 11 0 20 4 20 8 0 5 11 15 25 22 28 15 35 60 10 60 -8 0 -15
-4 -15 -10 0 -5 -4 -10 -10 -10 -14 0 -12 38 2 54 12 13 61 7 77 -9z m761 -50
c0 -18 5 -25 19 -25 12 0 21 9 25 25 7 27 26 34 26 10 0 -8 -4 -15 -10 -15 -5
0 -10 -7 -10 -15 0 -8 -9 -15 -19 -15 -30 0 -51 -11 -51 -26 0 -9 12 -14 35
-14 24 0 35 5 35 15 0 8 5 15 10 15 6 0 10 -13 10 -30 0 -20 -5 -30 -15 -30
-8 0 -15 -4 -15 -10 0 -5 -9 -19 -20 -30 -11 -11 -20 -27 -20 -35 0 -8 -4 -15
-10 -15 -5 0 -10 5 -10 10 0 6 -7 10 -15 10 -9 0 -15 -9 -15 -25 0 -16 -6 -25
-15 -25 -12 0 -15 15 -15 73 0 40 -5 78 -11 84 -16 16 -4 29 30 36 20 4 31 14
35 32 8 32 26 33 26 0z m-196 -10 c-4 -8 -10 -15 -15 -15 -5 0 -9 -11 -9 -25
0 -14 5 -25 10 -25 6 0 10 -7 10 -15 0 -8 -4 -15 -10 -15 -5 0 -11 -8 -11 -17
-1 -17 -2 -17 -6 0 -5 18 -33 24 -33 7 0 -5 -7 -10 -15 -10 -8 0 -15 -4 -15
-10 0 -5 7 -10 15 -10 9 0 15 -9 15 -25 0 -21 5 -25 29 -25 16 0 31 6 34 13 2
6 8 9 12 4 5 -4 3 -13 -3 -19 -7 -7 -12 -27 -12 -45 0 -18 -4 -33 -10 -33 -5
0 -10 -16 -10 -35 0 -19 5 -35 10 -35 15 0 12 -77 -2 -83 -10 -4 -10 -8 -1
-14 6 -4 15 -21 18 -36 4 -15 13 -27 21 -27 18 0 18 -16 0 -23 -13 -5 -46 25
-46 43 0 6 -9 10 -20 10 -19 0 -20 4 -14 49 6 46 5 49 -21 55 -21 5 -25 11
-19 24 4 9 7 37 7 62 -1 25 1 37 4 28 3 -11 14 -18 29 -18 21 0 24 5 24 35 0
24 -5 35 -15 35 -8 0 -15 -4 -15 -10 0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 11 0
19 -28 39 -53 39 -22 0 -25 -4 -24 -35 1 -22 6 -35 14 -35 7 0 13 -4 13 -10 0
-5 -4 -10 -10 -10 -5 0 -10 -7 -10 -15 0 -8 4 -15 10 -15 5 0 2 -9 -7 -20 -30
-34 -41 -24 -48 45 -4 36 -11 65 -16 65 -5 0 -9 7 -9 15 0 8 5 15 10 15 6 0
10 9 10 19 0 20 18 31 51 31 10 0 19 7 19 15 0 8 6 15 14 15 8 0 18 19 25 44
11 43 12 45 53 49 24 2 44 4 45 5 1 1 0 -5 -3 -13z m-1518 -29 c18 -19 18 -36
-1 -36 -8 0 -15 -7 -15 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -7 -15 -15 0 -8 -7
-15 -16 -15 -8 0 -12 5 -9 10 9 15 -4 50 -20 50 -17 0 -25 -23 -25 -76 0 -42
-1 -44 -30 -44 -18 0 -33 -6 -36 -15 -4 -8 -18 -15 -34 -15 -71 0 -118 -27
-75 -44 26 -10 16 -26 -15 -26 -25 0 -30 4 -30 25 0 14 5 25 10 25 6 0 10 5
10 10 0 6 -7 10 -16 10 -11 0 -14 5 -10 16 3 9 6 20 6 25 0 5 7 9 15 9 8 0 15
7 15 15 0 24 48 18 74 -10 13 -14 31 -25 39 -25 9 0 19 11 23 25 3 14 10 25
15 25 5 0 9 11 9 24 0 14 6 31 13 38 7 7 16 21 19 30 11 27 91 37 114 14z
m-1976 -41 c-6 -16 -9 -34 -6 -42 7 -18 36 -17 36 2 0 10 10 15 29 15 18 0 34
8 43 22 8 12 19 18 26 14 18 -11 14 -23 -9 -29 -19 -5 -21 -11 -15 -39 3 -18
11 -34 16 -36 6 -2 10 -10 10 -18 0 -9 -11 -14 -34 -14 -19 0 -36 -6 -39 -12
-2 -8 -8 -9 -13 -4 -5 5 -4 11 4 13 17 7 15 43 -3 43 -8 0 -15 5 -15 10 0 6
-13 10 -30 10 -20 0 -30 -5 -30 -15 0 -8 5 -15 10 -15 6 0 10 -4 10 -10 0 -15
-19 -12 -36 5 -9 8 -11 15 -5 15 6 0 11 9 11 20 0 16 -7 20 -30 20 -24 0 -30
-4 -30 -22 0 -15 -3 -19 -8 -11 -4 7 -19 16 -32 22 -24 10 -24 10 7 10 24 1
33 7 39 24 9 29 22 37 62 37 18 0 32 5 32 12 0 6 2 9 5 6 3 -3 1 -18 -5 -33z
m3166 -36 c1 -23 8 -45 15 -50 10 -6 11 -9 2 -9 -7 0 -13 -7 -13 -15 0 -10 10
-15 30 -15 17 0 30 -4 30 -10 0 -5 -9 -10 -21 -10 -16 0 -19 -4 -13 -17 4 -10
10 -42 12 -71 3 -45 7 -53 28 -58 32 -8 31 -24 -1 -24 -15 0 -25 -5 -24 -12 5
-48 -38 -79 -68 -50 -15 16 -15 17 0 20 22 4 25 102 3 102 -39 0 -76 -14 -77
-29 -1 -14 -2 -13 -6 1 -2 10 -19 22 -36 27 -25 7 -27 9 -9 10 12 1 22 7 22
16 0 8 5 15 10 15 6 0 10 -7 10 -15 0 -10 10 -15 26 -15 23 0 26 3 22 30 -3
20 0 30 9 30 7 0 13 9 13 20 0 11 -4 20 -10 20 -5 0 -10 20 -10 44 0 26 -5 46
-12 49 -7 3 -3 6 10 6 17 1 22 7 22 26 0 17 5 25 18 25 14 0 18 -9 18 -41z
m-783 14 c-7 -2 -13 -13 -13 -23 0 -10 6 -21 13 -24 8 -3 7 -8 -5 -16 -10 -6
-18 -18 -18 -25 0 -8 -7 -15 -15 -15 -8 0 -15 -9 -15 -19 0 -11 -4 -23 -10
-26 -15 -9 -12 -44 5 -61 21 -21 19 -34 -6 -34 -28 0 -46 -21 -54 -64 -6 -29
-4 -36 9 -36 9 0 16 5 16 10 0 6 11 10 25 10 14 0 25 5 25 10 0 6 11 10 25 10
22 0 25 -4 25 -35 0 -19 -4 -35 -10 -35 -5 0 -10 -22 -10 -50 0 -27 5 -50 10
-50 6 0 10 -11 10 -23 0 -15 9 -28 23 -35 17 -8 18 -11 5 -11 -10 -1 -18 -7
-19 -13 0 -10 -2 -10 -6 0 -2 6 -17 12 -31 13 -15 0 -21 3 -14 6 16 6 15 31
-2 49 -17 16 -46 19 -46 4 0 -5 -9 -10 -20 -10 -11 0 -20 7 -20 15 0 8 -4 15
-10 15 -5 0 -10 7 -10 15 0 8 6 15 14 15 8 0 21 -7 30 -15 8 -8 24 -15 35 -15
23 0 26 14 15 69 -6 26 -11 31 -35 31 -18 0 -29 -5 -29 -14 0 -7 -9 -16 -20
-19 -12 -3 -20 0 -20 8 0 7 -7 15 -15 19 -8 3 -15 15 -15 26 0 11 -7 20 -15
20 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -4 -15 -10 0 -16 -22
-12 -35 7 -11 14 -10 20 4 33 10 8 20 23 24 33 8 21 27 23 27 2 0 -8 7 -15 15
-15 9 0 18 -7 21 -15 4 -8 15 -15 25 -15 14 0 19 7 19 25 0 14 5 25 10 25 6 0
10 7 10 15 0 8 -7 15 -15 15 -8 0 -15 -7 -15 -15 0 -8 -6 -15 -14 -15 -19 0
-33 23 -18 28 19 6 14 22 -7 22 -11 0 -21 9 -24 22 -8 29 -4 32 38 30 29 -1
35 2 35 18 0 15 7 20 25 20 15 0 25 6 25 14 0 18 -33 30 -60 21 -14 -5 -20 -2
-20 9 0 10 9 16 23 16 38 0 67 12 67 26 0 8 7 14 15 14 8 0 15 6 15 14 0 14
19 23 50 24 10 0 11 -1 3 -5z m-781 -67 c3 -25 -1 -38 -14 -48 -21 -14 -24
-48 -5 -49 10 0 10 -2 0 -6 -7 -2 -13 -16 -13 -29 0 -18 6 -24 23 -25 14 -1 7
-5 -18 -11 -22 -6 -43 -16 -47 -22 -5 -7 -12 -4 -21 8 -9 12 -20 17 -31 13
-28 -11 -74 4 -80 25 -4 17 -10 19 -33 13 -15 -3 -31 -12 -35 -18 -4 -6 -11
-8 -15 -3 -4 5 -2 11 5 13 14 5 16 33 2 33 -5 0 -10 7 -10 15 0 8 -4 15 -10
15 -5 0 -10 -5 -10 -11 0 -16 -26 -39 -44 -39 -9 0 -16 -7 -16 -15 0 -8 -4
-15 -10 -15 -5 0 -10 -15 -10 -34 0 -36 15 -46 72 -46 15 0 30 -4 33 -10 4 -6
-7 -10 -24 -10 -26 0 -31 -4 -31 -25 0 -16 -6 -25 -15 -25 -8 0 -15 -4 -15 -9
0 -5 -9 -11 -20 -14 -13 -3 -20 0 -20 9 0 8 -6 14 -12 15 -10 0 -10 2 0 6 14
5 16 30 3 35 -20 7 -16 68 4 68 10 0 15 10 15 29 0 45 10 61 36 61 22 0 24 4
24 50 l0 50 35 0 c19 0 35 -4 35 -10 0 -5 9 -10 20 -10 11 0 20 7 20 15 0 23
22 18 49 -11 36 -38 19 -59 -42 -55 -37 2 -47 -1 -47 -13 0 -12 10 -16 40 -16
22 0 40 -4 40 -10 0 -5 11 -10 24 -10 19 0 26 7 31 30 5 24 13 31 41 36 19 3
34 10 34 15 0 5 7 9 15 9 10 0 15 10 15 30 0 25 -4 30 -23 30 -13 0 -28 -5
-35 -12 -13 -13 -27 -5 -17 11 3 6 28 11 54 11 48 0 49 -1 53 -34z m1019 -8
c9 -9 29 -20 43 -24 21 -5 26 -3 26 12 0 15 3 14 13 -6 7 -14 25 -31 40 -38
31 -15 36 -42 7 -42 -11 0 -20 -7 -20 -15 0 -8 9 -15 20 -15 11 0 20 -6 20
-13 0 -10 -14 -13 -50 -11 -47 2 -80 20 -80 43 0 5 -10 11 -22 13 -20 3 -21 4
-5 20 9 9 17 26 17 39 0 19 -4 21 -30 16 -22 -5 -30 -3 -30 8 0 8 5 15 10 15
6 0 10 10 11 23 0 14 2 17 6 7 3 -8 14 -23 24 -32z m-1787 16 c10 -25 7 -34
-9 -34 -8 0 -15 5 -15 10 0 6 -11 10 -25 10 -14 0 -25 -4 -25 -10 0 -5 -11
-10 -25 -10 -30 0 -36 -36 -7 -43 13 -4 11 -5 -5 -6 -29 -1 -31 -31 -3 -31 11
0 20 -4 20 -9 0 -10 -49 -21 -96 -21 l-34 0 0 50 c0 28 4 50 9 50 5 0 11 9 14
20 4 16 14 20 51 20 25 0 46 5 46 10 0 6 22 10 49 10 35 0 51 -4 55 -16z m196
-69 c0 -19 -4 -35 -8 -35 -14 0 -52 -36 -52 -49 0 -6 7 -11 16 -11 8 0 12 -4
9 -10 -3 -5 -17 -10 -30 -10 -26 0 -38 -21 -29 -56 5 -18 13 -24 35 -24 19 0
29 5 29 15 0 8 9 15 20 15 11 0 20 7 20 15 0 8 7 15 15 15 8 0 15 5 15 10 0
14 28 12 33 -2 7 -21 -4 -38 -24 -38 -12 0 -19 -7 -19 -19 0 -15 -8 -21 -32
-23 -28 -3 -34 -8 -36 -31 -3 -29 -29 -67 -45 -67 -5 0 -1 7 7 16 9 8 16 29
16 45 0 26 -3 29 -30 29 -27 0 -30 3 -30 33 -1 34 -19 67 -39 67 -6 0 -11 -5
-11 -11 0 -6 -7 -17 -15 -25 -8 -9 -15 -11 -15 -5 0 6 -9 11 -20 11 -13 0 -20
7 -20 19 0 15 10 21 40 26 22 4 40 11 40 16 0 5 14 9 30 9 17 0 30 -4 30 -10
0 -5 6 -10 14 -10 8 0 16 16 19 42 3 24 11 44 16 46 6 2 11 10 11 17 0 12 13
22 33 24 4 1 7 -15 7 -34z m1110 -13 c19 3 26 0 23 -8 -3 -7 -17 -14 -31 -16
-21 -2 -28 -10 -32 -33 -4 -23 -12 -31 -35 -35 -49 -11 -75 -32 -75 -62 0 -32
5 -34 28 -11 16 16 17 15 21 -3 2 -10 9 -23 17 -27 8 -4 13 -20 13 -35 -1 -17
-3 -20 -6 -9 -3 13 -15 17 -49 17 -44 0 -44 0 -44 -36 0 -27 -5 -37 -20 -41
-11 -3 -20 -12 -20 -20 0 -10 8 -12 34 -7 32 5 34 4 29 -15 -3 -14 -13 -21
-29 -21 -13 0 -24 -3 -24 -8 0 -4 -10 -12 -22 -17 -23 -10 -25 -17 -27 -102
-1 -28 -5 -33 -26 -33 -16 0 -25 -6 -25 -15 0 -8 7 -15 15 -15 8 0 15 -7 15
-15 0 -8 7 -15 15 -15 10 0 15 11 16 33 1 17 3 25 6 16 4 -16 68 -34 85 -23
14 9 9 34 -7 34 -10 0 -15 10 -15 30 0 40 15 38 27 -2 8 -29 13 -33 44 -31 19
0 26 -1 17 -4 -25 -6 -34 -44 -14 -58 20 -15 20 -21 -3 -44 -23 -23 -81 -33
-81 -14 0 7 -7 13 -16 13 -13 0 -15 -7 -10 -43 5 -35 3 -45 -15 -60 -14 -13
-20 -26 -16 -43 2 -13 0 -24 -4 -24 -5 0 -9 -7 -9 -15 0 -8 5 -15 10 -15 6 0
10 -16 10 -35 0 -24 5 -35 15 -35 8 0 15 -4 15 -10 0 -5 -5 -10 -11 -10 -20 0
-39 29 -39 59 0 21 -5 31 -15 31 -8 0 -15 7 -15 15 0 8 -7 15 -16 15 -12 0
-15 -6 -10 -24 8 -31 -2 -56 -21 -56 -12 0 -14 8 -9 39 3 22 2 51 -4 65 -10
28 -40 36 -40 11 0 -27 -18 -15 -22 15 -3 23 -1 30 12 30 8 0 22 10 30 21 8
12 25 33 38 47 26 29 28 45 7 66 -8 8 -15 20 -15 26 0 8 -10 10 -25 8 -19 -4
-25 -1 -25 13 0 11 -5 19 -11 19 -17 0 -39 27 -39 49 0 17 -6 21 -35 21 -22 0
-38 -6 -41 -15 -4 -8 -12 -15 -19 -15 -7 0 -23 -11 -36 -25 -13 -13 -30 -25
-36 -25 -8 0 -13 -14 -13 -35 0 -32 2 -35 30 -35 17 0 30 -3 30 -7 0 -5 3 -18
6 -30 5 -17 2 -23 -10 -23 -9 0 -16 -5 -16 -11 0 -6 -7 -4 -15 5 -8 8 -15 19
-15 25 0 6 -11 11 -25 11 -16 0 -25 6 -25 15 0 19 -60 21 -78 3 -9 -9 -12 -9
-12 0 0 7 7 15 15 18 17 7 21 44 5 44 -5 0 -10 11 -10 24 0 30 -14 35 -73 29
-41 -5 -47 -8 -47 -29 0 -13 -5 -24 -11 -24 -14 0 -39 26 -39 40 0 6 -10 15
-22 20 -18 7 -12 9 30 9 28 1 52 6 52 11 0 6 7 10 15 10 8 0 15 -4 15 -10 0
-21 28 -9 34 15 6 24 26 36 26 15 0 -5 12 -11 28 -11 16 -1 20 -3 10 -6 -10
-2 -18 -14 -18 -25 0 -17 4 -19 23 -13 12 4 45 10 74 12 31 3 54 10 57 18 7
19 -5 85 -15 85 -5 0 -9 14 -9 31 0 26 -3 31 -20 26 -16 -4 -20 0 -20 19 0 15
-6 24 -15 24 -20 0 -19 6 5 30 11 11 33 20 50 20 20 0 30 5 30 15 0 11 12 15
45 15 38 0 45 3 45 20 0 11 -7 20 -15 20 -10 0 -15 10 -14 33 1 17 3 24 6 15
2 -10 11 -18 19 -18 9 0 14 -11 14 -29 0 -21 6 -32 20 -36 11 -3 20 -11 20
-16 0 -5 7 -9 16 -9 11 0 15 6 11 20 -3 13 0 20 9 20 8 0 14 6 14 13 0 7 16
13 40 14 28 1 40 -3 40 -13 0 -8 5 -14 10 -14 6 0 10 -9 10 -19 0 -33 11 -51
31 -51 10 0 19 7 19 15 0 8 5 15 10 15 6 0 10 6 10 14 0 10 7 12 22 8 17 -4
20 -2 15 12 -4 10 -7 22 -7 27 0 14 -27 10 -44 -7 -15 -14 -16 -14 -16 0 0 9
7 16 15 16 8 0 15 7 15 15 0 8 -9 15 -20 15 -16 0 -20 7 -20 35 0 19 -4 35
-10 35 -5 0 -10 5 -10 10 0 6 16 10 35 10 24 0 35 -5 35 -15 0 -10 10 -15 31
-15 30 0 31 1 25 35 -7 38 3 45 28 20 8 -8 19 -15 24 -15 6 0 12 7 16 15 3 8
12 15 21 15 8 0 16 10 16 23 2 17 3 15 6 -7 4 -24 8 -28 33 -24z m790 -56 c0
-14 6 -31 14 -38 35 -36 31 -104 -7 -110 -18 -2 -23 -10 -25 -40 -2 -21 -8
-38 -13 -38 -5 0 -7 11 -3 25 5 19 2 25 -10 25 -9 0 -16 7 -16 15 0 7 -8 19
-17 25 -15 8 -13 9 10 4 21 -5 27 -2 27 10 0 10 -11 19 -30 23 -31 6 -42 23
-15 23 22 0 19 40 -2 41 -17 1 -17 2 0 6 9 2 17 10 17 17 0 9 9 12 25 8 18 -3
25 0 25 12 0 9 5 16 10 16 6 0 10 -11 10 -24z m-2500 -16 c0 -5 6 -10 14 -10
21 0 30 -33 12 -46 -9 -6 -17 -22 -17 -35 -1 -17 -3 -19 -6 -6 -2 9 -11 17
-20 17 -12 0 -14 -8 -10 -34 7 -42 1 -44 -80 -38 -46 3 -63 8 -63 19 0 25 -27
43 -64 43 -30 0 -34 3 -29 17 12 31 51 63 75 62 13 0 17 -3 11 -6 -7 -2 -13
-14 -13 -25 0 -18 5 -20 39 -15 37 5 81 36 81 57 0 6 16 10 35 10 19 0 35 -4
35 -10z m-690 -140 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 7 10 15 10 9 0 15
-9 15 -25 0 -13 8 -28 17 -34 10 -5 14 -13 9 -18 -5 -4 -11 -2 -13 5 -5 14
-43 16 -43 2 0 -5 -9 -10 -20 -10 -11 0 -20 7 -20 15 0 21 -23 19 -43 -5 -10
-12 -22 -18 -27 -15 -20 12 -47 -6 -60 -40 -8 -19 -16 -35 -20 -35 -12 0 -40
41 -40 59 0 11 -8 27 -17 37 -16 16 -16 16 7 9 14 -4 27 -12 28 -16 7 -18 45
-8 68 16 13 14 32 25 43 25 11 0 26 7 33 15 15 16 38 20 38 5z m3274 -5 c-5
-11 1 -15 20 -15 14 0 26 -4 26 -10 0 -5 9 -10 21 -10 12 0 23 -8 26 -19 3
-11 16 -22 29 -25 23 -6 34 -26 14 -26 -6 0 -10 -28 -10 -65 0 -49 -3 -65 -14
-65 -9 0 -16 17 -20 45 l-7 45 -45 0 c-28 0 -44 -4 -44 -12 0 -7 -11 -13 -25
-13 -14 0 -25 6 -25 13 0 6 -12 12 -27 12 -16 0 -23 4 -17 8 7 4 14 18 17 32
l4 25 2 -26 c1 -20 8 -29 26 -33 35 -9 45 -1 45 35 0 19 5 29 15 29 8 0 15 9
15 20 0 13 -7 20 -20 20 -15 0 -20 7 -20 25 0 14 4 25 10 25 5 0 7 -7 4 -15z
m-3084 -55 c0 -5 18 -10 40 -10 33 0 40 -3 40 -19 0 -11 -4 -23 -10 -26 -5 -3
-10 -26 -10 -51 0 -24 5 -44 10 -44 6 0 10 -7 10 -15 0 -19 17 -19 36 -1 17
18 18 33 2 39 -8 3 -5 6 6 6 12 1 17 7 14 19 -4 15 1 17 44 14 26 -2 48 -8 48
-13 0 -12 -38 -49 -50 -49 -5 0 -10 -7 -10 -15 0 -8 -14 -17 -35 -21 -19 -3
-35 -12 -35 -19 0 -14 -57 -31 -67 -21 -4 3 3 6 15 6 18 0 22 6 22 29 0 25 -5
31 -30 36 -33 7 -39 28 -15 51 20 20 20 74 0 74 -19 0 -55 -31 -55 -47 0 -7
-7 -13 -15 -13 -8 0 -15 -6 -16 -12 0 -10 -2 -10 -6 0 -2 6 -16 12 -30 12
l-26 0 27 25 c14 13 26 31 26 40 0 8 7 15 15 15 8 0 15 5 15 10 0 6 9 10 20
10 11 0 20 -4 20 -10z m-3360 -75 c0 -8 5 -15 10 -15 6 0 10 -12 10 -27 0 -16
7 -36 14 -45 14 -20 36 -24 36 -8 0 6 5 10 10 10 6 0 10 -14 10 -30 l0 -30
-85 6 c-83 7 -85 6 -85 -15 0 -12 4 -30 9 -39 15 -28 -78 -23 -107 6 -12 12
-22 26 -22 31 0 13 25 20 48 15 10 -3 26 2 35 10 8 9 20 16 25 16 5 0 13 29
17 65 l7 65 34 0 c24 0 34 -5 34 -15z m-1610 -62 c0 -7 -4 -13 -10 -13 -5 0
-10 -7 -10 -15 0 -7 -6 -18 -13 -22 -20 -13 -41 -83 -28 -96 6 -6 11 -24 11
-39 0 -23 4 -28 25 -28 14 0 25 4 25 9 0 5 9 11 20 14 13 3 20 0 20 -8 0 -19
-50 -28 -115 -20 l-55 7 0 -31 c0 -24 4 -31 20 -31 11 0 20 -4 20 -10 0 -5 7
-10 15 -10 18 0 20 -16 3 -26 -6 -4 -16 0 -23 9 -15 20 -64 19 -85 -2 -13 -13
-15 -12 -18 12 -2 15 -8 27 -13 27 -5 0 -9 5 -9 10 0 6 7 10 15 10 11 0 15 11
14 43 -2 33 3 49 19 65 30 30 52 28 52 -3 0 -20 5 -25 25 -25 28 0 32 14 10
36 -8 9 -15 24 -15 34 0 11 -10 22 -22 26 -46 14 -44 49 3 59 16 4 29 12 29
18 0 8 17 12 45 12 29 0 45 -4 45 -12z m376 -43 c13 0 24 5 24 10 0 6 9 10 20
10 11 0 20 -7 20 -15 0 -10 10 -15 30 -15 17 0 30 -4 30 -10 0 -5 -13 -10 -30
-10 -32 0 -33 -2 -24 -49 6 -26 11 -31 35 -31 16 0 29 -4 29 -10 0 -5 7 -10
16 -10 11 0 15 8 14 28 -3 44 -1 52 15 52 8 0 15 -3 16 -7 0 -5 2 -21 3 -37 1
-16 7 -31 14 -34 15 -5 16 -22 1 -22 -19 0 -40 -29 -38 -53 4 -40 -11 -75 -30
-70 -11 3 -30 -8 -49 -26 -33 -33 -62 -41 -62 -16 0 8 5 15 10 15 16 0 11 47
-6 54 -9 3 -31 6 -49 6 -39 0 -66 22 -64 53 2 21 2 21 6 -1 4 -20 10 -23 46
-21 62 2 87 10 87 25 0 9 -11 14 -30 14 -29 0 -44 24 -20 32 6 2 12 17 13 33
2 25 -2 30 -23 33 -33 4 -52 34 -43 70 3 15 8 21 11 14 2 -6 15 -12 28 -12z
m-1182 -15 c16 -17 106 -21 106 -5 0 6 7 10 15 10 8 0 15 -4 15 -10 0 -5 12
-13 26 -16 23 -6 25 -10 20 -37 -3 -16 -6 -36 -6 -43 0 -8 -4 -14 -10 -14 -5
0 -10 7 -10 15 0 9 -9 15 -25 15 -18 0 -25 -5 -25 -20 0 -14 7 -20 23 -20 20
0 23 -4 19 -30 -3 -23 0 -30 12 -30 19 0 22 -30 4 -31 -7 0 -68 -2 -136 -2
l-122 -2 0 33 c0 17 -5 32 -11 32 -16 0 -39 26 -39 45 0 21 24 45 44 45 22 0
46 28 46 56 0 28 29 33 54 9z m8136 -87 c0 -40 12 -68 29 -69 14 -1 13 -2 -1
-6 -11 -3 -18 -14 -18 -28 0 -14 -9 -29 -20 -35 -11 -6 -20 -17 -20 -25 0 -10
-10 -15 -30 -15 -25 0 -30 -4 -30 -25 0 -21 5 -25 31 -25 33 0 74 -30 51 -38
-6 -2 -12 -10 -12 -18 0 -8 -7 -14 -15 -14 -8 0 -15 5 -15 10 0 6 -7 10 -15
10 -9 0 -18 7 -21 15 -4 8 -15 15 -25 15 -28 0 -27 66 1 73 11 3 20 13 20 22
0 13 -6 15 -30 10 -19 -4 -30 -3 -30 4 0 6 5 11 11 11 6 0 18 7 26 16 11 11
26 14 50 9 29 -5 33 -3 33 15 0 11 -7 20 -15 20 -8 0 -15 5 -15 10 0 6 7 10
15 10 9 0 15 9 15 25 0 16 -6 25 -15 25 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0
15 5 15 11 0 6 7 4 15 -5 8 -8 15 -25 15 -38z m-2260 -28 c0 -13 7 -20 20 -20
13 0 20 7 20 20 0 21 27 28 46 12 5 -5 28 -14 52 -21 23 -6 42 -15 42 -20 0
-13 -40 -51 -55 -51 -8 0 -15 -4 -15 -10 0 -5 -9 -10 -20 -10 -11 0 -20 7 -20
15 0 10 -10 15 -30 15 -16 0 -30 5 -30 10 0 6 -16 10 -35 10 -19 0 -35 -4 -35
-10 0 -5 -7 -10 -15 -10 -8 0 -15 -9 -15 -19 0 -10 7 -21 15 -25 8 -3 15 -12
15 -20 0 -8 -7 -21 -15 -30 -23 -23 -18 -35 20 -42 38 -7 52 -19 35 -29 -15
-9 -12 -35 4 -35 8 0 17 6 19 13 3 7 6 5 6 -5 1 -27 51 -25 51 2 0 12 -7 20
-16 20 -13 0 -15 7 -10 31 3 17 6 44 6 60 0 28 2 29 51 29 48 0 50 -1 43 -22
-3 -13 -11 -24 -15 -26 -12 -5 -11 -32 1 -32 6 0 10 -4 10 -10 0 -5 -7 -10
-15 -10 -8 0 -15 9 -15 20 0 11 -7 20 -15 20 -8 0 -15 -9 -15 -20 0 -11 4 -20
10 -20 5 0 3 -21 -5 -47 -23 -74 -20 -83 19 -83 21 0 36 -6 40 -15 3 -9 0 -15
-9 -15 -8 0 -15 -7 -15 -15 0 -8 5 -15 10 -15 16 0 12 -27 -5 -33 -8 -4 -37
-3 -65 0 -46 5 -50 4 -50 -16 0 -11 6 -21 14 -21 8 0 16 -6 19 -13 6 -16 133
-31 172 -21 22 5 25 12 25 49 0 39 -3 44 -30 54 -34 11 -40 31 -10 31 11 0 20
7 20 15 0 8 -7 15 -15 15 -8 0 -15 9 -15 19 0 11 -3 26 -6 35 -4 10 -1 16 8
16 17 0 45 32 50 56 2 11 11 20 20 22 12 2 15 -2 11 -17 -4 -17 -1 -21 21 -21
21 0 26 -5 26 -24 0 -37 -10 -56 -31 -56 -10 0 -19 -7 -19 -15 0 -8 -4 -15
-10 -15 -15 0 -12 -17 7 -40 11 -12 30 -20 49 -20 27 0 31 -3 27 -20 -6 -24
-53 -28 -53 -5 0 8 -4 15 -10 15 -5 0 -10 -7 -10 -15 0 -8 -4 -15 -10 -15 -14
0 -12 -27 3 -34 10 -5 10 -7 0 -12 -18 -8 -16 -24 3 -24 9 0 18 9 21 20 6 24
19 26 27 4 4 -9 0 -22 -8 -28 -7 -6 -18 -20 -24 -31 -6 -11 -12 -14 -12 -7 0
24 -30 12 -30 -12 0 -13 -7 -26 -15 -30 -8 -3 -15 -12 -15 -21 0 -9 7 -18 15
-21 8 -4 15 -13 15 -21 0 -12 -8 -14 -35 -10 -23 4 -40 1 -47 -7 -17 -18 -35
1 -43 48 -8 41 -35 50 -35 11 0 -18 -5 -25 -20 -25 -21 0 -24 -8 -14 -34 4
-10 18 -16 35 -16 28 0 37 -9 22 -24 -5 -4 -13 -24 -18 -44 -10 -36 -35 -56
-35 -28 0 9 -7 16 -15 16 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -15 -10 -8 0 -15 7
-15 15 0 8 5 15 11 15 24 0 53 49 43 74 -3 8 -16 17 -29 20 -35 9 -44 66 -9
66 8 0 14 7 14 15 0 8 5 15 10 15 6 0 10 19 10 41 l0 42 -45 -5 c-34 -4 -45
-2 -45 8 0 8 5 14 10 14 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0
18 17 20 23 3 2 -7 8 -10 12 -6 12 11 -4 58 -20 58 -31 0 -14 28 20 34 26 5
35 12 35 26 0 13 -7 20 -19 20 -30 0 -51 -11 -51 -26 0 -9 -11 -14 -30 -14
-23 0 -30 4 -30 20 0 22 -21 26 -49 8 -15 -9 -22 -8 -37 7 -13 13 -15 20 -6
23 6 2 12 12 12 23 0 16 7 19 40 19 29 0 40 -4 40 -15 0 -9 9 -15 25 -15 16 0
25 6 25 15 0 8 -4 15 -9 15 -5 0 -11 10 -14 21 -5 18 -12 21 -41 17 -34 -4
-36 -2 -36 24 0 15 -5 28 -11 28 -15 0 -39 -26 -39 -44 0 -8 -4 -18 -10 -21
-5 -3 -10 -1 -10 4 0 6 -13 11 -30 11 -16 0 -30 -4 -30 -10 0 -5 7 -10 15 -10
8 0 15 -4 15 -10 0 -6 -28 -10 -65 -10 -37 0 -65 4 -65 10 0 6 16 10 36 10 31
0 35 3 29 20 -4 11 -2 20 4 20 6 0 11 6 11 14 0 8 11 17 25 20 14 4 25 11 25
16 0 6 9 10 20 10 37 0 19 35 -24 46 -26 6 -29 5 -23 -15 3 -14 1 -21 -9 -21
-8 0 -14 3 -14 8 0 4 -3 17 -6 30 -5 16 -2 22 10 22 9 0 19 7 22 15 4 8 10 15
15 15 5 0 9 -7 9 -15 0 -8 6 -15 14 -15 8 0 20 -14 28 -30 7 -17 23 -33 35
-36 12 -3 24 -12 27 -20 8 -21 36 -17 36 6 0 11 7 20 15 20 10 0 15 10 15 30
0 25 4 30 35 36 30 5 35 10 35 35 0 19 5 29 15 29 8 0 15 -9 15 -20z m866 -9
c-10 -16 6 -71 20 -71 8 0 14 -4 14 -10 0 -5 -4 -10 -10 -10 -18 0 -11 -38 9
-45 21 -6 52 3 64 19 5 8 9 7 15 -1 4 -6 20 -15 35 -18 29 -8 37 -25 12 -25
-8 0 -15 -6 -15 -14 0 -8 7 -16 15 -20 8 -3 15 -15 15 -26 0 -11 5 -20 10 -20
20 0 9 -20 -15 -26 -13 -3 -27 -15 -30 -25 -7 -20 -35 -27 -35 -9 0 6 6 10 14
10 8 0 16 16 19 43 8 58 0 87 -25 87 -15 0 -19 -5 -15 -20 5 -18 0 -20 -37
-20 -41 0 -43 -2 -54 -37 -6 -21 -8 -50 -5 -65 4 -21 1 -28 -11 -28 -9 0 -16
5 -16 10 0 6 -7 10 -15 10 -9 0 -17 -14 -21 -39 -4 -27 -12 -41 -25 -44 -16
-4 -19 0 -19 29 0 33 -1 34 -40 34 -31 0 -40 -4 -41 -17 0 -11 -3 -13 -6 -5
-2 6 -13 12 -24 12 -10 0 -19 6 -19 14 0 16 30 26 79 26 31 0 33 2 26 27 -8
32 -21 43 -52 43 -14 0 -23 -6 -23 -15 0 -8 -7 -15 -15 -15 -9 0 -15 9 -15 21
0 12 -8 28 -17 35 -16 12 -15 13 14 14 17 0 33 6 36 13 4 10 7 10 18 0 18 -17
29 -16 29 2 0 8 5 15 10 15 6 0 10 -7 10 -15 0 -8 -4 -15 -10 -15 -5 0 -10 -9
-10 -20 0 -11 6 -20 14 -20 8 0 22 -7 30 -16 15 -14 17 -14 32 0 8 9 28 16 43
16 32 0 55 33 49 71 -2 20 -8 24 -36 21 -22 -1 -32 2 -32 12 0 8 -7 22 -16 30
-14 14 -13 18 9 38 13 13 27 33 31 46 4 12 14 22 22 22 8 0 13 -4 10 -9z
m-1756 -56 c0 -8 4 -15 9 -15 5 0 11 -14 15 -31 7 -36 25 -56 54 -61 15 -2 19
-7 13 -16 -11 -18 -120 -22 -136 -5 -8 7 -18 13 -24 13 -6 0 -11 9 -11 20 0
11 -4 20 -10 20 -14 0 -12 58 3 75 17 20 87 20 87 0z m2367 -52 c4 -10 11 -21
17 -25 7 -5 4 -8 -7 -8 -14 0 -18 -6 -15 -27 2 -23 8 -28 33 -29 56 -1 65 -4
65 -19 0 -10 -10 -15 -30 -15 -21 0 -30 -5 -30 -16 0 -9 -9 -18 -20 -21 -11
-3 -20 -9 -20 -14 0 -5 -7 -9 -15 -9 -9 0 -15 -9 -15 -25 0 -18 5 -25 20 -25
11 0 20 -6 19 -12 -1 -29 -16 -43 -46 -46 -31 -2 -33 0 -33 28 0 18 6 33 15
36 8 4 15 15 15 25 0 22 -45 27 -53 7 -4 -9 -6 -10 -6 -1 -1 6 6 18 14 27 8 8
15 26 15 40 0 16 -6 26 -15 26 -8 0 -15 -7 -15 -16 0 -13 -6 -15 -27 -9 -41
10 -41 8 5 60 6 7 12 20 13 27 0 10 2 10 6 1 2 -7 11 -13 18 -13 15 0 30 35
21 49 -6 9 13 18 47 20 10 0 21 -7 24 -16z m2091 -180 c6 -15 12 -32 12 -39 0
-7 6 -14 13 -16 6 -3 13 -19 13 -36 3 -58 4 -62 19 -62 8 0 15 -5 15 -11 0
-17 23 -35 48 -36 20 -2 23 2 18 21 -5 20 -1 24 24 30 21 4 30 11 30 26 0 11
-4 20 -10 20 -5 0 -10 6 -10 14 0 8 -7 22 -16 30 -14 15 -14 16 0 16 9 0 16 8
16 20 0 11 6 20 14 20 8 0 16 6 19 13 2 6 2 1 0 -12 -4 -21 -1 -23 32 -19 33
4 35 2 35 -24 0 -15 6 -28 13 -29 10 0 10 -2 0 -6 -7 -2 -13 -11 -13 -18 0 -7
-10 -15 -21 -18 -19 -5 -21 -11 -15 -46 5 -32 3 -41 -8 -41 -8 0 -16 6 -18 12
-3 9 -9 8 -22 -4 -16 -17 -16 -19 3 -40 26 -27 26 -38 1 -38 -11 0 -20 6 -20
14 0 27 -31 48 -62 42 -35 -7 -78 9 -78 29 0 8 -4 15 -10 15 -11 0 -21 33 -24
81 -1 18 -8 35 -14 37 -16 5 -15 29 1 36 10 5 10 7 0 12 -14 6 -18 34 -5 34 4
0 13 -12 20 -27z m-10059 -15 c15 -9 28 -9 60 2 29 9 50 10 73 3 29 -8 33 -12
30 -41 -3 -24 1 -32 13 -32 9 0 23 -6 31 -14 16 -17 19 -56 4 -56 -5 0 -10
-12 -10 -26 0 -23 -2 -24 -15 -10 -8 8 -15 22 -15 30 0 24 -37 19 -43 -5 -5
-19 -11 -21 -44 -16 -21 4 -48 7 -60 7 -19 0 -23 6 -23 30 0 20 -5 30 -15 30
-8 0 -15 9 -15 20 0 13 -7 20 -20 20 -11 0 -20 5 -20 10 0 6 6 10 14 10 8 0
17 11 20 25 7 29 9 29 35 13z m791 -32 c0 -19 -4 -36 -10 -38 -5 -1 -12 -18
-16 -35 -6 -32 -5 -33 25 -33 28 0 43 -14 22 -22 -4 -1 -12 -13 -15 -25 -6
-19 -4 -23 13 -23 12 0 21 -5 21 -11 0 -12 25 -39 35 -39 4 0 5 13 3 29 -3 23
0 31 15 35 17 4 19 1 13 -32 -3 -20 -10 -38 -16 -40 -5 -2 -10 -11 -10 -19 0
-11 7 -14 25 -9 20 5 25 3 25 -14 0 -16 -7 -20 -35 -20 -23 0 -35 -5 -35 -14
0 -8 -7 -16 -15 -20 -8 -3 -15 -12 -15 -20 0 -9 -14 -18 -35 -22 -20 -3 -35
-12 -35 -20 0 -8 -7 -14 -16 -14 -25 0 -13 44 14 48 19 3 22 9 22 47 0 29 5
45 15 49 9 3 15 18 15 36 0 20 -5 30 -15 30 -8 0 -15 7 -15 16 0 22 -25 44
-49 44 -25 0 -28 25 -6 46 8 9 15 19 15 24 0 5 -7 15 -15 24 -17 17 -20 36 -5
36 6 0 10 9 10 20 0 16 7 20 35 20 33 0 35 -2 35 -34z m-130 -15 c8 -14 -9
-71 -25 -83 -5 -4 -22 -8 -37 -8 -21 0 -29 -6 -34 -25 -8 -32 -34 -33 -34 -1
0 15 7 26 20 29 11 3 20 12 20 21 0 12 -10 16 -40 16 -37 0 -40 2 -40 28 0 16
2 31 5 34 12 11 157 1 165 -11z m1758 -14 c12 -3 10 -5 -5 -6 -13 0 -23 -5
-23 -10 0 -5 -13 -12 -30 -16 -25 -5 -30 -11 -30 -36 0 -22 5 -29 20 -29 11 0
20 7 20 15 0 8 5 15 10 15 6 0 10 -9 10 -20 0 -11 -7 -20 -15 -20 -8 0 -15 -4
-15 -10 0 -5 -9 -10 -20 -10 -22 0 -23 -1 -14 -24 3 -9 12 -16 19 -16 19 0 27
-17 20 -40 -7 -23 -45 -27 -45 -5 0 8 -4 15 -9 15 -20 0 -41 -13 -41 -25 0
-12 -21 -25 -39 -25 -5 0 -6 11 -3 25 2 14 8 25 13 25 5 0 9 7 9 15 0 8 -4 15
-10 15 -5 0 -10 9 -10 20 0 11 4 20 8 20 10 0 32 68 32 99 0 11 5 21 10 21 6
0 10 7 10 15 0 8 7 15 15 15 8 0 15 -4 15 -10 0 -5 -4 -10 -10 -10 -5 0 -10
-9 -10 -20 0 -24 22 -26 44 -5 8 8 22 15 30 15 10 0 16 9 17 23 1 15 3 17 6 5
2 -9 12 -19 21 -21z m5633 -19 c0 -7 1 -29 2 -48 2 -28 6 -36 25 -38 12 -2 22
-8 22 -13 0 -5 10 -9 21 -9 12 0 29 -8 38 -18 9 -11 23 -22 31 -25 8 -4 -18
-4 -57 -1 -62 4 -73 3 -73 -10 0 -9 9 -16 20 -16 13 0 20 -7 20 -19 0 -11 6
-22 13 -25 6 -3 0 -3 -15 0 -26 6 -28 4 -28 -25 0 -17 -5 -31 -11 -31 -6 0 -9
-9 -6 -20 7 -25 -24 -27 -48 -3 -9 9 -29 16 -44 16 -26 0 -27 2 -12 19 8 10
21 18 27 18 6 0 14 14 18 30 4 24 11 30 31 30 16 0 25 6 25 15 0 8 -4 15 -10
15 -5 0 -10 9 -10 20 0 11 -7 20 -15 20 -12 0 -15 14 -15 65 0 63 1 65 25 65
14 0 25 -6 26 -12z m-7659 -48 c-1 -14 -7 -26 -12 -28 -6 -2 -10 -12 -10 -23
0 -14 7 -19 30 -19 23 0 30 4 30 20 0 27 13 25 25 -2 7 -16 20 -24 43 -26 28
-3 32 -7 32 -33 0 -26 3 -29 33 -29 l32 0 0 -61 c0 -55 -3 -63 -23 -70 -30
-11 -29 -52 3 -77 33 -26 32 -32 -10 -32 -31 0 -35 -3 -35 -25 0 -16 6 -25 15
-25 8 0 15 -9 15 -20 0 -16 -7 -20 -32 -20 -18 -1 -43 -9 -55 -19 -27 -23 -30
-68 -7 -86 15 -10 15 -13 0 -27 -9 -9 -16 -20 -16 -23 0 -4 -16 -22 -35 -41
-28 -27 -43 -34 -75 -34 -22 0 -40 5 -40 10 0 6 -9 10 -20 10 -11 0 -20 5 -20
10 0 6 7 10 15 10 8 0 15 5 15 10 0 6 7 10 15 10 10 0 15 10 15 30 0 23 5 30
19 30 11 0 23 9 26 20 3 11 13 20 21 20 12 0 13 8 8 35 -6 34 -5 35 25 35 28
0 31 3 31 30 0 23 5 31 25 36 19 5 25 13 25 35 0 16 5 29 10 29 6 0 10 18 10
40 l0 40 -45 0 c-33 0 -45 4 -45 15 0 8 5 15 10 15 6 0 10 14 10 30 0 25 -4
30 -25 30 -14 0 -28 7 -31 15 -6 16 -44 21 -44 6 0 -5 -11 -12 -24 -15 -17 -4
-26 -15 -31 -35 -9 -41 -75 -59 -75 -21 0 13 7 20 20 20 27 0 29 59 3 61 -17
1 -17 2 0 6 18 5 19 7 21 69 3 59 42 95 101 91 26 -2 30 -6 27 -27z m6739 0
c16 0 29 5 29 10 0 6 16 10 36 10 34 0 35 -1 29 -29 -3 -16 -3 -45 1 -65 5
-31 10 -36 34 -36 16 0 30 -5 32 -11 2 -6 31 -11 66 -12 35 -1 58 -3 52 -5
-13 -5 -13 -32 0 -32 6 0 10 -16 10 -35 0 -28 4 -35 20 -35 11 0 20 7 20 15 0
8 7 15 15 15 8 0 15 7 15 15 0 8 -4 15 -10 15 -5 0 -10 9 -10 20 0 11 5 20 10
20 6 0 10 -4 10 -10 0 -5 9 -10 20 -10 11 0 20 5 20 11 0 16 26 39 44 39 9 0
16 -4 16 -10 0 -5 13 -10 29 -10 20 0 30 -6 34 -20 4 -15 14 -20 36 -20 19 0
31 5 31 13 0 12 39 52 45 45 1 -2 0 -29 -2 -60 -5 -48 -7 -55 -19 -43 -8 7
-14 18 -14 24 0 6 -7 11 -15 11 -8 0 -15 -4 -15 -10 0 -5 -6 -10 -14 -10 -8 0
-22 -7 -31 -17 -11 -11 -19 -13 -24 -5 -3 6 -19 14 -34 17 -24 6 -27 4 -27
-17 0 -13 5 -29 12 -36 10 -10 7 -17 -10 -34 -19 -19 -22 -20 -22 -5 0 9 -3
17 -7 17 -5 0 -18 3 -30 6 -17 5 -23 2 -23 -9 0 -8 9 -17 20 -20 17 -4 20 -10
14 -34 -9 -36 -9 -36 -44 -36 -18 0 -30 -6 -30 -14 0 -7 -4 -13 -10 -13 -5 0
-11 -8 -11 -17 -1 -17 -2 -17 -6 0 -2 9 -11 17 -18 17 -11 0 -13 8 -8 30 5 25
2 30 -15 30 -11 0 -23 -6 -26 -12 -3 -7 -2 6 1 30 4 34 3 42 -10 42 -9 0 -17
-6 -20 -12 -2 -7 -3 -4 -1 7 2 11 4 32 4 48 0 20 5 27 20 27 13 0 20 7 20 20
0 17 -5 19 -32 13 -18 -3 -34 -10 -36 -15 -5 -11 -32 -10 -32 2 0 5 -11 12
-25 16 -14 3 -25 12 -25 20 0 9 -11 14 -30 14 -28 0 -30 -3 -30 -35 0 -24 5
-35 15 -35 8 0 15 -4 15 -10 0 -5 7 -10 15 -10 8 0 15 -7 15 -15 0 -9 -9 -15
-25 -15 -14 0 -25 -5 -25 -10 0 -6 -11 -8 -25 -4 -19 5 -25 2 -25 -10 0 -11
-9 -16 -30 -16 -20 0 -30 -5 -30 -15 0 -9 -7 -18 -15 -21 -17 -7 -21 -34 -4
-34 6 0 3 -7 -5 -16 -21 -20 -21 -74 -1 -74 8 0 15 5 15 10 0 15 40 12 56 -5
9 -8 24 -15 35 -15 14 0 19 7 19 30 0 20 -5 30 -15 30 -8 0 -15 5 -15 11 0 20
27 38 49 32 24 -6 29 -33 6 -33 -18 0 -20 -41 -3 -58 7 -7 26 -12 43 -12 29 0
31 -2 27 -30 -4 -27 -22 -44 -22 -20 0 6 -9 10 -20 10 -11 0 -20 7 -20 15 0
25 -29 17 -36 -10 -3 -13 -16 -27 -27 -31 -12 -4 -24 -10 -27 -14 -3 -4 -15
-10 -27 -14 -19 -6 -23 -4 -23 13 0 23 -3 24 -37 15 -28 -8 -30 -34 -3 -41 11
-3 20 -11 20 -18 0 -7 6 -16 13 -19 8 -3 7 -8 -5 -16 -10 -6 -18 -18 -18 -25
0 -10 -11 -15 -35 -15 -24 0 -35 5 -35 15 0 8 -7 15 -15 15 -8 0 -15 -4 -15
-10 0 -5 -4 -10 -10 -10 -5 0 -10 7 -10 15 0 8 -7 15 -15 15 -8 0 -15 7 -15
15 0 8 5 15 10 15 6 0 10 7 10 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15 0 10
-10 15 -31 15 -31 0 -46 -10 -68 -45 -11 -17 -12 -17 -7 -2 3 10 6 27 6 38 0
15 7 19 35 19 19 0 35 5 35 10 0 6 9 10 20 10 13 0 20 7 20 20 0 15 -7 20 -27
21 -24 2 -25 2 -5 6 18 4 22 11 22 44 0 21 4 39 10 39 5 0 7 -7 4 -15 -4 -9 0
-15 10 -15 9 0 16 6 16 14 0 8 14 17 30 21 26 6 30 11 30 39 0 45 -32 80 -65
72 -14 -4 -25 -13 -25 -21 0 -7 -9 -17 -20 -20 -11 -3 -20 -12 -20 -19 0 -9
-9 -12 -25 -8 -19 3 -32 -3 -50 -22 -30 -33 -31 -47 -5 -66 21 -15 27 -45 10
-55 -5 -3 -10 -17 -10 -30 0 -25 -17 -35 -60 -35 -20 0 -29 -6 -34 -22 -9 -34
-7 -68 3 -68 12 0 20 -59 9 -66 -4 -3 -8 9 -8 25 0 24 -4 31 -20 31 -16 0 -20
-7 -20 -30 0 -20 -5 -30 -15 -30 -8 0 -15 5 -15 10 0 6 -10 10 -22 10 -16 0
-19 3 -10 9 9 5 11 17 6 34 -5 21 -2 27 10 27 29 0 17 27 -16 35 -23 5 -27 9
-15 12 25 7 23 53 -3 53 -11 0 -20 7 -20 15 0 8 5 15 11 15 14 0 39 -25 39
-39 0 -6 11 -11 25 -11 14 0 25 5 25 11 0 14 -29 43 -37 36 -9 -9 -31 17 -26
30 4 10 10 9 26 -2 22 -16 67 -21 67 -7 0 4 3 17 6 30 5 16 2 22 -9 22 -8 0
-18 -6 -20 -12 -3 -8 -6 -6 -6 5 -1 9 -5 17 -11 17 -5 0 -10 5 -10 10 0 6 7
10 15 10 8 0 15 5 15 10 0 6 7 10 15 10 8 0 15 5 15 10 0 6 16 10 35 10 32 0
35 2 35 29 0 21 6 32 20 36 11 3 20 12 20 18 0 29 -13 57 -26 57 -8 0 -14 5
-14 10 0 16 27 12 34 -5 3 -8 17 -15 31 -15 16 0 25 6 25 15 0 8 -4 15 -10 15
-5 0 -10 9 -10 20 0 16 7 20 34 20 19 0 38 -4 41 -10 9 -15 25 -12 25 5 0 10
10 15 29 15 25 0 31 5 36 30 4 17 11 30 16 30 5 0 9 -13 9 -30 0 -16 -5 -30
-11 -30 -6 0 -9 -7 -5 -15 3 -9 0 -15 -9 -15 -18 0 -18 -2 -4 -34 9 -20 9 -29
-1 -41 -15 -18 -5 -65 14 -65 8 0 17 13 21 29 3 16 13 32 21 35 8 3 14 12 14
21 0 13 7 15 36 9 33 -6 36 -5 32 17 -3 22 -7 24 -58 22 -30 0 -45 1 -32 4 12
2 22 11 22 19 0 8 5 14 10 14 6 0 10 12 10 26 0 17 6 28 20 31 20 5 29 33 10
33 -5 0 -10 9 -10 20 0 13 -7 20 -20 20 -33 0 -23 18 13 22 19 2 33 -2 35 -9
2 -7 17 -13 33 -13z m-5236 -88 c0 -35 -4 -52 -12 -52 -8 0 -13 -13 -13 -30 0
-29 2 -30 45 -30 45 0 45 0 45 -35 0 -22 6 -38 15 -41 26 -10 17 -24 -15 -24
-18 0 -33 -6 -36 -15 -7 -16 -34 -21 -34 -6 0 5 -9 13 -20 16 -20 6 -29 45
-10 45 6 0 10 14 10 30 0 17 -5 30 -11 30 -6 0 -8 9 -4 20 4 14 2 20 -8 20
-19 0 -29 71 -13 85 7 6 15 18 19 28 4 11 14 17 24 15 15 -3 18 -14 18 -56z
m3788 34 c15 -12 15 -15 2 -26 -8 -7 -15 -21 -15 -31 0 -22 43 -27 65 -6 45
39 65 48 65 27 0 -5 12 -10 26 -10 23 0 24 -1 10 -16 -23 -23 -20 -41 9 -48
14 -4 25 -13 25 -21 0 -18 27 -20 33 -2 6 18 14 6 20 -34 8 -53 -3 -64 -47
-49 -19 6 -44 9 -55 5 -20 -6 -31 16 -31 68 0 15 -6 27 -13 27 -13 0 -41 -28
-50 -51 -4 -10 -6 -9 -6 4 -1 9 -5 17 -10 17 -5 0 -11 14 -14 30 -6 25 -11 30
-37 30 -29 0 -30 1 -30 50 0 41 3 50 18 50 9 0 25 -7 35 -14z m3117 -60 c0 -9
17 -16 50 -21 33 -5 50 -12 50 -21 0 -8 7 -14 16 -14 9 0 14 8 13 18 -5 57 12
69 35 24 13 -26 13 -29 -5 -40 -10 -7 -30 -12 -44 -12 -28 0 -33 -20 -10 -44
8 -8 15 -22 15 -31 0 -14 -6 -16 -27 -11 -30 8 -43 -5 -43 -46 0 -17 -5 -28
-13 -28 -9 0 -12 10 -9 30 4 25 1 30 -17 30 -14 0 -19 5 -14 16 3 9 -3 23 -15
35 -25 23 -32 24 -32 4 0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15
6 0 10 12 11 28 0 15 3 21 6 15 2 -7 13 -13 24 -13 26 0 26 40 -1 47 -21 5
-25 15 -13 26 12 12 33 7 33 -7z m1681 4 c14 0 19 -7 19 -25 0 -20 5 -25 24
-25 13 0 27 6 29 13 3 6 6 1 6 -12 1 -21 5 -23 31 -17 28 5 30 3 30 -24 0 -26
4 -30 28 -30 47 0 72 -11 72 -31 0 -11 -7 -19 -15 -19 -9 0 -15 -9 -15 -25 0
-19 5 -25 23 -26 16 -1 18 -2 5 -6 -10 -2 -18 -11 -18 -19 0 -19 44 -18 64 1
20 21 36 19 36 -5 0 -15 7 -20 25 -20 24 0 30 -9 25 -39 -1 -8 4 -17 11 -22 9
-5 9 -11 -4 -23 -8 -9 -18 -16 -22 -16 -4 0 -13 -7 -21 -15 -9 -8 -24 -15 -35
-15 -11 0 -27 -7 -35 -15 -9 -8 -30 -14 -47 -14 -18 1 -24 3 -14 6 9 2 17 11
17 19 0 8 5 14 10 14 6 0 10 9 10 20 0 11 -4 20 -10 20 -5 0 -10 7 -10 15 0 8
-4 15 -9 15 -21 0 -41 -13 -41 -27 0 -26 -25 -8 -28 20 -2 15 -8 27 -13 27 -5
0 -9 7 -9 15 0 12 -28 35 -44 35 -3 0 -6 -11 -6 -25 0 -20 -5 -25 -25 -25 -26
0 -35 25 -12 33 6 2 9 7 6 11 -11 11 -59 -5 -59 -19 0 -22 -18 -27 -60 -20
-23 4 -40 12 -40 20 0 17 -63 32 -68 16 -2 -6 -10 -11 -18 -11 -8 0 -14 -4
-14 -10 0 -5 -7 -10 -15 -10 -22 0 -18 17 5 23 11 3 20 12 20 21 0 9 -8 16
-20 16 -13 0 -20 7 -20 20 0 16 -7 20 -35 20 -22 0 -35 5 -35 13 0 9 9 12 30
9 18 -3 35 2 45 11 18 18 20 57 3 57 -6 0 -9 2 -6 5 3 3 12 3 21 -1 12 -4 15
-14 10 -35 -5 -25 -2 -29 16 -29 17 0 21 6 21 35 0 19 5 35 10 35 10 0 14 -19
11 -52 -2 -11 5 -18 14 -18 9 0 19 -9 22 -21 5 -17 11 -20 34 -15 19 4 31 1
37 -10 4 -8 21 -16 37 -17 35 -2 45 22 19 47 -10 11 -18 40 -22 81 -3 44 -9
65 -18 65 -7 0 -14 -4 -16 -9 -1 -5 -9 -3 -16 5 -11 12 -10 14 3 14 8 0 15 7
15 16 0 11 5 14 16 10 9 -3 24 -6 35 -6z m-2653 -182 c2 -4 10 -8 18 -8 26 0
14 -27 -15 -33 -18 -4 -32 -14 -36 -27 -3 -11 -12 -20 -20 -20 -16 0 -25 -22
-25 -61 0 -22 5 -29 20 -29 11 0 20 7 20 15 0 8 7 15 15 15 17 0 21 26 5 33
-6 3 -5 4 2 3 7 -1 15 -16 16 -32 2 -16 8 -31 12 -34 5 -3 10 -16 13 -30 2
-14 2 -19 0 -11 -4 10 -16 12 -44 7 -31 -5 -39 -3 -39 9 0 18 -16 20 -24 3 -5
-10 -7 -10 -12 0 -3 6 -13 12 -22 12 -26 0 -25 113 1 118 9 2 17 8 17 13 0 5
6 9 13 9 14 0 47 35 47 49 0 5 -6 12 -12 15 -7 3 1 2 17 -1 17 -3 32 -10 33
-15z m-5328 1 c0 -17 27 -39 49 -39 11 0 26 -6 33 -13 21 -21 67 -33 141 -36
34 -1 55 -21 22 -21 -10 0 -15 -10 -15 -29 0 -16 6 -31 15 -35 8 -3 15 -15 15
-26 0 -11 7 -20 15 -20 8 0 15 -4 15 -10 0 -5 -5 -10 -11 -10 -6 0 -9 -7 -5
-15 3 -8 1 -15 -4 -15 -6 0 -10 5 -10 10 0 6 -6 10 -14 10 -8 0 -17 12 -21 27
-8 32 -21 43 -52 43 -16 0 -23 6 -23 20 0 15 -7 20 -25 20 -20 0 -25 -5 -25
-26 0 -25 -2 -26 -37 -18 -21 4 -54 8 -73 9 -57 3 -59 3 -66 43 -4 20 -12 40
-18 44 -6 4 1 8 17 8 21 0 27 5 27 23 0 42 12 67 31 67 11 0 19 -5 19 -11z
m-1150 -164 c0 -30 13 -32 41 -4 11 11 24 17 27 13 4 -3 1 -9 -5 -11 -7 -3
-13 -16 -13 -29 0 -18 5 -24 21 -24 20 0 48 -25 49 -43 0 -4 -11 -12 -25 -17
-14 -5 -25 -16 -25 -25 0 -8 -4 -15 -10 -15 -5 0 -10 -6 -10 -14 0 -9 -7 -12
-20 -9 -15 4 -18 11 -14 29 5 21 3 24 -20 24 -21 0 -26 -5 -26 -25 0 -20 -5
-25 -26 -25 -16 0 -24 -5 -22 -12 8 -24 -20 -40 -50 -30 -30 10 -43 42 -17 42
19 0 45 23 45 39 0 6 9 11 20 11 15 0 20 7 20 24 0 14 6 31 13 38 8 7 17 30
21 51 8 42 26 50 26 12z m-680 -35 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6
11 10 25 10 14 0 25 -4 25 -9 0 -5 8 -12 18 -14 9 -3 0 -5 -20 -6 -21 0 -38
-5 -38 -11 0 -5 -16 -12 -35 -16 -39 -7 -47 -31 -19 -58 8 -9 11 -16 6 -16 -5
0 -15 -20 -22 -45 -11 -40 -16 -45 -41 -45 -16 0 -29 5 -29 10 0 6 -7 10 -15
10 -8 0 -15 5 -15 11 0 7 -18 10 -50 7 -35 -2 -50 1 -50 10 0 6 11 12 24 12
45 0 86 13 86 26 0 8 4 14 10 14 15 0 50 33 50 47 0 7 5 13 10 13 6 0 10 14
10 30 0 17 5 30 10 30 6 0 10 -4 10 -10z m6983 -32 c-3 -24 -12 -47 -19 -51
-8 -4 -14 -15 -14 -23 0 -24 -157 -16 -164 9 -3 13 2 17 20 17 17 0 24 6 24
20 0 11 5 20 10 20 6 0 10 7 10 15 0 9 6 12 15 9 8 -4 15 -12 15 -20 0 -8 9
-14 20 -14 16 0 20 7 20 30 0 27 3 30 34 30 l34 0 -5 -42z m301 -40 c3 -13 6
-33 6 -45 0 -12 8 -26 17 -32 10 -5 14 -13 9 -18 -5 -4 -11 -2 -13 5 -11 30
-23 8 -17 -30 7 -42 32 -78 54 -78 18 0 40 -27 40 -49 0 -21 -16 -29 -22 -11
-1 5 -24 13 -50 16 l-48 7 0 -32 c0 -21 -5 -31 -15 -31 -8 0 -15 5 -15 10 0 6
5 10 10 10 23 0 8 29 -17 32 -14 2 -28 9 -29 16 -1 7 0 8 3 2 8 -16 53 -12 53
5 0 8 -4 15 -10 15 -16 0 -12 27 5 34 8 3 15 17 15 31 0 21 -5 25 -32 27 -18
0 -43 0 -55 -1 -20 -1 -21 2 -16 34 6 34 5 35 -30 35 -26 0 -36 5 -40 20 -5
20 8 39 15 20 2 -8 17 -8 51 0 26 5 59 7 72 4 19 -5 25 -2 25 10 0 24 27 19
34 -6z m-6531 -128 c3 -12 14 -20 26 -20 12 0 21 -6 21 -15 0 -8 5 -15 10 -15
6 0 10 -13 10 -29 0 -25 5 -31 30 -36 32 -7 39 -19 15 -29 -8 -3 -15 -14 -15
-25 0 -11 7 -26 15 -35 8 -8 15 -19 15 -25 0 -6 4 -11 9 -11 18 0 39 -30 34
-49 -3 -14 -17 -20 -51 -24 -25 -3 -48 -10 -50 -16 -4 -13 -32 -15 -32 -3 0 4
13 19 30 32 47 40 33 66 -30 53 -28 -5 -30 -4 -30 26 0 21 -5 31 -15 31 -8 0
-15 -5 -15 -11 0 -6 -8 -3 -17 7 -11 10 -16 29 -14 46 1 16 -2 28 -9 28 -8 0
-7 6 1 22 9 16 9 23 0 26 -6 2 -11 10 -11 18 0 8 -4 14 -10 14 -5 0 -10 7 -10
15 0 8 6 15 14 15 8 0 16 7 20 15 8 23 53 19 59 -5z m-1223 -15 c0 -20 5 -25
25 -25 16 0 25 -6 25 -15 0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 7 15 15 15 8
0 15 -6 15 -14 0 -8 7 -16 15 -20 8 -3 15 -12 15 -21 0 -10 10 -15 30 -15 27
0 30 -3 30 -30 0 -32 -14 -40 -24 -15 -3 8 -14 15 -25 15 -11 0 -21 4 -23 10
-1 5 -29 12 -60 16 -52 6 -58 5 -59 -12 -1 -17 -2 -17 -6 -1 -2 9 -11 17 -19
17 -9 0 -14 11 -14 30 0 23 -5 30 -19 30 -14 0 -18 5 -14 20 3 11 14 20 24 20
11 0 19 5 19 10 0 6 7 10 15 10 9 0 15 -9 15 -25z m1450 15 c0 -5 9 -10 20
-10 11 0 20 5 20 10 0 6 10 2 21 -9 20 -18 21 -27 15 -88 -4 -37 -12 -71 -18
-74 -16 -10 -38 0 -38 17 0 9 -12 14 -35 14 -24 0 -35 5 -35 15 0 8 -9 15 -19
15 -19 0 -35 23 -24 34 4 3 15 6 26 6 17 0 19 5 14 40 -5 39 -4 40 24 40 16 0
29 -4 29 -10z m8670 -30 c0 -13 7 -20 19 -20 13 0 20 -10 25 -32 4 -19 2 -34
-4 -36 -5 -2 -10 -15 -10 -28 0 -13 -4 -24 -10 -24 -5 0 -10 -9 -10 -20 0 -13
7 -20 20 -20 13 0 20 -7 20 -20 0 -17 7 -20 40 -20 27 0 40 -4 40 -14 0 -10
-14 -13 -57 -10 -32 2 -59 8 -61 14 -2 5 -10 10 -18 10 -8 0 -14 9 -14 20 0
11 -7 20 -15 20 -8 0 -15 2 -15 4 0 2 -3 11 -6 20 -4 11 -1 16 11 16 19 0 45
23 45 40 0 17 -26 40 -44 40 -24 0 -20 41 4 47 11 3 20 12 20 19 0 8 5 14 10
14 6 0 10 -9 10 -20z m-9720 -29 c0 -5 -9 -11 -20 -14 -25 -6 -26 -34 -2 -40
9 -3 -4 -5 -30 -6 -49 -1 -59 -12 -34 -37 18 -17 53 -18 59 -1 4 9 8 8 16 -3
13 -19 -22 -34 -58 -25 -38 10 -66 -28 -65 -87 0 -40 -3 -48 -18 -48 -10 0
-18 -4 -18 -10 0 -5 -15 -10 -34 -10 l-33 0 3 57 c3 54 14 73 42 73 6 0 12 5
12 10 0 6 11 10 25 10 14 0 25 3 25 6 0 16 -23 44 -36 44 -9 0 -14 6 -12 13 3
6 17 12 33 12 16 1 30 6 33 13 2 7 10 12 18 12 8 0 14 5 14 10 0 6 11 10 25
10 14 0 25 5 25 10 0 6 7 10 15 10 8 0 15 -4 15 -9z m330 -46 c0 -11 10 -13
50 -8 41 5 50 3 50 -10 0 -11 14 -18 45 -23 41 -6 45 -9 45 -35 0 -16 -4 -29
-10 -29 -5 0 -10 5 -10 10 0 22 -40 9 -46 -15 -5 -21 -12 -25 -45 -25 -26 0
-42 -5 -45 -15 -4 -8 -12 -15 -20 -15 -8 0 -14 -9 -14 -20 0 -11 5 -20 10 -20
16 0 11 -27 -6 -34 -25 -10 -34 -7 -34 9 0 8 5 15 10 15 6 0 10 7 10 15 0 10
-10 15 -30 15 -32 0 -70 -26 -70 -47 0 -7 -6 -13 -14 -13 -20 0 -48 29 -40 42
4 5 1 21 -7 34 -10 20 -21 24 -58 25 -25 1 -36 4 -23 6 12 2 22 11 22 19 0 8
5 14 11 14 7 0 20 10 31 22 15 17 21 19 27 9 5 -7 6 -22 3 -33 -6 -26 15 -39
54 -32 21 4 34 1 43 -10 17 -20 47 -21 55 -1 3 8 12 15 21 15 10 0 15 10 15
29 0 26 -3 29 -45 34 -55 6 -66 14 -42 31 9 7 17 23 17 35 0 14 6 21 20 21 11
0 20 -7 20 -15z m7499 -98 c0 -9 -2 -9 -6 1 -7 17 -33 15 -55 -5 -19 -17 -24
-43 -8 -43 6 0 10 -7 10 -15 0 -9 9 -15 25 -15 16 0 25 -6 25 -15 0 -8 7 -15
15 -15 8 0 15 -7 15 -16 0 -20 -24 -44 -44 -44 -9 0 -16 -8 -16 -20 0 -11 7
-20 15 -20 8 0 15 -7 15 -15 0 -8 -4 -15 -9 -15 -5 0 -11 7 -15 15 -3 9 -19
15 -41 15 -32 0 -35 2 -35 30 0 21 -5 30 -17 31 -17 1 -17 2 0 6 11 3 17 14
17 34 0 19 5 29 15 29 8 0 15 7 15 15 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 8
5 15 10 15 6 0 10 9 10 19 0 13 8 21 23 23 27 5 57 -8 56 -25z m-7818 -17 c5
0 9 -11 9 -25 0 -14 -4 -25 -10 -25 -5 0 -10 -4 -10 -10 0 -5 20 -10 45 -10
l45 0 0 -46 c0 -37 4 -47 20 -51 23 -6 26 -21 6 -29 -8 -3 -17 -16 -20 -30 -5
-18 -13 -24 -35 -24 -16 0 -36 -3 -45 -6 -13 -5 -16 1 -16 25 0 21 -5 31 -15
31 -8 0 -15 -7 -15 -15 0 -9 -9 -15 -25 -15 -14 0 -25 -4 -25 -10 0 -5 -7 -10
-15 -10 -8 0 -15 5 -15 11 0 6 -6 17 -14 25 -13 13 -11 53 3 55 44 7 142 6
146 -1 11 -17 35 -11 35 9 0 13 -9 21 -30 26 -16 4 -30 11 -30 16 0 5 -6 9
-14 9 -7 0 -16 11 -18 25 -4 19 -1 25 13 25 24 0 24 23 2 46 -17 17 -17 18 0
11 10 -4 23 -7 28 -7z m-411 -141 c0 -5 20 -9 44 -9 27 0 46 -5 49 -12 3 -10
11 -10 39 0 57 20 65 17 60 -25 -3 -20 -10 -43 -18 -50 -15 -15 -44 -17 -44
-3 0 6 7 10 15 10 8 0 15 9 15 20 0 16 -7 20 -30 20 -27 0 -30 -3 -30 -30 0
-17 5 -30 13 -30 22 0 -17 -20 -40 -20 -18 0 -23 -6 -23 -25 0 -14 -4 -25 -10
-25 -5 0 -10 4 -10 9 0 5 -3 16 -6 25 -4 11 2 19 20 26 35 13 36 43 1 34 -14
-3 -45 -3 -70 0 -39 6 -45 10 -45 32 0 13 5 24 10 24 6 0 10 8 10 18 0 10 3
21 6 24 8 8 44 -3 44 -13z m7986 -96 c7 -7 17 -13 23 -13 6 0 11 -4 11 -10 0
-20 27 -9 38 15 13 27 32 34 32 11 0 -9 12 -16 31 -18 19 -2 37 -11 43 -23 29
-50 52 -58 62 -20 5 19 13 25 35 25 33 0 39 -16 9 -25 -11 -3 -20 -11 -20 -16
0 -6 -7 -17 -15 -25 -8 -9 -15 -11 -15 -5 0 6 -7 11 -15 11 -22 0 -18 -27 5
-33 14 -3 20 -14 20 -31 0 -19 5 -26 20 -26 11 0 20 7 20 15 0 10 9 15 28 14
15 0 21 -3 15 -6 -7 -2 -13 -16 -13 -29 0 -17 -6 -24 -20 -24 -11 0 -20 -4
-20 -10 0 -5 5 -10 11 -10 5 0 7 -5 3 -12 -6 -9 -9 -9 -12 0 -5 17 -42 15 -42
-2 0 -8 7 -21 15 -30 8 -8 15 -22 15 -31 0 -13 -7 -15 -37 -9 -42 7 -54 15
-35 21 17 6 15 33 -2 33 -16 0 -31 -34 -21 -50 3 -6 -1 -7 -9 -4 -9 3 -16 12
-16 20 0 8 -9 14 -20 14 -11 0 -20 7 -20 15 0 8 -4 15 -10 15 -5 0 -10 7 -10
15 0 8 5 15 10 15 6 0 10 23 10 50 0 28 -5 50 -11 50 -5 0 -15 14 -20 30 -13
35 -21 37 -49 13 -25 -21 -26 -41 -2 -50 9 -4 24 -13 32 -21 13 -13 8 -14 -33
-8 -39 6 -47 5 -47 -8 0 -9 -6 -16 -14 -16 -8 0 -17 -11 -20 -25 -4 -16 -13
-25 -27 -25 -11 0 -22 -7 -25 -15 -4 -8 -16 -15 -28 -14 -13 0 -16 3 -7 6 32
13 51 44 51 83 0 27 5 43 15 46 8 4 15 14 16 23 0 10 2 11 6 4 7 -18 30 -16
37 3 10 26 7 32 -16 40 -12 3 -28 13 -36 20 -12 12 -9 15 17 20 24 5 31 12 31
30 0 15 -6 24 -15 24 -13 0 -14 2 -3 14 11 12 14 10 22 -12 5 -15 15 -32 22
-39z m-8050 -7 c8 -8 14 -19 14 -25 0 -6 5 -20 12 -31 11 -16 8 -22 -16 -44
-32 -27 -30 -56 4 -56 23 0 23 0 10 -34 -10 -28 -40 -36 -40 -12 0 16 -18 23
-63 25 -21 1 -30 -5 -34 -19 -6 -24 -39 -36 -58 -20 -8 7 -37 9 -72 7 -32 -2
-46 -2 -30 0 21 3 27 10 27 29 0 18 -5 24 -21 24 -17 0 -18 2 -7 14 8 7 23 16
35 20 17 5 21 1 26 -24 6 -25 11 -30 36 -30 22 0 32 6 37 22 3 12 13 29 21 36
9 9 12 24 9 38 -4 14 -1 26 7 31 8 4 19 17 26 28 10 17 11 13 6 -22 -7 -43 -7
-43 24 -43 28 0 31 3 31 30 0 17 -4 30 -10 30 -5 0 -10 9 -10 20 0 23 16 26
36 6z m1049 -86 c20 0 19 -36 0 -44 -11 -4 -15 -21 -15 -61 0 -52 1 -55 25
-55 20 0 27 6 32 30 5 28 23 43 23 20 0 -6 11 -15 25 -20 14 -5 25 -15 24 -22
0 -10 -2 -10 -6 0 -6 15 -30 16 -35 1 -2 -5 -24 -13 -48 -16 -25 -4 -50 -13
-55 -20 -7 -9 -23 -13 -40 -11 -27 3 -28 4 -12 20 9 9 17 22 17 28 0 6 7 13
15 16 23 9 18 34 -8 34 -12 0 -30 9 -40 20 -9 11 -13 20 -8 20 5 0 12 13 16
29 4 20 14 31 31 35 13 4 24 12 24 19 0 6 5 4 11 -5 5 -10 17 -18 24 -18z
m-695 -11 c0 -17 5 -20 21 -16 15 4 19 2 15 -10 -9 -22 4 -63 20 -63 14 0 18
-11 15 -42 -1 -9 6 -18 16 -21 10 -3 4 -5 -13 -6 -18 0 -34 -7 -38 -16 -3 -8
-10 -15 -16 -15 -5 0 -10 7 -10 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15 0 8 -9
15 -19 15 -10 0 -22 7 -25 16 -9 24 -7 34 8 34 14 0 26 21 26 46 0 8 -7 14
-15 14 -21 0 -19 28 3 33 34 9 42 7 42 -14z m410 3 c0 -4 14 -13 30 -19 17 -6
30 -17 29 -25 0 -10 -2 -10 -6 0 -6 16 -33 15 -33 -2 0 -37 14 -75 29 -79 9
-3 1 -5 -16 -6 -18 0 -33 -5 -33 -11 0 -5 -12 -10 -26 -10 -23 0 -25 -3 -19
-30 5 -23 3 -30 -9 -30 -9 0 -16 -8 -16 -20 0 -11 5 -20 10 -20 6 0 10 -7 10
-15 0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -8 -7 -15 -15 -15 -8 0 -15 4
-15 9 0 5 -11 12 -25 15 -17 4 -25 13 -25 27 0 11 -7 27 -15 35 -18 18 -20 64
-4 64 7 0 17 -7 23 -15 8 -11 20 -14 44 -9 28 6 32 10 32 40 0 19 5 34 10 34
6 0 10 13 10 29 0 57 12 91 31 91 11 0 19 -4 19 -8z m5722 -186 c-3 -27 0 -34
17 -39 17 -4 19 -10 14 -43 -3 -22 -9 -41 -13 -44 -4 -3 -10 -14 -13 -25 -3
-13 -5 -9 -6 13 -1 35 -14 41 -37 17 -9 -8 -30 -15 -47 -15 -31 0 -64 -24 -77
-57 -3 -7 -10 -9 -18 -4 -8 5 -15 35 -17 78 -5 81 -3 83 88 83 40 0 47 3 47
20 0 11 6 20 14 20 8 0 16 7 20 15 11 30 32 16 28 -19z m-5379 -33 c-7 -2 -13
-13 -13 -23 0 -10 -6 -25 -13 -32 -19 -19 -27 -45 -21 -63 7 -18 51 -20 56 -3
2 6 4 -8 3 -33 -1 -41 -3 -45 -33 -54 -18 -5 -32 -15 -32 -22 0 -7 -7 -13 -15
-13 -8 0 -15 -4 -15 -10 0 -5 -25 -10 -55 -10 -54 0 -55 1 -49 25 5 21 3 25
-15 25 -13 0 -21 -6 -21 -16 0 -14 -8 -15 -57 -9 -66 8 -74 12 -53 26 11 8 12
12 3 16 -7 2 -13 18 -13 34 0 22 -5 29 -20 29 -11 0 -20 6 -20 14 0 17 103 33
108 17 2 -6 10 -11 18 -11 8 0 14 -4 14 -10 0 -18 49 -11 70 10 21 21 27 50
10 50 -5 0 -10 7 -10 16 0 13 6 15 28 9 15 -3 29 -11 30 -16 10 -26 54 0 66
39 5 16 15 22 34 21 15 0 21 -3 15 -6z m7045 -91 c6 -2 12 1 12 7 0 6 11 11
25 11 28 0 31 -5 13 -25 -26 -29 -35 -41 -41 -55 -4 -12 -6 -11 -6 3 -1 10 -9
17 -21 17 -11 0 -20 5 -20 10 0 16 -47 12 -54 -5 -3 -8 -13 -15 -22 -15 -14 0
-15 -9 -9 -67 4 -38 9 -71 12 -75 2 -5 20 -8 39 -8 27 0 34 -4 34 -20 0 -11 5
-20 10 -20 6 0 10 -6 10 -14 0 -8 8 -17 18 -19 13 -4 11 -5 -5 -6 -13 0 -23
-4 -23 -8 1 -21 13 -53 21 -53 8 0 36 20 65 48 12 11 14 11 14 -3 0 -8 7 -18
15 -21 8 -4 15 -17 15 -30 0 -15 6 -24 15 -24 24 0 17 -27 -8 -34 -36 -9 -67
4 -73 30 -7 29 -50 33 -70 7 -12 -17 -14 -17 -34 5 -11 12 -30 22 -42 22 -18
0 -20 4 -14 32 3 17 7 33 9 34 8 8 47 -10 47 -22 0 -14 30 -20 30 -6 0 4 3 17
6 30 5 17 2 22 -14 22 -11 0 -28 7 -36 16 -9 8 -20 12 -26 9 -5 -3 -10 1 -10
9 0 9 -4 16 -10 16 -5 0 -10 7 -10 15 0 9 -9 15 -25 15 -14 0 -28 -7 -31 -15
-4 -9 -19 -15 -35 -15 -27 0 -29 -3 -29 -39 0 -22 5 -41 12 -43 9 -3 8 -9 -5
-22 -16 -17 -17 -16 -17 18 0 22 -5 36 -12 37 -10 0 -10 2 0 6 6 2 12 18 12
34 0 23 4 29 21 29 18 0 20 4 14 28 -3 15 -11 29 -16 30 -15 6 -10 22 6 22 9
0 15 9 15 25 0 15 7 28 18 32 9 4 23 13 30 20 7 7 29 13 48 13 27 0 34 4 34
20 0 13 7 20 20 20 11 0 20 4 20 9 0 5 8 11 18 13 11 2 19 -4 22 -21 3 -13 11
-26 18 -29z m-458 3 c0 -8 5 -15 10 -15 6 0 10 -20 10 -44 0 -36 4 -46 20 -51
11 -3 20 -13 20 -21 0 -17 26 -19 33 -1 4 9 6 9 6 -1 1 -7 -6 -15 -14 -18 -9
-4 -15 -19 -15 -35 0 -33 -25 -40 -33 -9 -5 18 -13 20 -102 18 -58 -1 -99 3
-103 9 -4 6 -15 12 -24 14 -13 3 -15 8 -7 19 8 11 12 12 16 3 2 -7 16 -13 29
-13 17 0 24 6 24 20 0 23 12 25 43 9 16 -9 22 -9 25 0 2 6 8 11 14 11 13 0 38
26 38 40 0 17 -28 42 -40 35 -5 -3 -10 1 -10 9 0 9 7 16 15 16 8 0 15 5 15 10
0 6 7 10 15 10 8 0 15 -7 15 -15z m-7075 -52 c-3 -15 -10 -30 -14 -33 -5 -3
-12 -16 -16 -30 -9 -30 -22 -40 -52 -40 -15 0 -23 -6 -23 -16 0 -13 7 -15 35
-11 31 5 34 4 20 -9 -9 -10 -13 -24 -9 -40 5 -18 2 -24 -10 -24 -9 0 -16 8
-16 20 0 18 -7 20 -54 20 -43 0 -56 -4 -60 -17 -2 -10 -2 -1 1 20 5 36 4 37
-26 37 -21 0 -31 5 -31 15 0 8 4 15 9 15 5 0 12 8 16 19 4 15 15 19 54 18 41
-1 47 -3 43 -19 -2 -10 -11 -18 -18 -18 -8 0 -14 -9 -14 -20 0 -12 7 -20 16
-20 9 0 18 9 21 20 3 11 12 20 19 20 9 0 14 11 14 30 0 28 3 30 35 30 32 0 35
2 35 30 0 21 5 30 16 30 13 0 15 -6 9 -27z m9469 2 c7 -26 31 -34 39 -12 4 9
6 9 6 -1 1 -7 -7 -16 -16 -19 -23 -9 -33 -23 -33 -50 0 -18 -6 -23 -25 -23
-16 0 -25 -6 -25 -15 0 -8 7 -15 15 -15 22 0 19 -8 -14 -41 -16 -16 -39 -29
-50 -29 -14 0 -21 -8 -23 -27 -5 -42 -22 -37 -37 11 -7 25 -18 47 -24 50 -11
4 19 10 37 7 24 -4 46 12 46 35 0 15 6 24 15 24 8 0 15 7 15 15 0 10 -10 15
-30 15 -16 0 -30 -4 -30 -10 0 -5 -9 -10 -20 -10 -23 0 -25 17 -6 43 14 19 36
23 36 7 0 -5 11 -10 25 -10 21 0 25 5 25 30 0 17 -4 30 -10 30 -5 0 -10 5 -10
10 0 6 20 10 44 10 38 0 45 -3 50 -25z m-1480 -75 c12 0 15 -6 10 -25 -5 -22
-3 -25 20 -25 24 0 26 -4 26 -39 0 -22 -7 -46 -15 -55 -8 -8 -15 -18 -15 -23
0 -12 -56 -63 -69 -63 -6 0 -11 -5 -11 -11 0 -6 -7 -18 -17 -27 -15 -16 -16
-14 -10 26 8 49 37 82 72 82 19 0 25 5 25 21 0 19 -4 20 -42 14 -24 -4 -45
-11 -48 -15 -3 -4 -21 -10 -42 -14 -33 -6 -36 -5 -31 14 4 13 0 20 -10 20 -8
0 -17 7 -21 15 -4 12 3 15 30 15 l35 0 -7 45 c-6 41 -4 45 14 45 32 0 52 11
52 28 0 11 4 10 19 -6 10 -12 26 -22 35 -22z m-1089 -160 c-3 -5 -3 -20 1 -34
5 -22 14 -27 55 -32 27 -4 49 -10 49 -15 0 -5 -3 -9 -7 -9 -5 0 -18 -3 -30 -6
-17 -5 -23 -2 -23 10 0 9 -8 16 -20 16 -15 0 -22 -9 -26 -30 -5 -23 -12 -30
-31 -30 -13 0 -26 -7 -29 -15 -9 -22 -24 -18 -24 5 0 13 -7 20 -20 20 -11 0
-20 5 -20 10 0 14 -27 12 -33 -2 -3 -7 -6 -2 -6 12 -1 14 8 33 19 43 22 19 60
23 60 7 0 -5 11 -10 24 -10 17 0 26 7 30 25 4 14 4 30 1 35 -4 6 3 10 15 10
12 0 19 -4 15 -10z m-369 -40 c8 0 14 -7 14 -15 0 -8 5 -15 11 -15 16 0 39
-26 39 -44 0 -9 -4 -16 -10 -16 -16 0 -12 -30 7 -48 12 -12 18 -13 21 -4 5 17
22 15 22 -3 0 -21 30 -19 31 3 0 10 3 12 6 5 7 -18 30 -16 37 2 8 22 26 18 26
-5 0 -11 -7 -20 -15 -20 -11 0 -15 -13 -17 -47 -1 -46 -2 -46 -5 -9 l-4 38
-46 -7 c-25 -4 -52 -9 -59 -12 -9 -3 -14 2 -14 15 0 15 -9 21 -34 26 -26 5
-36 13 -41 34 -4 16 -2 28 4 30 14 5 14 29 0 34 -7 2 -8 17 -3 43 4 22 9 34
11 28 3 -7 11 -13 19 -13z m-5929 -175 c12 -12 28 -14 79 -9 58 6 64 5 64 -12
0 -17 -44 -64 -60 -64 -4 0 -10 9 -13 21 -4 16 -12 20 -34 17 -30 -3 -47 -38
-18 -38 9 0 25 -9 37 -20 l22 -20 -27 0 c-14 0 -29 -4 -32 -10 -3 -5 -21 -10
-40 -10 -19 0 -37 -5 -40 -10 -12 -20 6 -49 36 -55 27 -6 30 -10 24 -33 -4
-15 -12 -30 -18 -34 -18 -12 -37 -9 -37 4 0 7 -12 23 -27 35 -14 13 -21 23
-15 23 7 0 12 7 12 15 0 11 -11 15 -41 15 -33 0 -40 3 -37 18 3 13 12 16 37
14 29 -2 33 0 28 18 -4 16 0 20 19 20 15 0 24 6 24 15 0 8 -7 15 -15 15 -8 0
-15 7 -15 15 0 8 7 15 15 15 9 0 15 9 15 25 0 16 6 25 15 25 8 0 15 5 15 10 0
14 9 12 27 -5z m-607 -35 c0 -5 -4 -10 -10 -10 -5 0 -10 -10 -10 -21 0 -19 4
-21 30 -15 22 4 30 2 30 -9 0 -8 7 -15 16 -15 23 0 44 25 44 54 0 23 4 26 35
26 19 0 35 -4 35 -10 0 -5 -4 -10 -9 -10 -5 0 -11 -9 -14 -20 -3 -11 -12 -20
-21 -20 -10 0 -19 -12 -23 -30 -4 -21 -13 -32 -29 -36 -23 -6 -35 -34 -14 -34
6 0 10 -9 10 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -7 -10 -15 0 -8 -7 -15 -15
-15 -8 0 -15 -7 -15 -15 0 -8 5 -15 10 -15 6 0 10 -7 10 -15 0 -8 -4 -15 -9
-15 -5 0 -16 7 -25 15 -8 8 -26 15 -40 15 -23 0 -26 4 -26 35 0 19 -4 35 -10
35 -19 0 -10 33 15 56 21 19 25 32 25 79 0 42 3 55 15 55 8 0 15 -4 15 -10z
m5960 -91 c8 -8 23 -18 33 -22 15 -6 17 -13 12 -39 -4 -18 -11 -34 -16 -36 -5
-2 -9 -15 -9 -28 0 -17 -6 -24 -20 -24 -14 0 -20 -7 -20 -21 0 -12 8 -23 20
-26 11 -3 20 -9 20 -14 0 -14 -28 -11 -33 4 -3 9 -7 9 -19 -1 -13 -10 -16 -9
-21 10 -8 31 2 68 19 68 8 0 14 10 14 21 0 18 -5 21 -27 17 -16 -3 -39 -2 -52
1 -22 6 -23 8 -8 23 10 9 17 28 17 42 0 16 6 26 15 26 10 0 15 10 15 27 0 26
0 26 22 7 13 -10 30 -26 38 -35z m-1060 31 c0 -5 18 -10 40 -10 22 0 40 -2 40
-4 0 -16 -14 -36 -26 -36 -8 0 -14 -7 -14 -15 0 -8 -4 -15 -10 -15 -5 0 -10 5
-10 10 0 6 -4 10 -10 10 -5 0 -10 -11 -10 -25 0 -22 -4 -25 -34 -25 -44 0 -66
-9 -66 -26 0 -8 -4 -14 -10 -14 -5 0 -10 14 -10 30 0 18 -6 33 -15 36 -9 4
-15 19 -15 39 l0 33 62 0 c36 0 64 5 66 11 5 14 22 15 22 1z m2054 -20 c20 -7
26 -16 26 -40 0 -19 -5 -30 -14 -30 -8 0 -16 -7 -20 -15 -3 -8 -12 -15 -20
-15 -15 0 -26 -29 -26 -72 0 -18 -5 -28 -15 -28 -8 0 -15 -6 -15 -14 0 -8 -7
-16 -15 -20 -11 -4 -15 2 -15 24 0 34 -28 67 -65 76 -14 3 -25 10 -25 15 0 15
39 49 55 49 8 0 15 8 15 19 0 16 6 18 45 13 40 -4 45 -2 45 15 0 17 8 33 16
33 1 0 14 -4 28 -10z m402 -4 c8 -8 14 -19 14 -25 0 -6 6 -11 14 -11 8 0 17
-11 20 -25 5 -20 13 -25 36 -25 17 0 30 4 30 9 0 5 8 11 18 13 15 4 17 0 14
-29 -3 -25 -10 -35 -28 -39 -13 -3 -24 -10 -24 -15 0 -5 -9 -9 -20 -9 -14 0
-21 -9 -26 -35 -6 -33 -9 -35 -44 -35 -34 0 -38 -3 -44 -32 -3 -17 -9 -28 -13
-25 -8 8 18 120 29 128 5 3 9 32 11 64 2 51 -1 60 -17 63 -15 3 -18 0 -14 -16
3 -11 2 -26 -3 -33 -7 -11 -9 -10 -9 4 0 12 -8 17 -30 17 -20 0 -30 -5 -30
-15 0 -8 -9 -15 -20 -15 -13 0 -20 -7 -20 -19 0 -10 -7 -21 -15 -25 -9 -3 -15
0 -15 9 0 8 -7 15 -15 15 -8 0 -15 8 -15 19 0 13 9 20 30 25 17 3 30 12 30 19
0 10 8 13 25 9 15 -2 25 0 25 7 0 37 76 56 106 27z m344 -71 c0 -18 36 -20 43
-2 3 7 6 5 6 -5 1 -24 71 -26 71 -3 0 8 5 15 10 15 6 0 10 -9 10 -21 0 -18 5
-20 38 -17 l37 3 -3 -45 c-2 -25 -9 -50 -16 -55 -7 -6 -16 -20 -20 -33 -7 -22
-36 -32 -36 -12 0 6 -9 10 -20 10 -13 0 -18 5 -14 15 4 8 12 15 20 15 8 0 14
9 14 20 0 11 -4 20 -10 20 -5 0 -10 7 -10 15 0 9 -10 15 -26 15 -23 0 -25 -3
-20 -25 5 -18 1 -27 -14 -35 -11 -6 -20 -17 -20 -25 0 -8 -4 -15 -10 -15 -5 0
-10 6 -10 14 0 18 -22 26 -76 26 -38 0 -44 3 -44 21 0 19 4 20 40 14 36 -6 40
-5 40 14 0 12 6 21 15 21 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 9 -15 20
0 11 5 20 10 20 6 0 10 -7 10 -15z m2250 -25 c0 -5 11 -10 24 -10 17 0 26 -7
30 -25 4 -16 13 -25 25 -25 33 0 51 -28 49 -73 l-1 -42 -4 36 c-4 34 -6 37
-46 42 -23 3 -43 11 -45 16 -6 17 -32 13 -32 -4 0 -8 7 -15 15 -15 8 0 15 -6
15 -14 0 -8 8 -17 18 -19 9 -3 2 -5 -15 -6 -35 -1 -73 -27 -73 -49 0 -7 -7
-15 -15 -18 -8 -4 -15 -17 -15 -30 0 -14 -7 -27 -17 -31 -10 -4 -20 -10 -23
-14 -3 -4 -18 -11 -33 -14 -22 -6 -27 -4 -27 12 0 10 10 25 21 33 41 29 50 42
43 64 -4 11 -11 23 -15 24 -23 9 -5 21 34 24 39 3 42 5 45 36 2 19 9 32 17 32
8 0 16 4 20 10 10 16 -6 59 -23 63 -12 3 -10 5 6 6 12 0 22 -3 22 -9z m-2710
-20 c0 -5 6 -10 14 -10 7 0 16 -8 19 -19 3 -14 0 -17 -13 -14 -9 3 -24 -2 -32
-10 -14 -14 -15 -27 -10 -135 l2 -33 62 3 c53 3 63 0 66 -14 2 -13 -3 -18 -17
-18 -14 0 -21 -6 -21 -20 0 -13 -7 -20 -20 -20 -11 0 -20 -7 -20 -15 0 -8 -4
-15 -10 -15 -5 0 -10 7 -10 15 0 8 -3 15 -7 15 -14 -1 -43 -27 -43 -39 0 -6
-7 -11 -15 -11 -8 0 -15 5 -15 10 0 6 7 13 15 16 20 8 19 20 -5 44 -24 24 -40
26 -40 5 0 -8 -4 -15 -10 -15 -5 0 -10 -9 -10 -20 0 -11 -4 -20 -10 -20 -23 0
-4 67 21 73 10 3 19 12 19 21 0 9 7 16 16 16 12 0 14 7 9 30 -5 23 -3 30 9 30
9 0 16 6 16 14 0 16 -21 26 -55 26 -39 0 -38 27 1 34 20 4 34 13 34 21 0 8 6
15 14 15 8 0 17 11 20 25 6 24 26 36 26 15z m-1839 -90 c11 0 19 7 19 17 0 14
2 15 13 4 10 -10 8 -16 -10 -31 -13 -10 -23 -29 -23 -44 0 -14 -4 -26 -10 -26
-5 0 -10 -11 -10 -24 0 -14 -7 -31 -15 -40 -20 -19 -19 -36 2 -36 9 0 13 -3
10 -6 -4 -4 -45 -7 -91 -8 -70 -1 -87 2 -98 17 -15 20 -34 22 -41 5 -3 -7 -6
1 -6 17 0 17 3 25 7 19 4 -6 17 -14 30 -18 12 -4 22 -12 22 -17 0 -5 18 -9 40
-9 29 0 40 4 40 15 0 8 4 15 8 15 12 0 32 59 25 76 -6 16 -33 19 -33 4 0 -5
-7 -10 -15 -10 -10 0 -15 10 -15 30 0 17 5 30 10 30 14 0 12 27 -2 33 -7 3 4
3 25 0 29 -4 37 -2 37 10 0 8 11 18 25 21 14 4 26 12 27 19 1 6 3 -5 5 -25 3
-30 9 -38 24 -38z m-4358 30 c-7 -25 15 -34 52 -20 20 8 35 7 54 -1 22 -10 26
-18 26 -49 0 -34 -2 -38 -37 -47 -20 -5 -43 -6 -50 -2 -7 5 -25 9 -40 9 -30 0
-81 41 -60 48 26 8 11 31 -25 37 -57 10 -64 16 -12 10 37 -3 52 0 69 15 28 25
29 25 23 0z m5727 -40 c0 -5 8 -10 18 -11 10 0 12 -3 5 -6 -7 -2 -13 -17 -13
-33 0 -28 15 -40 51 -40 16 0 19 5 14 20 -4 11 -2 20 4 20 16 0 51 -38 51 -55
0 -9 -9 -15 -24 -15 -13 0 -26 -7 -30 -15 -3 -8 -12 -15 -21 -15 -8 0 -15 -4
-15 -10 0 -5 -7 -10 -16 -10 -9 0 -25 -10 -35 -22 -11 -11 -19 -16 -19 -10 0
7 -15 12 -32 12 -18 0 -40 5 -48 10 -12 8 -12 10 -1 10 7 0 24 11 37 26 17 18
24 21 24 10 0 -9 7 -16 15 -16 8 0 15 7 15 15 0 8 5 15 10 15 6 0 10 7 10 15
0 8 -4 15 -10 15 -5 0 -10 16 -10 35 0 26 -4 35 -17 36 -17 1 -17 2 0 6 9 2
17 9 17 14 0 5 5 9 10 9 6 0 10 -4 10 -10z m-1205 -110 c7 0 16 6 18 13 4 8 8
9 14 1 4 -6 25 -14 46 -18 l38 -7 -3 -47 -3 -47 -47 -1 c-45 -1 -78 -19 -78
-43 0 -6 -7 -11 -15 -11 -8 0 -15 7 -15 15 0 8 -9 15 -20 15 -11 0 -20 4 -20
9 0 5 -13 12 -30 16 -51 11 -37 26 34 37 11 2 24 16 29 33 11 33 30 57 35 44
2 -5 10 -9 17 -9z"/>
<path d="M16183 8755 c-7 -8 -13 -19 -13 -25 0 -6 -7 -13 -15 -16 -8 -4 -15
-15 -15 -25 0 -10 7 -19 15 -19 9 0 15 -9 15 -25 0 -14 5 -25 10 -25 6 0 10
-7 10 -15 0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 -9 15 -20 15 -11 0 -20
-7 -20 -15 0 -8 -4 -15 -10 -15 -5 0 -10 5 -10 10 0 6 -9 10 -20 10 -16 0 -20
7 -20 30 0 17 5 30 10 30 6 0 10 18 10 40 0 42 -8 47 -57 34 -18 -5 -23 -14
-23 -40 0 -19 5 -34 10 -34 6 0 10 -13 10 -30 0 -16 -4 -30 -10 -30 -5 0 -10
-16 -10 -35 0 -32 2 -35 30 -35 23 0 30 4 30 20 0 12 -7 20 -17 20 -9 0 -14 3
-11 6 3 3 25 0 48 -6 34 -9 45 -9 51 2 7 11 11 10 19 -2 15 -23 12 -30 -11
-30 -14 0 -19 -5 -17 -17 3 -13 12 -17 33 -16 31 2 47 19 41 45 -2 10 4 18 15
21 24 6 28 67 4 67 -8 0 -15 3 -15 6 0 3 7 12 15 20 8 9 15 29 15 45 0 19 -5
29 -15 29 -8 0 -15 5 -15 10 0 14 -13 12 -27 -5z"/>
<path d="M7869 19233 c0 -27 -3 -42 -6 -35 -2 6 -16 12 -29 12 -14 0 -27 7
-30 15 -8 19 -31 19 -50 1 -18 -19 -18 -44 1 -62 8 -9 15 -24 15 -35 0 -12 7
-19 19 -19 24 0 37 -17 46 -57 6 -30 10 -33 43 -33 25 0 33 -3 23 -9 -8 -5
-12 -15 -9 -23 4 -10 26 -13 87 -13 86 2 97 5 88 29 -4 11 -20 15 -51 14 -29
0 -51 5 -61 16 -9 9 -23 16 -31 16 -8 0 -14 4 -14 9 0 5 -10 11 -22 13 -18 2
-23 10 -25 38 -1 19 0 29 3 23 3 -7 14 -13 24 -13 10 0 21 6 24 13 5 10 7 10
12 0 7 -15 34 -17 34 -3 0 6 16 10 35 10 32 0 35 2 35 30 0 34 -28 67 -65 76
-14 3 -25 12 -25 20 0 9 -12 14 -35 14 -35 0 -35 0 -36 -47z"/>
<path d="M7570 19219 c0 -18 7 -38 18 -45 16 -13 16 -15 0 -28 -10 -7 -18 -19
-18 -25 0 -25 -35 -6 -38 20 -3 23 -9 27 -45 32 -22 3 -45 3 -49 1 -14 -9 -9
-34 7 -34 10 0 15 -10 15 -30 0 -16 -4 -30 -10 -30 -5 0 -10 -22 -10 -50 0
-48 1 -50 29 -50 20 0 30 -6 34 -20 7 -29 54 -28 59 2 5 32 26 33 33 1 11 -50
14 -53 40 -53 14 0 25 -4 25 -10 0 -5 10 -10 21 -10 20 0 21 4 15 43 -4 23
-11 44 -16 45 -6 2 -10 10 -10 18 0 8 -9 14 -20 14 -13 0 -20 7 -20 19 0 16 7
19 48 18 36 -2 53 3 71 20 34 32 23 48 -34 48 -29 0 -50 5 -52 13 -3 6 2 12
11 12 9 0 16 6 16 13 0 7 9 19 20 27 11 8 20 18 20 22 0 16 -35 48 -53 48 -15
0 -17 -5 -12 -30 4 -19 3 -30 -4 -30 -14 0 -51 37 -51 50 0 6 -9 10 -20 10
-16 0 -20 -7 -20 -31z"/>
<path d="M7168 18903 c-3 -41 5 -79 18 -86 20 -12 17 -27 -6 -27 -11 0 -20 -4
-20 -10 0 -5 7 -10 15 -10 9 0 18 -12 22 -30 3 -16 12 -30 19 -30 8 0 14 -4
14 -10 0 -5 6 -10 14 -10 8 0 18 -10 22 -22 8 -27 28 -48 46 -48 16 0 58 -43
58 -59 0 -6 5 -11 11 -11 6 0 4 -7 -5 -15 -8 -8 -22 -15 -30 -15 -9 0 -16 -8
-16 -20 0 -18 7 -20 54 -20 75 0 116 10 116 28 0 11 -7 13 -30 9 -16 -4 -37
-4 -45 -1 -17 7 -21 34 -5 34 6 0 10 14 10 30 0 24 4 30 21 30 19 0 21 4 15
30 -4 22 -1 32 9 36 9 4 15 19 15 41 0 32 -2 34 -27 31 -26 -3 -28 -7 -31 -55
-2 -37 -7 -53 -17 -53 -7 0 -16 -6 -18 -12 -4 -10 -6 -10 -6 0 -1 6 -8 12 -16
12 -8 0 -15 5 -15 11 0 7 -8 18 -17 25 -17 13 -17 13 0 14 18 0 25 30 7 30 -5
0 -10 6 -10 14 0 8 -6 21 -13 28 -8 7 -19 23 -26 36 -8 15 -21 22 -41 22 -31
0 -41 17 -15 26 8 4 15 15 15 25 0 14 -7 19 -25 19 -16 0 -25 6 -25 15 0 21
-40 19 -42 -2z"/>
<path d="M7788 18783 c-19 -5 -25 -43 -8 -43 6 0 10 -4 10 -10 0 -5 -7 -10
-15 -10 -20 0 -20 -44 1 -64 14 -15 13 -16 -15 -16 -20 0 -31 5 -31 14 0 8
-11 17 -25 20 -29 7 -75 -2 -75 -15 0 -5 -9 -9 -20 -9 -13 0 -20 -7 -20 -20 0
-16 7 -20 35 -20 28 0 35 4 35 20 0 13 6 20 18 19 10 0 12 -3 5 -6 -7 -2 -13
-20 -13 -39 0 -27 4 -34 20 -34 11 0 20 -4 20 -9 0 -14 35 -23 64 -15 14 3 26
12 26 19 0 7 16 16 35 19 21 4 35 13 35 21 0 22 27 18 33 -5 6 -23 29 -26 49
-6 8 7 24 17 36 20 15 5 22 15 22 32 0 20 5 24 30 24 23 0 30 4 30 20 0 22
-35 29 -43 8 -3 -9 -10 -7 -21 7 -22 29 -20 32 24 39 62 10 51 32 -20 40 -72
8 -84 3 -76 -36 6 -30 21 -50 46 -62 8 -3 4 -4 -10 -1 -14 2 -42 5 -62 5 l-37
0 -3 48 c-3 45 -5 47 -33 49 -16 0 -38 -1 -47 -4z"/>
<path d="M8040 18600 c0 -5 -9 -10 -20 -10 -13 0 -20 -7 -20 -20 0 -11 -8 -24
-17 -27 -10 -4 -24 -13 -31 -20 -19 -19 -41 -5 -44 30 -4 38 -36 51 -45 18 -3
-12 -9 -21 -14 -21 -20 0 -7 -28 16 -34 17 -4 25 -13 25 -28 0 -19 4 -20 50
-15 39 5 52 3 57 -8 6 -17 43 -21 43 -5 0 6 11 10 24 10 24 0 36 16 36 51 0
12 -7 19 -20 19 -13 0 -18 5 -14 15 4 8 12 15 20 15 8 0 14 5 14 10 0 6 -7 10
-15 10 -8 0 -15 5 -15 10 0 6 -7 10 -15 10 -8 0 -15 -4 -15 -10z"/>
<path d="M5853 18125 c-7 -8 -13 -19 -13 -25 0 -5 -7 -10 -15 -10 -8 0 -15 5
-15 10 0 6 -9 10 -20 10 -14 0 -21 -9 -26 -35 -4 -20 -12 -35 -20 -35 -15 0
-18 -45 -7 -97 7 -33 93 -44 93 -11 0 12 -39 48 -52 48 -4 0 -8 9 -8 20 0 16
7 20 30 20 25 0 30 4 30 25 0 18 5 25 20 25 11 0 20 -4 20 -10 0 -5 7 -10 15
-10 16 0 19 28 9 68 -7 25 -23 28 -41 7z"/>
<path d="M19010 18100 c0 -5 -9 -10 -20 -10 -11 0 -20 -3 -20 -6 0 -11 29 -34
44 -34 9 0 16 -7 16 -15 0 -10 10 -15 30 -15 17 0 30 5 30 13 0 9 2 9 9 -1 4
-7 4 -36 -1 -64 -5 -27 -7 -52 -5 -54 11 -12 37 11 37 32 0 14 7 27 15 30 18
7 20 30 3 37 -8 3 -6 6 5 6 21 1 23 31 2 31 -8 0 -15 7 -15 15 0 8 -9 15 -20
15 -11 0 -20 7 -20 15 0 11 -12 15 -45 15 -25 0 -45 -4 -45 -10z"/>
<path d="M19348 17613 c-10 -2 -18 -13 -18 -24 0 -16 -7 -19 -40 -19 -54 0
-90 -36 -90 -91 0 -28 4 -39 15 -39 8 0 15 7 15 15 0 8 7 15 16 15 9 0 25 10
36 22 14 16 21 19 30 10 15 -15 48 -16 48 -2 0 6 7 10 15 10 31 0 48 93 19
104 -17 6 -19 6 -46 -1z"/>
<path d="M20500 17461 c0 -20 13 -41 24 -41 8 0 12 -16 10 -49 -2 -48 -1 -50
27 -56 16 -4 29 -11 29 -16 0 -5 5 -9 10 -9 6 0 10 5 10 11 0 6 7 17 15 25 8
9 15 33 15 55 0 35 -3 39 -25 39 -22 0 -25 3 -19 25 6 24 5 25 -45 25 -28 0
-51 -4 -51 -9z"/>
<path d="M8940 17345 c0 -14 5 -25 10 -25 6 0 10 -10 10 -21 0 -19 4 -20 35
-15 l35 7 0 -41 c0 -28 4 -40 14 -40 30 0 46 13 46 36 0 34 39 48 61 23 26
-30 49 -24 49 11 0 20 -5 30 -15 30 -8 0 -15 7 -15 15 0 12 -14 15 -69 15 -46
0 -71 -4 -75 -12 -3 -9 -8 -8 -16 5 -6 9 -18 17 -25 17 -8 0 -15 5 -15 10 0 6
-7 10 -15 10 -9 0 -15 -9 -15 -25z"/>
<path d="M5100 16995 c0 -8 -7 -15 -15 -15 -9 0 -15 -9 -15 -21 0 -18 -4 -20
-27 -14 -16 3 -32 13 -36 21 -5 9 -24 13 -56 13 l-49 -2 -6 -44 c-7 -54 -3
-63 30 -63 24 0 64 -31 64 -50 0 -5 -11 -10 -25 -10 -14 0 -25 -4 -25 -9 0 -5
-22 -14 -50 -21 -42 -10 -50 -16 -50 -35 0 -26 13 -32 78 -34 42 -2 42 -2 42
34 0 26 4 35 18 36 57 4 57 4 60 37 2 17 7 32 13 32 5 0 9 14 9 31 0 27 2 29
15 16 8 -9 20 -17 26 -19 8 -3 8 -20 0 -63 -14 -73 -14 -95 -1 -95 6 0 10 7
10 16 0 11 6 14 20 9 26 -8 60 3 60 20 0 8 9 17 20 20 11 3 20 13 20 21 0 8
-9 14 -20 14 -40 0 -13 45 31 52 30 4 39 38 10 38 -12 0 -21 -4 -21 -10 0 -5
-4 -10 -10 -10 -5 0 -10 7 -10 15 0 8 -4 15 -10 15 -5 0 -10 -16 -10 -35 0
-24 -5 -35 -15 -35 -10 0 -15 10 -15 29 0 39 9 61 26 61 8 0 14 7 14 15 0 18
-26 20 -33 3 -4 -10 -6 -10 -6 0 -1 14 -31 17 -31 2 0 -5 -7 -10 -15 -10 -8 0
-15 7 -15 15 0 8 7 15 15 15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 -7 -15
-15z"/>
<path d="M19010 16898 c-11 -12 -20 -30 -20 -40 0 -10 -4 -18 -10 -18 -5 0
-10 -12 -11 -27 0 -16 -3 -22 -6 -15 -8 20 -33 14 -33 -8 0 -11 -7 -20 -15
-20 -19 0 -55 -31 -55 -47 0 -7 -7 -13 -15 -13 -8 0 -15 -7 -15 -15 0 -8 -7
-15 -15 -15 -8 0 -15 -7 -15 -15 0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 7 15
15 15 8 0 15 7 15 15 0 9 9 15 25 15 14 0 25 -4 25 -10 0 -5 -4 -10 -10 -10
-11 0 -13 -30 -4 -54 10 -26 32 -18 39 14 6 26 10 29 31 24 25 -6 34 10 12 20
-10 5 -10 7 0 12 6 3 12 14 12 25 0 12 7 19 20 19 22 0 28 20 28 90 0 24 5 46
11 48 16 5 13 32 -4 32 -8 0 -23 -10 -35 -22z"/>
<path d="M8736 16780 c-4 -26 -11 -40 -21 -40 -10 0 -15 -10 -15 -30 0 -23 4
-30 20 -30 13 0 20 -7 20 -20 0 -15 -6 -20 -22 -19 -13 1 -38 1 -55 1 l-33 -2
0 -60 c0 -49 3 -62 18 -67 24 -9 51 -42 58 -70 4 -13 12 -23 20 -23 8 0 14 -7
14 -15 0 -8 5 -15 10 -15 16 0 12 -37 -5 -44 -11 -4 -13 -16 -9 -49 6 -40 4
-45 -17 -50 -13 -4 -36 -7 -51 -7 -18 0 -29 -7 -33 -21 -9 -28 -5 -31 58 -31
61 -1 96 23 98 65 0 12 3 16 6 10 6 -17 43 -16 43 1 0 7 -11 22 -25 32 -14 10
-25 24 -25 31 0 6 -6 14 -12 16 -10 4 -10 6 0 6 6 1 12 11 12 24 0 16 3 18 9
8 11 -18 61 -6 61 14 0 8 -11 17 -24 21 -14 3 -27 12 -30 20 -3 8 -12 14 -20
14 -7 0 -16 6 -19 14 -4 9 0 12 13 9 23 -6 35 28 20 57 -9 17 -8 20 10 20 11
0 20 5 20 10 0 6 7 10 15 10 8 0 15 5 15 10 0 6 8 10 19 10 11 0 21 9 25 23
11 41 6 57 -19 67 -27 10 -34 30 -10 30 10 0 15 10 15 29 0 25 -5 30 -44 41
-25 7 -47 18 -50 26 -3 8 -16 14 -29 14 -21 0 -25 -6 -31 -40z"/>
<path d="M5130 16695 c0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -8 -4 -15
-10 -15 -5 0 -10 -6 -10 -14 0 -8 -7 -16 -15 -20 -8 -3 -15 -12 -15 -21 0 -8
-4 -15 -10 -15 -5 0 -10 -9 -10 -20 0 -15 7 -20 26 -20 17 0 28 6 31 20 3 11
12 20 20 20 8 0 17 -9 20 -20 3 -11 10 -20 15 -20 13 0 38 -26 38 -39 0 -6 9
-11 20 -11 16 0 20 7 20 30 0 19 5 30 14 30 7 0 16 10 20 23 9 34 8 37 -14 37
-11 0 -20 -4 -20 -9 0 -12 -27 -21 -44 -15 -19 7 -26 28 -15 45 8 12 10 12 18
-1 14 -21 23 -7 15 24 -3 14 -13 26 -20 26 -8 0 -17 7 -20 15 -4 8 -12 15 -20
15 -8 0 -14 -7 -14 -15z"/>
<path d="M9000 16580 c0 -6 -5 -8 -10 -5 -16 10 -50 -5 -50 -21 0 -8 4 -14 9
-14 5 0 11 -7 14 -14 4 -10 1 -13 -8 -10 -7 3 -16 12 -19 20 -4 9 -21 14 -51
14 -43 0 -45 -1 -45 -28 0 -30 38 -61 58 -48 6 3 17 -1 25 -9 9 -8 29 -15 46
-15 30 0 55 -20 33 -28 -7 -2 -12 -10 -12 -18 0 -14 -12 -16 -90 -18 -28 -1
-36 -5 -38 -23 -3 -17 2 -23 16 -23 24 0 32 -18 32 -76 0 -40 2 -44 25 -44 20
0 27 6 32 30 3 17 12 30 19 30 21 0 18 57 -3 64 -10 3 10 2 45 -1 l62 -5 0 36
c0 26 -4 36 -16 36 -12 0 -14 7 -9 29 4 21 2 32 -9 38 -9 5 -16 27 -18 58 -2
38 -7 51 -20 53 -10 2 -18 -2 -18 -8z"/>
<path d="M5360 16500 c0 -5 -7 -10 -15 -10 -21 0 -19 -32 3 -51 10 -9 32 -19
48 -23 32 -8 44 -22 44 -49 0 -31 34 -59 64 -51 30 7 35 34 6 34 -16 0 -19 5
-13 30 4 23 2 30 -11 30 -9 0 -16 7 -16 15 0 18 -26 20 -34 3 -3 -7 -4 0 -1
15 10 48 -2 67 -41 67 -19 0 -34 -4 -34 -10z"/>
<path d="M5000 16410 c0 -18 6 -33 15 -36 8 -4 15 -19 15 -34 0 -27 -2 -28
-35 -23 -33 5 -35 4 -35 -21 0 -14 -4 -26 -10 -26 -5 0 -10 -13 -10 -30 0 -20
5 -30 15 -30 8 0 15 7 15 15 0 8 4 15 9 15 5 0 12 8 14 18 4 16 5 16 6 0 0
-10 7 -18 15 -18 8 0 18 -10 22 -22 14 -47 64 -54 64 -9 0 25 -5 30 -35 35
-46 9 -49 37 -3 34 28 -3 32 1 40 31 13 48 2 102 -23 108 -10 3 -19 9 -19 14
0 5 -13 9 -30 9 -27 0 -30 -3 -30 -30z"/>
<path d="M1 16330 c-1 -66 1 -80 14 -80 8 0 15 -7 15 -16 0 -9 9 -18 20 -21
11 -3 20 -9 20 -14 0 -5 7 -9 15 -9 9 0 15 9 15 25 0 16 6 25 15 25 8 0 15 -4
15 -10 0 -5 7 -10 15 -10 8 0 15 -7 15 -15 0 -9 7 -18 15 -21 8 -4 15 -12 15
-20 0 -8 5 -14 10 -14 6 0 10 13 10 28 0 33 -33 72 -60 72 -12 0 -20 7 -20 16
0 15 -23 44 -34 44 -3 0 -6 -9 -6 -20 0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20
0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 11 -4 20 -10 20 -5 0 -10 5 -10 10 0
6 7 10 16 10 11 0 14 5 10 16 -3 9 -6 18 -6 20 0 2 -6 4 -14 4 -10 0 -14 -19
-15 -80z"/>
<path d="M8560 16365 c0 -31 5 -47 15 -51 17 -7 21 -44 5 -44 -5 0 -10 -11
-10 -25 0 -21 5 -25 30 -25 26 0 30 4 30 28 0 26 2 27 40 24 44 -4 47 0 31 56
-11 37 -33 62 -55 62 -9 0 -16 5 -16 10 0 6 -16 10 -35 10 -35 0 -35 0 -35
-45z"/>
<path d="M930 16240 c0 -20 -5 -30 -15 -30 -8 0 -15 -4 -15 -10 0 -5 -7 -10
-15 -10 -8 0 -15 -9 -15 -20 0 -11 5 -20 11 -20 6 0 3 -7 -5 -16 -23 -22 -21
-64 3 -64 20 0 31 -25 31 -72 0 -18 5 -28 15 -28 8 0 15 -4 15 -10 0 -5 7 -10
15 -10 8 0 15 7 15 15 0 8 5 15 10 15 6 0 10 -7 10 -15 0 -8 -4 -15 -10 -15
-18 0 -11 -35 10 -50 11 -8 20 -22 20 -32 0 -10 4 -18 9 -18 5 0 12 -16 16
-36 8 -43 -10 -62 -36 -38 -21 19 -43 18 -35 -2 3 -9 6 -20 6 -25 0 -5 27 -9
59 -9 43 0 60 -4 65 -15 3 -8 12 -15 21 -15 8 0 15 -7 15 -16 0 -11 -6 -14
-20 -9 -16 5 -20 2 -20 -14 0 -26 -9 -27 -26 -3 -10 13 -17 15 -31 6 -21 -13
-30 -61 -14 -77 6 -6 11 -18 11 -27 0 -16 33 -50 49 -50 5 0 12 6 15 13 5 10
7 10 12 0 8 -19 54 -16 54 2 0 8 -4 15 -10 15 -5 0 -10 16 -10 35 0 24 -5 35
-15 35 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 9 15 20 0 15 7 20 29 20 38 0
44 11 36 59 -5 31 -12 41 -32 46 -14 3 -31 1 -36 -4 -23 -23 -36 -10 -42 43
-7 50 -10 55 -36 61 -33 7 -38 22 -11 32 39 15 54 46 27 57 -8 3 -15 12 -15
21 0 8 7 15 15 15 8 0 15 7 15 15 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 11
-11 15 -40 15 l-40 0 0 40 c0 22 -4 40 -10 40 -5 0 -10 7 -10 15 0 9 -7 18
-15 21 -8 4 -15 10 -15 15 0 5 7 9 15 9 16 0 19 17 9 44 -11 28 -34 18 -34
-14z"/>
<path d="M666 16168 c4 -13 12 -26 18 -30 12 -9 -9 -28 -31 -28 -6 0 -14 -9
-18 -19 -7 -23 4 -71 16 -71 5 0 9 -7 9 -15 0 -8 7 -15 15 -15 11 0 15 12 16
43 0 32 2 37 9 22 4 -11 6 -30 3 -42 -7 -33 23 -30 31 2 4 14 11 25 16 25 22
0 8 29 -17 38 -16 6 -22 11 -15 11 14 1 17 31 2 31 -5 0 -10 5 -10 10 0 6 14
10 30 10 23 0 30 4 30 20 0 24 -26 26 -51 4 -16 -14 -18 -14 -24 5 -3 12 -13
21 -21 21 -11 0 -13 -6 -8 -22z"/>
<path d="M4553 16113 c-17 -6 -16 -31 2 -49 17 -17 9 -44 -12 -44 -11 0 -13
-7 -9 -24 4 -16 1 -26 -9 -30 -8 -3 -15 -17 -15 -31 0 -23 4 -25 40 -25 33 0
40 3 40 20 0 11 -7 20 -15 20 -8 0 -15 5 -15 10 0 6 6 10 14 10 8 0 17 10 19
23 l4 22 2 -22 c0 -13 8 -23 16 -23 8 0 15 -4 15 -10 0 -5 -4 -10 -8 -10 -5 0
-14 -15 -22 -34 -8 -22 -22 -37 -37 -41 -12 -3 -23 -13 -23 -21 0 -13 7 -15
35 -11 20 3 33 2 30 -3 -3 -4 -2 -22 1 -39 5 -24 12 -31 30 -31 17 0 24 6 24
20 0 11 7 20 15 20 8 0 15 9 15 20 0 16 7 20 36 20 43 0 64 19 64 58 0 32 -17
42 -72 42 -21 0 -28 -5 -28 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -13 -10 -30 0
-37 -27 -41 -36 -6 -7 29 1 46 21 46 19 0 13 79 -6 91 -10 6 -11 9 -1 9 6 0
12 11 12 25 0 21 -5 25 -30 25 -25 0 -30 4 -30 25 0 25 -16 31 -47 18z"/>
<path d="M0 15940 c0 -56 3 -70 15 -70 8 0 15 -4 15 -10 0 -5 7 -10 15 -10 8
0 15 5 15 10 0 14 27 12 34 -2 5 -10 7 -10 12 0 8 17 24 15 24 -3 0 -8 9 -15
20 -15 11 0 20 6 20 13 0 28 -13 57 -25 57 -8 0 -21 8 -31 17 -13 13 -19 14
-22 5 -7 -21 -22 -13 -22 11 0 29 -31 67 -54 67 -13 0 -16 -12 -16 -70z"/>
<path d="M764 15945 c-3 -19 -10 -35 -15 -35 -5 0 -9 -9 -9 -20 0 -11 7 -20
15 -20 9 0 15 -9 15 -25 0 -14 5 -25 10 -25 6 0 10 -7 10 -15 0 -8 -4 -15 -10
-15 -5 0 -10 -4 -10 -10 0 -17 25 -11 48 10 23 21 52 27 52 10 0 -6 -11 -15
-24 -20 -45 -17 -65 -90 -26 -90 11 0 20 5 20 10 0 6 7 10 15 10 8 0 15 -7 15
-15 0 -8 7 -15 16 -15 14 0 16 8 12 45 -3 25 -1 45 4 45 5 0 6 9 3 20 -5 14
-2 20 9 20 9 0 16 7 16 15 0 18 -26 20 -33 3 -4 -10 -6 -10 -6 0 -1 6 -7 12
-15 12 -8 0 -12 8 -9 23 6 29 -23 60 -48 54 -16 -4 -19 1 -19 29 0 47 -27 46
-36 -1z"/>
<path d="M595 15813 c7 -29 37 -63 55 -63 5 0 10 -14 10 -30 0 -17 6 -33 14
-36 8 -3 17 -19 20 -36 11 -57 51 -81 100 -62 24 9 21 54 -3 54 -11 0 -22 -6
-24 -12 -3 -8 -6 -6 -6 5 -1 9 -5 17 -10 17 -5 0 -12 14 -16 30 -4 17 -13 30
-20 30 -9 0 -12 6 -9 15 3 8 -6 26 -20 40 -14 14 -26 30 -26 34 0 5 -11 12
-25 15 -14 4 -25 11 -25 16 0 6 -5 10 -11 10 -7 0 -9 -10 -4 -27z"/>
<path d="M9067 15813 c-14 -13 -7 -62 11 -73 12 -8 13 -13 5 -16 -20 -9 -15
-41 7 -47 11 -3 20 -12 20 -21 0 -12 11 -16 46 -16 41 0 46 2 41 20 -3 13 1
21 14 25 24 8 27 65 4 65 -8 0 -15 5 -15 11 0 15 -42 59 -57 59 -19 0 -16 -38
4 -49 10 -5 14 -13 9 -18 -5 -4 -11 -2 -13 5 -3 6 -11 12 -19 12 -8 0 -14 10
-14 25 0 18 -5 25 -18 25 -10 0 -22 -3 -25 -7z"/>
<path d="M427 15759 c-19 -22 -22 -39 -7 -39 6 0 10 -12 10 -27 0 -44 11 -72
29 -76 14 -4 14 -5 -1 -6 -22 -1 -24 -27 -2 -35 24 -9 34 -7 34 7 0 23 25 67
38 67 6 0 12 7 12 15 0 8 5 15 10 15 6 0 10 7 10 15 0 8 7 15 15 15 8 0 15 7
15 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15 0 9 -10 15 -22 16 -13 1 -39 3 -59
4 -26 3 -40 -2 -52 -16z"/>
<path d="M170 15706 c0 -8 5 -16 11 -18 5 -2 11 -18 11 -37 1 -21 10 -42 25
-57 12 -13 23 -34 23 -47 0 -22 6 -26 45 -31 53 -8 64 -36 15 -36 -28 0 -44
-23 -21 -32 5 -1 13 -15 16 -30 7 -26 5 -28 -24 -28 -26 0 -31 4 -31 25 0 20
-5 25 -24 25 -32 0 -56 24 -49 50 3 12 -1 22 -11 26 -9 3 -16 12 -16 20 0 8
-7 14 -15 14 -8 0 -15 -7 -15 -15 0 -8 4 -15 9 -15 5 0 11 -9 14 -19 3 -14 1
-18 -9 -14 -8 3 -14 12 -14 20 0 10 -6 13 -16 9 -9 -3 -18 -6 -20 -6 -12 0 -2
-29 12 -34 10 -4 14 -15 12 -33 -3 -28 -28 -45 -28 -19 0 9 -8 16 -19 16 -14
0 -18 5 -14 20 4 14 0 20 -10 20 -31 0 -34 -113 -4 -132 8 -5 7 -8 -4 -8 -15
0 -17 -16 -18 -125 0 -114 1 -126 19 -135 11 -6 20 -15 20 -21 0 -14 26 -39
39 -39 6 0 11 14 11 30 0 17 -4 30 -10 30 -5 0 -10 7 -10 15 0 8 9 15 20 15
22 0 26 20 9 52 -6 11 -8 23 -4 26 3 3 8 -4 12 -16 3 -12 9 -22 14 -22 5 0 9
-15 9 -34 0 -44 9 -66 26 -66 8 0 14 -9 14 -20 0 -11 4 -20 8 -20 5 0 15 -26
22 -57 11 -44 11 -58 2 -61 -20 -6 -14 -38 13 -63 19 -19 25 -34 25 -71 0 -36
4 -48 15 -48 19 0 19 -14 0 -34 -8 -8 -15 -32 -15 -55 0 -36 3 -41 23 -41 38
0 67 -12 67 -26 0 -8 7 -14 15 -14 8 0 15 7 15 15 0 8 7 15 15 15 8 0 15 -7
15 -15 0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -8 -9 -15 -20 -15 -11 0
-20 -7 -20 -15 0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 -4 15 -10 15 -5 0
-10 -4 -10 -10 0 -5 -5 -10 -11 -10 -14 0 -39 25 -39 39 0 6 -6 11 -14 11 -8
0 -17 11 -20 25 -5 21 -12 25 -41 25 -33 0 -35 2 -29 25 5 21 3 25 -14 25 -22
0 -38 17 -27 28 3 3 9 1 13 -5 4 -6 25 -13 46 -15 36 -3 40 -1 48 27 11 40 -1
135 -17 135 -14 0 -25 31 -25 73 0 23 -4 27 -30 27 -16 0 -30 -4 -30 -10 0 -5
-7 -10 -15 -10 -16 0 -21 26 -7 32 12 4 23 68 12 68 -5 0 -19 -4 -33 -9 -26
-10 -41 -4 -50 19 -3 8 -6 -59 -6 -150 -1 -154 0 -167 19 -183 11 -9 24 -17
29 -17 5 0 11 -16 13 -35 3 -24 0 -35 -9 -35 -18 0 -16 -27 2 -34 8 -3 15 -10
15 -16 0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 -9 10 -20 10 -19 0 -20 -7
-20 -92 0 -85 2 -95 24 -115 13 -13 29 -23 35 -23 6 0 11 -4 11 -10 0 -5 -7
-10 -15 -10 -8 0 -15 5 -15 10 0 6 -9 10 -20 10 -13 0 -20 -7 -20 -20 0 -13 7
-20 20 -20 15 0 20 -7 20 -25 0 -19 5 -25 23 -25 17 0 26 -9 36 -35 7 -19 16
-35 22 -35 17 0 49 34 49 52 0 10 6 18 14 18 8 0 17 8 19 18 4 15 5 15 6 0 1
-18 34 -33 50 -24 5 3 7 -3 4 -14 -4 -14 0 -20 11 -20 9 0 16 7 16 15 0 8 5
15 10 15 19 0 10 29 -10 35 -13 4 -23 18 -27 36 -4 24 -10 29 -34 29 -30 0
-33 8 -23 48 4 15 14 22 30 22 15 0 24 6 24 15 0 8 -7 15 -15 15 -8 0 -15 7
-15 15 0 10 -10 15 -31 15 -28 0 -29 1 -15 16 9 8 16 20 16 25 0 5 7 9 15 9
10 0 15 -10 15 -26 0 -21 4 -25 20 -21 11 3 20 0 20 -5 0 -6 7 -16 16 -22 24
-18 7 -46 -27 -46 -30 0 -34 -6 -23 -34 9 -22 54 -21 55 2 0 13 2 12 9 -5 6
-16 18 -23 35 -23 27 0 35 24 10 34 -23 9 -19 35 5 29 11 -3 20 0 20 6 0 6 -7
11 -15 11 -8 0 -15 4 -15 9 0 5 -3 16 -6 24 -3 9 3 16 15 20 12 3 21 12 21 21
0 11 9 16 29 16 24 0 30 -5 34 -30 4 -16 11 -30 17 -30 17 0 40 26 40 45 0 19
-23 45 -39 45 -6 0 -11 11 -11 24 0 37 -10 56 -31 56 -10 0 -19 7 -19 15 0 11
7 14 25 9 21 -5 25 -3 25 14 0 11 -7 28 -16 36 -8 9 -11 16 -5 16 6 0 11 9 11
20 0 11 -6 20 -14 20 -8 0 -16 7 -19 14 -4 11 -1 13 11 9 22 -9 32 -23 32 -50
0 -14 6 -23 15 -23 8 0 15 9 15 19 0 11 5 21 12 23 18 6 58 -3 58 -13 0 -5 7
-9 15 -9 8 0 15 -9 15 -20 0 -11 5 -20 10 -20 6 0 10 -9 10 -21 0 -15 4 -19
16 -15 9 3 18 6 20 6 10 0 3 29 -8 32 -17 6 -18 128 0 128 21 0 15 58 -8 65
-11 3 -20 13 -20 21 0 8 -4 14 -10 14 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0
10 18 10 40 0 33 3 40 20 40 22 0 27 25 8 34 -10 5 -10 7 0 12 6 3 12 12 12
20 0 8 7 14 15 14 9 0 15 -9 15 -25 0 -28 24 -34 34 -9 10 27 7 42 -11 47 -16
4 -17 5 -1 6 23 1 37 69 17 82 -29 18 -39 10 -39 -36 0 -41 -2 -45 -24 -45
-17 0 -27 -8 -34 -27 -6 -16 -11 -21 -11 -12 -1 9 -8 19 -16 23 -8 3 -15 15
-15 26 0 31 -42 26 -58 -7 -18 -41 -15 -74 8 -80 11 -3 20 -10 20 -17 1 -6 3
-44 6 -83 5 -70 4 -73 -16 -73 -13 0 -24 8 -27 20 -4 14 -14 20 -34 20 -26 0
-29 4 -29 30 0 20 -5 30 -15 30 -9 0 -12 6 -9 15 4 8 17 15 30 15 13 0 24 5
24 10 0 6 -16 10 -35 10 -33 0 -35 2 -29 25 4 18 2 25 -9 25 -8 0 -18 11 -21
25 -4 14 -13 25 -21 25 -11 0 -15 -12 -15 -50 0 -39 4 -52 20 -60 11 -6 20
-17 20 -25 0 -8 5 -15 10 -15 6 0 10 -13 10 -30 0 -16 5 -41 12 -55 6 -14 8
-28 3 -33 -4 -4 -10 -1 -12 6 -3 6 -14 12 -26 12 -24 1 -85 56 -74 67 4 3 7 1
7 -5 0 -23 30 -12 36 13 3 14 10 25 15 25 23 0 6 29 -18 32 -26 3 -28 6 -25
41 2 32 0 37 -18 37 -11 0 -20 -7 -20 -15 0 -8 5 -15 12 -15 6 0 9 -3 5 -6 -3
-3 -15 -3 -26 1 -25 8 -30 55 -6 55 8 0 15 5 15 11 0 6 -8 9 -18 7 -15 -4 -17
0 -14 27 2 20 -2 38 -13 48 -9 10 -11 17 -5 17 6 0 13 -7 16 -15 7 -17 34 -21
34 -5 0 6 9 10 20 10 15 0 20 -7 20 -25 0 -16 6 -25 15 -25 10 0 15 11 15 35
0 19 5 35 10 35 16 0 12 19 -10 42 -15 17 -26 20 -56 15 -21 -4 -41 -12 -46
-19 -6 -10 -8 -10 -8 0 0 6 -6 12 -12 12 -14 0 -18 63 -6 75 11 12 47 -5 53
-26 8 -24 35 -25 35 0 0 11 9 21 23 25 33 9 37 8 37 -9 0 -18 36 -20 43 -2 4
7 6 6 6 -4 1 -16 20 -29 42 -29 5 0 9 11 9 25 0 14 6 25 13 25 29 0 55 -13 60
-29 3 -14 15 -18 51 -18 25 -1 46 3 46 8 0 5 -4 9 -9 9 -5 0 -12 11 -15 24 -6
24 -30 36 -73 36 -25 0 -31 15 -10 23 8 3 5 11 -10 24 -13 11 -23 25 -23 31 0
12 -24 22 -56 22 -20 0 -24 5 -24 29 0 16 6 31 15 35 8 3 15 17 15 31 0 20 -5
25 -26 25 -23 0 -25 -3 -20 -25 5 -20 3 -25 -14 -25 -11 0 -20 7 -20 15 0 8
-4 15 -10 15 -5 0 -10 7 -10 15 0 9 -10 15 -22 16 -17 1 -19 2 -5 6 21 6 24
53 2 53 -8 0 -15 -7 -15 -15 0 -10 -10 -15 -30 -15 -28 0 -30 3 -30 35 0 22
-6 38 -15 41 -8 4 -15 12 -15 20 0 8 -9 14 -20 14 -11 0 -20 -6 -20 -14z"/>
<path d="M19162 15696 c4 -18 1 -26 -8 -26 -8 0 -14 -7 -14 -15 0 -8 5 -15 10
-15 6 0 10 -11 10 -25 0 -14 -4 -25 -10 -25 -5 0 -10 5 -10 10 0 17 -18 11
-45 -15 -14 -14 -25 -35 -25 -45 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0
-13 7 -20 20 -20 13 0 20 -7 20 -20 0 -11 7 -20 15 -20 12 0 15 9 13 38 -3 35
-1 37 28 40 24 2 33 -1 36 -16 5 -19 -21 -52 -42 -52 -6 0 -10 -9 -10 -21 0
-18 4 -20 25 -15 18 5 27 2 31 -9 7 -17 34 -21 34 -5 0 19 28 10 33 -11 5 -18
11 -20 33 -15 24 6 25 5 11 -9 -9 -8 -29 -15 -46 -15 -24 0 -31 -4 -31 -20 0
-11 5 -20 11 -20 7 0 17 -7 23 -14 16 -22 58 -28 79 -12 10 8 21 33 24 55 3
23 9 41 13 41 4 0 11 16 14 35 4 19 10 35 15 35 11 0 31 46 31 70 0 24 -6 25
-26 5 -9 -8 -28 -15 -44 -15 -20 0 -29 -6 -34 -22 -9 -34 -8 -38 10 -38 14 0
14 -2 0 -15 -8 -8 -21 -15 -29 -15 -8 0 -17 7 -21 15 -3 8 -17 15 -31 15 -42
0 -28 19 18 22 36 3 43 7 45 27 4 23 3 23 -53 16 -55 -6 -56 -6 -45 14 6 12
15 21 20 21 6 0 10 9 10 20 0 23 12 25 40 8 15 -10 22 -10 32 0 18 18 28 15
28 -8 0 -27 26 -25 34 3 9 35 7 47 -9 47 -8 0 -15 4 -15 9 0 5 -29 12 -64 16
-64 6 -65 7 -68 38 -2 25 -8 33 -27 35 -21 3 -23 0 -19 -22z"/>
<path d="M1270 15650 c0 -29 4 -40 15 -40 17 0 19 -9 6 -28 -7 -12 -12 -12
-30 -1 -11 8 -21 19 -21 26 0 7 -9 13 -20 13 -16 0 -20 -7 -20 -35 0 -24 -5
-35 -15 -35 -8 0 -15 -9 -15 -20 0 -11 4 -20 9 -20 5 0 13 -9 16 -21 5 -14 13
-19 26 -16 14 4 19 0 19 -14 0 -10 -6 -19 -14 -19 -8 0 -17 -6 -19 -12 -3 -8
-6 -6 -6 5 -1 9 -8 17 -16 17 -8 0 -15 5 -15 10 0 14 -43 12 -59 -2 -11 -10
-14 -10 -18 0 -6 16 -51 15 -69 -2 -16 -16 -19 -36 -5 -36 5 0 12 -11 15 -25
4 -14 11 -25 16 -25 6 0 10 -11 10 -25 0 -35 28 -33 32 2 3 30 38 47 38 19 0
-8 8 -20 18 -26 12 -8 13 -13 5 -16 -23 -10 -14 -34 12 -34 23 0 25 4 26 43 2
34 3 36 6 12 6 -37 20 -55 43 -55 13 0 20 -11 25 -40 4 -22 11 -40 16 -40 5 0
9 -7 9 -15 0 -22 29 -18 56 8 27 25 32 67 9 67 -8 0 -15 -4 -15 -10 0 -5 -9
-10 -20 -10 -15 0 -22 9 -26 30 -4 17 -10 30 -15 30 -5 0 -9 9 -9 19 0 11 -7
27 -16 35 -8 9 -11 16 -5 16 6 0 11 7 11 15 0 26 27 16 40 -15 15 -36 60 -44
60 -10 0 11 -4 20 -10 20 -5 0 -10 6 -10 14 0 7 -11 25 -25 40 -14 15 -25 37
-25 49 1 22 1 22 12 2 16 -27 34 -45 47 -45 15 0 14 37 -1 44 -10 5 -10 7 0
12 14 7 16 34 2 34 -5 0 -10 16 -10 35 0 26 -4 35 -17 36 -11 0 -13 3 -5 6 14
5 17 33 3 33 -5 0 -11 9 -14 20 -3 11 -12 20 -21 20 -12 0 -16 -10 -16 -40z"/>
<path d="M840 15655 c0 -8 5 -15 10 -15 6 0 10 -7 10 -15 0 -8 -7 -15 -15 -15
-8 0 -15 -4 -15 -9 0 -5 -9 -13 -20 -16 -11 -3 -20 -15 -20 -26 0 -10 -5 -19
-11 -19 -15 0 -39 -26 -39 -43 0 -8 7 -17 15 -21 25 -9 17 -26 -14 -26 -21 0
-30 -6 -35 -22 -9 -36 -8 -38 24 -49 17 -6 30 -18 30 -26 0 -12 8 -14 38 -10
20 3 29 3 20 1 -19 -6 -25 -34 -9 -34 11 0 61 -44 61 -54 0 -3 -9 -6 -20 -6
-16 0 -20 -7 -20 -30 0 -16 -4 -30 -10 -30 -5 0 -10 -4 -10 -10 0 -5 11 -10
25 -10 14 0 25 5 25 10 0 7 6 7 19 0 27 -14 31 -13 31 10 0 13 -7 20 -20 20
-11 0 -20 7 -20 15 0 8 7 15 15 15 8 0 15 7 15 15 0 8 9 15 20 15 11 0 20 5
20 10 0 6 -7 10 -15 10 -9 0 -15 10 -15 26 0 26 -20 54 -39 54 -6 0 -11 -4
-11 -10 0 -5 -4 -10 -10 -10 -5 0 -10 7 -10 15 0 8 7 15 15 15 8 0 15 7 15 15
0 8 7 15 15 15 8 0 15 -4 15 -10 0 -5 6 -10 14 -10 8 0 16 16 20 40 3 22 11
40 16 40 6 0 10 10 10 23 0 21 -5 24 -50 25 -47 1 -50 -1 -50 -24 0 -13 -4
-24 -10 -24 -5 0 -10 -7 -10 -15 0 -8 -9 -15 -19 -15 -11 0 -22 -6 -24 -12 -4
-10 -6 -10 -6 0 -1 8 8 16 19 19 11 3 21 13 22 22 1 9 2 23 4 31 1 8 2 27 3
43 1 28 31 40 31 12 0 -11 11 -15 36 -15 28 0 35 3 32 18 -2 10 -12 17 -26 16
-23 -1 -30 12 -11 18 8 3 10 16 5 41 -6 33 -10 37 -36 37 -20 0 -30 -5 -30
-15z"/>
<path d="M4810 15526 c0 -13 -3 -31 -6 -40 -5 -13 1 -16 25 -16 17 0 31 -4 31
-9 0 -5 3 -16 6 -25 4 -10 1 -16 -9 -16 -8 0 -17 7 -21 15 -3 9 -18 15 -36 15
-27 0 -30 -3 -30 -30 0 -18 -6 -33 -15 -36 -8 -4 -15 -12 -15 -20 0 -8 -7 -14
-15 -14 -8 0 -15 7 -15 15 0 8 -4 15 -10 15 -5 0 -10 -7 -10 -15 0 -8 5 -15
10 -15 6 0 10 -15 10 -32 l0 -33 -50 3 c-32 3 -50 0 -50 -7 0 -6 5 -11 10 -11
6 0 10 -4 10 -10 0 -5 -6 -10 -13 -10 -17 0 -47 -36 -47 -56 0 -13 6 -15 27
-9 29 7 43 21 43 43 0 6 11 12 25 12 16 0 25 -6 25 -15 0 -11 11 -15 36 -15
27 0 37 5 41 20 6 23 23 27 23 5 0 -8 5 -15 11 -15 7 0 9 10 5 25 -7 28 20 50
45 34 11 -7 11 -9 -3 -9 -10 0 -18 -7 -18 -16 0 -12 6 -15 23 -10 12 3 25 6
30 6 4 0 7 7 7 15 0 8 5 15 10 15 6 0 10 14 10 30 0 17 5 30 10 30 14 0 12 28
-2 33 -7 2 -10 8 -6 12 4 4 14 2 22 -4 21 -18 70 -10 74 12 2 12 -3 17 -17 17
-12 0 -21 6 -21 15 0 8 -7 15 -15 15 -9 0 -18 7 -21 15 -4 8 -14 15 -23 16 -9
0 -1 5 17 10 35 10 40 23 18 45 -19 18 -36 18 -36 -1 0 -8 -4 -15 -10 -15 -5
0 -10 6 -10 14 0 16 -21 26 -56 26 -19 0 -24 -5 -24 -24z"/>
<path d="M1100 15267 c0 -45 53 -78 63 -39 2 9 2 2 0 -17 -4 -30 -2 -33 16
-28 12 3 21 1 21 -3 0 -5 14 -22 31 -39 33 -33 79 -42 79 -15 0 32 -20 54 -50
54 -29 0 -30 1 -27 48 l2 47 -35 1 c-19 0 -50 4 -67 8 -31 7 -33 6 -33 -17z"/>
<path d="M4576 15064 c-17 -17 -19 -35 -10 -82 4 -22 13 -32 32 -37 15 -4 29
-11 33 -16 3 -5 22 -12 42 -16 30 -4 37 -3 37 11 0 9 -4 16 -10 16 -5 0 -7 7
-4 15 4 8 12 15 20 15 8 0 14 5 14 10 0 6 -13 10 -30 10 -16 0 -30 4 -30 9 0
5 -8 12 -17 14 -17 4 -17 5 0 6 9 0 17 8 17 16 0 8 7 15 15 15 8 0 15 7 15 15
0 17 -2 18 -52 6 -22 -5 -38 -5 -38 0 0 14 -17 11 -34 -7z"/>
<path d="M4894 15040 c-4 -16 -10 -30 -15 -30 -5 0 -9 -16 -9 -35 0 -24 -5
-35 -15 -35 -19 0 -19 -17 -1 -36 19 -19 34 -18 39 4 4 14 5 15 6 1 0 -9 11
-19 23 -23 22 -5 22 -6 3 -20 -30 -22 -13 -28 49 -21 l56 7 0 44 c0 31 -4 44
-14 44 -8 0 -16 -4 -18 -8 -1 -5 -15 -12 -29 -15 -33 -8 -39 9 -32 93 5 57 5
60 -16 60 -16 0 -23 -8 -27 -30z"/>
<path d="M830 14907 c0 -8 -13 -20 -30 -27 -31 -13 -43 -53 -17 -63 6 -3 -1
-6 -16 -6 -32 -1 -57 -21 -57 -45 0 -9 7 -16 15 -16 8 0 15 -7 15 -16 0 -8 7
-22 15 -30 8 -9 15 -24 15 -35 0 -17 31 -33 56 -28 21 4 34 -2 34 -16 0 -10
-10 -15 -30 -15 -16 0 -30 -4 -30 -10 0 -5 -7 -10 -15 -10 -10 0 -15 10 -15
30 0 27 -3 30 -30 30 -23 0 -30 -4 -30 -20 0 -11 5 -20 10 -20 18 0 10 -17
-12 -27 -25 -10 -72 -10 -155 2 -37 6 -53 4 -53 -4 0 -6 4 -11 9 -11 6 0 6
-12 0 -32 -12 -43 -11 -58 2 -58 15 0 59 42 59 58 0 16 17 16 27 -1 4 -8 16
-16 26 -20 9 -3 17 -13 17 -21 0 -19 48 -30 66 -15 11 9 21 9 44 -1 25 -10 32
-10 45 4 9 9 21 14 26 11 11 -7 12 -45 2 -45 -9 0 -33 -49 -33 -67 0 -21 38
-15 45 7 3 11 11 20 16 20 5 0 9 13 9 28 0 17 11 41 25 58 16 18 24 36 20 49
-2 11 -5 44 -5 73 0 39 -4 52 -15 52 -8 0 -15 9 -15 20 0 11 7 20 15 20 10 0
17 14 21 40 4 22 10 40 15 40 5 0 9 7 9 15 0 10 10 15 30 15 20 0 30 5 30 15
0 8 5 15 10 15 6 0 10 9 10 20 0 13 -7 20 -20 20 -11 0 -20 -4 -20 -10 0 -5
-9 -10 -20 -10 -11 0 -20 -7 -20 -16 0 -11 -8 -14 -32 -12 -30 3 -33 6 -33 38
0 25 -5 36 -17 38 -11 2 -18 -3 -18 -11z"/>
<path d="M19060 14800 c0 -5 -7 -10 -15 -10 -8 0 -15 -9 -15 -20 0 -13 -7 -20
-20 -20 -15 0 -20 -7 -20 -29 0 -22 -6 -30 -25 -35 -27 -7 -35 -36 -10 -36 8
0 15 -6 15 -14 0 -8 7 -16 15 -20 8 -3 15 -12 15 -21 0 -8 7 -15 15 -15 8 0
15 -9 15 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -12 -11 -27 0 -16 -3 -22 -6 -15
-7 18 -31 15 -36 -5 -4 -17 -5 -17 -6 0 0 9 -5 17 -11 17 -5 0 -10 7 -10 15 0
8 -9 15 -20 15 -15 0 -20 -7 -20 -25 0 -14 -7 -28 -15 -31 -8 -3 -17 -21 -21
-40 -3 -19 -10 -34 -15 -34 -5 0 -9 -9 -9 -20 0 -11 7 -20 15 -20 21 0 19 -19
-2 -27 -10 -3 -30 -20 -45 -36 -15 -16 -42 -32 -60 -36 -27 -5 -34 -12 -36
-34 -3 -27 -2 -28 35 -21 21 4 41 11 44 16 4 6 33 11 65 13 33 1 62 3 67 4 4
0 7 17 7 36 0 24 -5 35 -15 35 -8 0 -15 6 -15 14 0 18 27 46 43 46 14 0 40 28
50 53 3 9 13 17 21 17 8 0 17 13 21 29 3 16 13 32 21 35 19 7 19 66 -1 66 -9
0 -15 9 -15 23 0 12 -3 31 -6 42 -4 17 1 22 33 31 45 12 73 8 73 -11 0 -7 6
-16 13 -18 10 -4 10 -6 0 -6 -19 -1 -16 -42 5 -57 10 -8 44 -13 80 -14 69 0
100 -16 75 -38 -7 -7 -13 -21 -13 -31 0 -10 -9 -21 -20 -24 -11 -3 -20 -12
-20 -21 0 -9 8 -16 20 -16 13 0 20 7 20 20 0 15 7 20 29 20 21 0 30 6 35 23
10 37 7 75 -6 80 -7 2 -1 2 15 0 37 -6 47 21 17 47 -13 11 -29 20 -37 20 -7 0
-13 7 -13 15 0 22 -40 19 -41 -2 0 -11 -3 -13 -6 -5 -2 6 -15 12 -28 12 -13 0
-26 -6 -28 -12 -3 -7 -6 -3 -6 10 -1 12 5 22 13 22 13 0 26 20 26 41 0 5 -7 9
-15 9 -9 0 -15 9 -15 25 0 18 -5 25 -19 25 -24 0 -30 -17 -26 -71 3 -24 2 -36
-1 -26 -6 20 -30 23 -38 5 -5 -10 -7 -10 -12 0 -3 6 -12 12 -19 12 -7 0 -16
14 -20 30 -4 18 -13 30 -23 31 -11 0 -12 2 -4 6 17 7 15 33 -3 33 -8 0 -15 -4
-15 -10z"/>
<path d="M9563 14730 c-3 -11 -11 -20 -18 -20 -7 0 -16 -6 -18 -12 -4 -10 -7
-10 -14 0 -5 7 -39 15 -76 19 -37 3 -67 10 -67 14 0 5 -9 9 -21 9 -16 0 -20
-5 -17 -27 2 -21 9 -29 28 -31 14 -2 31 -11 38 -20 9 -13 28 -17 71 -17 51 0
61 -4 88 -30 l30 -30 -24 -20 c-23 -20 -31 -45 -14 -45 5 0 11 -9 14 -20 3
-13 13 -19 29 -19 12 1 35 -2 51 -6 17 -5 27 -3 27 4 0 6 7 11 15 11 30 0 14
72 -21 90 -13 8 -24 17 -24 22 0 17 29 38 59 44 29 6 31 9 31 50 0 37 -3 44
-20 44 -11 0 -20 -4 -20 -10 0 -5 -20 -10 -45 -10 -33 0 -45 4 -45 15 0 22
-31 18 -37 -5z"/>
<path d="M590 14480 c0 -5 -7 -10 -15 -10 -8 0 -15 -7 -15 -15 0 -8 -4 -15
-10 -15 -5 0 -10 -13 -10 -30 0 -23 -4 -30 -20 -30 -11 0 -20 7 -20 15 0 8 -7
15 -15 15 -8 0 -15 -7 -15 -15 0 -8 7 -15 15 -15 8 0 15 -6 15 -14 0 -8 11
-17 25 -20 14 -4 34 -15 45 -26 24 -24 27 -55 4 -46 -9 3 -19 6 -24 6 -4 0
-10 13 -13 29 -7 32 -33 43 -78 34 -25 -5 -29 -10 -29 -39 0 -24 -5 -34 -16
-34 -13 0 -15 -11 -9 -72 3 -40 11 -76 18 -80 25 -16 37 -8 38 25 0 17 3 26 6
20 2 -7 11 -13 19 -13 8 0 14 -6 14 -13 0 -7 9 -20 20 -30 15 -13 20 -29 20
-66 0 -47 2 -50 28 -53 16 -2 31 -12 37 -25 5 -13 13 -23 18 -23 13 0 37 -27
37 -42 0 -7 12 -24 25 -37 17 -16 25 -34 25 -57 0 -19 5 -34 10 -34 6 0 10
-22 10 -50 0 -44 -2 -50 -21 -50 -18 0 -20 -4 -15 -25 5 -21 3 -25 -15 -25
-19 0 -20 -3 -14 -52 4 -29 11 -55 14 -58 4 -3 10 -13 14 -22 3 -10 13 -18 22
-18 9 0 18 -12 22 -30 3 -16 9 -30 14 -30 5 0 9 -7 9 -15 0 -8 -4 -15 -10 -15
-16 0 -12 -18 10 -40 25 -25 70 -27 70 -4 0 12 6 15 23 10 12 -3 28 -6 35 -6
6 0 12 -4 12 -10 0 -5 -16 -10 -36 -10 -36 0 -36 -1 -30 -32 4 -18 11 -34 16
-36 6 -2 10 -21 10 -43 0 -28 -4 -39 -15 -39 -8 0 -15 -7 -15 -15 0 -8 6 -15
14 -15 8 0 17 -7 20 -16 10 -27 7 -54 -8 -54 -8 0 -17 -6 -19 -12 -3 -8 -6 -6
-6 4 -1 10 -12 20 -26 24 -30 8 -46 -3 -25 -17 11 -6 12 -11 3 -17 -6 -4 -15
-20 -18 -35 -6 -20 -4 -27 8 -27 8 0 18 6 20 13 3 6 6 -3 6 -21 1 -25 6 -35
21 -39 11 -3 20 -12 20 -20 0 -11 7 -13 25 -9 22 6 25 3 25 -19 0 -22 4 -25
35 -25 30 0 35 3 35 24 0 14 7 31 15 40 8 8 15 19 15 25 0 6 5 11 10 11 6 0
10 9 10 20 0 11 -4 20 -10 20 -5 0 -10 7 -10 15 0 9 9 15 25 15 14 0 25 -4 25
-10 0 -5 7 -10 15 -10 25 0 19 49 -8 60 -17 7 -18 9 -4 9 11 1 17 9 17 26 0
21 -5 25 -29 25 -26 0 -29 -4 -35 -45 -4 -25 -11 -45 -16 -45 -15 0 -50 33
-50 46 0 7 -7 14 -15 18 -8 3 -15 17 -15 31 0 14 7 28 15 31 8 4 15 12 15 19
0 7 14 15 30 19 30 6 41 29 18 40 -10 5 -10 7 0 12 22 10 14 41 -13 48 -14 4
-25 13 -25 21 0 8 -9 15 -20 15 -11 0 -20 7 -20 15 0 8 7 15 15 15 8 0 15 9
15 20 0 24 -31 27 -54 5 -22 -22 -36 -18 -36 10 0 13 -6 28 -14 32 -23 13 -31
62 -12 69 24 9 21 54 -3 54 -11 0 -22 -6 -25 -12 -5 -10 -7 -10 -12 0 -11 24
-34 13 -34 -18 0 -16 5 -30 10 -30 15 0 12 -17 -5 -24 -12 -4 -15 5 -15 49 l0
55 35 0 c41 0 46 19 14 49 -13 12 -19 26 -16 35 9 22 24 20 32 -4 5 -15 16
-20 43 -22 20 -1 44 -2 52 -4 8 -1 21 -2 28 -3 20 -2 13 -21 -8 -21 -11 0 -20
-7 -20 -15 0 -8 9 -15 20 -15 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 16 0 20 7
20 30 0 30 14 40 24 18 5 -10 7 -10 12 0 9 20 81 15 104 -8 11 -11 29 -20 40
-20 13 0 20 7 20 20 0 11 7 20 15 20 11 0 15 11 15 40 0 29 -4 40 -15 40 -8 0
-15 -8 -15 -19 0 -23 -36 -34 -54 -16 -7 8 -11 25 -9 38 3 17 -2 32 -16 46
-26 26 -26 31 -1 31 11 0 20 -4 20 -10 0 -5 14 -10 30 -10 20 0 30 5 30 15 0
8 9 15 19 15 15 0 19 7 19 35 0 32 -2 35 -21 29 -25 -8 -41 -23 -50 -46 -10
-29 -24 -21 -31 18 -5 25 -12 35 -24 33 -52 -5 -55 -1 -50 49 6 54 2 72 -18
72 -8 0 -14 4 -14 9 0 5 -22 8 -50 8 -46 -1 -50 -3 -50 -25 0 -22 3 -24 35
-18 29 6 34 4 29 -9 -3 -8 -12 -15 -20 -15 -8 0 -14 -7 -14 -15 0 -8 -3 -15
-6 -15 -9 0 -34 28 -34 38 0 10 -23 22 -41 22 -26 0 -1 -50 31 -63 9 -3 10 -6
3 -6 -20 -1 -15 -49 7 -71 11 -11 33 -20 50 -20 25 0 30 -4 29 -22 -1 -17 -2
-19 -6 -5 -5 19 -28 23 -38 7 -7 -11 -35 12 -35 29 0 6 -6 11 -14 11 -9 0 -14
-12 -14 -35 0 -35 14 -58 40 -68 18 -6 8 -14 -29 -22 -32 -6 -33 -5 -33 24 0
26 -4 31 -25 31 -20 0 -25 5 -25 25 0 18 5 25 20 25 11 0 20 7 20 15 0 8 -9
15 -20 15 -16 0 -20 7 -20 35 0 19 -4 35 -9 35 -5 0 -13 10 -17 23 -4 12 -12
26 -18 30 -6 4 1 5 17 1 23 -5 27 -3 27 15 0 18 6 21 40 21 33 0 40 3 40 20 0
11 5 20 10 20 6 0 10 11 10 25 0 14 -4 25 -10 25 -5 0 -10 9 -10 21 0 12 -8
23 -20 26 -11 3 -20 9 -20 15 0 5 -9 18 -19 30 -20 21 -18 48 3 48 13 0 46 35
46 50 0 5 -11 12 -25 16 -14 3 -25 12 -25 20 0 8 -4 14 -10 14 -5 0 -10 -4
-10 -10 0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 -7 10 -16 10 -13 0 -15 -7
-10 -32 7 -39 -8 -72 -23 -49 -5 7 -12 41 -15 74 -7 57 -22 87 -45 87 -11 0
-15 12 -12 43 2 12 -5 17 -23 17 -14 0 -26 5 -26 10 0 6 5 10 10 10 6 0 10 5
10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
<path d="M5253 14483 c-7 -2 -13 -12 -14 -21 0 -11 -2 -12 -6 -4 -6 16 -33 16
-33 -1 0 -9 -10 -12 -30 -9 -22 3 -30 0 -30 -11 0 -9 13 -18 30 -22 21 -5 30
-13 30 -26 0 -10 7 -19 15 -19 8 0 15 9 15 20 0 11 -5 20 -12 20 -6 0 -9 3 -5
6 11 12 45 -7 51 -29 7 -30 -3 -67 -20 -67 -8 0 -14 -9 -14 -20 0 -11 7 -20
15 -20 8 0 15 9 15 20 0 11 6 20 14 20 8 0 20 14 28 30 7 17 16 30 20 30 5 0
8 7 8 15 0 8 -4 15 -8 15 -5 0 -13 17 -18 37 -9 36 -25 48 -51 36z"/>
<path d="M5090 14443 c-8 -3 -19 -12 -23 -19 -4 -8 -22 -14 -38 -14 -28 0 -30
-2 -27 -32 3 -29 7 -33 36 -36 29 -3 32 -6 26 -25 -4 -12 -17 -30 -30 -40 -24
-18 -34 -67 -14 -67 6 0 10 -9 10 -20 0 -16 -7 -20 -31 -20 -30 0 -59 -19 -59
-38 0 -13 44 -24 66 -15 45 17 74 83 42 96 -10 4 -10 6 0 6 27 2 44 33 53 99
5 37 14 69 19 70 6 2 10 17 10 33 0 29 -9 34 -40 22z"/>
<path d="M9110 14312 c0 -4 5 -13 12 -20 9 -9 9 -12 0 -12 -23 0 -12 -30 13
-36 32 -8 32 -24 1 -24 -14 0 -31 -6 -38 -14 -8 -7 -24 -17 -36 -20 -31 -10
-27 -33 7 -41 16 -3 32 -13 35 -21 7 -19 36 -18 36 1 0 8 5 15 10 15 6 0 10 7
10 15 0 9 9 15 23 15 45 0 60 21 52 73 -4 26 -11 47 -16 47 -5 0 -9 7 -9 15 0
11 -12 15 -50 15 -27 0 -50 -4 -50 -8z"/>
<path d="M9310 14100 c0 -5 -8 -10 -17 -10 -23 0 -87 -23 -94 -34 -6 -7 -5
-54 0 -98 1 -5 8 -8 17 -8 14 0 14 -1 0 -16 -26 -26 -20 -44 17 -45 17 0 26
-3 20 -6 -7 -2 -13 -19 -14 -36 0 -18 -3 -26 -6 -19 -3 7 -23 12 -49 12 -42 0
-44 -1 -44 -30 0 -23 5 -31 25 -36 14 -3 25 -10 25 -15 0 -5 9 -9 20 -9 11 0
20 5 20 10 0 6 6 10 14 10 8 0 18 9 21 20 6 19 35 29 35 12 0 -13 36 -52 48
-52 26 0 23 54 -4 64 -21 8 -17 26 6 26 11 0 20 -4 20 -10 0 -5 7 -10 15 -10
13 0 15 -7 9 -35 -6 -35 -6 -35 32 -36 22 0 32 -3 24 -6 -24 -10 -37 -27 -44
-60 -6 -26 -4 -31 16 -37 35 -9 35 -9 42 34 6 36 9 40 36 40 23 0 31 5 35 23
4 13 5 27 4 33 -1 5 -1 21 0 37 1 23 5 27 29 27 31 0 62 30 62 61 0 18 -4 20
-31 14 -25 -6 -31 -4 -36 14 -3 12 -9 21 -14 21 -5 0 -9 7 -9 15 0 10 -11 15
-35 15 -35 0 -50 -22 -22 -33 6 -3 0 -6 -15 -6 -19 -1 -28 4 -28 14 0 14 -16
20 -51 17 -20 -2 -31 44 -22 88 4 23 2 30 -11 30 -9 0 -16 5 -16 10 0 6 -7 10
-15 10 -8 0 -15 -4 -15 -10z"/>
<path d="M5100 14060 c0 -11 -7 -20 -15 -20 -8 0 -15 -7 -15 -15 0 -8 7 -15
15 -15 8 0 15 -4 15 -10 0 -16 -27 -12 -34 5 -3 8 -12 15 -20 15 -8 0 -21 -7
-30 -15 -21 -22 -26 -19 -26 16 0 27 -3 30 -27 27 -22 -2 -29 -9 -31 -31 -3
-22 1 -30 17 -34 12 -3 21 -12 21 -19 0 -8 9 -14 20 -14 11 0 20 5 20 10 0 6
5 10 10 10 6 0 10 -7 10 -15 0 -8 7 -15 15 -15 8 0 15 6 15 13 0 7 22 19 49
26 52 13 66 30 55 65 -12 39 -64 52 -64 16z"/>
<path d="M4997 13743 c-4 -3 -7 -19 -7 -35 0 -22 5 -28 23 -29 17 -1 18 -2 4
-6 -13 -3 -17 -12 -14 -31 2 -15 2 -21 0 -14 -6 17 -30 15 -37 -3 -3 -8 -12
-15 -21 -15 -8 0 -15 -7 -15 -15 0 -11 -8 -13 -30 -8 -27 5 -30 3 -30 -21 0
-30 26 -36 33 -8 3 10 5 6 6 -10 1 -18 7 -28 16 -28 8 0 15 5 15 10 0 6 5 10
10 10 6 0 10 -7 10 -15 0 -8 -4 -15 -10 -15 -5 0 -10 -13 -10 -29 0 -25 5 -31
30 -36 17 -4 30 -11 30 -16 0 -5 6 -9 14 -9 14 0 29 28 25 46 -5 29 3 84 12
84 21 0 36 27 42 78 8 67 2 82 -34 82 -22 0 -29 5 -29 20 0 19 -20 27 -33 13z"/>
<path d="M4513 13298 c0 -13 6 -27 12 -31 7 -6 6 -10 -2 -14 -7 -2 -13 -11
-13 -18 0 -8 -9 -17 -20 -20 -11 -3 -20 -10 -20 -14 0 -13 -36 -51 -49 -51 -6
0 -11 -6 -11 -14 0 -16 34 -31 50 -21 5 3 10 1 10 -6 0 -16 31 -38 53 -39 12
0 17 8 17 25 0 14 5 25 11 25 19 0 41 30 44 63 2 17 6 47 10 65 5 21 4 32 -4
32 -6 0 -11 6 -11 14 0 13 -29 26 -62 26 -11 0 -16 -7 -15 -22z"/>
<path d="M5230 13143 c-24 -9 -30 -25 -23 -65 5 -33 3 -38 -15 -38 -22 0 -32
-18 -32 -60 0 -16 -4 -30 -10 -32 -5 -1 -13 -18 -16 -37 l-7 -34 58 7 c58 7
58 6 41 -13 -17 -19 -17 -21 -2 -36 9 -9 16 -23 16 -31 0 -8 7 -14 15 -14 11
0 15 11 15 39 0 25 5 41 15 45 10 4 15 20 15 51 0 49 17 60 46 30 22 -22 64
-20 64 3 0 10 -6 24 -12 31 -10 11 -10 14 0 18 12 5 18 63 6 63 -8 0 -30 -24
-37 -40 -3 -9 -6 -10 -6 -2 -1 6 -8 12 -16 12 -8 0 -15 7 -15 15 0 8 -9 15
-20 15 -11 0 -20 -4 -20 -10 0 -5 -11 -10 -25 -10 -14 0 -25 5 -25 10 0 6 7
10 15 10 8 0 15 5 15 10 0 6 -9 10 -21 10 -30 0 -18 24 14 28 33 4 37 32 5 31
-13 0 -30 -3 -38 -6z"/>
<path d="M5700 12945 c0 -16 -6 -25 -15 -25 -8 0 -15 -9 -15 -20 0 -11 -7 -23
-15 -26 -8 -4 -15 -12 -15 -20 0 -8 -9 -14 -19 -14 -11 0 -22 -6 -24 -12 -3
-8 -6 -6 -6 5 -1 21 -31 23 -31 2 0 -8 -4 -15 -10 -15 -21 0 -9 -28 15 -34 27
-7 35 -36 10 -36 -8 0 -15 5 -15 10 0 6 -14 10 -31 10 -29 0 -31 -2 -24 -27 3
-16 11 -29 16 -31 5 -2 9 -11 9 -19 0 -9 -6 -13 -15 -9 -23 8 -45 -4 -45 -25
0 -10 7 -19 15 -19 8 0 15 -4 15 -10 0 -5 7 -10 16 -10 18 0 44 23 44 39 0 6
7 11 15 11 8 0 15 -4 15 -10 0 -5 9 -10 20 -10 11 0 20 7 20 15 0 8 -8 15 -17
15 -26 0 -26 60 -2 81 11 9 19 24 19 33 0 9 8 20 18 23 9 4 20 11 24 17 4 6
11 7 15 2 4 -5 2 -11 -4 -13 -32 -11 3 -55 36 -46 30 8 30 60 -1 65 -19 3 -20
5 -7 18 9 8 19 37 22 63 6 42 5 47 -13 47 -15 0 -20 -7 -20 -25z"/>
<path d="M5490 12911 c0 -28 -1 -29 -42 -24 -35 5 -40 4 -35 -10 3 -9 -1 -21
-11 -29 -13 -10 -14 -10 -8 0 11 19 -2 62 -19 62 -8 0 -15 -7 -15 -15 0 -9 -9
-15 -25 -15 -21 0 -25 -5 -25 -31 0 -37 20 -59 54 -59 18 0 25 -6 28 -22 2
-15 11 -24 26 -26 12 -2 22 -8 22 -13 0 -5 7 -9 15 -9 10 0 15 11 15 35 0 19
4 35 9 35 5 0 14 14 20 30 5 17 17 30 25 30 9 0 16 7 16 15 0 8 5 15 10 15 6
0 10 7 10 15 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 9 -9 15 -25 15 -21 0 -25
-5 -25 -29z"/>
<path d="M7946 12865 c-8 -8 -20 -15 -26 -15 -6 0 -13 -6 -16 -14 -3 -8 -18
-16 -35 -20 -17 -3 -29 -12 -29 -21 0 -21 24 -45 44 -45 9 0 16 -8 16 -18 0
-10 5 -23 12 -30 9 -9 8 -12 -5 -12 -13 0 -17 -9 -17 -35 0 -28 4 -35 19 -35
11 0 22 8 24 18 4 16 5 16 6 0 0 -10 8 -18 16 -18 17 0 9 -31 -12 -43 -20 -12
-16 -50 7 -70 24 -20 37 -22 43 -4 4 10 7 10 18 0 18 -18 49 -16 49 2 0 8 -7
15 -16 15 -12 0 -15 6 -10 23 3 12 6 25 6 29 0 4 12 6 27 5 22 -2 29 3 31 21
2 15 -2 22 -12 22 -28 0 -17 17 14 24 32 6 39 28 16 51 -7 8 -35 15 -62 17
l-49 3 -1 50 c-1 100 -22 136 -58 100z"/>
<path d="M8400 12600 c0 -5 -6 -10 -14 -10 -17 0 -32 -87 -21 -121 13 -39 41
-62 68 -55 36 9 53 90 22 102 -8 4 -15 10 -15 15 0 5 7 9 15 9 8 0 15 -7 15
-15 0 -9 9 -15 21 -15 17 0 20 4 15 23 -3 12 -6 25 -6 29 0 3 -13 9 -30 13
-16 4 -30 13 -30 21 0 8 -9 14 -20 14 -11 0 -20 -4 -20 -10z"/>
<path d="M8560 12465 c0 -16 6 -25 15 -25 16 0 18 -9 15 -47 -1 -13 3 -23 9
-23 6 0 11 -4 11 -10 0 -15 33 -50 47 -50 7 0 13 -4 13 -10 0 -5 7 -10 15 -10
9 0 15 9 15 25 0 15 -6 25 -14 25 -14 0 -26 36 -18 58 2 7 12 12 23 12 23 0
27 44 5 66 -18 17 -82 19 -89 2 -4 -10 -6 -10 -6 0 -1 6 -10 12 -21 12 -15 0
-20 -7 -20 -25z"/>
<path d="M8590 12006 c0 -8 -6 -16 -13 -19 -9 -3 -14 -25 -14 -67 -1 -58 -11
-89 -20 -62 -3 6 -13 12 -24 12 -14 0 -19 -7 -19 -25 0 -22 4 -25 35 -25 19 0
35 5 35 10 0 21 19 8 29 -20 5 -16 15 -30 20 -30 6 0 11 -7 11 -15 0 -8 7 -15
15 -15 8 0 15 4 15 9 0 5 18 12 40 16 25 4 40 12 40 21 0 8 7 14 15 14 8 0 15
7 15 15 0 10 -11 15 -35 15 -19 0 -35 -4 -35 -10 0 -5 -9 -10 -20 -10 -15 0
-20 7 -20 25 0 14 -4 25 -10 25 -5 0 -10 5 -10 10 0 6 13 10 29 10 17 0 31 6
35 16 10 26 7 32 -16 36 -17 2 -24 11 -26 33 -2 16 -9 33 -15 37 -20 13 -57 9
-57 -6z"/>
<path d="M9248 11713 c-10 -2 -18 -13 -18 -24 0 -12 -7 -19 -20 -19 -22 0 -25
-12 -11 -38 8 -15 4 -17 -28 -14 -25 3 -43 -2 -54 -12 -17 -18 -47 -21 -47 -6
0 6 -12 10 -28 10 -23 0 -29 -6 -40 -40 -6 -22 -12 -48 -12 -58 0 -21 43 -62
65 -62 8 0 15 -7 15 -15 0 -10 10 -15 30 -15 25 0 30 4 30 25 0 14 -4 25 -10
25 -5 0 -10 15 -10 34 0 29 4 35 27 41 15 3 31 11 35 17 3 6 27 11 52 10 27
-1 46 2 46 9 0 6 9 8 21 4 12 -4 41 -4 64 0 49 7 65 30 43 64 -18 29 -38 26
-38 -4 0 -14 -2 -25 -5 -25 -15 0 -55 56 -55 76 0 24 -12 28 -52 17z"/>
<path d="M8636 9625 c-3 -8 -12 -15 -21 -15 -8 0 -15 -6 -15 -13 0 -9 -10 -12
-30 -9 -16 2 -30 2 -30 -2 0 -12 35 -46 48 -46 7 0 20 -7 28 -15 20 -19 44
-19 44 0 0 13 2 14 14 3 8 -7 10 -14 5 -16 -5 -2 -9 -8 -9 -13 0 -15 39 -49
55 -49 8 0 15 -7 15 -15 0 -8 -6 -15 -14 -15 -15 0 -27 -35 -19 -55 6 -17 56
-29 83 -20 14 5 20 2 20 -10 0 -29 27 -45 74 -45 34 0 46 4 46 15 0 10 -10 15
-30 15 -16 0 -30 5 -30 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -16 18 -12 40
10 22 22 26 40 10 40 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 16 10 35 0 19
-4 35 -10 35 -5 0 -10 7 -10 15 0 18 -26 20 -33 3 -3 -7 -6 2 -6 20 -1 29 -4
32 -31 32 -16 0 -30 5 -30 10 0 6 -9 10 -20 10 -11 0 -20 -7 -20 -15 0 -22
-17 -18 -30 6 -9 16 -18 19 -37 14 -19 -4 -26 -1 -30 15 -3 11 -9 20 -14 20
-5 0 -9 7 -9 15 0 10 -10 15 -34 15 -21 0 -36 -6 -40 -15z"/>
<path d="M8003 8979 c-15 -15 -17 -55 -3 -64 6 -3 10 -19 10 -36 0 -25 4 -29
29 -29 16 0 32 6 35 13 5 10 7 10 12 0 12 -27 24 -11 24 32 0 25 5 45 10 45 6
0 10 -4 10 -10 0 -5 9 -10 20 -10 20 0 26 16 12 36 -4 7 -29 14 -57 17 -27 2
-59 7 -70 11 -11 4 -25 2 -32 -5z"/>
<path d="M19028 8983 c-10 -2 -18 -13 -18 -24 0 -15 -7 -19 -35 -19 -24 0 -35
-5 -35 -15 0 -8 5 -15 10 -15 6 0 10 -9 10 -20 0 -22 25 -28 33 -7 4 9 8 9 16
-2 8 -12 14 -13 36 -3 22 10 26 17 21 37 -5 21 -3 25 15 25 18 0 20 -4 14 -27
-7 -29 -21 -43 -42 -43 -8 0 -13 -13 -13 -30 0 -20 5 -30 15 -30 22 0 18 -17
-5 -23 -12 -3 -20 -14 -20 -26 0 -12 -4 -21 -10 -21 -5 0 -10 -7 -10 -15 0 -9
9 -15 25 -15 14 0 25 -4 25 -10 0 -5 -7 -10 -15 -10 -15 0 -20 -26 -9 -54 8
-22 74 -22 74 -1 0 8 -9 15 -20 15 -11 0 -20 6 -20 14 0 8 9 18 20 21 29 9 28
55 -2 55 l-21 0 21 19 c28 24 29 61 2 61 -11 0 -20 7 -20 15 0 8 9 15 20 15
16 0 20 7 20 30 0 25 4 30 25 30 16 0 25 6 25 15 0 10 -10 15 -30 15 -23 0
-30 5 -30 19 0 16 -30 34 -50 30 -3 -1 -13 -3 -22 -6z"/>
<path d="M5094 8765 c-4 -8 -12 -15 -20 -15 -8 0 -14 -9 -14 -20 0 -17 -7 -20
-44 -20 -37 0 -45 -4 -50 -22 -9 -34 -8 -38 9 -38 8 0 15 7 15 15 0 8 4 15 9
15 9 0 41 -27 41 -34 0 -9 -51 -56 -60 -56 -5 0 -10 -5 -10 -11 0 -7 14 -9 43
-5 23 4 61 7 84 6 l43 0 -3 49 c-3 37 0 48 10 44 7 -3 13 -9 13 -14 0 -5 9 -9
20 -9 13 0 20 7 20 20 0 15 -7 20 -29 20 -17 0 -31 6 -35 16 -10 25 -7 34 9
34 8 0 15 9 15 20 0 16 -7 20 -30 20 -18 0 -33 -6 -36 -15z"/>
<path d="M7930 8750 c0 -20 -5 -30 -15 -30 -8 0 -15 5 -15 10 0 6 -9 10 -20
10 -11 0 -20 -7 -20 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -7 -15 -15 0 -11 10
-13 53 -8 28 3 54 10 55 15 5 11 32 10 32 -2 0 -5 7 -10 16 -10 9 0 18 -9 21
-19 3 -11 16 -22 29 -25 26 -7 33 -26 9 -26 -8 0 -15 -6 -15 -14 0 -8 -13 -20
-30 -28 -35 -15 -36 -21 -13 -48 22 -25 48 -26 70 0 10 11 23 20 30 20 7 0 13
5 13 10 0 6 14 10 30 10 27 0 30 3 30 30 0 41 -49 90 -90 90 -16 0 -30 5 -30
10 0 6 7 10 15 10 9 0 15 9 15 21 0 18 -5 20 -27 16 -33 -6 -53 1 -53 19 0 9
-11 14 -30 14 -27 0 -30 -3 -30 -30z"/>
<path d="M20460 8660 c0 -5 -11 -12 -25 -16 -19 -5 -25 -13 -25 -35 0 -19 5
-29 15 -29 10 0 15 -10 15 -30 0 -21 -5 -30 -16 -30 -22 0 -12 -23 14 -35 12
-5 22 -15 22 -22 0 -13 54 -28 70 -18 16 10 12 33 -7 44 -12 7 -14 13 -7 18 6
5 15 38 18 76 7 57 5 67 -8 67 -9 0 -16 5 -16 10 0 6 -11 10 -25 10 -14 0 -25
-4 -25 -10z"/>
<path d="M19336 8615 c-4 -19 -11 -35 -15 -35 -5 0 -12 -15 -16 -32 -4 -18 -9
-27 -12 -20 -8 22 -33 13 -33 -12 0 -13 -6 -26 -12 -28 -9 -3 -8 -9 4 -21 21
-21 38 -22 38 -2 0 8 5 15 10 15 6 0 10 -13 10 -30 0 -25 4 -30 24 -30 19 0
24 6 29 35 7 43 -2 107 -17 117 -5 4 2 10 17 13 22 6 27 13 27 36 0 24 -4 29
-24 29 -19 0 -25 -7 -30 -35z"/>
<path d="M19510 8530 c0 -11 -5 -20 -11 -20 -7 0 -10 -14 -7 -35 3 -29 1 -35
-14 -35 -11 0 -18 7 -18 20 0 16 -7 20 -35 20 -24 0 -35 -5 -35 -15 0 -8 -4
-15 -10 -15 -5 0 -10 -9 -10 -20 0 -11 5 -20 10 -20 6 0 10 -7 10 -15 0 -8 -7
-15 -15 -15 -8 0 -15 -7 -15 -15 0 -19 37 -20 56 -1 8 8 14 22 14 30 0 9 8 16
20 16 11 0 20 -7 20 -15 0 -23 60 -20 61 3 0 10 3 12 6 5 7 -19 63 -17 63 2 0
8 -7 15 -15 15 -8 0 -15 3 -15 8 -5 70 -12 107 -22 114 -22 14 -38 9 -38 -12z"/>
<path d="M11693 8443 c-16 -3 -23 -11 -23 -28 0 -34 -18 -41 -41 -16 -26 27
-59 28 -59 1 0 -11 5 -20 10 -20 6 0 10 -13 10 -30 0 -16 5 -30 10 -30 6 0 10
5 10 10 0 15 19 12 36 -5 9 -8 31 -15 50 -15 33 0 46 -13 22 -23 -10 -4 -10
-8 -2 -14 6 -4 14 -18 18 -30 4 -11 18 -24 31 -27 14 -4 25 -11 25 -16 0 -15
47 -12 54 3 5 10 7 10 12 0 3 -7 15 -13 26 -13 18 0 20 5 15 41 -3 23 -11 46
-19 50 -10 7 -10 9 0 9 6 0 12 12 12 28 0 15 4 22 8 16 4 -6 15 -13 25 -17 9
-3 17 -13 17 -22 0 -8 5 -15 10 -15 6 0 10 -7 10 -15 0 -9 9 -15 21 -15 18 0
21 5 17 35 -2 19 0 35 4 35 4 0 8 11 8 24 0 18 -8 27 -31 35 -18 6 -40 11 -50
11 -11 0 -19 5 -19 10 0 6 -16 10 -35 10 -27 0 -35 -4 -35 -18 0 -9 -9 -23
-19 -29 -17 -11 -21 -9 -26 7 -4 11 -11 20 -16 20 -5 0 -9 11 -9 24 0 21 -28
40 -50 35 -3 -1 -15 -4 -27 -6z"/>
<path d="M5214 8305 c4 -11 -2 -15 -25 -15 -26 0 -30 -3 -25 -22 3 -13 3 -17
0 -10 -8 17 -39 15 -54 -3 -7 -8 -19 -15 -26 -15 -8 0 -14 -7 -14 -15 0 -8 -7
-15 -15 -15 -8 0 -15 -7 -15 -15 0 -23 47 -19 53 5 6 23 39 27 59 7 7 -8 20
-16 28 -20 12 -4 11 -6 -2 -6 -29 -1 -22 -31 7 -31 16 0 25 6 25 15 0 8 4 15
9 15 5 0 11 13 14 30 5 21 14 31 32 36 25 6 37 44 14 44 -6 0 -17 7 -25 15 -9
8 -22 15 -31 15 -9 0 -13 -6 -9 -15z"/>
<path d="M20633 8236 c-35 -35 -43 -66 -18 -66 10 0 15 -16 17 -62 3 -62 4
-63 31 -61 21 1 27 -3 27 -18 0 -10 7 -19 15 -19 10 0 15 10 15 30 0 17 -4 30
-9 30 -5 0 -13 10 -17 23 -4 12 -12 26 -18 30 -8 6 -8 10 2 14 6 2 12 13 12
23 0 10 -6 21 -12 23 -10 4 -10 6 0 6 25 2 44 30 38 56 -9 35 -44 31 -83 -9z"/>
<path d="M19540 8225 c0 -8 -7 -15 -15 -15 -21 0 -19 -28 3 -33 12 -3 11 -5
-6 -6 -21 -1 -23 -5 -17 -31 6 -27 4 -30 -19 -30 -16 0 -26 -6 -26 -15 0 -9 9
-15 25 -15 14 0 25 4 25 10 0 5 7 7 15 4 9 -4 15 0 15 10 0 9 5 16 10 16 6 0
10 -25 10 -55 0 -48 3 -55 20 -55 14 0 21 9 26 37 5 25 3 38 -5 41 -6 2 -10
18 -10 35 1 24 3 26 6 10 8 -41 33 -27 33 17 0 22 -4 40 -10 40 -5 0 -10 5
-10 11 0 17 -27 39 -49 39 -12 0 -21 -6 -21 -15z"/>
<path d="M7503 8177 c-18 -6 -33 -15 -33 -19 0 -4 -6 -8 -13 -8 -14 0 -47 -35
-47 -49 0 -5 -5 -11 -10 -13 -14 -4 11 -68 27 -68 7 0 13 5 13 10 0 14 47 13
52 -2 5 -14 48 21 48 39 0 7 11 15 25 19 28 7 27 4 29 62 2 41 1 42 -28 41
-17 -1 -46 -7 -63 -12z"/>
<path d="M5278 8173 c-10 -2 -18 -9 -18 -14 0 -5 -7 -9 -16 -9 -12 0 -15 -6
-11 -21 5 -18 2 -20 -19 -15 -20 5 -24 3 -24 -14 0 -29 -33 -62 -54 -54 -24 9
-66 -4 -66 -21 0 -8 7 -15 15 -15 8 0 15 -4 15 -10 0 -5 -13 -10 -30 -10 -34
0 -37 -5 -16 -26 17 -16 56 -19 56 -4 0 6 7 10 15 10 8 0 15 -7 15 -16 0 -14
8 -15 58 -9 31 4 59 11 60 16 2 5 10 9 18 9 8 0 14 10 14 26 0 23 -3 26 -27
20 -16 -3 -22 -3 -15 0 17 8 15 34 -3 34 -8 0 -15 5 -15 10 0 6 16 10 36 10
19 0 42 6 50 14 19 20 18 82 -2 90 -9 3 -16 5 -17 5 -1 -1 -10 -3 -19 -6z"/>
<path d="M11460 8125 c0 -11 -7 -14 -25 -9 -21 5 -25 3 -25 -15 0 -12 5 -21
10 -21 6 0 10 -7 10 -15 0 -9 6 -18 14 -21 18 -7 27 -42 19 -72 -4 -16 -13
-22 -33 -22 -24 0 -30 -5 -35 -32 -5 -21 -4 -33 4 -36 6 -2 11 -10 11 -18 0
-9 11 -14 30 -14 17 0 30 5 30 10 0 6 6 10 14 10 8 0 16 6 19 13 3 10 9 10 26
0 31 -19 59 -16 66 7 5 16 15 20 51 20 24 0 44 -4 44 -10 0 -16 37 -12 44 5 3
8 17 15 31 15 20 0 25 5 25 25 0 15 -6 25 -14 25 -7 0 -28 3 -45 6 -24 5 -31
3 -31 -9 0 -9 -5 -19 -10 -22 -16 -10 -50 5 -50 21 0 8 -9 14 -19 14 -35 0
-51 12 -51 36 0 13 5 24 10 24 24 0 7 31 -20 37 -16 3 -30 12 -30 19 0 8 -9
14 -20 14 -11 0 -20 7 -20 15 0 8 -7 15 -15 15 -8 0 -15 -7 -15 -15z"/>
<path d="M19024 8082 c-6 -4 -14 -15 -17 -26 -6 -15 -14 -17 -42 -12 -28 6
-35 4 -35 -9 0 -8 7 -15 15 -15 8 0 15 -6 15 -14 0 -19 17 -21 155 -21 106 0
120 2 123 18 3 14 -4 17 -33 17 -38 0 -65 18 -65 44 0 24 -27 19 -35 -6 -7
-22 -7 -22 -12 -3 -6 23 -21 35 -43 35 -8 0 -20 -4 -26 -8z"/>
<path d="M5537 8043 c-4 -3 -7 -17 -7 -30 0 -14 -6 -23 -15 -23 -23 0 -19 -66
5 -90 11 -11 20 -22 20 -25 0 -3 -9 -5 -20 -5 -11 0 -20 -7 -20 -15 0 -8 -6
-15 -14 -15 -8 0 -18 -10 -22 -22 -4 -13 -12 -26 -18 -30 -20 -13 4 -21 29
-10 17 8 25 20 25 37 0 16 6 25 15 25 8 0 15 -4 15 -10 0 -17 28 -11 34 8 3 9
5 51 4 92 -1 47 3 75 11 78 6 2 11 12 11 23 0 13 -7 19 -23 19 -13 0 -27 -3
-30 -7z"/>
<path d="M7690 7836 c0 -17 -7 -19 -58 -16 -34 2 -52 -20 -35 -43 15 -18 4
-87 -13 -87 -17 0 -18 -17 -1 -23 6 -2 8 -8 4 -13 -5 -5 -13 -1 -18 9 -14 25
-59 23 -59 -3 0 -11 4 -20 9 -20 5 0 12 -11 15 -25 7 -26 36 -35 36 -11 0 10
11 12 45 8 37 -4 45 -2 45 12 0 9 -4 16 -9 16 -5 0 -11 34 -13 75 -2 55 0 75
10 75 6 0 12 -9 12 -20 0 -17 7 -20 40 -20 38 0 40 -2 40 -30 0 -28 -3 -30
-35 -30 -39 0 -42 -4 -21 -26 20 -20 44 -17 51 5 4 12 14 19 28 18 12 0 41 0
65 1 38 2 42 5 42 27 0 16 6 25 15 25 19 0 20 29 1 36 -8 3 -16 18 -20 35 -3
16 -10 29 -16 29 -14 0 -40 -25 -40 -38 0 -10 -71 -25 -79 -17 -2 3 3 5 12 5
35 0 11 41 -31 54 -16 6 -22 3 -22 -8z"/>
<path d="M19240 7755 c0 -8 -9 -15 -19 -15 -12 0 -23 -9 -27 -22 -4 -13 -11
-26 -17 -29 -6 -4 -1 -16 12 -30 22 -24 28 -49 11 -49 -5 0 -10 9 -10 20 0 12
-7 20 -16 20 -14 0 -16 -8 -10 -46 3 -26 6 -57 6 -70 0 -15 6 -24 15 -24 8 0
15 -4 15 -10 0 -5 -9 -10 -21 -10 -16 0 -19 -4 -14 -21 5 -15 2 -22 -14 -26
-12 -3 -21 -9 -21 -14 0 -5 -9 -9 -20 -9 -11 0 -20 5 -20 10 0 6 -7 10 -16 10
-13 0 -15 7 -10 35 6 31 4 35 -14 35 -11 0 -20 -7 -20 -15 0 -10 -10 -15 -30
-15 -23 0 -30 -5 -30 -19 0 -11 6 -22 13 -25 10 -5 10 -7 0 -12 -25 -11 -14
-34 17 -34 25 0 30 4 30 25 0 17 -6 25 -17 25 -9 0 -14 2 -11 5 3 4 17 3 32
-1 19 -4 27 -14 32 -40 3 -19 12 -34 19 -34 24 0 45 12 45 26 0 8 9 14 20 14
11 0 20 -7 20 -15 0 -8 7 -15 16 -15 13 0 15 7 11 30 -4 17 -2 30 3 30 6 0 10
9 10 20 0 11 5 20 10 20 6 0 10 6 10 13 0 14 35 47 50 47 6 0 10 9 10 20 0 11
-4 20 -10 20 -5 0 -10 7 -10 15 0 8 5 15 10 15 24 0 7 29 -21 35 -21 5 -29 11
-25 22 3 8 6 28 6 44 0 19 5 29 15 29 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M5570 7665 c0 -7 6 -16 13 -18 7 -3 4 -6 -7 -6 -14 -1 -17 -6 -12
-27 5 -20 3 -27 -14 -31 -11 -3 -20 -12 -20 -19 0 -16 26 -19 33 -4 3 6 4 5 3
-2 -1 -7 -15 -14 -29 -16 -29 -4 -36 -29 -15 -50 9 -9 7 -12 -11 -12 -19 0
-22 -4 -17 -27 5 -27 9 -94 7 -120 -1 -7 -10 -13 -20 -13 -16 0 -19 6 -15 42
5 51 -3 78 -22 78 -9 0 -14 -11 -14 -30 0 -22 -5 -30 -17 -30 -26 0 -52 20
-35 27 6 2 12 11 12 19 0 10 -14 14 -50 14 -47 0 -50 -2 -50 -25 0 -14 5 -25
10 -25 6 0 8 -11 4 -25 -5 -19 -2 -25 10 -25 9 0 16 7 16 15 0 8 7 15 15 15
17 0 21 -16 5 -22 -5 -1 -13 -24 -16 -50 -6 -40 -4 -48 10 -48 9 0 16 8 16 20
0 11 5 20 11 20 14 0 39 25 39 39 0 20 19 11 26 -11 4 -13 11 -26 17 -30 6 -4
4 -12 -6 -23 -10 -9 -17 -22 -17 -30 0 -16 -22 -25 -65 -25 -18 0 -35 -4 -37
-8 -1 -5 -15 -12 -30 -16 -27 -6 -28 -5 -28 29 0 28 -4 35 -20 35 -16 0 -20
-7 -20 -35 0 -24 -5 -35 -15 -35 -10 0 -15 -10 -15 -30 0 -23 5 -30 19 -30 11
0 21 4 23 9 2 4 13 12 26 15 16 6 22 3 22 -8 0 -9 -4 -16 -10 -16 -5 0 -10 -6
-10 -14 0 -15 23 -27 43 -22 6 1 9 1 5 -1 -15 -9 -8 -43 8 -43 11 0 14 -5 10
-16 -3 -9 -6 -20 -6 -25 0 -5 -7 -9 -15 -9 -8 0 -15 -9 -15 -20 0 -13 7 -20
20 -20 11 0 20 7 20 15 0 8 5 15 10 15 6 0 10 9 10 19 0 11 7 26 15 35 8 8 15
17 15 21 0 10 28 35 39 35 6 0 11 11 11 25 0 18 -5 25 -18 25 -15 0 -18 6 -14
29 3 20 8 28 18 24 8 -3 14 0 14 6 0 6 7 11 15 11 10 0 15 10 15 29 0 16 6 31
15 35 11 4 15 -2 15 -24 0 -27 3 -30 30 -30 27 0 30 3 30 30 0 20 -5 30 -15
30 -21 0 -7 28 18 36 42 13 60 47 31 58 -25 10 -34 7 -34 -9 0 -8 -4 -15 -9
-15 -15 0 -21 29 -15 74 6 43 7 44 51 51 33 5 47 12 50 26 6 21 20 24 42 10
12 -8 12 -12 -1 -25 -9 -9 -18 -20 -20 -26 -2 -6 -21 -13 -43 -17 -22 -3 -43
-11 -47 -17 -12 -18 -9 -36 6 -36 8 0 17 6 19 13 3 7 6 5 6 -5 1 -10 9 -19 19
-20 9 -1 24 -2 32 -4 8 -1 21 -2 28 -3 17 -1 15 -31 -3 -31 -8 0 -15 -7 -15
-16 0 -12 6 -15 23 -10 12 3 28 6 35 6 7 0 12 14 12 34 0 19 -6 37 -12 39 -20
8 12 27 45 27 17 0 27 5 27 15 0 8 -4 15 -10 15 -23 0 -7 21 20 27 21 4 30 11
30 24 0 11 -4 19 -10 19 -5 0 -10 10 -10 23 0 49 -11 67 -41 67 -16 0 -29 -4
-29 -10 0 -5 -9 -2 -20 7 -11 10 -20 28 -20 40 0 13 -6 24 -12 24 -85 8 -118
6 -118 -6z"/>
<path d="M7860 7565 c0 -18 5 -25 20 -25 32 0 23 -18 -12 -26 -18 -3 -40 -15
-49 -25 -9 -10 -19 -16 -22 -13 -3 4 -18 4 -32 0 -18 -5 -25 -13 -25 -31 0
-20 5 -25 25 -25 16 0 25 -6 25 -15 0 -8 5 -15 10 -15 6 0 10 5 10 10 0 15 19
12 36 -5 16 -15 69 -20 98 -9 17 7 22 34 6 34 -5 0 -10 11 -10 25 0 14 5 25
10 25 6 0 10 -4 10 -10 0 -5 9 -10 20 -10 13 0 20 7 20 20 0 11 -6 20 -14 20
-16 0 -26 21 -26 56 0 20 -5 24 -30 24 -16 0 -30 5 -30 10 0 6 -9 10 -20 10
-15 0 -20 -7 -20 -25z"/>
<path d="M7393 7343 c-16 -3 -23 -11 -23 -29 0 -20 5 -24 30 -24 23 0 30 -5
30 -19 0 -20 -18 -31 -51 -31 -20 0 -24 -16 -11 -37 4 -7 32 -17 62 -23 44 -8
57 -8 69 5 12 11 12 19 3 40 -6 14 -18 25 -27 25 -21 0 -19 17 5 30 11 6 20
21 20 34 0 18 -7 25 -31 30 -33 6 -39 6 -76 -1z"/>
<path d="M7003 7237 c-4 -8 -15 -16 -24 -19 -15 -6 -18 -36 -10 -115 2 -18 31
-16 31 2 0 10 11 15 35 15 26 0 33 -3 29 -15 -4 -8 -10 -15 -15 -15 -5 0 -9
-11 -9 -25 0 -14 4 -25 9 -25 21 0 41 13 41 26 0 8 9 14 20 14 16 0 20 7 20
30 0 17 -4 30 -10 30 -23 0 -8 29 20 39 40 13 42 46 3 36 -16 -3 -30 -11 -31
-16 -2 -5 -16 4 -30 21 -28 31 -65 39 -79 17z"/>
<path d="M20340 7235 c0 -8 -7 -15 -15 -15 -18 0 -35 -39 -35 -81 0 -16 -4
-29 -10 -29 -16 0 -12 -18 10 -40 28 -28 40 -25 40 9 0 26 3 29 46 34 45 5 64
18 64 45 0 6 -7 12 -15 12 -8 0 -15 5 -15 10 0 6 7 10 15 10 25 0 19 47 -7 54
-42 11 -78 7 -78 -9z"/>
<path d="M20688 7243 c-11 -3 -18 -15 -19 -31 0 -15 -3 -21 -6 -14 -6 17 -33
15 -33 -2 0 -15 -21 -26 -51 -26 -14 0 -19 -7 -19 -25 0 -20 5 -25 24 -25 14
0 31 -7 40 -15 8 -8 19 -15 25 -15 6 0 11 -4 11 -10 0 -5 -4 -10 -10 -10 -5 0
-10 -18 -10 -40 0 -41 -21 -57 -34 -25 -8 22 -59 19 -79 -5 -9 -11 -26 -20
-37 -20 -13 0 -20 -7 -20 -20 0 -11 -6 -20 -14 -20 -8 0 -16 -7 -20 -15 -3 -9
-18 -15 -35 -15 -16 0 -32 -6 -34 -12 -4 -10 -6 -10 -6 0 -1 6 -8 12 -16 12
-12 0 -15 -16 -15 -86 l0 -85 38 3 c30 2 38 7 40 26 2 12 10 22 18 22 8 0 14
5 14 10 0 6 11 10 24 10 19 0 26 -7 31 -30 4 -16 13 -30 21 -30 8 0 14 -7 14
-15 0 -9 7 -18 15 -21 16 -7 21 -34 6 -34 -6 0 -11 -15 -13 -32 -2 -25 -8 -34
-25 -36 -16 -2 -23 2 -23 12 0 23 -36 20 -60 -4 -24 -24 -25 -36 -5 -44 31
-11 16 -32 -20 -28 -25 3 -35 0 -35 -10 0 -7 -9 -21 -20 -31 -11 -9 -20 -21
-20 -27 0 -13 57 -13 65 0 3 6 14 10 23 9 11 0 12 -3 5 -6 -7 -2 -13 -11 -13
-18 0 -8 -9 -17 -20 -20 -24 -8 -27 -50 -4 -59 34 -13 44 -6 45 32 1 20 3 30
6 20 2 -9 15 -19 29 -22 27 -7 32 -31 9 -40 -22 -8 -18 -36 5 -36 11 0 20 -7
20 -15 0 -8 5 -15 10 -15 6 0 19 -9 30 -20 12 -12 33 -20 55 -20 l35 0 0 48
c0 36 -5 50 -20 60 -16 10 -20 21 -18 56 2 24 6 42 11 39 4 -2 7 -11 7 -19 0
-8 5 -14 10 -14 6 0 10 -7 10 -15 0 -10 10 -15 30 -15 17 0 30 -4 30 -10 0 -5
7 -10 15 -10 12 0 15 14 15 65 0 69 -12 82 -44 49 -9 -8 -16 -11 -16 -5 0 15
-27 14 -33 -1 -2 -7 -8 -10 -11 -6 -16 14 38 52 71 51 32 -1 33 0 33 38 0 21
-4 39 -10 39 -5 0 -10 7 -10 16 0 14 -8 16 -50 11 -42 -5 -52 -3 -57 10 -4 9
-1 19 5 21 7 2 12 17 12 33 0 19 5 29 15 29 8 0 15 9 15 20 0 11 7 20 15 20 8
0 15 6 15 14 0 8 11 17 25 20 21 5 25 12 25 40 0 19 -6 36 -12 39 -10 4 -10 7
0 18 13 14 18 340 5 338 -5 -1 -16 -3 -25 -6z"/>
<path d="M10713 7178 c-23 -11 -33 -58 -13 -58 6 0 10 5 10 10 0 6 5 10 10 10
6 0 10 -7 10 -15 0 -10 11 -15 35 -15 19 0 35 -4 35 -10 0 -5 7 -10 15 -10 8
0 15 -4 15 -10 0 -5 25 -10 55 -10 30 0 55 5 55 10 0 6 -7 10 -15 10 -11 0
-15 12 -15 45 0 41 -2 45 -25 45 -22 0 -25 -4 -25 -35 0 -19 -3 -35 -8 -35 -8
0 -31 26 -48 56 -13 22 -59 28 -91 12z"/>
<path d="M19260 7130 c0 -11 7 -20 15 -20 8 0 15 -4 15 -9 0 -11 -25 -21 -56
-21 -15 0 -24 -6 -24 -15 0 -8 -9 -15 -20 -15 -11 0 -20 5 -20 10 0 6 7 10 16
10 22 0 14 44 -8 48 -12 2 -18 -3 -18 -16 0 -11 -7 -22 -15 -26 -22 -8 -18
-36 5 -36 15 0 20 -7 20 -25 0 -16 6 -25 15 -25 8 0 15 7 15 15 0 10 10 15 30
15 24 0 30 -4 30 -21 0 -15 5 -20 23 -17 19 3 22 8 19 44 -2 28 2 47 12 57 18
18 21 37 6 37 -5 0 -10 7 -10 15 0 9 -9 15 -25 15 -18 0 -25 -5 -25 -20z"/>
<path d="M19600 7135 c0 -10 -16 -16 -50 -21 -27 -4 -50 -10 -50 -15 0 -5 -7
-9 -15 -9 -8 0 -15 -4 -15 -10 0 -5 14 -10 30 -10 17 0 30 -4 30 -10 0 -5 7
-10 15 -10 8 0 15 -7 15 -15 0 -8 6 -15 14 -15 7 0 16 -9 19 -20 4 -15 14 -20
36 -20 28 0 31 3 32 33 0 17 3 26 6 20 8 -22 30 -14 37 12 6 24 -11 65 -27 65
-4 0 -20 -11 -34 -25 -15 -13 -29 -22 -32 -19 -3 3 2 10 12 15 25 15 23 69 -3
69 -11 0 -20 -7 -20 -15z"/>
<path d="M1705 7110 c-3 -5 -3 -21 1 -35 5 -19 12 -25 34 -25 16 0 36 -7 44
-16 15 -14 18 -14 34 2 16 16 17 16 20 1 2 -9 8 -17 13 -17 5 0 9 -9 9 -19 0
-20 49 -39 69 -27 6 4 30 8 55 10 51 4 76 24 79 63 3 39 -28 42 -50 5 -15 -25
-20 -27 -31 -16 -18 19 -40 17 -45 -3 -3 -11 -5 -7 -6 10 -1 22 -7 29 -31 34
-21 4 -30 11 -30 24 0 14 -7 19 -27 19 -43 0 -73 -11 -73 -26 0 -8 -6 -11 -15
-8 -8 4 -15 12 -15 20 0 16 -26 19 -35 4z"/>
<path d="M5790 7050 c0 -16 5 -30 10 -30 7 0 7 -6 0 -19 -5 -11 -7 -27 -4 -35
7 -19 30 -21 38 -3 3 6 2 -6 -1 -29 -4 -36 -2 -42 17 -47 30 -8 90 3 90 17 0
16 -13 32 -30 39 -12 4 -11 6 3 6 27 1 20 30 -10 37 -33 9 -73 50 -73 75 0 12
-7 19 -20 19 -16 0 -20 -7 -20 -30z"/>
<path d="M9886 7013 c-11 -3 -26 -11 -34 -19 -11 -12 -11 -16 0 -28 8 -8 23
-16 34 -18 15 -3 18 -11 16 -36 -1 -21 2 -32 11 -32 16 0 47 -36 47 -55 0 -8
7 -15 15 -15 18 0 20 36 3 43 -10 4 -9 8 2 16 13 10 13 12 0 20 -8 6 -11 13
-7 18 5 4 12 1 17 -6 7 -12 11 -12 24 2 18 18 21 37 6 37 -5 0 -10 9 -10 20 0
11 -4 20 -10 20 -5 0 -10 6 -10 14 0 23 -50 33 -104 19z"/>
<path d="M2182 6995 c-7 -8 -22 -15 -32 -15 -26 0 -25 -21 0 -43 26 -22 47
-22 53 1 4 13 5 11 6 -5 0 -13 7 -23 15 -23 11 0 13 -10 9 -42 -4 -24 -10 -46
-16 -49 -14 -10 1 -29 23 -29 12 0 20 -8 22 -22 3 -24 48 -40 48 -17 0 6 7 17
17 24 19 14 11 45 -12 45 -22 0 -59 36 -50 49 3 6 12 11 19 11 12 0 25 28 26
53 0 15 -39 6 -43 -10 -3 -10 -5 -3 -6 15 0 17 -5 32 -11 32 -5 0 -10 9 -10
20 0 24 -38 27 -58 5z"/>
<path d="M1464 6947 c-4 -12 -13 -27 -21 -32 -10 -7 -12 -20 -8 -38 6 -22 13
-27 36 -27 19 0 29 -5 29 -15 0 -9 7 -18 15 -21 17 -7 21 -34 5 -34 -5 0 -10
-15 -11 -32 0 -18 -3 -27 -6 -20 -6 14 -33 16 -33 2 0 -5 -7 -10 -15 -10 -9 0
-15 -9 -15 -25 0 -16 6 -25 15 -25 22 0 18 -21 -5 -27 -11 -3 -20 -9 -20 -15
0 -5 -8 -18 -19 -29 -18 -20 -19 -20 -49 10 -35 36 -48 38 -57 12 -9 -28 5
-51 30 -51 13 0 25 -7 29 -15 3 -8 15 -15 26 -15 11 0 20 -4 20 -10 0 -5 -9
-10 -20 -10 -18 0 -20 -7 -20 -65 0 -49 3 -65 14 -65 8 0 17 6 19 13 3 7 6 5
6 -5 1 -10 7 -18 15 -18 9 0 12 -7 9 -20 -6 -21 5 -24 45 -14 12 4 22 12 22
18 0 8 11 11 30 8 18 -3 30 0 30 7 0 6 12 11 28 10 l27 -1 -28 -11 c-23 -9
-27 -16 -22 -34 4 -16 0 -25 -10 -29 -8 -4 -15 -12 -15 -20 0 -8 -7 -14 -15
-14 -9 0 -15 -9 -15 -22 0 -13 -3 -33 -6 -45 -5 -17 -2 -23 10 -23 9 0 16 5
16 10 0 6 9 10 20 10 16 0 20 -7 20 -30 0 -27 3 -30 30 -30 20 0 30 5 30 15 0
8 -4 15 -9 15 -5 0 -11 9 -14 20 -3 11 -1 20 4 20 12 0 49 38 49 50 0 6 -4 10
-9 10 -5 0 -11 9 -14 20 -4 15 0 20 14 20 13 0 19 7 19 24 0 26 16 36 60 36
36 0 41 8 33 53 -6 40 -16 45 -39 22 -24 -24 -34 -18 -34 20 0 28 -4 36 -24
41 -14 3 -27 12 -30 20 -3 8 -15 14 -27 14 -15 0 -19 4 -15 16 3 9 6 22 6 30
0 8 7 14 14 14 11 0 16 15 18 53 l3 52 45 -4 c25 -1 47 -7 48 -12 2 -5 12 -9
23 -9 14 0 19 -7 19 -25 0 -29 26 -35 33 -7 3 10 5 6 6 -11 1 -17 -8 -37 -24
-52 -29 -30 -45 -32 -45 -5 0 15 -7 20 -25 20 -16 0 -25 -6 -25 -15 0 -8 -4
-15 -10 -15 -5 0 -10 -9 -10 -20 0 -15 7 -20 29 -20 16 0 31 -6 35 -15 4 -11
20 -15 56 -15 57 0 67 -15 15 -24 -21 -4 -35 -13 -35 -21 0 -8 5 -15 10 -15 6
0 10 -7 10 -15 0 -9 9 -15 25 -15 14 0 25 5 25 10 0 6 11 10 24 10 15 0 27 7
30 18 3 9 3 -4 1 -30 -4 -39 -1 -48 14 -53 11 -3 22 -12 25 -20 3 -8 15 -15
26 -15 12 0 33 -13 47 -30 13 -16 31 -30 39 -30 9 0 14 11 14 30 0 20 -5 30
-15 30 -8 0 -15 7 -15 15 0 10 -10 15 -29 15 -33 0 -41 14 -41 72 0 49 -18 68
-64 68 -29 0 -36 4 -36 20 0 12 -7 20 -16 20 -11 0 -14 5 -10 16 3 9 6 22 6
30 0 8 7 14 15 14 10 0 15 -10 15 -30 0 -20 5 -30 15 -30 8 0 15 7 15 15 0 8
7 15 15 15 8 0 15 7 15 15 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 8 5 15 10 15
6 0 10 6 10 13 0 20 -50 37 -107 37 -40 0 -53 4 -53 15 0 8 -9 15 -20 15 -17
0 -19 5 -13 32 7 38 21 54 49 60 14 3 19 12 19 36 0 23 4 32 16 32 13 0 17 9
17 35 0 31 -3 35 -25 35 -20 0 -24 -4 -20 -20 4 -15 0 -20 -14 -20 -10 0 -19
7 -19 15 0 8 -7 15 -15 15 -8 0 -15 -4 -15 -9 0 -5 -9 -13 -20 -16 -30 -9 -28
-65 2 -65 12 0 19 -3 15 -6 -12 -12 -55 6 -60 26 -8 29 -37 26 -37 -5 0 -22
-3 -24 -32 -18 -41 8 -64 28 -43 37 8 3 15 1 15 -4 0 -6 9 -10 20 -10 16 0 20
7 20 36 0 27 -5 37 -20 41 -11 3 -20 9 -20 14 0 4 -22 8 -50 7 -41 0 -50 -3
-56 -21z"/>
<path d="M19330 6955 c0 -8 -7 -15 -16 -15 -18 0 -44 -23 -44 -39 0 -6 5 -11
10 -11 6 0 10 -18 10 -40 0 -28 -4 -40 -14 -40 -9 0 -12 7 -9 20 4 15 0 20
-16 20 -11 0 -21 -6 -21 -14 0 -15 -22 -26 -48 -25 -11 0 -12 2 -4 6 6 2 12
11 12 18 0 7 -6 16 -12 18 -10 4 -10 8 1 15 12 9 12 13 -2 26 -32 32 -43 20
-45 -50 -1 -62 0 -66 18 -61 11 3 20 1 20 -4 0 -5 -7 -9 -15 -9 -10 0 -15 -10
-15 -31 0 -29 2 -31 28 -24 15 3 31 13 35 21 5 9 25 14 57 14 28 0 50 4 50 10
0 5 11 12 25 16 19 5 25 13 25 35 0 16 -4 29 -10 29 -5 0 -10 16 -10 35 0 31
3 35 25 35 21 0 25 5 25 30 0 27 -3 30 -30 30 -20 0 -30 -5 -30 -15z"/>
<path d="M6910 6895 c0 -8 -5 -15 -11 -15 -6 0 -9 -14 -7 -32 2 -24 12 -38 37
-55 19 -13 29 -23 22 -23 -6 0 -11 -11 -11 -25 0 -21 5 -25 30 -25 17 0 30 5
30 10 0 6 7 10 15 10 21 0 19 38 -2 43 -14 4 -12 5 5 6 42 2 22 51 -22 52 -17
1 -23 3 -13 6 24 6 22 43 -3 43 -11 0 -20 5 -20 10 0 6 -11 10 -25 10 -16 0
-25 -6 -25 -15z"/>
<path d="M5888 6873 c-21 -5 -24 -29 -5 -37 10 -5 10 -7 0 -12 -7 -3 -13 -13
-13 -23 0 -9 -9 -24 -20 -34 -11 -9 -20 -26 -20 -37 0 -11 -6 -20 -13 -20 -9
0 -12 10 -9 30 2 17 10 30 18 30 8 0 14 9 14 20 0 11 -7 20 -15 20 -8 0 -15
-7 -15 -15 0 -9 -9 -15 -25 -15 -23 0 -25 -3 -25 -50 0 -47 2 -50 25 -50 16 0
25 -6 25 -15 0 -8 7 -15 15 -15 8 0 15 5 15 10 0 6 7 10 15 10 8 0 15 9 15 20
0 13 7 20 20 20 13 0 20 7 20 20 0 11 7 20 15 20 11 0 14 -9 13 -32 -3 -30 0
-33 28 -36 25 -2 33 1 37 17 3 12 12 21 21 21 9 0 16 7 16 15 0 13 -7 15 -35
9 -48 -9 -59 18 -21 51 31 27 34 45 6 45 -11 0 -20 6 -20 14 0 20 -41 30 -82
19z"/>
<path d="M19030 6800 c0 -5 -22 -10 -50 -10 -43 0 -50 -3 -50 -19 0 -11 -9
-23 -20 -26 -11 -3 -20 -13 -20 -20 0 -10 10 -12 38 -9 20 3 32 2 26 -3 -6 -4
-14 -20 -18 -35 -3 -16 -11 -28 -18 -28 -19 0 -78 -68 -78 -91 0 -18 4 -20 27
-14 28 7 43 21 43 40 0 6 19 9 44 7 l44 -4 -3 94 c-1 51 -1 87 2 81 3 -7 18
-13 34 -13 26 0 29 4 29 30 0 20 -5 30 -15 30 -8 0 -15 -4 -15 -10z"/>
<path d="M2488 6743 c-10 -2 -18 -11 -18 -19 0 -8 -8 -14 -18 -14 -10 0 -24
-9 -32 -20 -10 -13 -21 -18 -37 -14 -20 5 -23 2 -23 -24 0 -26 4 -30 38 -36
57 -9 67 -8 92 14 13 11 27 20 32 20 19 0 38 29 38 58 0 38 -22 48 -72 35z"/>
<path d="M20040 6730 c0 -5 -11 -10 -25 -10 -18 0 -25 -5 -25 -20 0 -11 -4
-20 -10 -20 -5 0 -10 -9 -10 -20 0 -11 5 -20 10 -20 6 0 10 -4 10 -10 0 -5 -7
-10 -15 -10 -18 0 -20 -26 -2 -37 7 -4 20 -26 28 -47 17 -47 39 -59 39 -22 0
21 5 26 25 26 17 0 25 6 25 18 0 9 3 27 6 40 5 16 2 22 -10 22 -9 0 -16 -7
-16 -15 0 -22 -28 -18 -35 4 -7 24 4 72 22 89 18 18 17 42 -2 42 -8 0 -15 -4
-15 -10z"/>
<path d="M2040 6629 c0 -42 5 -56 25 -75 14 -13 25 -26 25 -29 0 -3 -11 -5
-25 -5 -14 0 -25 5 -25 10 0 6 -7 10 -15 10 -8 0 -15 -7 -15 -15 0 -8 4 -15 9
-15 17 0 22 -34 6 -42 -19 -11 -20 -28 0 -28 8 0 15 -5 15 -10 0 -6 8 -20 18
-31 14 -14 26 -18 50 -13 18 4 32 10 32 15 0 5 5 9 10 9 6 0 10 -7 10 -15 0
-8 6 -15 13 -15 8 0 24 -9 37 -20 l23 -20 -31 6 c-30 6 -32 5 -32 -25 l0 -31
58 0 c57 0 58 0 71 36 7 19 10 39 6 42 -4 4 -10 1 -12 -5 -7 -19 -53 -16 -53
2 0 8 5 15 10 15 16 0 12 27 -5 34 -8 3 -15 15 -15 27 0 12 -8 28 -17 35 -16
12 -11 13 36 8 53 -7 54 -7 48 17 -4 13 -7 27 -7 32 0 4 -20 7 -45 7 -33 0
-45 4 -45 15 0 8 -9 15 -20 15 -11 0 -20 -4 -20 -10 0 -5 -4 -10 -10 -10 -5 0
-10 16 -10 35 0 33 -2 35 -35 35 -32 0 -35 2 -35 30 0 20 -5 30 -15 30 -11 0
-15 -12 -15 -51z"/>
<path d="M20100 6650 c0 -22 5 -30 18 -30 14 0 14 -2 3 -9 -9 -6 -15 -27 -16
-53 -1 -36 -4 -43 -23 -46 -12 -2 -22 -10 -22 -18 0 -8 -4 -14 -10 -14 -5 0
-10 -7 -10 -15 0 -8 7 -15 15 -15 8 0 15 7 15 16 0 14 8 16 47 11 26 -3 49
-10 51 -16 2 -6 10 -11 17 -11 10 0 12 -19 9 -84 -3 -78 -2 -85 16 -91 12 -4
26 0 35 9 17 17 20 36 5 36 -5 0 -10 7 -10 15 0 8 7 15 15 15 10 0 15 10 15
30 0 23 -4 30 -20 30 -16 0 -20 7 -20 30 0 17 -5 30 -12 30 -7 0 -5 5 4 11 24
14 22 87 -3 112 -11 10 -19 24 -19 31 0 8 -16 17 -35 20 -21 4 -35 13 -35 21
0 8 -7 15 -15 15 -10 0 -15 -10 -15 -30z"/>
<path d="M7194 6615 c-3 -14 -10 -25 -15 -25 -5 0 -9 -18 -9 -40 0 -23 5 -40
11 -40 7 0 17 -6 23 -14 6 -8 32 -17 59 -21 43 -6 47 -4 47 14 0 12 5 21 10
21 6 0 10 7 10 15 0 8 -7 15 -16 15 -12 0 -15 6 -10 26 7 26 6 27 -29 21 -39
-6 -65 -1 -65 14 0 5 7 9 15 9 8 0 15 7 15 15 0 26 -39 18 -46 -10z"/>
<path d="M1160 6606 c0 -8 -14 -17 -31 -21 -25 -6 -30 -11 -25 -28 3 -12 6
-28 6 -34 0 -7 7 -13 15 -13 8 0 15 7 15 15 0 8 5 15 10 15 6 0 10 7 10 15 0
22 28 18 35 -5 3 -11 4 -24 1 -28 -3 -5 -2 -32 3 -60 5 -33 4 -53 -2 -55 -20
-6 2 -55 29 -61 15 -4 29 -5 31 -2 3 3 6 31 7 62 1 35 7 59 14 62 18 6 16 82
-3 82 -9 0 -15 9 -15 21 0 18 -4 20 -30 14 -26 -6 -30 -4 -30 14 0 14 -6 21
-20 21 -11 0 -20 -6 -20 -14z"/>
<path d="M5268 6603 c-3 -37 3 -55 26 -78 25 -25 26 -43 4 -107 -16 -46 -32
-54 -59 -27 -45 45 -109 29 -109 -27 0 -23 3 -25 35 -22 20 1 39 -3 43 -9 4
-6 20 -15 35 -18 29 -8 36 -32 12 -41 -8 -4 -15 -12 -15 -20 0 -8 -7 -14 -15
-14 -8 0 -15 -4 -15 -10 0 -5 -9 -10 -20 -10 -10 0 -23 -8 -29 -17 -5 -10 -13
-14 -18 -9 -4 5 -2 11 5 13 22 8 13 33 -13 33 -16 0 -25 6 -25 15 0 8 -4 15
-10 15 -5 0 -10 -4 -10 -10 0 -5 -11 -12 -25 -16 -14 -3 -25 -11 -25 -16 0
-16 28 -38 49 -38 12 0 21 -4 21 -10 0 -5 11 -10 24 -10 17 0 26 -7 30 -25 4
-14 14 -25 23 -25 19 0 42 -28 43 -53 0 -10 7 -20 15 -23 8 -4 15 -17 15 -30
0 -21 5 -24 39 -24 81 0 129 -12 135 -32 4 -16 10 -19 29 -14 21 5 26 2 30
-22 4 -20 1 -31 -14 -41 -10 -7 -19 -18 -19 -23 0 -13 -26 -38 -40 -38 -6 0
-10 -6 -8 -12 3 -7 21 -14 41 -16 30 -3 37 0 38 15 1 10 6 3 12 -16 8 -26 8
-36 -1 -39 -7 -2 -12 -16 -12 -32 0 -20 -6 -31 -20 -35 -23 -7 -60 2 -60 16 0
5 -5 9 -11 9 -7 0 -9 -11 -5 -30 4 -22 2 -30 -8 -30 -15 0 -30 -45 -21 -60 3
-6 20 -9 38 -7 22 1 32 -3 35 -15 2 -13 -8 -18 -52 -23 -31 -4 -56 -11 -56
-16 0 -5 -9 -9 -20 -9 -11 0 -20 -7 -20 -15 0 -8 9 -15 19 -15 11 0 23 -4 26
-10 3 -5 19 -10 36 -10 16 0 29 -4 29 -9 0 -6 20 -13 45 -17 28 -4 45 -11 45
-20 0 -8 5 -14 10 -14 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10 -4 10 -10
0 -5 -7 -10 -15 -10 -8 0 -15 7 -15 15 0 11 -12 15 -50 15 -38 0 -50 -4 -50
-15 0 -9 6 -18 14 -21 8 -3 17 -16 20 -29 7 -29 24 -35 104 -41 60 -5 65 -3
87 22 12 14 29 24 36 21 10 -4 9 -6 -3 -6 -22 -1 -24 -27 -2 -35 9 -3 31 -6
50 -6 27 0 34 4 34 19 0 10 -9 21 -20 24 -31 8 -24 24 13 29 31 3 32 2 29 -29
-4 -30 -2 -33 22 -33 16 0 26 5 26 15 0 8 5 15 10 15 6 0 10 -6 10 -14 0 -8
12 -17 28 -20 43 -9 332 -4 332 5 0 5 -13 9 -30 9 -47 0 -37 43 13 55 32 8 36
30 7 38 -13 3 -20 14 -20 30 0 14 -4 27 -9 29 -10 3 -14 27 -12 71 1 22 6 27
26 27 16 0 25 -6 25 -15 0 -10 10 -15 31 -15 18 0 37 -7 44 -16 15 -21 65 -12
65 11 0 8 7 15 16 15 20 0 44 24 44 44 0 13 -8 15 -41 10 -46 -8 -62 5 -52 43
4 17 1 23 -11 23 -9 0 -16 7 -16 16 0 26 -54 14 -58 -13 -2 -13 1 -23 7 -23 6
0 11 -7 11 -15 0 -8 -9 -15 -20 -15 -11 0 -20 5 -20 10 0 6 -7 10 -15 10 -8 0
-15 7 -15 15 0 8 -4 15 -10 15 -5 0 -10 18 -10 40 0 33 -3 40 -20 40 -16 0
-20 7 -20 35 0 28 -4 35 -20 35 -13 0 -20 -7 -20 -20 0 -11 -7 -20 -15 -20
-10 0 -15 10 -15 29 0 21 -6 30 -22 35 -38 10 -48 7 -48 -14 0 -13 7 -20 19
-20 14 0 18 -5 14 -20 -3 -11 -12 -20 -19 -20 -8 0 -14 -7 -14 -16 0 -12 7
-15 24 -12 15 3 27 0 30 -8 7 -19 26 -17 26 2 0 9 6 14 13 11 7 -2 11 -17 9
-36 -1 -22 2 -31 13 -31 8 0 15 -7 15 -15 0 -8 11 -17 25 -21 26 -6 29 -24 7
-32 -7 -2 -12 3 -12 11 0 17 -21 27 -56 27 -13 0 -24 5 -24 10 0 6 -9 10 -20
10 -11 0 -20 -7 -20 -15 0 -8 9 -15 20 -15 15 0 20 -7 20 -29 0 -27 -24 -50
-38 -36 -3 3 0 5 6 5 20 0 13 28 -8 35 -13 4 -20 15 -20 31 0 32 -28 32 -36
-1 -3 -14 -10 -25 -15 -25 -5 0 -9 -4 -9 -10 0 -5 7 -10 15 -10 9 0 15 -9 15
-25 0 -14 4 -25 10 -25 5 0 12 -16 16 -35 3 -19 10 -35 15 -35 5 0 9 -9 9 -20
0 -11 5 -20 10 -20 6 0 10 -7 10 -16 0 -13 -7 -15 -37 -9 -21 4 -39 11 -41 16
-2 5 -17 9 -33 9 -22 0 -29 5 -29 20 0 13 -7 20 -20 20 -14 0 -20 -7 -20 -21
0 -20 -4 -21 -46 -15 -26 3 -53 6 -60 6 -8 0 -14 11 -14 25 0 18 -5 25 -20 25
-24 0 -26 -16 -3 -38 16 -16 15 -17 -15 -20 -20 -2 -32 -9 -32 -18 0 -8 5 -14
10 -14 6 0 10 -7 10 -15 0 -8 -4 -15 -10 -15 -5 0 -10 5 -10 11 0 6 -7 9 -15
5 -8 -3 -15 -1 -15 5 0 5 -11 7 -25 3 -21 -5 -25 -3 -25 15 0 16 -6 21 -25 21
-18 0 -25 5 -25 20 0 11 5 20 10 20 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0 -10
7 -10 15 0 8 -9 15 -19 15 -26 0 -51 10 -51 21 0 5 7 9 15 9 8 0 15 6 15 14 0
8 9 17 19 21 16 5 20 14 18 46 -1 26 4 43 13 49 22 14 40 12 40 -5 0 -8 9 -15
20 -15 13 0 20 7 20 20 0 13 -7 20 -20 20 -15 0 -20 7 -20 25 0 16 -6 25 -15
25 -8 0 -15 -4 -15 -10 0 -5 -4 -10 -10 -10 -5 0 -10 7 -10 15 0 10 10 15 30
15 20 0 30 5 30 15 0 10 10 15 34 15 35 0 66 21 66 45 0 7 4 15 9 17 4 2 12
13 15 26 7 19 4 22 -23 22 -17 0 -31 5 -31 10 0 6 5 10 10 10 6 0 10 8 11 18
0 10 3 12 6 5 5 -15 73 -18 73 -4 0 23 32 18 57 -9 27 -31 58 -39 66 -17 3 7
6 5 6 -5 1 -10 8 -18 17 -18 13 0 15 -6 9 -27 -8 -34 -4 -30 -38 -38 -21 -6
-27 -4 -27 9 0 9 -4 16 -10 16 -5 0 -10 12 -10 26 0 20 -4 25 -16 20 -9 -3
-18 -6 -20 -6 -2 0 -4 -9 -4 -20 0 -11 4 -20 9 -20 5 0 14 -13 20 -30 9 -27
14 -30 50 -30 34 0 41 3 41 20 0 13 7 20 19 20 23 0 33 27 30 78 -3 63 0 82
16 82 8 0 15 -7 15 -15 0 -8 5 -15 10 -15 6 0 10 -9 10 -20 0 -13 7 -20 19
-20 10 0 21 9 24 20 3 11 10 20 16 20 6 0 11 -9 11 -20 0 -15 7 -20 25 -20 16
0 25 6 25 15 0 8 5 15 10 15 6 0 10 9 10 19 0 20 -18 31 -51 31 -10 0 -19 7
-19 15 0 8 6 15 14 15 13 0 26 20 26 41 0 28 -108 -1 -132 -36 -5 -6 -8 -5 -8
3 0 7 6 15 14 18 7 3 16 27 20 53 l6 48 59 -1 c33 -1 62 2 65 5 15 15 17 67 3
80 -20 21 -30 56 -17 64 5 3 13 -1 16 -9 7 -18 74 -23 74 -6 0 6 -13 10 -30
10 -20 0 -30 5 -30 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15 0 11 -11 15 -40 15
-29 0 -40 -4 -40 -15 0 -19 -16 -19 -24 0 -8 21 21 45 55 45 30 0 41 23 17 33
-8 3 -6 6 5 6 21 1 23 31 2 31 -10 0 -15 11 -15 35 0 24 -5 35 -15 35 -8 0
-15 5 -15 10 0 25 -30 6 -40 -25 -11 -32 -30 -48 -30 -25 0 6 5 10 10 10 6 0
10 7 10 15 0 11 -12 15 -45 15 l-44 0 -7 -50 c-6 -44 -9 -50 -30 -50 -13 0
-24 -6 -24 -12 0 -7 -3 -23 -6 -35 -8 -30 22 -32 31 -3 3 11 13 20 21 20 11 0
13 -6 9 -20 -3 -10 -1 -28 5 -40 7 -13 7 -20 0 -20 -5 0 -10 -13 -10 -30 0
-16 5 -30 10 -30 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10 -7 10 -15 0 -8
8 -15 18 -15 14 0 17 -7 14 -45 -4 -46 -10 -50 -54 -47 -13 1 -18 -6 -18 -24
0 -24 -3 -25 -42 -22 l-43 3 1 -37 c1 -30 -2 -38 -17 -38 -10 0 -21 9 -24 20
-3 11 -11 20 -16 20 -16 0 -39 -27 -40 -47 0 -10 -5 -5 -10 12 -5 19 -5 31 1
33 17 6 11 30 -10 37 -11 3 -20 11 -20 16 0 5 -7 9 -15 9 -8 0 -15 5 -15 10 0
6 -7 10 -15 10 -8 0 -15 7 -15 15 0 8 7 15 15 15 11 0 15 8 13 27 -2 20 2 28
15 31 16 3 17 -2 11 -47 -7 -58 5 -66 46 -31 13 11 36 20 52 20 18 0 28 5 28
15 0 9 9 15 25 15 18 0 25 5 25 19 0 11 5 21 12 23 12 4 17 72 5 84 -3 3 -26
9 -51 11 l-45 6 6 -36 c10 -63 -20 -122 -34 -67 -3 10 -14 22 -24 25 -14 5
-18 12 -13 31 5 18 2 24 -9 24 -9 0 -18 -8 -20 -17 -4 -14 -5 -12 -6 5 -1 17
-7 22 -26 22 -16 0 -25 6 -25 15 0 8 -5 15 -11 15 -7 0 -9 -9 -5 -22 3 -13 6
-26 6 -30 0 -5 7 -8 15 -8 20 0 19 -27 -1 -47 -11 -11 -14 -26 -9 -50 4 -21 2
-33 -5 -33 -7 0 -10 -11 -6 -27 3 -16 8 -47 11 -70 4 -26 12 -43 20 -43 8 0
11 -7 8 -20 -3 -11 -12 -20 -19 -20 -10 0 -14 -13 -14 -45 0 -38 -3 -45 -20
-45 -18 0 -20 7 -20 59 0 38 -4 61 -12 64 -10 4 -10 6 0 6 7 1 15 -6 18 -14
11 -27 34 -17 34 15 0 24 -4 30 -22 31 -17 1 -19 2 -5 6 27 7 21 33 -8 33 -16
0 -25 6 -25 15 0 8 5 15 10 15 6 0 10 9 10 20 0 15 -7 20 -25 20 -14 0 -28 -7
-31 -15 -3 -8 -22 -17 -42 -20 -21 -4 -49 -9 -64 -11 -23 -5 -28 -2 -28 15 0
12 5 21 10 21 6 0 10 16 10 35 0 24 -5 35 -15 35 -8 0 -15 -6 -15 -14 0 -8
-11 -17 -25 -20 -18 -5 -25 -13 -25 -31 0 -13 -4 -27 -10 -30 -6 -4 -10 7 -10
24 0 21 -5 31 -15 31 -9 0 -15 -9 -15 -25 0 -14 -4 -25 -10 -25 -5 0 -10 7
-10 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15 0 8 5 15 10 15 6 0 10 9 10 20 0
11 7 20 15 20 8 0 15 4 15 10 0 5 16 12 35 16 28 5 35 11 35 30 0 13 -4 24
-10 24 -16 0 -11 37 6 44 12 5 14 13 9 33 -4 16 -12 31 -18 35 -8 5 -6 8 6 8
10 0 17 -8 17 -19 0 -11 8 -22 19 -26 21 -6 51 3 51 16 0 19 20 8 26 -15 6
-24 29 -36 72 -36 12 0 22 -4 22 -10 0 -5 7 -10 15 -10 8 0 15 7 15 15 0 8 8
15 19 15 16 0 30 24 31 52 0 11 -36 19 -54 12 -19 -7 -21 -30 -3 -38 6 -4 1
-4 -13 -1 -14 2 -35 5 -47 5 -20 0 -23 5 -23 45 0 32 4 45 14 45 18 0 28 38
15 62 -12 21 -39 24 -39 3 0 -7 8 -19 18 -25 16 -11 16 -11 -1 -6 -10 3 -23 6
-28 6 -5 0 -9 7 -9 15 0 8 -9 15 -20 15 -13 0 -20 -7 -20 -19 0 -14 -5 -18
-20 -14 -11 3 -20 11 -20 19 0 7 -11 16 -25 20 -18 4 -25 13 -25 30 0 13 -4
24 -9 24 -4 0 -6 11 -3 25 3 18 0 25 -12 25 -9 0 -17 -8 -18 -17z"/>
<path d="M19205 6571 c-5 -18 -43 -31 -87 -31 -11 0 -26 -6 -34 -14 -19 -19
-18 -56 1 -56 8 0 15 -4 15 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -7 -15 -15 0 -8
9 -15 20 -15 11 0 20 7 20 15 0 8 5 15 10 15 6 0 10 14 10 32 l0 31 20 -23
c11 -13 20 -27 20 -32 0 -4 7 -8 15 -8 8 0 15 5 15 10 0 16 57 12 64 -5 3 -8
14 -15 25 -15 13 0 21 -8 23 -22 2 -19 10 -24 41 -26 40 -3 48 9 21 35 -10 10
-13 25 -10 39 5 17 2 24 -8 24 -8 0 -16 7 -20 15 -3 8 -15 15 -26 15 -11 0
-20 -7 -20 -15 0 -8 -4 -15 -10 -15 -5 0 -10 20 -10 44 0 37 -4 45 -22 50 -34
9 -36 8 -43 -13z"/>
<path d="M19737 6483 c-4 -3 -7 -13 -7 -20 0 -8 -9 -24 -20 -36 -23 -24 -20
-63 6 -83 17 -14 19 -65 2 -83 -10 -11 -10 -14 0 -18 19 -7 14 -23 -8 -23 -11
0 -20 5 -20 10 0 6 -7 10 -15 10 -8 0 -15 -7 -15 -15 0 -8 7 -15 15 -15 8 0
15 -4 15 -9 0 -5 -7 -11 -15 -15 -19 -7 -20 -36 -2 -36 8 0 24 -9 37 -20 33
-29 50 -25 50 10 0 17 -4 30 -10 30 -5 0 -10 11 -10 25 0 14 5 25 11 25 13 0
39 25 39 38 0 6 -6 13 -12 15 -10 4 -10 6 0 6 14 1 17 41 2 41 -13 0 -13 27
-1 32 5 2 13 15 16 31 6 21 4 27 -9 27 -21 0 -21 19 1 35 22 16 10 45 -20 45
-13 0 -27 -3 -30 -7z"/>
<path d="M6509 6433 c0 -11 -3 -13 -6 -5 -9 22 -33 13 -33 -13 0 -15 6 -25 14
-25 7 0 16 -10 18 -23 4 -20 1 -22 -29 -19 -26 3 -33 1 -33 -13 0 -9 -9 -19
-20 -22 -15 -4 -20 -14 -20 -39 0 -23 -5 -34 -15 -34 -8 0 -17 -11 -21 -25 -5
-20 -16 -28 -50 -36 -47 -12 -51 -22 -46 -100 1 -19 -4 -28 -18 -32 -24 -6
-26 -30 -5 -51 8 -8 15 -28 15 -44 0 -39 9 -62 24 -62 8 0 11 -13 9 -39 l-3
-38 38 1 c31 1 38 5 40 25 2 14 -3 30 -10 35 -8 6 -18 23 -22 39 -5 23 -3 27
14 27 11 0 20 7 20 15 0 8 -8 15 -17 15 -12 0 -14 3 -7 8 6 4 15 22 19 40 3
17 11 32 16 32 5 0 9 7 9 16 0 14 -7 15 -40 10 -35 -5 -40 -4 -40 13 0 10 7
21 15 25 9 3 15 0 15 -9 0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 7 15 15 15 8 0
15 7 15 15 0 8 -9 15 -20 15 -11 0 -20 5 -20 10 0 6 9 10 19 10 13 0 21 8 23
23 3 20 8 22 51 19 42 -3 47 -2 47 17 0 12 -6 21 -15 21 -10 0 -15 10 -15 29
0 35 18 44 44 22 14 -12 20 -13 23 -3 6 17 33 15 33 -2 0 -18 22 -26 76 -26
38 0 46 4 54 25 8 22 16 25 55 25 43 0 46 2 52 30 4 23 11 30 29 30 17 0 24 6
24 20 0 24 -22 26 -44 5 -16 -17 -46 -20 -46 -6 0 5 -18 12 -40 16 -22 4 -40
11 -40 16 0 5 -11 9 -25 9 -14 0 -25 -5 -25 -11 0 -6 -7 -3 -16 5 -10 10 -33
16 -65 16 -40 0 -49 -3 -50 -17z"/>
<path d="M10523 6413 c-7 -2 -13 -11 -13 -18 0 -17 -22 -25 -49 -18 -12 3 -28
0 -36 -7 -17 -14 -21 -60 -5 -60 6 0 10 -7 10 -15 0 -10 11 -15 33 -16 17 0
26 -3 20 -6 -19 -7 -16 -53 2 -53 8 0 15 5 15 10 0 6 14 10 30 10 23 0 30 4
30 20 0 11 -7 20 -15 20 -8 0 -15 6 -15 14 0 8 -7 16 -15 20 -8 3 -15 10 -15
16 0 5 7 10 15 10 8 0 15 6 15 14 0 7 9 16 21 19 13 4 20 12 17 24 -3 18 -23
26 -45 16z"/>
<path d="M20697 6313 c-4 -3 -7 -15 -7 -25 0 -13 -7 -18 -25 -18 -20 0 -25 -5
-25 -25 0 -19 -5 -25 -21 -25 -12 0 -23 -8 -26 -20 -3 -11 -12 -20 -19 -20 -8
0 -14 9 -14 20 0 11 -7 20 -15 20 -8 0 -15 5 -15 10 0 6 -9 10 -20 10 -11 0
-20 -4 -20 -10 0 -5 -7 -10 -15 -10 -24 0 -17 -30 10 -40 14 -5 25 -14 25 -19
0 -5 11 -12 25 -15 14 -4 25 -11 25 -16 0 -6 18 -10 40 -10 22 0 40 5 40 10 0
6 11 10 25 10 16 0 25 -6 25 -15 0 -8 -5 -15 -11 -15 -18 0 -46 -33 -31 -38
17 -5 15 -32 -3 -32 -9 0 -15 -9 -15 -25 0 -14 -4 -25 -10 -25 -5 0 -10 5 -10
10 0 13 -77 13 -82 0 -2 -6 -21 -13 -43 -17 -22 -3 -48 -14 -57 -23 -20 -22
-72 -16 -64 7 3 8 6 21 6 29 0 8 9 14 20 14 15 0 20 7 20 25 0 33 -20 75 -36
75 -8 0 -11 6 -8 15 4 8 13 15 21 15 12 0 14 8 9 38 -3 20 -6 43 -6 50 0 6 -7
12 -15 12 -8 0 -15 -4 -15 -10 0 -5 -4 -10 -10 -10 -5 0 -10 11 -10 25 0 18
-5 25 -20 25 -16 0 -20 -7 -20 -30 0 -20 5 -30 15 -30 24 0 20 -75 -5 -100
-12 -12 -20 -33 -20 -55 0 -34 2 -35 38 -36 20 -1 29 -3 20 -6 -10 -2 -18 -10
-18 -18 0 -7 -9 -15 -20 -18 -11 -3 -20 -12 -20 -21 0 -9 -4 -16 -10 -16 -15
0 -12 -81 4 -94 8 -6 17 -20 20 -32 5 -16 0 -23 -19 -32 -28 -13 -32 -34 -10
-56 8 -8 15 -24 15 -35 0 -14 6 -21 20 -21 13 0 20 7 20 20 0 15 7 20 29 20
25 0 30 5 35 35 10 49 55 48 70 -2 4 -13 14 -23 22 -23 8 0 14 -9 14 -21 0
-13 8 -23 21 -26 12 -4 18 -11 15 -20 -3 -8 -6 -19 -6 -24 0 -5 -7 -9 -15 -9
-16 0 -18 -12 -9 -47 6 -21 13 -23 65 -23 l59 0 0 -35 c0 -19 -4 -35 -10 -35
-5 0 -10 -7 -10 -15 0 -8 -9 -15 -20 -15 -11 0 -20 5 -20 10 0 6 -7 10 -15 10
-8 0 -15 9 -15 20 0 28 -36 26 -44 -2 -11 -43 -6 -78 14 -98 21 -21 26 -40 11
-40 -5 0 -12 -8 -14 -17 -3 -10 -5 -5 -6 12 0 17 -10 38 -21 48 -12 10 -20 29
-20 48 0 26 -3 30 -22 27 -15 -2 -24 -11 -26 -25 -3 -23 -22 -33 -22 -12 0 14
-25 39 -39 39 -6 0 -11 -4 -11 -10 0 -5 -9 -10 -20 -10 -22 0 -25 -12 -10 -41
9 -15 7 -21 -5 -25 -22 -9 -19 -34 5 -34 13 0 20 -7 20 -20 0 -16 7 -20 30
-20 23 0 30 4 30 20 0 12 7 20 18 20 21 0 62 -42 62 -64 0 -14 17 -16 125 -16
l125 0 0 25 c0 29 -21 33 -44 9 -14 -13 -16 -13 -16 -1 0 9 -6 18 -12 21 -10
5 -10 7 0 12 6 3 12 12 12 20 0 8 9 14 20 14 15 0 20 7 20 25 0 14 5 25 10 25
15 0 13 157 -2 163 -10 4 -10 6 0 6 9 1 12 47 12 196 0 165 -2 195 -15 195
-11 0 -14 11 -11 49 2 27 7 55 11 62 16 24 15 120 0 136 -19 19 -19 43 0 43 9
0 15 9 15 25 0 24 -10 32 -23 18z m-214 -403 c17 0 27 -5 27 -15 0 -23 76 -20
90 3 10 14 11 13 6 -8 -3 -14 -6 -37 -6 -51 0 -14 -9 -36 -20 -49 -11 -13 -20
-27 -20 -32 0 -4 -6 -8 -13 -8 -12 0 -25 14 -46 48 -4 8 -27 16 -49 19 -33 4
-43 2 -48 -11 -8 -21 -20 -20 -44 4 -28 28 -26 56 5 64 13 3 29 16 36 27 8 15
17 19 32 15 12 -3 34 -6 50 -6z"/>
<path d="M19340 6225 c0 -8 5 -15 10 -15 6 0 10 -4 10 -10 0 -5 -7 -10 -16
-10 -11 0 -14 -6 -8 -22 4 -13 11 -26 17 -30 6 -3 10 -27 8 -52 -2 -41 1 -46
19 -46 11 0 23 7 26 15 10 25 24 17 24 -15 0 -28 3 -30 35 -30 24 0 35 -5 35
-15 0 -9 9 -15 23 -15 20 -1 20 -2 5 -11 -20 -11 -24 -45 -6 -51 6 -2 11 -18
10 -36 0 -25 3 -32 19 -32 12 0 19 7 19 20 0 11 5 20 10 20 17 0 12 87 -5 94
-8 3 -15 17 -15 31 0 14 -4 25 -10 25 -5 0 -10 7 -10 15 0 18 -46 21 -53 3 -6
-17 -13 -9 -21 25 -5 23 -3 27 14 27 21 0 28 24 10 35 -16 10 -50 -5 -50 -21
0 -8 -7 -14 -15 -14 -8 0 -15 7 -15 15 0 8 5 15 10 15 6 0 10 7 10 15 0 8 -4
15 -10 15 -5 0 -10 9 -10 19 0 37 -12 51 -41 51 -19 0 -29 -5 -29 -15z"/>
<path d="M19835 6190 c-3 -11 -12 -20 -20 -20 -17 0 -32 -43 -16 -48 6 -2 11
-12 11 -23 0 -10 5 -19 10 -19 6 0 10 -6 10 -13 0 -7 13 -24 30 -37 16 -14 29
-31 30 -37 0 -7 3 -13 8 -13 20 1 30 19 34 60 l3 45 -32 -3 c-24 -1 -33 2 -33
13 0 8 7 15 15 15 10 0 15 10 15 30 0 20 -5 30 -15 30 -9 0 -15 -9 -15 -25 0
-16 -6 -25 -15 -25 -8 0 -15 5 -15 10 0 6 5 10 10 10 6 0 10 9 10 20 0 11 7
20 15 20 8 0 15 7 15 15 0 23 -47 19 -55 -5z"/>
<path d="M6760 6165 c0 -8 -11 -17 -24 -21 -14 -3 -27 -13 -29 -22 -3 -12 -5
-10 -6 6 -1 15 -7 22 -21 22 -11 0 -20 -5 -20 -11 0 -5 -14 -23 -31 -39 -33
-29 -35 -60 -4 -60 9 0 15 -9 15 -25 0 -14 5 -25 10 -25 6 0 10 -9 10 -20 0
-11 6 -20 13 -20 7 0 18 9 25 20 17 28 42 26 42 -5 0 -29 21 -33 44 -9 26 25
20 52 -14 59 -16 4 -30 11 -30 17 0 13 26 38 39 38 14 0 53 22 64 35 6 6 10 6
14 -2 2 -8 22 -13 44 -13 35 0 39 3 39 25 0 21 5 25 30 25 23 0 30 4 30 20 0
11 -6 20 -14 20 -26 0 -46 -12 -46 -27 0 -13 -10 -15 -61 -10 -33 4 -67 14
-75 22 -20 19 -44 19 -44 0z"/>
<path d="M11260 6145 c0 -14 -4 -25 -10 -25 -5 0 -10 -9 -10 -20 0 -11 7 -20
15 -20 8 0 15 -7 15 -15 0 -8 7 -15 15 -15 8 0 15 5 15 10 0 6 9 10 20 10 11
0 20 -5 20 -11 0 -6 -9 -13 -20 -16 -11 -3 -20 -12 -20 -19 0 -8 -7 -14 -15
-14 -8 0 -15 -4 -15 -9 0 -5 -9 -11 -20 -14 -11 -3 -20 -12 -20 -21 0 -9 -4
-16 -10 -16 -5 0 -10 -7 -10 -15 0 -8 5 -15 11 -15 6 0 3 -7 -5 -16 -20 -20
-20 -34 -1 -34 8 0 15 -4 15 -10 0 -5 -4 -10 -10 -10 -5 0 -10 -7 -10 -15 0
-15 6 -25 38 -60 6 -7 12 -16 12 -20 0 -14 -28 -33 -57 -39 -17 -4 -34 -13
-36 -21 -3 -7 -19 -16 -36 -20 -17 -4 -31 -11 -31 -16 0 -5 -7 -9 -15 -9 -19
0 -19 -27 -1 -46 17 -16 86 -19 86 -3 0 6 7 17 15 25 8 9 15 27 15 40 0 23 3
24 34 19 27 -6 38 -2 55 16 11 13 21 26 21 31 0 4 5 8 10 8 6 0 10 -11 10 -24
0 -15 7 -26 18 -29 10 -3 6 -5 -10 -6 -50 -2 -26 -61 24 -61 13 0 33 -7 44
-15 27 -20 34 -19 34 10 0 16 -6 25 -15 25 -8 0 -15 7 -15 15 0 8 5 15 10 15
6 0 10 9 10 20 0 11 -5 20 -11 20 -6 0 -3 7 6 17 12 12 15 25 10 52 -4 20 -11
38 -16 39 -12 5 -12 72 1 72 6 0 10 14 10 30 0 23 -4 30 -20 30 -11 0 -20 -7
-20 -15 0 -8 -4 -15 -10 -15 -5 0 -10 15 -10 34 0 19 -7 42 -16 50 -13 14 -13
16 -1 16 9 0 18 6 21 13 5 10 7 10 12 0 8 -18 24 -16 24 2 0 9 9 15 25 15 21
0 25 5 25 29 0 26 -3 29 -47 34 -26 3 -49 10 -51 16 -2 6 -17 11 -33 11 -17 0
-32 6 -35 15 -10 25 -34 17 -34 -10z"/>
<path d="M6993 6124 c-32 -36 -29 -47 10 -40 17 3 39 1 47 -4 11 -7 5 -10 -22
-10 -21 0 -38 -4 -38 -10 0 -5 -4 -10 -10 -10 -5 0 -10 7 -10 15 0 8 -9 15
-20 15 -15 0 -20 -7 -20 -29 0 -16 -6 -31 -12 -34 -19 -6 9 -37 33 -37 15 0
19 -7 19 -36 0 -34 -2 -36 -25 -30 -33 8 -75 -3 -75 -20 0 -8 7 -14 15 -14 8
0 15 -7 15 -15 0 -8 7 -15 15 -15 8 0 15 -7 15 -15 0 -8 7 -15 15 -15 18 0 20
26 3 33 -20 8 10 26 32 20 12 -3 20 2 25 16 3 12 11 21 16 21 5 0 9 14 9 30 0
18 6 33 15 36 22 9 18 34 -4 34 -26 0 -51 10 -51 21 0 5 30 9 68 9 55 0 71 4
85 20 25 30 22 33 -27 27 -42 -6 -44 -5 -51 21 -12 48 -39 54 -72 16z"/>
<path d="M20090 6095 c0 -8 5 -15 10 -15 6 0 10 -7 10 -16 0 -8 7 -22 15 -30
8 -9 15 -33 15 -54 0 -21 -7 -45 -15 -54 -8 -8 -15 -21 -15 -29 0 -8 7 -17 15
-21 8 -3 15 -10 15 -16 0 -16 -27 -12 -34 5 -8 23 -33 18 -40 -7 -11 -42 -8
-51 18 -44 16 4 26 1 30 -9 3 -8 15 -15 26 -15 16 0 20 7 20 30 0 23 4 30 20
30 16 0 20 7 20 34 0 19 7 41 15 50 8 8 15 19 15 25 0 6 6 11 14 11 18 0 29
31 20 54 -7 19 -64 22 -64 4 0 -9 -2 -10 -8 -2 -4 6 -17 14 -29 18 -13 4 -23
14 -23 22 0 8 -7 14 -15 14 -8 0 -15 7 -15 15 0 8 -4 15 -10 15 -5 0 -10 -7
-10 -15z"/>
<path d="M19253 6073 c-7 -2 -13 -22 -13 -44 0 -28 -4 -39 -15 -39 -19 0 -19
-7 -1 -26 8 -8 26 -14 40 -14 16 0 26 -6 26 -15 0 -8 11 -17 25 -21 14 -3 25
-10 25 -15 0 -5 23 -9 50 -9 28 0 50 5 50 10 0 6 7 10 15 10 8 0 15 -4 15 -10
0 -5 7 -10 15 -10 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 5 -15 10 0 6 5
10 10 10 19 0 10 29 -12 36 -15 4 -25 1 -35 -12 -12 -17 -14 -17 -28 2 -8 10
-15 24 -15 30 0 14 -23 24 -56 24 -28 0 -29 -7 -9 -34 21 -28 19 -36 -10 -36
-15 0 -25 6 -25 14 0 8 -6 17 -12 19 -8 4 -7 6 4 6 13 1 16 10 15 43 -2 43
-14 54 -44 41z"/>
<path d="M10523 5995 c-7 -8 -13 -21 -13 -30 0 -8 -9 -15 -20 -15 -11 0 -20
-7 -20 -15 0 -8 5 -15 10 -15 6 0 10 -5 10 -11 0 -16 -26 -39 -45 -39 -13 0
-15 -6 -10 -29 4 -19 1 -34 -9 -44 -18 -17 -21 -47 -6 -47 16 0 12 -50 -5 -66
-8 -9 -15 -29 -15 -45 0 -44 34 -36 38 8 3 28 8 33 38 39 19 3 34 10 34 15 0
5 11 9 25 9 14 0 25 -4 25 -10 0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 28 10
65 10 59 0 65 2 65 21 0 13 -6 20 -17 18 -10 -1 -24 -1 -30 0 -18 2 -16 41 2
41 11 0 15 11 15 40 0 27 -5 43 -15 46 -8 4 -15 13 -15 20 0 8 -7 17 -15 20
-8 4 -15 13 -15 21 0 9 -8 13 -22 11 -19 -2 -24 2 -26 30 -3 27 -7 32 -30 32
-15 -1 -33 -7 -39 -15z"/>
<path d="M0 5730 c0 -115 3 -140 14 -140 8 0 17 8 19 18 4 13 5 11 6 -5 0 -13
6 -23 12 -23 6 0 4 -7 -5 -15 -8 -8 -22 -15 -31 -15 -13 0 -15 -18 -14 -140
l1 -140 228 0 228 0 6 31 c3 17 6 44 6 60 0 24 -3 28 -31 27 -35 -1 -52 18
-38 43 5 10 9 27 9 38 0 17 -6 21 -36 21 -19 0 -42 7 -51 15 -16 17 -48 12
-71 -11 -11 -12 -11 -16 2 -30 21 -21 21 -21 -19 -16 -21 3 -35 10 -35 18 0 8
-8 14 -17 15 -11 0 -13 3 -5 6 6 2 12 11 12 19 0 8 5 14 10 14 6 0 10 5 10 11
0 18 -26 38 -69 51 -48 15 -52 23 -12 31 20 4 31 14 35 32 6 24 26 36 26 15 0
-5 9 -10 20 -10 11 0 20 5 20 10 0 6 9 10 20 10 15 0 20 7 20 25 0 14 5 25 10
25 15 0 12 39 -4 55 -10 11 -35 14 -93 12 l-78 -3 -3 -32 c-2 -24 -8 -32 -23
-32 -10 0 -19 7 -19 15 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 8 5 15 10 15 15
0 12 60 -4 76 -8 8 -22 14 -30 14 -14 0 -16 -19 -16 -140z"/>
<path d="M5140 5817 c0 -15 9 -29 25 -37 25 -13 33 -30 15 -30 -16 0 -12 -27
5 -34 24 -9 17 -26 -15 -37 -22 -8 -30 -17 -30 -35 0 -27 14 -31 36 -9 9 8 24
15 35 15 10 0 19 7 19 15 0 8 9 15 20 15 11 0 20 5 20 10 0 19 40 50 65 50 32
0 33 28 1 36 -16 4 -26 15 -30 35 -4 21 -11 29 -26 29 -12 0 -20 -7 -20 -16 0
-14 -8 -16 -42 -11 -24 3 -44 11 -46 16 -7 21 -32 11 -32 -12z"/>
<path d="M6668 5813 c-16 -2 -28 -9 -28 -14 0 -5 -7 -9 -15 -9 -8 0 -15 5 -15
10 0 6 -9 10 -20 10 -13 0 -20 -7 -20 -20 0 -11 5 -20 10 -20 18 0 11 -22 -15
-46 -28 -26 -33 -54 -10 -54 11 0 14 -7 9 -25 -5 -19 -2 -25 11 -25 15 0 15
-1 1 -16 -23 -22 -20 -34 9 -34 18 0 25 5 25 19 0 12 9 21 25 25 18 5 25 13
25 30 0 14 7 31 15 40 25 25 17 36 -25 36 -22 0 -40 5 -40 10 0 6 18 10 40 10
34 0 41 -4 46 -25 6 -23 34 -36 34 -15 0 5 17 12 37 16 38 7 63 24 63 43 0 15
-37 14 -44 -1 -5 -10 -7 -10 -12 0 -3 6 -21 12 -39 12 -18 0 -36 -6 -38 -12
-3 -7 -6 -3 -6 10 -1 16 5 22 19 22 11 0 20 7 20 15 0 15 -9 16 -62 8z"/>
<path d="M6978 5805 c-7 -9 -18 -22 -25 -29 -7 -8 -15 -25 -19 -40 -3 -14 -10
-26 -14 -26 -4 0 -10 -13 -13 -30 -4 -16 -12 -30 -20 -30 -14 0 -47 -35 -47
-50 0 -6 -7 -10 -15 -10 -9 0 -20 -11 -25 -25 -7 -18 -17 -25 -35 -25 -14 0
-25 -5 -25 -11 0 -14 25 -39 39 -39 15 0 14 -9 -5 -27 -9 -10 -14 -26 -11 -41
7 -38 -2 -52 -34 -52 -16 0 -29 -4 -29 -9 0 -45 55 -59 66 -16 8 31 20 32 27
3 7 -26 1 -38 -19 -38 -8 0 -14 -9 -14 -20 0 -16 7 -20 35 -20 19 0 35 5 35
10 0 6 16 10 35 10 19 0 35 -5 35 -10 0 -6 34 -10 88 -9 103 2 122 6 122 24 0
18 -23 25 -86 25 -49 0 -54 2 -60 25 -3 14 -12 25 -19 25 -8 0 -10 9 -7 25 3
18 0 25 -12 25 -9 0 -16 7 -16 15 0 8 9 15 20 15 23 0 60 33 60 55 0 8 -7 15
-15 15 -8 0 -15 7 -15 15 0 9 -9 15 -25 15 -15 0 -25 -6 -25 -14 0 -8 -15 -17
-35 -20 -21 -4 -35 -13 -35 -21 0 -9 -6 -12 -15 -9 -8 4 -15 12 -15 20 0 8 7
14 15 14 8 0 15 7 15 15 0 8 7 15 15 15 8 0 15 6 15 14 0 20 19 24 62 13 43
-11 68 -4 68 18 0 11 -12 15 -47 16 -27 1 -40 3 -30 6 9 2 17 9 17 14 0 5 7 9
15 9 8 0 15 -4 15 -10 0 -16 27 -12 34 5 3 9 18 15 36 15 42 0 39 26 -4 34
-23 4 -36 13 -39 28 -7 26 -1 38 19 38 8 0 14 5 14 10 0 6 7 10 15 10 18 0 19
7 2 24 -23 23 -54 28 -69 11z"/>
<path d="M13023 5813 c-18 -7 -16 -33 2 -33 9 0 15 -9 15 -25 0 -14 9 -34 20
-45 22 -22 75 -27 84 -7 5 10 7 10 12 0 8 -18 31 -16 38 2 3 9 18 15 36 15 20
0 30 5 30 15 0 8 7 15 15 15 8 0 15 7 15 16 0 14 -7 16 -40 11 -37 -5 -109 9
-120 24 -7 8 -92 18 -107 12z"/>
<path d="M19965 5770 c4 -14 2 -20 -8 -20 -10 0 -17 -16 -21 -49 -4 -31 -12
-51 -22 -55 -14 -5 -15 -1 -9 31 4 21 11 40 16 41 5 2 9 11 9 19 0 12 -7 14
-31 9 -17 -3 -38 -6 -45 -6 -8 0 -14 -7 -14 -15 0 -8 5 -15 11 -15 6 0 17 -7
25 -15 9 -8 11 -15 5 -15 -6 0 -11 -13 -11 -30 0 -20 5 -30 15 -30 8 0 15 -9
15 -19 0 -14 -5 -18 -20 -14 -11 3 -20 0 -20 -6 0 -6 7 -11 15 -11 8 0 15 -4
15 -10 0 -15 31 -12 44 5 7 9 19 12 34 9 22 -6 31 -24 12 -24 -5 0 -10 -7 -10
-15 0 -9 9 -15 21 -15 20 0 21 -3 14 -65 l-7 -65 37 0 c34 0 36 2 35 28 -3 44
-1 52 15 52 8 0 15 5 15 10 0 6 -6 10 -14 10 -16 0 -26 50 -26 133 0 54 -1 57
-25 57 -37 0 -32 29 5 37 29 5 30 8 24 37 -8 39 -8 39 -33 31 -12 -3 -21 -14
-22 -23 0 -12 -3 -11 -9 6 -12 28 -44 31 -35 2z"/>
<path d="M10930 5760 c0 -5 -7 -10 -15 -10 -8 0 -15 -9 -15 -20 0 -13 -7 -20
-20 -20 -11 0 -20 -4 -20 -10 0 -5 -6 -10 -13 -10 -8 0 -20 -11 -27 -25 -15
-28 -30 -33 -30 -10 0 8 5 15 10 15 6 0 10 5 10 10 0 6 -9 10 -20 10 -11 0
-20 -4 -20 -10 0 -5 -6 -10 -14 -10 -16 0 -30 -80 -16 -94 5 -5 34 -5 69 1 33
5 66 7 73 3 7 -3 39 -4 71 -2 40 3 57 8 57 18 0 8 10 14 25 14 16 0 25 6 25
15 0 8 -7 15 -15 15 -8 0 -15 6 -15 14 0 7 -9 16 -20 19 -12 3 -20 14 -20 26
0 12 -4 21 -10 21 -5 0 -12 11 -16 25 -6 24 -34 36 -34 15z"/>
<path d="M6430 5736 c0 -7 9 -16 20 -19 25 -6 27 -37 2 -37 -68 0 -45 -123 25
-136 18 -3 40 -3 49 0 22 9 13 40 -14 44 -30 5 -28 22 3 22 21 0 25 5 25 30 0
18 -5 30 -13 30 -14 0 -37 40 -37 65 0 10 -10 15 -30 15 -19 0 -30 -5 -30 -14z"/>
<path d="M5700 5730 c0 -5 -13 -10 -30 -10 -20 0 -30 -5 -30 -15 0 -8 6 -15
13 -15 8 0 8 -3 1 -8 -6 -4 -13 -15 -17 -24 -3 -10 -13 -18 -22 -18 -22 0 -20
-59 3 -67 9 -4 23 -13 30 -20 7 -7 20 -13 28 -13 8 0 14 -4 14 -10 0 -5 -4
-10 -10 -10 -24 0 -7 -31 20 -36 17 -4 30 -13 30 -20 0 -9 12 -14 35 -14 19 0
35 4 35 9 0 5 6 16 13 23 8 7 17 30 21 51 6 30 4 37 -8 37 -9 0 -21 9 -28 20
-15 24 -58 27 -58 5 0 -8 7 -15 15 -15 8 0 15 -7 15 -15 0 -8 -7 -15 -16 -15
-8 0 -22 7 -30 15 -9 8 -11 15 -5 15 7 0 11 30 11 80 0 64 -3 80 -15 80 -8 0
-15 -4 -15 -10z"/>
<path d="M20230 5716 c0 -13 -4 -26 -9 -28 -5 -1 -12 -18 -16 -35 -5 -26 -3
-33 9 -33 34 0 14 -28 -29 -40 -37 -10 -45 -17 -45 -37 0 -13 -7 -26 -15 -29
-25 -10 -18 -44 9 -44 25 0 36 -16 36 -55 0 -16 7 -25 26 -29 33 -9 34 -7 41
45 5 32 3 41 -8 37 -7 -2 -16 -15 -19 -29 l-6 -24 -7 25 c-4 14 -7 38 -7 53 0
25 3 27 23 21 12 -3 24 -11 25 -15 2 -5 17 -9 33 -9 33 0 38 19 12 46 -16 16
-16 16 6 9 22 -7 22 -5 15 42 -8 60 -10 63 -40 63 -28 0 -31 8 -8 25 12 8 15
20 10 38 -8 34 -36 36 -36 3z"/>
<path d="M19457 5660 c-25 -29 -21 -38 18 -46 20 -3 35 -12 35 -20 0 -8 11
-14 28 -15 15 0 21 -3 15 -6 -7 -2 -13 -16 -13 -29 0 -15 -6 -24 -16 -24 -10
0 -14 -6 -10 -15 3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 -6 15 -14 15 -25 0
-46 -12 -46 -26 0 -8 -10 -14 -25 -14 -21 0 -25 5 -25 29 0 17 -6 31 -16 35
-24 9 -34 7 -34 -7 0 -7 -9 -20 -20 -30 -19 -16 -22 -16 -36 -2 -9 8 -11 15
-5 15 6 0 13 11 17 25 5 19 13 25 35 25 22 0 29 5 29 20 0 11 -4 20 -10 20 -5
0 -10 7 -10 15 0 9 -9 15 -25 15 -16 0 -25 -6 -25 -15 0 -11 -12 -15 -44 -15
-36 0 -46 -4 -51 -20 -3 -11 -11 -20 -16 -20 -16 0 -39 -27 -39 -44 0 -9 7
-16 15 -16 8 0 15 6 15 14 0 8 14 15 35 18 20 2 35 0 35 -6 0 -14 -50 -56 -67
-56 -29 0 -12 -29 22 -36 35 -6 46 -19 23 -26 -18 -6 0 -28 23 -28 24 0 49 22
49 44 0 9 8 16 19 16 11 0 22 6 25 13 3 6 3 0 0 -15 -5 -20 -2 -30 10 -34 24
-9 20 -44 -4 -44 -11 0 -23 -7 -26 -15 -9 -23 -44 -19 -44 5 0 24 -32 27 -50
5 -10 -11 -9 -18 4 -30 9 -9 16 -26 16 -37 0 -17 5 -20 35 -16 19 2 35 0 35
-5 0 -4 21 -7 48 -5 40 3 47 6 50 26 3 17 -2 22 -17 22 -12 0 -21 2 -21 5 0 9
36 41 53 48 9 3 17 13 17 22 0 8 5 15 10 15 6 0 10 -7 10 -15 0 -9 7 -18 15
-21 8 -4 15 -12 15 -20 0 -8 -7 -14 -15 -14 -8 0 -15 -7 -15 -15 0 -8 -4 -15
-10 -15 -5 0 -10 -9 -10 -20 0 -17 7 -20 45 -20 26 0 45 5 45 11 0 7 10 9 28
5 15 -4 73 -9 130 -12 97 -5 102 -5 102 14 0 11 -6 23 -12 26 -7 3 -3 3 10 0
25 -6 27 1 10 40 -12 26 -16 27 -57 22 -24 -3 -52 -6 -62 -6 -29 0 -26 -47 4
-53 16 -3 13 -5 -10 -6 -27 -1 -33 3 -33 20 0 17 -5 20 -21 16 -12 -3 -22 0
-26 9 -3 8 -15 14 -28 14 -18 0 -24 7 -29 34 -4 19 -12 37 -19 39 -9 4 -9 6 1
6 7 1 16 -7 19 -16 10 -27 26 -40 60 -47 26 -6 31 -4 37 16 3 13 6 33 6 45 0
12 6 23 12 25 9 3 8 9 -4 21 -20 21 -38 22 -38 3 0 -9 -7 -12 -20 -9 -23 6
-27 23 -6 23 7 0 16 9 19 20 6 23 27 27 27 5 0 -25 27 -17 34 10 4 14 11 25
16 25 6 0 10 5 10 10 0 6 -10 10 -22 9 -59 -5 -98 1 -98 16 0 8 -9 15 -20 15
-16 0 -20 7 -20 30 0 26 -4 30 -28 30 -15 0 -35 -9 -45 -20z"/>
<path d="M1060 5635 c0 -9 -9 -15 -25 -15 -22 0 -25 -4 -25 -35 0 -31 3 -36
30 -41 23 -5 30 -12 30 -30 0 -15 6 -24 15 -24 8 0 15 -4 15 -10 0 -5 7 -10
15 -10 8 0 15 -5 15 -11 0 -6 -7 -17 -15 -25 -22 -22 -18 -42 8 -47 l22 -4
-22 -8 c-29 -9 -30 -50 -4 -82 15 -18 30 -23 65 -23 39 0 46 3 46 20 0 22 -35
29 -43 8 -5 -16 -13 -10 -21 20 -6 18 -3 22 13 22 12 0 21 -4 21 -10 0 -5 9
-10 20 -10 17 0 20 6 18 42 -1 24 3 43 10 46 17 5 15 32 -3 32 -8 0 -15 -7
-15 -15 0 -8 -7 -15 -15 -15 -10 0 -15 10 -15 30 0 24 -4 30 -22 31 -21 1 -20
2 5 11 23 8 27 15 27 49 0 45 -24 64 -45 36 -15 -21 -35 -22 -35 -2 0 8 7 15
15 15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 9 -15 20 0 16 -7 20 -35 20
-24 0 -35 -5 -35 -15z"/>
<path d="M19705 5620 c-8 -24 1 -40 21 -40 8 0 14 -7 14 -15 0 -8 -7 -15 -15
-15 -20 0 -19 -34 2 -68 29 -45 63 -58 63 -23 0 10 11 29 25 42 19 18 25 34
25 67 0 23 -4 42 -9 42 -5 0 -16 3 -24 6 -8 3 -22 -2 -31 -11 -15 -15 -16 -14
-16 9 0 21 -5 26 -24 26 -16 0 -27 -7 -31 -20z"/>
<path d="M11083 5572 c-6 -4 -13 -28 -15 -52 l-3 -45 -48 0 c-27 0 -51 3 -53
7 -3 4 -21 8 -41 8 -35 0 -35 -1 -29 -32 7 -35 36 -68 61 -68 8 0 15 -4 15
-10 0 -5 7 -10 15 -10 8 0 15 -4 15 -10 0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6
12 16 28 23 15 6 30 16 34 22 5 6 11 6 20 -3 6 -6 36 -12 65 -12 48 0 53 2 59
25 5 19 13 25 35 25 16 0 29 -4 29 -10 0 -5 -7 -10 -15 -10 -11 0 -15 -11 -15
-39 0 -22 -7 -46 -15 -55 -22 -22 -18 -36 9 -36 14 0 28 -4 31 -10 3 -5 42
-10 85 -10 70 0 79 2 85 20 3 11 13 20 21 20 8 0 14 6 14 14 0 17 33 27 51 14
11 -7 11 -11 2 -15 -18 -7 -16 -24 5 -39 20 -16 152 -19 152 -4 0 6 -4 10 -10
10 -5 0 -10 7 -10 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 16 0 12 7 14 33 9 17
-4 34 -11 35 -16 7 -17 23 -9 56 27 37 41 56 38 56 -7 0 -19 5 -29 15 -29 9 0
15 9 15 24 0 13 6 27 13 29 9 4 9 8 0 14 -6 4 -15 21 -18 36 -5 20 -13 27 -31
27 -28 0 -30 6 -9 26 20 20 19 44 -1 44 -10 0 -14 -6 -10 -15 4 -11 -1 -15
-19 -15 -18 0 -25 -5 -25 -20 0 -11 -8 -24 -17 -27 -23 -9 -43 -29 -43 -42 0
-7 -24 -11 -59 -11 -38 0 -61 -4 -64 -12 -4 -10 -7 -10 -15 0 -5 7 -25 12 -48
11 -63 -3 -82 -9 -87 -31 -5 -17 -11 -20 -38 -14 -17 3 -40 6 -50 6 -11 0 -19
5 -19 10 0 6 7 10 15 10 8 0 15 5 15 10 0 6 15 8 40 4 33 -5 40 -4 40 10 0 8
11 18 25 22 18 4 25 13 25 30 0 17 -7 25 -25 30 -14 3 -25 12 -25 20 0 8 -7
14 -15 14 -8 0 -15 -9 -15 -21 0 -15 -5 -19 -17 -15 -10 3 -35 8 -55 12 -32 5
-38 10 -38 30 0 20 -5 24 -30 24 -20 0 -30 -5 -30 -15 0 -10 -11 -15 -35 -15
-30 0 -35 -3 -35 -22 0 -34 -11 -68 -21 -68 -5 0 -9 12 -9 26 0 19 -4 25 -15
20 -15 -6 -21 24 -16 87 2 27 -5 33 -26 19z"/>
<path d="M6590 5535 c0 -10 -10 -15 -28 -15 -55 0 -99 -65 -49 -72 16 -2 23
-11 25 -30 3 -25 8 -28 38 -28 27 0 34 -4 34 -20 0 -13 -7 -20 -19 -20 -47 0
-63 -20 -37 -46 22 -21 33 -17 48 16 9 21 20 30 35 30 14 0 23 6 23 15 0 8 7
15 15 15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15 0 8 -4 15 -10 15
-5 0 -10 9 -10 20 0 11 7 20 15 20 16 0 18 12 9 48 -5 17 -14 22 -40 22 -24 0
-34 -5 -34 -15z"/>
<path d="M12203 5423 c-7 -15 -13 -37 -13 -50 0 -46 -43 -18 -55 35 -7 35 -30
43 -39 15 -3 -10 -2 -31 2 -48 6 -23 15 -31 35 -33 35 -4 35 -20 0 -24 -20 -2
-29 -9 -31 -25 -3 -21 1 -23 42 -23 25 0 46 5 46 10 0 6 -4 10 -10 10 -5 0
-10 7 -10 16 0 11 5 14 16 10 9 -3 22 -6 30 -6 8 0 14 -7 14 -15 0 -16 17 -19
44 -9 25 10 19 34 -9 34 -16 0 -25 6 -25 15 0 8 5 15 10 15 6 0 10 9 10 19 0
11 7 26 15 35 28 27 18 46 -22 46 -31 0 -40 -5 -50 -27z"/>
<path d="M12370 5380 c0 -6 -18 -10 -40 -10 -22 0 -40 -4 -40 -9 0 -27 15 -41
41 -41 22 0 29 -5 29 -19 0 -13 10 -20 31 -25 50 -10 179 -7 179 4 0 6 -18 10
-40 10 -38 0 -52 12 -30 25 14 9 12 52 -2 62 -19 12 -128 14 -128 3z"/>
</g>
</svg>
`;
