import React from 'react';
import JsxParser from 'react-jsx-parser';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

import HighlightCarousel from 'src/components/common/carousels/HighlightCarousel';
import { Highlight } from 'src/types/common';
import { stripHTMLTags } from 'src/utils/utils';

interface HighlightInfoProps {
  highlight_ids: number[];
  highlights?: Highlight[];
  highlightLinkButton: JSX.Element;
  body?: string;
}

function HighlightInfo({
  highlightLinkButton,
  highlights,
  highlight_ids,
  body,
}: HighlightInfoProps) {
  // used to test whether or not a highlight's body paragraph is empty
  const strippedBody = body ? stripHTMLTags(body) : '';
  return (
    <Grid container>
      {body && strippedBody !== '' && (
        <Grid item md={4} sx={{ marginTop: '3rem' }} paddingLeft={8}>
          <Typography
            variant="body2"
            sx={{ paddingBottom: '3rem', color: 'inherit' }}
          >
            {/* @ts-ignore - ignore */}
            <JsxParser jsx={body} />
          </Typography>
        </Grid>
      )}
      <Grid
        item
        md={12}
        sx={{ marginTop: '3rem', marginX: 'auto' }}
        data-testid="highlight-carousel-container"
      >
        {highlights && (
          <HighlightCarousel
            highlights={highlights}
            highlight_ids={highlight_ids}
            id="normal"
          />
        )}
        {highlightLinkButton}
      </Grid>
    </Grid>
  );
}

export default HighlightInfo;
