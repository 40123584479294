import { FilterGroup, FilterType } from 'src/types/filters';

export const FILTERS: FilterGroup[] = [
  {
    label: 'Topics',
    id: -1,
    type: FilterType.demographic,
    options: [
      {
        key: 'codes',
        type: 'multi',
        match: [9, 10, 11, 12, 13],
        label: 'Client Engagement',
      },
      {
        key: 'codes',
        type: 'multi',
        match: [14, 15, 16, 17],
        label: 'Training + Development',
      },
      {
        key: 'codes',
        type: 'multi',
        match: [18, 19, 20, 21, 22],
        label: 'Work-Life Balance',
      },
      {
        key: 'codes',
        type: 'multi',
        match: [23, 24, 25, 26],
        label: 'Relationships',
      },
      {
        key: 'codes',
        type: 'multi',
        match: [27, 28, 29, 30, 31],
        label: 'Remote Working Experience',
      },
      {
        key: 'codes',
        type: 'multi',
        match: [32, 33, 34, 35],
        label: 'Intra-Organizational',
      },
      {
        key: 'codes',
        type: 'multi',
        match: [36, 37, 38, 39, 40, 41],
        label: 'Culture',
      },
      {
        key: 'codes',
        type: 'multi',
        match: [42, 43, 44, 45, 46],
        label: 'Process',
      },
    ],
  },
  {
    label: 'Tenure',
    id: 0,
    type: FilterType.demographic,
    options: [
      { key: 'demographics', type: 'single', match: 1, label: '0-5 years' },
      { key: 'demographics', type: 'single', match: 2, label: '5-10 years' },
      { key: 'demographics', type: 'single', match: 3, label: '10-15 years' },
      { key: 'demographics', type: 'single', match: 4, label: '15+ years' },
    ],
  },
  {
    label: 'Prompts',
    id: 1,
    type: FilterType.structural,
    options: [
      { key: 'codes', type: 'single', match: 2, label: 'opportunities' },
      { key: 'codes', type: 'single', match: 3, label: 'challenges' },
      {
        key: 'codes',
        type: 'single',
        match: 4,
        label: 'lived experiences',
      },
      {
        key: 'codes',
        type: 'single',
        match: 5,
        label: 'resonating experiences',
      },
      { key: 'codes', type: 'single', match: 6, label: 'fmcs future' },
      { key: 'codes', type: 'single', match: 7, label: 'takeaways' },
      { key: 'codes', type: 'single', match: 8, label: 'lived experences' },
    ],
  },
  {
    label: 'Regions',
    id: 5,
    type: FilterType.demographic,
    options: [
      { key: 'demographics', type: 'single', match: 6, label: 'JA Africa' },
      { key: 'demographics', type: 'single', match: 7, label: 'JA Americas' },
      {
        key: 'demographics',
        type: 'single',
        match: 8,
        label: 'JA Asia Pacific',
      },
      { key: 'demographics', type: 'single', match: 9, label: 'JA Europe' },
      {
        key: 'demographics',
        type: 'single',
        match: 10,
        label: 'INJAZ Al-Arab JA MENA',
      },
      {
        key: 'demographics',
        type: 'single',
        match: 11,
        label: 'JA USA',
      },
    ],
  },
];
