import React from 'react';
import isHotkey from 'is-hotkey';
import { createEditor, Descendant } from 'slate';
import { withHistory } from 'slate-history';
import {
  Editable,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react';

import EditorToolbar from './EditorToolbar';
import { useEditorToolbar } from './utils/useEditorToolbar';
import { HOTKEYS, HTMLToSlate, slateToHTML } from './utils/utils';

const CustomEditor = React.forwardRef(
  (
    {
      initialText,
      embed,
      onChange,
    }: {
      initialText: string;
      embed?: boolean;
      onChange?: (value: string) => void;
    },
    ref
  ) => {
    const [editor] = React.useState(withHistory(withReact(createEditor())));
    const embedStartTag = new RegExp('<Embed', 'g');
    const embedCloseTag = new RegExp('</Embed', 'g');
    let modifiedText = initialText
      .replace(embedStartTag, '<E')
      .replace(embedCloseTag, '</E');
    if (!modifiedText.startsWith('<p>')) {
      modifiedText = `<p>${modifiedText}</p>`; // needed for processing
    }
    const document = new DOMParser().parseFromString(
      modifiedText ?? '<p></p>',
      'text/html'
    );
    const initialValue = HTMLToSlate(document.body) as Descendant[];
    const handleChange = (value: Descendant[]) => {
      // Reconvert text to html string
      const text = slateToHTML({
        children: value,
      });
      onChange && onChange(text);
      if (ref) {
        (ref as React.MutableRefObject<string>).current = text;
      }
    };

    return (
      <div
        data-testid="custom-editor-field"
        style={{
          backgroundColor: 'white',
          padding: '1rem 1.5rem',
          borderRadius: '5px',
        }}
      >
        <Slate
          editor={editor}
          initialValue={initialValue}
          onChange={handleChange}
        >
          <EditorBody embed={embed} />
        </Slate>
      </div>
    );
  }
);

const EditorBody = ({ embed }: { embed?: boolean }) => {
  const renderElement = React.useCallback(
    ({ attributes, children, element }: RenderElementProps) => {
      switch (element.type) {
        case 'bulleted-list':
          return <ul {...attributes}>{children}</ul>;
        case 'numbered-list':
          return <ol {...attributes}>{children}</ol>;
        case 'list-item':
          return <li {...attributes}>{children}</li>;
        default:
          return <p {...attributes}>{children}</p>;
      }
    },
    []
  );

  const renderLeaf = React.useCallback(
    ({ attributes, children, leaf }: RenderLeafProps) => {
      if (leaf.bold) {
        return (
          <span {...attributes}>
            <strong>{children}</strong>
          </span>
        );
      }
      if (leaf.italic) {
        return (
          <span {...attributes}>
            <em>{children}</em>
          </span>
        );
      }
      if (leaf.embed) {
        return (
          <span {...attributes} style={{ color: '#4F29B7' }}>
            <u>{children}</u>
          </span>
        );
      }
      return <span {...attributes}>{children}</span>;
    },
    []
  );

  const toolbarProps = useEditorToolbar(embed);
  const { toggleMark } = toolbarProps;
  return (
    <>
      <EditorToolbar {...toolbarProps} embed={embed} />
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        data-testid="string-editor-input"
        spellCheck
        onKeyDown={(event) => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event as any)) {
              const mark = HOTKEYS[hotkey];
              toggleMark(event, mark);
            }
          }
        }}
      />
    </>
  );
};

export default CustomEditor;
