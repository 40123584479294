import { Codes, PageBase } from 'src/types/common';
import { AppState } from '../redux/app/app-slice';
import { codesToFilters, generateCodes } from './code';
import {
  generateEditMetadata,
  incrementInternalVersion,
  LATEST_INTERNAL_VERSION,
  mergeEditMetadata,
} from './editmetadata';
import { INITIAL_ORGANIZATION_METADATA } from './initial/organization_metadata';
import { INITIAL_PAGES } from './initial/pages/pages';
import { INITIAL_ROUTES } from './initial/routes';
import {
  getFilterOrder,
  getInsightsShowcase,
  modifyInsights,
  modifyMap,
  modifyVoicesRoutes,
} from './pages';
import { getBootstrapData } from './version';

/**
 * This function takes bootstrap data, and converts it into the pages necessarry for an app.
 * It will process the highlights and generate pages for things that are found in the highlights.
 * only runs while in editing mode.
 */
export const setupApp = async (): Promise<AppState> => {
  const bootstrap_data = await getBootstrapData();

  const initialState: AppState = {
    editAccess: true,
    organization_metadata: INITIAL_ORGANIZATION_METADATA,
    routes: INITIAL_ROUTES,
    pages: INITIAL_PAGES,
    filters: [],
    highlights: [],
    codes: {} as Codes,
    analytic_codes: [],
    demographic_codes: [],
    map: [],
    conversations: [],
    unpublished_draft: false,
    editing: false,
    changes_made: !getBootstrapData(),
    request_sent: false,
    error_message: undefined,
    page_edit_metadata: [],
    version: 0,
    embedURL: '',
    internalVersion: LATEST_INTERNAL_VERSION,
    insights_data_timestamp: '',
  };
  const analytic = generateCodes(
    bootstrap_data.codes?.analytic ?? [],
    bootstrap_data.highlights,
    bootstrap_data.analytic_codes
  );
  const demographic = generateCodes(
    bootstrap_data.codes?.demographic ?? [],
    bootstrap_data.highlights,
    bootstrap_data.demographic_codes
  );
  const codes = {
    analytic,
    demographic,
  };

  const data = {
    ...initialState,
    ...bootstrap_data,
    codes,
  } as AppState;
  // before modifying the data, we need to merge any new changes to edit_metadata with whatever's pulled from the bootstrap data
  const { mergedPages, mergedOrganizationMetadata } = mergeEditMetadata(
    data.pages,
    data.organization_metadata,
    initialState.pages,
    initialState.organization_metadata
  );
  data.pages = mergedPages;
  data.organization_metadata = mergedOrganizationMetadata;
  // make sure any structural updates to pages are run, and the internalVersion gets updated.
  incrementInternalVersion(data);
  document.title = `${data.organization_metadata.short_name}`;

  // Update filters and get filter order
  const filters = codesToFilters(codes);
  const highlightsModification = getFilterOrder(data.pages, filters);
  if (!data.editAccess) {
    return { ...data, pages: highlightsModification.pages, filters };
  }
  const insightsModification = modifyInsights(
    data.routes,
    highlightsModification.pages,
    codes.analytic,
    data.organization_metadata.mui_themes
  );
  const voicesModification = modifyVoicesRoutes(
    insightsModification.routes,
    insightsModification.pages,
    !!data.conversations
  );
  const homePageModification = getInsightsShowcase(
    voicesModification.pages,
    codes.analytic
  );
  const mapModification: { pages: PageBase[] } = modifyMap(
    homePageModification.pages,
    data.map,
    codes.demographic
  );
  const routes = voicesModification.routes;
  const pages = mapModification.pages;

  const page_edit_metadata = generateEditMetadata(pages, routes);
  return {
    ...data,
    page_edit_metadata,
    codes,
    filters,
    routes,
    pages,
  };
};
