import React from 'react';
import { Grid } from '@mui/material';

import { Palette } from 'src/types/organization_metadata';
import ColorEditor from './ColorEditor';

const PaletteEditor = React.forwardRef(
  ({ initialPalette }: { initialPalette: Palette }, ref) => {
    const centerStyle = {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginY: '1rem',
    };
    const [palette, setPalette] = React.useState<Palette>(initialPalette);
    const handleChange = (value: string, type: keyof Palette) => {
      const newPalette = { ...palette };
      switch (type) {
        case 'main':
          newPalette.main = value;
          break;
        case 'light':
          newPalette.light = value;
          break;
        case 'dark':
          newPalette.dark = value;
          break;
        case 'contrastText':
          newPalette.contrastText = value;
          break;
      }
      if (ref) {
        (ref as React.MutableRefObject<Palette>).current = newPalette;
      }
      setPalette(newPalette);
    };
    return (
      <Grid container>
        <Grid item xs={6} sx={centerStyle}>
          <ColorEditor
            label={'Main'}
            color={palette.main}
            onColorChange={(color) => {
              handleChange(color.hex, 'main');
            }}
          />
        </Grid>
        <Grid item xs={6} sx={centerStyle}>
          <ColorEditor
            label={'Light'}
            color={palette.light}
            onColorChange={(color) => {
              handleChange(color.hex, 'light');
            }}
          />
        </Grid>
        <Grid item xs={6} sx={centerStyle}>
          <ColorEditor
            label={'Dark'}
            color={palette.dark}
            onColorChange={(color) => {
              handleChange(color.hex, 'dark');
            }}
          />
        </Grid>
        <Grid item xs={6} sx={centerStyle}>
          <ColorEditor
            label={'Contrast Text'}
            color={palette.contrastText}
            onColorChange={(color) => {
              handleChange(color.hex, 'contrastText');
            }}
          />
        </Grid>
      </Grid>
    );
  }
);

export default PaletteEditor;
