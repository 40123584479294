import React from 'react';

export const EDITING_QUERY = '?editing';

export const useGeneratePath = () => {
  const isEditing = location.search.includes(EDITING_QUERY);
  const generatePath = React.useCallback(
    (path: string) => {
      // need to pre-encode query string, as the URL constructor doesn't properly encode some special characters like +
      const preEncodedPath: string = path.replace(
        /=.*$/,
        (queryString: string) => {
          return `=${encodeURIComponent(queryString.slice(1))}`;
        }
      );
      const location = new URL(preEncodedPath, window.location.origin);
      if (isEditing) {
        location.search = EDITING_QUERY + '&' + location.search.slice(1);
      }
      let newPath = location.pathname + location.search;
      if (newPath.endsWith('&')) {
        newPath = newPath.slice(0, newPath.length - 1);
      }
      return newPath;
    },
    [isEditing]
  );

  return generatePath;
};
