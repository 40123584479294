import React from 'react';

import { BannerItem } from 'src/types/common';
import FullBanner from './FullBanner';
import SplitBanner from './SplitBanner';
import ThinBanner from './ThinBanner';

export const getBanner = (config: BannerItem) => {
  switch (config.header_type) {
    case 'full':
      return (
        <FullBanner
          title={config.title}
          body={config.body}
          picture={config.image}
          navigation={config.navigation}
          secondary={config.secondary}
          large={config.large}
          texture={config.texture}
          center={config.center}
          icon={config.icon}
        />
      );

    case 'split':
      return (
        <SplitBanner
          title={config.title}
          body={config.body}
          picture={config.image}
          secondary={config.secondary}
          large={config.large}
          texture={config.texture}
          icon={config.icon}
        />
      );
    case 'thin':
      return <ThinBanner title={config.title} icon={config.icon} />;
  }
};
