import React from 'react';
import JsxParser from 'react-jsx-parser';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

interface ThinBannerProps {
  title: string;
  icon?: React.ReactElement; // Icon element that contains any needed child components
  children?: React.ReactElement;
  isSubBanner?: boolean;
}

function ThinBanner({ title, icon, children, isSubBanner }: ThinBannerProps) {
  return (
    <Grid
      item
      data-testid="thin-banner-grid"
      xs={12}
      id={`section-${title}`}
      sx={{ backgroundColor: 'primary.dark', color: 'white' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          paddingX: { xs: 2, md: 3 },
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        <Grid
          container
          item
          xs={10}
          sx={{ justifyContent: 'center', flexDirection: 'column' }}
        >
          <Box sx={{ display: 'flex' }}>
            {!isSubBanner && (
              <Typography
                noWrap
                component="span"
                variant="body1"
                paddingRight={2.25}
                paddingLeft={2}
                sx={{ fontWeight: '600' }}
              >
                <div style={{ display: 'inline-block' }}>
                  {/* @ts-ignore - jsxparser */}
                  <JsxParser jsx={title} />
                </div>
              </Typography>
            )}
            {icon}
          </Box>
        </Grid>
        <Grid
          container
          item
          xs={2}
          sx={{ justifyContent: 'end', paddingRight: '2rem' }}
        >
          {children}
        </Grid>
      </Box>
    </Grid>
  );
}

export default ThinBanner;
