import { slugify } from 'src/utils/utils';
export const formatCodes = (
  parentLabel: string | undefined,
  childLabel: string | undefined
) => {
  if (parentLabel === undefined || childLabel === undefined) {
    return '/';
  }

  const formattedParent: string = slugify(parentLabel);
  const formattedChild: string = slugify(childLabel);

  const highlightExplorerUrl = `/voices/highlight-explorer?${formattedParent}=${formattedChild}`;

  return highlightExplorerUrl;
};
