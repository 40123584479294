import React, { useEffect } from 'react';
import JsxParser from 'react-jsx-parser';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { get } from 'lodash';

import HighlightContainer from 'src/components/Audio/Highlight/Highlight';
import Section from 'src/components/common/background/Section';
import { getBanner } from 'src/components/common/banners/utils';
import FloatingCard from 'src/components/common/cards/FloatingCard';
import appSelectors from 'src/redux/app/app-selectors';
import { StoreState } from 'src/redux/store';
import { Highlight, PageProps } from 'src/types/common';
import { ProcessPage } from 'src/types/pages/about';
import { slugify } from 'src/utils/utils';

interface ProcessSection {
  label: string;
  visible: boolean;
  text: string;
  image: string;
}

const Process = ({ page_data_id }: PageProps) => {
  const pageInfo = useSelector((state: StoreState) =>
    appSelectors.getPage(state, page_data_id)
  ) as ProcessPage;
  const highlights: Highlight[] = useSelector(appSelectors.getHighlights);
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!pageInfo) {
    return <></>;
  }

  const visibleSections: ProcessSection[] = pageInfo.step_order
    .map((step) => get(pageInfo, step) as ProcessSection)
    .filter((step) => step.visible);

  return (
    <div>
      {pageInfo.header?.visible && (
        <div
          data-edit-id={`${pageInfo.page_id}.header`}
          style={{ width: '100%' }}
        >
          {getBanner(pageInfo.header.content)}
        </div>
      )}
      {pageInfo.introduction?.visible && (
        <div
          data-edit-id={`${pageInfo.page_id}.introduction`}
          style={{ width: '100%' }}
        >
          <FloatingCard
            title={pageInfo.introduction.content.title}
            body={pageInfo.introduction.content.body}
            texture={pageInfo.introduction.content.texture}
          >
            {pageInfo.introduction.content.featured_highlight ? (
              <Box marginTop={3}>
                <HighlightContainer
                  sectionId={`featured-highlight`}
                  highlight={
                    highlights.find(
                      (highlight) =>
                        highlight.id ===
                        pageInfo.introduction.content.featured_highlight
                    )!
                  }
                  scrolling
                  light
                />
              </Box>
            ) : (
              <></>
            )}
            {/* TODO: Uncomment when we figure out how we'd like to handle the generation and retrieval of Medleys */}
            {/* {pageInfo.introduction?.content.medley ? (
              <Medley
                highlightId={pageInfo.introduction.content.medley.id}
                duration={pageInfo.introduction.content.medley.duration}
                title={pageInfo.introduction.content.medley.title}
              />
            ) : (
              <></>
            )} */}
          </FloatingCard>
        </div>
      )}
      {visibleSections.length && (
        <Grid>
          {visibleSections.map((step, idx) => (
            <div
              key={step.label}
              data-edit-id={`${pageInfo.page_id}.${pageInfo.step_order[idx]
                .replace(' ', '_')
                .toLowerCase()}`}
              style={{ width: '100%' }}
            >
              <Section
                sx={{
                  margin: '0',
                  padding: isSmallScreen ? '2.5rem 0' : '5rem 0',
                }}
                containerSx={{
                  display: 'flex',
                  margin: isSmallScreen ? '0 24px' : 'auto',
                  flexDirection: isSmallScreen
                    ? 'column'
                    : idx % 2 === 0
                    ? 'row'
                    : 'row-reverse',
                }}
              >
                {step.image.length > 0 ? (
                  <Grid
                    item
                    md={4}
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: isSmallScreen ? '2rem' : 'unset',
                    }}
                  >
                    <img src={step.image} width="100%" />
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid
                  item
                  md={step.image.length > 0 ? 8 : 12}
                  sx={{ display: 'flex', alignItems: 'center' }}
                  id={`screen-${slugify(step.label)}`}
                  className="jump-link"
                >
                  <Grid
                    container
                    sx={{
                      justifyContent: 'flex-end',
                      flexDirection: isSmallScreen
                        ? 'column'
                        : idx % 2 === 0
                        ? 'row'
                        : 'row-reverse',
                    }}
                  >
                    <Grid
                      item
                      sm={12}
                      sx={{
                        maxWidth: isSmallScreen
                          ? 'unset'
                          : step.image.length > 0
                          ? '90% !important'
                          : 'unset',
                      }}
                    >
                      <Typography variant="h5" color="primary.dark">
                        {/* @ts-ignore - jsx parser */}
                        {step.label && <JsxParser jsx={step.label} />}
                      </Typography>
                      {/* @ts-ignore - ignore */}
                      {step.text && <JsxParser jsx={step.text} />}
                    </Grid>
                  </Grid>
                </Grid>
              </Section>
            </div>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default Process;
