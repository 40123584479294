import React, { useEffect, useRef } from 'react';
import { Waypoint } from 'react-waypoint';
import { Grid, useMediaQuery } from '@mui/material';
import _ from 'lodash';

import { Highlight, HighlightLinkStyles, Paragraph } from 'src/types/common';
import { slugify } from 'src/utils/utils';
import { SelectedEmbed } from '../Audio/ZagatEmbed';
import SplitBackgroundGrid from '../common/background/SplitBackgroundGrid';
import ConversationModal from '../ConversationLibrary/ConversationModal';
import HighlightBlock from './HighlightBlock';
import ParagraphBlock from './ParagraphBlock';

interface ParagraphContainerProps extends HighlightLinkStyles {
  paragraph: Paragraph;
  highlights: Highlight[];
  index: number;
  lastParagraph: boolean;
  selectedEmbed?: SelectedEmbed;
  setSelectedEmbed: (selectedEmbed: SelectedEmbed | undefined) => void;
  onEnterWaypoint: (label: string) => void;
  default_highlight_id?: number;
}

type HighlightPosition = 'flex-start' | 'center' | 'flex-end';

const determineHighlightPosition = (
  parentContainer: HTMLDivElement,
  selectedEmbed: SelectedEmbed
): HighlightPosition => {
  const { top: containerStart, bottom: containerEnd } =
    parentContainer.getBoundingClientRect();
  const thirdHeight: number = Math.floor((containerEnd - containerStart) / 3);
  if (
    _.inRange(
      selectedEmbed.embedYPos,
      containerStart,
      containerStart + thirdHeight
    )
  ) {
    // embed falls within the first 3rd of the container height
    return 'flex-start';
  } else if (
    _.inRange(
      selectedEmbed.embedYPos,
      containerStart + thirdHeight,
      containerEnd - thirdHeight
    )
  ) {
    // embed falls within the second 3rd of the container height
    return 'center';
  } else {
    // embed falls within the third 3rd of the container height
    return 'flex-end';
  }
};

function ParagraphContainer({
  paragraph,
  highlights,
  index,
  selectedEmbed,
  lastParagraph = false,
  setSelectedEmbed,
  onEnterWaypoint,
  default_color,
  active_color,
  default_highlight_id,
}: ParagraphContainerProps) {
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const highlightPositionRef = useRef<HighlightPosition>();
  const featured =
    selectedEmbed &&
    selectedEmbed.paragraphIndex === index &&
    highlights.find(
      (highlight) => highlight.id === selectedEmbed.selectedEmbedId
    );

  useEffect(() => {
    if (default_highlight_id && containerRef.current) {
      setSelectedEmbed(
        isSmallScreen
          ? undefined
          : {
              selectedEmbedId: default_highlight_id,
              embedYPos: containerRef.current.getBoundingClientRect().top + 1,
              paragraphIndex: index,
            }
      );
    }
  }, [default_highlight_id, index, isSmallScreen, setSelectedEmbed]);

  const handleClose = () => {
    setSelectedEmbed(undefined);
  };

  const handleSelectedEmbed = (selectedEmbed: SelectedEmbed) => {
    if (containerRef.current) {
      highlightPositionRef.current = determineHighlightPosition(
        containerRef.current,
        selectedEmbed
      );
    }
    // append the paragraph index before it bubbles up to the InsightSummary
    selectedEmbed.paragraphIndex = index;
    setSelectedEmbed(selectedEmbed);
  };

  return (
    <Grid xs={12}>
      <Waypoint
        onEnter={() => onEnterWaypoint(paragraph.label)}
        topOffset="30%"
        bottomOffset="60%"
      >
        <div ref={containerRef}>
          <SplitBackgroundGrid no_split={isSmallScreen}>
            <Grid
              container
              paddingTop={{ xs: 5, md: index === 0 ? 5 : 0 }}
              paddingBottom={lastParagraph ? '24px' : 'unset'}
              id={`${slugify(paragraph.label)}`}
              sx={{
                backgroundColor: { xs: 'white', sm: 'transparent' },
                alignItems: highlightPositionRef.current,
              }}
              className="jump-link"
            >
              <ParagraphBlock
                key={`summary-container-${paragraph.label}`}
                title={paragraph.label}
                text={paragraph.text}
                selected={!!featured}
                selectedEmbedId={selectedEmbed?.selectedEmbedId}
                setSelectedEmbed={handleSelectedEmbed}
                default_color={default_color}
                active_color={active_color}
              />

              {isSmallScreen && featured && (
                <ConversationModal onClose={handleClose} highlight={featured} />
              )}
              {!isSmallScreen && featured && (
                <HighlightBlock
                  highlight={featured ? (featured as Highlight) : undefined}
                />
              )}
            </Grid>
          </SplitBackgroundGrid>
        </div>
      </Waypoint>
    </Grid>
  );
}

export default ParagraphContainer;
