import { PageBase } from 'src/types/common';
import { ABOUT } from './about/about';
import { PROCESS } from './about/process';
import { TEAM } from './about/team';
import { HOME } from './home/home';
import { INSIGHTS } from './insights/insights';
import { CONVERSATION_LIBRARY } from './voices/conversation_library';
import { HIGHLIGHT_EXPLORER } from './voices/highlight_explorer';
import { VOICES } from './voices/voices';

export const INITIAL_PAGES: PageBase[] = [
  HOME,
  VOICES,
  CONVERSATION_LIBRARY,
  HIGHLIGHT_EXPLORER,
  ABOUT,
  TEAM,
  PROCESS,
  INSIGHTS,
];
