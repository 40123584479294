import * as d3 from 'd3';
import styled from 'styled-components';

import { Code } from 'src/types/common';
import { slugify } from 'src/utils/utils';
import { formatCodes } from '../utils';
import { transl } from './utils';

const EXTRA_PADDING_WHEN_ZOOMED_IN_PX = 30;

interface D3CodeObject {
  id: string;
  count: number;
  label: string;
  background_color?: string;
  text_color?: string;
  children: D3CodeObject[];
  cumulative_count: number;
  isTerminal?: boolean;
}
let root = {} as D3CodeObject;

function bakePack(
  codes: Code[],
  width: number,
  height: number,
  circlePadding: number,
  selectedCodeId?: string
) {
  const codeData = codes
    .filter((code) => code.count >= 1)
    .map((curr) => ({
      id: `::${curr.id}`,
      count: curr.count,
      label: curr.label,
      background_color: curr.showcase_colors.background_color,
      text_color: curr.showcase_colors.text_color,
      cumulative_count:
        curr.subcodes?.map(({ count }) => count).reduce((a, b) => a + b, 0) ??
        0,
      children:
        curr.subcodes
          ?.filter((code) => code.count >= 1)
          .map((sub) => ({
            id: `::${curr.id}::${slugify(sub.label)}`,
            count: sub.count,
            cumulative_count: sub.count,
            label: sub.label,
            isTerminal: true,
            background_color: sub.showcase_colors.background_color ?? '#f4f4f4',
            text_color: sub.showcase_colors.text_color,
            children: [],
          })) ?? [],
    }));

  if (selectedCodeId) {
    root =
      codeData.find((code) => selectedCodeId === `${code.id}`) ??
      ({} as D3CodeObject);
  } else {
    root = {
      id: '::',
      count: 0,
      label: '',
      background_color: '',
      cumulative_count: 0,
      children: codeData.map((code) => ({ ...code, children: [] })),
    };
  }

  const hierarchy = d3
    .hierarchy(root)
    .sum((d) => d.cumulative_count)
    .sort((a, b) => (b.value ?? 0) - (a.value ?? 0));

  return d3.pack<D3CodeObject>().padding(circlePadding).size([width, height])(
    hierarchy
  );
}

export default class PackedCirclesViz {
  svgRef;
  base: d3.Selection<SVGGElement, unknown, null, undefined> | undefined;
  width;
  height;
  circlePadding;
  animDuration;
  navigateToHighlights: (url: string) => void;
  constructor(
    svgRef: React.RefObject<SVGElement>,
    width: number,
    height: number,
    {
      circlePadding = 4, // Spacing between circles
      animDuration = 150, // Length of update transition in ms
    } = {},
    navigateToHighlights: (url: string) => void
  ) {
    this.svgRef = svgRef;
    this.base = undefined;
    this.width = width;
    this.height = height;
    this.circlePadding = circlePadding;
    this.animDuration = animDuration;
    this.navigateToHighlights = navigateToHighlights;
  }

  setUp(x: number, y: number) {
    console.assert(this.svgRef.current !== null);

    const svg = d3.select(this.svgRef.current);
    this.base = svg
      .append('g')
      .classed('base', true)
      .attr('transform', transl(x, y));
  }

  tearDown() {
    console.assert(this.svgRef.current !== null);
    d3.select(this.svgRef.current).selectChildren().remove();
  }

  update(codes: Code[], selectedCircleId?: string) {
    if (!this.base) {
      console.error('You must run setUp() before calling update().');
      return;
    }

    const pack = bakePack(
      codes,
      this.width,
      selectedCircleId
        ? this.height - EXTRA_PADDING_WHEN_ZOOMED_IN_PX * 2
        : this.height,
      this.circlePadding,
      selectedCircleId
    );

    const zoomTrans = d3
      .transition('zoom')
      .duration(this.animDuration)
      .ease(d3.easeBackInOut);
    const opacityTrans = d3
      .transition('opacity')
      .duration(this.animDuration)
      .ease(d3.easeExpIn);

    const move = selectedCircleId
      ? (d: any) => transl(d.x, d.y + EXTRA_PADDING_WHEN_ZOOMED_IN_PX)
      : (d: any) => transl(d.x, d.y);

    const handleClick = (d: any) => {
      const parentLabel: string = root.label;
      const childLabel: string = d.data.label;

      const highlightExplorerURL = formatCodes(parentLabel, childLabel);

      if (this.navigateToHighlights) {
        this.navigateToHighlights(highlightExplorerURL);
      }
    };
    const nodes = this.base
      .selectAll('g.node')
      .data(
        pack.descendants().filter((n) => n.data.id !== '::'),
        (n: any) => n.data.id
      )
      .join(
        (enter) => {
          const g = enter
            .append('g')
            .attr('transform', move)
            .attr('opacity', 0)
            .on('click', (event, d) => handleClick(d))
            .style('cursor', 'pointer');

          g.transition(opacityTrans).attr('opacity', 1);

          g.append('circle')
            .attr('fill', (d) => {
              return d.data.background_color ?? '';
            })
            .attr('r', (d) => {
              // arbitrarily chosen minimum circle radius; chosen based on fitting the word "Organization" at the circle's widest
              return d.r >= 35 ? d.r : 35;
            });

          const textGroup = g.append('g');
          textGroup.append('text').attr('fill', (d) => d.data.text_color ?? '');

          return g;
        },
        (update) => {
          if (!update.data().length) {
            // Prevent update from nullifying transition from enter
            return update;
          }
          update.transition(zoomTrans).attr('transform', move);

          update
            .select('circle')
            .transition(zoomTrans)
            .attr('r', (d) => {
              // arbitrarily chosen minimum circle radius; chosen based on fitting the word "Organization" at the circle's widest
              return d.r >= 35 ? d.r : 35;
            })
            .attr('fill', (d) => d.data.background_color ?? '');

          return update;
        }
      )
      .classed('node', true);

    nodes.classed(
      'selected',
      (n) => !!selectedCircleId && selectedCircleId.startsWith(n.data.id)
    );
    nodes.classed('selectable', (n) => !n.data.isTerminal);

    nodes
      .select('text')
      .selectAll('tspan')
      .data((d) => {
        if (d.data.isTerminal) {
          return d.data.label
            .split(' ')
            .concat([` (${d.data.count} highlights)`]);
        }

        return d.data.label.split(' ');
      })
      .join('tspan')
      .attr('x', 0)
      .attr('y', (d, i, nodes) => `${(i - nodes.length / 2) * 1.3 + 1}em`)
      .text((d) => d);
  }

  registerHandlers(
    selectedInsightId: string | undefined,
    setSelected: (id: string | undefined) => void
  ) {
    this.base?.selectAll('g.node.selectable').on('click', function (e, n: any) {
      if (n.data.id === selectedInsightId) setSelected(undefined);
      else {
        setSelected(n.data.id);
      }
    });
  }

  deregisterHandlers() {
    this.base?.selectAll('g.node.selectable').on('.', null);
  }

  static styled() {
    return styled.svg`
      width: 100%;
      max-height: 60vh;
      height: 100%;

      text {
        text-anchor: middle;
        font-size: 10px;
        pointer-events: none;
      }

      g.node.selectable circle {
        cursor: pointer;
      }

      g.node.selectable:hover circle {
        cursor: pointer;
      }

      g.node.terminal circle {
        stroke-width: 0px;
        fill: #fff;
      }

      g.node.terminal text {
        fill: #000;
      }

      g.node.selected text {
        display: none;
      }

      g.node.selected,
      g.node.terminal text {
        display: block;
      }
    `;
  }
}
