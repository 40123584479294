import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import appSelectors from 'src/redux/app/app-selectors';
import { Highlight } from 'src/types/common';

interface FooterProps {
  highlight: Highlight;
}
function Footer({ highlight }: FooterProps) {
  const organization_metadata = useSelector(
    appSelectors.getOrganizationMetadata
  );
  const codes = useSelector(appSelectors.getCodes);
  const footerCodes = codes.demographic.filter(
    (code) => organization_metadata?.embed_demographic_ids.includes(code.id)
  );
  return (
    <Grid
      container
      sx={{
        paddingTop: '.6rem',
        paddingBottom: '.2rem',
        paddingX: '.5rem',
        background: '#F5F5F5',
      }}
    >
      <Grid
        item
        xs={4}
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingBottom: '.5rem',
          paddingLeft: '1rem',
        }}
      >
        <img
          style={{
            height: '14.2px',
            verticalAlign: 'top',
          }}
          alt="Logo"
          src={organization_metadata?.embed_logo}
        />
        <Typography
          sx={{
            fontSize: '10.5px',
            color: '#11304B',
            fontWeight: 500,
            marginLeft: '0.25rem',
          }}
        >
          {organization_metadata?.short_name}
        </Typography>
      </Grid>
      {footerCodes && (
        <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'right' }}>
          {footerCodes.map((code) => {
            const codeValue = code.subcodes?.find((subcode) =>
              highlight.demographics.includes(subcode.id)
            )?.label;
            if (!codeValue) {
              return <></>;
            }
            return (
              <Typography
                sx={{
                  fontSize: '10.5px',
                  paddingRight: '1.2rem',
                  textTransform: 'capitalize',
                }}
              >
                <b>{code.label}: </b>
                <i>{codeValue}</i>
              </Typography>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
}

export default Footer;
