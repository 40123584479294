import React from 'react';
import JsxParser from 'react-jsx-parser';
import { Box, Grid, Typography } from '@mui/material';

import { slugify } from 'src/utils/utils';

interface HeaderProps {
  title: string;
  body: string;
  padding?: boolean;
  center?: boolean;
}

function Header({ title, body, padding, center }: HeaderProps) {
  return (
    <Grid
      data-testid={`group-callout-${slugify(title)}`}
      container
      item
      xs={12}
      direction="column"
      alignItems={center ? 'center' : 'left'}
      justifyContent="center"
      paddingTop={4}
      paddingBottom={padding ? 12 : 6}
    >
      <Grid xs={10} md={7}>
        <Box sx={{ color: 'inherit' }}>
          <Typography align="left" variant="h5" paddingBottom={2}>
            {/* @ts-ignore - jsx parser */}
            {title && <JsxParser jsx={title} />}
          </Typography>
          {body && (
            <Typography align="left" variant="body2">
              {/* @ts-ignore - jsx parser */}
              {body && <JsxParser jsx={body} />}
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default Header;
