import { cloneDeep } from 'lodash';

import { BootstrapData } from 'src/types/boostrap';
import { EDITING_QUERY } from 'src/utils/useGeneratePath';
import { getBootstrapFromStorage } from './storage';

/**
 * Increments the version of the bootstrap data to be stored on the backend.
 * @param data - the bootstrap data to increment
 * @param type  -  the type of change requested
 * @returns  the bootstrap data with version incremented
 */
export const incrementVersion = (
  data: BootstrapData,
  type: 'draft' | 'publish'
) => {
  const finalData = cloneDeep(data);
  let newVersion = finalData.version;
  switch (type) {
    case 'draft':
      newVersion = newVersion + 0.001;
      if (Math.trunc(newVersion) > Math.trunc(finalData.version)) {
        window.alert('You must publish before proceeding');
        return undefined;
      }
      break;
    case 'publish':
      newVersion = Math.trunc(newVersion + 1);
      break;
  }
  finalData.version = newVersion;
  return finalData;
};

/**
 * Grab the appropriate bootstrap data from the window or local storage.
 * @returns bootstrap data
 */
export const getBootstrapData = async () => {
  const windowData = cloneDeep(window.BOOTSTRAP_DATA);
  const isEditingRoute = location.search.includes(EDITING_QUERY);
  const storageData = await getBootstrapFromStorage();
  if (
    !storageData ||
    storageData.version < windowData.version ||
    !isEditingRoute
  ) {
    return {
      ...windowData,
      editAccess: windowData.editAccess && isEditingRoute,
    };
  }
  return {
    ...windowData,
    organization_metadata: storageData.organization_metadata,
    routes: storageData.routes,
    pages: storageData.pages,
    filters: storageData.filters,
    codes: storageData.codes,
    map: storageData.map,
    editAccess: windowData.editAccess && isEditingRoute,
    changes_made: true,
  };
};
