import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { cloneDeep, shuffle } from 'lodash';

import { checkFilterMatch } from 'src/components/HighlightExplorer/utils/utils';
import appSelectors from 'src/redux/app/app-selectors';
import { Highlight } from 'src/types/common';
import { FilterOption } from 'src/types/filters';

export const useHighlights = (
  activeFilters: FilterOption[],
  shuffleHighlights?: boolean
) => {
  const highlights = useSelector(appSelectors.getHighlights);
  const [filteredHighlights, setFilteredHighlights] = useState(highlights);

  useEffect(() => {
    const scrollElement = document.getElementById('highlight-scroll');
    if (scrollElement) {
      scrollElement.scrollTop = 0;
    }
  }, [filteredHighlights]);

  useEffect(() => {
    let filteredHighlights = cloneDeep(highlights);

    if (activeFilters.length > 0) {
      filteredHighlights = filteredHighlights
        .filter((highlight: Highlight) => {
          return activeFilters.every((activeFilter: FilterOption) =>
            checkFilterMatch(highlight, activeFilter)
          );
        })
        .map((highlight: Highlight) => {
          return { ...highlight, match: true };
        });
    }
    // sort highlights by # of associated codes for better visual effect
    filteredHighlights.sort((a, b) => b.codes.length - a.codes.length);
    setFilteredHighlights(
      shuffleHighlights ? shuffle(filteredHighlights) : filteredHighlights
    );
  }, [highlights, activeFilters, shuffleHighlights]);

  return { highlights, filteredHighlights };
};
