import React from 'react';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import {
  Checkbox,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material';
import { cloneDeep, set } from 'lodash';

import { Code } from 'src/types/common';
import ColorEditor from './ColorEditor';

const CodeEditor = React.forwardRef(
  (
    {
      initialCode,
      allowSubcodes = true,
      demographic = false,
      onChange,
    }: {
      initialCode: Code;
      allowSubcodes?: boolean;
      demographic?: boolean;
      onChange?: (value: string | boolean, match: string) => void;
    },
    ref
  ) => {
    const centerStyle = {
      alignItems: 'center',
      position: 'relative',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginY: '1rem',
    };
    const [open, setOpen] = React.useState<boolean>();
    const [code, setCode] = React.useState<Code>(initialCode);
    const handleChange = (value: string | boolean, match: string) => {
      if (onChange) {
        onChange(value, match);
        return;
      }
      const newCode = cloneDeep(code);
      set(newCode, match, value);
      if (ref) {
        (ref as React.MutableRefObject<Code>).current = newCode;
      }
      setCode(newCode);
    };
    return (
      <>
        <ListItem disableGutters>
          <ListItemButton
            onClick={() => setOpen((self) => !self)}
            disableGutters={!onChange}
          >
            {open ? <ExpandMore /> : <ChevronRight />}
            <Typography variant="h6">{code.display_name}</Typography>
          </ListItemButton>
        </ListItem>
        <Collapse in={open}>
          <Grid container sx={{ padding: !onChange ? '' : '0 0 0 2rem' }}>
            {demographic && (
              <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography>Map Values:</Typography>
                <Checkbox
                  checked={code.map}
                  onChange={(value, checked) => {
                    handleChange(checked, 'map');
                  }}
                />
              </Grid>
            )}
            <Grid item container xs={12}>
              <Typography
                variant="h6"
                sx={{ width: '100%', textAlign: 'center' }}
              >
                Showcase Colors
              </Typography>
              <Grid item xs={6} sx={centerStyle}>
                <ColorEditor
                  label="Background:"
                  color={code.showcase_colors.background_color}
                  onColorChange={(value) => {
                    handleChange(value.hex, 'showcase_colors.background_color');
                  }}
                />
              </Grid>
              <Grid item xs={6} sx={centerStyle}>
                <ColorEditor
                  label="Text:"
                  color={code.showcase_colors.text_color}
                  onColorChange={(value) => {
                    handleChange(value.hex, 'showcase_colors.text_color');
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Typography
                variant="h6"
                sx={{ width: '100%', textAlign: 'center' }}
              >
                Conversation Library Colors
              </Typography>
              <Grid item xs={6} sx={centerStyle}>
                <ColorEditor
                  label="Background:"
                  color={code.conversation_library_colors.background_color}
                  onColorChange={(value) => {
                    handleChange(
                      value.hex,
                      'conversation_library_colors.background_color'
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6} sx={centerStyle}>
                <ColorEditor
                  label="Text:"
                  color={code.conversation_library_colors.text_color}
                  onColorChange={(value) => {
                    handleChange(
                      value.hex,
                      'conversation_library_colors.text_color'
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Typography
                variant="h6"
                sx={{ width: '100%', textAlign: 'center' }}
              >
                Embed Colors
              </Typography>
              <Grid item xs={6} sx={centerStyle}>
                <ColorEditor
                  label="Background:"
                  color={code.embed_colors.background_color}
                  onColorChange={(value) => {
                    handleChange(value.hex, 'embed_colors.background_color');
                  }}
                />
              </Grid>
              <Grid item xs={6} sx={centerStyle}>
                <ColorEditor
                  label="Text:"
                  color={code.embed_colors.text_color}
                  onColorChange={(value) => {
                    handleChange(value.hex, 'embed_colors.text_color');
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <List>
                {allowSubcodes &&
                  code.subcodes?.map((subcode, ind) => {
                    return (
                      <CodeEditor
                        allowSubcodes={false}
                        initialCode={subcode}
                        onChange={(value, match) => {
                          handleChange(value, `subcodes[${ind}].${match}`);
                        }}
                      />
                    );
                  })}
              </List>
            </Grid>
          </Grid>
        </Collapse>
      </>
    );
  }
);

export default CodeEditor;
