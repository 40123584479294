/**
 * This function gets all the highlight ids in a string that has the special key
 * <Embed getEmbedInfo={getEmbedInfo}>{lable}={highlight_id}</Embed>
 * @param text - The string that is parsed
 * @returns - a list of numbers
 */
export const getHighlightIds = (text: string) => {
  const matchElementRegex = new RegExp(
    /<Embed getEmbedInfo={getEmbedInfo}>.+?-\d+<\/Embed>/,
    'g'
  );
  const matchHighlightIdRegex = new RegExp(/-\d+/);
  const match = text.match(matchElementRegex);
  return match?.map((item) => {
    const id = item.match(matchHighlightIdRegex);
    return id ? parseInt(id[0].slice(1)) : undefined;
  });
};
