import { LATEST_INTERNAL_VERSION } from 'src/setup/editmetadata';
import { BootstrapData } from 'src/types/boostrap';
import { ANALYTIC_CODES } from './analytic';
import { CODES } from './codes';
import { CONVERSATIONS } from './conversations/conversations';
import { DEMOGRAPHIC_CODES } from './demographics';
import { FILTERS } from './filters';
import { HIGHLIGHTS } from './highlights';
import { MAP } from './map';
import { ORGANIZATION_METADATA } from './organization_metadata';
import { PAGES } from './pages/pages';
import { ROUTES } from './routes';

export const ROBUST_BOOTSTRAP_DATA: BootstrapData = {
  editAccess: true,
  organization_metadata: ORGANIZATION_METADATA,
  routes: ROUTES,
  pages: PAGES,
  filters: FILTERS,
  codes: CODES,
  map: MAP,
  highlights: HIGHLIGHTS,
  analytic_codes: ANALYTIC_CODES,
  demographic_codes: DEMOGRAPHIC_CODES,
  conversations: CONVERSATIONS,
  unpublished_draft: false,
  version: 0,
  internalVersion: LATEST_INTERNAL_VERSION,
  insights_data_timestamp: '',
  embedURL: 'https://embed.fora.io',
};

export const INITIAL_BOOTSTRAP_DATA: BootstrapData = {
  editAccess: true,
  highlights: HIGHLIGHTS,
  analytic_codes: ANALYTIC_CODES,
  demographic_codes: DEMOGRAPHIC_CODES,
  conversations: CONVERSATIONS,
  unpublished_draft: false,
  version: 0,
  internalVersion: LATEST_INTERNAL_VERSION,
  insights_data_timestamp: '',
  embedURL: 'http://localhost:10004',
};
