import { useCallback, useMemo, useRef } from 'react';
import { get } from 'lodash';

import { Highlight } from 'src/types/common';
import { FilterOption } from 'src/types/filters';
import { LocationMetadata, MapItem } from 'src/types/map';

/**
 * Tests whether a given highlight matches the desired filter
 * @param highlight Highlight to test against
 * @param filter Filter to test against
 * @returns true if the highlight matches, false otherwise
 */
export const checkFilterMatch = (
  highlight: Highlight,
  filter: FilterOption
) => {
  const key = filter.key;
  const type = filter.type;
  const match = filter.match;

  if (type === 'single') {
    if (get(highlight, key).some((item: string | number) => item === match)) {
      return true;
    }
  }
  if (type === 'multi') {
    if ((match as number[]).length) {
      if (
        (match as number[]).some((id) =>
          get(highlight, key).some((item: string | number) => item === id)
        )
      ) {
        return true;
      }
    }
  }
  return false;
};

/**
 * Transforms the input MapItems into LocationMetadata, to be used with d3 mapping.
 * Also checks how many highlights match the MapItem and whether or not to use a muted color (based on active map filters)
 * @param locations Input MapItems
 * @param highlights All of the current highlights, used to get a matching highlight count
 * @param activeMapIds All of the active map filters, used to mute regions that aren't currently being filtered
 * @returns a list of LocationMetadata, to be used with d3 mapping.
 */
export const getD3Locations = (
  locations: MapItem[],
  highlights: Highlight[],
  activeMapIds: number[]
) => {
  const updatedLocations = locations.map((location) => {
    const matching_count = highlights.filter((highlight) =>
      highlight.demographics.includes(location.demographic_id)
    ).length;
    return {
      ...location,
      matching_count,
      mute:
        activeMapIds.length > 0
          ? !activeMapIds.includes(location.demographic_id)
          : false,
    };
  });
  return separateLocationPaths(updatedLocations);
};

/**
 * Takes MapItems and splits their paths array into distinct LocationMetadata, each having one path
 * @param locations MapItems to split
 * @returns complete array of LocationMetadata
 */
export const separateLocationPaths = (locations: LocationMetadata[]) =>
  locations
    .map((location) => {
      const splitLocations = [];
      for (const path of location.paths) {
        splitLocations.push({
          ...location,
          path,
        });
      }
      return splitLocations;
    })
    .reduce((prev, curr) => prev.concat(curr), []);

/**
 * This function handles the logic to resize the map to fit the width or height of the container
 * then adjust the container to the size of the map. This requires scaling the <g> inside the <svg> to the size that would
 * make the width or height match the container. It then moves the top left corner of the <g> to match that of the <svg>.
 * Next, it resizes the container and the <svg> to match the size of the <g> (this is essential for zooming).
 * This allows the map to be centered within the parent div of the container.
 * @param svgContainer - the container that the map is bound by
 * @param svg - the svg that the map is drawn within
 */
export const resizeMap = (svgContainer: HTMLDivElement, svg: SVGSVGElement) => {
  // Reset container to maximize the given space (min height for smaller screens)
  svgContainer.style.width = '100%';
  svgContainer.style.height = '100%';
  svgContainer.style.minHeight = '250px';
  // Offset for scrollbars
  const scrollbarOffset = 8;
  const { height: containerHeight, width: containerWidth } =
    svgContainer.getBoundingClientRect();
  const g = svg.firstChild as SVGGElement;
  if (g) {
    const {
      height: gHeight,
      width: gWidth,
      x: gX,
      y: gY,
    } = g.getBoundingClientRect();
    const { x: svgX, y: svgY } = svg.getBoundingClientRect();
    const xOffset = svgX - gX;
    const yOffset = svgY - gY;
    const heightScale = containerHeight / gHeight;
    const widthScale = containerWidth / gWidth;
    const scale = Math.min(heightScale, widthScale);
    // This will scale the g to the size of the container, and shift the g to be in line with the svg
    g.style.transform = `scale(${scale}) translate(${xOffset}px,${yOffset}px)`;
    if (heightScale < widthScale) {
      // The scale to maximize size is height
      const adjustedWidth = gWidth * scale;
      // Modify svg styles
      svg.style.width = `${adjustedWidth.toFixed(2)}px`;
      svg.style.height = `${containerHeight.toFixed(2)}px`;
      // Modify container styles, ensuring room for scrollbars
      svgContainer.style.width = `${(adjustedWidth + scrollbarOffset).toFixed(
        2
      )}px`;
      svgContainer.style.height = `${(
        containerHeight + scrollbarOffset
      ).toFixed(2)}px`;
    } else {
      // The scale to maximize size is width
      const adjustedHeight = gHeight * scale;
      // Modify svg styles
      svg.style.height = `${adjustedHeight.toFixed(2)}px`;
      svg.style.width = `${containerWidth.toFixed(2)}px`;
      // Modify container styles, ensuring room for scrollbars
      svgContainer.style.height = `${(adjustedHeight + scrollbarOffset).toFixed(
        2
      )}px`;
      svgContainer.style.width = `${(containerWidth + scrollbarOffset).toFixed(
        2
      )}px`;
    }
  }
};

/**
 * This hook enables the map to be zoomed in, and zoomed back out to it's original state.
 * @param svg - the svg of the map
 * @param speed - the interval at which we want to scale
 * @param size - the number of times we are allowed to scale
 * @returns
 */
export const useMapZoom = (
  svg: React.RefObject<SVGSVGElement>,
  speed = 0.4,
  size = 5
) => {
  const zoom = useRef(0);
  const zoomIn = useCallback(() => {
    if (svg.current && zoom.current < size) {
      const newZoom = zoom.current + 1;
      svg.current.style.transform = `scale(${1 + newZoom * speed})`;
      zoom.current = newZoom;
    }
  }, [size, speed, svg]);
  const zoomOut = useCallback(() => {
    if (svg.current && zoom.current > 0) {
      const newZoom = zoom.current - 1;
      svg.current.style.transform = `scale(${1 + newZoom * speed})`;
      zoom.current = newZoom;
    }
  }, [speed, svg]);
  return useMemo(
    () => ({
      zoomIn,
      zoomOut,
    }),
    [zoomIn, zoomOut]
  );
};
