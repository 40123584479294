export interface EditMetadata {
  sections: EditSection[];
}

export interface EditSection {
  section_label: string;
  numbered?: boolean;
  update_section?: UpdateSectionMetadata;
  sub_sections?: EditSection[];
  edit_blocks?: EditBlock[];
  edit_header?: string;
  onUpdateSection?: () => void;
}
export interface UpdateSectionMetadata {
  match: string; // location to insert the data
  label: string;
  /**
   * Value of an empty data item
   */
  empty: any;
  newSectionMetadata: EditSection;
}

export interface EditBlock {
  block_label: string;
  match: string;
  type: EditType;
  value?: any; // generated on the frontend
}

export const enum EditType {
  string = 'string',
  complex_string = 'complex-string',
  embed_string = 'embed-string',
  image = 'image',
  list = 'list',
  highlight_list = 'highlight-list',
  code_list = 'code-list',
  code = 'code',
  demographic = 'demographic',
  demographic_list = 'demographic-list',
  filter_group = 'filter-group',
  filter_order = 'filter-order',
  link = 'link',
  optional_link = 'optional-link',
  color = 'color',
  color_palette = 'color-palette',
  typography = 'typography',
  font_family = 'font-family',
  medley = 'medley',
  featured_highlight = 'featured-highlight', // using this in place of medley until we can figure out how to handle creation of Medleys
  map = 'map',
  route = 'route',
  tab_type = 'tab-type',
  banner = 'banner',
  checkbox = 'checkbox',
  graph = 'graph',
}

export interface EditableText {
  text: string;
  bold?: boolean;
  italic?: boolean;
  embed?: boolean;
}

export interface EditableParagraph {
  type: 'paragraph' | 'list-item' | 'bulleted-list' | 'numbered-list';
  children: EditableText[] | EditableParagraph[];
}

export type Formats =
  | 'bold'
  | 'italic'
  | 'embed'
  | 'bulleted-list'
  | 'numbered-list';
