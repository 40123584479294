import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useState,
} from 'react';
import * as d3 from 'd3';
import { cloneDeep } from 'lodash';

import { resizeMap } from 'src/components/HighlightExplorer/utils/utils';
import { LocationMetadata, MapItem } from 'src/types/map';
import { valueOrThrow } from 'src/utils/utils';
import * as ImageTracer from '../utils/imagetracer';
import {
  calculateImageScaleFactor,
  convertSVGToLocationMetadata,
  InitialMapItems,
  NULL_PK,
} from './mapUtils';

export const useFileUploaded = (
  svgRef: MutableRefObject<SVGSVGElement | null>,
  svgContainerRef: MutableRefObject<null>,
  resetMapItems: MutableRefObject<MapItem[]>,
  initialValue: InitialMapItems,
  mapItems: MutableRefObject<MapItem[]>,
  updateRef: () => void,
  setD3MappingComplete: Dispatch<SetStateAction<any>>
) => {
  const [processingImage, setProcessingImage] = useState(false);

  /**
   * Fired when user clicks the Upload button; rasterizes the image and appends it in the DOM
   * @param event Event containing the uploaded image
   */
  const fileUploaded = (
    event: React.ChangeEvent<HTMLInputElement>,
    threshhold?: number
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setProcessingImage(true);
      const existingUpload =
        document.getElementById('svgContainer')?.firstChild;
      if (existingUpload) {
        existingUpload.remove();
        const mapItemsPathsRemoved: MapItem[] = cloneDeep(
          initialValue.mapItems
        );
        mapItemsPathsRemoved.forEach((mapItem: MapItem) => {
          mapItem.paths = [];
        });
        mapItems.current = mapItemsPathsRemoved;
      }
      const url = URL.createObjectURL(event.target.files[0]);
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        ImageTracer.imageToSVG(
          url,
          (svg: string) => {
            setProcessingImage(false);
            ImageTracer.appendSVGString(svg, 'svgContainer');

            // Adds all paths in the new svg to the "Unassigned map regions" bucket
            // Joins the data with d3
            if (svgContainerRef.current) {
              const svgElem: SVGSVGElement = (
                svgContainerRef.current as SVGSVGElement
              ).children[0] as SVGSVGElement;
              if (svgElem) {
                // Zoom Related
                svgElem.style.transformOrigin = 'top left';
                svgRef.current = svgElem;
                // insert a g around the paths so that proper resizing can occur
                const g = document.createElementNS(
                  'http://www.w3.org/2000/svg',
                  'g'
                );
                const paths: Node[] = [];
                svgElem.childNodes.forEach((node) => paths.push(node));
                g.replaceChildren(...paths);
                svgElem.replaceChildren(g);

                const unmatchedMetadata: LocationMetadata[] =
                  convertSVGToLocationMetadata(svgElem, threshhold);

                mapItems.current.forEach((mapItem: MapItem) => {
                  if (mapItem.demographic_id === NULL_PK) {
                    mapItem.paths = unmatchedMetadata.map((locationMetadata) =>
                      valueOrThrow<string>(locationMetadata.path)
                    );
                  }
                });

                resetMapItems.current = cloneDeep(mapItems.current);

                d3.select(svgElem)
                  .selectAll('path')
                  .data(unmatchedMetadata)
                  .attr('id', (d: LocationMetadata) => {
                    return d.label;
                  });
                setD3MappingComplete({});
                updateRef();
                resizeMap(svgContainerRef.current, svgElem as SVGSVGElement);
              }
            }
          },
          {
            viewBox: true,
            // what options do we need here?
            lineFilter: true,
            strokewidth: 0,
            scale: calculateImageScaleFactor(
              img.height,
              valueOrThrow<HTMLElement>(document.getElementById('svgContainer'))
            ),
          }
        );
      };
      img.src = url;
    }
  };

  return { fileUploaded, processingImage };
};
