import React from 'react';
import { SxProps, Theme } from '@mui/material';
import Grid from '@mui/material/Grid';

import { SectionStyle } from 'src/types/common';
import Section from '../background/Section';
import { useTexture } from '../texture/texture';
import Callout from './Callout';

interface StyledCalloutProps extends SectionStyle {
  boxSx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
  children: React.ReactElement | React.ReactElement[];
  transparent?: boolean;
}

function StyledCallout({
  boxSx,
  containerSx,
  children,
  texture,
  secondary = false,
  transparent,
}: StyledCalloutProps) {
  const { getTexture } = useTexture(secondary);
  const backgroundColor = transparent
    ? 'transparent'
    : secondary
    ? 'primary.light'
    : 'primary.main';
  let sx: SxProps<Theme> = {
    color: transparent
      ? 'primary.dark'
      : secondary
      ? 'primary.main'
      : 'primary.contrastText',
    backgroundColor: backgroundColor,
  };

  if (!transparent && texture) {
    sx = {
      ...sx,
      backgroundImage: `url(${getTexture(secondary)})`,
      backgroundSize: 'auto',
      height: '100%',
    };
  }

  return (
    <Section containerSx={containerSx} sx={{ marginBottom: '0px' }}>
      <Callout boxSx={boxSx} sx={sx} backgroundColor={backgroundColor}>
        <Grid container data-testid="styled-callout-grid">
          {children}
        </Grid>
      </Callout>
    </Section>
  );
}

export default StyledCallout;
