import React, { useEffect, useRef, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import 'react-alice-carousel/lib/alice-carousel.css';

const createItems = (
  items: any,
  activeIndex: number,
  handleClick: (i: number) => void
) => {
  let deltaX = 0;
  let difference = 0;
  const swipeDelta = 10;

  return items.map((item: any, i: number) => {
    return (
      <Box
        data-value={i + 1}
        key={`carousel-item-${i}`}
        className="item"
        onMouseDown={(e) => (deltaX = e.pageX)}
        onMouseUp={(e) => (difference = Math.abs(e.pageX - deltaX))}
        onClick={() => difference < swipeDelta && handleClick(i)}
        style={{ opacity: activeIndex === i ? '1' : '0.6' }}
      >
        {item}
      </Box>
    );
  });
};

interface CarouselProps {
  content: any[];
  compact?: boolean;
  activeIndex: number;
  setActiveIndex: (i: number) => void;
}

const Carousel = ({
  content,
  compact,
  activeIndex,
  setActiveIndex,
}: CarouselProps) => {
  const [items, setItems] = useState(
    createItems(content, activeIndex, setActiveIndex)
  );
  const carouselContainer = useRef<HTMLDivElement>(null);
  const padding =
    carouselContainer.current && !compact
      ? carouselContainer.current.offsetWidth * 0.5
      : 0;

  const slideNext = () => {
    if (activeIndex < content.length - 1) {
      setActiveIndex(activeIndex + 1);
    } else {
      setActiveIndex(0);
    }
  };

  const slidePrev = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    } else {
      setActiveIndex(content.length - 1);
    }
  };

  const syncActiveIndex = ({ item }: { item: number }) => setActiveIndex(item);

  useEffect(() => {
    setItems(createItems(content, activeIndex, setActiveIndex));
  }, [activeIndex, content, setActiveIndex]);

  return (
    <>
      <Grid
        item
        xs={12}
        marginBottom={2}
        marginRight={{ md: 6 }}
        sx={{
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" sx={{ color: '#FFF', paddingRight: '1rem' }}>
          {activeIndex + 1}/{items.length}
        </Typography>
        <ChevronLeft
          onClick={slidePrev}
          fontSize="large"
          style={{
            cursor: 'pointer',
            color: activeIndex === 0 ? '#CCC' : '#FFF',
          }}
        />
        <ChevronRight
          onClick={slideNext}
          fontSize="large"
          style={{
            cursor: 'pointer',
            color: activeIndex === items.length - 1 ? '#CCC' : '#FFF',
          }}
        />
      </Grid>
      <div
        ref={carouselContainer}
        style={{ display: 'flex', width: '100%', maxWidth: '100%' }}
      >
        <AliceCarousel
          mouseTracking
          disableButtonsControls
          // Disable for now; Something wonky is happening with the render cycle/how we're instantiating new HighlightPlayers.
          // infinite
          disableDotsControls
          paddingRight={padding}
          // paddingLeft={padding}
          items={items}
          activeIndex={activeIndex}
          controlsStrategy="alternate"
          onSlideChanged={syncActiveIndex}
        />
      </div>
    </>
  );
};

export default Carousel;
