import React from 'react';
import { Grid } from '@mui/material';

import FullBanner from 'src/components/common/banners/FullBanner';
import { useEditing } from 'src/utils/useEditing';

const NotFoundPage = () => {
  const { canEdit, isEditing } = useEditing();
  return (
    <Grid container>
      <FullBanner
        title="404"
        body={`Sorry, we couldn't find the page you're looking for. ${
          canEdit && isEditing
            ? "You may need to enable this page's visibility in the Edit bar."
            : ''
        }`}
      />
    </Grid>
  );
};

export default NotFoundPage;
