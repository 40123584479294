import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import { SelectedEmbed } from 'src/components/Audio/ZagatEmbed';
import { getBanner } from 'src/components/common/banners/utils';
import ParagraphContainer from 'src/components/InsightParagraph/ParagraphContainer';
import StickyNav from 'src/components/Navigation/StickyNav';
import appSelectors from 'src/redux/app/app-selectors';
import { StoreState } from 'src/redux/store';
import { PageProps } from 'src/types/common';
import { InsightSummaryPage } from 'src/types/pages/insights';
import { slugify } from 'src/utils/utils';
import { getHighlightIds } from './utils';

function InsightSummary({ page_data_id }: PageProps) {
  const pageInfo = useSelector((state: StoreState) =>
    appSelectors.getPage(state, page_data_id)
  ) as InsightSummaryPage;
  const highlights = useSelector(appSelectors.getHighlights);
  const [currSection, setCurrSection] = useState<string>();
  const [selectedEmbed, setSelectedEmbed] = useState<SelectedEmbed>();

  useEffect(() => {
    setCurrSection(pageInfo.summary.content.paragraphs[0]?.label);
    window.scrollTo(0, 0);
  }, [pageInfo]);

  if (!pageInfo) {
    return <></>;
  }

  const handleClick = (label: string) => {
    setCurrSection(label);
  };

  const handleChangeDropdown = (label: string) => {
    setCurrSection(label);

    const element = document.querySelector(`#${slugify(label)}`);
    if (element) {
      const topPos =
        element.getBoundingClientRect()?.top + window.pageYOffset - 80;

      window.scrollTo({
        top: topPos,
        behavior: 'smooth',
      });
    }
  };

  const handleEnterWaypoint = (label: string) => {
    setCurrSection(label);
  };

  const nav_steps = pageInfo.summary?.content.paragraphs.map(({ label }) => ({
    link: slugify(label),
    label,
  }));

  return (
    <Grid>
      {pageInfo.header?.visible && (
        <div
          data-edit-id={`${pageInfo.page_id}.header`}
          style={{ width: '100%' }}
        >
          {getBanner(pageInfo.header.content)}
        </div>
      )}
      {pageInfo.summary?.visible && (
        <>
          {currSection && (
            <StickyNav
              steps={nav_steps}
              onClick={handleClick}
              onChange={handleChangeDropdown}
              currSection={currSection}
            />
          )}
          <Grid data-edit-id={`${pageInfo.page_id}.summary`}>
            {pageInfo?.summary.content.paragraphs.map(
              (paragraph, ind, allParagraphs) => {
                const highlightIds = getHighlightIds(paragraph.text);
                const filteredHighlights = highlights.filter(
                  (highlight) => highlightIds?.includes(highlight.id)
                );
                const defaultId =
                  ind === 0 ? highlightIds && highlightIds[0] : undefined;
                return (
                  <ParagraphContainer
                    key={ind}
                    paragraph={paragraph}
                    lastParagraph={ind === allParagraphs.length - 1}
                    index={ind}
                    selectedEmbed={selectedEmbed}
                    setSelectedEmbed={setSelectedEmbed}
                    highlights={filteredHighlights}
                    default_highlight_id={defaultId}
                    onEnterWaypoint={handleEnterWaypoint}
                    {...pageInfo?.summary.content.highlight_link_style}
                  />
                );
              }
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default InsightSummary;
