import escapeHtml from 'escape-html';
import { Descendant, Text } from 'slate';
import { jsx } from 'slate-hyperscript';

import { EditableParagraph, EditableText, Formats } from 'src/types/edit';
import { CustomRoute } from 'src/types/routes';

export const LIST_TYPES = ['numbered-list', 'bulleted-list'];
export const HOTKEYS: { [key: string]: Formats } = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'embed',
};

// The below functions were modified from the slate documentation
// https://docs.slatejs.org/concepts/10-serializing
export const slateToHTML = (
  node: Descendant | { children: Descendant[] }
): string => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<b>${string}</b>`;
    }
    if (node.italic) {
      string = `<i>${string}</i>`;
    }
    if (node.embed) {
      string = `<Embed getEmbedInfo={getEmbedInfo}>${string}</Embed>`;
    }
    return string;
  }

  const children = node.children.map((n) => slateToHTML(n)).join('');

  switch ((node as EditableParagraph).type) {
    case 'paragraph':
      return `<p>${children}</p>`;
    case 'bulleted-list':
      return `<ul>${children}</ul>`;
    case 'numbered-list':
      return `<ol>${children}</ol>`;
    case 'list-item':
      return `<li>${children}</li>`;
    default:
      return children;
  }
};

export const HTMLToSlate = (
  el: HTMLElement | ChildNode,
  markAttributes: Omit<EditableText, 'text'> = {}
): (Descendant | null)[] | EditableText | EditableParagraph | null => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };

  // define attributes for text nodes
  switch (el.nodeName) {
    case 'B':
      nodeAttributes.bold = true;
      break;
    case 'I':
      nodeAttributes.italic = true;
      break;
    case 'E':
      nodeAttributes.embed = true;
      break;
  }

  const children = Array.from(el.childNodes)
    .map((node) => HTMLToSlate(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'P':
      return jsx('element', { type: 'paragraph' }, children);
    case 'UL':
      return jsx('element', { type: 'numbered-list' }, children);
    case 'OL':
      return jsx('element', { type: 'ordered-list' }, children);
    case 'LI':
      return jsx('element', { type: 'list-item' }, children);
    default:
      return children;
  }
};

/**
 * This function finds the path to the route that matches the page_id
 * @param allRoutes - the list of all potential routes
 * @param page_id - The id of the route to match
 */
export const getRoutePath = (allRoutes: CustomRoute[], page_id: string) => {
  let path = '';
  allRoutes.every((route, ind) => {
    path = `[${ind}]`;
    if (route.page_data_id === page_id) {
      return false;
    }
    const subrouteMatched = !route.subroutes?.every((subroute, ind) => {
      if (subroute.page_data_id === page_id) {
        path = path + `.subroutes[${ind}]`;
        return false;
      }
      return true;
    });
    if (subrouteMatched) {
      return false;
    }
    path = '';
    return true;
  });
  return path;
};

// Functions to "focus" and "unfocus" a section in the dom when the mouse hovers over an edit section
export const focusSection = (section: string) => {
  const matchingElement = document.querySelector(
    `[data-edit-id="${section}"]`
  ) as HTMLElement;
  if (matchingElement) {
    matchingElement.style.opacity = '0.5';
    window.scroll({
      top: matchingElement.offsetTop,
      left: 0,
      behavior: 'smooth',
    });
  }
};
export const unfocusSection = (section: string) => {
  const matchingElement = document.querySelector(
    `[data-edit-id="${section}"]`
  ) as HTMLElement;
  if (matchingElement) {
    matchingElement.style.opacity = '1';
  }
};
