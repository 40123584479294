import React from 'react';
import Favicon from 'react-favicon';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { cloneDeep } from 'lodash';

import About from 'src/pages/About/About';
import Process from 'src/pages/About/Process';
import Team from 'src/pages/About/Team';
import AuthOverlay from 'src/pages/Auth/AuthOverlay';
import Insights from 'src/pages/Insights/Insights';
import InsightSummary from 'src/pages/Insights/InsightSummary';
import Landing from 'src/pages/Landing/Landing';
import ConversationViewContainer from 'src/pages/Voices/ConversationLibrary';
import HighlightExplorer from 'src/pages/Voices/HighlightExplorer/HighlightExplorer';
import Voices from 'src/pages/Voices/Voices';
import appSelectors from 'src/redux/app/app-selectors';
import EmbedableHighlightExplorer from './pages/Embeds/EmbedableHighlightExplorer';
import NotFoundPage from './pages/Error/NotFoundPage';
import { setup } from './redux/app/app-slice';

function App() {
  const routes = useSelector(appSelectors.getCustomRoutes);
  const organization_metadata = useSelector(
    appSelectors.getOrganizationMetadata
  );
  const customTheme = cloneDeep(organization_metadata?.mui_themes);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(setup());
  }, [dispatch]);

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const _mtm = (window._mtm = window._mtm || []);
      _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });

      const d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      if (!s.parentNode) {
        return;
      }
      g.async = true;
      g.src =
        'https://cdn.matomo.cloud/fora.matomo.cloud/container_MeLbXAjH.js';
      s.parentNode.insertBefore(g, s);
    }
  }, []);

  let theme = {};
  if (!customTheme) {
    return <></>;
  }
  if (customTheme) {
    customTheme.typography.fontFamily =
      organization_metadata?.default_font_family;
    //@ts-ignore - theme
    theme = createTheme(customTheme);
  }
  return (
    <ThemeProvider theme={theme}>
      <Favicon url={organization_metadata?.favicon ?? ''} />
      <Routes>
        <Route path="/" element={<AuthOverlay />}>
          {!(routes && organization_metadata) ? (
            // TODO: Add loading state
            <></>
          ) : (
            <>
              {routes.map((route) => {
                switch (route.path) {
                  case '/':
                    return (
                      route.visible && (
                        <Route
                          index
                          key={route.label}
                          element={
                            <Landing page_data_id={route.page_data_id} />
                          }
                        />
                      )
                    );
                  case '/insights':
                    return (
                      <>
                        {route.visible && (
                          <Route
                            path="/insights"
                            key={route.label}
                            element={
                              <Insights page_data_id={route.page_data_id} />
                            }
                          />
                        )}
                        {route.subroutes?.map(
                          (subroute) =>
                            subroute.visible && (
                              <Route
                                key={subroute.label}
                                path={route.path + subroute.path}
                                element={
                                  <InsightSummary
                                    page_data_id={subroute.page_data_id}
                                  />
                                }
                              />
                            )
                        )}
                      </>
                    );
                  case '/voices':
                    return (
                      <>
                        {route.visible && (
                          <Route
                            path="/voices"
                            key={route.label}
                            element={
                              <Voices page_data_id={route.page_data_id} />
                            }
                          />
                        )}
                        {route.subroutes?.map((subroute) => {
                          switch (subroute.path) {
                            case '/conversation-library':
                              return (
                                subroute.visible && (
                                  <Route
                                    key={subroute.label}
                                    path="/voices/conversation-library"
                                    element={
                                      <ConversationViewContainer
                                        page_data_id={subroute.page_data_id}
                                      />
                                    }
                                  />
                                )
                              );
                            case '/highlight-explorer':
                              return (
                                subroute.visible && (
                                  <Route
                                    key={subroute.label}
                                    path="/voices/highlight-explorer"
                                    element={
                                      <HighlightExplorer
                                        page_data_id={subroute.page_data_id}
                                      />
                                    }
                                  />
                                )
                              );
                            default:
                              return <></>;
                          }
                        })}
                      </>
                    );
                  case '/about':
                    return (
                      <>
                        {route.visible && (
                          <Route
                            path="/about"
                            key={route.label}
                            element={
                              <About page_data_id={route.page_data_id} />
                            }
                          />
                        )}
                        {route.subroutes?.map((subroute) => {
                          switch (subroute.path) {
                            case '/process':
                              return (
                                subroute.visible && (
                                  <Route
                                    key={subroute.label}
                                    path="about/process"
                                    element={
                                      <Process
                                        page_data_id={subroute.page_data_id}
                                      />
                                    }
                                  />
                                )
                              );
                            case '/team':
                              return (
                                subroute.visible && (
                                  <Route
                                    key={subroute.label}
                                    path="about/team"
                                    element={
                                      <Team
                                        page_data_id={subroute.page_data_id}
                                      />
                                    }
                                  />
                                )
                              );
                            default:
                              return <></>;
                          }
                        })}
                      </>
                    );
                  default:
                    return <></>;
                }
              })}
            </>
          )}
        </Route>
        {/* <Route path="/embed" element={<EmbedsHome />} /> */}
        <Route
          path="/embed/highlight-explorer"
          element={<EmbedableHighlightExplorer />}
        />
        <Route path="*" element={<AuthOverlay />}>
          <Route path="*" element={<NotFoundPage />}></Route>
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
