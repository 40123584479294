import React, { useEffect } from 'react';
import JsxParser from 'react-jsx-parser';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography/Typography';

import { getBanner } from 'src/components/common/banners/utils';
import ImageShowcase from 'src/components/common/sections/ImageShowcase';
import GroupCallout from 'src/components/GroupCallout/GroupCallout';
import appSelectors from 'src/redux/app/app-selectors';
import { StoreState } from 'src/redux/store';
import { PageProps } from 'src/types/common';
import { TeamPage } from 'src/types/pages/about';
import { stripHTMLTags } from 'src/utils/utils';

function Team({ page_data_id }: PageProps) {
  const pageInfo = useSelector((state: StoreState) =>
    appSelectors.getPage(state, page_data_id)
  ) as TeamPage;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {pageInfo && (
        <div>
          {pageInfo.header?.visible && (
            <div
              data-edit-id={`${pageInfo.page_id}.header`}
              style={{ width: '100%' }}
            >
              {getBanner(pageInfo.header.content)}
            </div>
          )}
          {pageInfo.staffShowcase?.visible && (
            <div
              data-testid="page-info-staff"
              data-edit-id={`${pageInfo.page_id}.staffShowcase`}
              style={{ width: '100%', paddingTop: '2rem' }}
            >
              <GroupCallout
                {...pageInfo.staffShowcase.content}
                showBackground={pageInfo.staffShowcase.background ?? false}
              />
            </div>
          )}
          {pageInfo.adminShowcase?.visible && (
            <div
              data-testid="page-info-admin"
              data-edit-id={`${pageInfo.page_id}.adminShowcase`}
              style={{ width: '100%' }}
            >
              <GroupCallout
                {...pageInfo.adminShowcase.content}
                showBackground={pageInfo.adminShowcase.background ?? false}
              />
            </div>
          )}
          {pageInfo.partnerShowcase?.visible && (
            <div
              data-edit-id={`${pageInfo.page_id}.partnerShowcase`}
              style={{ width: '100%' }}
            >
              <ImageShowcase
                {...pageInfo.partnerShowcase.content}
                showBackground={pageInfo.partnerShowcase.background ?? false}
              />
            </div>
          )}
          {stripHTMLTags(pageInfo.addendumText) !== '' && (
            <div
              data-edit-id={`${pageInfo.page_id}.addendumText`}
              style={{ width: '100%' }}
            >
              <Typography align="center" variant="body2">
                {/* @ts-ignore - jsx parser */}
                <JsxParser jsx={pageInfo.addendumText} />
              </Typography>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Team;
