import React, { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

import HighlightContainer from 'src/components/Audio/Highlight/Highlight';
import { Highlight } from 'src/types/common';
import { FilterOption } from 'src/types/filters';
import { slugify } from 'src/utils/utils';

interface HighlightScrollProps {
  filters: FilterOption[];
  handleRemoveFilter: (filter: FilterOption) => void;
  onOpenMap?: () => void;
  highlights: Highlight[];
  sectionId: string;
  isMapShowing?: boolean;
}

function HighlightScroll({
  filters,
  handleRemoveFilter,
  onOpenMap,
  highlights,
  sectionId,
  isMapShowing,
}: HighlightScrollProps) {
  const [selectedHighlight, setSelectedHighlight] = useState<number>();

  // for lazy loading
  const [displayIndex, setDisplayIndex] = useState<number>(5);
  const [displayHighlights, setDisplayHighlights] = useState<Highlight[]>([]);

  const handleSelect = (hid: number) => {
    setSelectedHighlight(hid);
  };

  const handleWaypointEnter = (index: number) => {
    setDisplayIndex(
      Math.min(Math.max(displayIndex, index + 2), highlights.length)
    );
  };

  useEffect(() => {
    setDisplayHighlights(highlights.slice(0, displayIndex));
  }, [highlights, displayIndex]);

  return (
    <Grid
      id="highlight-scroll"
      style={{
        background: '#f3f3f3',
        height: '85vh',
        fontSize: '14px',
        overflow: 'scroll',
      }}
    >
      <Grid
        container
        paddingLeft={{ xs: 4, md: 6 }}
        paddingRight={{ xs: 4, md: 3 }}
        paddingTop={7}
      >
        <Grid
          xs={isMapShowing ? 12 : 6}
          item
          data-testid="number-of-highlights"
        >
          {highlights.length} highlights.
        </Grid>
        {onOpenMap && (
          <Grid
            xs={6}
            item
            sx={{
              display: isMapShowing ? 'none' : 'block',
              textAlign: 'right',
              paddingRight: '1rem',
            }}
          >
            <Button onClick={onOpenMap} size="small">
              <ArrowBackIosIcon
                sx={{
                  fontSize: '12px',
                  verticalAlign: 'middle',
                  paddingLeft: '.5rem',
                }}
              />{' '}
              Show Map
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid marginX={{ xs: 3, md: 5 }} marginY={2} xs={12}>
        {filters.map((item) => (
          <Chip
            label={item.label}
            data-testid={`${slugify(item.label)}-filter-chip`}
            onDelete={() => handleRemoveFilter(item)}
            sx={{ marginRight: 1, marginBottom: 1 }}
          />
        ))}
      </Grid>
      <Grid
        container
        paddingX={{ xs: 4, md: 5 }}
        paddingTop={3}
        sx={{ width: '100%' }}
      >
        {displayHighlights.map((highlight, highlightIndex) => (
          <Waypoint
            key={`highlight-scroll-waypoint-${highlight.id}`}
            onEnter={() => handleWaypointEnter(highlightIndex)}
            topOffset={'33%'}
            bottomOffset={'10%'}
          >
            <Grid
              item
              key={highlight.id}
              xs={12}
              md={isMapShowing ? 12 : 6}
              paddingRight={{ xs: 0, md: isMapShowing ? 2 : 7.5 }}
              paddingBottom={8}
            >
              <HighlightContainer
                sectionId={sectionId}
                highlight={highlight}
                onSelect={handleSelect}
                selectedHighlightId={selectedHighlight}
                scrolling
              />
            </Grid>
          </Waypoint>
        ))}
      </Grid>
    </Grid>
  );
}

export default HighlightScroll;
