import { Codes } from 'src/types/common';

export const CODES: Codes = {
  analytic: [
    {
      id: 1,
      display_name: '*structural codes',
      subcodes: [
        {
          id: 2,
          display_name: 'opportunities',
          count: 79,
          label: 'opportunities',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 3,
          display_name: 'challenges',
          count: 94,
          label: 'challenges',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 4,
          display_name: 'lived experiences',
          count: 79,
          label: 'lived experiences',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 5,
          display_name: 'resonating experiences',
          count: 51,
          label: 'resonating experiences',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 6,
          display_name: 'fmcs future',
          count: 71,
          label: 'fmcs future',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 7,
          display_name: 'takeaways',
          count: 43,
          label: 'takeaways',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 8,
          display_name: 'lived experences',
          count: 20,
          label: 'lived experences',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
      ],
      count: 319,
      label: '*structural codes',
      showcase_colors: {
        background_color: '#11304B',
        text_color: '#ffffff',
      },
      conversation_library_colors: {
        background_color: '#11304B',
        text_color: '#ffffff',
      },
      embed_colors: {
        background_color: '#11304B',
        text_color: '#ffffff',
      },
    },
    {
      id: 9,
      display_name: 'client engagement',
      subcodes: [
        {
          id: 10,
          display_name: 'effective service',
          count: 94,
          label: 'effective service',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 11,
          display_name: 'meeting spaces',
          count: 79,
          label: 'meeting spaces',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 12,
          display_name: 'relationship building',
          count: 68,
          label: 'relationship building',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 13,
          display_name: 'client satisfaction',
          count: 37,
          label: 'client satisfaction',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
      ],
      count: 149,
      label: 'client engagement',
      showcase_colors: {
        background_color: '#11304B',
        text_color: '#ffffff',
      },
      conversation_library_colors: {
        background_color: '#327342',
        text_color: '#ffffff',
      },
      embed_colors: {
        background_color: '#327342',
        text_color: '#ffffff',
      },
    },
    {
      id: 14,
      display_name: 'training + development',
      subcodes: [
        {
          id: 15,
          display_name: 'technology',
          count: 93,
          label: 'technology',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 16,
          display_name: 'upskilling',
          count: 66,
          label: 'upskilling',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 17,
          display_name: 'resistance to change',
          count: 29,
          label: 'resistance to change',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
      ],
      count: 130,
      label: 'training + development',
      showcase_colors: {
        background_color: '#11304B',
        text_color: '#ffffff',
      },
      conversation_library_colors: {
        background_color: '#355d72',
        text_color: '#ffffff',
      },
      embed_colors: {
        background_color: '#355d72',
        text_color: '#ffffff',
      },
    },
    {
      id: 18,
      display_name: 'work-life balance',
      subcodes: [
        {
          id: 19,
          display_name: 'scheduling',
          count: 29,
          label: 'scheduling',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 20,
          display_name: 'travel',
          count: 26,
          label: 'travel',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 21,
          display_name: 'wellness',
          count: 16,
          label: 'wellness',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 22,
          display_name: 'family',
          count: 11,
          label: 'family',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
      ],
      count: 55,
      label: 'work-life balance',
      showcase_colors: {
        background_color: '#11304B',
        text_color: '#ffffff',
      },
      conversation_library_colors: {
        background_color: '#3b899b',
        text_color: '#ffffff',
      },
      embed_colors: {
        background_color: '#3b899b',
        text_color: '#ffffff',
      },
    },
    {
      id: 23,
      display_name: 'relationships',
      subcodes: [
        {
          id: 24,
          display_name: 'disconnection',
          count: 46,
          label: 'disconnection',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 25,
          display_name: 'connection',
          count: 60,
          label: 'connection',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 26,
          display_name: 'teamwork',
          count: 39,
          label: 'teamwork',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
      ],
      count: 125,
      label: 'relationships',
      showcase_colors: {
        background_color: '#11304B',
        text_color: '#ffffff',
      },
      conversation_library_colors: {
        background_color: '#9ac255',
        text_color: '#ffffff',
      },
      embed_colors: {
        background_color: '#9ac255',
        text_color: '#ffffff',
      },
    },
    {
      id: 27,
      display_name: 'remote working experience',
      subcodes: [
        {
          id: 28,
          display_name: 'impersonal',
          count: 41,
          label: 'impersonal',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 29,
          display_name: 'workflow',
          count: 78,
          label: 'workflow',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 30,
          display_name: 'learning curve',
          count: 64,
          label: 'learning curve',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 31,
          display_name: 'fatigue',
          count: 21,
          label: 'fatigue',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
      ],
      count: 152,
      label: 'remote working experience',
      showcase_colors: {
        background_color: '#11304B',
        text_color: '#ffffff',
      },
      conversation_library_colors: {
        background_color: '#519237',
        text_color: '#ffffff',
      },
      embed_colors: {
        background_color: '#519237',
        text_color: '#ffffff',
      },
    },
    {
      id: 32,
      display_name: 'intra-organizational',
      subcodes: [
        {
          id: 33,
          display_name: 'collaboration',
          count: 54,
          label: 'collaboration',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 34,
          display_name: 'awareness',
          count: 65,
          label: 'awareness',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 35,
          display_name: 'engagement',
          count: 68,
          label: 'engagement',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
      ],
      count: 130,
      label: 'intra-organizational',
      showcase_colors: {
        background_color: '#11304B',
        text_color: '#ffffff',
      },
      conversation_library_colors: {
        background_color: '#00a0af',
        text_color: '#ffffff',
      },
      embed_colors: {
        background_color: '#00a0af',
        text_color: '#ffffff',
      },
    },
    {
      id: 36,
      display_name: 'culture',
      subcodes: [
        {
          id: 37,
          display_name: 'inclusion',
          count: 33,
          label: 'inclusion',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 38,
          display_name: 'internal politics or structure',
          count: 57,
          label: 'internal politics or structure',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 39,
          display_name: 'espirit de corps',
          count: 0,
          label: 'espirit de corps',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 40,
          display_name: 'power dynamics',
          count: 25,
          label: 'power dynamics',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 41,
          display_name: 'esprit de corps',
          count: 48,
          label: 'esprit de corps',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
      ],
      count: 99,
      label: 'culture',
      showcase_colors: {
        background_color: '#11304B',
        text_color: '#ffffff',
      },
      conversation_library_colors: {
        background_color: '#cc8899',
        text_color: '#ffffff',
      },
      embed_colors: {
        background_color: '#cc8899',
        text_color: '#ffffff',
      },
    },
    {
      id: 42,
      display_name: 'process',
      subcodes: [
        {
          id: 43,
          display_name: 'appreciation',
          count: 26,
          label: 'appreciation',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 44,
          display_name: 'feedback',
          count: 18,
          label: 'feedback',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 45,
          display_name: 'approach-strategy',
          count: 12,
          label: 'approach-strategy',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 46,
          display_name: 'critique',
          count: 5,
          label: 'critique',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
      ],
      count: 39,
      label: 'process',
      showcase_colors: {
        background_color: '#11304B',
        text_color: '#ffffff',
      },
      conversation_library_colors: {
        background_color: '#ffa500',
        text_color: '#ffffff',
      },
      embed_colors: {
        background_color: '#ffa500',
        text_color: '#ffffff',
      },
    },
  ],
  demographic: [
    {
      id: 0,
      display_name: 'fmcs tenure',
      subcodes: [
        {
          id: 1,
          display_name: '0-5 years',
          count: 0,
          label: '0-5 years',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 2,
          display_name: '5-10 years',
          count: 79,
          label: '5-10 years',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 3,
          display_name: '10-15 years',
          count: 94,
          label: '10-15 years',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 4,
          display_name: '15+ years',
          count: 79,
          label: '15+ years',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
      ],
      count: 164,
      label: 'fmcs tenure',
      showcase_colors: {
        background_color: '#11304B',
        text_color: '#ffffff',
      },
      conversation_library_colors: {
        background_color: '#11304B',
        text_color: '#ffffff',
      },
      embed_colors: {
        background_color: '#11304B',
        text_color: '#ffffff',
      },
    },
    {
      id: 5,
      display_name: 'region',
      map: true,
      subcodes: [
        {
          id: 6,
          display_name: 'JA Africa',
          count: 71,
          label: 'JA Africa',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 7,
          display_name: 'JA Americas',
          count: 43,
          label: 'JA Americas',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 8,
          display_name: 'JA Asia Pacific',
          count: 20,
          label: 'JA Asia Pacific',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 9,
          display_name: 'JA Europe',
          count: 1,
          label: 'JA Europe',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 10,
          display_name: 'INJAZ Al-Arab JA MENA',
          count: 94,
          label: 'INJAZ Al-Arab JA MENA',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
        {
          id: 11,
          display_name: 'JA USA',
          count: 79,
          label: 'JA USA',
          showcase_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          conversation_library_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
          embed_colors: {
            background_color: '#11304B',
            text_color: '#ffffff',
          },
        },
      ],
      count: 244,
      label: 'region',
      showcase_colors: {
        background_color: '#11304B',
        text_color: '#ffffff',
      },
      conversation_library_colors: {
        background_color: '#11304B',
        text_color: '#ffffff',
      },
      embed_colors: {
        background_color: '#11304B',
        text_color: '#ffffff',
      },
    },
  ],
};
