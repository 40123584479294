import { useSelector } from 'react-redux';
import { cloneDeep, isEqual } from 'lodash';

import appSelectors from 'src/redux/app/app-selectors';
import { Code } from 'src/types/common';
import { MapItem } from 'src/types/map';
import { valueOrThrow } from 'src/utils/utils';
import { InitialMapItems, NULL_PK, UNASSIGNED_COLOR } from './mapUtils';

/**
 * Custom hook that either returns the existing map or creates a new list of MapItem from the mappable demographic codes
 * @returns object containing list of MapItems[] that make up the initial map, a boolean flag to indicate whether or not it was retrieved or generated, and the initial demographic codes
 */
export const useInitialMapItems = (): InitialMapItems => {
  const existingMapItems: MapItem[] = useSelector(appSelectors.getMap) ?? [];
  const demographicCodes: Code[] = useSelector(appSelectors.getCodes)
    .demographic.filter((demographicCode: Code) => demographicCode.map === true)
    .flatMap((code) => valueOrThrow<Code[]>(code.subcodes));

  let initialMapItems: MapItem[];
  let generated: boolean;
  let initialDemographicCodes: Code[];

  // need to determine whether or not the mappable codes have changed since the map was last edited/uploaded
  // if the user have changed which codes are mappable, treat this as a wholly new upload
  const demographicCodeIDs: number[] = demographicCodes.map((code) => code.id);
  const mapCodeIDs: number[] = existingMapItems.map(
    (mapItem) => mapItem.demographic_id
  );
  mapCodeIDs.splice(-1, 1);
  const mappableCodesChanged =
    mapCodeIDs.length > 0 && !isEqual(demographicCodeIDs, mapCodeIDs);

  if (existingMapItems.length > 0 && !mappableCodesChanged) {
    initialMapItems = cloneDeep(existingMapItems);
    generated = false;
    initialDemographicCodes = [...demographicCodes];
  } else {
    initialMapItems = demographicCodes.map((code: Code): MapItem => {
      return {
        demographic_id: code.id,
        label: code.label,
        paths: [],
        color:
          code.embed_colors.background_color === ''
            ? UNASSIGNED_COLOR
            : code.embed_colors.background_color,
        hide: false,
      };
    });

    initialMapItems.push({
      demographic_id: NULL_PK,
      label: 'Unselected Map Regions',
      paths: [],
      color: UNASSIGNED_COLOR,
      hide: true,
    });
    generated = true;
    initialDemographicCodes = [...demographicCodes];
  }
  return {
    mapItems: initialMapItems,
    generated: generated,
    demographicCodes: initialDemographicCodes,
    mappableCodesChanged: mappableCodesChanged,
  };
};
