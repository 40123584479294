import React from 'react';
import { SxProps, Theme } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

interface BannerProps {
  children: React.ReactElement;
  tabs?: React.ReactElement;
  center?: boolean;
  sx?: SxProps<Theme>;
}

function Banner({ children, center, tabs: subNavigation, sx }: BannerProps) {
  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        sx={sx}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <Grid
            item
            xs={12}
            md={center ? 12 : 7}
            marginY={15}
            marginX={{ xs: 3, sm: 6, md: 0 }}
          >
            {children}
          </Grid>
          {subNavigation}
        </Container>
      </Grid>
    </Grid>
  );
}

export default Banner;
