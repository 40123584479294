import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getMatchingFilters } from 'src/components/common/filters/utils';
import appSelectors from 'src/redux/app/app-selectors';
import { FilterOption } from 'src/types/filters';
import { EDITING_QUERY } from 'src/utils/useGeneratePath';
import { getQueryArg, makeFilterQueryArgs } from 'src/utils/utils';

export const useFilters = () => {
  const filters = useSelector(appSelectors.getFilters);
  const [activeFilters, setActiveFilters] = useState<FilterOption[]>([]);
  const firstUpdate = React.useRef(true);

  const matchingSearchParams = React.useMemo(
    () =>
      filters?.map(({ label }) => ({
        label,
        query: getQueryArg(label.toLowerCase()),
      })),
    [filters]
  );

  useEffect(() => {
    if (filters) {
      setActiveFilters(getMatchingFilters(filters, matchingSearchParams));
    }
    window.scrollTo(0, 0);
  }, [filters, matchingSearchParams]);

  useEffect(() => {
    if (filters && !firstUpdate.current) {
      const newURL = new URL(window.location.href);
      const newSearchParams = makeFilterQueryArgs(filters, activeFilters);
      const logoUrl = new URLSearchParams(window.location.search).get('logo');

      const isEditing = location.search.includes(EDITING_QUERY);
      newURL.search = `?${isEditing ? `editing&` : ''}${
        logoUrl ? `logo=${logoUrl}&` : ''
      }${Object.keys(newSearchParams)
        .map((key) => `${key}=${newSearchParams[key]}`)
        .join('&')}`;
      window.history.replaceState(null, '', newURL.toString());
    }
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
  }, [activeFilters, filters]);

  return { filters, activeFilters, setActiveFilters };
};
