import React from 'react';
import Grid from '@mui/material/Grid';

import CardTab from './CardTab';

interface CardTabsProps {
  items: any[];
  selectedIdx: number;
  onClick: (idx: number) => void;
  incrementLabel?: string;
  padding?: boolean;
  small?: boolean;
}

function CardTabs({
  items,
  incrementLabel,
  selectedIdx,
  onClick,
  padding,
  small,
}: CardTabsProps) {
  const itemsPerLine = Math.floor(12 / items.length);
  return (
    <Grid paddingX={padding ? 8 : 0} container sx={{ zIndex: '100' }}>
      <Grid
        container
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          zIndex: '100',
        }}
      >
        {items.map((item, idx) => {
          return (
            <Grid item xs={itemsPerLine} sx={{ height: '100%' }} key={idx}>
              <CardTab
                idx={idx}
                label={incrementLabel ? `${incrementLabel} ${idx + 1}` : ''}
                body={item.label}
                selectedIdx={selectedIdx}
                onClick={onClick}
                primaryColor={item.primaryColor}
                secondaryColor={item.secondaryColor}
                small={small}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

export default CardTabs;
