import { EditType } from 'src/types/edit';
import { AboutPage } from 'src/types/pages/about';

export const ABOUT: AboutPage = {
  page_id: 'about',
  section_order: [],
  header: {
    visible: true,
    content: {
      title: 'About',
      body: 'Learn more about the sensemaking process behind this portal and the team that helped us create it.',
      header_type: 'full',
      texture: true,
    },
  },
  navigation_cards: {
    visible: true,
    content: {
      cards: [
        {
          title: 'Our Team',
          body: '<p>Insert notes about the team</p>',
          image: '',
          link: { to: '/about/team', label: 'View Team' },
        },
        {
          title: 'Our Process',
          body: '<p>Insert notes about the process</p>',
          image: '',
          link: { to: '/about/process', label: 'View Process' },
        },
      ],
      card_order: [],
    },
  },
  addendumText: '',
  edit_metadata: {
    page_label: 'About',
    page_id: 'about',
    sections: [
      {
        section_label: 'Introduction',
        edit_blocks: [
          {
            block_label: 'Header',
            match: 'header.content',
            type: EditType.banner,
          },
        ],
      },
      {
        section_label: 'Navigation Cards',
        update_section: {
          match: 'navigation_cards.content.cards',
          label: 'Add Navigation Card',
          empty: {
            title: 'Default Title',
            body: '<p>Default Body</p>',
            image: '',
            link: {
              to: '/',
              label: 'Default Link',
            },
          },
          newSectionMetadata: {
            section_label: 'Navigation Card',
            numbered: true,
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'navigation_cards.content.cards[index].title',
                type: EditType.string,
              },
              {
                block_label: 'Paragraph',
                match: 'navigation_cards.content.cards[index].body',
                type: EditType.complex_string,
              },
              {
                block_label: 'Image',
                match: 'navigation_cards.content.cards[index].image',
                type: EditType.image,
              },
              {
                block_label: 'Link',
                match: 'navigation_cards.content.cards[index].link',
                type: EditType.link,
              },
            ],
          },
        },
        sub_sections: [
          {
            section_label: 'Navigation Card',
            numbered: true,
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'navigation_cards.content.cards[0].title',
                type: EditType.string,
              },
              {
                block_label: 'Paragraph',
                match: 'navigation_cards.content.cards[0].body',
                type: EditType.complex_string,
              },
              {
                block_label: 'Image',
                match: 'navigation_cards.content.cards[0].image',
                type: EditType.image,
              },
              {
                block_label: 'Link',
                match: 'navigation_cards.content.cards[0].link',
                type: EditType.link,
              },
            ],
          },
          {
            section_label: 'Navigation Card',
            numbered: true,
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'navigation_cards.content.cards[1].title',
                type: EditType.string,
              },
              {
                block_label: 'Paragraph',
                match: 'navigation_cards.content.cards[1].body',
                type: EditType.complex_string,
              },
              {
                block_label: 'Image',
                match: 'navigation_cards.content.cards[1].image',
                type: EditType.image,
              },
              {
                block_label: 'Link',
                match: 'navigation_cards.content.cards[1].link',
                type: EditType.link,
              },
            ],
          },
        ],
      },
      {
        section_label: 'Addendum',
        edit_blocks: [
          {
            block_label: 'Paragraph',
            match: 'addendumText',
            type: EditType.complex_string,
          },
        ],
      },
    ],
  },
};
