import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import BadExample from './examples/bad-map-upload-example.png';
import GoodExample from './examples/good-map-upload-example.png';

const FileUploadDialog = ({
  showUploadModal,
  toggleUploadModal,
  fileUpload,
}: {
  showUploadModal: boolean;
  toggleUploadModal: () => void;
  fileUpload: (
    event: React.ChangeEvent<HTMLInputElement>,
    threshhold?: number
  ) => void;
}) => {
  const [threshhold, setThreshhold] = React.useState<number>();
  const [tabIndex, setTabIndex] = React.useState<0 | 1>(0);

  const getPage = () => {
    switch (tabIndex) {
      case 1:
        return (
          <>
            <Typography
              variant="body1"
              color="text.primary"
              sx={{ marginTop: '1rem' }}
            >
              For best results, please upload a moderately-sized (~720 x 720px),
              roughly-square map that has clearly-defined boundaries with little
              to no annotation.
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{
                margin: '0',
                width: '100%',
                height: '100%',
                paddingTop: '1rem',
                flexWrap: 'nowrap',
              }}
            >
              <Grid
                key={1}
                item
                md={6}
                className="light-rounded-border"
                sx={{
                  marginRight: '1rem',
                  paddingRight: '16px',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="h5" color="text.primary">
                  <i className="fas fa-check-square success-icon"></i>Good
                </Typography>
                <img
                  src={GoodExample}
                  alt="Example of a good map upload"
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid
                key={2}
                item
                md={6}
                className="light-rounded-border"
                sx={{
                  marginLeft: '1rem',
                  paddingRight: '16px',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="h5" color="text.primary">
                  <i className="fas fa-times-circle error-icon"></i>Bad
                </Typography>
                <img
                  src={BadExample}
                  alt="Example of a bad map upload"
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          </>
        );

      default:
        return (
          <>
            <Typography>
              Please input a threshold representing the smallest allowable area
              (by percentage) on the map. The default is 1%
            </Typography>
            <br />
            <TextField
              id="outlined-number"
              label="Percent as Decimal"
              data-testid="map-threshold-input"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={0.01}
              onChange={(event) => {
                setThreshhold(parseFloat(event.target.value));
              }}
            />
          </>
        );
    }
  };

  const handleClose = () => {
    setTabIndex(0);
    toggleUploadModal();
  };

  const getButtons = () => {
    switch (tabIndex) {
      case 1:
        return (
          <Button
            variant="contained"
            component="label"
            data-testid="upload-modal-back-button"
            onClick={(e) => {
              e.preventDefault();
              setTabIndex(0);
            }}
          >
            Back
          </Button>
        );

      default:
        return (
          <>
            <Button
              variant="contained"
              component="label"
              data-testid="upload-modal-upload-button"
            >
              Upload
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={(event) => {
                  fileUpload(event, threshhold);
                  handleClose();
                }}
              />
            </Button>
            <Button
              variant="contained"
              component="label"
              data-testid="upload-modal-help-button"
              onClick={() => {
                setTabIndex(1);
              }}
            >
              Help
            </Button>
          </>
        );
    }
  };
  return (
    <Dialog
      open={showUploadModal}
      onClose={handleClose}
      fullWidth
      maxWidth={'sm'}
    >
      <DialogTitle>{tabIndex ? 'Help' : 'Uploading Map'}</DialogTitle>
      <DialogContent>{getPage()}</DialogContent>
      <DialogActions>{getButtons()}</DialogActions>
    </Dialog>
  );
};

export default FileUploadDialog;
