import { EditType } from 'src/types/edit';
import { ConversationLibraryPage } from 'src/types/pages/voices';

export const CONVERSATION_LIBRARY: ConversationLibraryPage = {
  page_id: 'conversation-library',
  section_order: [],
  header: {
    visible: true,
    content: {
      title: 'Voices by Conversation',
      header_type: 'thin',
      body: '',
    },
  },
  edit_metadata: {
    page_label: 'Conversation Library',
    subroute: true,
    sections: [
      {
        section_label: 'Introduction',
        edit_blocks: [
          {
            block_label: 'Header',
            match: 'header.content',
            type: EditType.banner,
          },
        ],
      },
    ],
  },
};
