import React, { ChangeEvent, useState } from 'react';
import { Checkbox, Typography } from '@mui/material';

const CheckboxEditor = React.forwardRef(
  (
    {
      initialValue,
      label,
    }: {
      initialValue: boolean;
      label: string;
    },
    ref
  ) => {
    const [checked, setChecked] = useState(initialValue);
    const handleChange = (value: boolean) => {
      if (ref) {
        (ref as React.MutableRefObject<boolean>).current = value;
      }
      setChecked(value);
    };

    return (
      <>
        <Typography variant="h6" sx={{ marginTop: '1rem' }}>
          {label}
        </Typography>
        <Checkbox
          checked={checked}
          data-testid="checkbox"
          onChange={(
            event: ChangeEvent<HTMLInputElement>,
            checked: boolean
          ) => {
            handleChange(checked);
          }}
        ></Checkbox>
      </>
    );
  }
);

export default CheckboxEditor;
