import React from 'react';

import { Code, ConversationMetadata, Highlight } from 'src/types/common';
import ConversationButtonTags from './ConversationButtonTags';
import ConversationModal from './ConversationModal';
import { useConversationLibrarySizing } from './utils/useConversationLibrarySizing';
import { useDrawConversations } from './utils/useDrawConversations';
import { useSnippetEvents } from './utils/useSnippetEvents';

interface ConversationVizProps {
  conversations: ConversationMetadata[];
  codes: Code[];
  highlights: Highlight[];
}

function ConversationViz({
  conversations,
  codes,
  highlights,
}: ConversationVizProps) {
  const [selectedHighlight, setSelectedHighlight] = React.useState<Highlight>();

  const handleModalClose = () => {
    setSelectedHighlight(undefined);
  };
  const updateSelectedHighlight = React.useCallback(
    (id?: number) => {
      setSelectedHighlight(highlights.find((highlight) => highlight.id === id));
    },
    [highlights]
  );

  return (
    <div>
      <MemoVizBase
        conversations={conversations}
        codes={codes}
        updateSelectedHighlight={updateSelectedHighlight}
      />
      <ConversationModal
        onClose={handleModalClose}
        highlight={selectedHighlight}
      ></ConversationModal>
    </div>
  );
}

const VizBase = ({
  conversations,
  codes,
  updateSelectedHighlight,
}: Omit<ConversationVizProps, 'highlights'> & {
  updateSelectedHighlight: (id?: number) => void;
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const svgRef = React.useRef<SVGSVGElement>(null);
  const { mouseoverSnippet, mouseleaveSnippet, snippetClick } =
    useSnippetEvents(containerRef, updateSelectedHighlight);

  const {
    libraryHeight,
    libraryWidth,
    numberOfColumns,
    processedConversations,
    isMobileScreen,
  } = useConversationLibrarySizing(containerRef, conversations);
  useDrawConversations(
    svgRef,
    codes,
    processedConversations,
    libraryWidth,
    libraryHeight,
    numberOfColumns,
    isMobileScreen,
    snippetClick,
    mouseoverSnippet,
    mouseleaveSnippet
  );

  return (
    <div ref={containerRef} className="conversation-viz">
      {isMobileScreen && (
        <ConversationButtonTags
          codes={codes}
          svgWidth={libraryWidth}
          svgHeight={libraryHeight}
          isMobile={isMobileScreen}
        ></ConversationButtonTags>
      )}
      <svg ref={svgRef} width={libraryWidth} height={libraryHeight} />
      {!isMobileScreen && (
        <ConversationButtonTags
          codes={codes}
          svgWidth={libraryWidth}
          svgHeight={libraryHeight}
          isMobile={isMobileScreen}
        ></ConversationButtonTags>
      )}
    </div>
  );
};

const MemoVizBase = React.memo(VizBase);

export default ConversationViz;
