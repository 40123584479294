import React from 'react';
import { animated, useSpring } from 'react-spring';

import { D3CodeObject } from './utils';

interface BarProps {
  d: D3CodeObject;
  y: d3.ScaleBand<string>;
  x: d3.ScaleLinear<number, number>;
  padding: number;
  labelX: number;
  barTextY: number;
  barX: number;
  barHeight: number;
  onBarClick?: (id?: string) => void;
}

const Bar = ({
  d,
  y,
  x,
  padding,
  labelX,
  barTextY,
  barX,
  barHeight,
  onBarClick = () => {
    return;
  },
}: BarProps) => {
  // the whole row is going to be at the same yPos
  const yPos = y(d.label) || 0;

  // bar
  const barWidth = x(d.cumulative_count);

  // value label (i.e. 33 conversations)
  let countX = padding;

  // short bars will have their labels at the end of the bar
  const shortBarWidth = 40;
  const showLabelAtEndOfBar = barWidth < shortBarWidth;
  if (showLabelAtEndOfBar) {
    countX = x(d.cumulative_count) + padding;
  }

  const labelText = d.label;
  const countText = `${d.cumulative_count}`;
  let countTextAnchor;

  const animatedProps = useSpring({
    width: barWidth,
  });

  return (
    <>
      <style scoped>
        {`/* This is not our usual way to define a style, however, since we want the style definition to update with
          every change in seektime (and we do not want the editor to update as well), we use this css trick.
          */
          g {
            cursor: pointer;
          }
          `}
      </style>
      <g
        key={`${d.label}`}
        transform={`translate(0 ${yPos})`}
        data-testid={`${d.label}-bar`}
        onClick={() => onBarClick(d.id)}
        fill={d.background_color}
      >
        <text
          x={labelX}
          y={barTextY}
          textAnchor="end"
          dominantBaseline="central"
          style={{ fontWeight: 500, wordWrap: 'break-word' }}
          fill="#000000"
        >
          {labelText}
        </text>
        <animated.rect
          x={barX}
          height={barHeight}
          rx={2}
          style={animatedProps}
        />
        <text
          x={countX}
          y={barTextY}
          dominantBaseline="central"
          textAnchor={countTextAnchor}
          fill={showLabelAtEndOfBar ? d.background_color : d.text_color}
        >
          {countText}
        </text>
      </g>
    </>
  );
};

export default Bar;
