/**
 * This file should contain idempotent migration functions to be executed from editmetadata.ts.
 *
 * PLEASE PROCEED WITH GREAT CAUTION, AS THIS WILL AFFECT LIVE PORTAL DATA
 */

import { Codes, PageBase } from 'src/types/common';
import { FilterGroup } from 'src/types/filters';
import { ProcessPage } from 'src/types/pages/about';
import { HomePage } from 'src/types/pages/home';
import { HighlightsPage } from 'src/types/pages/voices';
import { CustomRoute } from 'src/types/routes';
import { codesToFilters } from './code';
import { getFilterOrder } from './pages';

export const migrateToVersion1 = (pages: PageBase[]): PageBase[] => {
  const processPage: ProcessPage = pages.find(
    (page) => page.page_id === 'process'
  ) as ProcessPage;
  // check if update has already ran
  if (!processPage.facilitated_conversations.image) {
    pages.forEach((page: PageBase) => {
      if (page.page_id === 'process') {
        (page as ProcessPage).conversation_portal.image = '';
        (page as ProcessPage).facilitated_conversations.image = '';
        delete (page as any).facilitated_conversations.content;
        (page as ProcessPage).sensemaking.image = '';
        delete (page as any).sensemaking.content;
      }
    });
  }
  return pages;
};

export const migrateToVersion2 = (pages: PageBase[]): PageBase[] => {
  const homePage: HomePage = pages.find(
    (page) => page.page_id === 'home'
  ) as HomePage;
  // check if update has already ran
  if (!homePage.insights_showcase.content.overview.graph_type) {
    pages.forEach((page: PageBase) => {
      if (page.page_id === 'home') {
        (page as HomePage).insights_showcase.content.overview.graph_type =
          'packed_circles';
      }
    });
  }
  return pages;
};

export const migrateToVersion3 = (pages: PageBase[]): PageBase[] => {
  const homePage: HomePage = pages.find(
    (page) => page.page_id === 'home'
  ) as HomePage;
  // check if update has already ran
  if (!homePage.map_showcase.content.zoomable) {
    pages.forEach((page: PageBase) => {
      if (page.page_id === 'home') {
        (page as HomePage).map_showcase.content.zoomable = false;
      }
    });
  }
  const highlightsPage: HighlightsPage = pages.find(
    (page) => page.page_id === 'highlight-explorer'
  ) as HighlightsPage;
  if (!highlightsPage.map.content.zoomable) {
    pages.forEach((page: PageBase) => {
      if (page.page_id === 'highlight-explorer') {
        (page as HighlightsPage).map.content.zoomable = false;
      }
    });
  }
  return pages;
};

/**
 * This migration changes the existing filters to match the new filter paradigm
 */
export const migrateToVersion4 = (
  pages: PageBase[],
  codes: Codes
): { pages: PageBase[]; filters: FilterGroup[] } => {
  const filters = codesToFilters(codes);
  const highlightsModification = getFilterOrder(pages, filters);
  return { pages: highlightsModification.pages, filters };
};

/**
 * This migration adds the in_nav property to the routes
 */
export const migrateToVersion5 = (routes: CustomRoute[]): CustomRoute[] => {
  routes.forEach((route) => {
    route.in_nav = true;
    route.subroutes?.forEach((subRoute) => {
      subRoute.in_nav = true;
    });
  });
  return routes;
};

export const migrateToVersion6 = (pages: PageBase[]): PageBase[] => {
  const homePage: HomePage = pages.find(
    (page) => page.page_id === 'home'
  ) as HomePage;
  // check if update has already ran
  if (!homePage.insights_showcase.content.overview.disable_through_click) {
    pages.forEach((page: PageBase) => {
      if (page.page_id === 'home') {
        (
          page as HomePage
        ).insights_showcase.content.overview.disable_through_click = false;
      }
    });
  }
  return pages;
};

// export const migrateToVersionX = (pages: PageBase[]): PageBase[] => {}
// export const migrateToVersionX = (pages: PageBase[]): PageBase[] => {}
// ...etc
