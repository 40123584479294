import React from 'react';
import { Grid } from '@mui/material';

import { ProfileMetadata } from 'src/types/common';
import Profile from './Profile';

function ProfileGrid({ people }: { people: ProfileMetadata[] }) {
  return (
    <Grid
      data-testid="profile-grid"
      container
      spacing={6}
      justifyContent="left"
      alignItems="baseline"
    >
      {people.map((person) => (
        <Profile profile={person} key={`about-profile-${person.name}`} />
      ))}
    </Grid>
  );
}

export default ProfileGrid;
