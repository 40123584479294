import React, { useEffect, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import CloseIcon from '@mui/icons-material/Close';
import { Box, ButtonGroup } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as d3 from 'd3';

import { LocationMetadata } from 'src/types/map';
import { hexToGrayscale, slugify } from 'src/utils/utils';
import { resizeMap, useMapZoom } from './utils/utils';

interface InteractiveMapProps {
  locations: LocationMetadata[];
  onLocationClick: (location: string) => void;
  onReset: () => void;
  locationType?: string;
  activeMapFilter?: boolean;
  zoomable?: boolean;
}

function InteractiveMap({
  locations,
  onLocationClick,
  onReset,
  locationType,
  activeMapFilter,
  zoomable,
}: InteractiveMapProps) {
  const svgRef = useRef<SVGSVGElement>(null);
  const svgContainerRef = useRef<HTMLDivElement>(null);

  let instruction = (
    <Typography variant="body2">
      Click on a {locationType?.toLowerCase()} to learn more.
    </Typography>
  );

  if (activeMapFilter) {
    instruction = (
      <Button onClick={onReset} sx={{ padding: 0 }}>
        <Typography variant="body2">
          <CloseIcon
            sx={{
              fontSize: '14px',
              paddingRight: '0.5rem',
              verticalAlign: 'middle',
            }}
          />
          Back to all {locationType?.toLowerCase()}s
        </Typography>
      </Button>
    );
  }
  const { zoomIn, zoomOut } = useMapZoom(svgRef);

  useEffect(() => {
    const draw = () => {
      const svg = d3.select(svgRef.current);
      svg.select('g').remove();
      svg
        .append('g')
        .selectAll('g')
        .data(locations)
        .join('path')
        .attr('fill', (d: LocationMetadata) => {
          const color =
            d.mute && activeMapFilter && d.demographic_id > 0
              ? hexToGrayscale(d.color ?? '#CCC')
              : d.color ?? '#CCC';
          return color;
        })
        .style('cursor', (d: LocationMetadata) => (d.hide ? 'auto' : 'pointer'))
        .attr('d', (d) => d.path ?? '')
        .attr('data-tip', (d: LocationMetadata) => {
          if (d.hide) {
            return '';
          }
          const label = `<b>View ${d.label}</b><br/>`;
          if (d.matching_count > 0) {
            return `${label}${d.matching_count} highlights`;
          }
          return `${label}No highlights`;
        })
        .attr('class', (d: LocationMetadata) => slugify(d.label))
        .attr('data-for', 'map-tooltip')
        .on('click', (e, selectedLocation: LocationMetadata) => {
          if (!selectedLocation.hide) {
            svg
              .selectAll('path')
              .filter((location: any) => {
                // only fill muted regions that aren't the selected region
                return (
                  location.path !== selectedLocation.path &&
                  location.mute &&
                  location.demographic_id > 0 &&
                  location.demographic_id !== selectedLocation.demographic_id
                );
              })
              .attr('fill', (d: any) => hexToGrayscale(d.color));
            onLocationClick(selectedLocation.demographic_id.toString());
            ReactTooltip.hide();
          }
        });
    };

    draw();
    if (svgRef.current && svgContainerRef.current) {
      resizeMap(svgContainerRef.current, svgRef.current);
    }
    ReactTooltip.rebuild();
  }, [activeMapFilter, locations, onLocationClick]);

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '8px 32px 24px',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      {zoomable && (
        <div
          style={{
            position: 'absolute',
            top: 8,
            right: 40,
            zIndex: 2,
          }}
        >
          <ButtonGroup orientation="vertical" sx={{ background: '#ffffff' }}>
            <Button onClick={zoomIn} sx={{ padding: 0 }}>
              +
            </Button>
            <Button onClick={zoomOut} sx={{ padding: 0 }}>
              -
            </Button>
          </ButtonGroup>
        </div>
      )}
      <Grid
        item
        className="map"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          overflow: 'hidden',
          alignItems: 'center',
          height: '90%',
          width: '100%',
        }}
      >
        <Box
          ref={svgContainerRef}
          sx={{
            overflow: 'auto',
            scrollbarWidth: 'auto',
            scrollbarColor: '#6A6C6E #ffffff',
            ['&::-webkit-scrollbar']: { width: '8px', height: '8px' },
            ['&::-webkit-scrollbar-track']: { background: ' #ffffff' },
            ['&::-webkit-scrollbar-thumb']: {
              backgroundColor: '#6A6C6E',
              borderRadius: '10px',
            },
          }}
        >
          <svg
            id="svg"
            ref={svgRef}
            style={{
              transformOrigin: 'top left',
            }}
          />
        </Box>
        <ReactTooltip
          id="map-tooltip"
          delayHide={250}
          effect="float"
          html
          type="light"
        />
      </Grid>
      <Grid
        item
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '10%',
        }}
      >
        {instruction}
      </Grid>
    </Grid>
  );
}

export default InteractiveMap;
