import React from 'react';
import * as d3 from 'd3';

import { getMatchingParentCode } from 'src/setup/code';
import {
  Code,
  ConversationVisualizationMetadata,
  SnippetMetadata,
} from 'src/types/common';

export const useDrawConversations = (
  ref: React.RefObject<SVGSVGElement>,
  codes: Code[],
  conversations: ConversationVisualizationMetadata[],
  svgWidth: number,
  svgHeight: number,
  numberOfColumns: number,
  isMobileScreen: boolean,
  onSnippetClick: (event: any, d: SnippetMetadata) => void,
  onSnippetMouseOver: (event: any, d: Partial<SnippetMetadata>) => void,
  onSnippetMouseLeave: (event: any, d: Partial<SnippetMetadata>) => void
) => {
  const zoom = React.useRef(false);

  const clickToZoom = React.useCallback(
    (event: any, d: any) => {
      const conversationsContainer = d3.select('g.conversations_container');
      let x, y, k;

      if (zoom.current) {
        x = svgWidth / 2;
        y = svgHeight / 2;
        k = 1;
      } else {
        const bbox = event.target.getBBox();
        x = bbox.x + bbox.width / 2;
        y = bbox.y + bbox.height / 2;
        switch (numberOfColumns) {
          case 1:
            k = 1;
            break;
          case 2:
            k = 1.5;
            break;
          default:
            k = 2;
        }
      }

      conversationsContainer
        .transition()
        .duration(750)
        .attr(
          'transform',
          `translate(${svgWidth / 2},${
            svgHeight / 2
          })scale(${k})translate(-${x},-${y})`
        );
      if (zoom.current) {
        d3.selectAll('.speaker_text')
          .transition()
          .duration(750)
          .style('opacity', 0);
        d3.select('.conv_background_' + d)
          .transition()
          .duration(750)
          .style('opacity', 0)
          .on('end', () => {
            d3.select('.conv_background_' + d).style('display', 'none');
          });
      } else {
        d3.selectAll('.conv_id' + d)
          .transition()
          .duration(750)
          .style('opacity', 1);
        d3.select('.conv_g_' + d).raise();
        d3.select('.conv_background_' + d)
          .style('display', '')
          .transition()
          .duration(750)
          .style('opacity', 1);
      }
      zoom.current = !zoom.current;
    },
    [numberOfColumns, svgHeight, svgWidth]
  );

  const drawConversationZoomOverlay = React.useCallback(
    (
      gConversation: d3.Selection<SVGGElement, unknown, null, undefined>,
      conversation: ConversationVisualizationMetadata
    ) => {
      // Draw background rectangle used when zooming in on a
      // conversation to allow the user to click anywhere to
      // zoom back out
      gConversation
        .append('rect')
        .data([conversation.id])
        .attr('class', 'conv_background_' + conversation.id)
        .attr('x', conversation.conversation_zoom_x)
        .attr('y', conversation.conversation_zoom_y)
        .attr('width', svgWidth)
        .attr('height', svgHeight)
        .attr('fill', function () {
          return 'rgba(0, 0, 0, 0.4)';
        })
        .style('opacity', 0)
        .style('display', 'none')
        .on(
          'click',
          !isMobileScreen
            ? clickToZoom
            : () => {
                return;
              }
        );
    },
    [clickToZoom, isMobileScreen, svgHeight, svgWidth]
  );

  const drawConversationRectangle = React.useCallback(
    (
      gConversation: d3.Selection<SVGGElement, unknown, null, undefined>,
      conversation: ConversationVisualizationMetadata
    ) => {
      // Append conversation rectangle (white rectangle background)
      gConversation
        .append('rect')
        .data([conversation.id])
        .attr(
          'x',
          conversation.conversation_starting_x + conversation.text_width
        )
        .attr('y', conversation.conversation_starting_y)
        .attr(
          'width',
          conversation.conversation_width - conversation.text_width
        )
        .attr('height', conversation.conversation_height)
        .attr('fill', function () {
          return '#FFFFFF';
        })
        .on(
          'click',
          !isMobileScreen
            ? clickToZoom
            : () => {
                return;
              }
        );
    },
    [clickToZoom, isMobileScreen]
  );

  const drawSnippets = React.useCallback(
    (
      gConversation: d3.Selection<SVGGElement, unknown, null, undefined>,
      conversation: ConversationVisualizationMetadata
    ) => {
      // Create scale based on conversation duration.
      // Is used to decide the size of a snippet
      const speakerList = conversation.snippets.reduce(
        (list, snippet) => {
          if (!list.find((speaker) => speaker.id == snippet.speaker_id)) {
            list.push({ id: snippet.speaker_id, name: snippet.speaker_name });
          }
          return list;
        },
        [] as { id: number; name: string }[]
      );
      const scale = d3
        .scaleLinear()
        .domain([0, conversation.duration])
        .range([
          0,
          conversation.conversation_width -
            2 * conversation.conversation_padding -
            conversation.text_width,
        ]);
      gConversation
        .append('g')
        .selectAll('snippet')
        .data(conversation.snippets)
        .enter()
        .append('rect')
        .attr('id', (snippet: SnippetMetadata) => {
          return 'id' + snippet['id'];
        })
        .attr('class', (snippet: SnippetMetadata) => {
          // Add each tag as a class for later highlighting etc
          let tags = '';
          if (snippet.codes) {
            tags += 'hasTag ';
            snippet.codes.forEach((code_id: number) => {
              const matchingCode = getMatchingParentCode(code_id, codes);
              if (matchingCode) {
                tags += `_${matchingCode.id}` + ' ';
              }
            });
          }
          return 'snippet ' + tags;
        })
        .attr(
          'x',
          (snippet: SnippetMetadata) =>
            conversation.conversation_starting_x +
            conversation.text_width +
            conversation.conversation_padding +
            scale(snippet['audio_start_offset'])
        )
        .attr(
          'y',
          (snippet: SnippetMetadata) =>
            conversation.conversation_starting_y +
            conversation.conversation_padding +
            (conversation.snippet_height + conversation.snippet_padding) *
              Number(
                speakerList.findIndex(
                  (speaker) => speaker.id === snippet.speaker_id
                )
              )
        )
        .attr('width', (snippet: SnippetMetadata) => scale(snippet.duration))
        .attr('height', conversation.snippet_height)
        .attr('fill', function () {
          return '#d0d4d4';
        })
        .on('mouseover', (e, d) => {
          if (isMobileScreen || !zoom.current) return;
          onSnippetMouseOver(e, d);
        })
        .on('mouseleave', (e, d) => {
          if (isMobileScreen || !zoom.current) return;
          onSnippetMouseLeave(e, d);
        })
        .on('click', (e, d) => {
          if (isMobileScreen || !zoom.current) return;
          onSnippetClick(e, d);
        });
    },
    [isMobileScreen, onSnippetClick, onSnippetMouseLeave, onSnippetMouseOver]
  );

  const drawSpeakers = React.useCallback(
    (
      gConversation: d3.Selection<SVGGElement, unknown, null, undefined>,
      conversation: ConversationVisualizationMetadata
    ) => {
      // Draw speakers hidden by default only showing once zoomed in
      // on that conversation
      const speakerList = conversation.snippets.reduce(
        (list, snippet) => {
          if (!list.find((speaker) => speaker.id == snippet.speaker_id)) {
            list.push({ id: snippet.speaker_id, name: snippet.speaker_name });
          }
          return list;
        },
        [] as { id: number; name: string }[]
      );
      gConversation
        .append('g')
        .attr('class', () => 'conv_id' + conversation.id + ' speaker_text')
        .style('opacity', 0)
        .selectAll('speakers')
        .data(speakerList)
        .enter()
        .append('text')
        .attr(
          'x',
          conversation.conversation_starting_x + conversation.text_width - 5
        )
        .attr(
          'y',
          (speaker, i) =>
            conversation.conversation_starting_y +
            conversation.conversation_padding +
            (conversation.snippet_height + conversation.snippet_padding) *
              Number(i)
        )
        .attr('dy', conversation.snippet_height / 2)
        .attr('alignment-baseline', 'middle')
        .attr('text-anchor', 'end')
        .style('font-size', '7px')
        .style('fill', '#F8F8F8')
        .text(({ name }) => {
          return name;
        })
        // Added to further readability
        .on('mouseover', (event, d) => {
          if (numberOfColumns === 1)
            onSnippetMouseOver(event, { id: d.id, highlight_words: d.name });
        })
        .on('mouseleave', (event, d) => {
          if (numberOfColumns === 1)
            onSnippetMouseLeave(event, { id: d.id, highlight_words: d.name });
        });
    },
    [numberOfColumns, onSnippetMouseLeave, onSnippetMouseOver]
  );

  const drawConversation = React.useCallback(
    (
      conversation: ConversationVisualizationMetadata,
      conversationsContainer: d3.Selection<
        SVGGElement,
        unknown,
        null,
        undefined
      >
    ) => {
      if (conversationsContainer) {
        const gConversation = conversationsContainer
          .append('g')
          .attr('class', 'conv_g_' + conversation.id);
        drawConversationZoomOverlay(gConversation, conversation);
        drawConversationRectangle(gConversation, conversation);
        drawSnippets(gConversation, conversation);
        drawSpeakers(gConversation, conversation);
      }
    },
    [
      drawConversationRectangle,
      drawConversationZoomOverlay,
      drawSnippets,
      drawSpeakers,
    ]
  );
  React.useEffect(() => {
    if (ref.current) {
      const svg = d3.select(ref.current);
      svg.selectAll('g').remove();
      const conversationsContainer = svg
        .append('g')
        .attr('transform', 'translate(0,0)')
        .attr('class', 'conversations_container');
      conversations.forEach((conversation) => {
        drawConversation(conversation, conversationsContainer);
      });
    }
  }, [clickToZoom, conversations, drawConversation, ref]);
};
