import { EditType } from 'src/types/edit';
import { InsightsPage } from 'src/types/pages/insights';

export const INSIGHTS: InsightsPage = {
  page_id: 'insights',
  section_order: ['header', 'insight_cards'],
  header: {
    visible: true,
    content: {
      title: 'Insights and Recommendations',
      body: 'This portal shares the insights and recommendations of  FMCS staff across tenures based on their personal experiences of organizational culture.',
      header_type: 'full',
      texture: true,
    },
  },
  insight_cards: {
    visible: true,
    content: {
      cards: [
        {
          title: 'Work-Life Balance',
          subtitle: 'Scheduling, Travel, Wellness, Family',
          body: 'In the course of adjusting to pandemic-era work, mediators discuss the complicated and sometimes surprising results of working from home.',
          link: {
            to: '/insights/work-life-balance',
            label: 'Explore Full Insights',
          },
          id: '18',
        },
        {
          title: 'Remote Working Experience',
          subtitle: 'Workflow, Impersonal, Learning Curve, Fatigue',
          body: 'Everyone, everywhere, all the time. The signature experience of remote work for mediators is the remarkable number of connections mediators can make almost simultaneously.',
          link: {
            to: '/insights/remote-working-experience',
            label: 'Explore Full Insights',
          },
          id: '27',
        },
        {
          title: 'Client Engagement',
          subtitle:
            'Client Satisfaction, Effective Service, Relationship Building, Meeting Spaces',
          body: 'Mediators adapt to the changing needs of their clients by offering flexibility, creating welcoming environments, relying on their tried and true skill sets.',
          link: {
            to: '/insights/client-engagement',
            label: 'Explore Full Insights',
          },
          id: '9',
        },
        {
          title: 'Culture',
          subtitle:
            'Espirit De Corps, Power Dynamics, Inclusion, Internal Politics or Structure',
          body: 'While mediators are clear-eyed about some of the institutional challenges facing their agency, the feeling of fellowship and sharing a sense of purpose permeates the culture of FMCS.',
          link: {
            to: '/insights/culture',
            label: 'Explore Full Insights',
          },
          id: '36',
        },
        {
          title: 'Relationships',
          subtitle: 'Teamwork, Disconnection, Connection',
          body: 'Mediators take the care and feeding of their relationships seriously. Their connections emerge in a variety of ways and include the cultivation of client connections, teaming with colleagues across departments, and mentoring new mediators into the professional fold.',
          link: {
            to: '/insights/relationships',
            label: 'Explore Full Insights',
          },
          id: '23',
        },
        {
          title: 'Training + Development',
          subtitle: 'Upskilling, Resistant to Change, Technology',
          body: 'Mediators express great interest in skills training and the continued cultivation of their practice. Participants share their thoughts on professional development.',
          link: {
            to: '/insights/training-development',
            label: 'Explore Full Insights',
          },
          id: '14',
        },
        {
          title: 'Intra-Organizational',
          subtitle: 'Engagement, Collaboration, Awareness',
          body: 'Mediators observe that if FMCS would seize the changing landscape in order to innovate, streamline, de-silo, and create stronger teams, then the result would be better services, and a stronger professional network.',
          link: {
            to: '/insights/intra-organizational',
            label: 'Explore Full Insights',
          },
          id: '32',
        },
        {
          title: 'Process',
          subtitle: 'Critique and Feedback, Approach-Strategy and Appreciation',
          body: 'Mediators share their thoughts, feelings, and insights on the experience of participating in these small group conversations.',
          link: {
            to: '/insights/work-life-balance',
            label: 'Explore Full Insights',
          },
          id: '42',
        },
      ],
      card_order: [],
    },
  },
  edit_metadata: {
    page_label: 'Insights',
    sections: [
      {
        section_label: 'Introduction',
        edit_blocks: [
          {
            block_label: 'Header',
            match: 'header.content',
            type: EditType.banner,
          },
        ],
      },
      {
        section_label: 'Insight Cards',
        sub_sections: [
          {
            section_label: 'work-life balance',
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'insight_cards.content.cards[0].title',
                type: EditType.string,
              },
              {
                block_label: 'Subtitle',
                match: 'insight_cards.content.cards[0].subtitle',
                type: EditType.complex_string,
              },
              {
                block_label: 'Paragraph',
                match: 'insight_cards.content.cards[0].body',
                type: EditType.complex_string,
              },
              {
                block_label: 'Image',
                match: 'insight_cards.content.cards[0].image',
                type: EditType.image,
              },
              {
                block_label: 'Link',
                match: 'insight_cards.content.cards[0].link',
                type: EditType.link,
              },
            ],
          },
          {
            section_label: 'remote working experience',
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'insight_cards.content.cards[1].title',
                type: EditType.string,
              },
              {
                block_label: 'Subtitle',
                match: 'insight_cards.content.cards[1].subtitle',
                type: EditType.complex_string,
              },
              {
                block_label: 'Paragraph',
                match: 'insight_cards.content.cards[1].body',
                type: EditType.complex_string,
              },
              {
                block_label: 'Image',
                match: 'insight_cards.content.cards[1].image',
                type: EditType.image,
              },
              {
                block_label: 'Link',
                match: 'insight_cards.content.cards[1].link',
                type: EditType.link,
              },
            ],
          },
          {
            section_label: 'client engagement',
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'insight_cards.content.cards[2].title',
                type: EditType.string,
              },
              {
                block_label: 'Subtitle',
                match: 'insight_cards.content.cards[2].subtitle',
                type: EditType.complex_string,
              },
              {
                block_label: 'Paragraph',
                match: 'insight_cards.content.cards[2].body',
                type: EditType.complex_string,
              },
              {
                block_label: 'Image',
                match: 'insight_cards.content.cards[2].image',
                type: EditType.image,
              },
              {
                block_label: 'Link',
                match: 'insight_cards.content.cards[2].link',
                type: EditType.link,
              },
            ],
          },
          {
            section_label: 'culture',
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'insight_cards.content.cards[3].title',
                type: EditType.string,
              },
              {
                block_label: 'Subtitle',
                match: 'insight_cards.content.cards[3].subtitle',
                type: EditType.complex_string,
              },
              {
                block_label: 'Paragraph',
                match: 'insight_cards.content.cards[3].body',
                type: EditType.complex_string,
              },
              {
                block_label: 'Image',
                match: 'insight_cards.content.cards[3].image',
                type: EditType.image,
              },
              {
                block_label: 'Link',
                match: 'insight_cards.content.cards[3].link',
                type: EditType.link,
              },
            ],
          },
          {
            section_label: 'relationships',
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'insight_cards.content.cards[4].title',
                type: EditType.string,
              },
              {
                block_label: 'Subtitle',
                match: 'insight_cards.content.cards[4].subtitle',
                type: EditType.complex_string,
              },
              {
                block_label: 'Paragraph',
                match: 'insight_cards.content.cards[4].body',
                type: EditType.complex_string,
              },
              {
                block_label: 'Image',
                match: 'insight_cards.content.cards[4].image',
                type: EditType.image,
              },
              {
                block_label: 'Link',
                match: 'insight_cards.content.cards[4].link',
                type: EditType.link,
              },
            ],
          },
          {
            section_label: 'training + development',
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'insight_cards.content.cards[5].title',
                type: EditType.string,
              },
              {
                block_label: 'Subtitle',
                match: 'insight_cards.content.cards[5].subtitle',
                type: EditType.complex_string,
              },
              {
                block_label: 'Paragraph',
                match: 'insight_cards.content.cards[5].body',
                type: EditType.complex_string,
              },
              {
                block_label: 'Image',
                match: 'insight_cards.content.cards[5].image',
                type: EditType.image,
              },
              {
                block_label: 'Link',
                match: 'insight_cards.content.cards[5].link',
                type: EditType.link,
              },
            ],
          },
          {
            section_label: 'intra-organizational',
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'insight_cards.content.cards[6].title',
                type: EditType.string,
              },
              {
                block_label: 'Subtitle',
                match: 'insight_cards.content.cards[6].subtitle',
                type: EditType.complex_string,
              },
              {
                block_label: 'Paragraph',
                match: 'insight_cards.content.cards[6].body',
                type: EditType.complex_string,
              },
              {
                block_label: 'Image',
                match: 'insight_cards.content.cards[6].image',
                type: EditType.image,
              },
              {
                block_label: 'Link',
                match: 'insight_cards.content.cards[6].link',
                type: EditType.link,
              },
            ],
          },
          {
            section_label: 'process',
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'insight_cards.content.cards[7].title',
                type: EditType.string,
              },
              {
                block_label: 'Subtitle',
                match: 'insight_cards.content.cards[7].subtitle',
                type: EditType.complex_string,
              },
              {
                block_label: 'Paragraph',
                match: 'insight_cards.content.cards[7].body',
                type: EditType.complex_string,
              },
              {
                block_label: 'Image',
                match: 'insight_cards.content.cards[7].image',
                type: EditType.image,
              },
              {
                block_label: 'Link',
                match: 'insight_cards.content.cards[7].link',
                type: EditType.link,
              },
            ],
          },
        ],
      },
    ],
  },
};
