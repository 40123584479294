import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import InfoCard from 'src/components/common/cards/InfoCard';
import appSelectors from 'src/redux/app/app-selectors';
import { InsightCard } from 'src/types/common';
import HorizontalBarChart from './HorizontalBars/HorizontalBarChart';
import InsightCircles from './InsightCircles';

interface InsightsShowcaseProps {
  overview: InsightCard;
  insights: InsightCard[];
}

function InsightsShowcaseContainer({
  overview,
  insights,
}: InsightsShowcaseProps) {
  const [selectedInsightId, setSelectedInsightId] = useState<string>();
  const codes = useSelector(appSelectors.getCodes);

  const handleSelection = (id: string | undefined) => {
    setSelectedInsightId(id);
  };

  const handleClose = () => {
    setSelectedInsightId(undefined);
  };
  // the .slice(2) here removes the :: prefix from the ID
  const insightInfo = selectedInsightId
    ? insights.find((insight) => insight.id === selectedInsightId.slice(2))
    : undefined;

  return (
    <Grid container sx={{ backgroundColor: 'white' }}>
      <Grid item xs={12} md={6} paddingTop={{ xs: 3, md: 0 }} paddingBottom={5}>
        {overview.graph_type === 'packed_circles' || !overview.graph_type ? (
          <InsightCircles
            codes={codes.analytic.filter(
              (code) => !code.display_name.includes('*')
            )}
            selectedInsightId={selectedInsightId}
            onSelection={handleSelection}
            onClose={handleClose}
            disableThroughClick={overview.disable_through_click}
          />
        ) : (
          <HorizontalBarChart
            codes={codes.analytic.filter(
              (code) => !code.display_name.includes('*')
            )}
            selectedCodeId={selectedInsightId}
            onChangeSelectedId={handleSelection}
            disableThroughClick={overview.disable_through_click}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <Box paddingTop={6} paddingBottom={8} paddingX={{ xs: 3, sm: 6 }}>
          {insightInfo ? (
            <InfoCard {...insightInfo} transparent />
          ) : (
            <InfoCard {...overview} transparent />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default InsightsShowcaseContainer;
