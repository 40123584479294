import React from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, Typography } from '@mui/material';
import * as d3 from 'd3';

import { Code } from 'src/types/common';
import { formatCodes } from '../utils';
import Bar from './Bar';
import { bakePack } from './utils';

interface Props {
  codes: Code[];
  selectedCodeId?: string;
  disableThroughClick?: boolean;
  onChangeSelectedId: (id?: string) => void;
}

const HorizontalBarChart = ({
  codes,
  selectedCodeId,
  disableThroughClick,
  onChangeSelectedId,
}: Props) => {
  const svgContainerRef = React.useRef<HTMLDivElement>(null);

  const cleanedData = bakePack(codes, selectedCodeId);
  const barPadding = 0.2;

  // left margin set to fit the longest topic keyword at the time of development (representative government)
  const margins = {
    top: 0,
    left: 250,
    right: 20,
    bottom: 10,
  };
  const [width, setWidth] = React.useState<number>(0);
  React.useLayoutEffect(() => {
    if (svgContainerRef.current) {
      setWidth(svgContainerRef.current?.getBoundingClientRect().width);
    }
  }, [svgContainerRef]);
  const height = Math.min(450, 50 * cleanedData.length);

  // store all of our dimensions in this obj
  const dimensions = {
    width,
    height,
    margins,
    // bounded dimensions are where actual chart elements go (no axes or labels, just bars)
    boundedWidth: width - margins.left - margins.right,
    boundedHeight: height,
  };

  const maxValue = d3.max(cleanedData, (d) => d.cumulative_count) || 0;
  const x = d3
    .scaleLinear()
    .domain([0, maxValue])
    .range([0, dimensions.boundedWidth]);

  const y = d3
    .scaleBand()
    .domain(cleanedData.map((d) => d.label))
    .rangeRound([0, dimensions.boundedHeight])
    .paddingInner(barPadding);

  const barHeight = y.bandwidth();

  const padding = 9;
  // the x position of the bar
  const barX = 0;
  // the x position of the label (i.e. Education)
  const labelX = -padding;
  // the y position of text we want to put next to the bars
  const barTextY = barHeight / 2;

  const navigate = useNavigate();
  const onBarClick = (id?: string) => {
    if (id === selectedCodeId) onChangeSelectedId(undefined);
    else {
      onChangeSelectedId(id);
    }

    if (selectedCodeId) {
      if (disableThroughClick) {
        onChangeSelectedId(undefined);
        return;
      }
      const strippedParentID = selectedCodeId.slice(2);
      const parentLabel = codes.find((code) => {
        return code.id === parseInt(strippedParentID);
      })?.label;

      const childLabel = cleanedData.find((item) => {
        return item.id === id;
      })?.label;

      const highlightExplorerURL = formatCodes(parentLabel, childLabel);
      navigate(highlightExplorerURL);
    }
  };

  const titleText = selectedCodeId
    ? `${codes.find((d) => selectedCodeId?.includes(`${d.id}`))?.label}`
    : 'Key Insights';

  const numMatchingHighlights = selectedCodeId
    ? cleanedData
        .map(({ cumulative_count }) => cumulative_count)
        .reduce((prev, curr) => prev + curr)
    : -1;

  return (
    <Grid sx={{ paddingTop: 2 }} data-testid="horizontal-bars">
      <Grid
        item
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" color="text.secondary" lineHeight={1.5}>
          {titleText}
        </Typography>
        {numMatchingHighlights !== -1 && (
          <Typography variant="caption" color="text.secondary" lineHeight={1.5}>
            {numMatchingHighlights} matching highlights
          </Typography>
        )}
        <Box
          ref={svgContainerRef}
          sx={{
            width: '90%',
            marginY: 2,
            height: 450,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {svgContainerRef?.current && (
            <svg className="d-block m-auto mb-3" width={width} height={height}>
              <g
                transform={`translate(${dimensions.margins.left} ${dimensions.margins.top})`}
              >
                {cleanedData.map((d, i) => (
                  <Bar
                    d={d}
                    y={y}
                    x={x}
                    padding={padding}
                    onBarClick={(id) => onBarClick(id)}
                    labelX={labelX}
                    barTextY={barTextY}
                    barX={barX}
                    barHeight={barHeight}
                    key={`bar-${i}`}
                  />
                ))}
              </g>
            </svg>
          )}
        </Box>
      </Grid>
      <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
        {selectedCodeId ? (
          <Button onClick={() => onBarClick(undefined)}>
            <Typography variant="caption">
              <CloseIcon
                sx={{
                  fontSize: '14px',
                  paddingRight: '0.5rem',
                  verticalAlign: 'middle',
                }}
              />
              Back to Insights
            </Typography>
          </Button>
        ) : (
          <Box>
            <Typography variant="body2" color="text.secondary" lineHeight={1.5}>
              The bar length reflects the number of highlights for a theme.
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              lineHeight={1.5}
              component="div"
            >
              Click on a bar to learn more.
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default HorizontalBarChart;
