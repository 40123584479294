import { EditType } from 'src/types/edit';
import { InsightSummaryPage } from 'src/types/pages/insights';

export const CULTURE: InsightSummaryPage = {
  page_id: 'insights-36',
  section_order: ['header', 'summary'],
  header: {
    visible: true,
    content: {
      title: 'Culture',
      body: 'While mediators are clear-eyed about some of the institutional challenges facing their agency, the feeling of fellowship and sharing a sense of purpose permeates the culture of FMCS.',
      header_type: 'full',
    },
  },
  summary: {
    visible: true,
    content: {
      highlight_link_style: {
        default_color: '#11304B',
        active_color: '#48bf53',
      },
      paragraphs: [
        {
          label: 'Overview & Recommendations',
          text: '<p>The <Embed getEmbedInfo={getEmbedInfo}>traditional FMCS culture-5249127</Embed> of mediators as solitary service providers and districts operating in protected silos was revealed to be unresponsive and ineffective in the evolving world of conflict management. The <Embed getEmbedInfo={getEmbedInfo}>incongruity-5250457</Embed> of preaching collaboration, respect, and communication to the clients while not practicing these things within the agency became very apparent. The separation, territorialism and inconsistencies in priorities and message from district to district led to many people feeling marginalized, isolated, and disillusioned. However, the season of the pandemic brought opportunities for mediators to team up with colleagues from across the nation and to practice their craft without borders.<br/><br/>Recommendations from conversations include:<br/><ul><li>FMCS leadership addresses the staff experience of a disconnection between the agency championing transparency, collaboration and open communication to the clients, while failing to consistently practice these principles internally</li><li>Consistency in <Embed getEmbedInfo={getEmbedInfo}>training and application-5249070</Embed> of objective performance guidelines and performance assessments.</li><li>Agency leadership advocates and encourages the <Embed getEmbedInfo={getEmbedInfo}>continuation and growth of FMCS-5250895</Embed> service providers teaming up across the nation on special projects and to meet and exceed the needs of the clients in day to day casework</li><li>Break down geographic silos and protectionism to create and maintain a culture based on professional collaboration and <Embed getEmbedInfo={getEmbedInfo}>care for each other-5249084</Embed>.</li><li>Solicit listen, consider, and respect feedback from all levels of the organization and to promote<Embed getEmbedInfo={getEmbedInfo}> inclusivity and diversity-5250460</Embed> of mediators in the future.</li></ul></p>',
        },
        {
          label: 'Espirit De Corps',
          text: "<p>Esprit De Corps refers to the fellowship and loyalty common in the professional culture of FMCS. Conversation participants comment on the positive feedback and much needed support they’ve received with participants singling out their mediator tech teams for praise. <Embed getEmbedInfo={getEmbedInfo}>Speaker WA-5249061</Embed>, “I got to work and engage with people that are my peers and do that. So that's exciting. I got to learn some new systems.” <Embed getEmbedInfo={getEmbedInfo}>Speaker MC-5248955</Embed> adds that “saying yes to everything,” and working cross-regionally was especially rewarding: “And I connected with so many people across the country, just meeting techs, doing the meeting tech work, and then actually turning that into working on RDTs together and working across regions.” <br/><br/>Feeling grounded in the culture of FMCS is particularly important to newer mediators as they navigate their new professional terrain, for <Embed getEmbedInfo={getEmbedInfo}>Speaker KC-5249050</Embed>, that cultural connection comes from hearing from seasoned mediators about their experiences through story form: “I don't know any mediator that doesn't like talking about stories and that's the way you learn things. And when I was going through new mediator training, it was always helpful to have someone that had been doing it.” <br/><br/>Participants express loyalty and solidarity with FMCS but also point out that there has been attrition in recent years such that <Embed getEmbedInfo={getEmbedInfo}>Speaker LC-5250469</Embed> expresses how, “...it's been pretty heartbreaking to see the number of people that left.” Ultimately, this mediator expresses the hope that for FMCS, “... we get back to that place where people want to stay here for the duration of their careers, because we do such wonderful things and interesting things and different things.”</p>",
        },
        {
          label: 'Power Dynamics',
          text: "<p>In our conversations, participants pointed out the interplay among the institutional structures at FMCS, workers in the agency, and the requirements of various agency stakeholders, and government organizations. <Embed getEmbedInfo={getEmbedInfo}>Speaker MC-5249028</Embed> describes the complexity of balancing the pillars of their work is made more difficult with virtual negotiations: “I think that regulatory negotiations and large public policy multi-party negotiations, it seems as though we're looking at more and more of that in this agency…Especially right now, there's been a large influx of reg negs and multi-party policy negotiation.”<br/><br/>Mediator experiences also span a range where most mediators found their experiences to be even-handed and fair, but one participant, <Embed getEmbedInfo={getEmbedInfo}>Speaker DA-5250626</Embed> notes an uncomfortable experience during their transition to working at headquarters: “A great challenge that I had was when I went from working at headquarters, this was around 2005 to the field office, I wanted to become a real mediator… And somebody down in Washington when they heard I was doing this, they said, ‘You're going to have to lick the boots of the other mediators up there when you go there.’ It's like okay, not the nicest thing to say but it resonated with me.”</p>",
        },
        {
          label: 'Inclusion',
          text: "<p>Regardless of existing institutional challenges or the ongoing pressures from changing social climates, participants like <Embed getEmbedInfo={getEmbedInfo}>Speaker KA-5250371</Embed> expressed a desire that FMCS will be open to the feedback of their diverse constituency of stakeholders: “Looking at it in the future, I would hope that the structure of the politics of the agency is one of inclusion, is one of embracing the information, feedback and concepts from the people that work for the organization, in an ever evolving process to make things better.” Some, like <Embed getEmbedInfo={getEmbedInfo}>Speaker BB-5249015</Embed> see this movement for inclusion as already occurring, “I definitely see a lot more inclusion in listening to voices. So I think we're on that track actually.” However, they do caution that there may be “...a disconnect between the field and headquarters and sometimes that gets on a roll and creates a divide.” <br/><br/>Ultimately, the sentiment among mediators orbits around the singular and cherished goal of providing outstanding client services by pooling their talents and soliciting different perspectives. In the words of <Embed getEmbedInfo={getEmbedInfo}>Speaker MC-5250477</Embed>, “...we all want FMCS to serve more and more effectively… to allow this agency to be of greater service to the public, we need to use all of the experience and insights from all of our employees to get to that point.”</p>",
        },
        {
          label: 'Internal Politics or Structure',
          text: "<p>Navigating internal politics and agency structures can be a delicate process for mediators. Speakers tell wide-ranging stories about their experiences such as when <Embed getEmbedInfo={getEmbedInfo}>Speaker DA-5250627</Embed> navigated their transition to a different department, or their experience of changing practice in agency performance appraisals, which is what <Embed getEmbedInfo={getEmbedInfo}>Speaker SB-5249076</Embed> calls out in their shared story. <br/><br/>Participants also pointed out that while FMCS has evolved, they emphasize their own responsibilities in crafting their individual experiences during a time of change. <Embed getEmbedInfo={getEmbedInfo}>Speaker WA-5249091</Embed> reflects on how his attitude of positivity can sometimes be “passive” and he intends to be more deliberate and outspoken about this in service of putting: “In other words, not saying negative things, but to be intentional and say more positive things… How we work has changed, but I don't think that was necessitated by the leadership of our agency. I think it was necessitated by the circumstances that we were dealt.” <br/><br/>One participant, <Embed getEmbedInfo={getEmbedInfo}>Speaker BD-5249127</Embed> conveyed the extent to which mediators coordinate their movement between the sometimes rigid structure of institutions and their own role as professionals who rely on their own instincts and judgments. “I remember, one of the most important things someone told me when I started with FMCS was, ‘Don't let yourself get federalized.’ What they meant by that is, don't fall into the rut…I think it's a good thing. I really do. And I hope to see more of it. I hope this positions us to try more things more frequently.”</p>",
        },
      ],
    },
  },
  edit_metadata: {
    page_label: 'Culture',
    page_id: 'insights-36',
    subroute: true,
    sections: [
      {
        section_label: 'Introduction',
        edit_blocks: [
          {
            block_label: 'Header',
            match: 'header.content',
            type: EditType.banner,
          },
        ],
      },
      {
        section_label: 'Highlight Colors',
        edit_blocks: [
          {
            block_label: 'Color',
            match: 'summary.content.highlight_link_style.default_color',
            type: EditType.color,
          },
          {
            block_label: 'Active Color',
            match: 'summary.content.highlight_link_style.active_color',
            type: EditType.color,
          },
        ],
      },
      {
        section_label: 'Paragraphs',
        update_section: {
          match: 'summary.content.paragraphs',
          label: 'Add Paragraph',
          empty: {
            label: 'Default Label',
            text: 'Default Text',
          },
          newSectionMetadata: {
            section_label: 'Insight',
            numbered: true,
            edit_blocks: [
              {
                block_label: 'Title',
                match: `summary.content.paragraphs[index].label`,
                type: EditType.string,
              },
              {
                block_label: 'Paragraph',
                match: `summary.content.paragraphs[index].text`,
                type: EditType.embed_string,
              },
            ],
          },
        },
        sub_sections: [
          {
            section_label: 'Overview',
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'summary.content.paragraphs[0].label',
                type: EditType.string,
              },
              {
                block_label: 'Paragraph',
                match: 'summary.content.paragraphs[0].text',
                type: EditType.embed_string,
              },
            ],
          },
          {
            section_label: 'Insight',
            numbered: true,
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'summary.content.paragraphs[1].label',
                type: EditType.string,
              },
              {
                block_label: 'Paragraph',
                match: 'summary.content.paragraphs[1].text',
                type: EditType.embed_string,
              },
            ],
          },
          {
            section_label: 'Insight',
            numbered: true,
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'summary.content.paragraphs[2].label',
                type: EditType.string,
              },
              {
                block_label: 'Paragraph',
                match: 'summary.content.paragraphs[2].text',
                type: EditType.embed_string,
              },
            ],
          },
          {
            section_label: 'Insight',
            numbered: true,
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'summary.content.paragraphs[3].label',
                type: EditType.string,
              },
              {
                block_label: 'Paragraph',
                match: 'summary.content.paragraphs[3].text',
                type: EditType.embed_string,
              },
            ],
          },
          {
            section_label: 'Insight',
            numbered: true,
            edit_blocks: [
              {
                block_label: 'Title',
                match: 'summary.content.paragraphs[4].label',
                type: EditType.string,
              },
              {
                block_label: 'Paragraph',
                match: 'summary.content.paragraphs[4].text',
                type: EditType.embed_string,
              },
            ],
          },
        ],
      },
    ],
  },
};
