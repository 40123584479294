import React, { ChangeEvent, useState } from 'react';
import { Badge } from '@mui/material';
import { v4 } from 'uuid';

const ImageEditor = React.forwardRef(
  (
    {
      initialValue,
      onChange,
    }: { initialValue: string; onChange?: (value: string) => void },
    ref
  ) => {
    const uniqueId = React.useMemo(() => `image-upload-${v4()}`, []);
    const [url, setUrl] = useState(initialValue);
    const handleChange = (value: string) => {
      onChange && onChange(value);
      if (ref) {
        (ref as React.MutableRefObject<string>).current = value;
      }
    };

    const handleUploadClick = (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        const fr = new FileReader();
        fr.onload = function (e) {
          const url = e.target?.result as string;
          setUrl(url);
          handleChange(url);
        };
        fr.readAsDataURL(event.target.files[0]);
      }
    };

    const handleDeleteClick = (event: React.MouseEvent) => {
      if ((event.target as HTMLElement).nodeName === 'SPAN') {
        setUrl('');
        handleChange('');
      }
    };

    return (
      <div
        id="image-container"
        style={{
          borderRadius: '4px',
          border: '1px solid',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          width: '100%',
          margin: '1rem 0',
          height: '115px',
          display: 'flex',
        }}
        data-testid="image-drop"
      >
        {url === '' || !url ? (
          <span
            className="full-height-width clickable-icon"
            style={{
              fontSize: '1.5vw',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <label
              htmlFor={uniqueId}
              className="full-height-width"
              style={{ position: 'absolute', cursor: 'pointer' }}
            ></label>
            <div
              className="flex-column"
              style={{ justifyContent: 'center', height: '100%' }}
            >
              <i
                className="fa fa-solid fa-upload"
                style={{ alignSelf: 'center', fontSize: '2vw' }}
              ></i>
              <p style={{ margin: '0.25rem 0', fontSize: '1vw' }}>
                Upload image
              </p>
            </div>
            <input
              id={uniqueId}
              hidden
              accept="image/*"
              type="file"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleUploadClick(event)
              }
            />
          </span>
        ) : (
          <div style={{ display: 'flex', width: '100%' }}>
            <Badge
              badgeContent="x"
              color="error"
              data-testid="delete-image-button"
              style={{ padding: '0.5em' }}
              onClick={handleDeleteClick}
            >
              <img src={url} style={{ height: '100%' }}></img>
            </Badge>
          </div>
        )}
      </div>
    );
  }
);

export default ImageEditor;
