import React, { ChangeEvent, useState } from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import { cloneDeep, set } from 'lodash';

import { OptionalLink } from 'src/types/common';
import LinkEditor from './LinkEditor';

const OptionalLinkEditor = React.forwardRef(
  (
    {
      initialValue,
      potentialPaths,
      label,
    }: {
      initialValue: OptionalLink;
      potentialPaths: string[];
      label: string;
    },
    ref
  ) => {
    const [optionalLink, setOptionalLink] = useState(initialValue);
    const handleChange = (value: boolean | string, match: string) => {
      if (match === 'to' && !value) {
        // value is null
        value = '/';
      }
      const newOptionalLink: OptionalLink = cloneDeep(optionalLink);
      set(newOptionalLink, match, value);
      if (ref) {
        (ref as React.MutableRefObject<OptionalLink>).current = newOptionalLink;
      }
      setOptionalLink(newOptionalLink);
    };

    return (
      <>
        <Typography variant="h6" sx={{ marginTop: '1rem' }}>
          {label}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={optionalLink.visible}
            data-testid="optional-link-visibility-checkbox"
            onChange={(
              event: ChangeEvent<HTMLInputElement>,
              checked: boolean
            ) => {
              handleChange(checked as boolean, 'visible');
            }}
          ></Checkbox>
          <Typography variant="subtitle1">Visible?</Typography>
        </Box>
        {optionalLink.visible && (
          <LinkEditor
            link={optionalLink}
            potentialPaths={potentialPaths}
            optionalChangeHandler={handleChange}
          />
        )}
      </>
    );
  }
);

export default OptionalLinkEditor;
