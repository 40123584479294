import { CodeMetadata } from 'src/types/common';

export const DEMOGRAPHIC_CODES: CodeMetadata[] = [
  {
    id: 0,
    display_name: 'fmcs tenure',
    subcodes: [
      {
        id: 1,
        display_name: '0-5 years',
      },
      {
        id: 2,
        display_name: '5-10 years',
      },
      {
        id: 3,
        display_name: '10-15 years',
      },
      {
        id: 4,
        display_name: '15+ years',
      },
    ],
  },
  {
    id: 5,
    display_name: 'region',
    subcodes: [
      {
        id: 6,
        display_name: 'JA Africa',
      },
      {
        id: 7,
        display_name: 'JA Americas',
      },
      {
        id: 8,
        display_name: 'JA Asia Pacific',
      },
      {
        id: 9,
        display_name: 'JA Europe',
      },
      {
        id: 10,
        display_name: 'INJAZ Al-Arab JA MENA',
      },
      {
        id: 11,
        display_name: 'JA USA',
      },
    ],
  },
];
