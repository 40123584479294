import { FilterGroup } from 'src/types/filters';
import { CustomRoute } from 'src/types/routes';
import { slugify } from 'src/utils/utils';

export const generateAllPotentialPaths = (
  routes: CustomRoute[],
  filters?: FilterGroup[]
): string[] => {
  let allPaths: string[] = [];
  routes.map((route) => {
    if (route.visible) {
      allPaths.push(route.path);
      if (route.path === '/highlight-explorer') {
        const possibleSearches = generateFilterSearches(filters);
        possibleSearches.forEach((search) => {
          allPaths.push(`${route.path}?${search}`);
        });
      }
      if (route.subroutes) {
        const subpaths = generateAllPotentialPaths(route.subroutes, filters);
        allPaths = allPaths.concat(
          subpaths.map((subpath) => `${route.path}${subpath}`)
        );
      }
    }
  });
  return allPaths;
};

export const generateFilterSearches = (filters?: FilterGroup[]): string[] => {
  if (!filters) {
    return [];
  }
  const searches = filters.flatMap((group) => {
    const results = combinate(
      group.options.map(({ label }) => label),
      ','
    );
    return results.map(
      (value) => `${slugify(group.label.toLowerCase())}=${slugify(value)}`
    );
  });
  // const final = combinate(searches, '&').sort((a, b) => a.length - b.length);
  return searches;
};

const combinate = (array: string[], join: string) => {
  const combinations: string[] = [];
  array.forEach((value, ind) => {
    let process = array.length;
    while (process > ind) {
      combinations.push(`${array.slice(ind, process).join(join)}`);
      process--;
    }
  });
  return combinations;
};

export const getMatchingRoute = (
  routes: CustomRoute[],
  options?: { page_id?: string; path?: string }
) => {
  if (!options) {
    return;
  }
  const { page_id, path } = options;
  const flattenedRoutes = routes.reduce((final, route) => {
    let subroutes: CustomRoute[] = [];
    if (route.subroutes) {
      subroutes = route.subroutes.map((subroute) => ({
        ...subroute,
        path: route.path + subroute.path,
      }));
    }
    final = final.concat(subroutes).concat([route]);
    return final;
  }, [] as CustomRoute[]);
  if (page_id) {
    return flattenedRoutes.find((route) => route.page_data_id === page_id);
  }
  if (path) {
    return flattenedRoutes.find((route) => route.path === path);
  }
  return;
};
