import React from 'react';
import Grid from '@mui/material/Grid';

function Avatar({ src }: { src: string }) {
  return (
    <Grid
      item
      sx={{
        position: 'relative',
        width: '100%',
        paddingBottom: '100%',
        borderRadius: '50%',
        overflow: 'hidden',
        background: '#00763D',
      }}
    >
      {src && (
        <img
          alt=""
          src={src}
          style={{
            position: 'absolute',
            objectFit: 'cover',
            width: '100%',
            height: '100%',
          }}
        />
      )}
    </Grid>
  );
}

export default Avatar;
