import { cloneDeep } from 'lodash';

import { Code } from 'src/types/common';
import { slugify } from 'src/utils/utils';

export interface D3CodeObject {
  id: string;
  count: number;
  label: string;
  background_color?: string;
  text_color?: string;
  children: D3CodeObject[];
  cumulative_count: number;
  isTerminal?: boolean;
}

export const bakePack = (codes: Code[], selectedCodeId?: string) => {
  let codeData: D3CodeObject[] = codes.map((curr) => ({
    id: `::${curr.id}`,
    count: curr.count,
    label: curr.label,
    background_color: curr.showcase_colors.background_color,
    text_color: curr.showcase_colors.text_color,
    cumulative_count:
      curr.subcodes?.map(({ count }) => count).reduce((a, b) => a + b, 0) ?? 0,
    children:
      curr.subcodes?.map((sub) => ({
        id: `::${curr.id}::${slugify(sub.label)}`,
        count: sub.count,
        cumulative_count: sub.count,
        label: sub.label,
        isTerminal: true,
        background_color: sub.showcase_colors.background_color ?? '#f4f4f4',
        text_color: sub.showcase_colors.text_color,
        children: [],
      })) ?? [],
  }));

  codeData = GTLSortCodes(codeData);
  if (selectedCodeId) {
    return (
      codeData.find((code) => selectedCodeId === `${code.id}`)?.children ?? []
    );
  }
  return codeData;
};

export const normalSortCodes = (codes: D3CodeObject[]) => {
  const newCodes = cloneDeep(codes).sort(
    (a, b) => (b.cumulative_count ?? 0) - (a.cumulative_count ?? 0)
  );
  return Array.from(newCodes.keys())
    .sort((a, b) => (b % 2) - (a % 2) || (a % 2 ? b - a : a - b))
    .map((i) => {
      newCodes[i].children = newCodes[i].children
        ? normalSortCodes(newCodes[i].children ?? [])
        : [];
      return newCodes[i];
    });
};

export const GTLSortCodes = (codes: D3CodeObject[]) => {
  return cloneDeep(codes)
    .sort((a, b) => (b.cumulative_count ?? 0) - (a.cumulative_count ?? 0))
    .map((code) => {
      code.children = code.children.sort(
        (a, b) => (b.cumulative_count ?? 0) - (a.cumulative_count ?? 0)
      );
      return code;
    });
};

export const LTGSortCodes = (codes: D3CodeObject[]) => {
  return cloneDeep(codes)
    .sort((a, b) => (a.cumulative_count ?? 0) - (b.cumulative_count ?? 0))
    .map((code) => {
      code.children = code.children.sort(
        (a, b) => (a.cumulative_count ?? 0) - (b.cumulative_count ?? 0)
      );
      return code;
    });
};
