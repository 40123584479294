import React, { useEffect, useLayoutEffect, useState } from 'react';
import JsxParser from 'react-jsx-parser';
import { useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { Tune } from '@mui/icons-material';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import ShuffleOnIcon from '@mui/icons-material/ShuffleOn';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { Container, useMediaQuery } from '@mui/material';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

import HighlightContainer from 'src/components/Audio/Highlight/Highlight';
import InfoModalButton from 'src/components/common/buttons/InfoModalButton';
import FilterModal from 'src/components/common/filters/FilterModal';
import { useFilters } from 'src/pages/Voices/HighlightExplorer/useFilters';
import { useHighlights } from 'src/pages/Voices/HighlightExplorer/useHighlights';
import appSelectors from 'src/redux/app/app-selectors';
import { StoreState } from 'src/redux/store';
import { Highlight } from 'src/types/common';
import { FilterOption } from 'src/types/filters';
import { HighlightsPage } from 'src/types/pages/voices';
import { getQueryArg, slugify } from 'src/utils/utils';

function EmbedableHighlightExplorer() {
  const isExtraSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  const logoUrl = React.useMemo(() => getQueryArg('logo'), []);
  const pageInfo = useSelector((state: StoreState) =>
    appSelectors.getPage(state, 'highlight-explorer')
  ) as HighlightsPage;
  const organization_metadata = useSelector(
    appSelectors.getOrganizationMetadata
  );
  const [audioIcon, setAudioIcon] = useState('play');
  const { filters, activeFilters, setActiveFilters } = useFilters();
  const [shuffledHighlights, setShuffledHighlights] = useState<boolean>(false);
  const { filteredHighlights } = useHighlights(
    activeFilters,
    shuffledHighlights
  );
  const [selectedHighlight, setSelectedHighlight] = useState<number>(0);
  const handleRemoveFilter = (option: FilterOption) => {
    setActiveFilters(activeFilters.filter((x) => x !== option));
  };

  // for lazy loading
  const [displayIndex, setDisplayIndex] = useState<number>(5);
  const [displayHighlights, setDisplayHighlights] = useState<Highlight[]>([]);

  const autoPlay = (hid: number) => {
    if (!displayHighlights.map((h) => h.id).includes(hid)) {
      // All highlights have been played
      return;
    }
    const highlightContainer = document.getElementById(`play-${hid}`);
    highlightContainer?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });

    if (highlightContainer) {
      const playButton = highlightContainer.querySelector(
        '[data-testid="play-button"]'
      );
      if (playButton) {
        (playButton as HTMLButtonElement).click();
      }
    }
    setSelectedHighlight(hid);
  };

  const handleWaypointEnter = (index: number) => {
    setDisplayIndex(
      Math.min(Math.max(displayIndex, index + 2), filteredHighlights.length)
    );
  };

  useEffect(() => {
    setDisplayHighlights(filteredHighlights.slice(0, displayIndex));
    setSelectedHighlight(filteredHighlights[0].id);
  }, [filteredHighlights, displayIndex]);

  useLayoutEffect(() => {
    const root = document.getElementById('root');
    const html = document.documentElement;
    if (root) {
      root.style.display = 'flex';
      root.style.justifyContent = 'center';
      root.style.alignItems = 'center';
    }
    if (html) {
      html.style.height = 'auto';
    }
  }, []);

  return (
    <div
      style={{
        width: isExtraSmallScreen ? '100vw' : '75vw',
        boxShadow: '0 0 11px rgba(33,33,33,.2)',
        borderRadius: '10px',
      }}
    >
      <Container
        sx={{
          paddingLeft: { xs: 4, md: 6 },
          paddingRight: { xs: 4, md: 3 },
          paddingY: 2,
        }}
      >
        <div
          style={{
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginBottom: '5px',
          }}
        >
          {logoUrl && (
            <img
              alt={
                organization_metadata?.logo ? organization_metadata?.name : ''
              }
              src={logoUrl}
              style={{
                height: '50px',
                maxWidth: '300px',
                objectFit: 'scale-down',
                marginRight: '5px',
              }}
            />
          )}
          <span style={{ fontSize: '26px' }}>
            {/* @ts-ignore - ignore */}
            <JsxParser jsx={pageInfo.header.content.title} />
          </span>
        </div>
        <div style={{ width: '100%', marginTop: '0.5rem', display: 'flex' }}>
          <InfoModalButton
            iconLabel="Filters"
            icon={<Tune sx={{ fontSize: '20px' }} />}
            closeButtonLabel="Close"
            color={'black'}
          >
            {filters ? (
              <FilterModal
                filters={filters}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                small={isExtraSmallScreen}
                filter_order={pageInfo.filters.content.filter_order}
              />
            ) : (
              <></>
            )}
          </InfoModalButton>
        </div>
        <div style={{ marginTop: '0.5rem', width: '100%' }}>
          {activeFilters?.map((item) => (
            <Chip
              label={item.label}
              data-testid={`${slugify(item.label)}-filter-chip`}
              onDelete={() => handleRemoveFilter(item)}
              sx={{
                marginRight: 1,
                marginBottom: 1,
              }}
            />
          ))}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '1rem',
          }}
        >
          <div>
            {shuffledHighlights ? (
              <ShuffleOnIcon
                fontSize="large"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setShuffledHighlights(false);
                }}
              />
            ) : (
              <ShuffleIcon
                fontSize="large"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setShuffledHighlights(true);
                }}
              />
            )}
          </div>
          <div>
            {audioIcon === 'play' ? (
              <PlayCircleOutlineIcon
                onClick={() => autoPlay(selectedHighlight)}
                style={{
                  cursor: 'pointer',
                  fontSize: '50px',
                  margin: '0 0.5rem',
                }}
              />
            ) : (
              <PauseCircleOutlineIcon
                fontSize="large"
                onClick={() => autoPlay(selectedHighlight)}
                style={{
                  cursor: 'pointer',
                  fontSize: '50px',
                  margin: '0 0.5rem',
                }}
              />
            )}
          </div>
          <div>
            <SkipNextIcon
              onClick={() =>
                autoPlay(
                  displayHighlights[
                    displayHighlights.findIndex(
                      (h) => selectedHighlight === h.id
                    ) + 1
                  ].id
                )
              }
              fontSize="large"
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
        <div
          style={{
            width: '100%',
            textAlign: isExtraSmallScreen ? 'left' : 'right',
          }}
        >
          <span>{filteredHighlights.length} highlights</span>
        </div>
      </Container>
      <Grid
        id="highlight-scroll"
        style={{
          background: '#f3f3f3',
          height: '100%',
          maxHeight: '700px',
          fontSize: '14px',
          overflow: 'scroll',
        }}
      >
        <Grid
          container
          paddingX={{ xs: 4, md: 5 }}
          paddingTop={3}
          sx={{ width: '100%' }}
        >
          {displayHighlights.map((highlight, highlightIndex) => (
            <Waypoint
              key={`highlight-scroll-waypoint-${highlight.id}`}
              onEnter={() => handleWaypointEnter(highlightIndex)}
              topOffset={'33%'}
              bottomOffset={'10%'}
            >
              <Grid
                item
                key={highlight.id}
                xs={12}
                md={6}
                paddingX={1}
                paddingBottom={8}
              >
                <HighlightContainer
                  sectionId={'full'}
                  highlight={highlight}
                  onSelect={setSelectedHighlight}
                  selectedHighlightId={selectedHighlight}
                  triggerNext={() => {
                    console.log('next');
                    autoPlay(displayHighlights[highlightIndex + 1].id);
                  }}
                  setAudioIcon={setAudioIcon}
                  scrolling
                />
              </Grid>
            </Waypoint>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}

export default EmbedableHighlightExplorer;
