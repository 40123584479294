import React from 'react';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { Button, Collapse, Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';

import { FilterOption } from 'src/types/filters';

interface MultipleCheckFilterProps {
  options: FilterOption[];
  defaultName?: string;
  selectedItems: FilterOption[];
  setSelectedItems: React.Dispatch<React.SetStateAction<FilterOption[]>>;
  singleColumn?: boolean;
  collapse?: boolean;
}

function MultipleCheckFilter({
  options,
  defaultName,
  selectedItems,
  setSelectedItems,
  singleColumn,
  collapse,
}: MultipleCheckFilterProps) {
  const [open, setOpen] = React.useState<boolean>(false);
  const handleChange = (option: FilterOption) => {
    if (selectedItems.includes(option)) {
      setSelectedItems((prev) => prev.filter((item) => item !== option));
    } else {
      setSelectedItems((prev) => [...prev, option]);
    }
  };

  return (
    <Grid>
      {collapse ? (
        <Button
          onClick={() => setOpen((curr) => !curr)}
          fullWidth
          sx={{ paddingLeft: 0 }}
          data-testid={`${defaultName}-collapse-button`}
        >
          <Grid
            display={'flex'}
            sx={{ justifyContent: 'space-between', width: '100%' }}
          >
            <Typography
              variant="body1"
              paddingBottom=".5rem"
              data-testid={`${defaultName}-filters-header`}
            >
              {defaultName && <strong>{defaultName}</strong>}
            </Typography>
            {open ? <ExpandMore /> : <ChevronRight />}
          </Grid>
        </Button>
      ) : (
        <></>
      )}
      <Collapse in={collapse ? open : true}>
        {!collapse && (
          <Typography
            variant="body1"
            paddingBottom=".5rem"
            data-testid={`${defaultName}-filters-header`}
          >
            {defaultName && <strong>{defaultName}</strong>}
          </Typography>
        )}
        <FormGroup data-testid={`${defaultName}-filters-form-group`}>
          <ul
            className={singleColumn ? 'list-single-column' : 'list-two-column'}
            style={{ width: '100%' }}
          >
            {options.map((option) => {
              return (
                <li>
                  <FormControlLabel
                    key={option.key}
                    control={
                      <Checkbox
                        size="small"
                        checked={selectedItems.includes(option)}
                        sx={{ paddingY: 0.25, alignItems: 'flex-start' }}
                      />
                    }
                    label={
                      <Typography variant="body2" sx={{ whiteSpace: 'normal' }}>
                        {option.label}
                      </Typography>
                    }
                    onChange={() => handleChange(option)}
                  />
                </li>
              );
            })}
          </ul>
        </FormGroup>
      </Collapse>
    </Grid>
  );
}

export default MultipleCheckFilter;
