import { BootstrapData } from 'src/types/boostrap';

export const draftSite = (site: BootstrapData) => {
  const requestOptions = {
    method: 'PUT',
    Accept: 'application/json',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ bootstrapData: site }),
  };
  return fetch('/save_draft', requestOptions)
    .then((response) => response.json())
    .then(() => location.reload());
};

export const publishSite = (site: BootstrapData) => {
  const requestOptions = {
    method: 'PUT',
    Accept: 'application/json',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ bootstrapData: site }),
  };
  return fetch('/publish', requestOptions)
    .then((response) => response.json())
    .then(() => location.reload());
};

export const updateInsights = (site: BootstrapData) => {
  const requestOptions = {
    method: 'PUT',
    Accept: 'application/json',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ bootstrapData: site }),
  };
  return fetch('/update_data', requestOptions)
    .then((response) => response.json())
    .then(() => location.reload());
};
