import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import {
  Backdrop,
  CircularProgress,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Box } from '@mui/system';

import EditingDrawer from 'src/components/Editing/EditingDrawer';
import MainNav from 'src/components/Navigation/MainNav';
import appSelectors from 'src/redux/app/app-selectors';
import { useEditing } from 'src/utils/useEditing';

const AuthOverlay = () => {
  const organization_metadata = useSelector(
    appSelectors.getOrganizationMetadata
  );
  const requestSent = useSelector(appSelectors.getRequestStatus);
  const saveMessage = useSelector(appSelectors.getSaveMessage);
  const { canEdit } = useEditing();
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const AppBody = (
    <Box minHeight="100vh" sx={{ width: '100%' }}>
      <MainNav />
      <Outlet />
      <footer>
        <Box
          sx={{
            backgroundColor: 'white',
            border: '2px solid #F4F4F4',
            color: 'primary.dark',
            width: '100%',
            height: '5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <a
            href={organization_metadata?.homepage}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            <img
              alt={organization_metadata?.name}
              src={organization_metadata?.logo}
              height={mobile ? '50px' : '60px'}
              style={{ maxWidth: '100%', objectFit: 'scale-down' }}
            />
          </a>
        </Box>
      </footer>
    </Box>
  );
  return (
    <Box display="flex">
      {canEdit && <EditingDrawer />}
      {AppBody}
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          width: '75%',
          left: '25%',
        }}
        open={requestSent}
        transitionDuration={{ exit: 3000 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{ fontWeight: 'bolder' }}
          >
            {saveMessage}
          </Typography>
          <div style={{ height: '40px' }}>
            {requestSent && <CircularProgress color="inherit" />}
          </div>
        </Box>
      </Backdrop>
    </Box>
  );
};

export default AuthOverlay;
