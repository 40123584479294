export interface FilterOption {
  key: string;
  type: 'single' | 'multi';
  match: number | number[];
  label: string;
}

export interface FilterGroup {
  label: string;
  id: number;
  options: FilterOption[];
  type: FilterType;
}

export const enum FilterType {
  thematic = 'thematic',
  structural = 'structural',
  demographic = 'demographic',
}

export interface FilterQuery {
  label: string;
  query?: string;
}

export interface FilterOrder {
  tabs: FilterType[];
  demographic: { name: string; order: string[] };
  thematic: { name: string; order: string[] };
}
