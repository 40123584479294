import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {
  bindHover,
  bindMenu,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import HoverMenu from 'material-ui-popup-state/HoverMenu';

import { useGeneratePath } from 'src/utils/useGeneratePath';

interface SingleFadeMenuProps {
  label: string;
  links: any[];
  defaultLink: string;
}

export default function SingleFadeMenu({
  label,
  links,
  defaultLink,
}: SingleFadeMenuProps) {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoMenu',
  });
  const navigate = useNavigate();
  const generatePath = useGeneratePath();
  const handleOnClick = useCallback(() => {
    popupState.close();
    return navigate(generatePath(defaultLink), { replace: true });
  }, [popupState, navigate, generatePath, defaultLink]);

  return (
    <div>
      <Button
        id="fade-button"
        sx={{ paddingRight: '1.5rem', color: 'primary.dark' }}
        {...bindHover(popupState)}
        onClick={handleOnClick}
      >
        {label}
      </Button>
      {links.length >= 1 && (
        <HoverMenu
          {...bindMenu(popupState)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          {links.map((link) => (
            <Link
              key={`fade-menu-${link.link}`}
              to={generatePath(link.link)}
              data-testid="hover-menu-link"
              style={{
                textDecoration: 'none',
                color: 'black',
                fontSize: '12px',
              }}
            >
              <MenuItem onClick={popupState.close}>{link.label}</MenuItem>
            </Link>
          ))}
        </HoverMenu>
      )}
    </div>
  );
}
