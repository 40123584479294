import React from 'react';
import Typography from '@mui/material/Typography';

interface SpeakerProps {
  speaker: string;
  primary?: string;
  secondary?: string;
}

function Speaker({ speaker, primary, secondary }: SpeakerProps) {
  return (
    <>
      <Typography variant="body1" sx={{ color: 'primary.dark' }}>
        <strong>{speaker}</strong>
      </Typography>
      <Typography component="span" variant="body2" sx={{ color: 'gray' }}>
        {primary}
        {secondary && `, ${secondary}`}
      </Typography>
    </>
  );
}

export default Speaker;
