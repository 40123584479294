import { MutableRefObject, useCallback, useEffect } from 'react';
import * as d3 from 'd3';
import { remove } from 'lodash';

import { Code } from 'src/types/common';
import { LocationMetadata, MapItem } from 'src/types/map';
import { NULL_PK, UNASSIGNED_COLOR } from './mapUtils';

export const useRegionClick = (
  d3MappingComplete: any,
  svgRef: MutableRefObject<null>,
  trimMode: boolean,
  selectedCode: Code | undefined,
  mapItems: MutableRefObject<MapItem[]>,
  updateRef: () => void
) => {
  /**
   * Holds all region selection logic, including the coloring of regions and underlying edits to the mapItems ref
   * @param event Event that holds the current target (region)
   * @param d LocationMetadata, retrieved from d3
   */
  const handleRegionClick = useCallback(
    (event: any, d: LocationMetadata) => {
      if (selectedCode) {
        if (!trimMode) {
          if (d.demographic_id == -1) {
            // region has not yet ben assigned
            d.demographic_id = selectedCode.id;
            mapItems.current.forEach((mapItem: MapItem) => {
              if (mapItem.demographic_id === selectedCode.id) {
                mapItem.paths.push(d.path!); // add it to selected demographics paths[] array
              } else if (mapItem.demographic_id === NULL_PK) {
                // remove it from the Unassigned paths[] array
                remove(mapItem.paths, (path: string) => {
                  return path === d.path;
                });
              }
            });
            d3.select(event.currentTarget).attr(
              'fill',
              selectedCode.embed_colors.background_color
            );
          } else {
            // if the one they clicked on corresponds to the currently selected one, simply deselect it
            if (d.demographic_id === selectedCode.id) {
              d.demographic_id = NULL_PK;
              mapItems.current.forEach((mapItem: MapItem) => {
                if (mapItem.demographic_id === selectedCode.id) {
                  remove(mapItem.paths, (path: string) => {
                    return path === d.path;
                  });
                } else if (mapItem.demographic_id === NULL_PK) {
                  mapItem.paths.push(d.path!);
                }
              });
              d3.select(event.currentTarget).attr('fill', UNASSIGNED_COLOR);
            } else {
              // they're clicking on a differently-colored region; we need to override
              mapItems.current.forEach((mapItem: MapItem) => {
                if (mapItem.demographic_id === d.demographic_id) {
                  remove(mapItem.paths, (path: string) => {
                    return path === d.path;
                  });
                } else if (mapItem.demographic_id === selectedCode.id) {
                  mapItem.paths.push(d.path!);
                }
              });
              d.demographic_id = selectedCode.id;
              d3.select(event.currentTarget).attr(
                'fill',
                selectedCode.embed_colors.background_color
              );
            }
          }
        } else {
          mapItems.current.forEach((mapItem: MapItem) => {
            if (mapItem.demographic_id === d.demographic_id) {
              remove(mapItem.paths, (path: string) => {
                return path === d.path;
              });
            }
          });
          event.currentTarget.remove();
        }
        updateRef();
      }
    },
    [selectedCode, trimMode, updateRef, mapItems]
  );

  /**
   * Handles d3 click logic.
   */
  useEffect(() => {
    if (svgRef.current) {
      const svgElem: HTMLElement = (svgRef.current as HTMLElement)
        .children[0] as HTMLElement;
      d3.select(svgElem)
        .selectAll('path')
        .on('click', (e: any, d: any) => {
          handleRegionClick(e, d);
        });
    }
  }, [handleRegionClick, d3MappingComplete, svgRef]);
};
