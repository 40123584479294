import React from 'react';
import { Editor, Element as SlateElement, Transforms } from 'slate';
import { useSlate } from 'slate-react';

import { Formats } from 'src/types/edit';
import { LIST_TYPES } from './utils';

/**
 * This functionality is modified from the slate rich text example
 * https://github.com/ianstormtaylor/slate/blob/main/site/examples/richtext.tsx
 * @returns an object of functions that enables the slate toolbar
 */
export const useEditorToolbar = (embed?: boolean) => {
  const editor = useSlate();
  const isBlockActive = React.useCallback(
    (format: Formats) => {
      const { selection } = editor;
      if (!selection) return false;

      const [match] = Array.from(
        Editor.nodes(editor, {
          at: Editor.unhangRange(editor, selection),
          match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            n['type'] === format,
        })
      );

      return !!match;
    },
    [editor]
  );

  const isMarkActive = React.useCallback(
    (format: Formats) => {
      const marks = Editor.marks(editor);
      //@ts-ignore - TODO
      return marks ? marks[format] === true : false;
    },
    [editor]
  );
  const toggleBlock = React.useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      format: Formats
    ) => {
      event.preventDefault();
      const isActive = isBlockActive(format);
      const isList = LIST_TYPES.includes(format);

      Transforms.unwrapNodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          LIST_TYPES.includes(n.type),
        split: true,
      });
      const newProperties = {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
      };
      // @ts-ignore - TODO
      Transforms.setNodes<SlateElement>(editor, newProperties);

      if (
        !isActive &&
        (format === 'bulleted-list' || format === 'numbered-list')
      ) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
      }
    },
    [editor, isBlockActive]
  );

  const toggleMark = React.useCallback(
    (
      event:
        | React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<HTMLDivElement>,
      format: Formats
    ) => {
      event?.preventDefault();
      const isActive = isMarkActive(format);
      if (!embed && format === 'embed') {
        return;
      }

      if (isActive) {
        Editor.removeMark(editor, format);
      } else {
        Editor.addMark(editor, format, true);
      }
    },
    [editor, embed, isMarkActive]
  );

  return React.useMemo(
    () => ({
      toggleBlock,
      toggleMark,
      isBlockActive,
      isMarkActive,
    }),
    [isBlockActive, isMarkActive, toggleBlock, toggleMark]
  );
};
