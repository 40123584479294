import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';

import { getFiltersByLabel } from 'src/setup/code';
import {
  FilterGroup,
  FilterOption,
  FilterOrder,
  FilterType,
} from 'src/types/filters';
import MultipleCheckFilter from './MultipleCheckFilter';

interface FilterModalProps {
  filters: FilterGroup[];
  filter_order: FilterOrder;
  activeFilters: FilterOption[];
  setActiveFilters: React.Dispatch<React.SetStateAction<FilterOption[]>>;
  small?: boolean;
}

const FilterModal = ({
  filters,
  filter_order,
  activeFilters,
  setActiveFilters,
  small,
}: FilterModalProps) => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const demographicFilters =
    filter_order.demographic.order?.flatMap((label) =>
      getFiltersByLabel(filters, label)
    ) ?? [];
  const themeFilters =
    filter_order.thematic.order?.flatMap((label) =>
      getFiltersByLabel(filters, label)
    ) ?? [];

  const getFilterTab = (index: number) => {
    switch (index) {
      case filter_order.tabs.indexOf(FilterType.thematic):
        return (
          <>
            {themeFilters.map((filterGroup) => (
              <Box key={filterGroup.label}>
                <MultipleCheckFilter
                  options={filterGroup?.options ?? []}
                  defaultName={filterGroup?.label ?? ''}
                  selectedItems={activeFilters}
                  setSelectedItems={setActiveFilters}
                  singleColumn={small}
                  collapse={themeFilters.length > 1}
                />
              </Box>
            ))}
          </>
        );
      case filter_order.tabs.indexOf(FilterType.demographic):
        return (
          <>
            {demographicFilters.map((filterGroup) => (
              <Box key={filterGroup.label}>
                <MultipleCheckFilter
                  options={filterGroup?.options ?? []}
                  defaultName={filterGroup?.label ?? ''}
                  selectedItems={activeFilters}
                  setSelectedItems={setActiveFilters}
                  singleColumn={small}
                  collapse={demographicFilters.length > 1}
                />
              </Box>
            ))}
          </>
        );
      default:
        return <></>;
    }
  };
  const orderedTabContent = filter_order.tabs.map((tab) => {
    switch (tab) {
      case FilterType.demographic:
        return (
          <Tab
            label={filter_order.demographic.name ?? 'Demographics'}
            data-testid="demographic-filter-tab"
          />
        );
      case FilterType.thematic:
        return (
          <Tab
            label={filter_order.thematic.name ?? 'Themes'}
            data-testid="theme-filter-tab"
          />
        );

      default:
        return <></>;
    }
  });
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Tabs
        value={currentTab}
        onChange={(e, value) => setCurrentTab(value)}
        aria-label="Filter Tabs"
        variant="fullWidth"
        scrollButtons="auto"
      >
        {orderedTabContent}
      </Tabs>
      {getFilterTab(currentTab)}
    </Box>
  );
};

export default FilterModal;
