import React from 'react';
import JsxParser from 'react-jsx-parser';
import { SxProps, Theme } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { InformationCard } from 'src/types/common';
import LinkButton from '../buttons/LinkButton';

export interface InfoCardProps extends InformationCard {
  small?: boolean;
  transparent?: boolean;
  light?: boolean;
  sx?: SxProps<Theme>;
}

export default function InfoCard({
  title,
  subtitle,
  image: image,
  link,
  small,
  body,
  transparent,
  light,
  sx,
}: InfoCardProps) {
  return (
    <Card
      data-testid="info-card"
      elevation={0}
      sx={{
        backgroundColor: transparent ? 'unset' : 'white' /* Light Gray */,
        minHeight: '100%',
        display: 'grid',
        ...sx,
      }}
    >
      {image && <CardMedia sx={{ paddingTop: '40%' }} image={image} />}
      <CardContent sx={{ padding: '1.5rem' }}>
        <Typography
          variant="h5"
          component="div"
          sx={{
            color: light ? 'white' : 'primary.dark',
            paddingBottom: '.5rem',
          }}
        >
          {/* @ts-ignore - jsx parser */}
          <JsxParser jsx={title} />
        </Typography>
        {subtitle && (
          <Typography
            gutterBottom
            variant={small ? 'body2' : 'body1'}
            component="div"
            color={light ? 'white' : 'primary.dark'}
            sx={{ fontWeight: '550' }}
          >
            {/* @ts-ignore - jsx parser */}
            <JsxParser jsx={subtitle} />
          </Typography>
        )}
        <Typography
          variant="body2"
          component="span"
          color={light ? 'whitesmoke' : 'text.secondary'}
          sx={{ paddingTop: '1rem' }}
        >
          {/* @ts-ignore - jsx parser */}
          {body && <JsxParser jsx={body} />}
        </Typography>
      </CardContent>
      {link && (
        <CardActions sx={{ padding: '1.5rem', verticalAlign: 'bottom' }}>
          <LinkButton link={link.to} size={small ? 'small' : 'medium'}>
            {link.label}
          </LinkButton>
        </CardActions>
      )}
    </Card>
  );
}
