import React from 'react';
import { Grid, Typography } from '@mui/material';

import { Code } from 'src/types/common';
import { useInteractiveLibraryEvents } from './utils/useInteractiveLibraryEvents';

interface ConversationButtonTagsProps {
  codes: Code[];
  svgWidth: number;
  svgHeight: number;
  isMobile: boolean;
}

function ConversationButtonTags({
  codes,
  svgHeight,
  svgWidth,
  isMobile,
}: ConversationButtonTagsProps) {
  const { updateButtons, activeButtons, hoverButtons } =
    useInteractiveLibraryEvents(codes, svgWidth, svgHeight, isMobile);
  const buttonTagClick = (id: number) => {
    updateButtons({ activeId: id });
  };
  const linkTagClick = (id: number) => {
    updateButtons({ activeId: id });
  };

  const buttonTagMouseOver = (id: number) => {
    if (activeButtons.includes(id) || hoverButtons.includes(id)) {
      return;
    }
    updateButtons({ hoverId: id });
  };

  const buttonTagMouseOut = (id: number) => {
    if (activeButtons.includes(id) || !hoverButtons.includes(id)) {
      return;
    }

    updateButtons({ hoverId: id });
  };

  const reorderedTags = React.useMemo(
    () =>
      codes
        .reduce((final, tag, ind, original) => {
          switch (ind) {
            case 2:
              final.push(original[4]);
              break;
            case 3:
              final.push(original[5]);
              break;
            case 4:
              final.push(original[2]);
              break;
            case 5:
              final.push(original[3]);
              break;

            default:
              final.push(tag);
          }
          return final;
        }, [] as Code[])
        .filter((tag) => tag !== undefined),
    [codes]
  );

  return (
    <>
      {isMobile && (
        <div>
          <Grid container>
            {reorderedTags.map(({ label, conversation_library_colors, id }) => {
              const active = activeButtons.includes(id);
              const hovered = hoverButtons.includes(id) && !active;
              return (
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: `none`,
                  }}
                  onClick={() => {
                    linkTagClick(id);
                  }}
                  component={'button'}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: conversation_library_colors.text_color,
                      backgroundColor:
                        conversation_library_colors.background_color,
                      textDecoration: hovered ? 'underline' : '',
                      fontWeight: active ? 'bold' : '',
                    }}
                  >
                    {label}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </div>
      )}
      <div
        className="tag-container"
        style={{
          marginBottom: isMobile ? '0px' : '20px',
          marginTop: isMobile ? '20px' : '0px',
        }}
      >
        {codes.map(({ label, conversation_library_colors, id }) => {
          const active = activeButtons.includes(id);
          return (
            <div className="button-container" key={label}>
              <button
                id={`_${id}`}
                className={'tag-button'}
                onClick={() => buttonTagClick(id)}
                onMouseOver={() => buttonTagMouseOver(id)}
                onMouseOut={() => buttonTagMouseOut(id)}
                style={{
                  color: active
                    ? 'white'
                    : conversation_library_colors.text_color,
                  background: active
                    ? conversation_library_colors.background_color
                    : '',
                  borderColor: conversation_library_colors.background_color,
                }}
              >
                {isMobile ? '' : label}
              </button>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default ConversationButtonTags;
