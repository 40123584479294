import React from 'react';
import { Container, Grid, SxProps, Theme } from '@mui/material';

import { useTexture } from '../texture/texture';

interface SectionProps {
  children: React.ReactElement | React.ReactElement[];
  texture?: string;
  dark?: boolean;
  sx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
}

const Section = ({
  children,
  texture,
  dark,
  sx,
  containerSx,
}: SectionProps) => {
  const { getTexture } = useTexture();
  return (
    <Grid
      container
      item
      paddingBottom={6}
      marginBottom={11}
      xs={12}
      sx={{
        height: '100%',
        backgroundImage: texture ? `url(${getTexture(!!dark)})` : 'unset',
        backgroundSize: 'cover',
        paddingBottom: '2rem',
        backgroundColor: dark ? 'primary.light' : 'unset',
        ...sx,
      }}
      className="section-container-grid"
    >
      <Container
        maxWidth="lg"
        className="section-container"
        sx={{ ...containerSx }}
      >
        {children}
      </Container>
    </Grid>
  );
};

export default Section;
