import { cloneDeep } from 'lodash';

import { NULL_PK } from 'src/components/Editing/utils/mapUtils';
import { Code } from 'src/types/common';
import { EditSection, EditType } from 'src/types/edit';
import { MapItem } from 'src/types/map';
import { HomePage } from 'src/types/pages/home';
import { slugify, valueOrThrow } from 'src/utils/utils';

/**
 * Dynamically generates a new Home Page that includes an updated map_showcase and edit_metadata
 * @param page Existing home page
 * @param mapItems New Map Items
 * @param demographicCodes Existing Demographic Codes (used to match against Map Items for proper query resolution)
 * @param useExistingLocationCards Boolean flag to indicate if the location cards should be pulled from the homepage or generated
 * @returns Updated Home Page
 */
export const generateHomePageWithMap = (
  page: HomePage,
  mapItems: MapItem[],
  demographicCodes: Code[],
  useExistingLocationCards?: boolean
): HomePage => {
  const newPage: HomePage = cloneDeep(page);
  const filteredMapItems = mapItems.filter(
    (mapItem) => mapItem.demographic_id !== NULL_PK
  );
  newPage.map_showcase.visible = true;
  if (!useExistingLocationCards) {
    newPage.map_showcase.content.location_cards = filteredMapItems.map(
      (mapItem: MapItem) => {
        // find the matching demographic code so we can use it's label for proper View Related Highlights link routing
        const matchingDemographicCode: Code = valueOrThrow<Code>(
          demographicCodes.find((code: Code) => {
            return code.subcodes
              ?.map((code) => code.id)
              .includes(mapItem.demographic_id);
          })
        );
        return {
          title: mapItem.label,
          body: '<p>Insert some default text here</p>',
          id: mapItem.demographic_id.toString(),
          link: {
            to: `/voices/highlight-explorer?${slugify(
              matchingDemographicCode.display_name
            )}=${slugify(mapItem.label)}`,
            label: 'View Related Highlights',
          },
        };
      }
    );
  }
  // delete map showcase section (if it exists, in the case of updated mappable demographic codes)
  const existingMapShowcaseSectionIndex: number =
    newPage.edit_metadata.sections.findIndex(
      (editSection) => editSection.section_label === 'Map Showcase'
    );
  if (existingMapShowcaseSectionIndex !== NULL_PK) {
    newPage.edit_metadata.sections.splice(existingMapShowcaseSectionIndex, 1);
  }
  // insert the Map Showcase section before the Navigation Cards
  newPage.edit_metadata.sections.splice(
    newPage.edit_metadata.sections.length - 1,
    0,
    {
      section_label: 'Map Showcase',
      sub_sections: [
        {
          section_label: 'Overview',
          edit_blocks: [
            {
              block_label: 'Enable zooming on this map?',
              match: 'map_showcase.content.zoomable',
              type: EditType.checkbox,
            },
            {
              block_label: 'Location Type',
              match: 'map_showcase.content.location_type',
              type: EditType.string,
            },
            {
              block_label: 'Title',
              match: 'map_showcase.content.overview.title',
              type: EditType.string,
            },
            {
              block_label: 'Subtitle',
              match: 'map_showcase.content.overview.subtitle',
              type: EditType.complex_string,
            },
            {
              block_label: 'Body',
              match: 'map_showcase.content.overview.body',
              type: EditType.complex_string,
            },
            {
              block_label: 'Link',
              match: 'map_showcase.content.overview.link',
              type: EditType.link,
            },
          ],
        },
        ...filteredMapItems.map(
          (mapItem: MapItem, index: number): EditSection => {
            return {
              section_label: mapItem.label,
              edit_blocks: [
                {
                  block_label: 'Title',
                  match: `map_showcase.content.location_cards[${index}].title`,
                  type: EditType.string,
                },
                {
                  block_label: 'Subtitle',
                  match: `map_showcase.content.location_cards[${index}].subtitle`,
                  type: EditType.complex_string,
                },
                {
                  block_label: 'Body',
                  match: `map_showcase.content.location_cards[${index}].body`,
                  type: EditType.complex_string,
                },
                {
                  block_label: 'Link',
                  match: `map_showcase.content.location_cards[${index}].link`,
                  type: EditType.link,
                },
              ],
            };
          }
        ),
      ],
    }
  );
  return newPage;
};
