import React from 'react';
import Grid from '@mui/material/Grid';

interface TranscriptProps {
  sectionId: string;
  hid: number;
  extend?: boolean;
}
function Transcript({ sectionId, hid, extend }: TranscriptProps) {
  return (
    <Grid
      item
      xs={12}
      id={`${sectionId}-embed-minimal-${hid}`}
      style={{
        height: extend ? `134px` : `104px`,
      }}
      paddingX="1rem"
    />
  );
}

export default Transcript;
