import React from 'react';
import Grid from '@mui/material/Grid';

import { SectionStyle } from 'src/types/common';
import { useTexture } from '../texture/texture';

interface StyledBackgroundProps extends SectionStyle {
  children: React.ReactElement | React.ReactElement[];
  md?: boolean;
  offset?: boolean;
  item?: boolean;
  padding?: boolean;
  minHeight?: boolean;
}

function StyledBackground({
  children,
  md,
  offset,
  item,
  padding,
  texture,
  secondary = false,
  image: image,
  minHeight,
}: StyledBackgroundProps) {
  const { getTexture } = useTexture(secondary);
  return (
    <Grid
      container
      item
      xs={12}
      md={md}
      paddingX={{ xs: padding ? 5 : 0, sm: padding ? 8 : 0 }}
      paddingY={padding ? 10 : 4}
      sx={{
        minHeight: { md: minHeight ? '100%' : '' },
        backgroundImage: image
          ? `url(${image})`
          : texture
          ? `url(${getTexture(secondary)})`
          : 'unset',
        backgroundSize: 'cover',
        color: secondary ? 'primary.main' : 'white',
        backgroundColor: 'primary.dark',
        marginTop: offset ? '-100px' : 'unset',
      }}
    >
      {item && <Grid item>{children}</Grid>}
      {!item && children}
    </Grid>
  );
}

export default StyledBackground;
