import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/material';
import { cloneDeep, get, set } from 'lodash';

import appSelectors from 'src/redux/app/app-selectors';
import { updateOrganizationMetadata } from 'src/redux/app/app-slice';
import { StoreState } from 'src/redux/store';
import { EditBlock, EditSection } from 'src/types/edit';
import CollapsableSection from '../CollapsableSection';
import EditSectionModal from '../EditSectionModal';

const SiteEditingTab = () => {
  const dispatch = useDispatch();
  const organization_metadata = useSelector((state: StoreState) =>
    appSelectors.getOrganizationMetadata(state)
  );
  const [editSection, setEditSection] = React.useState<EditSection>();
  const modifiedBlocks = React.useMemo(() => {
    if (!editSection || !organization_metadata) {
      return [];
    }
    return (
      editSection?.edit_blocks?.map((block) => {
        return {
          ...block,
          value: get(organization_metadata, block.match),
        };
      }) ?? []
    );
  }, [editSection, organization_metadata]);
  const onClose = () => {
    setEditSection(undefined);
  };
  const onSave = (blocks: EditBlock[]) => {
    if (organization_metadata && editSection) {
      const newOrganization_metadata = cloneDeep(organization_metadata);
      blocks.forEach((block) => {
        set(newOrganization_metadata, block.match, block.value);
      });
      dispatch(updateOrganizationMetadata(newOrganization_metadata));
    }
    onClose();
  };

  return (
    <div>
      <List>
        {organization_metadata?.edit_metadata.sections.map((section) => {
          return (
            <CollapsableSection
              key={section.section_label}
              pageLabel={section.section_label}
              section={section}
              onSectionClick={(section) => setEditSection(section)}
            />
          );
        })}
      </List>
      <EditSectionModal
        label={editSection?.edit_header}
        blocks={modifiedBlocks}
        onSave={onSave}
        onClose={onClose}
      />
    </div>
  );
};
export default SiteEditingTab;
