import React from 'react';
import JsxParser from 'react-jsx-parser';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import HighlightCarousel from 'src/components/common/carousels/HighlightCarousel';
import DropdownMenu from 'src/components/common/menus/DropdownMenu';
import { Highlight } from 'src/types/common';
import { stripHTMLTags } from 'src/utils/utils';

interface HighlightInfoCompactProps {
  tabs: { label: string }[];
  selectedTopic: string;
  handleChange: (label: string) => void;
  highlights?: Highlight[];
  highlight_ids: number[];
  highlightLinkButton: JSX.Element;
  body?: string;
}

function HighlightInfoCompact({
  tabs,
  selectedTopic,
  handleChange,
  highlights,
  highlight_ids,
  highlightLinkButton,
  body,
}: HighlightInfoCompactProps) {
  // used to test whether or not a highlight's body paragraph is empty
  const strippedBody = body ? stripHTMLTags(body) : '';
  return (
    <Box padding={3} sx={{ width: '100vw', boxSizing: 'border-box' }}>
      <Grid>
        <Typography variant="h5" sx={{ color: 'white' }}>
          Highlights from Conversations
        </Typography>
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <DropdownMenu
          items={tabs}
          selected={selectedTopic}
          label="Themes"
          handleChange={(event) => {
            handleChange(event.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} marginTop={5}>
        {body && strippedBody !== '' && (
          <Typography
            variant="body2"
            sx={{ paddingBottom: '3rem', color: 'inherit' }}
          >
            {/* @ts-ignore - ignore */}
            <JsxParser jsx={body} />
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        marginTop={6}
        data-testid="highlight-carousel-container"
      >
        {highlights && (
          <HighlightCarousel
            highlights={highlights}
            highlight_ids={highlight_ids}
            id="compact"
          />
        )}
        {highlightLinkButton}
      </Grid>
    </Box>
  );
}

export default HighlightInfoCompact;
