import { FilterGroup, FilterOption, FilterQuery } from 'src/types/filters';
import { slugify } from 'src/utils/utils';

export const getMatchingFilters = (
  filters: FilterGroup[],
  queries?: FilterQuery[]
) => {
  if (!queries) {
    return [];
  }

  const match: FilterOption[] = [];
  queries.forEach((item) => {
    if (item.query) {
      const queryList = item.query
        .split(',')
        .map((prompt) => prompt.toLowerCase());
      queryList.push(item.query.toLowerCase()); // Handle case where the query wasn't a list
      const matchingFilter = filters.find(
        (filter) => filter.label.toLowerCase() === item.label.toLowerCase()
      );

      matchingFilter?.options.forEach((option) => {
        const filteringLabel = slugify(option.label.toLowerCase());

        if (queryList.includes(filteringLabel)) {
          match.push(option);
        }
      });
    }
  });

  return match;
};
