import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';

interface InfoModalButtonProps {
  title?: string;
  icon: React.ReactElement;
  closeButtonLabel: string;
  children: React.ReactElement | React.ReactElement[];
  defaultOpen?: boolean;
  iconLabel?: string;
  color?: string;
}

export default function InfoModalButton({
  title,
  icon,
  closeButtonLabel,
  children,
  defaultOpen = false,
  iconLabel,
  color = 'white',
}: InfoModalButtonProps) {
  const [open, setOpen] = useState(defaultOpen);

  const handleClose = () => {
    setOpen(false);
  };

  const iconButtonStyle = {
    color: color,
    padding: 0,
    ':hover': iconLabel ? { borderRadius: '10%' } : 'unset',
  };

  return (
    <>
      <IconButton sx={iconButtonStyle} onClick={() => setOpen(true)}>
        <Typography sx={{ display: 'flex', color }} variant="body2">
          {icon}
          {iconLabel ? (
            <Typography variant="body2" sx={{ marginLeft: '0.5rem' }}>
              {iconLabel}
            </Typography>
          ) : (
            <></>
          )}
        </Typography>
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent sx={{ height: '50vh' }}>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" autoFocus>
            {closeButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
