import React from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { Box, Typography } from '@mui/material';

const ColorEditor = React.forwardRef(
  (
    {
      label,
      color,
      onColorChange,
    }: {
      label: string;
      color?: string;
      onColorChange?: (color: ColorResult) => void;
    },
    ref
  ) => {
    const [open, setOpen] = React.useState(false);
    const [colorState, setColorState] = React.useState(color);
    const handleColorChange = (color: ColorResult) => {
      onColorChange && onColorChange(color);
      setColorState(color.hex);
      if (ref) {
        (ref as React.MutableRefObject<string>).current = color.hex;
      }
    };
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
        <Typography variant="h6">{label}</Typography>
        <div
          data-testid="color-swatch"
          style={{
            width: '40px',
            height: '20px',
            marginLeft: '1rem',
            borderRadius: '2px',
            cursor: 'pointer',
            backgroundColor: colorState,
            border: '2px solid #fff',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          }}
          onClick={() => setOpen((self) => !self)}
        />
        {open && (
          <div style={{ position: 'absolute', zIndex: '2' }}>
            <div
              style={{
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
              }}
              onClick={() => setOpen(false)}
            />
            <ChromePicker
              color={colorState}
              onChange={handleColorChange}
            ></ChromePicker>
          </div>
        )}
      </Box>
    );
  }
);

export default ColorEditor;
