import React from 'react';
import ChatIcon from '@mui/icons-material/Chat';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { Button, ButtonGroup, Toolbar } from '@mui/material';

import { Formats } from 'src/types/edit';

const EditorToolbar = ({
  toggleBlock,
  toggleMark,
  isBlockActive,
  isMarkActive,
  embed,
}: {
  toggleBlock: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    format: Formats
  ) => void;
  toggleMark: (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLDivElement>,
    format: Formats
  ) => void;
  isBlockActive: (format: Formats) => boolean;
  isMarkActive: (format: Formats) => boolean;
  embed?: boolean;
}) => {
  return (
    <Toolbar disableGutters>
      <ButtonGroup>
        <Button
          size="small"
          onClick={(event) => toggleMark(event, 'bold')}
          // disabled={isMarkActive('bold')}
        >
          <FormatBoldIcon
            sx={isMarkActive('bold') ? { opacity: '1' } : { opacity: '0.5' }}
          />
        </Button>
        <Button size="small" onClick={(event) => toggleMark(event, 'italic')}>
          <FormatItalicIcon
            sx={isMarkActive('italic') ? { opacity: '1' } : { opacity: '0.5' }}
          />
        </Button>
        <Button
          size="small"
          onClick={(event) => toggleBlock(event, 'bulleted-list')}
        >
          <FormatListBulletedIcon
            sx={
              isBlockActive('bulleted-list')
                ? { opacity: '1' }
                : { opacity: '0.5' }
            }
          />
        </Button>
        <Button
          size="small"
          onClick={(event) => toggleBlock(event, 'numbered-list')}
        >
          <FormatListNumberedIcon
            sx={
              isBlockActive('numbered-list')
                ? { opacity: '1' }
                : { opacity: '0.5' }
            }
          />
        </Button>
        {embed && (
          <Button size="small" onClick={(event) => toggleMark(event, 'embed')}>
            <ChatIcon
              sx={isMarkActive('embed') ? { opacity: '1' } : { opacity: '0.5' }}
            />
          </Button>
        )}
      </ButtonGroup>
    </Toolbar>
  );
};

export default EditorToolbar;
