import { CodeMetadata } from 'src/types/common';

export const ANALYTIC_CODES: CodeMetadata[] = [
  {
    id: 1,
    display_name: '*structural codes',
    subcodes: [
      { id: 2, display_name: 'opportunities' },
      { id: 3, display_name: 'challenges' },
      { id: 4, display_name: 'lived experiences' },
      { id: 5, display_name: 'resonating experiences' },
      { id: 6, display_name: 'fmcs future' },
      { id: 7, display_name: 'takeaways' },
      { id: 8, display_name: 'lived experences' },
    ],
  },
  {
    id: 9,
    display_name: 'client engagement',
    subcodes: [
      { id: 10, display_name: 'effective service' },
      { id: 11, display_name: 'meeting spaces' },
      { id: 12, display_name: 'relationship building' },
      { id: 13, display_name: 'client satisfaction' },
    ],
  },
  {
    id: 14,
    display_name: 'training + development',
    subcodes: [
      { id: 15, display_name: 'technology' },
      { id: 16, display_name: 'upskilling' },
      { id: 17, display_name: 'resistance to change' },
    ],
  },
  {
    id: 18,
    display_name: 'work-life balance',
    subcodes: [
      { id: 19, display_name: 'scheduling' },
      { id: 20, display_name: 'travel' },
      { id: 21, display_name: 'wellness' },
      { id: 22, display_name: 'family' },
    ],
  },
  {
    id: 23,
    display_name: 'relationships',
    subcodes: [
      { id: 24, display_name: 'disconnection' },
      { id: 25, display_name: 'connection' },
      { id: 26, display_name: 'teamwork' },
    ],
  },
  {
    id: 27,
    display_name: 'remote working experience',
    subcodes: [
      { id: 28, display_name: 'impersonal' },
      { id: 29, display_name: 'workflow' },
      { id: 30, display_name: 'learning curve' },
      { id: 31, display_name: 'fatigue' },
    ],
  },
  {
    id: 32,
    display_name: 'intra-organizational',
    subcodes: [
      { id: 33, display_name: 'collaboration' },
      { id: 34, display_name: 'awareness' },
      { id: 35, display_name: 'engagement' },
    ],
  },
  {
    id: 36,
    display_name: 'culture',
    subcodes: [
      { id: 37, display_name: 'inclusion' },
      { id: 38, display_name: 'internal politics or structure' },
      { id: 39, display_name: 'espirit de corps' },
      { id: 40, display_name: 'power dynamics' },
      { id: 41, display_name: 'esprit de corps' },
    ],
  },
  {
    id: 42,
    display_name: 'process',
    subcodes: [
      { id: 43, display_name: 'appreciation' },
      { id: 44, display_name: 'feedback' },
      { id: 45, display_name: 'approach-strategy' },
      { id: 46, display_name: 'critique' },
    ],
  },
];
