import React from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import useDynamicRefs from 'use-dynamic-refs';

import appSelectors from 'src/redux/app/app-selectors';
import { getFilterByType } from 'src/setup/code';
import { generateAllPotentialPaths } from 'src/setup/routes';
import { LinkItem } from 'src/types/common';
import { EditBlock, EditType } from 'src/types/edit';
import { FilterType } from 'src/types/filters';
import { FONTS } from 'src/utils/utils';
import BannerEditor from './BannerEditor';
import CheckboxEditor from './CheckboxEditor';
import CodeEditor from './CodeEditor';
import ColorEditor from './ColorEditor';
import CustomEditor from './CustomEditor';
import FilterGroupEditor from './FilterGroupEditor';
import FilterOrderEditor from './FilterOrderEditor';
import ImageEditor from './ImageEditor';
import LinkEditor from './LinkEditor';
import ListEditor from './ListEditor';
import MapEditor from './MapEditor/MapEditor';
import OptionalLinkEditor from './OptionalLinkEditor';
import PaletteEditor from './PaletteEditor';
import RouteEditor from './RouteEditor';
import StringEditor from './StringEditor';
import TypographyEditor from './TypographyEditor';

const TAB_TYPES = ['floating', 'tab'];
const GRAPH_TYPES = ['horizontal_bars', 'packed_circles'];

const EditSectionModal = ({
  label,
  blocks,
  onSave,
  onClose,
  onRemove,
}: {
  label: string | undefined;
  blocks: EditBlock[];
  onSave: (blocks: EditBlock[]) => void;
  onClose: () => void;
  onRemove?: () => void;
}) => {
  // Allows us to create a ref for each editor. The ref's value is updated
  // on user input, which stores the editor state without re-rendering all the editors.
  const highlights = useSelector(appSelectors.getHighlights);
  const filters = useSelector(appSelectors.getFilters);
  const routes = useSelector(appSelectors.getCustomRoutes);
  const codes = useSelector(appSelectors.getCodes);
  const demographicOptions = Array.from(
    new Set(
      codes.demographic?.reduce((final, curr) => {
        return final.concat(curr.label);
      }, [] as string[])
    )
  );
  const allHighlightIds = Array.from(
    new Set(
      highlights.reduce((final, curr) => {
        return final.concat([curr.id.toString()]);
      }, [] as string[])
    )
  );
  const allPaths = generateAllPotentialPaths(routes, filters);
  const [getRef, setRef] = useDynamicRefs();
  const handleSave = () => {
    let newBlocks = cloneDeep(blocks);
    newBlocks = newBlocks.map((block) => {
      let value = block.value;
      const blockRef = getRef(block.block_label);
      if (blockRef && blockRef.current !== null) {
        switch (block.type) {
          case EditType.demographic_list:
            value = (blockRef.current as string[])
              .map(
                (label) =>
                  codes.demographic.find((code) => code.label === label)?.id
              )
              .filter((id) => id !== undefined);
            break;
          case EditType.highlight_list:
            value = (blockRef.current as string[]).map((highlightIdString) =>
              parseInt(highlightIdString)
            );
            break;
          case EditType.featured_highlight:
            // if we get an array back, that means the user has cleared the highlight entirely
            value = Array.isArray(blockRef.current)
              ? undefined
              : parseInt(blockRef.current as string);
            break;
          default:
            value = blockRef.current as string;
            break;
        }
      }
      return {
        ...block,
        value,
      };
    });
    onSave(newBlocks);
  };
  return (
    <Dialog
      open={!!blocks.length}
      onClose={onClose}
      maxWidth={false}
      PaperComponent={({ children }) => (
        <Paper
          sx={{
            backgroundColor: '#f4f4f4',
          }}
          className="flex-column"
        >
          {children}
        </Paper>
      )}
    >
      <DialogTitle sx={{ paddingBottom: '0' }}>{label}</DialogTitle>
      <DialogContent
        sx={
          blocks.find((block) => block.type === EditType.map)
            ? {
                width: '80vw',
                height: '80vh',
                display: 'flex',
                flexDirection: 'column',
              }
            : { width: '50vw', height: '80vh' }
        }
      >
        {blocks.map((block) => {
          switch (block.type) {
            case EditType.string:
              return (
                <React.Fragment key={block.block_label}>
                  <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                    {block.block_label}
                  </Typography>
                  <StringEditor
                    initialValue={block.value ?? ''}
                    ref={setRef(block.block_label) ?? undefined}
                  />
                </React.Fragment>
              );
            case EditType.complex_string:
              return (
                <React.Fragment key={block.block_label}>
                  <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                    {block.block_label}
                  </Typography>
                  <CustomEditor
                    initialText={block.value ?? ''}
                    ref={setRef(block.block_label) ?? undefined}
                  />
                </React.Fragment>
              );
            case EditType.embed_string:
              return (
                <React.Fragment key={block.block_label}>
                  <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                    {block.block_label}
                  </Typography>
                  <CustomEditor
                    initialText={block.value ?? ''}
                    embed
                    ref={setRef(block.block_label) ?? undefined}
                  />
                </React.Fragment>
              );
            case EditType.image:
              return (
                <React.Fragment key={block.block_label}>
                  <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                    {block.block_label}
                  </Typography>
                  <ImageEditor
                    initialValue={block.value}
                    ref={setRef(block.block_label) ?? undefined}
                  />
                </React.Fragment>
              );
            case EditType.link:
              return (
                <React.Fragment key={block.block_label}>
                  <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                    {block.block_label}
                  </Typography>
                  <LinkEditor
                    ref={setRef(block.block_label) ?? undefined}
                    link={block.value as LinkItem}
                    potentialPaths={allPaths}
                  />
                </React.Fragment>
              );
            case EditType.list:
              return (
                <React.Fragment key={block.block_label}>
                  <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                    {block.block_label}
                  </Typography>
                  <ListEditor initialOptions={block.value} />
                </React.Fragment>
              );
            case EditType.highlight_list:
              return (
                <React.Fragment key={block.block_label}>
                  <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                    {block.block_label}
                  </Typography>
                  <ListEditor
                    initialOptions={block.value.map((highlightId: number) =>
                      highlightId.toString()
                    )}
                    allOptions={allHighlightIds}
                    ref={setRef(block.block_label) ?? undefined}
                  />
                </React.Fragment>
              );
            case EditType.filter_order:
              return (
                <React.Fragment key={block.block_label}>
                  <FilterOrderEditor
                    initialOrder={block.value}
                    thematicOptions={getFilterByType(
                      filters ?? [],
                      FilterType.thematic
                    )
                      .map((group) => group.label)
                      .concat(
                        getFilterByType(
                          filters ?? [],
                          FilterType.structural
                        ).map((group) => group.label)
                      )}
                    demographicOptions={getFilterByType(
                      filters ?? [],
                      FilterType.demographic
                    ).map((group) => group.label)}
                    ref={setRef(block.block_label) ?? undefined}
                  />
                </React.Fragment>
              );
            case EditType.filter_group:
              return (
                <React.Fragment key={block.block_label}>
                  <FilterGroupEditor
                    initialGroup={block.value}
                    ref={setRef(block.block_label) ?? undefined}
                  />
                </React.Fragment>
              );
            case EditType.map:
              return (
                <React.Fragment key={block.block_label}>
                  <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                    {block.block_label}
                  </Typography>
                  <MapEditor
                    initialValue={block.value}
                    ref={setRef(block.block_label) ?? undefined}
                  ></MapEditor>
                </React.Fragment>
              );
            case EditType.color:
              return (
                <React.Fragment key={block.block_label}>
                  <ColorEditor
                    color={block.value}
                    label={block.block_label}
                    ref={setRef(block.block_label) ?? undefined}
                  />
                </React.Fragment>
              );
            case EditType.demographic_list:
              return (
                <React.Fragment key={block.block_label}>
                  <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                    {block.block_label}
                  </Typography>
                  <ListEditor
                    initialOptions={
                      (block.value as [])
                        .map(
                          (id) =>
                            codes.demographic.find((code) => code.id === id)
                              ?.label
                        )
                        .filter((label) => label) as string[]
                    }
                    allOptions={demographicOptions}
                    ref={setRef(block.block_label) ?? undefined}
                  />
                </React.Fragment>
              );
            case EditType.color_palette:
              return (
                <React.Fragment key={block.block_label}>
                  <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                    {block.block_label}
                  </Typography>
                  <PaletteEditor
                    initialPalette={block.value}
                    ref={setRef(block.block_label) ?? undefined}
                  />
                </React.Fragment>
              );
            case EditType.typography:
              return (
                <React.Fragment key={block.block_label}>
                  <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                    {block.block_label}
                  </Typography>
                  <TypographyEditor
                    initialTypography={block.value}
                    ref={setRef(block.block_label) ?? undefined}
                  />
                </React.Fragment>
              );
            case EditType.font_family:
              return (
                <React.Fragment key={block.block_label}>
                  <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                    {block.block_label}
                  </Typography>
                  <ListEditor
                    initialOptions={block.value}
                    allOptions={FONTS}
                    single
                    ref={setRef(block.block_label) ?? undefined}
                  />
                </React.Fragment>
              );
            case EditType.tab_type:
              return (
                <React.Fragment key={block.block_label}>
                  <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                    {block.block_label}
                  </Typography>
                  <ListEditor
                    initialOptions={block.value}
                    allOptions={TAB_TYPES}
                    single
                    ref={setRef(block.block_label) ?? undefined}
                  />
                </React.Fragment>
              );
            case EditType.code:
              return (
                <React.Fragment key={block.block_label}>
                  <CodeEditor
                    initialCode={block.value}
                    ref={setRef(block.block_label) ?? undefined}
                  />
                </React.Fragment>
              );
            case EditType.demographic:
              return (
                <React.Fragment key={block.block_label}>
                  <CodeEditor
                    initialCode={block.value}
                    demographic
                    ref={setRef(block.block_label) ?? undefined}
                  />
                </React.Fragment>
              );
            case EditType.route:
              return (
                <React.Fragment key={block.block_label}>
                  <RouteEditor
                    initialRoute={block.value}
                    ref={setRef(block.block_label) ?? undefined}
                    allRoutes={routes}
                  />
                </React.Fragment>
              );
            case EditType.banner:
              return (
                <React.Fragment key={block.block_label}>
                  <BannerEditor
                    initialData={block.value}
                    ref={setRef(block.block_label) ?? undefined}
                  />
                </React.Fragment>
              );
            case EditType.checkbox:
              return (
                <React.Fragment key={block.block_label}>
                  <CheckboxEditor
                    initialValue={block.value}
                    label={block.block_label}
                    ref={setRef(block.block_label) ?? undefined}
                  ></CheckboxEditor>
                </React.Fragment>
              );
            case EditType.optional_link:
              return (
                <React.Fragment key={block.block_label}>
                  <OptionalLinkEditor
                    initialValue={block.value}
                    potentialPaths={allPaths}
                    label={block.block_label}
                    ref={setRef(block.block_label) ?? undefined}
                  ></OptionalLinkEditor>
                </React.Fragment>
              );
            case EditType.featured_highlight:
              return (
                <React.Fragment key={block.block_label}>
                  <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                    {block.block_label}
                  </Typography>
                  <ListEditor
                    initialOptions={
                      block.value ? [(block.value as number).toString()] : []
                    }
                    allowCustomValues={false}
                    allOptions={allHighlightIds}
                    single
                    ref={setRef(block.block_label) ?? undefined}
                  />
                </React.Fragment>
              );
            case EditType.graph:
              return (
                <React.Fragment key={block.block_label}>
                  <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                    {block.block_label}
                  </Typography>
                  <ListEditor
                    initialOptions={
                      block.value ? [(block.value as number).toString()] : []
                    }
                    allowCustomValues={false}
                    allOptions={GRAPH_TYPES}
                    single
                    ref={setRef(block.block_label) ?? undefined}
                  />
                </React.Fragment>
              );
            default:
              break;
          }
        })}
      </DialogContent>
      <DialogActions>
        {onRemove && (
          <Button
            data-testid="remove-edit-section-button"
            onClick={onRemove}
            variant="text"
            color="error"
          >
            Remove
          </Button>
        )}
        <Button
          data-testid="save-edit-section-button"
          onClick={handleSave}
          variant="text"
          autoFocus
          sx={{ color: '#4F29B7' }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSectionModal;
