import React from 'react';
import JsxParser from 'react-jsx-parser';
import { ArrowDownward } from '@mui/icons-material';
import { Box, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';

import { LinkItem } from 'src/types/common';
import { useTexture } from '../texture/texture';
import Banner from './Banner';
import ThinBanner from './ThinBanner';

interface FullBannerProps {
  title: string;
  body: string;
  picture?: string;
  navigation?: LinkItem;
  texture?: boolean;
  secondary?: boolean;
  large?: boolean;
  arrow?: boolean;
  center?: boolean;
  icon?: React.ReactElement;
}

function FullBanner({
  title,
  body,
  picture,
  navigation,
  texture,
  secondary = false,
  large,
  arrow,
  center,
  icon,
}: FullBannerProps) {
  const { getTexture } = useTexture(secondary);
  const isExtraSmallScreen: boolean = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  let sx: SxProps<Theme> = {
    color: secondary ? 'primary.dark' : 'white',
    backgroundColor: secondary ? 'primary.light' : 'primary.dark',
    minHeight: '40vh',
    textAlign: center ? 'center' : 'default',
  };

  if (picture || texture) {
    sx = {
      ...sx,
      backgroundImage: picture
        ? `url(${picture})`
        : texture
        ? `url(${getTexture(secondary)})`
        : 'unset',
      backgroundSize: isExtraSmallScreen ? 'auto' : '100%',
      minHeight: picture ? (large ? '60vh' : '55vh') : '40vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    };
  }

  return (
    <>
      <Banner sx={sx} center={center}>
        <Box data-testid="full-banner">
          {/* TODO: Uncomment this if we decide to re-implement */}
          {/* {navigation && (
            <Box>
              <BreadcrumbLinks navigation={navigation} color="inherit" />
            </Box>
          )} */}
          <Typography
            variant="h4"
            sx={{
              paddingBottom: '1.5rem',
            }}
            component="span"
          >
            {/* @ts-ignore - ignore */}
            {title && <JsxParser jsx={title} />}
          </Typography>
          {body && (
            <Typography
              variant="h6"
              sx={{ fontWeight: 550, fontSize: '1.125rem' }}
              component="span"
            >
              {/* @ts-ignore - ignore */}
              {body && <JsxParser jsx={body} />}
            </Typography>
          )}
          {arrow && (
            <Typography sx={{ paddingTop: '2rem', marginBottom: '6rem' }}>
              <ArrowDownward />
            </Typography>
          )}
        </Box>
      </Banner>
      {icon && <ThinBanner title={title} icon={icon} isSubBanner={true} />}
    </>
  );
}

export default FullBanner;
