import { LinkItem, PageBase } from 'src/types/common';
import { FilterGroup } from 'src/types/filters';
import { slugify, valueOrThrow } from 'src/utils/utils';

/**
 * Utility function that determines whether a given object is of a given type, TType
 * @param obj Object to test against
 * @param field Field belonging to the desired type (TType), used to index into the object
 * @returns Boolean indicating whether the input object is of type TType
 */
export function isType<TType>(obj: any, field: keyof TType): obj is TType {
  try {
    return (obj as TType)[field] !== undefined;
  } catch (err) {
    console.error(err);
    console.log(obj);
    return false;
  }
}

const traverse = (obj: any, filter: (key: any, value: any) => boolean) => {
  if (typeof obj !== 'object' || obj === null) return;
  Object.entries(obj).forEach(([key, value]) => {
    if (filter(key, value)) traverse(value, filter);
  });
};

interface FilterUpdate {
  oldValue: string;
  newValue: string;
}

/**
 * Utility function that recursively iterates through pages, updating any links to have up-to-date labels
 *
 * @param existingPages Current pages in the store
 * @param existingFilters Current filters in the store
 * @param newFilters New filters, after having been updated
 */
export const updatePageLinks = (
  existingPages: PageBase[],
  existingFilters: FilterGroup[],
  newFilters: FilterGroup[]
): PageBase[] => {
  newFilters
    // map the modified filters to FilterUpdate object
    .map((modifiedFilter: FilterGroup): FilterUpdate => {
      return {
        oldValue: valueOrThrow(
          existingFilters.find(
            (existingFilter) =>
              modifiedFilter.id === existingFilter.id &&
              modifiedFilter.type === existingFilter.type
          )
        ).label,
        newValue: modifiedFilter.label,
      };
    })
    // iterate over each FilterUpdate and find/mutate the corresponding Link in the pages array
    .forEach((filterUpdate: FilterUpdate) => {
      traverse(existingPages, (key, value) => {
        const targetSubstring = `${slugify(filterUpdate.oldValue)}=`;
        // we've found a LinkItem that has the old label value; update it.
        if (
          value &&
          isType<LinkItem>(value, 'to') &&
          (value as LinkItem).to.includes(targetSubstring)
        ) {
          value.to = value.to.replace(
            targetSubstring,
            `${slugify(filterUpdate.newValue)}=`
          );
          return false;
        }
        return true;
      });
    });

  return existingPages;
};
