import React from 'react';
import { Grid, Typography } from '@mui/material';
import { cloneDeep, set } from 'lodash';

import { FilterOrder, FilterType } from 'src/types/filters';
import ListEditor from './ListEditor';
import StringEditor from './StringEditor';

const FilterOrderEditor = React.forwardRef(
  (
    {
      initialOrder,
      thematicOptions,
      demographicOptions,
    }: {
      initialOrder: FilterOrder;
      thematicOptions: string[];
      demographicOptions: string[];
    },
    ref
  ) => {
    const [order, setOrder] = React.useState<FilterOrder>(initialOrder);
    const handleChange = (value: string[] | string, match: string) => {
      const newOrder = cloneDeep(order);
      set(newOrder, match, value);
      if (ref) {
        (ref as React.MutableRefObject<FilterOrder>).current = newOrder;
      }
      setOrder(newOrder);
    };

    return (
      <>
        <Typography variant="h6" sx={{ marginTop: '1rem' }}>
          Tab Names
        </Typography>
        <Grid container>
          <Grid item sm={6}>
            {FilterType.demographic}
          </Grid>
          <Grid item sm={6}>
            {FilterType.thematic}
          </Grid>
          <Grid item sm={6}>
            <StringEditor
              initialValue={initialOrder.demographic.name}
              onChange={(value) =>
                handleChange(value as string, 'demographic.name')
              }
            />
          </Grid>
          <Grid item sm={6}>
            <StringEditor
              initialValue={initialOrder.thematic.name}
              onChange={(value) =>
                handleChange(value as string, 'thematic.name')
              }
            />
          </Grid>
        </Grid>
        <Typography variant="h6" sx={{ marginTop: '1rem' }}>
          Tab Order
        </Typography>
        <ListEditor
          testID="filter-tabs-list"
          initialOptions={initialOrder.tabs}
          allOptions={[FilterType.demographic, FilterType.thematic]}
          onChange={(value) => handleChange(value as string[], 'tabs')}
        />
        <Typography variant="h6" sx={{ marginTop: '1rem' }}>
          Thematic Tab Order
        </Typography>
        <ListEditor
          testID="theme-code-list"
          initialOptions={initialOrder.thematic.order}
          allOptions={thematicOptions}
          onChange={(value) =>
            handleChange(value as string[], 'thematic.order')
          }
        />
        <Typography variant="h6" sx={{ marginTop: '1rem' }}>
          Demographic Tab Order
        </Typography>
        <ListEditor
          testID="demographic-code-list"
          initialOptions={initialOrder.demographic.order}
          allOptions={demographicOptions}
          onChange={(value) =>
            handleChange(value as string[], 'demographic.order')
          }
        />
      </>
    );
  }
);

export default FilterOrderEditor;
