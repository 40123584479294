import { PageBase } from 'src/types/common';
import { ABOUT } from './about/about';
import { PROCESS as ABOUT_PROCESS } from './about/process';
import { TEAM } from './about/team';
import { HOME } from './home/home';
import { CLIENT_ENGAGEMENT } from './insights/client_engagement';
import { CULTURE } from './insights/culture';
import { INSIGHTS } from './insights/insights';
import { INTRA_ORGANIZATIONAL } from './insights/intra_organizational';
import { PROCESS } from './insights/process';
import { RELATIONSHIPS } from './insights/relationships';
import { REMOTE_WORKING_EXPERIENCE } from './insights/remote_working_experience';
import { TRAINING_DEVELOPMENT } from './insights/training_development';
import { WORK_LIFE_BALANCE } from './insights/work_life_balance';
import { CONVERSATION_LIBRARY } from './voices/conversation_library';
import { HIGHLIGHT_EXPLORER } from './voices/highlight_explorer';
import { VOICES } from './voices/voices';

export const PAGES: PageBase[] = [
  HOME,
  VOICES,
  CONVERSATION_LIBRARY,
  HIGHLIGHT_EXPLORER,
  ABOUT,
  TEAM,
  ABOUT_PROCESS,
  INSIGHTS,
  CULTURE,
  CLIENT_ENGAGEMENT,
  PROCESS,
  INTRA_ORGANIZATIONAL,
  RELATIONSHIPS,
  REMOTE_WORKING_EXPERIENCE,
  TRAINING_DEVELOPMENT,
  WORK_LIFE_BALANCE,
];
