import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import { getBanner } from 'src/components/common/banners/utils';
import InfoCards from 'src/components/common/cards/InfoCards';
import appSelectors from 'src/redux/app/app-selectors';
import { StoreState } from 'src/redux/store';
import { PageProps } from 'src/types/common';
import { InsightsPage } from 'src/types/pages/insights';

function Insights({ page_data_id }: PageProps) {
  const pageInfo = useSelector((state: StoreState) =>
    appSelectors.getPage(state, page_data_id)
  ) as InsightsPage;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!pageInfo) {
    return <></>;
  }

  return (
    <Grid container>
      {pageInfo.header?.visible && (
        <div
          data-edit-id={`${pageInfo.page_id}.header`}
          style={{ width: '100%' }}
        >
          {getBanner(pageInfo.header.content)}
        </div>
      )}
      {pageInfo.insight_cards?.visible && (
        <div
          data-edit-id={`${pageInfo.page_id}.insight_cards`}
          style={{ width: '100%' }}
        >
          <InfoCards cards={pageInfo.insight_cards.content.cards} triple />
        </div>
      )}
    </Grid>
  );
}

export default Insights;
