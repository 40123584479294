import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

interface CustomTabsProps {
  items: { label: string }[];
  selected_index: number;
  onChange: (ind: number) => void;
  scroll?: boolean;
  small?: boolean;
}

function CustomTabs({
  items,
  selected_index,
  onChange,
  scroll,
  small,
}: CustomTabsProps) {
  const selected = items[selected_index].label;
  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={selected}
        TabIndicatorProps={{
          style: { transition: 'none', display: 'none' },
        }}
        onChange={(event, value) =>
          onChange(items.findIndex((item) => item.label === value))
        }
        variant={scroll ? 'scrollable' : 'fullWidth'}
        scrollButtons="auto"
        centered={!scroll}
      >
        {items.map((item) => {
          return (
            <Tab
              value={item.label}
              key={item.label}
              iconPosition="start"
              label={item.label}
              sx={{
                fontFamily: 'Montserrat',
                fontSize: small ? '12px' : '16px',
                textTransform: 'inherit',
                backgroundColor: selected === item.label ? 'primary.dark' : '',
                color:
                  selected === item.label
                    ? '#FFF !important'
                    : '#000 !important',
                borderRadius: '5px 5px 0 0',
              }}
            />
          );
        })}
      </Tabs>
    </Box>
  );
}

export default CustomTabs;
