import React from 'react';
import { Container, Grid } from '@mui/material';

interface SplitBackgroundGridProps {
  children: React.ReactElement | React.ReactElement[];
  no_split?: boolean;
}

function SplitBackgroundGrid({ children, no_split }: SplitBackgroundGridProps) {
  return (
    <Grid
      item
      xs={12}
      component="div"
      sx={{
        background: no_split
          ? '#ffffff'
          : 'linear-gradient(to right, #ffffff 50%, rgba(0,0,0,0) 50%)',
      }}
    >
      <Container maxWidth="lg">{children}</Container>
    </Grid>
  );
}

export default SplitBackgroundGrid;
