import React, {
  forwardRef,
  MutableRefObject,
  useCallback,
  useRef,
  useState,
} from 'react';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
} from '@mui/material';
import * as d3 from 'd3';
import { cloneDeep } from 'lodash';
import { useEffectOnce, useIsFirstRender, useToggle } from 'usehooks-ts';

import { Code } from 'src/types/common';
import { LocationMetadata, MapItem } from 'src/types/map';
import { valueOrThrow } from 'src/utils/utils';
import {
  resizeMap,
  separateLocationPaths,
  useMapZoom,
} from '../../HighlightExplorer/utils/utils';
import {
  InitialMapItems,
  MapUpdate,
  NULL_PK,
  resetMap,
} from '../utils/mapUtils';
import { useFileUploaded } from '../utils/useFileUploaded';
import { useRegionClick } from '../utils/useRegionClick';
import DemographicList from './DemographicList';
import FileUploadDialog from './FileUploadDialog';

const MapEditor = forwardRef(
  (
    {
      initialValue,
    }: {
      initialValue: InitialMapItems;
    },
    ref
  ) => {
    const isFirstRender = useIsFirstRender();
    const svgContainerRef: MutableRefObject<null> = useRef(null);
    const svgRef = useRef<SVGSVGElement | null>(null);
    const [trimMode, toggleTrimMode] = useToggle(false);
    const [d3MappingComplete, setD3MappingComplete] = useState({});
    const [showUploadModal, toggleUploadModal] = useToggle(false);
    const demographicCodes: MutableRefObject<Code[]> = useRef(
      initialValue.demographicCodes
    );
    const [selectedCode, setSelectedCode] = useState<Code | undefined>(
      demographicCodes.current[0] ?? undefined
    );
    // used to track mutable mapItems state, think of this as the "working copy"
    const mapItems: MutableRefObject<MapItem[]> = useRef(initialValue.mapItems);
    // used to hold the pristine map items in case the user wishes to reset their changes
    const resetMapItems: MutableRefObject<MapItem[]> = useRef([]);

    const { zoomIn, zoomOut } = useMapZoom(svgRef, 0.4, 10);

    /**
     * Used to assign the map items and demographic codes to ref.current for saving purposes.
     */
    const updateRef = useCallback(() => {
      if (ref) {
        (ref as MutableRefObject<MapUpdate>).current = {
          mapItems: mapItems.current,
          demographicCodes: demographicCodes.current,
          codesHaveChanged: initialValue.mappableCodesChanged,
        };
      }
    }, [initialValue.mappableCodesChanged, ref]);

    // Custom hook to handle the logic behind clicking on regions
    useRegionClick(
      d3MappingComplete,
      svgContainerRef,
      trimMode,
      selectedCode,
      mapItems,
      updateRef
    );

    // Custom hook to handle image uploads
    const { fileUploaded, processingImage } = useFileUploaded(
      svgRef,
      svgContainerRef,
      resetMapItems,
      initialValue,
      mapItems,
      updateRef,
      setD3MappingComplete
    );

    // Callback to handle map resets
    const resetMapCallback = useCallback(
      () =>
        resetMap(
          mapItems,
          resetMapItems,
          svgContainerRef,
          updateRef,
          setD3MappingComplete
        ),
      [
        mapItems,
        resetMapItems,
        svgContainerRef,
        updateRef,
        setD3MappingComplete,
      ]
    );

    /**
     * Function that conditionally displays the trim and reset buttons if there is a map present.
     * @returns Trim and Reset buttons
     */
    const displayMapButtons = () => {
      const mapPresent =
        valueOrThrow<MapItem>(
          mapItems.current.find((mapItem) => mapItem.demographic_id === NULL_PK)
        ).paths.length > 0;
      return mapPresent ? (
        <>
          <Button
            variant="contained"
            component="label"
            data-testid="trim-mode-button"
            disabled={demographicCodes.current.length < 1}
            style={{ marginLeft: '1rem', width: 'min-content' }}
            onClick={toggleTrimMode}
          >
            Trim
          </Button>
          <Button
            variant="contained"
            component="label"
            data-testid="reset-button"
            disabled={trimMode || demographicCodes.current.length < 1}
            onClick={resetMapCallback}
            style={{ marginLeft: '1rem', width: 'min-content' }}
          >
            Reset
          </Button>
        </>
      ) : (
        <></>
      );
    };

    /**
     * Event handler for when the user selects a different demographic code or changes the selected code's color
     * @param updatedCode Code that changed
     */
    const onDemographicCodeChanged = useCallback(
      (updatedCode: Code) => {
        demographicCodes.current = demographicCodes.current.map(
          (code: Code) => {
            return code.id === updatedCode.id ? updatedCode : code;
          }
        );
        let colorChanged = false;
        mapItems.current.forEach((mapItem: MapItem) => {
          if (
            mapItem.demographic_id === updatedCode.id &&
            mapItem.color !== updatedCode.embed_colors.background_color
          ) {
            colorChanged = true;
            mapItem.color = updatedCode.embed_colors.background_color;
          }
        });

        if (svgContainerRef.current && colorChanged) {
          const svgElem: HTMLElement = (svgContainerRef.current as HTMLElement)
            .children[0] as HTMLElement;
          d3.select(svgElem)
            .selectAll('path')
            .filter(
              (d: any) =>
                (d as LocationMetadata).demographic_id === updatedCode.id
            )
            .attr('fill', updatedCode.embed_colors.background_color);
        }
        setSelectedCode(updatedCode);
        updateRef();
      },
      [updateRef]
    );

    /**
     * Initialization logic. Only fires if there's an existing map pulled from the store.
     */
    useEffectOnce(() => {
      if (!initialValue.generated && svgContainerRef.current) {
        const svgContainer = d3.select(svgContainerRef.current as HTMLElement);
        const locationPaths: LocationMetadata[] = separateLocationPaths(
          initialValue.mapItems as LocationMetadata[]
        );
        resetMapItems.current = cloneDeep(mapItems.current);
        svgContainer
          .append('svg')
          .attr('id', 'initialMapSvg')
          .attr('width', '100%')
          .attr('height', '100%')
          .attr('preserveAspectRatio', 'xMidYMid meet')
          .append('g')
          .selectAll('g')
          .data(locationPaths)
          .join('path')
          .attr('fill', (d: LocationMetadata) => d.color ?? '#CCC')
          .attr('d', (d: LocationMetadata) => d.path ?? '');

        setD3MappingComplete({});
      }
    });

    useEffectOnce(() => {
      if (svgContainerRef.current) {
        const svgContainer = svgContainerRef.current as HTMLDivElement;
        const svg = svgContainer.firstChild as SVGSVGElement;
        if (svg) {
          svgRef.current = svg;
          svg.style.transformOrigin = 'top left'; // Needed to anchor the zoom
          resizeMap(svgContainer, svg);
        }
      }
    });

    return (
      <>
        {trimMode && (
          <Alert severity="warning" style={{ marginTop: '1rem' }}>
            Warning: Trim Mode is a destructive operation; any trimmed
            selections will be permanently deleted
          </Alert>
        )}
        <div
          style={{
            display: 'flex',
            height: '100%',
            marginTop: '1rem',
          }}
          data-testid="map-editor-container"
        >
          <Box sx={{ width: '25%', marginRight: '1rem', height: '100%' }}>
            {demographicCodes.current.length > 0 ? (
              <DemographicList
                initialDemographicCodes={demographicCodes.current}
                trimMode={trimMode}
                onDemographicCodeChanged={onDemographicCodeChanged}
              ></DemographicList>
            ) : (
              <div>
                No mappable codes found. Please add mappable codes before
                editing/uploading a map.
              </div>
            )}
          </Box>
          <div
            id="map-upload-container"
            className="flex-column"
            style={{ width: '75%', height: '100%' }}
          >
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex' }}>
                <Button
                  variant="contained"
                  component="label"
                  disabled={trimMode || demographicCodes.current.length < 1}
                  data-testid="upload-map-button"
                  onClick={toggleUploadModal}
                >
                  Upload
                </Button>
                {displayMapButtons()}
              </div>
              <ButtonGroup style={{ marginLeft: '1rem' }}>
                <Button onClick={zoomIn} sx={{ padding: 0 }}>
                  +
                </Button>
                <Button onClick={zoomOut} sx={{ padding: 0 }}>
                  -
                </Button>
              </ButtonGroup>
            </div>
            {isFirstRender && initialValue.mappableCodesChanged && (
              <Alert severity="warning" style={{ marginTop: '1rem' }}>
                The mappable demographic codes have changed since the last map
                upload/edit. Change the codes back to edit the existing map, or
                upload a new map.
              </Alert>
            )}
            <div
              style={{
                display: 'flex',
                marginTop: '1rem',
                width: '100%',
                height: '100%',
              }}
            >
              <Box
                id="svgContainer"
                data-testid="svg-container"
                ref={svgContainerRef}
                sx={{
                  width: '100%',
                  height: '100%',
                  overflow: 'auto',
                  scrollbarWidth: 'auto',
                  scrollbarColor: '#6A6C6E #ffffff',
                  ['&::-webkit-scrollbar']: { width: '8px', height: '8px' },
                  ['&::-webkit-scrollbar-track']: { background: ' #ffffff' },
                  ['&::-webkit-scrollbar-thumb']: {
                    backgroundColor: '#6A6C6E',
                    borderRadius: '10px',
                  },
                }}
              ></Box>
            </div>
            {processingImage && <CircularProgress disableShrink={true} />}
          </div>
        </div>
        <FileUploadDialog
          showUploadModal={showUploadModal}
          toggleUploadModal={toggleUploadModal}
          fileUpload={fileUploaded}
        ></FileUploadDialog>
      </>
    );
  }
);

export default MapEditor;
