import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Launch } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { cloneDeep, get, set } from 'lodash';

import appSelectors from 'src/redux/app/app-selectors';
import { updatePage, updateRoutes } from 'src/redux/app/app-slice';
import { StoreState } from 'src/redux/store';
import { getMatchingRoute } from 'src/setup/routes';
import { EditBlock, EditSection, EditType } from 'src/types/edit';
import { CustomRoute } from 'src/types/routes';
import { useGeneratePath } from 'src/utils/useGeneratePath';
import CollapsableSection from '../CollapsableSection';
import EditSectionModal from '../EditSectionModal';
import { getRoutePath } from '../utils/utils';

const PageEditingTab = () => {
  const navigate = useNavigate();
  const generatePath = useGeneratePath();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const page_edit_metadata = useSelector((state: StoreState) =>
    appSelectors.getEditMetadata(state)
  );
  const routes = useSelector((state: StoreState) =>
    appSelectors.getCustomRoutes(state)
  );
  const [routeToEdit, setRouteToEdit] = React.useState<CustomRoute>();
  //Unnest routes so that we can get the full edit data.
  const activeRoute = getMatchingRoute(routes, { path: pathname });
  const activePage = page_edit_metadata.find(
    (data) => data.page_id === activeRoute?.page_data_id
  );

  const handlePageClick = useCallback(
    (page_id?: string) => {
      if (!page_id) {
        return;
      }
      const matchingRoute = getMatchingRoute(routes, { page_id });
      if (matchingRoute) {
        return navigate(generatePath(matchingRoute.path), { replace: true });
      }
    },
    [routes, navigate, generatePath]
  );

  const pageInfo = useSelector((state: StoreState) =>
    appSelectors.getPage(state, activePage?.page_id ?? '')
  );
  const [editSection, setEditSection] = React.useState<EditSection>();
  const modifiedBlocks = React.useMemo(() => {
    if (!editSection || !pageInfo) {
      return [];
    }
    return (
      editSection?.edit_blocks?.map((block) => {
        return {
          ...block,
          value: get(pageInfo, block.match),
        };
      }) ?? []
    );
  }, [editSection, pageInfo]);
  const onClose = () => {
    setEditSection(undefined);
  };
  const onSave = (blocks: EditBlock[]) => {
    if (pageInfo && editSection) {
      const newPage = cloneDeep(pageInfo);
      blocks.forEach((block) => {
        set(newPage, block.match, block.value);
      });
      dispatch(updatePage(newPage));
    }
    onClose();
  };
  const onCloseRouteEditor = () => {
    setRouteToEdit(undefined);
  };
  const onSaveRoute = (blocks: EditBlock[]) => {
    if (routeToEdit && routes) {
      const newRoutes = cloneDeep(routes);
      blocks.forEach((block) => {
        if (block.match) {
          // need to trim the parent if the path is a subroute
          const sanitizedPath: string = (
            block.value as CustomRoute
          ).path.replace(/\/\w+/, '');
          if (sanitizedPath.length > 0) {
            block.value.path = sanitizedPath;
          }
          set(newRoutes, block.match, block.value);
        }
      });
      dispatch(updateRoutes(newRoutes));
    }
    onCloseRouteEditor();
  };
  const routeBlocks: EditBlock[] = React.useMemo(() => {
    if (!routeToEdit) {
      return [];
    } else {
      return [
        {
          block_label: 'Visibility',
          match: getRoutePath(routes, routeToEdit.page_data_id),
          type: EditType.route,
          value: routeToEdit,
        },
      ];
    }
  }, [routeToEdit, routes]);
  const onUpdateSection = (
    section: EditSection,
    removeSubsection?: EditSection
  ) => {
    if (pageInfo && section.update_section) {
      const newPage = cloneDeep(pageInfo);
      const newData = get(newPage, section.update_section.match) as any[];
      const editSectionIndex = newPage.edit_metadata?.sections.findIndex(
        ({ section_label }) => section_label === section.section_label
      );
      if (!newData || !editSectionIndex || !newPage.edit_metadata) {
        return;
      }
      const editSectionMatch = `edit_metadata.sections[${editSectionIndex}]`; // The path to the coresponding edit section
      const newEditSection = get(newPage, editSectionMatch) as EditSection; // A copy of the corresponding edit section
      const matchIndexRegex = new RegExp(/([0-9]*)(?=\])/, 'g');
      if (removeSubsection) {
        // We are removing
        const removeSubsectionMatch = removeSubsection.edit_blocks
          ? removeSubsection.edit_blocks[0].match
          : '';
        const dataIndexTemp = removeSubsectionMatch.match(
          matchIndexRegex //Search for the index in the match ie. insights_showcase.card[0] => 0
        );
        if (!dataIndexTemp) {
          return;
        }
        const dataIndex = parseInt(dataIndexTemp[0]);
        if (typeof dataIndex === 'number') {
          // Remove the index from the data and subsections
          newData.splice(dataIndex, 1);
          // Remove all subsections;
          newEditSection.sub_sections = newEditSection.sub_sections?.filter(
            (subsection) => {
              const isNumbered = subsection.numbered;
              return !isNumbered;
            }
          );
          // Add new subsections
          const indexRegex = new RegExp(/\[index\]/, 'g');
          const newEditSubsections = newEditSection.sub_sections?.concat(
            newData.map((data, ind) => {
              let stringifiedSubsection = JSON.stringify(
                newEditSection.update_section?.newSectionMetadata
              );
              stringifiedSubsection = stringifiedSubsection.replace(
                indexRegex,
                `[${ind.toString()}]`
              );
              return JSON.parse(stringifiedSubsection);
            }) ?? []
          );
          newEditSection.sub_sections = newEditSubsections;
        }
      } else {
        // We are adding
        const indexRegex = new RegExp(/\[index\]/, 'g');
        const nextDataItem = cloneDeep(newEditSection.update_section?.empty);
        let nextEditSubSection =
          newEditSection.sub_sections &&
          newEditSection.update_section?.newSectionMetadata;
        if (!nextDataItem || !nextEditSubSection) {
          return;
        }
        newData.push(nextDataItem);
        let stringifiedSubsection = JSON.stringify(nextEditSubSection);
        stringifiedSubsection = stringifiedSubsection.replace(
          indexRegex,
          `[${(newData.length - 1).toString()}]`
        );
        nextEditSubSection = JSON.parse(stringifiedSubsection);
        newEditSection.sub_sections?.push(
          nextEditSubSection ?? ({} as EditSection)
        );
      }
      dispatch(updatePage(newPage));
    }
    onClose();
  };

  return (
    <div>
      <List>
        {page_edit_metadata.map((data) => {
          const isActive = activePage?.page_id === data.page_id;
          return (
            <React.Fragment key={data.page_label}>
              <ListItem disablePadding>
                <Divider />
                <ListItemButton
                  data-testid={`section-button.${data.page_id}`}
                  selected={isActive}
                  onClick={() => !isActive && handlePageClick(data.page_id)}
                  sx={
                    isActive
                      ? {
                          opacity: '1 !important',
                          textDecoration: 'underline',
                        }
                      : {}
                  }
                >
                  <ListItemText primary={data.page_label} />
                  <IconButton
                    data-testid="route-modify-button"
                    disabled={!isActive}
                    onClick={() =>
                      setRouteToEdit(
                        getMatchingRoute(routes, { page_id: data.page_id })
                      )
                    }
                  >
                    <Launch />
                  </IconButton>
                </ListItemButton>
              </ListItem>
              {isActive && (
                <List>
                  {data.sections.map((section) => {
                    const editSectionLabel = section.sub_sections?.length
                      ? section.sub_sections
                          ?.at(0)
                          ?.edit_blocks?.at(0)
                          ?.match.split('.')
                          .at(0) ?? ''
                      : section.edit_blocks?.at(0)?.match.split('.').at(0) ??
                        '';
                    const editId = `${data.page_id}.${editSectionLabel}`;
                    return (
                      <CollapsableSection
                        onUpdateSection={onUpdateSection}
                        editId={editId}
                        key={section.section_label}
                        disabled={!activeRoute?.visible}
                        pageLabel={data.page_label}
                        section={section}
                        onSectionClick={(section) => setEditSection(section)}
                      />
                    );
                  })}
                </List>
              )}
            </React.Fragment>
          );
        })}
      </List>
      <EditSectionModal
        label={editSection?.edit_header}
        blocks={modifiedBlocks}
        onSave={onSave}
        onClose={onClose}
        onRemove={editSection?.onUpdateSection}
      />
      <EditSectionModal
        label={'Modify this Route'}
        blocks={routeBlocks}
        onSave={onSaveRoute}
        onClose={onCloseRouteEditor}
      />
    </div>
  );
};
export default PageEditingTab;
