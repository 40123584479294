import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import { getBanner } from 'src/components/common/banners/utils';
import InfoCards from 'src/components/common/cards/InfoCards';
import appSelectors from 'src/redux/app/app-selectors';
import { StoreState } from 'src/redux/store';
import { PageProps } from 'src/types/common';
import { VoicesPage } from 'src/types/pages/voices';

function Voices({ page_data_id }: PageProps) {
  const pageInfo = useSelector((state: StoreState) =>
    appSelectors.getPage(state, page_data_id)
  ) as VoicesPage;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {pageInfo ? (
        <Grid container paddingBottom={6}>
          {pageInfo.header?.visible && (
            <div
              data-edit-id={`${pageInfo.page_id}.header`}
              style={{ width: '100%' }}
            >
              {getBanner(pageInfo.header.content)}
            </div>
          )}
          {pageInfo.navigation_cards && pageInfo.navigation_cards.visible && (
            <div
              data-edit-id={`${pageInfo.page_id}.navigation_cards`}
              style={{ width: '100%' }}
            >
              <InfoCards cards={pageInfo.navigation_cards.content.cards} />
            </div>
          )}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}

export default Voices;
